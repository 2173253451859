#sidebar {}

#sidebar .leftisidelogo {
    width: 100px;
    height: 100px;
    display: inline-block;
}

#sidebar .sideanker .btnsecondary::after{
    display: none;
}
#sidebar .dropdowSidebar {
    font-size: 14px;
    color: black;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
#sidebar .dropdowSidebar:hover{
    background-color: #00cab2;
    color: white;
    border-radius: 8px;
}
#sidebar .dropdowSidebar:hover .btnsecondary{
    color: white;
    transition: all 0.3s ease-in-out;
}
#sidebar .dropdowSidebar .btnsecondary {
    font-size: 14px;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    transition: all 0.3s ease-in-out;
}

#sidebar .dropdowSidebar .dropdown-menu {
    width: 100%;
    background: #00cab2;
    border: none;
}

#sidebar .dropdowSidebar .dropdown-menu li:hover .dropdown-item{
    color: white;
}

#sidebar .dropdowSidebar .dropdown-menu li .dropdown-item {
    font-size: 13px;
    color: black;
}

@media (min-width: 320px) and (max-width: 575.99px) {}

@media (min-width: 576px) and (max-width: 991.99px) {}