@charset "UTF-8";
/*------------------------------------------------------------------
[Master Stylesheet]
Project        :   ZEM - HTML5 Bootstrap Admin Template
Version        :   V.1
Copyright      :   Spruko Technologies Private Limited 
Author         :   SPRUKO
Author URL     :   https://themeforest.net/user/spruko
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/
/* ######## FONT ######## */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&amp;display=swap");

:root {
  --background: rgba(255, 255, 255, 0.1);
  --color: #b0bbc7;
  --color2: rgba(255, 255, 255, 0.75);
  --primary-rgb: 77, 238, 234;
  --background-rgb: 18, 55, 61;
  --main-gradient-bg: radial-gradient(circle,
      rgb(var(--background-rgb)) 0%,
      #121923,
      #121923 100%);
  --primary-bg-color: rgb(var(--primary-rgb));
  --primary-bg-hover: rgb(var(--primary-rgb));
  --primary-bg-border: rgb(var(--primary-rgb));
  --primary01: rgba(var(--primary-rgb), 0.1);
  --primary02: rgba(var(--primary-rgb), 0.2);
  --primary03: rgba(var(--primary-rgb), 0.3);
  --primary04: rgba(var(--primary-rgb), 0.4);
  --primary05: rgba(var(--primary-rgb), 0.5);
  --primary06: rgba(var(--primary-rgb), 0.6);
  --primary07: rgba(var(--primary-rgb), 0.7);
  --primary08: rgba(var(--primary-rgb), 0.8);
  --primary09: rgba(var(--primary-rgb), 0.9);
  --primary005: rgba(var(--primary-rgb), 0.05);
  --primary-transparentcolor: rgba(var(--primary-rgb), 0.2);
  --secondary-rgb: 249, 248, 113;
  --secondary: rgb(var(--secondary-rgb));
  --secondary-02: rgba(var(--secondary-rgb), 0.2);
  --secondary-03: rgba(var(--secondary-rgb), 0.3);
  --secondary-05: rgba(var(--secondary-rgb), 0.5);
  --secondary-09: rgba(var(--secondary-rgb), 0.9);
  --dark-body: #161b25;
  --dark-theme: #1e232d;
  --app-color-picker: #4deeea;
  --dark: #191f2b;
  --black: #fff;
  --yellow: #ffc102;
  --muted: #9aa5b9;
  --light: #17171c;
  --gray-100: #110f0f;
  --gray-200: #17171c;
  --gray-300: #393946;
  --gray-400: #505062;
  --gray-500: #73738c;
  --gray-600: #8f8fa3;
  --gray-700: #ababba;
  --gray-800: #c7c7d1;
  --gray-900: #e3e3e8;
  --white-1: rgba(0, 0, 0, 0.1);
  --white-2: rgba(0, 0, 0, 0.2);
  --white-3: rgba(0, 0, 0, 0.3);
  --white-4: rgba(0, 0, 0, 0.4);
  --white-5: rgba(0, 0, 0, 0.5);
  --white-6: rgba(0, 0, 0, 0.6);
  --white-7: rgba(0, 0, 0, 0.7);
  --white-8: rgba(0, 0, 0, 0.8);
  --white-9: rgba(0, 0, 0, 0.9);
  --white-05: rgba(0, 0, 0, 0.05);
  --white-08: rgba(0, 0, 0, 0.08);
  --white-75: rgba(0, 0, 0, 0.075);
  --black-1: rgba(255, 255, 255, 0.1);
  --black-2: rgba(255, 255, 255, 0.2);
  --black-3: rgba(255, 255, 255, 0.3);
  --black-4: rgba(255, 255, 255, 0.4);
  --black-5: rgba(255, 255, 255, 0.5);
  --black-6: rgba(255, 255, 255, 0.6);
  --black-7: rgba(255, 255, 255, 0.7);
  --black-8: rgba(255, 255, 255, 0.8);
  --black-9: rgba(255, 255, 255, 0.9);
  --black-03: rgba(255, 255, 255, 0.03);
  --black-05: rgba(255, 255, 255, 0.05);
  --shadow: 0 0 4px rgba(255, 255, 255, 0.1);
  --shadow-color: rgba(0, 0, 0, 0.1);
  --card-background: rgba(255, 255, 255, 0.1);
  --border: rgba(var(--primary-rgb), 0.1);
  --float-start: left;
  --float-end: right;
  --form-border: rgba(255, 255, 255, 0.1);
  --bg-hover: rgba(0, 0, 0, 0.2);
  --bg-white: rgba(0, 0, 0, 0.2);
  --bg-active2: #17171c;
  --header-color: rgba(255, 255, 255, 0.5);
  --header-border: rgba(255, 255, 255, 0.1);
  --input-bg: rgba(0, 0, 0, 0.2);
  --menu-color: rgba(255, 255, 255, 0.5);
  --menu-border: rgba(255, 255, 255, 0.1);
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 992px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

/******variables Declaration******/
/*gradient variables*/
/*gray variables*/
/*shadow variables*/
/*float variables*/
/*white variables*/
/*black variables*/
/*header variables*/
/*menu variables*/
[dir="rtl"] {
  --float-start: right;
  --float-end: left;
}

[data-theme-color="light"],
[data-theme-color="glassy"] {
  --main-gradient-bg: linear-gradient(72deg,
      #dadde2,
      #ffffff 25% 40%,
      #ffffff 40% 80%,
      #dadde2);
  --background-rgb: 241, 241, 241;
  --primary-rgb: 78, 98, 177;
  --color: #5d6162;
  --color2: #7b8191;
  --muted: #7987a1;
  --border: rgba(0, 0, 0, 0.05);
  --form-border: #cdd4e2;
  --gray-100: #f9fafb;
  --gray-200: #f2f4f5;
  --gray-300: #e6eaeb;
  --gray-400: #dbdfe1;
  --gray-500: #949eb7;
  --gray-600: #7987a1;
  --gray-700: #4d5875;
  --gray-800: #383853;
  --gray-900: #323251;
  --white-1: rgba(255, 255, 255, 0.1);
  --white-2: rgba(255, 255, 255, 0.2);
  --white-3: rgba(255, 255, 255, 0.3);
  --white-4: rgba(255, 255, 255, 0.4);
  --white-5: rgba(255, 255, 255, 0.5);
  --white-6: rgba(255, 255, 255, 0.6);
  --white-7: rgba(255, 255, 255, 0.7);
  --white-8: rgba(255, 255, 255, 0.8);
  --white-9: rgba(255, 255, 255, 0.9);
  --white-05: rgba(255, 255, 255, 0.05);
  --white-08: rgba(255, 255, 255, 0.08);
  --white-75: rgba(255, 255, 255, 0.075);
  --black: rgba(0, 0, 0, 1);
  --black-1: rgba(0, 0, 0, 0.1);
  --black-2: rgba(0, 0, 0, 0.2);
  --black-3: rgba(0, 0, 0, 0.3);
  --black-4: rgba(0, 0, 0, 0.4);
  --black-5: rgba(0, 0, 0, 0.5);
  --black-6: rgba(0, 0, 0, 0.6);
  --black-7: rgba(0, 0, 0, 0.7);
  --black-8: rgba(0, 0, 0, 0.8);
  --black-9: rgba(0, 0, 0, 0.9);
  --black-03: rgba(0, 0, 0, 0.03);
  --black-05: rgba(0, 0, 0, 0.05);
  --bg-hover: #f3f3f3;
  --dark-theme: #fff;
  --header-bg: #f1f1f1;
  --menu-bg: #f1f1f1;
  --bg-white: #f9f9f9;
  --secondary-rgb: 187, 186, 66;
}

[data-theme-color="dark"] {
  --main-gradient-bg: linear-gradient(rgb(var(--background-rgb)) 0%,
      #121923,
      #121923 100%);
  --background-rgb: 20, 20, 20;
  --color: #dde5ed;
  --color2: rgba(255, 255, 255, 0.5);
  --muted: #bcc3d1;
  --border: rgba(255, 255, 255, 0.1);
  --header-bg: #1e232d;
  --menu--bg: #1e232d;
}

/* BOOTSTRAP */
.nft_theme .accordion>.accordion-item {
  border-color: var(--border);
  overflow: hidden;
  background-color: transparent;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a {
  display: block;
  position: relative;
  padding: 12px;
  color: var(--color);
  transition: all ease 0.3s;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a:not(.collapsed)>.accordion-controls>.shrink {
  display: block;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a:not(.collapsed)>.accordion-controls>.expand {
  display: none;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a.collapsed>.accordion-controls>.shrink {
  display: none;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a.collapsed>.accordion-controls>.expand {
  display: block;
}

.nft_theme .accordion>.accordion-item>.accordion-header>a>.accordion-controls {
  position: absolute;
  inset-inline-end: 12px;
}

.nft_theme .accordion>.accordion-item>.accordion-collapse {
  border-block-start: 1px solid var(--border);
}

.nft_theme .accordion.custom-accordion>.accordion-item+.accordion-item {
  margin-block-start: 10px;
}

.nft_theme .accordion.custom-accordion>.accordion-item {
  border-radius: 4px;
}

.nft_theme .accordion.custom-accordion>.accordion-item:not(:first-of-type) {
  border-block-start: 1px solid var(--border);
}

.nft_theme .accordion.custom-accordion>.accordion-item>.accordion-header>a:not(.collapsed) {
  background-color: var(--primary-bg-color);
  color: #000;
}

.nft_theme .accordion.custom-accordion>.accordion-item>.accordion-header>a.collapsed:hover {
  background-color: var(--bg-hover);
  color: var(--primary-bg-color);
}

.nft_theme .accordion.accordion-style1>.accordion-item+.accordion-item {
  margin-block-start: 10px;
}

.nft_theme .accordion.accordion-style1>.accordion-item {
  border-radius: 4px;
}

.nft_theme .accordion.accordion-style1>.accordion-item:not(:first-of-type) {
  border-block-start: 1px solid var(--border);
}

.nft_theme .accordion.accordion-style1>.accordion-item>.accordion-header>a:not(.collapsed) {
  background-color: var(--primary-bg-color);
  color: #000;
}

.nft_theme .accordion.accordion-style1>.accordion-item>.accordion-header>a.collapsed:hover {
  background-color: var(--bg-hover);
  color: var(--primary-bg-color);
}

.nft_theme .accordion.accordion-style2>.accordion-item {
  border-radius: 0px;
  border: none;
}

.nft_theme .accordion.accordion-style2>.accordion-item:not(:last-child) {
  border-block-end: 1px dashed var(--border);
}

.nft_theme .accordion.accordion-style2>.accordion-item>.accordion-collapse {
  border-block-start: none !important;
}

.nft_theme .accordion.accordion-style2>.accordion-item>.accordion-header>a:not(.collapsed) {
  color: var(--primary-bg-color);
}

.nft_theme .accordion.accordion-style2>.accordion-item>.accordion-header>a.collapsed:hover {
  color: var(--primary-bg-color);
}

.nft_theme .accordion.accordion-style2>.accordion-item>.accordion-collapse>.accordion-body {
  padding-block-start: 0px;
}

.alert {
  padding: 12px 15px;
  border-radius: 4px;
}

.alert .close {
  text-shadow: none;
  opacity: 0.5;
}

.alert strong {
  font-weight: 600;
}

.alert.style-1 {
  padding: 8px;
}

.alert.style-1 .alert_content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 95%;
}

.alert.style-2 .alert_content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 95%;
  position: relative;
}

.alert.style-2 .alert_content::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  border-radius: 20px;
  inset-inline-start: -9px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  background-color: rgb(var(--background-rgb));
}

.alert.style-2 .alert_content.warning::before {
  background-color: #ffcf86 !important;
}

.alert.style-2 .alert_content.primary::before {
  background-color: var(--primary-bg-color) !important;
}

.alert.style-2 .alert_content.secondary::before {
  background-color: var(--secondary) !important;
}

.alert.style-2 .alert_content.success::before {
  background-color: #43ce85 !important;
}

.alert.style-2 .alert_content.info::before {
  background-color: #4ec2f0 !important;
}

.alert.style-2 .alert_content.danger::before {
  background-color: #d6573c !important;
}

.alert.style-2 .alert_content.dark::before {
  background-color: var(--dark) !important;
}

.alert.style-3 {
  padding: 10px 12px;
}

.alert.style-3 .alert_content {
  display: flex;
  align-items: flex-start;
  max-width: 92%;
}

.alert.style-4 {
  border: none;
  padding: 20px 15px;
  border-radius: 23px;
}

.alert.style-4 .alert_content {
  width: 80%;
  margin: 0 auto;
}

.alert.style-4 .alert_inner_icn {
  position: absolute;
  inset-block-start: -13px;
  inset-inline-start: 6px;
}

.alert.style-4 .alert_inner_icn .avatar {
  position: relative;
}

.alert.style-4 .alert_inner_icn .avatar::before {
  content: "";
  height: 12px;
  width: 12px;
  position: absolute;
  inset-block-end: -2px;
  inset-inline-start: 7px;
  border-end-start-radius: 2px;
  border-end-end-radius: 2px;
  transform: rotate(67deg);
  border-start-start-radius: 20px;
  border-start-end-radius: 5px;
}

.alert.style-4 .alert_inner_icn .avatar.bg-success::before {
  background-color: #43ce85;
}

.alert.style-4 .alert_inner_icn .avatar.bg-warning::before {
  background-color: #ffcf86;
}

.alert.style-4 .alert_inner_icn .avatar.bg-info::before {
  background-color: #4ec2f0;
}

.alert.style-4 .alert_inner_icn .avatar.bg-secondary::before {
  background-color: var(--secondary);
}

.alert.style-4 .alert_inner_icn .avatar.bg-danger::before {
  background-color: #d6573c;
}

.alert.style-4 .alert_inner_icn .avatar.bg-primary::before {
  background-color: var(--primary-bg-color);
}

.alert.style-4 .alert_inner_icn .avatar.bg-dark::before {
  background-color: var(--dark);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible .btn-close {
  color: inherit;
  padding: 7px !important;
}

.alert-primary {
  color: var(--primary-bg-color);
  background-color: var(--primary02);
  border-color: var(--primary02);
}

.alert-primary hr {
  border-block-start-color: var(--primary-bg-color);
}

.alert-primary .alert-link {
  color: var(--primary-bg-color);
}

.alert-secondary {
  color: var(--secondary);
  background-color: var(--secondary-02);
  border-color: var(--secondary-02);
}

.alert-secondary hr {
  border-block-start-color: var(--secondary);
}

.alert-secondary .alert-link {
  color: var(--dark);
  font-weight: bold;
}

.alert-success {
  color: #43ce85;
  background-color: rgba(67, 206, 133, 0.2);
  border-color: rgba(67, 206, 133, 0.2);
}

.alert-success hr {
  border-block-start-color: #43ce85;
}

.alert-success .alert-link {
  color: #43ce85;
}

.alert-info {
  color: #4ec2f0;
  background-color: rgba(78, 194, 240, 0.2);
  border-color: rgba(78, 194, 240, 0.2);
}

.alert-info hr {
  border-block-start-color: #4ec2f0;
}

.alert-info .alert-link {
  color: #4ec2f0;
}

.alert-warning {
  color: var(--dark);
  background-color: rgba(255, 207, 134, 0.2);
  border-color: rgba(255, 207, 134, 0.2);
}

.alert-warning hr {
  border-block-start-color: #ffcf86;
}

.alert-warning .alert-link {
  color: var(--dark);
}

.alert-danger {
  color: #d6573c;
  background-color: rgba(214, 87, 60, 0.2);
  border-color: rgba(214, 87, 60, 0.2);
}

.alert-danger hr {
  border-block-start-color: #d6573c;
}

.alert-danger .alert-link {
  color: #d6573c;
}

.alert-light {
  color: var(--muted);
  background-color: var(--light);
  border-color: var(--border);
}

.alert-light hr {
  border-block-start-color: var(--border);
}

.alert-light .alert-link {
  color: var(--color);
}

.alert-dark {
  color: var(--dark);
  background-color: var(--gray-500);
  border-color: #c8ccd3;
}

.alert-dark hr {
  border-block-start-color: #babfc8;
}

.alert-dark .alert-link {
  color: #0c0e13;
}

.alert-default {
  color: var(--muted);
  background-color: var(--light);
  border-color: var(--border);
}

.alert-default hr {
  border-block-start-color: #babfc8;
}

.alert-default .alert-link {
  color: var(--color);
}

.alert-white {
  color: var(--color);
  background-color: rgb(var(--background-rgb));
  border-color: var(--border);
}

.alert-white hr {
  border-block-start-color: #babfc8;
}

.alert-white .alert-link {
  color: var(--color);
}

[class*="alert-outline-"] {
  border: 1px solid transparent;
}

.alert-outline-success {
  border-color: #43ce85;
  color: #43ce85;
}

.alert-outline-info {
  border-color: #4ec2f0;
  color: #4ec2f0;
}

.alert-outline-warning {
  border-color: #ffcf86;
  color: #ffcf86;
}

.alert-outline-primary {
  border-color: var(--primary-bg-color);
  color: var(--primary-bg-color);
}

.alert-outline-warning {
  border-color: #ffcf86;
  color: #ffcf86;
}

.alert-outline-danger {
  border-color: #d6573c;
  color: #d6573c;
}

[class*="alert-solid-"] {
  border-width: 0;
  color: rgb(var(--background-rgb));
}

.alert-solid-success {
  background-color: #43ce85;
}

.alert-solid-info {
  background-color: #4ec2f0;
}

.alert-solid-warning {
  background-color: #ffcf86;
}

.alert-solid-danger {
  background-color: #d6573c;
}

.badge-light {
  background-color: var(--bg-hover);
  color: var(--black);
}

.badge-pill {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

.nft_theme .badge-primary-transparent {
  color: var(--primary-bg-color);
  background-color: var(--primary02);
}

.nft_theme .badge-secondary-transparent {
  color: var(--secondary);
  background-color: rgba(var(--secondary-rgb), 0.15) !important;
}

.badge-success-transparent {
  background-color: rgba(67, 206, 133, 0.15) !important;
  color: #43ce85;
}

.badge-teal-transparent {
  background-color: rgba(74, 201, 189, 0.15) !important;
  color: #4ac9bd;
}

.badge-green-transparent {
  background-color: rgba(45, 206, 137, 0.15) !important;
  color: #2dce89;
}

.badge-warning-transparent {
  background-color: rgba(255, 207, 134, 0.15) !important;
  color: #ffcf86;
}

.badge-danger-transparent {
  color: #d6573c;
  background-color: rgba(214, 87, 60, 0.15);
}

.badge-purple-transparent {
  color: #7891ef;
  background-color: rgba(120, 145, 239, 0.15);
}

.badge-info-transparent {
  color: #4ec2f0;
  background-color: rgba(78, 194, 240, 0.15);
}

.badge-pink-transparent {
  color: #f754fa;
  background-color: rgba(247, 84, 250, 0.15);
}

.badge-light-transparent {
  color: var(--muted);
  background-color: rgba(0, 0, 0, 0.15);
}

.badge-dark-transparent {
  color: var(--dark);
  background-color: rgba(0, 0, 0, 0.15);
}

.badge {
  display: inline-block;
  padding: 4px 6px;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 3px;
  font-size: 75%;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.badge-sm {
  padding: 5px 9px !important;
  font-size: 11px;
}

.badge-sm.rounded-circle {
  padding: 5px !important;
}

.badge-md {
  padding: 6px 11px !important;
  font-size: 12px;
}

.badge-md.rounded-circle {
  padding: 6px !important;
}

.badge-lg {
  padding: 8px 13px !important;
  font-size: 13px;
}

.badge-lg.rounded-circle {
  padding: 8px !important;
}

.badge-top {
  inset-inline-start: 100%;
  transform: translate(-50%, -50%);
  position: absolute !important;
  border: 2px solid var(--border);
}

@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}

a.badge:hover,
a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge,
.sp-container button .badge {
  inset-block-start: -1px;
}

.badge-pill {
  padding-inline-end: 0.6em;
  padding-inline-start: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #000;
  background-color: var(--primary-bg-color);
}

.nft_theme a.badge-primary:hover {
  color: #000;
  background-color: var(--primary02);
}

.nft_theme a.badge-primary:focus {
  color: #000;
  background-color: var(--primary02);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--primary02);
}

.nft_theme a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--primary02);
}

.nft_theme .badge-secondary {
  color: var(--dark) !important;
  background-color: var(--secondary) !important;
}

.nft_theme a.badge-secondary:hover {
  color: #000;
  background-color: rgba(var(--secondary), 0.65);
}

.nft_theme a.badge-secondary:focus {
  color: #000;
  background-color: rgba(var(--secondary), 0.65);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.15);
}

.nft_theme a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(var(--secondary), 0.15);
}

.badge-success {
  color: #fff;
  background-color: #43ce85;
}

a.badge-success:hover {
  color: #fff;
  background-color: rgba(67, 206, 133, 0.65);
}

.nft_theme a.badge-success:focus {
  color: #fff;
  background-color: rgba(67, 206, 133, 0.65);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.1);
}

.nft_theme a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.1);
}

.nft_theme .badge-green {
  color: #fff;
  background-color: #2dce89;
}

.nft_theme a.badge-green:hover {
  color: #fff;
  background-color: rgba(45, 206, 137, 0.65);
}

.nft_theme a.badge-green:focus {
  color: #fff;
  background-color: rgba(45, 206, 137, 0.65);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 206, 137, 0.1);
}

.nft_theme a.badge-green.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(45, 206, 137, 0.1);
}

.nft_theme .badge-info {
  color: #fff;
  background-color: #4ec2f0;
}

a.badge-info:hover {
  color: #fff;
  background-color: #4ec2f0;
}

a.badge-info:focus {
  color: #fff;
  background-color: #4ec2f0;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.badge-warning {
  color: #fff;
  background-color: #ffcf86;
}

a.badge-warning:hover,
a.badge-warning:focus {
  color: var(--gray-900);
  background-color: #ffcf86;
}

.badge-purple {
  color: #fff;
  background-color: #7891ef;
}

a.badge-purple:hover {
  color: #fff;
  background-color: #7891ef;
}

a.badge-purple:focus {
  color: #fff;
  background-color: #7891ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.badge-teal {
  color: #fff;
  background-color: #4ac9bd;
}

a.badge-teal:hover,
a.badge-teal:focus {
  color: var(--gray-900);
  background-color: #4ac9bd;
}

.badge-danger {
  color: #fff;
  background-color: #d6573c;
}

a.badge-danger:hover {
  color: #fff;
  background-color: #d6573c;
}

a.badge-danger:focus {
  color: #fff;
  background-color: #d6573c;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.badge-pink {
  color: #fff;
  background-color: #f754fa;
}

a.badge-pink:hover {
  color: #fff;
  background-color: #f754fa;
}

a.badge-pink:focus {
  color: #fff;
  background-color: #f754fa;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 84, 250, 0.5);
}

a.badge-pink.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(247, 84, 250, 0.5);
}

.badge-purple {
  color: #fff;
  background-color: #7891ef;
}

a.badge-purple:hover {
  color: #fff;
  background-color: #7891ef;
}

a.badge-purple:focus {
  color: #fff;
  background-color: #7891ef;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

a.badge-purple.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.bg-light {
  color: var(--gray-900);
  background-color: var(--gray-100);
}

a.badge-light:hover {
  color: var(--gray-900);
  background-color: var(--bg-hover);
}

a.badge-light:focus {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(244, 245, 248, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: var(--gray-800);
}

a.badge-dark:hover {
  color: #fff;
  background-color: var(--dark);
}

a.badge-dark:focus {
  color: #fff;
  background-color: var(--dark);
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

@media print {
  .badge {
    border: 1px solid var(--black);
  }
}

.badge.badge-orange {
  color: #fff;
  background-color: #ffb67b;
}

.badge.badge-cyan {
  color: #fff;
  background-color: #7891ef;
}

.badge.bg-white {
  color: var(--color) !important;
}

.btn-outline-primary:hover .bg-primary {
  background: #fff !important;
  color: var(--primary-bg-color);
}

.btn-outline-success:hover .bg-success {
  background: #fff !important;
  color: #43ce85;
}

.btn-outline-secondary:hover .bg-secondary {
  background: #fff !important;
  color: var(--secondary);
}

.btn-outline-info:hover .bg-info {
  background: #fff !important;
  color: #4ec2f0;
}

.breadcrumb-header {
  display: flex;
  margin-block-start: 20px;
  margin-block-end: 20px;
  width: 100%;
}

.breadcrumb-header .breadcrumb {
  background: transparent !important;
  padding: 0;
  margin-block-end: 0;
  font-size: 13px;
}

.breadcrumb-center ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 7px;
}

.breadcrumb-center li {
  display: contents;
  text-align: center;
}

.breadcrumb-end ol {
  position: relative;
  display: block;
  width: 100%;
  text-align: end;
}

.breadcrumb-end li {
  display: contents;
  text-align: end;
}

@media (max-width: 633px) {
  .breadcrumb-header {
    display: block;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-block-end: 1rem;
  list-style: none;
  border-radius: 7px;
}

.breadcrumb-item a {
  color: var(--muted);
}

.breadcrumb-item+.breadcrumb-item::before {
  display: inline-block;
}

.breadcrumb-item+.breadcrumb-item:hover::before {
  text-decoration: underline;
  text-decoration: none;
}

.breadcrumb-item.active {
  color: var(--primary-bg-color);
}

.breadcrumb-style1 .breadcrumb-item a:hover,
.breadcrumb-style1 .breadcrumb-item a:focus {
  color: var(--primary-bg-color);
}

.main-content-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--gray-500);
  margin-block-end: 10px;
}

.main-content-breadcrumb span {
  position: relative;
}

.main-content-breadcrumb span+span::before {
  content: "\f3d1";
  font-family: "Ionicons";
  font-size: 12px;
  font-weight: 300;
  display: inline;
  margin: 0 5px;
  color: var(--gray-400);
  line-height: 0;
  position: relative;
  inset-block-start: 1px;
}

.main-content-breadcrumb span:last-child {
  color: var(--gray-700);
}

.main-breadcrumbs {
  padding: 0;
  background-color: transparent;
  margin-block-end: 0;
}

.main-breadcrumbs .breadcrumb-item {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 700;
}

.main-breadcrumbs .breadcrumb-item a {
  color: var(--color);
  transition: all 0.2s ease-in-out;
}

.main-breadcrumbs .breadcrumb-item a:hover {
  color: var(--gray-500);
}

.main-breadcrumbs .breadcrumb-item.active {
  color: var(--primary-bg-color);
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item {
  padding-inline-start: 8px;
}

.main-breadcrumbs .breadcrumb-item+.breadcrumb-item::before {
  color: var(--gray-500);
  padding-inline-end: 8px;
  font-weight: 400;
}

@media (prefers-reduced-motion: reduce) {
  .main-breadcrumbs .breadcrumb-item a {
    transition: none;
  }
}

.breadcrumb-style3 .breadcrumb-item+.breadcrumb-item::before {
  float: none;
  padding-inline-end: 0.5rem;
  color: #d6dfe7;
  content: var(--bs-breadcrumb-divider, "\e933");
  font-family: "feather" !important;
}

.breadcrumb-style3 .breadcrumb-item a {
  margin-inline-end: 5px;
}

.breadcrumb-style2 .breadcrumb-item+.breadcrumb-item::before {
  float: none;
  padding-inline-end: 0.5rem;
  color: #d6dfe7;
  content: var(--bs-breadcrumb-divider, "\f3d1");
  font-family: "Ionicons" !important;
}

.breadcrumb-style2 .breadcrumb-item a {
  margin-inline-end: 5px;
}

.breadcrumb-item+.breadcrumb-item {
  display: inline-block;
}

.btn,
.sp-container button {
  color: var(--color);
  font-size: 0.8rem;
  border-radius: 4px;
}

@media (prefers-reduced-motion: reduce) {

  .btn,
  .sp-container button {
    transition: none;
  }
}

.btn:hover,
.sp-container button:hover {
  color: var(--color);
  text-decoration: none;
}

.btn:focus,
.sp-container button:focus,
.btn.focus,
.sp-container button.focus {
  outline: 0;
}

.btn.disabled,
.sp-container button.disabled,
.btn:disabled,
.sp-container button:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-close {
  padding: 5px;
  line-height: 1;
  font-size: 24px;
  background-image: none;
}

.btn-close:focus {
  box-shadow: none !important;
}

.activate_btn {
  border: 1px solid var(--primary-bg-color);
  border-radius: 8px;
  max-width: 203px;
  margin: auto;
  text-align: center;
  padding: 8px;
}

.btn-primary {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-border);
  margin-top: 10px;
  font-weight: 600;
}

.btn-primary:hover {
  color: #000;
  background-color: var(--primary-bg-hover) !important;
  border-color: var(--primary-bg-border);
}

.btn-primary:focus,
.btn-primary.focus {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.btn-primary.disabled,
.btn-primary:disabled {
  color: #000;
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color) !important;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.show>.btn-primary.dropdown-toggle {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(119, 210, 191, 0.2);
}

.btn-secondary {
  color: #000 !important;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:hover {
  color: #000;
  background-color: var(--secondary-09) !important;
  border-color: var(--secondary);
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #000;
  background-color: var(--secondary-09) !important;
  border-color: var(--secondary);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #000;
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active {
  color: #000;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.show>.btn-secondary.dropdown-toggle {
  color: #000;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.btn-success {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.btn-success:hover {
  color: #fff;
  background-color: rgba(67, 206, 133, 0.9);
  border-color: rgba(67, 206, 133, 0.9);
}

.btn-success:focus,
.btn-success.focus {
  color: #fff;
  background-color: rgba(67, 206, 133, 0.9);
  border-color: rgba(67, 206, 133, 0.9);
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.show>.btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-info:hover {
  color: #fff;
  background-color: rgba(78, 194, 240, 0.9);
  border-color: #4ec2f0;
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: rgba(78, 194, 240, 0.9);
  border-color: #4ec2f0;
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-info.disabled,
.btn-info:disabled {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.show>.btn-info.dropdown-toggle {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.show>.btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-warning {
  color: #000 !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-warning:hover {
  color: #000;
  background-color: rgba(255, 207, 134, 0.9);
  border-color: rgba(255, 207, 134, 0.9);
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
  color: #000;
  background-color: rgba(255, 207, 134, 0.9);
  border-color: rgba(255, 207, 134, 0.9);
}

.btn-warning.disabled,
.btn-warning:disabled {
  color: #000;
  background-color: #ffcf86 !important;
  border-color: #ffcf86 !important;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active {
  color: #000;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.btn-danger {
  color: #fff !important;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-danger:hover {
  color: #fff;
  background-color: rgba(214, 87, 60, 0.9);
  border-color: rgba(214, 87, 60, 0.9);
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: rgba(214, 87, 60, 0.9);
  border-color: rgba(214, 87, 60, 0.9);
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.2);
}

.btn-danger.disabled,
.btn-danger:disabled {
  color: #fff;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #d6573c;
  border-color: #d6573c;
}

.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.btn-teal {
  color: #fff;
  background-color: #4ac9bd;
  border-color: #4ac9bd;
}

.btn-teal:hover {
  color: #fff;
  background-color: rgba(74, 201, 189, 0.9);
  border-color: rgba(74, 201, 189, 0.9);
}

.btn-teal:focus,
.btn-teal.focus {
  color: #fff;
  background-color: rgba(74, 201, 189, 0.9);
  border-color: rgba(74, 201, 189, 0.9);
  box-shadow: 0 0 0 0.2rem rgba(74, 201, 189, 0.5);
}

.btn-teal.disabled,
.btn-teal:disabled {
  color: #fff;
  background-color: #4ac9bd;
  border-color: #4ac9bd;
}

.btn-teal:not(:disabled):not(.disabled):active,
.btn-teal:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4ac9bd;
  border-color: #4ac9bd;
}

.show>.btn-teal.dropdown-toggle {
  color: #fff;
  background-color: #4ac9bd;
  border-color: #4ac9bd;
}

.btn-teal:not(:disabled):not(.disabled):active:focus,
.btn-teal:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 201, 189, 0.5);
}

.show>.btn-teal.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(74, 201, 189, 0.5);
}

.btn-white {
  color: var(--gray-900);
  background-color: #fff;
  border-color: var(--border);
}

.btn-white:hover {
  color: var(--gray-900);
  background-color: #fff;
  border-color: #d5d9e4;
}

.btn-white:focus,
.btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(212, 214, 220, 0.5);
  background-color: #fff !important;
  border-color: #ededf5 !important;
}

.btn-white.disabled,
.btn-white:disabled {
  color: var(--gray-900);
  background-color: #fff;
  border-color: var(--gray-100);
}

.btn-white:not(:disabled):not(.disabled):active,
.btn-white:not(:disabled):not(.disabled).active {
  color: var(--gray-900);
  background-color: #fff;
  border-color: #cdd2df;
}

.btn-light {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.btn-light:hover {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.btn-light.disabled,
.btn-light:disabled {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.show>.btn-light.dropdown-toggle {
  color: var(--gray-900);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

.btn-dark {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:hover {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-dark.disabled,
.btn-dark:disabled {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
}

.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: var(--dark);
  border-color: var(--dark);
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(88, 99, 122, 0.5);
}

.btn-outline-primary {
  color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color);
}

.btn-outline-primary:hover {
  color: #000 !important;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: var(--gray-600);
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05) !important;
}

.show>.btn-outline-primary.dropdown-toggle {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.show>.btn-outline-primary.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05) !important;
}

.btn-outline-secondary {
  color: var(--secondary) !important;
  border-color: var(--secondary);
}

.btn-outline-secondary:hover {
  color: #000 !important;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: var(--gray-600);
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #000 !important;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05) !important;
}

.show>.btn-outline-secondary.dropdown-toggle {
  color: #000;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.show>.btn-outline-secondary.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.btn-check:active+.btn-outline-secondary,
.btn-check:checked+.btn-outline-secondary,
.btn-outline-secondary.active,
.btn-outline-secondary.dropdown-toggle.show,
.btn-outline-secondary:active {
  color: #000 !important;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.btn-check:active+.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary:focus,
.btn-outline-secondary.active:focus,
.btn-outline-secondary.dropdown-toggle.show:focus,
.btn-outline-secondary:active:focus {
  box-shadow: none;
}

.btn-outline-success {
  color: #43ce85;
  border-color: #43ce85;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #43ce85;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #43ce85;
  border-color: #43ce85;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.show>.btn-outline-success.dropdown-toggle {
  color: #fff !important;
  background-color: #43ce85;
  border-color: #43ce85;
}

.show>.btn-outline-success.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.btn-check:active+.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success:focus,
.btn-outline-success.active:focus,
.btn-outline-success.dropdown-toggle.show:focus,
.btn-outline-success:active:focus {
  box-shadow: none;
}

.btn-outline-info {
  color: #4ec2f0 !important;
  border-color: #4ec2f0;
}

.btn-outline-info:hover {
  color: #fff !important;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #4ec2f0;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.show>.btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.show>.btn-outline-info.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-check:active+.btn-outline-info:focus,
.btn-check:checked+.btn-outline-info:focus,
.btn-outline-info.active:focus,
.btn-outline-info.dropdown-toggle.show:focus,
.btn-outline-info:active:focus {
  box-shadow: none;
}

.btn-outline-warning {
  color: #ffcf86 !important;
  border-color: #ffcf86;
}

.btn-outline-warning:hover {
  color: #000 !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffcf86;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active {
  color: #000 !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.show>.btn-outline-warning.dropdown-toggle {
  color: var(--gray-900);
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.show>.btn-outline-warning.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #000 !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-check:active+.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning:focus,
.btn-outline-warning.active:focus,
.btn-outline-warning.dropdown-toggle.show:focus,
.btn-outline-warning:active:focus {
  box-shadow: none;
}

.btn-outline-danger {
  color: #d6573c !important;
  border-color: #d6573c;
}

.btn-outline-danger:hover {
  color: #fff !important;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #d6573c;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.show>.btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d6573c;
  border-color: #d6573c;
}

.show>.btn-outline-danger.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.btn-check:active+.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger:focus,
.btn-outline-danger.active:focus,
.btn-outline-danger.dropdown-toggle.show:focus,
.btn-outline-danger:active:focus {
  box-shadow: none;
}

.btn-outline-light {
  color: var(--gray-900);
  border-color: var(--border);
}

.btn-outline-light:hover {
  color: var(--gray-900);
  background-color: var(--border);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: var(--gray-500);
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
  color: var(--gray-900);
  background-color: var(--gray-100);
  border-color: var(--gray-100);
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

.show>.btn-outline-light.dropdown-toggle {
  color: var(--gray-900);
  background-color: var(--gray-100);
  border-color: var(--gray-100);
}

.show>.btn-outline-light.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color);
}

.btn-check:active+.btn-outline-light:focus,
.btn-check:checked+.btn-outline-light:focus,
.btn-outline-light.active:focus,
.btn-outline-light.dropdown-toggle.show:focus,
.btn-outline-light:active:focus {
  box-shadow: none;
}

.btn-outline-dark {
  color: var(--gray-800);
  border-color: var(--gray-800);
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: var(--gray-800);
  border-color: var(--gray-800);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: var(--gray-800);
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: var(--gray-800);
  border-color: var(--gray-800);
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.show>.btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: var(--gray-800);
  border-color: var(--gray-800);
}

.show>.btn-outline-dark.dropdown-toggle:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-check:active+.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark:focus,
.btn-outline-dark.active:focus,
.btn-outline-dark.dropdown-toggle.show:focus,
.btn-outline-dark:active:focus {
  box-shadow: none;
}

.btn-link {
  font-weight: 400;
  color: var(--primary-bg-color);
  text-decoration: none;
}

.btn-link:hover {
  color: #8485fb;
  text-decoration: none;
}

.btn-link:focus,
.btn-link.focus {
  text-decoration: none;
  box-shadow: none;
}

.btn-link:disabled,
.btn-link.disabled {
  color: var(--gray-600);
  pointer-events: none;
}

.btn-lg,
.btn-group-lg>.btn,
.sp-container .btn-group-lg>button {
  padding: 0.5rem 1rem !important;
  font-size: 1.02375rem !important;
  line-height: 1.5;
  height: calc(1.5em + 1rem + 2px);
}

.btn-sm,
.btn-group-sm>.btn,
.sp-container .btn-group-sm>button {
  font-size: 0.785rem !important;
  padding: 0.25rem 0.5rem !important;
}

.btn-def {
  border-radius: 0.25rem !important;
  line-height: 1.1;
  border: 0px;
}

.btn-def.def-white:hover {
  background: var(--bg-hover);
}

.btn-def:hover {
  background: var(--bg-hover);
  border: 0px;
}

.btn-def:hover i,
.btn-def:hover svg {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

.btn-def:focus {
  border: 0px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-block-start: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.btn-toolbar .input-group {
  width: auto;
}

.btn:active,
.sp-container button:active,
.btn:focus,
.sp-container button:focus {
  box-shadow: none;
}

.show>.btn-light.dropdown-toggle {
  color: var(--gray-900);
  background-color: var(--border);
  border-color: var(--border);
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem #f0eff4;
}

.show>.btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #f0eff4;
}

.btn-indigo {
  color: #fff;
  background-color: #b057bf;
  border-color: #b057bf;
}

.btn-indigo:hover {
  color: #fff;
  background-color: #d556b7;
  border-color: #d556b7;
}

.btn-indigo:focus,
.btn-indigo.focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.btn-indigo.disabled,
.btn-indigo:disabled {
  color: #fff;
  background-color: #b057bf;
  border-color: #b057bf;
}

.btn-indigo:not(:disabled):not(.disabled):active,
.btn-indigo:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #b057bf;
  border-color: #b057bf;
}

.show>.btn-indigo.dropdown-toggle {
  color: #fff;
  background-color: #b057bf;
  border-color: #b057bf;
}

.btn-indigo:not(:disabled):not(.disabled):active:focus,
.btn-indigo:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.show>.btn-indigo.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(172, 80, 187, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #7891ef;
  border-color: #7891ef;
}

.btn-purple:hover {
  color: #fff;
  background-color: rgba(120, 145, 239, 0.9);
  border-color: rgba(120, 145, 239, 0.9);
}

.btn-purple:focus,
.btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.btn-purple.disabled,
.btn-purple:disabled {
  color: #fff;
  background-color: #7891ef;
  border-color: #7891ef;
}

.btn-purple:not(:disabled):not(.disabled):active,
.btn-purple:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #7891ef;
  border-color: #7891ef;
}

.show>.btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #7891ef;
  border-color: #7891ef;
}

.btn-purple:not(:disabled):not(.disabled):active:focus,
.btn-purple:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.show>.btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.btn-pink {
  color: #fff;
  background-color: #f754fa;
  border-color: #cc30ce;
}

.btn-pink:hover {
  color: #fff;
  background-color: #cc30ce;
  border-color: #cc30ce;
}

.btn-pink:focus,
.btn-pink.focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-pink.disabled,
.btn-pink:disabled {
  color: #fff;
  background-color: #f754fa;
  border-color: #d80069;
}

.btn-pink:not(:disabled):not(.disabled):active,
.btn-pink:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #be005c;
  border-color: #98004a;
}

.show>.btn-pink.dropdown-toggle {
  color: #fff;
  background-color: #be005c;
  border-color: #98004a;
}

.btn-pink:not(:disabled):not(.disabled):active:focus,
.btn-pink:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.show>.btn-pink.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(221, 38, 127, 0.5);
}

.btn-gray-500 {
  color: var(--gray-900);
  background-color: var(--gray-500);
  border-color: #8896af;
}

.btn-gray-500:hover {
  color: #fff;
  background-color: #808faa;
  border-color: #697b9a;
}

.btn-gray-500:focus,
.btn-gray-500.focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-500.disabled,
.btn-gray-500:disabled {
  color: var(--gray-900);
  background-color: var(--gray-500);
  border-color: #8896af;
}

.btn-gray-500:not(:disabled):not(.disabled):active,
.btn-gray-500:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #7988a4;
  border-color: #637493;
}

.show>.btn-gray-500.dropdown-toggle {
  color: #fff;
  background-color: #7988a4;
  border-color: #637493;
}

.btn-gray-500:not(:disabled):not(.disabled):active:focus,
.btn-gray-500:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.show>.btn-gray-500.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 133, 158, 0.5);
}

.btn-gray-700 {
  color: #fff;
  background-color: var(--gray-700);
  border-color: #4f5c73;
}

.btn-gray-700:hover {
  color: #fff;
  background-color: #49566b;
  border-color: #3a4455;
}

.btn-gray-700:focus,
.btn-gray-700.focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.btn-gray-700.disabled,
.btn-gray-700:disabled {
  color: #fff;
  background-color: var(--gray-700);
  border-color: #4f5c73;
}

.btn-gray-700:not(:disabled):not(.disabled):active,
.btn-gray-700:not(:disabled):not(.disabled).active {
  color: #fff;
  background-color: #445064;
  border-color: #353e4d;
}

.show>.btn-gray-700.dropdown-toggle {
  color: #fff;
  background-color: #445064;
  border-color: #353e4d;
}

.btn-gray-700:not(:disabled):not(.disabled):active:focus,
.btn-gray-700:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

.show>.btn-gray-700.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(105, 116, 136, 0.5);
}

/* ###### 5.1 Buttons  ###### */
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem var(--primary02);
}

.btn-rounded {
  border-radius: 50px;
}

.btn-icon {
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}

.btn-icon i {
  line-height: 0;
  font-size: 1rem;
}

.btn-icon i.typcn {
  line-height: 1.95;
}

.btn-icon-list {
  display: flex;
  align-items: center;
}

.btn-icon-list .btn+.btn,
.btn-icon-list .sp-container button+.btn {
  margin-inline-start: 5px;
}

.sp-container .btn-icon-list button+.btn,
.btn-icon-list .sp-container .btn+button,
.sp-container .btn-icon-list .btn+button,
.btn-icon-list .sp-container button+button,
.sp-container .btn-icon-list button+button {
  margin-inline-start: 5px;
}

@media (max-width: 991px) {
  .btn.btn-default.nav-link {
    margin-block-start: 0;
  }
}

.nav-link#bs-example-navbar-collapse-1 {
  padding: 0rem 0.3rem;
}

.btn-primary-gradient {
  color: #fff;
  background: linear-gradient(to right,
      var(--primary-bg-color) 0%,
      #43ce85 100%) !important;
  border: 1px solid var(--primary-bg-color);
}

.btn-primary-gradient:hover {
  color: #fff;
}

.btn-primary-gradient:not(:disabled):not(.disabled):active:focus,
.btn-primary-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-secondary-gradient {
  color: #000;
  background: linear-gradient(to right,
      var(--secondary) 0%,
      var(--secondary-05) 100%);
  border: 1px solid var(--secondary);
}

.btn-secondary-gradient:hover {
  color: #000 !important;
}

.btn-secondary-gradient:not(:disabled):not(.disabled):active:focus,
.btn-secondary-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.btn-danger-gradient {
  color: #fff;
  background: linear-gradient(to right,
      #d6573c 0%,
      rgba(214, 87, 60, 0.5) 100%);
  border: 1px solid #d6573c;
}

.btn-danger-gradient:hover {
  color: #fff !important;
}

.btn-danger-gradient:not(:disabled):not(.disabled):active:focus,
.btn-danger-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.btn-pink-gradient {
  color: #fff;
  background: linear-gradient(to right,
      #f754fa 0%,
      rgba(247, 84, 250, 0.5) 100%);
  border: 1px solid #f754fa;
}

.btn-pink-gradient:hover {
  color: #fff !important;
}

.btn-pink-gradient:not(:disabled):not(.disabled):active:focus,
.btn-pink-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(247, 84, 250, 0.5);
}

.btn-purple-gradient {
  color: #fff;
  background: linear-gradient(to right,
      #7891ef 0%,
      rgba(120, 145, 239, 0.5) 100%);
  border: 1px solid #7891ef;
}

.btn-purple-gradient:hover {
  color: #fff !important;
}

.btn-purple-gradient:not(:disabled):not(.disabled):active:focus,
.btn-purple-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(120, 145, 239, 0.5);
}

.btn-warning-gradient {
  color: #000;
  background: linear-gradient(to right,
      #ffcf86 0%,
      rgba(255, 207, 134, 0.5) 100%);
  border: 1px solid #ffcf86;
}

.btn-warning-gradient:hover {
  color: #000 !important;
}

.btn-warning-gradient:not(:disabled):not(.disabled):active:focus,
.btn-warning-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.btn-success-gradient {
  color: #fff;
  background: linear-gradient(to right,
      #43ce85 0%,
      rgba(67, 206, 133, 0.5) 100%);
  border: 1px solid #43ce85;
}

.btn-success-gradient:hover {
  color: #fff !important;
}

.btn-success-gradient:not(:disabled):not(.disabled):active:focus,
.btn-success-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.btn-info-gradient {
  color: #fff;
  background: linear-gradient(to right,
      #4ec2f0 0%,
      rgba(78, 194, 240, 0.5) 100%);
  border: 1px solid #4ec2f0;
}

.btn-info-gradient:hover {
  color: #fff !important;
}

.btn-info-gradient:not(:disabled):not(.disabled):active:focus,
.btn-info-gradient:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-light-gradient {
  color: var(--black);
  background: linear-gradient(120deg, #cdd2de 0%, var(--gray-100) 100%);
  border: 1px solid #cdd2de;
}

.btn-light-gradient:hover {
  color: var(--black);
  background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
  border-color: #cdd2de;
}

.btn-light-gradient:focus,
.btn-light-gradient.focus {
  background: linear-gradient(120deg, #c1c7d6 0%, #e0e5f1 100%);
}

.btn-dark-gradient {
  color: #fff;
  background: linear-gradient(60deg, #29323c 0%, #485563 100%);
  border: 1px solid #29323c;
}

.btn-dark-gradient:hover {
  color: #fff;
  background: linear-gradient(60deg, #212931 0%, #424f5d 100%);
  border-color: #29323c;
}

.btn-dark-gradient:focus,
.btn-dark-gradient.focus {
  background: linear-gradient(60deg, #212931 0%, #424f5d 100%);
}

.btn-square {
  border-radius: 0;
}

.btn-list>.btn:not(:last-child),
.btn-list>.dropdown:not(:last-child) {
  margin-inline-end: 0.4rem;
}

.btn-list>.btn,
.btn-list>.dropdown {
  margin-block-end: 0.3rem;
}

.btn-outline-light:hover {
  border-color: var(--border);
}

.btn-check:focus+.btn-secondary {
  color: #000;
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.nav.panel-tabs .nav-item:focus-visible {
  outline: none !important;
}

.tabs-style-1 .main-nav-line .nav-link.active {
  border: 1px solid;
  border-color: var(--border) var(--border) #fff;
}

.btn-animation .btn-loaders:after {
  content: "";
  animation: loader 500ms infinite linear;
  border: 2px solid #fff;
  border-radius: 50%;
  border-inline-end-color: transparent !important;
  border-block-start-color: transparent !important;
  display: block;
  height: 1.4em;
  width: 1.4em;
  position: absolute;
  inset-inline-end: 10px !important;
  inset-block-start: calc(50% - 0.7em);
  transform-origin: center;
  position: absolute !important;
}

.btn-animation .btn-spiner:after {
  content: "";
  animation: sk-bounce 1.2s infinite ease-in-out;
  animation-delay: -1s;
  border-radius: 50%;
  border-inline-end-color: transparent !important;
  border-block-start-color: transparent !important;
  display: block;
  height: 0.5em;
  width: 0.5em;
  position: absolute;
  inset-inline-end: 12px !important;
  inset-block-start: calc(50% - 0.7em);
  transform-origin: center;
  position: absolute !important;
  background: #fff;
  width: 14%;
  height: 45%;
}

.btn-animation .btn-loaders {
  pointer-events: none;
  position: relative;
}

.btn-animation .btn-loaders.btn-primary,
.btn-animation .btn-loaders.btn-secondary {
  color: #000 !important;
}

.btn-animation .btn-spiner {
  pointer-events: none;
  position: relative;
}

.btn-animation .btn-spiner.btn-primary,
.btn-animation .btn-spiner.btn-secondary {
  color: #000 !important;
}

.btn-animation .loading {
  margin-inline-end: 30px;
}

@keyframes loader {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.btn-primary-light {
  color: var(--primary-bg-color);
  background-color: var(--primary02);
  border-color: var(--primary01);
}

.btn-primary-light:hover {
  color: #fff;
  background-color: var(--primary05) !important;
  border-color: var(--primary05);
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus,
.btn-primary-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05);
}

.btn-secondary-light {
  color: #000;
  background-color: var(--secondary-02);
  border-color: var(--secondary-02);
}

.btn-secondary-light:hover {
  color: #000;
  background-color: var(--secondary) !important;
  border-color: var(--secondary);
}

.btn-secondary-light:not(:disabled):not(.disabled):active:focus,
.btn-secondary-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem var(--secondary-05);
}

.btn-success-light {
  color: #1a9c86;
  background-color: rgba(26, 156, 134, 0.2);
  border-color: rgba(26, 156, 134, 0.2);
}

.btn-success-light:hover {
  color: #fff;
  background-color: #43ce85 !important;
  border-color: #43ce85;
}

.btn-success-light:not(:disabled):not(.disabled):active:focus,
.btn-success-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.5);
}

.btn-info-light {
  color: #4ec2f0;
  background-color: rgba(78, 194, 240, 0.2);
  border-color: rgba(78, 194, 240, 0.2);
}

.btn-info-light:hover {
  color: #fff !important;
  background-color: #4ec2f0 !important;
  border-color: #4ec2f0;
}

.btn-info-light:not(:disabled):not(.disabled):active:focus,
.btn-info-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(78, 194, 240, 0.5);
}

.btn-warning-light {
  color: #000;
  background-color: rgba(253, 172, 65, 0.2);
  border-color: rgba(253, 172, 65, 0.2);
}

.btn-warning-light:hover {
  color: #000 !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-warning-light:not(:disabled):not(.disabled):active:focus,
.btn-warning-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 207, 134, 0.5);
}

.btn-danger-light {
  color: #d6573c !important;
  background-color: rgba(214, 87, 60, 0.2);
  border-color: rgba(214, 87, 60, 0.2);
}

.btn-danger-light:hover {
  color: #fff !important;
  background-color: #d6573c !important;
  border-color: #d6573c;
}

.btn-danger-light:not(:disabled):not(.disabled):active:focus,
.btn-danger-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.5);
}

.btn-dark-light {
  color: #000;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-dark-light:hover {
  color: #fff !important;
  background-color: #000 !important;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-dark-light:not(:disabled):not(.disabled):active:focus,
.btn-dark-light:not(:disabled):not(.disabled).active:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.5);
}

.btn-check:active+.btn-outline-primary,
.btn-check:checked+.btn-outline-primary,
.btn-outline-primary.active,
.btn-outline-primary.dropdown-toggle.show,
.btn-outline-primary:active {
  color: #000 !important;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.btn-check:active+.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary:focus,
.btn-outline-primary.active:focus,
.btn-outline-primary.dropdown-toggle.show:focus,
.btn-outline-primary:active:focus {
  box-shadow: 0 0 0 0.2rem var(--primary05) !important;
}

.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus {
  box-shadow: none !important;
}

.btn-check:active+.btn-outline-warning,
.btn-check:checked+.btn-outline-warning,
.btn-outline-warning.active,
.btn-outline-warning.dropdown-toggle.show,
.btn-outline-warning:active {
  color: #fff !important;
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.btn-check:active+.btn-outline-info,
.btn-check:checked+.btn-outline-info,
.btn-outline-info.active,
.btn-outline-info.dropdown-toggle.show,
.btn-outline-info:active {
  color: #fff !important;
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.btn-check:active+.btn-outline-danger,
.btn-check:checked+.btn-outline-danger,
.btn-outline-danger.active,
.btn-outline-danger.dropdown-toggle.show,
.btn-outline-danger:active {
  color: #fff !important;
  background-color: #d6573c;
  border-color: #d6573c;
}

.btn-circle {
  height: 30px;
  width: 30px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn-circle-sm {
  height: 25px;
  width: 25px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn-circle-xs {
  height: 20px;
  width: 20px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn-circle-lg {
  height: 35px;
  width: 35px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn-circle-xl {
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn-circle-xxl {
  height: 50px;
  width: 50px;
  border-radius: 50% !important;
  padding: 0px !important;
}

.btn.split-dropdown {
  opacity: 0.8;
}

.btn-group-vertical>.btn-check:checked+.btn,
.btn-group-vertical>.btn-check:focus+.btn,
.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn-check:checked+.btn,
.btn-group>.btn-check:focus+.btn,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 0;
}

.btn-list .button-icon i {
  display: inline-block;
}

.btn-def.white {
  color: #fff !important;
}

.nft_theme .card-group {
  margin-block-end: 1.5rem;
  box-shadow: var(--shadow);
  border-radius: 4px;
}

.nft_theme .card-group>.card {
  box-shadow: none;
}

.nft_theme .card-group>.card:not(:last-child) {
  border-inline-end: 1px dotted var(--border);
}

.nft_theme .card {
  border-radius: 5px;
  margin-block-end: 0.5rem;
  box-shadow: inset 0px -1px 12px 3px rgba(255, 255, 255, 0.05);
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  content: "";
  margin-top: 6px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
}

.nft_theme .card:before {
  position: absolute;
  content: "";
  width: 80%;
  height: 1px;
  top: 0;
  background: linear-gradient(45deg,
      transparent,
      var(--primary03),
      var(--primary06),
      var(--primary06),
      var(--primary03),
      transparent);
  inset-inline-start: 10%;
  box-shadow: 0 8px 17px 0px var(--primary-bg-color);
}

.nft_theme .card>hr {
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.nft_theme .card>.list-group:first-child .list-group-item:first-child {
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;
}

.nft_theme .card>.list-group:last-child .list-group-item:last-child {
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
}

.nft_theme .card>.card-body:first-child {
  border-start-start-radius: 4px !important;
  border-start-end-radius: 4px !important;
  border-end-end-radius: 0 !important;
  border-end-start-radius: 0 !important;
}

.nft_theme .card>.card-body:first-child:last-child {
  border-radius: 4px !important;
}

.nft_theme .card>.card-body:last-child {
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 4px !important;
  border-end-start-radius: 4px !important;
}

.nft_theme .card>.card-body:not(:first-child):not(:last-child) {
  border-radius: 0px;
}

.nft_theme .card.card-bg-primary {
  background-color: var(--primary-bg-color);
  color: #000;
}

.nft_theme .card.card-bg-primary>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-primary .card-title {
  color: #000;
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-primary .card-subtitle {
  color: #000;
  opacity: 0.758;
}

.nft_theme .card.card-bg-secondary {
  background-color: var(--secondary);
  color: rgb(var(--background-rgb));
}

.nft_theme .card.card-bg-secondary>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-secondary .card-title {
  color: rgb(var(--background-rgb));
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-secondary .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-info {
  background-color: #4ec2f0;
  color: rgb(var(--background-rgb));
}

.nft_theme .card.card-bg-info>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-info .card-title {
  color: rgb(var(--background-rgb));
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-info .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-success {
  background-color: #43ce85;
  color: rgb(var(--background-rgb));
}

.nft_theme .card.card-bg-success>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-success .card-title {
  color: rgb(var(--background-rgb));
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-success .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-warning {
  background-color: #ffcf86;
  color: rgb(var(--background-rgb));
}

.nft_theme .card.card-bg-warning>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-warning .card-title {
  color: rgb(var(--background-rgb));
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-warning .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-danger {
  background-color: #d6573c;
  color: rgb(var(--background-rgb));
}

.nft_theme .card.card-bg-danger>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-danger .card-title {
  color: rgb(var(--background-rgb));
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-danger .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-dark {
  background-color: var(--dark);
  color: #fff;
}

.nft_theme .card.card-bg-dark>.card-footer {
  border-block-start-color: var(--border);
}

.nft_theme .card.card-bg-dark .card-title {
  color: #fff;
  border-block-end-color: var(--border);
}

.nft_theme .card.card-bg-dark .card-subtitle {
  color: rgb(var(--background-rgb));
  opacity: 0.758;
}

.nft_theme .card.card-bg-light {
  background-color: var(--gray-200);
  color: var(--dark);
}

.nft_theme .card.card-bg-light>.card-footer {
  border-block-start-color: rgba(0, 0, 0, 0.15);
}

.nft_theme .card.card-bg-light .card-title {
  color: var(--dark);
  border-block-end-color: rgba(0, 0, 0, 0.18);
}

.nft_theme .card.card-bg-light .card-subtitle {
  opacity: 0.758;
}

.nft_theme .card.card-primary {
  border-block-start: 2px solid var(--primary-bg-color) !important;
}

.nft_theme .card.card-primary .card-title {
  color: var(--primary-bg-color);
}

.nft_theme .card.card-secondary {
  border-block-start: 2px solid var(--secondary) !important;
}

.nft_theme .card.card-secondary .card-title {
  color: var(--secondary);
}

.nft_theme .card.card-success {
  border-block-start: 2px solid #43ce85 !important;
}

.nft_theme .card.card-success .card-title {
  color: #43ce85;
}

.nft_theme .card.card-danger {
  border-block-start: 2px solid #d6573c !important;
}

.nft_theme .card.card-danger .card-title {
  color: #d6573c;
}

.nft_theme .card.card-warning {
  border-block-start: 2px solid #ffcf86 !important;
}

.nft_theme .card.card-warning .card-title {
  color: #ffcf86;
}

.nft_theme .card.card-info {
  border-block-start: 2px solid #4ec2f0 !important;
}

.card.card-info .card-title {
  color: #4ec2f0;
}

.nft_theme .card.card-dark {
  border-block-start: 2px solid var(--dark) !important;
}

.nft_theme .card.card-dark .card-title {
  color: var(--dark);
}

.nft_theme .card.card-light {
  border-block-start: 2px solid var(--gray-400) !important;
}

.nft_theme .card.card-light .card-title {
  color: var(--gray-600);
}

.nft_theme .card-header {
  border-block-end: 0;
  margin-block-end: 0;
  padding: 1rem 1.25rem;
  border-start-start-radius: 4px !important;
  border-start-end-radius: 4px !important;
  border-block-end: 1px dashed var(--primary01);
  text-transform: uppercase;
}

.nft_theme .card-header>.card-title {
  margin-block-end: 0px;
}

.nft_theme .card-header>.card-title~.card-subtitle {
  margin-block-end: 0px;
  margin-block-start: 0.4rem;
}

.nft_theme .card-header:first-child {
  border-radius: 0;
}

.card-header:first-child:last-child {
  border-radius: 4px;
}

.nft_theme .card-body {
  padding: 1.25rem;
}

.nft_theme .card-subtitle {
  margin-block-start: -0.575rem;
  margin-block-end: 1rem;
  color: var(--muted);
}

.nft_theme .card-footer {
  position: relative;
  padding: 1.25rem 1.25rem;
  background-color: transparent;
  border-block-start: 1px solid var(--border);
}

.nft_theme .card-footer:last-child {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
}

.nft_theme .card-collapsed> :not(.card-header):not(.card-status) {
  display: none;
}

.nft_theme .card-collapsed>.card-header {
  border-radius: 4px;
  padding-block-end: 1.25rem;
}

.nft_theme .card-link:hover {
  text-decoration: none;
}

.nft_theme .card-link+.card-link {
  margin-inline-start: 1.25rem;
}

.nft_theme .card-img-overlay {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  padding: 1.25rem;
  border-radius: 4px;
  overflow: auto;
}

.nft_theme .card-img {
  width: 100%;
  border-radius: 4px;
}

.nft_theme .card-img-top {
  width: 100%;
  border-start-start-radius: 4px;
  border-start-end-radius: 4px;
}

.nft_theme .card-img-bottom {
  width: 100%;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
}

.nft_theme .card-img-start {
  width: 100%;
  height: 100%;
  border-start-start-radius: 4px;
  border-end-start-radius: 4px;
}

.nft_theme .card-img-end {
  width: 100%;
  height: 100%;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
}

.nft_theme .card-title {
  font-weight: 500;
  font-size: 15px;
  color: var(--primary-bg-color);
}

.nft_theme .card-alert {
  padding: 1rem 1.25rem;
  font-size: 14px;
  margin-block-start: 1.25rem;
  border-width: 0px 0px 0px 1px;
  border-style: solid;
}

.nft_theme .card-alert.alert-primary {
  border-color: var(--primary-bg-color);
}

.nft_theme .card-alert.alert-secondary {
  border-color: var(--secondary);
}

.nft_theme .card-alert.alert-danger {
  border-color: #d6573c;
}

.nft_theme .card-alert.alert-success {
  border-color: #43ce85;
}

.nft_theme .card-alert.alert-warning {
  border-color: #ffcf86;
}

.nft_theme .card-alert.alert-info {
  border-color: #4ec2f0;
}

.nft_theme .card-alert.alert-dark {
  border-color: var(--dark);
}

.nft_theme .card-alert.alert-light {
  border-color: var(--gray-400);
}

.nft_theme .card-sigin {
  background: rgb(var(--background-rgb));
  padding: 50px;
  border-radius: 4px;
}

.nft_theme .square-box div:nth-child(1) {
  inset-block-start: 12%;
  inset-inline-start: 42%;
  animation: animate 10s linear infinite;
}

.nft_theme .square-box div {
  position: absolute;
  width: 50px;
  height: 50px;
  background: transparent;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-radius: 35px;
}

.nft_theme .square-box div:nth-child(2) {
  inset-block-start: 70%;
  inset-inline-start: 50%;
  animation: animate 7s linear infinite;
}

.nft_theme .square-box div:nth-child(4) {
  inset-block-start: 20%;
  inset-inline-start: 60%;
  animation: animate 10s linear infinite;
}

.nft_theme .square-box div:nth-child(3) {
  inset-block-start: 17%;
  inset-inline-start: 6%;
  animation: animate 9s linear infinite;
}

.nft_theme .square-box div:nth-child(5) {
  inset-block-start: 88%;
  inset-inline-start: 10%;
  animation: animate 6s linear infinite;
}

.nft_theme .square-box div:nth-child(6) {
  inset-block-start: 80%;
  inset-inline-start: 70%;
  animation: animate 12s linear infinite;
}

.nft_theme .square-box div:nth-child(7) {
  inset-block-start: 60%;
  inset-inline-start: 80%;
  animation: animate 15s linear infinite;
}

.nft_theme .square-box div:nth-child(8) {
  inset-block-start: 32%;
  inset-inline-start: 25%;
  animation: animate 16s linear infinite;
}

.nft_theme .square-box div:nth-child(9) {
  inset-block-start: 20%;
  inset-inline-start: 80%;
  animation: animate 10s linear infinite;
}

.nft_theme .square-box div:nth-child(10) {
  inset-block-start: 40%;
  inset-inline-start: 72%;
  animation: animate 5s linear infinite;
}

.nft_theme .square-box div:nth-child(11) {
  inset-block-start: 50%;
  inset-inline-start: 30%;
  animation: animate 6s linear infinite;
}

.nft_theme .square-box div:nth-child(12) {
  inset-block-start: 65%;
  inset-inline-start: 16%;
  animation: animate 9s linear infinite;
}

.nft_theme .square-box div:nth-child(13) {
  inset-block-start: 45%;
  inset-inline-start: 3%;
  animation: animate 14s linear infinite;
}

.nft_theme .square-box div:nth-child(14) {
  inset-block-start: 47%;
  inset-inline-start: 55%;
  animation: animate 3s linear infinite;
}

.nft_theme .square-box div:nth-child(15) {
  inset-block-start: 79%;
  inset-inline-start: 90%;
  animation: animate 8s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }

  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}

@media (max-width: 767px) {
  .card-sigin-main {
    padding: 25px;
  }
}

@media (max-width: 767px) {
  .card-sigin {
    padding: 25px;
  }
}

.card-fullscreen {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  z-index: 9999;
  margin: 0;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel .carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  border-radius: 5px;
}

.carousel .carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel .carousel-item {
  position: relative;
  display: none;
  float: var(--float-start);
  width: 100%;
  margin-inline-end: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel .carousel-item img {
  border-radius: 0px;
}

.carousel .carousel-item.active {
  display: block;
}

.carousel .carousel-item-next:not(.carousel-item-start),
.carousel .active.carousel-item-end {
  transform: translateX(100%);
}

.carousel .carousel-item-prev:not(.carousel-item-end),
.carousel .active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel .carousel-item-next,
.carousel .carousel-item-prev {
  display: block;
}

.carousel .carousel-control-next,
.carousel .carousel-control-prev {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 20px;
  inset-block-start: 50%;
  transform: translateY(-50%);
  opacity: 0.8;
  z-index: 0;
}

.carousel .carousel-control-next:hover,
.carousel .carousel-control-prev:hover {
  opacity: 1;
  color: rgb(var(--background-rgb));
  text-decoration: none;
  outline: 0;
}

.carousel .carousel-control-next:focus,
.carousel .carousel-control-prev:focus {
  opacity: 0.8;
  color: rgb(var(--background-rgb));
  text-decoration: none;
  outline: 0;
}

.carousel .carousel-control-next:focus:hover,
.carousel .carousel-control-prev:focus:hover {
  opacity: 1;
}

.carousel .carousel-control-next.custom,
.carousel .carousel-control-prev.custom {
  background-color: rgb(var(--background-rgb));
  color: var(--black);
  display: none;
  opacity: 0.5;
}

.carousel .carousel-control-next.custom:hover,
.carousel .carousel-control-prev.custom:hover {
  opacity: 0.7;
  color: var(--black);
}

.carousel .carousel-control-next.custom:focus,
.carousel .carousel-control-prev.custom:focus {
  opacity: 0.5;
  color: var(--black);
}

.carousel .carousel-control-next.custom:focus:hover,
.carousel .carousel-control-prev.custom:focus:hover {
  opacity: 0.7;
}

.carousel .carousel-control-next i,
.carousel .carousel-control-prev i {
  font-size: 40px;
  text-shadow: 0 0 1px black;
}

.carousel .carousel-control-next.custom i,
.carousel .carousel-control-prev.custom i {
  font-size: 20px;
}

.carousel .carousel-control-next {
  inset-inline-end: 2%;
}

.carousel .carousel-control-prev {
  inset-inline-start: 2%;
}

.carousel:hover .carousel-control-next.custom,
.carousel:hover .carousel-control-prev.custom {
  display: flex;
}

.carousel .carousel-indicators {
  z-index: 15;
}

.carousel .carousel-indicators .active {
  opacity: 1;
}

.carousel .carousel-indicators [data-bs-target] {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.carousel-fade .carousel-item {
  opacity: 0.65;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0.65;
}

@media (prefers-reduced-motion: reduce) {

  .carousel-item,
  .carousel-fade .active.carousel-item-left,
  .carousel-fade .active.carousel-item-right,
  .carousel-control-prev,
  .carousel-control-next,
  .carousel-indicators li {
    transition: none;
  }
}

html {
  font-family: "Poppins", sans-serif;
  line-height: 1.15;
  text-size-adjust: 100%;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

.nft_theme {
  margin: 0;
  font-family: "Rajdhani", sans-serif;
  font-size: 0.81568rem;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(255, 255, 255, 0.8);
  text-align: start;
  background: var(--main-gradient-bg);
  background-blend-mode: overlay;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  width: 100%;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0.5rem;
}

p {
  margin-block-start: 0;
  margin-block-end: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-block-end: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-block-end: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-block-start: 0;
  margin-block-end: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-block-end: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-block-end: 0.5rem;
  margin-inline-start: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  inset-block-end: -0.25em;
}

sup {
  inset-block-start: -0.5em;
}

a {
  color: var(--color);
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: var(--color);
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-block-start: 0;
  margin-block-end: 0rem;
}

figure {
  margin: 0 0 1rem;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
  color: var(--gray-600);
  text-align: start;
  caption-side: bottom;
}

.nft_theme th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-block-end: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none !important;
}

select {
  text-transform: none;
  word-wrap: normal;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-block-end: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

::-webkit-file-upload-button {
  font: inherit;
  appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-block-end: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.1875rem;
}

h2,
.h2 {
  font-size: 1.75rem;
}

h3,
.h3 {
  font-size: 1.53125rem;
}

h4,
.h4 {
  font-size: 1.3125rem;
}

h5,
.h5 {
  font-size: 1.09375rem;
}

h6,
.h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.09375rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-41,
.display-42,
.display-43,
.display-44 {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.2;
}

hr {
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  border: 0;
  border-block-start: 1px solid var(--black-1);
}

small,
.small {
  font-size: 17px;
  font-weight: 400;
  opacity: 0.6;
}

.list-unstyled,
.list-inline {
  padding-inline-start: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-inline-end: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-block-end: 1rem;
  font-size: 1.09375rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: var(--gray-600);
}

.blockquote-footer::before {
  content: "— ";
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-block-end: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: var(--gray-600);
}

code {
  font-size: 13px;
  color: var(--color);
  word-break: normal;
}

a>code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: rgb(var(--background-rgb));
  background-color: var(--gray-900);
  border-radius: 0.2rem;
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: var(--gray-900);
}

pre code {
  font-size: inherit;
  color: var(--primary-bg-color);
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-inline-end: -0.75rem;
  margin-inline-start: -0.75rem;
}

.no-gutters {
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.no-gutters>.col,
.no-gutters>[class*="col-"] {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.order-last {
  order: 13;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
    margin-inline-start: 3px;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.nft_theme .justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}

.float-start {
  float: var(--float-start) !important;
}

.float-end {
  float: var(--float-end) !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: var(--float-start) !important;
  }

  .float-sm-right {
    float: var(--float-end) !important;
  }

  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: var(--float-start) !important;
  }

  .float-md-right {
    float: var(--float-end) !important;
  }

  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: var(--float-start) !important;
  }

  .float-lg-right {
    float: var(--float-end) !important;
  }

  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: var(--float-start) !important;
  }

  .float-xl-right {
    float: var(--float-end) !important;
  }

  .float-xl-none {
    float: none !important;
  }
}

/*----- Typography ------*/
.heading-primary {
  background-color: var(--primary-bg-color);
  color: #fff;
  padding: 5px;
}

.heading-inverse {
  background-color: #000;
  color: #fff;
  padding: 5px;
}

.heading-secondary {
  background-color: var(--secondary);
  color: #000;
  padding: 5px;
}

.heading-success {
  background-color: #43ce85;
  color: #fff;
  padding: 5px;
}

.heading-info {
  background-color: #4ec2f0;
  color: #fff;
  padding: 5px;
}

.heading-warning {
  background-color: #ffcf86;
  color: #fff;
  padding: 5px;
}

.heading-danger {
  background-color: #d6573c;
  color: #fff;
  padding: 5px;
}

.tab-content>.active {
  display: block;
}

.tab-content>.tab-pane {
  display: none;
}

.z-99 {
  z-index: 99;
}

.z-999 {
  z-index: 999;
}

.z-9999 {
  z-index: 9999;
}

.z-99999 {
  z-index: 99999;
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-block-start: 0.3em solid;
  border-inline-end: 0.3em solid transparent;
  border-block-end: 0;
  border-inline-start: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-inline-start: 0;
}

.dropdown-menu {
  inset-block-start: 100%;
  z-index: 9;
  overflow: hidden;
  float: var(--float-start);
  padding: 0;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  color: var(--color);
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--primary02);
}

.dropdown-menu li {
  margin-block-end: 0 !important;
}

.dropdown-menu::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 1px;
  top: 0;
  background: linear-gradient(45deg,
      transparent,
      var(--primary03),
      var(--primary06),
      var(--primary06),
      var(--primary03),
      transparent);
  inset-inline-start: 10%;
  box-shadow: 0 8px 17px 0px var(--primary-bg-color);
}

.dropdown-menu-left {
  inset-inline-end: auto;
  inset-inline-start: 0;
}

.dropdown-menu-right {
  inset-inline-end: 0;
  inset-inline-start: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    inset-inline-end: auto;
    inset-inline-start: 0;
  }

  .dropdown-menu-sm-right {
    inset-inline-end: 0;
    inset-inline-start: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    inset-inline-end: auto;
    inset-inline-start: 0;
  }

  .dropdown-menu-md-right {
    inset-inline-end: 0;
    inset-inline-start: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    inset-inline-end: auto;
    inset-inline-start: 0;
  }

  .dropdown-menu-lg-right {
    inset-inline-end: 0;
    inset-inline-start: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    inset-inline-end: auto;
    inset-inline-start: 0;
  }

  .dropdown-menu-xl-right {
    inset-inline-end: 0;
    inset-inline-start: auto;
  }
}

.dropup .dropdown-menu {
  inset-block-start: auto;
  inset-block-end: 100%;
  margin-block-start: 0;
  margin-block-end: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-block-start: 0;
  border-inline-end: 0.3em solid transparent;
  border-block-end: 0.3em solid;
  border-inline-start: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-inline-start: 0;
}

.dropright .dropdown-menu {
  inset-block-start: 0;
  inset-inline-end: auto;
  inset-inline-start: 100%;
  margin-block-start: 0;
  margin-inline-start: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-block-start: 0.3em solid transparent;
  border-inline-end: 0;
  border-block-end: 0.3em solid transparent;
  border-inline-start: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-inline-start: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  inset-block-start: 0;
  inset-inline-end: 100%;
  inset-inline-start: auto;
  margin-block-start: 0;
  margin-inline-end: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-inline-start: 0.255em;
  vertical-align: 0.255em;
  content: "";
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-inline-end: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-block-start: 0.3em solid transparent;
  border-inline-end: 0.3em solid;
  border-block-end: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-inline-start: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[data-popper-placement^="top"],
.dropdown-menu[data-popper-placement^="right"],
.dropdown-menu[data-popper-placement^="bottom"],
.dropdown-menu[data-popper-placement^="left"] {
  inset-block-end: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-block-start: 1px solid var(--gray-200);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 15px;
  clear: both;
  font-size: 13px;
  font-weight: 400;
  color: var(--gray-900);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  transition: all ease 0.3s;
}

.dropdown-item:not(.active):not(:active):hover,
.dropdown-item:focus:not(.active):not(:active) {
  color: var(--black) !important;
  text-decoration: none;
  background-color: var(--bg-hover);
}

.dropdown-item.active,
.dropdown-item:active {
  color: var(--black);
  text-decoration: none;
  background-color: var(--bg-hover);
}

.dropdown-item.active:hover,
.dropdown-item:active:hover,
.dropdown-item.active:focus,
.dropdown-item:active:focus {
  opacity: 0.8;
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  color: var(--gray-600);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-header {
  display: block;
  padding: 0.8rem 15px;
  margin-block-end: 0;
  font-size: 0.76562rem;
  color: var(--gray-600);
  white-space: nowrap;
}

@media (min-width: 768px) {

  .dropdown.d-cart .dropdown-menu,
  .dropdown.message .dropdown-menu {
    width: 20.5rem !important;
  }
}

.user-lock .dropdown {
  position: absolute;
  inset-inline-end: 20px;
}

.main-dropdown-form-demo .static-dropdown {
  padding: 20px;
  background-color: var(--gray-200);
  display: inline-flex;
  justify-content: center;
}

.main-dropdown-form-demo .static-dropdown .dropdown-menu {
  display: block;
  position: static;
  float: none;
}

.main-dropdown-form-demo .dropdown-menu {
  padding: 20px;
}

.main-dropdown-form-demo .dropdown-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--gray-900);
  margin-block-end: 5px;
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .static-dropdown {
    padding: 40px;
  }
}

@media (min-width: 576px) {
  .main-dropdown-form-demo .dropdown-menu {
    width: 300px;
    padding: 30px 25px;
  }
}

/* ###### 3.6 Forms ###### */
.form-control {
  border-radius: 0;
}

.form-control:focus {
  border-color: var(--color2);
  box-shadow: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

option {
  color: var(--gray-700);
  background: #183b42;
}

.form-label {
  display: block;
  margin-block-end: 5px;
  color: var(--color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--color);
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid var(--border);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control:focus {
  color: var(--color2);
  background-color: var(--primary02);
  border-color: var(--primary-bg-color);
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: white;
  opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: var(--background);
  opacity: 1;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-block-start: calc(0.375rem + 1px);
  padding-block-end: calc(0.375rem + 1px);
  margin-block-end: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-block-start: calc(0.5rem + 1px);
  padding-block-end: calc(0.5rem + 1px);
  font-size: 1.09375rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-block-start: calc(0.25rem + 1px);
  padding-block-end: calc(0.25rem + 1px);
  font-size: 0.76562rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-block-start: 0.375rem;
  padding-block-end: 0.375rem;
  margin-block-end: 0;
  line-height: 1.5;
  color: var(--color);
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}

.form-control-plaintext.form-control-sm,
.form-control-plaintext.form-control-lg {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.785rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size],
select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-block-end: 1rem;
}

.form-text {
  display: block;
  margin-block-start: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-inline-end: -5px;
  margin-inline-start: -5px;
}

.form-row>.col,
.form-row>[class*="col-"] {
  padding-inline-end: 5px;
  padding-inline-start: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-inline-start: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-block-start: 0.3rem;
  margin-inline-start: -1.25rem;
}

.form-check-input:disabled~.form-check-label {
  color: var(--gray-600);
}

.form-check-label {
  margin-block-end: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-inline-start: 0;
  margin-inline-end: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-block-start: 0;
  margin-inline-end: 0.3125rem;
  margin-inline-start: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-block-start: 0.25rem;
  font-size: 80%;
  color: #43ce85;
}

.valid-tooltip {
  position: absolute;
  inset-block-start: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-block-start: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: rgb(var(--background-rgb));
  background-color: #43ce85;
  border-radius: 3px;
}

.was-validated .form-control:valid,
.form-control.is-valid,
input.form-control.parsley-success,
textarea.form-control.parsley-success {
  border-color: #43ce85;
  padding-inline-end: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300744cb3 ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
input.form-control.parsley-success:focus,
textarea.form-control.parsley-success:focus {
  border-color: #43ce85;
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.25);
}

.was-validated .form-control:valid~.valid-feedback,
.was-validated .form-control:valid~.valid-tooltip {
  display: block;
}

.form-control.is-valid~.valid-feedback,
input.form-control.parsley-success~.valid-feedback,
textarea.form-control.parsley-success~.valid-feedback,
.form-control.is-valid~.valid-tooltip,
input.form-control.parsley-success~.valid-tooltip,
textarea.form-control.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid {
  padding-inline-end: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-valid,
textarea.form-control.parsley-success {
  padding-inline-end: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:valid,
.custom-select.is-valid,
input.custom-select.parsley-success,
textarea.custom-select.parsley-success {
  border-color: #43ce85;
  padding-inline-end: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") rgb(var(--background-rgb)) no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:valid:focus,
.custom-select.is-valid:focus,
input.custom-select.parsley-success:focus,
textarea.custom-select.parsley-success:focus {
  border-color: #43ce85;
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.25);
}

.was-validated .custom-select:valid~.valid-feedback,
.was-validated .custom-select:valid~.valid-tooltip {
  display: block;
}

.custom-select.is-valid~.valid-feedback,
input.custom-select.parsley-success~.valid-feedback,
textarea.custom-select.parsley-success~.valid-feedback,
.custom-select.is-valid~.valid-tooltip,
input.custom-select.parsley-success~.valid-tooltip,
textarea.custom-select.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid~.valid-feedback,
.was-validated .form-control-file:valid~.valid-tooltip {
  display: block;
}

.form-control-file.is-valid~.valid-feedback,
input.form-control-file.parsley-success~.valid-feedback,
textarea.form-control-file.parsley-success~.valid-feedback,
.form-control-file.is-valid~.valid-tooltip,
input.form-control-file.parsley-success~.valid-tooltip,
textarea.form-control-file.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label,
input.form-check-input.parsley-success~.form-check-label,
textarea.form-check-input.parsley-success~.form-check-label {
  color: #43ce85;
}

.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
  display: block;
}

.form-check-input.is-valid~.valid-feedback,
input.form-check-input.parsley-success~.valid-feedback,
textarea.form-check-input.parsley-success~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
input.form-check-input.parsley-success~.valid-tooltip,
textarea.form-check-input.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label,
input.custom-control-input.parsley-success~.custom-control-label,
textarea.custom-control-input.parsley-success~.custom-control-label {
  color: #43ce85;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before,
input.custom-control-input.parsley-success~.custom-control-label::before,
textarea.custom-control-input.parsley-success~.custom-control-label::before {
  border-color: #43ce85;
}

.was-validated .custom-control-input:valid~.valid-feedback,
.was-validated .custom-control-input:valid~.valid-tooltip {
  display: block;
}

.custom-control-input.is-valid~.valid-feedback,
input.custom-control-input.parsley-success~.valid-feedback,
textarea.custom-control-input.parsley-success~.valid-feedback,
.custom-control-input.is-valid~.valid-tooltip,
input.custom-control-input.parsley-success~.valid-tooltip,
textarea.custom-control-input.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before,
input.custom-control-input.parsley-success:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-success:checked~.custom-control-label::before {
  border-color: #43ce85;
  background-color: #43ce85;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before,
input.custom-control-input.parsley-success:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label::before,
input.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
textarea.custom-control-input.parsley-success:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:valid~.custom-file-label,
.custom-file-input.is-valid~.custom-file-label,
input.custom-file-input.parsley-success~.custom-file-label,
textarea.custom-file-input.parsley-success~.custom-file-label {
  border-color: #43ce85;
}

.was-validated .custom-file-input:valid~.valid-feedback,
.was-validated .custom-file-input:valid~.valid-tooltip {
  display: block;
}

.custom-file-input.is-valid~.valid-feedback,
input.custom-file-input.parsley-success~.valid-feedback,
textarea.custom-file-input.parsley-success~.valid-feedback,
.custom-file-input.is-valid~.valid-tooltip,
input.custom-file-input.parsley-success~.valid-tooltip,
textarea.custom-file-input.parsley-success~.valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus~.custom-file-label,
.custom-file-input.is-valid:focus~.custom-file-label,
input.custom-file-input.parsley-success:focus~.custom-file-label,
textarea.custom-file-input.parsley-success:focus~.custom-file-label {
  border-color: #43ce85;
  box-shadow: 0 0 0 0.2rem rgba(67, 206, 133, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-block-start: 0.25rem;
  font-size: 80%;
  color: #d6573c;
}

.invalid-tooltip {
  position: absolute;
  inset-block-start: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-block-start: 0.1rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  color: rgb(var(--background-rgb));
  background-color: rgba(214, 87, 60, 0.9);
  border-radius: 3px;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
input.form-control.parsley-error,
textarea.form-control.parsley-error {
  border-color: #d6573c;
  padding-inline-end: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
input.form-control.parsley-error:focus,
textarea.form-control.parsley-error:focus {
  border-color: #d6573c;
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.25);
}

.was-validated .form-control:invalid~.invalid-feedback,
.was-validated .form-control:invalid~.invalid-tooltip {
  display: block;
}

.form-control.is-invalid~.invalid-feedback,
input.form-control.parsley-error~.invalid-feedback,
textarea.form-control.parsley-error~.invalid-feedback,
.form-control.is-invalid~.invalid-tooltip,
input.form-control.parsley-error~.invalid-tooltip,
textarea.form-control.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid {
  padding-inline-end: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

textarea.form-control.is-invalid,
textarea.form-control.parsley-error {
  padding-inline-end: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .custom-select:invalid,
.custom-select.is-invalid,
input.custom-select.parsley-error,
textarea.custom-select.parsley-error {
  border-color: #d6573c;
  padding-inline-end: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px,
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fa5c7c' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23fa5c7c' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") rgb(var(--background-rgb)) no-repeat center right 1.75rem / calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.was-validated .custom-select:invalid:focus,
.custom-select.is-invalid:focus,
input.custom-select.parsley-error:focus,
textarea.custom-select.parsley-error:focus {
  border-color: #d6573c;
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.25);
}

.was-validated .custom-select:invalid~.invalid-feedback,
.was-validated .custom-select:invalid~.invalid-tooltip {
  display: block;
}

.custom-select.is-invalid~.invalid-feedback,
input.custom-select.parsley-error~.invalid-feedback,
textarea.custom-select.parsley-error~.invalid-feedback,
.custom-select.is-invalid~.invalid-tooltip,
input.custom-select.parsley-error~.invalid-tooltip,
textarea.custom-select.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid~.invalid-feedback,
.was-validated .form-control-file:invalid~.invalid-tooltip {
  display: block;
}

.form-control-file.is-invalid~.invalid-feedback,
input.form-control-file.parsley-error~.invalid-feedback,
textarea.form-control-file.parsley-error~.invalid-feedback,
.form-control-file.is-invalid~.invalid-tooltip,
input.form-control-file.parsley-error~.invalid-tooltip,
textarea.form-control-file.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label,
input.form-check-input.parsley-error~.form-check-label,
textarea.form-check-input.parsley-error~.form-check-label {
  color: #d6573c;
}

.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
  display: block;
}

.form-check-input.is-invalid~.invalid-feedback,
input.form-check-input.parsley-error~.invalid-feedback,
textarea.form-check-input.parsley-error~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
input.form-check-input.parsley-error~.invalid-tooltip,
textarea.form-check-input.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label,
input.custom-control-input.parsley-error~.custom-control-label,
textarea.custom-control-input.parsley-error~.custom-control-label {
  color: #d6573c;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before,
input.custom-control-input.parsley-error~.custom-control-label::before,
textarea.custom-control-input.parsley-error~.custom-control-label::before {
  border-color: #d6573c;
}

.was-validated .custom-control-input:invalid~.invalid-feedback,
.was-validated .custom-control-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-control-input.is-invalid~.invalid-feedback,
input.custom-control-input.parsley-error~.invalid-feedback,
textarea.custom-control-input.parsley-error~.invalid-feedback,
.custom-control-input.is-invalid~.invalid-tooltip,
input.custom-control-input.parsley-error~.invalid-tooltip,
textarea.custom-control-input.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before,
input.custom-control-input.parsley-error:checked~.custom-control-label::before,
textarea.custom-control-input.parsley-error:checked~.custom-control-label::before {
  border-color: #d6573c;
  background-color: #d6573c;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before,
input.custom-control-input.parsley-error:focus~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label::before,
.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label::before,
input.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
textarea.custom-control-input.parsley-error:focus:not(:checked)~.custom-control-label::before,
.was-validated .custom-file-input:invalid~.custom-file-label,
.custom-file-input.is-invalid~.custom-file-label,
input.custom-file-input.parsley-error~.custom-file-label,
textarea.custom-file-input.parsley-error~.custom-file-label {
  border-color: #d6573c;
}

.was-validated .custom-file-input:invalid~.invalid-feedback,
.was-validated .custom-file-input:invalid~.invalid-tooltip {
  display: block;
}

.custom-file-input.is-invalid~.invalid-feedback,
input.custom-file-input.parsley-error~.invalid-feedback,
textarea.custom-file-input.parsley-error~.invalid-feedback,
.custom-file-input.is-invalid~.invalid-tooltip,
input.custom-file-input.parsley-error~.invalid-tooltip,
textarea.custom-file-input.parsley-error~.invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus~.custom-file-label,
.custom-file-input.is-invalid:focus~.custom-file-label,
input.custom-file-input.parsley-error:focus~.custom-file-label,
textarea.custom-file-input.parsley-error:focus~.custom-file-label {
  border-color: #d6573c;
  box-shadow: 0 0 0 0.2rem rgba(214, 87, 60, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-block-end: 0;
  }

  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-block-end: 0;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-plaintext {
    display: inline-block;
  }

  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }

  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-inline-start: 0;
  }

  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-block-start: 0;
    margin-inline-end: 0.25rem;
    margin-inline-start: 0;
  }

  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }

  .form-inline .custom-control-label {
    margin-block-end: 0;
  }
}

/* ###### 5.3 Forms   ###### */
.main-form-group {
  padding: 12px 15px;
  border: 1px solid var(--border);
  border-radius: 5px;
}

.main-form-group.focus {
  border-color: var(--gray-400);
  box-shadow: 0 0 0 2px var(--bg-hover);
}

.main-form-group .form-label {
  font-size: 13px;
  margin-block-end: 2px;
  font-weight: 400;
  color: var(--gray-500);
}

.main-form-group .form-control {
  padding: 0;
  border-width: 0;
  height: 25px;
  color: var(--gray-900);
  font-weight: 500;
}

.main-form-group .form-control:focus {
  box-shadow: none;
}

.formgroup-wrapper .form-control {
  height: 40px !important;
}

.formgroup-wrapper .main-form-group .form-label {
  margin-block-end: 0px;
}

.main-header-center .form-control {
  padding: 1rem;
}

.main-header-center .form-control::placeholder {
  padding-inline-start: 0rem;
  padding-inline-end: 0rem;
}

.browse-file.form-control {
  background-color: transparent;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.file-browser .btn-default {
  background: #f1f4fb;
  border-start-end-radius: 3px !important;
  border-end-end-radius: 3px !important;
  padding: 0.6rem 15px;
  color: #68769a;
  border-radius: 0;
  border: 1px solid var(--border);
}

.form-select {
  border: 1px solid var(--border);
}

.form-select:focus {
  box-shadow: none;
  border-color: var(--border) !important;
}

.SumoSelect>.CaptionCont {
  border: 1px solid var(--border);
}

.form-control::-webkit-file-upload-button {
  background-color: var(--background);
}

.form-control:hover:not(disabled):not([readonly])::-webkit-file-upload-button {
  background-color: var(--background) !important;
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.colorinput-color {
  display: inline-block;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: 50%;
  color: rgb(var(--background-rgb));
}

.colorinput-input:checked~.colorinput-color:before {
  opacity: 1;
}

.colorinput-color:before {
  content: "";
  opacity: 0;
  position: absolute;
  inset-block-start: 0.25rem;
  inset-inline-start: 0.25rem;
  height: 1.25rem;
  width: 1.25rem;
  transition: 0.3s opacity;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E") no-repeat center center/50% 50%;
}

.dropify-wrapper input {
  z-index: 0;
}

.SumoSelect>.optWrapper>.options li.opt:hover {
  background-color: var(--gray-100);
}

.SumoSelect>.optWrapper.multiple>.options li.opt.selected span i,
.SumoSelect .select-all.selected>span i,
.SumoSelect .select-all.partial>span i {
  background-color: var(--primary-bg-color);
}

.SumoSelect>.optWrapper.multiple>.MultiControls>p:hover,
.SumoSelect>.optWrapper.multiple.okCancelInMulti>.MultiControls>p:focus {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.SumoSelect:focus>.CaptionCont {
  border-color: var(--primary-bg-color);
}

.richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
  z-index: 5;
}

.note-btn.btn-default {
  background-color: rgb(var(--background-rgb));
  color: var(--muted);
}

.note-btn.btn-default:hover,
.note-btn.btn-default:not(:disabled):not(.disabled).active {
  background-color: var(--bg-hover);
  color: var(--primary-bg-color);
}

.richText {
  overflow: hidden;
}

.richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li,
.richText .richText-editor {
  font-family: inherit;
}

.richText .richText-toolbar ul li a,
.richText .richText-undo,
.richText .richText-redo {
  color: var(--muted) !important;
}

.richText .richText-undo,
.richText .richText-redo {
  border: none;
  border-radius: 4px;
  margin: 2px;
}

.richText .richText-toolbar ul li a:hover,
.richText .richText-undo:hover,
.richText .richText-undo:focus,
.richText .richText-redo:hover,
.richText .richText-redo:focus {
  background-color: var(--gray-100) !important;
  color: var(--primary-bg-color) !important;
}

.form-control::-webkit-file-upload-button {
  color: var(--color2);
  background-color: var(--bg-hover);
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: rgb(var(--background-rgb));
}

.form-select {
  color: var(--color2);
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
}

/* ###### 3.7 Grid ###### */
.row-sm {
  margin-inline-start: -10px;
  margin-inline-end: -10px;
}

.row-sm>div {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.row-xs {
  margin-inline-start: -5px;
  margin-inline-end: -5px;
}

.row-xs>div {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}

@media (min-width: 576px) {
  .row-xs--sm {
    margin-inline-start: -5px;
    margin-inline-end: -5px;
  }

  .row-xs--sm>div {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .row-sm--sm {
    margin-inline-start: -10px;
    margin-inline-end: -10px;
  }

  .row-sm--sm>div {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .row--sm {
    margin-inline-start: -15px;
    margin-inline-end: -15px;
  }

  .row--sm>div {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
}

@media (min-width: 768px) {
  .row-xs--md {
    margin-inline-start: -5px;
    margin-inline-end: -5px;
  }

  .row-xs--md>div {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .row-sm--md {
    margin-inline-start: -10px;
    margin-inline-end: -10px;
  }

  .row-sm--md>div {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .row--md {
    margin-inline-start: -15px;
    margin-inline-end: -15px;
  }

  .row--md>div {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
}

@media (min-width: 992px) {
  .row-xs--lg {
    margin-inline-start: -5px;
    margin-inline-end: -5px;
  }

  .row-xs--lg>div {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .row-sm--lg {
    margin-inline-start: -10px;
    margin-inline-end: -10px;
  }

  .row-sm--lg>div {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .row--lg {
    margin-inline-start: -15px;
    margin-inline-end: -15px;
  }

  .row--lg>div {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
}

@media (min-width: 1200px) {
  .row-xs--xl {
    margin-inline-start: -5px;
    margin-inline-end: -5px;
  }

  .row-xs--xl>div {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .row-sm--xl {
    margin-inline-start: -10px;
    margin-inline-end: -10px;
  }

  .row-sm--xl>div {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .row--xl {
    margin-inline-start: -15px;
    margin-inline-end: -15px;
  }

  .row--xl>div {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}

.input-group>.form-control,
.input-group>.form-control-plaintext,
.input-group>.custom-select,
.input-group>.custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  margin-block-end: 0;
}

.input-group>.form-control+.form-control,
.input-group>.form-control+.custom-select,
.input-group>.form-control+.custom-file {
  margin-inline-start: -1px;
}

.input-group>.form-control-plaintext+.form-control,
.input-group>.form-control-plaintext+.custom-select,
.input-group>.form-control-plaintext+.custom-file {
  margin-inline-start: -1px;
}

.input-group>.custom-select+.form-control,
.input-group>.custom-select+.custom-select,
.input-group>.custom-select+.custom-file {
  margin-inline-start: -1px;
}

.input-group>.custom-file+.form-control,
.input-group>.custom-file+.custom-select,
.input-group>.custom-file+.custom-file {
  margin-inline-start: -1px;
}

.input-group>.form-control:focus,
.input-group>.custom-select:focus {
  z-index: 3;
}

.input-group>.custom-file .custom-file-input:focus {
  z-index: 4;
}

.input-group>.custom-file .custom-file-input:focus~.custom-file-label {
  z-index: 3;
}

.input-group>.form-control:not(:last-child),
.input-group>.custom-select:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.form-control:not(:first-child),
.input-group>.custom-select:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.input-group>.custom-file {
  display: flex;
  align-items: center;
}

.input-group>.custom-file:not(:last-child) .custom-file-label {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.custom-file:not(:last-child) .custom-file-label::after {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.custom-file:not(:first-child) .custom-file-label {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend .btn,
.input-group-prepend .sp-container button {
  position: relative;
  z-index: 0;
}

.sp-container .input-group-prepend button {
  position: relative;
  z-index: 2;
}

.input-group-append .btn,
.input-group-append .sp-container button {
  position: relative;
  z-index: 0;
}

.sp-container .input-group-append button {
  position: relative;
  z-index: 2;
}

.input-group-prepend .btn:focus,
.input-group-prepend .sp-container button:focus {
  z-index: 3;
}

.sp-container .input-group-prepend button:focus {
  z-index: 3;
}

.input-group-append .btn:focus,
.input-group-append .sp-container button:focus {
  z-index: 3;
}

.sp-container .input-group-append button:focus {
  z-index: 3;
}

.input-group-prepend .btn+.btn,
.input-group-prepend .sp-container button+.btn {
  margin-inline-start: -1px;
}

.sp-container .input-group-prepend button+.btn,
.input-group-prepend .sp-container .btn+button,
.sp-container .input-group-prepend .btn+button,
.input-group-prepend .sp-container button+button,
.sp-container .input-group-prepend button+button {
  margin-inline-start: -1px;
}

.input-group-prepend .btn+.input-group-text,
.input-group-prepend .sp-container button+.input-group-text {
  margin-inline-start: -1px;
}

.sp-container .input-group-prepend button+.input-group-text {
  margin-inline-start: -1px;
}

.input-group-prepend .input-group-text+.input-group-text,
.input-group-prepend .input-group-text+.btn {
  margin-inline-start: -1px;
}

.input-group-prepend .sp-container .input-group-text+button {
  margin-inline-start: -1px;
}

.sp-container .input-group-prepend .input-group-text+button {
  margin-inline-start: -1px;
}

.input-group-append .btn+.btn,
.input-group-append .sp-container button+.btn {
  margin-inline-start: -1px;
}

.sp-container .input-group-append button+.btn,
.input-group-append .sp-container .btn+button,
.sp-container .input-group-append .btn+button,
.input-group-append .sp-container button+button,
.sp-container .input-group-append button+button {
  margin-inline-start: -1px;
}

.input-group-append .btn+.input-group-text,
.input-group-append .sp-container button+.input-group-text {
  margin-inline-start: -1px;
}

.sp-container .input-group-append button+.input-group-text {
  margin-inline-start: -1px;
}

.input-group-append .input-group-text+.input-group-text,
.input-group-append .input-group-text+.btn {
  margin-inline-start: -1px;
}

.input-group-append .sp-container .input-group-text+button {
  margin-inline-start: -1px;
}

.sp-container .input-group-append .input-group-text+button {
  margin-inline-start: -1px;
}

.input-group-prepend {
  margin-inline-end: -1px;
}

.input-group-append {
  margin-inline-start: -1px;
}

.input-group-text {
  display: flex;
  border: 0;
  background: no-repeat;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-block-end: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 0;
  color: var(--gray-700);
  text-align: center;
  white-space: nowrap;
  /* background-color: var(--black-03); */
  /* border: 1px solid var(--border);
    border-radius: 3px; */
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
  margin-block-start: 0;
}

.input-group-lg>.form-control:not(textarea),
.input-group-lg>.custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg>.form-control,
.input-group-lg>.custom-select,
.input-group-lg>.input-group-prepend>.input-group-text,
.input-group-lg>.input-group-append>.input-group-text,
.input-group-lg>.input-group-prepend>.btn {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.sp-container .input-group-lg>.input-group-prepend>button,
.input-group-lg>.input-group-append>.btn,
.sp-container .input-group-lg>.input-group-append>button {
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm>.form-control:not(textarea),
.input-group-sm>.custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm>.form-control,
.input-group-sm>.custom-select,
.input-group-sm>.input-group-prepend>.input-group-text,
.input-group-sm>.input-group-append>.input-group-text,
.input-group-sm>.input-group-prepend>.btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.sp-container .input-group-sm>.input-group-prepend>button,
.input-group-sm>.input-group-append>.btn,
.sp-container .input-group-sm>.input-group-append>button {
  padding: 0.25rem 0.5rem;
  font-size: 0.76562rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg>.custom-select,
.input-group-sm>.custom-select {
  padding-inline-end: 1.75rem;
}

.input-group>.input-group-prepend>.btn,
.sp-container .input-group>.input-group-prepend>button {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.input-group-prepend>.input-group-text,
.input-group>.input-group-append:not(:last-child)>.btn {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

.sp-container .input-group>.input-group-append:not(:last-child)>button {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.input-group-append:not(:last-child)>.input-group-text,
.input-group>.input-group-append:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.sp-container .input-group>.input-group-append:last-child>button:not(:last-child):not(.dropdown-toggle) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.input-group-append:last-child>.input-group-text:not(:last-child) {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.input-group>.input-group-append>.btn {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.sp-container .input-group>.input-group-append>button {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.sp-container .input-group>.input-group-prepend:not(:first-child)>button {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.input-group>.input-group-prepend:not(:first-child)>.input-group-text,
.input-group>.input-group-prepend:first-child>.btn:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.sp-container .input-group>.input-group-prepend:first-child>button:not(:first-child),
.input-group>.input-group-prepend:first-child>.input-group-text:not(:first-child) {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

/* ###### 3.8 Input Group ###### */
.input-group-prepend,
.input-group-append {
  border-radius: 0;
}

.input-group-text {
  padding-block-start: 0;
  padding-block-end: 0;
  align-items: center;
  border-radius: 0;
  border-radius: 5px;
}

.input-group>.input-group-append>.input-group-text,
.input-group>.input-group-prepend:not(:first-child)>.btn {
  border-start-end-radius: 5px !important;
  border-end-end-radius: 5px !important;
}

.input-group .btn {
  z-index: 0;
}

.modal-open {
  overflow: auto !important;
  padding-inline-end: 0 !important;
}

.modal {
  z-index: 99999991;
}

.modal .modal-content {
  border: 1px solid var(--primary02);
  border-radius: 3px;
}

.modal .modal-header {
  border-block-end: 1px solid var(--primary02);
  border-start-start-radius: 3px;
  border-start-end-radius: 3px;
}

.modal .modal-header .modal-title {
  font-size: 16px;
}

.modal .modal-header .btn-close {
  padding: 1rem;
  margin-block-start: -1rem;
  margin-inline-end: -1rem;
  margin-block-end: -1rem;
  margin-inline-start: auto;
}

.modal .modal-body .modal-example-row .row>.col,
.modal .modal-body .modal-example-row .row [class^="col-"] {
  padding-block-start: 0.75rem;
  padding-block-end: 0.75rem;
  background-color: var(--gray-100);
  border: 1px solid var(--border);
}

.modal .modal-body .modal-example-row .row+.row {
  margin-block-start: 1rem;
}

.modal .modal-footer {
  padding: 1rem;
  border-block-start: 1px solid var(--border);
}

.modal#scrollingContentModal .modal-body {
  min-height: 1500px;
}

.modal.effect-scale .modal-dialog {
  transform: scale(0.7);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-scale.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-slide-in-right .modal-dialog {
  transform: translateX(20%);
  opacity: 0;
  transition: all 0.3s cubic-bezier(0.25, 0.5, 0.5, 0.9);
}

.modal.effect-slide-in-right.show .modal-dialog {
  transform: translateX(0);
  opacity: 1;
}

.modal.effect-slide-in-bottom .modal-dialog {
  transform: translateY(20%);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-slide-in-bottom.show .modal-dialog {
  transform: translateY(0);
  opacity: 1;
}

.modal.effect-newspaper .modal-dialog {
  transform: scale(0) rotate(720deg);
  opacity: 0;
}

.modal.effect-newspaper.show~.modal-backdrop,
.modal.effect-newspaper .modal-dialog {
  transition: all 0.5s;
}

.modal.effect-newspaper.show .modal-dialog {
  transform: scale(1) rotate(0deg);
  opacity: 1;
}

.modal.effect-fall {
  perspective: 1300px;
}

.modal.effect-fall .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(600px) rotateX(20deg);
  opacity: 0;
}

.modal.effect-fall.show .modal-dialog {
  transition: all 0.3s ease-in;
  transform: translateZ(0px) rotateX(0deg);
  opacity: 1;
}

.modal.effect-flip-horizontal {
  perspective: 1300px;
}

.modal.effect-flip-horizontal .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateY(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-horizontal.show .modal-dialog {
  transform: rotateY(0deg);
  opacity: 1;
}

.modal.effect-flip-vertical {
  perspective: 1300px;
}

.modal.effect-flip-vertical .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-70deg);
  transition: all 0.3s;
  opacity: 0;
}

.modal.effect-flip-vertical.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-super-scaled .modal-dialog {
  transform: scale(2);
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-super-scaled.show .modal-dialog {
  transform: scale(1);
  opacity: 1;
}

.modal.effect-sign {
  perspective: 1300px;
}

.modal.effect-sign .modal-dialog {
  transform-style: preserve-3d;
  transform: rotateX(-60deg);
  transform-origin: 50% 0;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-sign.show .modal-dialog {
  transform: rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-bottom {
  perspective: 1300px;
}

.modal.effect-rotate-bottom .modal-dialog {
  transform-style: preserve-3d;
  transform: translateY(100%) rotateX(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s ease-out;
}

.modal.effect-rotate-bottom.show .modal-dialog {
  transform: translateY(0%) rotateX(0deg);
  opacity: 1;
}

.modal.effect-rotate-left {
  perspective: 1300px;
}

.modal.effect-rotate-left .modal-dialog {
  transform-style: preserve-3d;
  transform: translateZ(100px) translateX(-30%) rotateY(90deg);
  transform-origin: 0 100%;
  opacity: 0;
  transition: all 0.3s;
}

.modal.effect-rotate-left.show .modal-dialog {
  transform: translateZ(0px) translateX(0%) rotateY(0deg);
  opacity: 1;
}

.modal-content {
  background: var(--main-gradient-bg);
}

.nav-link {
  color: var(--color);
}

.nav-link:hover,
.nav-link:focus,
.nav-link:active,
.nav-link.active {
  outline: none;
  text-decoration: none;
  color: var(--primary-bg-color);
}

.nav-link.disabled {
  color: var(--gray-600) !important;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  padding: 10px 10px 0px !important;
  border-block-end: 1px solid var(--border);
}

.nav-tabs .nav-link {
  padding: 10px 14px;
  color: var(--color2);
  border-block-end: 0px;
  text-align: center;
}

.nav-tabs .nav-link:not(.active):hover,
.nav-tabs .nav-link:not(.active):focus {
  background-color: var(--bg-hover);
  color: var(--gray-900);
  border-color: var(--border);
}

.nav-tabs .nav-link.active {
  color: var(--primary-bg-color);
  background-color: rgb(var(--background-rgb));
  border-color: var(--border) var(--border) rgb(var(--background-rgb)) var(--border);
}

.nav-tabs .nav-link.disabled {
  color: var(--gray-500) !important;
}

.nav-tabs .nav-item+.nav-item {
  margin-inline-start: 5px;
}

.nav-tabs .nav-item.show .nav-link {
  color: var(--color2);
  background-color: rgb(var(--background-rgb));
  border-color: var(--gray-300) var(--gray-300) rgb(var(--background-rgb));
}

.nav-tabs .dropdown-menu {
  margin-block-start: -1px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

@media (min-width: 768px) {
  .nav-tabs .nav-link {
    padding: 10px 20px;
  }
}

.nav-pills .nav-link {
  color: var(--color);
}

.nav-pills .nav-link.active {
  color: rgb(var(--background-rgb));
  border-color: var(--primary-bg-color) !important;
  background-color: var(--primary-bg-color);
}

.nav-pills .nav-link:not(.active):hover,
.nav-pills .nav-link:not(.active):focus {
  color: var(--primary-bg-color);
}

.nav-pills .show>.nav-link {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.main-nav-column {
  flex-direction: column;
}

.main-nav-column>.nav-item>.nav-link {
  height: 35px;
  font-size: 14px;
  color: var(--color);
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.main-nav-column>.nav-item>.nav-link>i {
  font-size: 16px;
  line-height: 0;
  margin-inline-end: 10px;
  transition: all 0.2s ease-in-out;
}

.main-nav-column>.nav-item>.nav-link>i:not([class*=" tx-"]) {
  color: var(--gray-600);
}

.main-nav-column>.nav-item>.nav-link.active {
  position: relative;
  background: var(--primary02);
  color: var(--primary-bg-color);
  border-radius: 4px;
}

.main-nav-column>.nav-item>.nav-link.active>i {
  color: var(--primary-bg-color);
}

.main-nav-column>.nav-item>.nav-link:not(.active):hover,
.main-nav-column>.nav-item>.nav-link:not(.active):focus,
.main-nav-column>.nav-item>.nav-link:not(.active):hover span,
.main-nav-column>.nav-item>.nav-link:not(.active):focus span,
.main-nav-column>.nav-item>.nav-link:not(.active):hover i,
.main-nav-column>.nav-item>.nav-link:not(.active):focus i {
  color: var(--primary-bg-color);
}

.main-nav-column>.nav-item+.nav-item {
  margin-block-start: 5px;
}

@media (min-width: 768px) {
  .main-nav {
    align-items: center;
  }
}

.main-nav .nav-link {
  color: var(--color2);
  position: relative;
  line-height: normal;
}

.main-nav .nav-link:hover,
.main-nav .nav-link:focus {
  color: var(--primary-bg-color);
}

.main-nav .nav-link.active {
  color: var(--primary-bg-color) !important;
}

.main-nav .nav-item+.nav-item .nav-link {
  border-inline-start: 1px solid var(--border);
}

.main-nav.search-nav .nav-item .nav-link {
  border-inline-start: 0px;
  margin-inline-end: 20px;
  padding: 0px;
  display: flex;
  align-items: center;
}

.main-nav.search-nav .nav-item:last-child {
  margin-inline-end: 0px;
}

.nav-primary {
  background-color: var(--primary-bg-color);
}

.nav-primary .nav-link {
  color: var(--white-6);
  border-color: var(--white-3) !important;
}

.nav-primary .nav-link.active {
  color: rgb(var(--background-rgb)) !important;
}

.nav-primary .nav-link:not(.active):hover,
.nav-primary .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.nav-secondary {
  background-color: var(--secondary);
}

.nav-secondary .nav-link {
  color: #000;
  border-color: #000 !important;
}

.nav-secondary .nav-link.active {
  color: #000 !important;
}

.nav-secondary .nav-link:not(.active):hover,
.nav-secondary .nav-link:focus {
  color: #000;
}

.nav-info {
  background-color: #4ec2f0;
}

.nav-info .nav-link {
  color: var(--white-6);
  border-color: var(--white-3) !important;
}

.nav-info .nav-link.active {
  color: rgb(var(--background-rgb)) !important;
}

.nav-info .nav-link:not(.active):hover,
.nav-info .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.nav-success {
  background-color: #43ce85;
}

.nav-success .nav-link {
  color: var(--white-6);
  border-color: var(--white-3) !important;
}

.nav-success .nav-link.active {
  color: rgb(var(--background-rgb)) !important;
}

.nav-success .nav-link:not(.active):hover,
.nav-success .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.nav-warning {
  background-color: #ffcf86;
}

.nav-warning .nav-link {
  color: var(--gray-600);
  border-color: var(--black-1) !important;
}

.nav-warning .nav-link.active {
  color: var(--gray-900) !important;
}

.nav-warning .nav-link:not(.active):hover,
.nav-warning .nav-link:focus {
  color: var(--gray-900);
}

.nav-danger {
  background-color: #d6573c;
}

.nav-danger .nav-link {
  color: var(--white-6);
  border-color: var(--white-3) !important;
}

.nav-danger .nav-link.active {
  color: rgb(var(--background-rgb)) !important;
}

.nav-danger .nav-link:not(.active):hover,
.nav-danger .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.nav-light {
  background-color: var(--gray-200);
}

.nav-light .nav-link {
  color: var(--gray-600);
  border-color: var(--black-1) !important;
}

.nav-light .nav-link.active {
  color: var(--gray-900) !important;
}

.nav-light .nav-link:not(.active):hover,
.nav-light .nav-link:focus {
  color: var(--gray-900);
}

.nav-dark {
  background-color: var(--gray-900);
}

.nav-dark .nav-link {
  color: var(--white-6);
  border-color: var(--white-3) !important;
}

.nav-dark .nav-link.active {
  color: rgb(var(--background-rgb)) !important;
}

.nav-dark .nav-link:not(.active):hover,
.nav-dark .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.primary-nav-tab {
  background-color: var(--primary-bg-color);
}

.primary-nav-tab .nav-tabs {
  border-color: var(--primary-bg-color) !important;
}

.primary-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #000;
}

.primary-nav-tab .nav-tabs .nav-link:not(.active):hover,
.primary-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.primary-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.secondary-nav-tab {
  background-color: var(--secondary);
}

.secondary-nav-tab .nav-tabs {
  border-color: var(--secondary) !important;
}

.secondary-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #000;
}

.secondary-nav-tab .nav-tabs .nav-link:not(.active):hover,
.secondary-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.secondary-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.success-nav-tab {
  background-color: #43ce85;
}

.success-nav-tab .nav-tabs {
  border-color: #43ce85 !important;
}

.success-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.success-nav-tab .nav-tabs .nav-link:not(.active):hover,
.success-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.success-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.danger-nav-tab {
  background-color: #d6573c;
}

.danger-nav-tab .nav-tabs {
  border-color: #d6573c !important;
}

.danger-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.danger-nav-tab .nav-tabs .nav-link:not(.active):hover,
.danger-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.danger-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.info-nav-tab {
  background-color: #4ec2f0;
}

.info-nav-tab .nav-tabs {
  border-color: #4ec2f0 !important;
}

.info-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.info-nav-tab .nav-tabs .nav-link:not(.active):hover,
.info-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.info-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.warning-nav-tab {
  background-color: #ffcf86;
}

.warning-nav-tab .nav-tabs {
  border-color: #ffcf86 !important;
}

.warning-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.warning-nav-tab .nav-tabs .nav-link:not(.active):hover,
.warning-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: var(--gray-900);
  background: none;
}

.warning-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.light-nav-tab {
  background-color: var(--gray-200);
}

.light-nav-tab .nav-tabs {
  border-color: var(--gray-200) !important;
}

.light-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.light-nav-tab .nav-tabs .nav-link:not(.active):hover,
.light-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: var(--gray-900);
  background: none;
}

.light-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

.dark-nav-tab {
  background-color: var(--color2);
}

.dark-nav-tab .nav-tabs {
  border-color: var(--color2) !important;
}

.dark-nav-tab .nav-tabs .nav-link {
  border: none;
  color: #fff;
}

.dark-nav-tab .nav-tabs .nav-link:not(.active):hover,
.dark-nav-tab .nav-tabs .nav-link:not(.active):focus {
  color: rgb(var(--background-rgb));
  background: none;
}

.dark-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

@media (prefers-reduced-motion: reduce) {
  .main-nav-column .nav-link i {
    transition: none;
  }
}

.main-nav-line {
  position: relative;
}

.main-nav-line .nav-link {
  padding: 0;
  color: var(--color2);
  position: relative;
  padding: 0px 10px;
  color: #37326d;
  position: relative;
}

.main-nav-line .nav-link:hover,
.main-nav-line .nav-link:focus {
  color: var(--gray-900);
}

.main-nav-line .nav-link.active {
  color: var(--gray-900);
}

.nav-item .dropdown-menu {
  inset-block-start: 44px;
}

.nav-item .dropdown-menu.show {
  transition: all ease 0.3s;
  animation: slideIn 0.2s linear;
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: none;
}

@media (max-width: 320px) {
  .main-toggle.on span {
    inset-inline-start: 29px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  padding-inline-start: 0;
  list-style: none;
  border-radius: 3px;
}

.pagination .page-item:first-child .page-link {
  margin-inline-start: 0;
  border-radius: 0;
  border-start-start-radius: 3px;
  border-end-start-radius: 3px;
}

.pagination .page-item:last-child .page-link {
  border-radius: 0;
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
}

.pagination .page-item.active .page-link {
  color: #000;
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color);
}

.pagination .page-item.disabled .page-link {
  color: var(--gray-600);
  pointer-events: none;
  cursor: auto;
}

.pagination .page-item:not(.active) .page-link:hover {
  color: #000;
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color) !important;
}

.pagination .page-item .page-link {
  position: relative;
  display: flex;
  line-height: 1.25;
  color: var(--color2);
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-weight: 500;
  border: 1px solid var(--border);
}

.pagination .page-item .page-link i {
  font-size: 18px;
  line-height: 0;
}

.pagination .page-item .page-link:hover,
.pagination .page-item .page-link:focus {
  z-index: 2;
  text-decoration: none;
  box-shadow: none;
  background: none;
}

.pagination .page-item input[type="number"] {
  padding: 5px;
  height: 30px;
  width: 30px;
  border-radius: 4px;
}

.pagination .page-item input[type="number"]::-webkit-outer-spin-button,
.pagination .page-item input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.pagination.custom-pagination .page-item:last-child .page-link,
.pagination.custom-pagination .page-item:first-child .page-link {
  border-radius: 4px;
}

.pagination.custom-pagination .page-item:not(:first-child) .page-link {
  margin-inline-start: 4px;
}

.pagination.custom-pagination .page-item .page-link {
  background-color: var(--bg-hover);
  border-radius: 4px;
}

.pagination.pagination-circled .page-item:not(.page-prev):not(.page-next):not(.page-number-starts) .page-link {
  margin-inline-start: 4px;
}

.pagination.pagination-circled .page-item .page-link {
  border-radius: 50% !important;
}

.pagination.pagination-circled .page-item:not(.active) .page-link:hover,
.pagination.pagination-circled .page-item.active .page-link {
  border-radius: 4px;
}

.pagination.pagination-circled .page-item.page-prev .page-link,
.pagination.pagination-circled .page-item.page-next .page-link {
  border-radius: 50%;
}

.pagination.pagination-circled .page-item.page-prev .page-link:hover,
.pagination.pagination-circled .page-item.page-next .page-link:hover {
  color: var(--gray-900);
  background-color: var(--gray-200) !important;
  border-color: var(--gray-200) !important;
  border-radius: 50%;
}

.pagination.pagination-circled .page-item.page-prev .page-link {
  margin-inline-end: 10px;
}

.pagination.pagination-circled .page-item.page-next .page-link {
  margin-inline-start: 10px;
}

.pagination.pagination-style1 .page-item:not(.page-prev):not(.page-next):not(.pagination-jump):not(.page-number-starts) {
  padding-inline-start: 5px;
}

.pagination.pagination-style1 .page-item:not(.active):not(.page-prev):not(.page-next) .page-link:hover {
  background-color: transparent !important;
  color: var(--primary-bg-color);
}

.pagination.pagination-style1 .page-item:not(.active):not(.page-prev):not(.page-next) .page-link:focus {
  background-color: var(--primary-bg-color) !important;
  color: #fff;
  border-color: var(--primary-bg-color) !important;
}

.pagination.pagination-style1 .page-item.active .page-link {
  box-shadow: var(--shadow);
}

.pagination.pagination-style1 .page-item.page-number-starts {
  border-start-start-radius: 16px;
  border-end-start-radius: 16px;
  padding-inline-start: 0px;
}

.pagination.pagination-style1 .page-item.page-number-starts .page-link {
  margin-inline-start: 10px;
}

.pagination.pagination-style1 .page-item.page-number-ends {
  border-start-end-radius: 16px;
  border-end-end-radius: 16px;
  padding-inline-end: 0px;
}

.pagination.pagination-style1 .page-item.page-number-ends .page-link {
  margin-inline-end: 10px;
}

.pagination.pagination-style1 .page-item.page-prev .page-link,
.pagination.pagination-style1 .page-item.page-next .page-link {
  border-radius: 50%;
  background-color: var(--bg-hover);
}

.pagination.pagination-style1 .page-item.page-prev .page-link:hover,
.pagination.pagination-style1 .page-item.page-next .page-link:hover {
  color: var(--gray-900);
  background-color: var(--gray-300) !important;
  border-color: var(--gray-300) !important;
}

.pagination.pagination-style1 .page-item.page-prev .page-link {
  margin-inline-end: 10px;
}

.pagination.pagination-style1 .page-item.page-next .page-link {
  margin-inline-start: 10px;
}

.pagination.pagination-style1 .page-item .page-link {
  background-color: var(--bg-hover);
  border-radius: 7px;
}

.pagination.pagination-style2 .page-item:not(.page-prev):not(.page-next):not(.page-number-starts) .page-link {
  margin-inline-start: 4px;
}

.pagination.pagination-style2 .page-item.page-prev .page-link,
.pagination.pagination-style2 .page-item.page-next .page-link {
  border-radius: 50%;
}

.pagination.pagination-style2 .page-item.page-prev .page-link:hover,
.pagination.pagination-style2 .page-item.page-next .page-link:hover {
  color: var(--gray-900);
  background-color: var(--gray-200) !important;
  border-color: var(--gray-200) !important;
  border-radius: 50%;
}

.pagination.pagination-style2 .page-item.page-prev .page-link {
  margin-inline-end: 10px;
}

.pagination.pagination-style2 .page-item.page-next .page-link {
  margin-inline-start: 10px;
}

.pagination.pagination-style2 .page-item .page-link {
  border-color: transparent !important;
  border-radius: 4px;
}

.pagination.pagination-secondary .page-item.active .page-link {
  color: var(--gray-900);
  background-color: var(--secondary) !important;
  border-color: var(--secondary);
}

.pagination.pagination-secondary .page-item:not(.active) .page-link:hover {
  color: var(--gray-900);
  background-color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

.pagination.pagination-warning .page-item.active .page-link {
  color: var(--gray-900);
  background-color: #ffcf86 !important;
  border-color: #ffcf86;
}

.pagination.pagination-warning .page-item:not(.active) .page-link:hover {
  color: var(--gray-900);
  background-color: #ffcf86 !important;
  border-color: #ffcf86 !important;
}

.pagination.pagination-light .page-item.active .page-link {
  color: var(--gray-900);
  background-color: var(--gray-200) !important;
  border-color: var(--gray-200);
}

.pagination.pagination-light .page-item:not(.active) .page-link:hover {
  color: var(--gray-900);
  background-color: var(--gray-200) !important;
  border-color: var(--gray-200) !important;
}

.pagination.pagination-info .page-item.active .page-link {
  color: #fff;
  background-color: #4ec2f0 !important;
  border-color: #4ec2f0;
}

.pagination.pagination-info .page-item:not(.active) .page-link:hover {
  color: #fff;
  background-color: #4ec2f0 !important;
  border-color: #4ec2f0 !important;
}

.pagination.pagination-success .page-item.active .page-link {
  color: #fff;
  background-color: #43ce85 !important;
  border-color: #43ce85;
}

.pagination.pagination-success .page-item:not(.active) .page-link:hover {
  color: #fff;
  background-color: #43ce85 !important;
  border-color: #43ce85 !important;
}

.pagination.pagination-danger .page-item.active .page-link {
  color: #fff;
  background-color: #d6573c !important;
  border-color: #d6573c;
}

.pagination.pagination-danger .page-item:not(.active) .page-link:hover {
  color: #fff;
  background-color: #d6573c !important;
  border-color: #d6573c !important;
}

.pagination.pagination-dark .page-item.active .page-link {
  color: #fff;
  background-color: var(--gray-900) !important;
  border-color: var(--gray-900);
}

.pagination.pagination-dark .page-item:not(.active) .page-link:hover {
  color: #fff;
  background-color: var(--gray-900) !important;
  border-color: var(--gray-900) !important;
}

.pagination.pagination-lg .page-item .page-link {
  height: 50px;
  width: 50px;
  font-size: 1.25rem;
}

.pagination.pagination-sm .page-item .page-link {
  height: 30px;
  width: 25px;
  font-size: 0.875rem;
}

@media screen and (max-width: 500px) {
  .pagination .page-item .page-link {
    width: 25px;
    height: 25px;
  }

  .pagination.pagination-style1 .page-item.page-number-starts .page-link {
    margin-inline-start: 0;
  }

  .pagination.pagination-style1 .page-item.page-number-ends .page-link {
    margin-inline-end: 0;
  }

  .pagination.pagination-style1 .page-item.page-next .page-link {
    margin-inline-start: 5px;
  }

  .pagination.pagination-style1 .page-item.page-prev .page-link {
    margin-inline-end: 5px;
  }

  .pagination.pagination-style2 .page-item.page-next .page-link {
    margin-inline-start: 0;
  }

  .pagination.pagination-style2 .page-item.page-prev .page-link {
    margin-inline-end: 0;
  }
}

.page-link {
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
}

/* ###### 3.12 Popover ###### */
.popover {
  font-size: 12px;
  padding: 0;
  border-radius: 0;
}

.popover-header {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: var(--gray-800);
  letter-spacing: 0.5px;
  padding: 12px 15px;
  background-color: rgb(var(--background-rgb));
  border-color: var(--border);
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.popover-header::before {
  display: none !important;
}

.popover-body {
  padding: 15px;
}

.popover-body p:last-child {
  margin-block-end: 0;
}

.popover-head-primary .popover-header {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
}

.popover-head-primary.bs-popover-top .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
  inset-block-end: -7px;
}

.popover-head-primary.bs-popover-start .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
  inset-inline-end: -7px;
}

.popover-head-primary.bs-popover-end .popover-arrow,
.popover-head-primary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
  inset-inline-start: -7px;
}

.popover-head-primary.bs-popover-bottom .popover-arrow::after,
.popover-head-primary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
  border-block-end-color: var(--primary-bg-color) !important;
}

.popover-head-secondary .popover-header {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
}

.popover-head-secondary.bs-popover-top .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
  inset-block-end: -7px;
}

.popover-head-secondary.bs-popover-start .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow {
  inset-inline-end: -7px;
}

.popover-head-secondary.bs-popover-end .popover-arrow,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
  inset-inline-start: -7px;
}

.popover-head-secondary.bs-popover-bottom .popover-arrow::after,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
  border-block-end-color: var(--primary-bg-color);
}

.popover-head-primary,
.popover-head-secondary {
  border: 0;
}

.popover-head-primary .popover-header,
.popover-head-secondary .popover-header {
  border: 0;
}

.popover-head-primary .popover-body,
.popover-head-secondary .popover-body {
  border-block-start-width: 0;
  border-end-end-radius: 2px;
  border-end-start-radius: 2px;
}

.popover-head-primary.bs-popover-bottom .popover-arrow::before,
.popover-head-primary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
  display: none;
}

.popover-head-secondary.bs-popover-bottom .popover-arrow::before,
.popover-head-secondary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::before {
  display: none;
}

.popover-primary {
  background-color: var(--primary-bg-color) !important;
  border-width: 0;
  padding: 15px;
  border: 1px solid var(--primary-bg-color) !important;
}

.popover-primary .popover-header {
  background-color: transparent;
  border-block-end-width: 0;
  padding: 0 0 15px;
  color: rgb(var(--background-rgb));
}

.popover-primary .popover-body {
  padding: 0;
  color: var(--white-7);
}

.popover-primary .popover-arrow::before {
  display: none;
}

.popover-primary.bs-popover-top .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
  border-block-start-color: var(--primary-bg-color) !important;
}

.popover-primary.bs-popover-bottom .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
  border-block-end-color: var(--primary-bg-color);
}

.popover-primary.bs-popover-start .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
  border-inline-start-color: var(--primary-bg-color);
}

.popover-primary.bs-popover-end .popover-arrow::after,
.popover-primary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
  border-inline-end-color: var(--primary-bg-color);
}

.popover-secondary {
  background-color: var(--secondary) !important;
  border-width: 0;
  padding: 15px;
  border: 1px solid var(--secondary) !important;
}

.popover-secondary .popover-header {
  background-color: transparent;
  border-block-end-width: 0;
  padding: 0 0 15px;
  color: #000;
}

.popover-secondary .popover-body {
  padding: 0;
  color: #000;
}

.popover-secondary .popover-arrow::before {
  display: none;
}

.popover-secondary.bs-popover-top .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
  border-block-start-color: var(--secondary);
}

.popover-secondary.bs-popover-bottom .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow::after {
  border-block-end-color: var(--secondary) !important;
}

.popover-secondary.bs-popover-start .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^="left"] .popover-arrow::after {
  border-inline-start-color: var(--primary-bg-color);
}

.popover-secondary.bs-popover-end .popover-arrow::after,
.popover-secondary.bs-popover-auto[data-popper-placement^="right"] .popover-arrow::after {
  border-inline-end-color: var(--primary-bg-color);
}

.popover {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 9;
  display: block;
  max-width: 276px;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  background-color: rgb(var(--background-rgb));
  background-clip: padding-box;
  border-radius: 4px;
  border: 1px solid var(--border);
  box-shadow: -8px 12px 18px 0 var(--shadow-color);
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}

.popover .popover-arrow::before,
.popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^="top"] {
  margin-block-end: 0.5rem;
}

.bs-popover-top>.popover-arrow,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow {
  inset-block-end: calc((0.5rem + 1px) * -1);
}

.bs-popover-top>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::before {
  inset-block-end: 0;
  border-width: 0.5rem 0.5rem 0;
  border-block-start-color: var(--border);
}

.bs-popover-top>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"]>.popover-arrow::after {
  border-width: 0.5rem 0.5rem 0;
  border-block-start-color: rgb(var(--background-rgb));
}

.bs-popover-auto[data-popper-placement^="right"] {
  margin-inline-start: 0.8rem !important;
}

.bs-popover-end>.popover-arrow,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow {
  inset-inline-start: calc((0.5rem + 1px) * -1);
  inset-inline-end: auto;
  width: 0.5rem;
  height: 1rem;
  margin: 0 0;
}

.bs-popover-end>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::before {
  inset-inline-start: 0;
  border-block-start-width: 0.5rem;
  border-inline-end-width: 0.5rem;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0;
  border-inline-end-color: var(--border);
}

.bs-popover-end>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="right"]>.popover-arrow::after {
  inset-inline-start: 1px;
  border-block-start-width: 0.5rem;
  border-inline-end-width: 0.5rem;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0;
  border-inline-end-color: rgb(var(--background-rgb));
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^="bottom"] {
  margin-block-start: 0.5rem;
}

.bs-popover-bottom>.popover-arrow,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow {
  inset-block-start: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::before {
  inset-block-start: 0;
  border-block-start-width: 0px;
  border-inline-end-width: 0.5rem;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0.5rem;
  border-block-end-color: var(--border);
}

.bs-popover-bottom>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"]>.popover-arrow::after {
  inset-block-start: 1px;
  border-block-start-width: 0px;
  border-inline-end-width: 0.5rem;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0.5rem;
  border-block-end-color: rgb(var(--background-rgb));
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 50%;
  display: block;
  width: 1rem;
  margin-inline-start: -0.5rem;
  content: "";
  border-block-end: 1px solid var(--border);
}

.bs-popover-start>.popover-arrow,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  inset-inline-end: calc((0.5rem + 1px) * -1);
  inset-inline-start: auto;
  width: 0.5rem;
  height: 1rem;
  margin: 0rem 0;
}

.bs-popover-start>.popover-arrow::before,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::before {
  inset-inline-end: 0;
  border-block-start-width: 0.5rem;
  border-inline-end-width: 0;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0.5rem;
  border-inline-start-color: rgb(var(--background-rgb));
}

.bs-popover-start>.popover-arrow::after,
.bs-popover-auto[data-popper-placement^="left"]>.popover-arrow::after {
  inset-inline-end: 1px;
  border-block-start-width: 0.5rem;
  border-inline-end-width: 0;
  border-block-end-width: 0.5rem;
  border-inline-start-width: 0.5rem;
  border-inline-start-color: rgb(var(--background-rgb));
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-block-end: 0;
  font-size: 0.875rem;
  background-color: rgb(var(--background-rgb));
  border-block-end: 1px solid var(--border);
  border-start-start-radius: calc(0.3rem - 1px);
  border-start-end-radius: calc(0.3rem - 1px);
}

.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: var(--color);
}

.popover-static-demo {
  background-color: var(--bg-hover);
  padding-block-start: 40px;
  padding-block-end: 40px;
  text-align: center;
}

.popover-static-demo .popover {
  z-index: 0;
  opacity: 1;
  position: relative;
  display: inline-block;
  margin: 0 10px;
  box-shadow: -8px 12px 18px 0 var(--shadow-color);
  border: 1px solid var(--border);
}

.popover-static-demo .bs-popover-top .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^="top"] .popover-arrow,
.popover-static-demo .bs-popover-bottom .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^="bottom"] .popover-arrow {
  inset-inline-start: 50%;
  margin-inline-start: -5px;
}

.popover-static-demo .bs-popover-start,
.popover-static-demo .bs-popover-auto[data-popper-placement^="left"],
.popover-static-demo .bs-popover-end,
.popover-static-demo .bs-popover-auto[data-popper-placement^="right"] {
  margin-block-start: 5px;
}

.popover-static-demo .bs-popover-start .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^="left"] .popover-arrow,
.popover-static-demo .bs-popover-end .popover-arrow,
.popover-static-demo .bs-popover-auto[data-popper-placement^="right"] .popover-arrow {
  inset-block-start: 50%;
  margin-block-start: -5px;
}

.bs-popover-top.x-placement,
.bs-popover-auto[data-popper-placement^="top"].x-placement,
.bs-popover-bottom.x-placement,
.bs-popover-auto[data-popper-placement^="bottom"].x-placement {
  margin-inline-start: -0.5rem !important;
}

.progress-bar-xs {
  height: 5px !important;
}

.progress-bar-sm {
  height: 8px !important;
}

.progress-bar-lg {
  height: 15px !important;
}

.progress {
  font-size: 0.65625rem;
  background-color: var(--black-03);
  border-radius: 4px;
  height: auto;
}

.progress-bar {
  background-color: var(--primary-bg-color);
  border-radius: 4px;
  height: 13px;
  font-size: 12px;
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.progress-sm .progress-bar {
  border-radius: 1px;
}

.progress-style .table th,
.progress-style .table td {
  padding: 0.75rem;
  vertical-align: middle;
}

.progress-style.progress {
  overflow: visible !important;
}

.progress-style.progress .progress-bar:after {
  content: "";
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 4px solid var(--primary-bg-color);
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: transparent;
  position: absolute;
  inset-inline-end: -5px;
  inset-block-start: -5px;
}

.progress-style .bg-secondary.progress-bar:after {
  border-color: var(--secondary);
}

.progress-style .bg-danger.progress-bar:after {
  border-color: #d6573c;
}

.progress-style .bg-teal.progress-bar:after {
  border-color: #4ac9bd;
}

.progress-style .bg-pink.progress-bar:after {
  border-color: #f754fa;
}

.progress-style .bg-success.progress-bar:after {
  border-color: #43ce85;
}

.progress-style .bg-danger-gradient.progress-bar:after {
  border-color: #d6573c;
}

.progress-style .bg-warning.progress-bar:after {
  border-color: #ffcf86;
}

.progress-style .bg-info.progress-bar:after {
  border-color: #4ec2f0;
}

.progress-style .bg-indigo.progress-bar:after {
  border-color: #5b67c7;
}

.progress-style .bg-dark.progress-bar:after {
  border-color: var(--dark);
}

.progress-style.progress .progress-bar {
  position: relative;
}

.progress-style .progress-bar {
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  overflow: visible !important;
  color: rgb(var(--background-rgb));
  text-align: center;
}

/* Custom Progressbar */
.progress-bar-custom {
  height: 3px;
  width: 100%;
  position: relative;
  z-index: 0;
}

.progress-bar-custom.progress-bar-custom-primary {
  background-color: var(--primary03);
}

.progress-bar-custom.progress-bar-custom-secondary {
  background-color: var(--secondary-03);
}

.progress-bar-custom.progress-bar-custom-warning {
  background-color: rgba(255, 207, 134, 0.3);
}

.progress-bar-custom.progress-bar-custom-info {
  background-color: rgba(78, 194, 240, 0.3);
}

.progress-bar-custom.progress-bar-custom-success {
  background-color: rgba(67, 206, 133, 0.3);
}

.progress-bar-custom.progress-bar-custom-danger {
  background-color: rgba(214, 87, 60, 0.3);
}

.progress-bar-custom.progress-bar-custom-purple {
  background-color: rgba(120, 145, 239, 0.3);
}

.progress-bar-custom.progress-bar-custom-orange {
  background-color: rgba(255, 182, 123, 0.3);
}

.progress-bar-custom.progress-bar-custom-white {
  background-color: rgba(var(--background-rgb), 0.3);
}

.progress-bar-custom .progress-custom {
  position: relative;
  height: 100%;
  transition: width 0.5s ease-out;
  border-radius: 0px 2px 2px 0px;
}

.progress-bar-custom .progress-custom.progress-primary {
  background-color: var(--primary-bg-color);
}

.progress-bar-custom .progress-custom.progress-secondary {
  background-color: var(--secondary);
}

.progress-bar-custom .progress-custom.progress-danger {
  background-color: #d6573c;
}

.progress-bar-custom .progress-custom.progress-warning {
  background-color: #ffcf86;
}

.progress-bar-custom .progress-custom.progress-info {
  background-color: #4ec2f0;
}

.progress-bar-custom .progress-custom.progress-success {
  background-color: #43ce85;
}

.progress-bar-custom .progress-custom.progress-purple {
  background-color: #7891ef;
}

.progress-bar-custom .progress-custom.progress-orange {
  background-color: #ffb67b;
}

.progress-bar-custom .progress-custom.progress-white {
  background-color: var(--background-rgb);
}

.progress-bar-custom .glow {
  width: 60px;
  max-width: 100%;
  height: 100%;
  float: right;
}

.progress-bar-custom .glow::before,
.progress-bar-custom .glow::after {
  content: "";
  display: block;
  position: relative;
  border-radius: 0px 2px 2px 0px;
}

.progress-bar-custom .glow.glow-primary::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px var(--primary-bg-color),
    0px 0px 10px var(--primary-bg-color);
  z-index: -5;
}

.progress-bar-custom .glow.glow-secondary::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px var(--secondary), 0px 0px 10px var(--secondary);
  z-index: -5;
}

.progress-bar-custom .glow.glow-warning::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #ffcf86, 0px 0px 10px #ffcf86;
  z-index: -5;
}

.progress-bar-custom .glow.glow-info::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #4ec2f0, 0px 0px 10px #4ec2f0;
  z-index: -5;
}

.progress-bar-custom .glow.glow-success::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #43ce85, 0px 0px 10px #43ce85;
  z-index: -5;
}

.progress-bar-custom .glow.glow-danger::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #d6573c, 0px 0px 10px #d6573c;
  z-index: -5;
}

.progress-bar-custom .glow.glow-orange::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #ffb67b, 0px 0px 10px #ffb67b;
  z-index: -5;
}

.progress-bar-custom .glow.glow-purple::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px #7891ef, 0px 0px 10px #7891ef;
  z-index: -5;
}

.progress-bar-custom .glow.glow-white::before {
  background: transparent;
  height: 100%;
  box-shadow: 0px 0px 10px var(--background-rgb),
    0px 0px 10px var(--background-rgb);
  z-index: -5;
}

.progress-bar-custom .glow::after {
  background: linear-gradient(to right, #1b2b32 0%, transparent 100%);
  height: 15px;
  width: calc(100% + 10px);
  top: -10px;
  inset-inline-start: -10px;
  z-index: -3;
}

.fill-1 {
  animation: fill-1 0.5s ease-out 0s;
  animation-fill-mode: forwards;
  width: 0%;
}

@keyframes fill-1 {
  0% {
    width: 0%;
  }

  100% {
    width: 70%;
  }
}

@keyframes label {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 0.9;
    transform: translateY(0);
  }
}

/* Custom Progressbar */
/* ###### 3.14 Table ###### */
.table {
  color: var(--color) !important;
}

.table thead th,
.table thead td {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.5px;
  border-block-end-width: 1px;
  border-block-start-width: 0;
  padding: 10px 15px;
}

.table tbody tr {
  background-color: transparent;
}

.table tbody tr th {
  font-weight: 500;
}

.table th,
.table td {
  padding: 9px 15px;
  line-height: 1.462;
  font-size: 13px;
}

.table-striped tbody tr:nth-of-type(4n + 2) {
  background-color: rgba(238, 238, 247, 0.5);
}

.table-bordered thead th,
.table-bordered thead td {
  border-block-start-width: 1px;
  padding-block-start: 11px;
  padding-block-end: 11px;
  background-color: rgb(var(--background-rgb));
}

.table {
  width: 100%;
  margin-block-end: 1rem;
  color: var(--color);
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-block-start: 1px solid var(--border);
}

.table thead th {
  vertical-align: bottom;
  background-color: var(--black-03);
}

.table tbody+tbody {
  border-block-start: 2px solid transparent !important;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid var(--border);
}

.table-bordered th,
.table-bordered td {
  border: 1px solid var(--border);
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody+tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--bg-white);
}

.table-hover>tbody>tr:hover {
  color: var(--color);
  background-color: var(--bg-hover);
}

.table-active {
  background-color: var(--dark);
}

.table-active>th,
.table-active>td {
  background-color: var(--dark);
}

.table-hover .table-active:hover {
  background-color: var(--dark);
}

.table-hover .table-active:hover>td,
.table-hover .table-active:hover>th {
  background-color: var(--dark);
}

.table .thead-dark th {
  color: rgb(var(--background-rgb));
  background-color: var(--gray-800);
  border-color: var(--border);
}

.table .thead-light th {
  color: var(--gray-700);
  background-color: var(--gray-200);
  border-color: var(--gray-300);
}

.table-dark {
  color: rgb(var(--background-rgb));
  background-color: var(--gray-800);
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: var(--border);
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: var(--white-05);
}

.table-dark.table-hover tbody tr:hover {
  color: rgb(var(--background-rgb));
  background-color: var(--white-7);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .table-responsive-sm>.table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .table-responsive-md>.table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .table-responsive-lg>.table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
  }

  .table-responsive-xl>.table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
}

.table-responsive>.table-bordered {
  border: 0;
}

@media print {
  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: rgb(var(--background-rgb)) !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid var(--border) !important;
  }

  .table-dark {
    color: inherit;
  }

  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody+tbody {
    border-color: var(--gray-300);
  }

  .table .thead-dark th {
    color: inherit;
    border-color: var(--gray-300);
  }
}

.table.table-clean td .value {
  font-size: 0.9rem;
  line-height: 1.6;
  font-weight: 500;
}

.table.table-clean td .sub-value {
  font-size: 0.72rem;
  color: var(--gray-600);
}

.table.table-clean tr:first-child td {
  border-block-start: none;
}

.table.table-clean td {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
  border-block-start-color: var(--black-05);
}

.table.table-clean th,
.table.table-clean td {
  padding: 7px 20px !important;
}

@media (max-width: 1199px) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
  }
}

.table> :not(:last-child)> :last-child>* {
  border-block-end-color: var(--border);
}

.table-bordered> :not(caption)>* {
  border-width: 0;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  --bs-table-accent-bg: #fff;
  color: var(--dark);
}

tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: var(--border);
  border-style: none;
  border-width: 0;
}

.table-hover>tbody>tr:hover {
  --bs-table-accent-bg: none !important;
  background: var(--primary01);
}

.table-hover>tbody>tr:hover>* {
  --bs-table-accent-bg: none;
}

.table-hover>tbody>tr:hover>* {
  color: var(--color2) !important;
}

@media (min-width: 768px) {
  .table-responsive.deleted-table .data-table-btn {
    position: absolute;
    inset-inline-start: 185px;
    z-index: 999;
  }
}

@media (min-width: 1319px) {
  .table-responsive.export-table .dt-buttons.btn-group {
    position: absolute;
    inset-block-start: 72px;
    inset-inline-start: 172px;
  }
}

.table> :not(:first-child) {
  border-block-start: 1px solid var(--border) !important;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  --bs-table-accent-bg: rgb($white) !important;
}

@media (max-width: 583px) {

  .dt-buttons .btn,
  .dt-buttons .sp-container button {
    padding: 0.5rem 0.1rem;
  }
}

.table> :not(caption)>*>* {
  background-color: transparent;
}

table.dataTable>tbody>tr.child ul.dtr-details>li {
  border-block-end: 1px solid var(--border);
}

.table-primary {
  background-color: var(--primary05);
}

.table-primary>th,
.table-primary>td {
  background-color: var(--primary05);
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody+tbody {
  border-color: #00b8ff26 !important;
}

.table-primary.table> :not(:first-child) {
  border-top: 1px solid var(--primary01) !important;
}

.table-primary thead th {
  background: var(--primary-bg-color);
  color: #fff;
}

.table-primary tbody tr {
  background-color: transparent !important;
}

.table-hover .table-primary:hover {
  background-color: var(--primary05);
}

.table-hover .table-primary:hover>td,
.table-hover .table-primary:hover>th {
  background-color: var(--primary05);
}

.table-secondary {
  background-color: var(--secondary-05);
}

.table-secondary>th,
.table-secondary>td {
  background-color: var(--secondary-05);
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody+tbody {
  border-color: #ffb40036 !important;
}

.table-secondary.table> :not(:first-child) {
  border-top: 1px solid rgba(var(--secondary), 0.1) !important;
}

.table-secondary thead th {
  background: var(--secondary);
  color: #fff;
}

.table-secondary tbody tr {
  background-color: transparent !important;
}

.table-hover .table-secondary:hover {
  background-color: var(--secondary-05);
}

.table-hover .table-secondary:hover>td,
.table-hover .table-secondary:hover>th {
  background-color: var(--secondary-05);
}

.table-warning {
  background-color: rgba(255, 207, 134, 0.5);
}

.table-warning>th,
.table-warning>td {
  background-color: rgba(255, 207, 134, 0.5);
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody+tbody {
  border-color: #ff99005c !important;
}

.table-warning.table> :not(:first-child) {
  border-top: 1px solid rgba(255, 207, 134, 0.1) !important;
}

.table-warning thead th {
  background: #ffcf86;
  color: #fff;
}

.table-warning tbody tr {
  background-color: transparent !important;
}

.table-hover .table-warning:hover {
  background-color: rgba(255, 207, 134, 0.5);
}

.table-hover .table-warning:hover>td,
.table-hover .table-warning:hover>th {
  background-color: rgba(255, 207, 134, 0.5);
}

.table-info {
  background-color: rgba(78, 194, 240, 0.5);
}

.table-info>th,
.table-info>td {
  background-color: rgba(78, 194, 240, 0.5);
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody+tbody {
  border-color: #00b7ff33 !important;
}

.table-info.table> :not(:first-child) {
  border-top: 1px solid rgba(78, 194, 240, 0.1) !important;
}

.table-info thead th {
  background: #4ec2f0;
  color: #fff;
}

.table-info tbody tr {
  background-color: transparent !important;
}

.table-hover .table-info:hover {
  background-color: rgba(78, 194, 240, 0.5);
}

.table-hover .table-info:hover>td,
.table-hover .table-info:hover>th {
  background-color: rgba(78, 194, 240, 0.5);
}

.table-success {
  background-color: rgba(67, 206, 133, 0.5);
}

.table-success>th,
.table-success>td {
  background-color: rgba(67, 206, 133, 0.5);
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody+tbody {
  border-color: #008d7942 !important;
}

.table-success.table> :not(:first-child) {
  border-top: 1px solid rgba(67, 206, 133, 0.1) !important;
}

.table-success thead th {
  background: #43ce85;
  color: #fff;
}

.table-success tbody tr {
  background-color: transparent !important;
}

.table-hover .table-success:hover {
  background-color: rgba(67, 206, 133, 0.5);
}

.table-hover .table-success:hover>td,
.table-hover .table-success:hover>th {
  background-color: rgba(67, 206, 133, 0.5);
}

.table-danger {
  background-color: rgba(214, 87, 60, 0.5);
}

.table-danger>th,
.table-danger>td {
  background-color: rgba(214, 87, 60, 0.5);
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody+tbody {
  border-color: #ff2d0033 !important;
}

.table-danger.table> :not(:first-child) {
  border-top: 1px solid rgba(214, 87, 60, 0.1) !important;
}

.table-danger thead th {
  background: #d6573c;
  color: #fff;
}

.table-danger tbody tr {
  background-color: transparent !important;
}

.table-hover .table-danger:hover {
  background-color: rgba(214, 87, 60, 0.5);
}

.table-hover .table-danger:hover>td,
.table-hover .table-danger:hover>th {
  background-color: rgba(214, 87, 60, 0.5);
}

.toast {
  background-color: var(--bg-hover);
  overflow: hidden;
  border: 1px solid var(--border);
  box-shadow: 0 0.5rem 1rem var(--shadow-color) !important;
  backdrop-filter: blur(10px);
  opacity: 0;
}

.toast:not(:last-child) {
  margin-block-end: 0.75rem;
}

.toast.show {
  display: block;
  opacity: 1;
}

.toast .toast-header {
  color: var(--color2);
  border-block-end: 1px solid var(--border);
  background-color: var(--bg-hover);
}

.toast .toast-header .close {
  font-weight: 300;
}

.toast .toast-body {
  padding: 0.75rem;
  color: var(--color);
}

.tooltip {
  inset-block-start: 0;
}

.tooltip-inner {
  font-size: 12px;
  border-radius: 2px;
}

.tooltip-primary .tooltip-inner {
  background-color: var(--primary-bg-color) !important;
  color: rgb(var(--background-rgb)) !important;
}

.tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: var(--primary-bg-color) !important;
}

.tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-block-end-color: var(--primary-bg-color) !important;
}

.tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-inline-start-color: var(--primary-bg-color) !important;
}

.tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.tooltip-primary.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-inline-end-color: var(--primary-bg-color) !important;
}

.tooltip-primary1 .tooltip-inner {
  background-color: #43ce85 !important;
  color: rgb(var(--background-rgb)) !important;
}

.tooltip-primary1.bs-tooltip-top .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: #43ce85 !important;
}

.tooltip-primary1.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-block-end-color: #43ce85 !important;
}

.tooltip-primary1.bs-tooltip-start .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-inline-start-color: #43ce85 !important;
}

.tooltip-primary1.bs-tooltip-end .tooltip-arrow::before,
.tooltip-primary1.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-inline-end-color: #43ce85 !important;
}

.tooltip-secondary .tooltip-inner {
  background-color: var(--secondary) !important;
  color: var(--dark) !important;
}

.tooltip-secondary.bs-tooltip-top .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: var(--secondary) !important;
}

.tooltip-secondary.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-block-end-color: var(--secondary) !important;
}

.tooltip-secondary.bs-tooltip-start .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-inline-start-color: var(--secondary) !important;
}

.tooltip-secondary.bs-tooltip-end .tooltip-arrow::before,
.tooltip-secondary.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-inline-end-color: var(--secondary) !important;
}

.tooltip-secondary1 .tooltip-inner {
  background-color: #d6573c !important;
  color: rgb(var(--background-rgb)) !important;
}

.tooltip-secondary1.bs-tooltip-top .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: #d6573c !important;
}

.tooltip-secondary1.bs-tooltip-bottom .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  border-block-end-color: #d6573c !important;
}

.tooltip-secondary1.bs-tooltip-start .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  border-inline-start-color: #d6573c !important;
}

.tooltip-secondary1.bs-tooltip-end .tooltip-arrow::before,
.tooltip-secondary1.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow::before {
  border-inline-end-color: #d6573c !important;
}

.tooltip {
  position: absolute;
  z-index: 901;
  display: block;
  margin: 0;
  font-family: inherit;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: start;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.76562rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow {
  inset-block-end: 0;
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  inset-block-start: 0;
  border-width: 0.4rem 0.4rem 0;
  border-block-start-color: rgb(var(--background-rgb));
}

.bs-tooltip-end .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  inset-inline-end: 0;
  border-block-start-width: 0.4rem;
  border-inline-end-width: 0.4rem;
  border-block-end-width: 0.4rem;
  border-inline-start-width: 0px;
  border-inline-end-color: rgb(var(--background-rgb));
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow {
  inset-block-start: 0;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .tooltip-arrow::before {
  inset-block-end: 0;
  border-width: 0 0.4rem 0.4rem;
  border-block-end-color: rgb(var(--background-rgb));
}

.bs-tooltip-start .tooltip-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow {
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow::before {
  inset-inline-start: 0;
  border-block-start-width: 0.4rem;
  border-inline-end-width: 0;
  border-block-end-width: 0.4rem;
  border-inline-start-width: 0.4rem;
  border-inline-start-color: rgb(var(--background-rgb));
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: rgb(var(--background-rgb));
  border-radius: 3px;
}

.tooltip-static-demo {
  text-align: center;
  background-color: var(--bg-hover);
  padding: 20px;
}

.tooltip-static-demo .tooltip {
  z-index: 0;
  opacity: 1;
  position: relative;
  display: inline-block;
  margin: 0 10px;
}

.tooltip-static-demo .bs-tooltip-top .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-bottom .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="bottom"] {
  inset-inline-start: 50%;
  margin-inline-start: -2px;
}

.tooltip-static-demo .bs-tooltip-start,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="left"],
.tooltip-static-demo .bs-tooltip-end,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="right"] {
  margin-block-start: 5px;
}

.tooltip-static-demo .tooltip-primary1.bs-tooltip-start .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  inset-block-start: 50%;
  margin-block-start: -6px;
}

.tooltip-static-demo .bs-tooltip-start .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-end .tooltip-arrow,
.tooltip-static-demo .bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow {
  inset-block-start: 50%;
  margin-block-start: -6px;
}

.bs-tooltip-auto[data-popper-placement^="right"] .tooltip-arrow,
.bs-tooltip-end .tooltip-arrow {
  inset-inline-start: 0;
  inset-inline-end: auto;
}

.bs-tooltip-auto[data-popper-placement^="left"] .tooltip-arrow,
.bs-tooltip-start .tooltip-arrow {
  inset-inline-start: auto;
  inset-inline-end: 0;
}

.tabs-style1 {
  border: 1px solid var(--border);
  border-radius: 3px;
  overflow: hidden;
}

.tabs-style1>.panel-head {
  background: var(--bg-hover);
}

.tabs-style1>.panel-body {
  border: none;
}

.tabs-style2>.panel-head>.nav-tabs {
  padding: 0px 0px 10px !important;
  border-block-end: 0;
}

.tabs-style2>.panel-head>.nav-tabs.p-0 {
  padding: 0px !important;
}

.tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-radius: 0.25rem;
  border-color: var(--primary03);
  border-block-end: 1px solid var(--primary03);
  color: var(--muted);
}

.tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  color: var(--primary-bg-color);
  background: var(--black-03);
}

.tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.tabs-style2>.panel-body {
  border-radius: 3px;
}

.tabs-style3 {
  display: flex;
}

.tabs-style3>.panel-head>.nav-tabs {
  flex-direction: column;
  padding: 0px !important;
  border-block-end: 0;
}

.tabs-style3>.panel-head>.nav-tabs>.nav-item+.nav-item {
  margin-inline-start: 0px;
  margin-block-start: 10px;
}

.tabs-style3>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-radius: 0.25rem;
  border-color: var(--border);
  border-block-end: 1px solid var(--border);
  color: var(--muted);
}

.tabs-style3>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  color: var(--primary-bg-color);
  background: var(--black-03);
}

.tabs-style3>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.tabs-style3>.panel-body {
  border-radius: 3px;
  margin-inline-start: 10px;
  flex: 1;
}

.tabs-style4 {
  display: flex;
}

.tabs-style4>.panel-head {
  order: 1;
}

.tabs-style4>.panel-head>.nav-tabs {
  flex-direction: column;
  padding: 0px !important;
  border-block-end: 0;
}

.tabs-style4>.panel-head>.nav-tabs>.nav-item+.nav-item {
  margin-inline-start: 0px;
  margin-block-start: 10px;
}

.tabs-style4>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-radius: 0.25rem;
  border-color: var(--border);
  border-block-end: 1px solid var(--border);
  color: var(--muted);
}

.tabs-style4>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  color: var(--primary-bg-color);
  background: var(--black-03);
}

.tabs-style4>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.tabs-style4 .panel-body {
  order: 0;
  flex: 1;
  border-radius: 3px;
  margin-inline-end: 10px;
}

.tabs-style5>.panel-head>.nav-tabs {
  padding: 0.5rem !important;
  background: var(--black-03);
  border-radius: 30px;
  border-block-end: 0;
}

.tabs-style5>.panel-head>.nav-tabs>.nav-item>.nav-link {
  padding: 0.5rem 1rem;
  border-radius: 30px;
  border: none;
  color: var(--muted);
}

.tabs-style5>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  color: var(--primary-bg-color);
  background: var(--black-03);
}

.tabs-style5>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  background: var(--primary-bg-color);
  color: #000;
}

.tabs-style5>.panel-body {
  border: none;
}

.tabs-style6>.panel-head>.nav-tabs {
  padding: 0.24rem !important;
  background: var(--primary005);
  border-radius: 30px;
  border-block-end: 0;
}

.tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link {
  padding: 3px 10px;
  font-size: 10px;
  border-radius: 30px;
  border: none;
  color: var(--muted);
}

.tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  color: var(--primary-bg-color);
  background: var(--bg-hover);
}

.tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  background: var(--primary01);
  color: var(--primary-bg-color);
}

.tabs-style6>.panel-body {
  border: none;
}

.tabs-style7>.panel-head>.nav-tabs {
  border-block-end: none;
  padding: 0px !important;
}

.tabs-style7>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-block-end: 2px solid transparent;
  color: var(--muted);
}

.tabs-style7>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover,
.tabs-style7>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):focus {
  color: var(--primary-bg-color);
  background: none;
  border-color: transparent;
  border-block-end-color: transparent;
}

.tabs-style7>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: var(--primary-bg-color);
  background-color: transparent;
  border-color: transparent transparent var(--primary-bg-color) transparent;
}

.tabs-style7>.panel-body {
  border: none;
}

.tabs-style7.scaleX>.panel-head .nav-tabs>.nav-item>.nav-link {
  position: relative;
  border-block-end: none;
}

.tabs-style7.scaleX>.panel-head .nav-tabs>.nav-item>.nav-link:focus::before,
.tabs-style7.scaleX>.panel-head .nav-tabs>.nav-item>.nav-link.active::before {
  transform: scaleX(1);
}

.tabs-style7.scaleX>.panel-head .nav-tabs>.nav-item>.nav-link::before {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-inline-start: 0%;
  height: 2px;
  width: 100%;
  transform: scaleX(0);
  transform-origin: center;
  background-color: var(--primary-bg-color);
  transition: transform ease 300ms;
}

/* CUSTOM-STYLES */
/* Checkbox */
.ckbox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 1;
}

.ckbox span {
  padding-inline-start: 15px;
}

.ckbox span:empty {
  float: var(--float-start);
}

.ckbox span:before,
.ckbox span:after {
  line-height: 18px;
  position: absolute;
}

.ckbox span:before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--form-border);
  inset-block-start: 1px;
  inset-inline-start: 0;
  border-radius: 3px;
}

.ckbox span:after {
  inset-block-start: 1px;
  inset-inline-start: 0;
  width: 16px;
  height: 16px;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
  background-size: 65%;
  background-repeat: no-repeat;
  background-position: 55% 45%;
  background-color: var(--primary-bg-color);
  line-height: 17px;
  display: none;
  border-radius: 3px;
}

.ckbox span:empty {
  padding-inline-start: 0;
  width: 3px;
}

.ckbox input[type="checkbox"] {
  opacity: 0;
  margin: 0;
}

.ckbox input[type="checkbox"]:checked+span:after {
  display: block;
}

.ckbox input[type="checkbox"][disabled]+span {
  opacity: 0.75;
}

.ckbox input[type="checkbox"][disabled]+span:before,
.ckbox input[type="checkbox"][disabled]+span:after {
  opacity: 0.75;
}

.ckbox-inline {
  display: inline-block;
}

.custom-control-label-md {
  padding: 2px 10px;
  font-size: 14px;
}

.custom-control-label-lg {
  padding: 0.25rem 1.25rem;
  font-size: 1rem;
}

.custom-switch {
  padding-inline-start: 0 !important;
}

/*---Count-down---*/
#count-down {
  position: relative;
  text-align: center;
  display: inline-block;
}

#count-down .clock-presenter {
  height: 80px;
  padding: 0px;
  text-align: center;
  width: 68px;
  height: 68px;
  float: var(--float-start);
  margin-block-start: 0;
  margin-inline-end: 20px;
  margin-block-end: 0;
  margin-inline-start: 0;
  border-radius: 3px;
  background-size: cover;
  position: relative;
}

#count-down .clock-presenter .digit {
  margin-block-start: 5px;
  font-size: 1.5rem;
  color: rgb(var(--background-rgb));
  line-height: 60px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
  text-align: center;
  position: relative;
  cursor: default;
  font-weight: 600;
  border-radius: 50%;
}

#count-down .clock-presenter:before {
  content: "";
  position: absolute;
  background: linear-gradient(to left,
      var(--primary-bg-color) 0%,
      var(--primary-bg-color) 100%) !important;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-end: 0;
  display: block;
  border-radius: 15%;
}

#count-down .clock-presenter .note {
  position: relative;
  margin-block-end: 0px;
  cursor: default;
  font-size: 16px;
  opacity: 0.7;
}

@media (max-width: 568px) {
  #count-down .clock-presenter {
    float: none !important;
    margin-block-end: 45px;
  }
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.3125rem;
  padding-inline-start: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-inline-end: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked~.custom-control-label::before {
  color: rgb(var(--background-rgb));
  border-color: var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 0.2rem var(--border);
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--border);
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: rgb(var(--background-rgb));
  background-color: var(--primary05);
  border-color: var(--primary05);
}

.custom-control-input:disabled~.custom-control-label {
  color: var(--black-5);
}

.custom-control-input:disabled~.custom-control-label::before {
  opacity: 0.5;
}

label.custom-control-lg {
  margin-block-end: 1rem !important;
}

.custom-control-label {
  position: relative;
  margin-block-end: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: transparent;
  border: var(--form-border) solid 1px;
}

.custom-control-label::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50%/50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 3px;
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  filter: invert(1);
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::before {
  border-color: var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.custom-checkbox .custom-control-input:indeterminate~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
  background-color: var(--primary05);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.05);
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
  filter: invert(1);
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: var(--primary05);
}

.custom-switch {
  padding-inline-start: 2.25rem;
}

.custom-switch .custom-control-label::before {
  inset-inline-start: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  inset-block-start: calc(0.15625rem + 2px);
  inset-inline-start: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: var(--gray-500);
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-switch .custom-control-input:checked~.custom-control-label::after {
  background-color: rgb(var(--background-rgb));
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: var(--primary05);
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding-block: 0.375rem;
  padding-inline-start: 1.75rem;
  padding-inline-end: 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-700);
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem center/8px 10px;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  border-radius: 3px;
  appearance: none;
}

.custom-select:focus {
  border-color: var(--border);
  outline: 0;
}

.custom-select[multiple],
.custom-select[size]:not([size="1"]) {
  height: auto;
  padding-inline-end: 0.75rem;
  background-image: none;
}

.custom-select:disabled {
  color: var(--gray-600);
  background-color: var(--gray-200);
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-block-start: 0.25rem;
  padding-block-end: 0.25rem;
  padding-inline-start: 0.5rem;
  font-size: 0.76562rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-block-start: 0.5rem;
  padding-block-end: 0.5rem;
  padding-inline-start: 1rem;
  font-size: 1.09375rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-block-end: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus~.custom-file-label {
  border-color: var(--border);
  box-shadow: 0 0 0 0.2rem var(--border);
}

.custom-file-input:disabled~.custom-file-label {
  background-color: var(--gray-200);
}

.custom-file-input:lang(en)~.custom-file-label::after {
  content: "Browse";
}

.custom-file-input~.custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--gray-700);
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  border-radius: 3px;
}

.custom-file-label::after {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: var(--gray-700);
  content: "Browse";
  background-color: var(--gray-200);
  border-inline-start: inherit;
  border-start-start-radius: 0;
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
  border-end-start-radius: 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px rgb(var(--background-rgb)), 0 0 0 0.2rem var(--border);
}

.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-block-start: -0.25rem;
  background-color: var(--primary-bg-color);
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  appearance: none;
}

.custom-range::-webkit-slider-thumb:active {
  background-color: var(--primary05);
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: rgb(var(--background-rgb));
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: var(--gray-500);
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {

  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.custom-file,
.custom-file-input {
  height: 38px;
}

.custom-file-label {
  height: 38px;
  line-height: 1.8;
  border-radius: 0;
}

.custom-file-label::after {
  line-height: 1.8;
  border-radius: 0;
  height: auto;
}

.custom-select {
  background: none;
}

.custom-control-label-lg::before {
  position: absolute;
  inset-block-start: 0.15625rem;
  inset-inline-start: -1.5rem;
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  pointer-events: none;
  content: "";
  background-color: rgb(var(--background-rgb));
  border: var(--border) solid 1px;
  border-radius: 35px;
}

.custom-control-label-lg::after {
  position: absolute;
  inset-block-start: 0.15625rem;
  inset-inline-start: -1.5rem;
  display: block;
  width: 1.75rem;
  height: 1.75rem;
  content: "";
  background: no-repeat 50%/50% 50%;
  border-radius: 35px;
}

.custom-control-label-md::before,
.custom-control-label-md::after {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.custom-control-label-lg::before,
.custom-control-label-lg::after {
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: var(--primary-bg-color);
  opacity: 0.2;
}

.custom-radio .custom-control-input:disabled:checked~.custom-control-label::before {
  background-color: var(--primary-bg-color);
  opacity: 0.2;
}

.custom-switch-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-switch-indicator {
  display: inline-block;
  height: 1.25rem;
  width: 2.25rem;
  background: var(--bg-hover);
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  cursor: pointer;
  border: 1px solid var(--primary01);
  transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator:before {
  content: "";
  position: absolute;
  height: calc(1.25rem - 4px);
  width: calc(1.25rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  background: rgb(var(--background-rgb));
  border: 1px solid var(--primary01);
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: 0 1px 2px 0 var(--shadow-color);
}

.custom-switch-input:checked~.custom-switch-indicator {
  background: var(--primary-bg-color);
}

.custom-switch-input:checked~.custom-switch-indicator:before {
  inset-inline-start: calc(1rem + 1px);
}

.custom-square {
  border-radius: 0px !important;
}

.custom-square:before {
  border-radius: 0px !important;
}

.custom-switch-indicator-lg {
  display: inline-block;
  height: 1.5rem;
  width: 3rem;
  background: var(--bg-hover);
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid var(--border);
  transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator-lg:before {
  content: "";
  position: absolute;
  height: calc(1.5rem - 4px);
  width: calc(1.5rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  background: rgb(var(--background-rgb));
  border: 1px solid var(--primary01);
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: 0 1px 2px 0 var(--shadow-color);
}

.custom-switch-input:checked~.custom-switch-indicator-lg:before {
  inset-inline-start: calc(1.46rem + 1px);
}

.custom-switch-indicator-xl {
  display: inline-block;
  height: 2rem;
  width: 4rem;
  background: var(--bg-hover);
  border-radius: 50px;
  position: relative;
  vertical-align: bottom;
  border: 1px solid var(--border);
  transition: 0.3s border-color, 0.3s background-color;
}

.custom-switch-indicator-xl:before {
  content: "";
  position: absolute;
  height: calc(2rem - 4px);
  width: calc(2rem - 4px);
  inset-block-start: 1px;
  inset-inline-start: 1px;
  border: 1px solid var(--primary01);
  background: rgb(var(--background-rgb));
  border-radius: 50%;
  transition: 0.3s left;
  box-shadow: 0 1px 2px 0 var(--shadow-color);
}

.custom-switch-input:checked~.custom-switch-indicator-xl:before {
  inset-inline-start: calc(1.96rem + 1px) !important;
}

.form-select:focus {
  box-shadow: none;
}

.item-card .cardprice-2 span.type--strikethrough {
  opacity: 0.5;
  text-decoration: line-through;
  font-size: 14px;
  font-weight: 400;
}

.item-card .cardprice-2 span {
  display: block;
  font-size: 18px;
  font-weight: 500;
}

.item-card .cardprice-2 {
  position: absolute;
  inset-inline-end: 15px;
}

img {
  vertical-align: middle;
  border-style: none;
  max-width: 100%;
}

.img-thumbnail .caption {
  padding: 15px;
}

.avatar-group {
  display: flex;
  align-items: center;
}

.avatar-group .avatar,
.avatar-group .avatar-status,
.avatar-group .avatar-badge {
  margin: 2px;
}

.avatar {
  position: relative;
  width: 42px;
  height: 42px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
}

.avatar-xs {
  width: 24px !important;
  height: 24px !important;
  font-size: 11px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-xs::after {
  width: 4px !important;
  height: 4px !important;
  inset-block-end: 1px !important;
  inset-inline-end: 2px !important;
}

.avatar-sm {
  width: 32px !important;
  height: 32px !important;
  font-size: 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-sm::after {
  width: 5px !important;
  height: 5px !important;
}

.avatar-lg {
  width: 60px !important;
  height: 60px !important;
  font-size: 28px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-lg::after {
  width: 10px !important;
  height: 10px !important;
  inset-block-end: 3px !important;
  inset-inline-end: 4px !important;
}

.avatar-xl {
  width: 84px !important;
  height: 84px !important;
  font-size: 36px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-xl::after {
  width: 11px !important;
  height: 11px !important;
  inset-block-end: 4px !important;
  inset-inline-end: 5px !important;
}

.avatar-xxl {
  width: 100px !important;
  height: 100px !important;
  font-size: 56px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.avatar-xxl::after {
  width: 13px !important;
  height: 13px !important;
  inset-block-end: 6px !important;
  inset-inline-end: 7px !important;
}

.avatar-status {
  position: relative;
}

.avatar-status::after {
  content: "";
  position: absolute;
  inset-block-end: 3px;
  inset-inline-end: 3px;
  width: 7px;
  height: 7px;
  background-color: #43ce85;
  box-shadow: 0 0 0 2px var(--shadow-color);
  border-radius: 100%;
  display: block !important;
}

.avatar-status.offline::after {
  background-color: var(--gray-500);
}

.wrapper.image-group .img-xs.rounded-circle {
  margin-inline-end: -5px;
}

.number-badge {
  position: absolute;
  inset-block-start: -7px;
  inset-inline-end: -5px;
  padding: 3px;
  font-size: 11px;
  line-height: 1;
  border: 2px solid var(--border);
  height: 20px;
  width: 20px;
}

.avatar-badge,
.avatar-btn {
  position: relative;
}

.avatar-badge.avatar-xs .number-badge {
  font-size: 7px;
  inset-block-start: -7px;
  inset-inline-end: -6px;
  width: 16px;
  height: 16px;
}

.avatar-badge.avatar-sm .number-badge {
  inset-block-start: -8px;
  inset-inline-end: -6px;
  font-size: 9px;
  height: 18px;
  width: 18px;
}

.avatar-badge.avatar-lg .number-badge {
  height: 26px;
  width: 26px;
  line-height: 1.3;
  font-size: 12px;
}

.avatar-badge.avatar-xl .number-badge {
  height: 30px;
  width: 30px;
  line-height: 1.5;
  font-size: 14px;
}

.avatar-badge.avatar-xxl .number-badge {
  height: 32px;
  width: 32px;
  line-height: 1.5;
  font-size: 15px;
}

.avatar-list-stacked {
  display: flex;
  align-items: center;
}

.avatar-list-stacked> :not(:first-child) {
  margin-inline-start: -0.8em !important;
}

.avatar-list-stacked>.avatar {
  box-shadow: 0 0 0 2px var(--shadow);
  transition: transform ease 200ms;
}

.avatar-list-stacked>.avatar:hover {
  z-index: 1;
  transform: translateY(-3px);
}

/* ###### 5.5 List   ###### */
.list-group.custom-list-group .list-group-item {
  margin-block-start: -1px;
}

.list-group-item-action {
  color: var(--color2);
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: var(--primary-bg-color);
  color: #000;
}

.list-group-item-action:active {
  color: #000;
  background-color: var(--primary-bg-color);
}

.list-group-item {
  padding: 0.85rem 1.25rem;
  border: 1px solid var(--border);
}

.list-group-item:first-child {
  border-start-start-radius: 3px;
  border-start-end-radius: 3px;
}

.list-group-item:last-child {
  margin-block-end: 0;
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--gray-600);
}

.list-group-item.active {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color) !important;
  color: #000 !important;
}

.list-group-horizontal {
  flex-direction: row;
}

.list-group-horizontal.custom-list-group-horizontal .list-group-item {
  margin-inline-end: -1px;
}

.list-group-horizontal .list-group-item {
  margin-block-end: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-start-start-radius: 3px;
  border-end-start-radius: 3px;
  border-start-end-radius: 0;
}

.list-group-horizontal .list-group-item:last-child {
  margin-inline-end: 0;
  border-start-end-radius: 3px;
  border-end-end-radius: 3px;
  border-end-start-radius: 0;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }

  .list-group-horizontal-sm.custom-list-group-horizontal .list-group-item {
    margin-inline-end: -1px;
  }

  .list-group-horizontal-sm .list-group-item {
    margin-block-end: 0;
  }

  .list-group-horizontal-sm .list-group-item:first-child {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
    border-start-end-radius: 0;
  }

  .list-group-horizontal-sm .list-group-item:last-child {
    margin-inline-end: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
    border-end-start-radius: 0;
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }

  .list-group-horizontal-md.custom-list-group-horizontal .list-group-item {
    margin-inline-end: -1px;
  }

  .list-group-horizontal-md .list-group-item {
    margin-block-end: 0;
  }

  .list-group-horizontal-md .list-group-item:first-child {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
    border-start-end-radius: 0;
  }

  .list-group-horizontal-md .list-group-item:last-child {
    margin-inline-end: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
    border-end-start-radius: 0;
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }

  .list-group-horizontal-lg.custom-list-group-horizontal .list-group-item {
    margin-inline-end: -1px;
  }

  .list-group-horizontal-lg .list-group-item {
    margin-block-end: 0;
  }

  .list-group-horizontal-lg .list-group-item:first-child {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
    border-start-end-radius: 0;
  }

  .list-group-horizontal-lg .list-group-item:last-child {
    margin-inline-end: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
    border-end-start-radius: 0;
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }

  .list-group-horizontal-xl.custom-list-group-horizontal .list-group-item {
    margin-inline-end: -1px;
  }

  .list-group-horizontal-xl .list-group-item {
    margin-block-end: 0;
  }

  .list-group-horizontal-xl .list-group-item:first-child {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
    border-start-end-radius: 0;
  }

  .list-group-horizontal-xl .list-group-item:last-child {
    margin-inline-end: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
    border-end-start-radius: 0;
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }

  .list-group-horizontal-xxl.custom-list-group-horizontal .list-group-item {
    margin-inline-end: -1px;
  }

  .list-group-horizontal-xxl .list-group-item {
    margin-block-end: 0;
  }

  .list-group-horizontal-xxl .list-group-item:first-child {
    border-start-start-radius: 3px;
    border-end-start-radius: 3px;
    border-start-end-radius: 0;
  }

  .list-group-horizontal-xxl .list-group-item:last-child {
    margin-inline-end: 0;
    border-start-end-radius: 3px;
    border-end-end-radius: 3px;
    border-end-start-radius: 0;
  }
}

.list-group-flush .list-group-item {
  border-inline-end: 0;
  border-inline-start: 0;
  border-radius: 0;
}

.list-group-flush .list-group-item:last-child {
  margin-block-end: -1px;
}

.list-group-flush:first-child .list-group-item:first-child {
  border-block-start: 0;
}

.list-group-flush:last-child .list-group-item:last-child {
  margin-block-end: 0;
  border-block-end: 0;
}

.list-group-item-primary {
  color: var(--primary-bg-color);
  background: var(--primary02);
}

.list-group-item-primary.list-group-item-action:hover,
.list-group-item-primary.list-group-item-action:focus {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
}

.list-group-item-primary.list-group-item-action.active {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-border);
}

.list-group-item-secondary {
  color: var(--gray-700);
  background-color: rgba(var(--secondary), 0.13);
}

.list-group-item-secondary.list-group-item-action:hover,
.list-group-item-secondary.list-group-item-action:focus {
  color: var(--dark);
  background-color: var(--secondary);
}

.list-group-item-secondary.list-group-item-action.active {
  color: var(--dark);
  background-color: var(--secondary);
  border-color: var(--secondary);
}

.list-group-item-success {
  color: #43ce85;
  background-color: rgba(67, 206, 133, 0.13);
}

.list-group-item-success.list-group-item-action:hover,
.list-group-item-success.list-group-item-action:focus {
  color: rgb(var(--background-rgb));
  background-color: #43ce85;
}

.list-group-item-success.list-group-item-action.active {
  color: rgb(var(--background-rgb));
  background-color: #43ce85;
  border-color: #43ce85;
}

.list-group-item-info {
  color: #4ec2f0;
  background-color: rgba(78, 194, 240, 0.13);
}

.list-group-item-info.list-group-item-action:hover,
.list-group-item-info.list-group-item-action:focus {
  color: rgb(var(--background-rgb));
  background-color: #4ec2f0;
}

.list-group-item-info.list-group-item-action.active {
  color: rgb(var(--background-rgb));
  background-color: #4ec2f0;
  border-color: #4ec2f0;
}

.list-group-item-warning {
  color: var(--gray-700);
  background-color: rgba(255, 207, 134, 0.13);
}

.list-group-item-warning.list-group-item-action:hover,
.list-group-item-warning.list-group-item-action:focus {
  color: var(--dark);
  background-color: #ffcf86;
}

.list-group-item-warning.list-group-item-action.active {
  color: var(--dark);
  background-color: #ffcf86;
  border-color: #ffcf86;
}

.list-group-item-danger {
  color: #d6573c;
  background-color: rgba(214, 87, 60, 0.13);
}

.list-group-item-danger.list-group-item-action:hover,
.list-group-item-danger.list-group-item-action:focus {
  color: rgb(var(--background-rgb));
  background-color: #d6573c;
}

.list-group-item-danger.list-group-item-action.active {
  color: rgb(var(--background-rgb));
  background-color: rgb(var(--background-rgb));
  border-color: rgb(var(--background-rgb));
}

.list-group-item-light {
  color: var(--gray-900);
  background-color: var(--bg-hover);
}

.list-group-item-light.list-group-item-action:hover,
.list-group-item-light.list-group-item-action:focus {
  color: var(--color2);
  background-color: var(--bg-hover);
}

.list-group-item-light.list-group-item-action.active {
  color: var(--color2);
  background-color: var(--bg-hover);
  border-color: var(--bg-hover);
}

.list-group-item-dark {
  color: var(--dark);
  background-color: rgba(0, 0, 0, 0.13);
}

.list-group-item-dark.list-group-item-action:hover,
.list-group-item-dark.list-group-item-action:focus {
  color: rgb(var(--background-rgb));
  background-color: var(--dark);
}

.list-group-item-dark.list-group-item-action.active {
  color: rgb(var(--background-rgb));
  background-color: var(--dark);
  border-color: var(--dark);
}

.unlist-style-1 {
  list-style: disc;
}

.unlist-style-2 {
  list-style: circle;
}

.unlist-style-3 {
  list-style: square;
}

.list-style-1 {
  list-style: decimal;
}

.list-style-2 {
  list-style: lower-alpha;
}

.list-style-3 {
  list-style: lower-roman;
}

.unlist-style-1 li:not(:last-child),
.unlist-style-2 li:not(:last-child),
.unlist-style-3 li:not(:last-child),
.list-style-1 li:not(:last-child),
.list-style-2 li:not(:last-child),
.list-style-3 li:not(:last-child),
.list-unstyled li:not(:last-child) {
  margin-block-end: 20px;
}

.list-group-item {
  color: var(--color);
  background-color: transparent;
  border: 1px solid var(--primary01);
}

.list-group-item.disabled,
.list-group-item:disabled {
  color: var(--color);
  background-color: var(--bg-hover);
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
}

.navbar>.container,
.navbar>.container-fluid {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-inline-start: 0;
  margin-block-end: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-toggler {
  font-size: 1.09375rem;
  color: #272746;
  position: relative;
  line-height: 35px;
  outline: none;
  height: 37px;
  width: 37px;
  text-align: center;
  border: 0px solid #dfd7e9;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.7em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
  color: var(--primary-bg-color);
  filter: drop-shadow(0 2px 5px var(--primary-bg-color));
}

@media (max-width: 991.98px) {

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    padding-inline-end: 0;
    padding-inline-start: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-inline-end: 0.5rem;
    padding-inline-start: 0.5rem;
  }

  .navbar-expand-lg>.container,
  .navbar-expand-lg>.container-fluid {
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .main-header {
    display: flex !important;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

.navbar-expand .navbar-nav {
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
  padding-inline-end: 0.5rem;
  padding-inline-start: 0.5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid {
  flex-wrap: nowrap;
}

.navbar-expand .main-header {
  display: flex !important;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-dark .navbar-nav .nav-link {
  color: var(--white-5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show>.nav-link,
.navbar-dark .navbar-nav .active>.nav-link {
  color: rgb(var(--background-rgb));
}

.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: rgb(var(--background-rgb));
}

.navbar-dark .navbar-toggler {
  color: var(--white-5);
  border-color: var(--white-1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='var(--white-5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

@media print {
  .navbar {
    display: none;
  }
}

.navbar-form.active .input-group-btn {
  position: absolute;
  inset-inline-end: 9px;
  z-index: 9999;
  inset-block-start: 4.3rem;
}

.navbar-form.nav-item.active .btn.btn-default.nav-link {
  display: none;
}

@media (min-width: 992px) {
  .navbar-form {
    display: none;
  }
}

@media (max-width: 991px) {
  .navbar-form.active .btn.btn-default.nav-link.resp-btn {
    display: none !important;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg.responsive-navbar .navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    background: transparent;
  }
}

#verticallpill .nav-pills .nav-link {
  padding-block: 0.75rem;
  padding-inline-start: 0;
  padding-inline-end: 0.75rem;
}

/* ###### 4.9 Perfect Scrollbar ###### */
.ps {
  overflow: hidden;
}

.ps>.ps__rail-y {
  width: 5px;
  background-color: transparent;
  z-index: 10;
  position: absolute;
  inset-inline-start: auto !important;
  inset-inline-end: 0;
  opacity: 0;
  transition: opacity 0.2s;
}

.ps>.ps__rail-y>.ps__thumb-y {
  position: absolute;
  border-radius: 0;
  width: 5px;
}

.ps.ps--active-y:hover>.ps__rail-y,
.ps.ps--active-y:focus>.ps__rail-y {
  opacity: 0.45;
}

@media (prefers-reduced-motion: reduce) {
  .ps>.ps__rail-y {
    transition: none;
  }
}

.ps__rail-y:hover>.ps__thumb-y,
.ps__rail-y:focus>.ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  width: 8px;
}

/* Radio Box */
.rdiobox {
  font-weight: normal;
  position: relative;
  display: block;
  line-height: 18px;
}

.rdiobox span {
  padding-inline-start: 15px;
}

.rdiobox span:before,
.rdiobox span:after {
  line-height: 18px;
  position: absolute;
}

.rdiobox span:before {
  content: "";
  width: 16px;
  height: 16px;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--form-border);
  border-radius: 50px;
  inset-block-start: 2px;
  inset-inline-start: 0;
}

.rdiobox span:after {
  content: "";
  width: 6px;
  height: 6px;
  background-color: rgb(var(--background-rgb));
  border-radius: 50px;
  inset-block-start: 7px;
  inset-inline-start: 5px;
  display: none;
}

.rdiobox span:empty {
  padding-inline-start: 0;
  width: 16px;
  display: block;
}

.rdiobox input[type="radio"] {
  opacity: 0;
  margin: 0;
}

.rdiobox input[type="radio"]:checked+span:before {
  border-color: transparent;
  background-color: var(--primary-bg-color);
}

.rdiobox input[type="radio"]:checked+span:after {
  display: block;
}

.rdiobox input[type="radio"][disabled]+span {
  opacity: 0.75;
}

.rdiobox input[type="radio"][disabled]+span:before,
.rdiobox input[type="radio"][disabled]+span:after {
  opacity: 0.75;
}

.rdiobox-inline {
  display: inline-block;
}

.form-group-rdiobox {
  display: flex;
  align-items: center;
}

.form-group-rdiobox .rdiobox {
  margin-block-end: 0;
}

.form-group-rdiobox .rdiobox+.rdiobox {
  margin-inline-start: 30px;
}

.form-group-rdiobox .rdiobox span {
  padding-inline-start: 0;
}

.rating-stars i {
  padding: 5px;
}

.tags {
  margin-block-end: -0.5rem;
  font-size: 0;
}

.tags>.tag {
  margin-block-end: 0.5rem;
}

.tags>.tag:not(:last-child) {
  margin-inline-end: 0.2rem;
}

.bootstrap-tagsinput>.tag:not(:last-child) {
  margin-inline-end: 0.2rem;
  margin-block-end: 0.2rem;
}

.tags-group .tag-item {
  position: relative;
}

.tags-group .tag-item .tag {
  background-color: var(--primary02);
  border-color: var(--primary02);
  color: var(--primary-bg-color);
  cursor: pointer;
}

.tags-group .tag-item .selectgroup-input:checked+.tag {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  color: #000;
}

.tag {
  font-size: 0.75rem;
  color: var(--color);
  border: 1px solid var(--border);
  border-radius: 3px;
  padding: 2px 0.55rem;
  line-height: 2em;
  display: -ms-inline-flexbox;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tag .tag-addon {
  display: inline-block;
  color: inherit;
  text-decoration: none;
  background: var(--bg-hover);
  margin-block-start: 0;
  margin-inline-end: -0.4rem;
  margin-block-end: 0;
  margin-inline-start: 0.5rem;
  text-align: center;
  min-width: 1.5rem;
  text-decoration: none;
  cursor: pointer;
  transition: 0.3s color, 0.3s background;
}

.tag .tag-addon:last-child {
  border-radius: 3px;
}

.tag .tag-addon:hover {
  background: var(--gray-200);
  color: inherit;
}

.tag .tag-addon i {
  vertical-align: middle;
  margin: 0 -0.25rem;
  line-height: 1.5px;
  font-size: 13px;
}

.tag.tag-primary {
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  color: #000 !important;
}

.tag.tag-primary .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-primary .tag-addon:hover {
  opacity: 1;
}

.tag.tag-secondary {
  background-color: var(--secondary);
  border-color: var(--secondary);
  color: #000 !important;
}

.tag.tag-secondary .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-secondary .tag-addon:hover {
  opacity: 1;
}

.tag.tag-success {
  background-color: #43ce85;
  border-color: #43ce85;
  color: #fff !important;
}

.tag.tag-success .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-success .tag-addon:hover {
  opacity: 1;
}

.tag.tag-info {
  background-color: #4ec2f0;
  border-color: #4ec2f0;
  color: #fff !important;
}

.tag.tag-info .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-info .tag-addon:hover {
  opacity: 1;
}

.tag.tag-warning {
  background-color: #ffcf86;
  border-color: #ffcf86;
  color: #000 !important;
}

.tag.tag-warning .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-warning .tag-addon:hover {
  opacity: 1;
}

.tag.tag-danger {
  background-color: #d6573c;
  border-color: #d6573c;
  color: #fff !important;
}

.tag.tag-danger .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-danger .tag-addon:hover {
  opacity: 1;
}

.tag.tag-light {
  background-color: var(--gray-100);
  border-color: var(--gray-100);
  color: var(--gray-600) !important;
}

.tag.tag-light .tag-addon {
  background-color: transparent;
}

.tag.tag-dark {
  background-color: var(--gray-900);
  border-color: var(--gray-900);
  color: rgb(var(--background-rgb)) !important;
}

.tag.tag-dark .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-dark .tag-addon:hover {
  opacity: 1;
}

.tag.tag-primary-transparent {
  background-color: var(--primary02);
  border-color: var(--primary02);
  color: var(--primary-bg-color) !important;
}

.tag.tag-primary-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-primary-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-secondary-transparent {
  background-color: rgba(var(--secondary), 0.35);
  border-color: rgba(var(--secondary), 0.35);
  color: var(--secondary) !important;
}

.tag.tag-secondary-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-secondary-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-info-transparent {
  background-color: rgba(78, 194, 240, 0.15);
  border-color: rgba(78, 194, 240, 0.15);
  color: #4ec2f0 !important;
}

.tag.tag-info-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-info-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-warning-transparent {
  background-color: rgba(255, 207, 134, 0.35);
  border-color: rgba(255, 207, 134, 0.35);
  color: var(--gray-700) !important;
}

.tag.tag-warning-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-warning-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-success-transparent {
  background-color: rgba(67, 206, 133, 0.15);
  border-color: rgba(67, 206, 133, 0.15);
  color: #43ce85 !important;
}

.tag.tag-success-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-success-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-danger-transparent {
  background-color: rgba(214, 87, 60, 0.15);
  border-color: rgba(214, 87, 60, 0.15);
  color: #d6573c !important;
}

.tag.tag-danger-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-danger-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-light-transparent {
  background-color: var(--gray-200);
  border-color: var(--gray-200);
  color: var(--gray-700) !important;
}

.tag.tag-light-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-light-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-dark-transparent {
  background-color: var(--gray-400);
  border-color: var(--gray-400);
  color: var(--gray-900) !important;
}

.tag.tag-dark-transparent .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-dark-transparent .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-primary {
  background-color: transparent !important;
  border-color: var(--primary-bg-color) !important;
  color: var(--primary-bg-color) !important;
}

.tag.tag-outline-primary .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-primary .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-secondary {
  background-color: transparent !important;
  border-color: var(--secondary) !important;
  color: var(--secondary) !important;
}

.tag.tag-outline-secondary .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-secondary .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-info {
  background-color: transparent !important;
  border-color: #4ec2f0 !important;
  color: #4ec2f0 !important;
}

.tag.tag-outline-info .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-info .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-success {
  background-color: transparent !important;
  border-color: #43ce85 !important;
  color: #43ce85 !important;
}

.tag.tag-outline-success .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-success .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-warning {
  background-color: transparent !important;
  border-color: #ffcf86 !important;
  color: #ffcf86 !important;
}

.tag.tag-outline-warning .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-warning .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-danger {
  background-color: transparent !important;
  border-color: #d6573c !important;
  color: #d6573c !important;
}

.tag.tag-outline-danger .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-danger .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-light {
  background-color: transparent !important;
  border-color: var(--border);
  color: var(--color) !important;
}

.tag.tag-outline-light .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-light .tag-addon:hover {
  opacity: 1;
}

.tag.tag-outline-dark {
  background-color: transparent !important;
  border-color: var(--gray-900);
  color: var(--gray-900) !important;
}

.tag.tag-outline-dark .tag-addon {
  background-color: transparent;
  opacity: 0.6;
}

.tag.tag-outline-dark .tag-addon:hover {
  opacity: 1;
}

a.tag {
  text-decoration: none;
  cursor: pointer;
}

.bootstrap-tagsinput .tag [data-role="remove"]::after {
  content: "\ea00";
  font-family: "feather" !important;
  speak-as: normal;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;
  margin: 0 -0.25rem;
  line-height: 1.5px;
  font-size: 13px;
}

.vtimeline {
  list-style: none;
  padding: 0;
  position: relative;
  margin-block-end: 20px;
}

.vtimeline::before {
  inset-block-start: 0;
  inset-block-end: 0;
  position: absolute;
  content: " ";
  width: 2px;
  border-inline-start: 2px dotted var(--border);
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.vtimeline .timeline-wrapper {
  display: block;
  margin-block-end: 20px;
  position: relative;
  width: 100%;
  padding-inline-end: 90px;
}

.vtimeline .timeline-wrapper::before {
  content: " ";
  display: table;
}

.vtimeline .timeline-wrapper::after {
  content: " ";
  display: table;
  clear: both;
}

.vtimeline .timeline-wrapper .timeline-badge {
  width: 45px;
  height: 45px;
  background: var(--primary-bg-color);
  position: absolute;
  inset-block-start: 16px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  z-index: 0;
  border: 0px solid rgb(var(--background-rgb));
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px var(--gray-300) !important;
}

.vtimeline .timeline-wrapper .timeline-badge i {
  color: rgb(var(--background-rgb));
  text-align: center;
  font-size: 25px;
  line-height: 1.8;
  display: flex;
  justify-content: center;
}

.vtimeline .timeline-wrapper .timeline-panel {
  position: relative;
  width: 35%;
  padding: 20px;
  background: var(--primary01);
  border-radius: 3px;
  margin-inline-start: 15%;
  box-shadow: none;
  border-block-start: 2px solid var(--primary-bg-color);
}

.vtimeline .timeline-wrapper .timeline-panel::after {
  content: " ";
  position: absolute;
  inset-block-start: 25px;
  inset-inline-end: -14px;
  display: inline-block;
  border-block-start: 14px solid transparent;
  border-inline-start: 14px solid var(--primary01);
  border-inline-end: 0 solid var(--primary01);
  border-block-end: 14px solid transparent;
}

.vtimeline .timeline-wrapper.timeline-inverted {
  padding-inline-end: 0;
  padding-inline-start: 90px;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
  margin-inline-start: auto;
  margin-inline-end: 15%;
}

.vtimeline .timeline-wrapper.timeline-inverted .timeline-panel::after {
  border-inline-start-width: 0;
  border-inline-end-width: 14px;
  inset-inline-start: -14px;
  inset-inline-end: auto;
}

@media (min-width: 425px) and (max-width: 520px) {
  .vtimeline .timeline-wrapper .timeline-panel {
    margin-inline-start: 20% !important;
  }
}

@media (min-width: 320px) and (max-width: 425px) {
  .vtimeline .timeline-wrapper .timeline-panel {
    margin-inline-start: 30% !important;
  }
}

@media (min-width: 100px) and (max-width: 320px) {
  .vtimeline .timeline-wrapper .timeline-panel {
    margin-inline-start: 29% !important;
  }
}

@media (max-width: 992px) {
  .vtimeline::before {
    inset-inline-start: 26px;
  }

  .vtimeline .timeline-wrapper {
    padding-inline-end: 0;
  }

  .vtimeline .timeline-wrapper .timeline-badge {
    inset-inline-start: 26px;
  }

  .vtimeline .timeline-wrapper .timeline-panel {
    width: auto;
    margin-inline-start: 15%;
  }

  .vtimeline .timeline-wrapper .timeline-panel::after {
    border-inline-end: 14px solid var(--gray-100);
    border-inline-start: 0 solid var(--gray-100);
    inset-inline-start: -14px;
    inset-inline-end: auto;
    inset-block-start: 25px;
  }

  .vtimeline .timeline-wrapper.timeline-inverted {
    padding-inline-start: 0;
  }

  .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel {
    margin-inline-end: 0;
    margin-inline-start: 15%;
  }
}

.main-toggle {
  width: 60px;
  height: 25px;
  background-color: var(--bg-hover);
  padding: 2px;
  position: relative;
  overflow: hidden;
}

.main-toggle span {
  position: absolute;
  inset-block-start: 3px;
  inset-block-end: 3px;
  inset-inline-start: 3px;
  display: block;
  width: 20px;
  background-color: rgb(var(--background-rgb));
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.main-toggle span::before,
.main-toggle span::after {
  position: absolute;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgb(var(--background-rgb));
  inset-block-start: 2px;
  line-height: 1.38;
}

.main-toggle span::before {
  content: "on";
  inset-inline-start: -25px;
}

.main-toggle span::after {
  content: "off";
  inset-inline-end: -29px;
}

.main-toggle.on {
  background-color: var(--primary-bg-color) !important;
}

.main-toggle.on span {
  inset-inline-start: 37px;
}

@media (prefers-reduced-motion: reduce) {
  .main-toggle span {
    transition: none;
  }
}

.main-toggle-secondary.on {
  background-color: var(--primary-bg-color) !important;
}

.main-toggle-success.on {
  background-color: #43ce85 !important;
}

.main-toggle-dark.on {
  background-color: var(--gray-900) !important;
}

.main-toggle-group-demo {
  display: flex;
}

.main-toggle-group-demo .main-toggle+.main-toggle {
  margin-inline-start: 10px;
}

* {
  box-sizing: border-box;
}

*::before,
*::after {
  box-sizing: border-box;
}

#global-loader {
  position: fixed;
  z-index: 50000;
  inset-inline-start: 0;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.loader-img {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-block-start: 43%;
  inset-inline-start: 0;
  margin: 0 auto;
  text-align: center;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }

  to {
    background-position: 0 0;
  }
}

.close {
  font-size: 1.3125rem;
  font-weight: 400;
  line-height: 1;
  color: var(--black);
  text-shadow: 0 1px 0 rgb(var(--background-rgb));
  opacity: 0.5;
  float: var(--float-end);
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

@media (max-width: 991px) {
  .close {
    font-size: 1.3125rem;
    font-weight: 400;
    line-height: 1;
    color: var(--black);
    text-shadow: 0 1px 0 rgb(var(--background-rgb));
    opacity: 0.5;
    position: absolute;
    inset-inline-end: 10px;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
  color: inherit;
}

a.close.disabled {
  pointer-events: none;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  vertical-align: text-bottom;
  animation: spinner-border 0.75s linear infinite;
  margin: 0 0 5px 0;
}

.spinner-border-xs {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

.spinner-grow-xs {
  width: 1rem;
  height: 1rem;
}

.spinner-border-sm {
  width: 1.5rem;
  height: 1.5rem;
  border-width: 0.2em;
}

.spinner-grow-sm {
  width: 1.5rem;
  height: 1.5rem;
}

.spinner-border-lg {
  width: 2.5rem;
  height: 2.5rem;
  border-width: 0.2em;
}

.spinner-grow-lg {
  width: 2.5rem;
  height: 2.5rem;
}

.spinner-border-xl {
  width: 3rem;
  height: 3rem;
  border-width: 0.2em;
}

.spinner-grow-xl {
  width: 3rem;
  height: 3rem;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  50% {
    transform: scale(0.2);
    opacity: 1;
  }
}

.spinner-grow {
  vertical-align: text-bottom;
  animation: spinner-grow 0.75s linear infinite;
}

.lds-circle {
  display: inline-block;
  transform: translateZ(1px);
}

.lds-circle>div {
  display: inline-block;
  width: 51px;
  height: 51px;
  background: var(--primary-bg-color);
  margin: 6px;
  border-radius: 50%;
  animation: lds-circle 2.4s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes lds-circle {

  0%,
  100% {
    animation-timing-function: cubic-bezier(0.5, 0, 1, 0.5);
  }

  0% {
    transform: rotateY(0deg);
  }

  50% {
    transform: rotateY(1800deg);
  }

  100% {
    transform: rotateY(3600deg);
  }
}

.lds-dual-ring {
  display: inline-block;
  width: 64px;
  height: 64px;
}

.lds-dual-ring::after {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border: 5px solid var(--primary-bg-color);
  border-color: var(--primary-bg-color) transparent var(--primary-bg-color) transparent;
  border-radius: 50%;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.lds-heart {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  transform: rotate(45deg);
  transform-origin: 32px 32px;
}

.lds-heart div {
  inset-block-start: 23px;
  inset-inline-start: 19px;
  position: absolute;
  width: 26px;
  height: 26px;
  animation: lds-heart 1.2s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  background: var(--primary-bg-color);
}

.lds-heart div::before,
.lds-heart div::after {
  content: " ";
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  background: var(--primary-bg-color);
}

.lds-heart div::before {
  inset-inline-start: -17px;
  border-start-start-radius: 50%;
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 50%;
}

.lds-heart div::after {
  inset-block-start: -17px;
  border-start-start-radius: 50%;
  border-start-end-radius: 50%;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

@keyframes lds-heart {
  0% {
    transform: scale(0.95);
  }

  5% {
    transform: scale(1.1);
  }

  39% {
    transform: scale(0.85);
  }

  45% {
    transform: scale(1);
  }

  60% {
    transform: scale(0.95);
  }

  100% {
    transform: scale(0.9);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ripple div {
  position: absolute;
  opacity: 1;
  border: 4px solid var(--primary-bg-color);
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    inset-block-start: 28px;
    inset-inline-start: 28px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    inset-block-start: -1px;
    inset-inline-start: -1px;
    width: 58px;
    height: 58px;
    opacity: 0;
  }
}

.lds-spinner {
  color: var(--primary-bg-color);
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-spinner div {
  transform-origin: 32px 32px;
  animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div::after {
  content: " ";
  display: block;
  position: absolute;
  inset-block-start: 3px;
  inset-inline-start: 29px;
  width: 5px;
  height: 14px;
  border-radius: 20%;
  background: var(--primary-bg-color);
}

.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.lds-lines {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-lines div {
  display: inline-block;
  position: absolute;
  background: var(--primary-bg-color);
  inset-inline-start: 6px;
  width: 7px;
  animation: lds-lines 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-lines div:nth-child(1) {
  inset-inline-start: 6px;
  animation-delay: -0.24s;
}

.lds-lines div:nth-child(2) {
  inset-inline-start: 24px;
  animation-delay: 0.01s;
}

.lds-lines div:nth-child(3) {
  inset-inline-start: 42px;
  animation-delay: 0.2s;
}

@keyframes lds-lines {
  0% {
    inset-block-start: 6px;
    height: 51px;
  }

  50%,
  100% {
    inset-block-start: 19px;
    height: 26px;
  }
}

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

.embed-responsive::before {
  display: block;
  content: "";
}

.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-block-start: 42.85714%;
}

.embed-responsive-16by9::before {
  padding-block-start: 56.25%;
}

.embed-responsive-4by3::before {
  padding-block-start: 75%;
}

.embed-responsive-1by1::before {
  padding-block-start: 100%;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.fixed-top {
  position: fixed;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-inline-start: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    inset-block-start: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem var(--shadow-color) !important;
}

.shadow {
  box-shadow: var(--shadow) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem var(--shadow-color) !important;
}

.shadow-none {
  box-shadow: none !important;
}

@media print {
  * {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
    border: 1px solid var(--gray-500);
    page-break-inside: avoid;
  }

  blockquote {
    border: 1px solid var(--gray-500);
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }

  body,
  .container {
    min-width: 992px !important;
  }
}

/* ###### 6.3 Global  ###### */
body {
  font-family: "Poppins", sans-serif;
  overflow: hidden auto !important;
  position: relative;
}

.main-body,
.main-dashboard {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
}

/* ###### 7.1 Demo  ###### */
@media (min-width: 992px) {
  .nav-link#bs-example-navbar-collapse-1 {
    display: none;
  }
}

.main-icon-group {
  text-align: center;
  padding: 20px;
  color: var(--gray-800);
}

.font-awesome.main-icon-group i {
  font-size: 14px;
}

.main-icon-group i {
  line-height: 3;
  font-size: 24px;
  font-size: 18px;
}

.brround {
  border-radius: 50%;
}

/* ############### UTILITIES/HELPER CLASSES ############### */
.h-6 {
  height: 2rem !important;
}

.flag .dropdown-menu {
  inset-block-start: 52px !important;
  border-radius: 6px;
  box-shadow: 0px 0px 15px 1px var(--shadow-color);
}

.flag>a::before {
  content: "";
  position: absolute;
  inset-block-start: 47px;
  inset-inline-start: 50%;
  margin-inline-start: -7px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-block-start-color: var(--gray-300);
  border-inline-start-color: var(--gray-300);
  transform: rotate(45deg);
  background-color: rgb(var(--background-rgb));
  z-index: 901;
  display: none;
}

.dropdown.flag .dropdown-item span {
  font-size: 14px;
}

.btn.btn-default.nav-link {
  height: 38px;
  width: 38px;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  font-size: 17px;
  padding: 1px;
}

.d-md-block.nav.nav-item.nav-link {
  padding: 0.5rem 0.7rem;
}

.dropdown-menu .dropdown-header {
  text-transform: uppercase;
  font-size: 0.73333333rem;
  font-weight: 500;
  border-block-end: 1px solid var(--border);
}

#dropdownMenuButton2 .badge-pill {
  padding: 5px 7px;
  font-size: 10.5px;
}

.dropdown-item .content {
  margin-inline-start: 15px;
  width: 200px;
  white-space: normal;
}

.main-header .nav-link .header-badge,
.main-header .nav-item .header-badge {
  position: absolute;
  inset-block-start: 0px;
  inset-inline-end: 0px;
  display: block !important;
  padding: 3px 5px !important;
  font-size: 10px;
  z-index: 9999;
  border-radius: 50px;
  line-height: 1.1;
}

.pulse {
  display: block;
  position: absolute;
  inset-block-start: 2px;
  left: 50%;
  transform: translateX(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary-bg-color);
}

.pulse:before {
  content: "";
  inset-block-start: -4px;
  inset-inline-end: -4px;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background: var(--secondary);
  cursor: pointer;
  box-shadow: 0 0 0 var(--secondary);
  animation: pulse 1s linear infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  8% {
    transform: scale(0);
    opacity: 0;
  }

  15% {
    transform: scale(0.1);
    opacity: 1;
  }

  30% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

@-moz-keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  8% {
    transform: scale(0);
    opacity: 0;
  }

  15% {
    transform: scale(0.1);
    opacity: 1;
  }

  30% {
    transform: scale(0.5);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(1.1);
  }
}

.pulse-danger {
  display: block;
  position: absolute;
  inset-block-start: 6px;
  inset-inline-end: 5px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #d6573c;
}

.pulse-danger:before {
  content: "";
  inset-block-start: -4px;
  inset-inline-end: -4px;
  width: 15px;
  height: 15px;
  position: absolute;
  border-radius: 50%;
  background: rgba(214, 87, 60, 0.8);
  cursor: pointer;
  box-shadow: 0 0 0 rgba(214, 87, 60, 0.9);
  animation: pulse 1s linear infinite;
}

.main-shortcut-list li a:hover {
  background-color: var(--gray-100);
}

.main-shortcut-list li a:hover .header-icon-svgs {
  fill: var(--primary-bg-color);
  color: var(--primary-bg-color);
  transform: scale(1.3);
}

.chat-scroll,
.Notification-scroll,
.chat-scroll1,
.Notification-scroll1,
.shopping-scroll {
  max-height: 280px;
  position: relative;
}

.cover-image {
  background-size: cover !important;
}

.notifyimg {
  border-radius: 25%;
}

.notifyimg i {
  color: var(--black);
  float: var(--float-start);
  height: 40px;
  line-height: 40px;
  text-align: center;
  vertical-align: middle;
  width: 40px;
  font-size: 21px;
}

.notification-label {
  font-size: 13.5px;
  transition: all 0.2s ease-in-out;
  border-radius: 2px;
  color: var(--color);
}

.notification-label:hover {
  color: var(--primary-bg-color);
}

.main-message-list .name:hover {
  color: var(--primary-bg-color);
}

.notification-subtext {
  font-size: 12px;
  color: var(--muted);
}

/* ###### 4.8 Box-shadows ###### */
.box-shadow-primary {
  box-shadow: 0 5px 10px var(--primary02);
}

.box-shadow-success {
  box-shadow: 0 5px 10px rgba(67, 206, 133, 0.25);
}

.box-shadow-warning {
  box-shadow: 0 5px 10px rgba(255, 207, 134, 0.25);
}

.box-shadow-danger {
  box-shadow: 0 5px 10px rgba(214, 87, 60, 0.25);
}

.box-shadow-pink {
  box-shadow: 0 5px 10px rgba(247, 84, 250, 0.25);
}

.box-shadow-info {
  box-shadow: 0 5px 10px rgba(78, 194, 240, 0.25);
}

.main-notification-list .las {
  font-size: 13px;
}

.main-notification-list a:hover {
  background: var(--bg-hover);
  color: var(--color);
}

.main-message-list li:hover {
  background: var(--bg-hover);
}

.progress-sm {
  height: 4px;
}

.card-chart {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-block-end: 10px;
  margin-block-start: 3px;
}

.browser-stats i {
  height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 50%;
  line-height: 32px;
}

.browser-stats .item {
  padding: 11px 1rem;
}

.browser-stats h6 {
  font-size: 13px;
  margin-block-end: 2px;
}

.browser-stats .sub-text {
  display: block;
  color: var(--black-7);
  font-size: 10.6px;
}

.sales-card .badge {
  border-radius: 30px;
  background-color: var(--primary06);
  padding: 4px 15px;
  color: var(--primary-bg-color);
  font-weight: 600;
}

.sales-card .sales-icon {
  color: rgb(var(--background-rgb));
  font-size: 18px;
  padding-block-start: 40px;
  padding-inline-start: 40px;
  padding-block-end: 20px;
  padding-inline-end: 20px;
  border-radius: 50%;
  position: absolute;
  inset-block-start: -30px;
  inset-inline-end: -30px;
  transition: all 0.3s ease-in-out;
}

.sales-card:hover .sales-icon {
  font-size: 25px;
}

.sales-session {
  margin-inline-start: 0;
  padding-inline-start: 0;
}

.sales-session li {
  list-style-type: none;
  margin-block-end: 1.2rem;
}

.stats .icon {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 45px;
  border-radius: 3px;
}

.stats .icon i {
  font-size: 20px;
}

.font-weight-semibold {
  font-weight: 500 !important;
}

.bounceIn {
  animation-duration: 0.75s;
  animation-name: bounceIn;
}

@keyframes bounceIn {

  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.order-list .list {
  padding-inline-start: 0;
}

.order-list .list .list-item:first-child {
  padding-block-start: 0;
}

.order-list .list.list-noborders .list-item {
  border: none;
}

.order-list .list .list-item {
  display: flex;
  align-items: center;
  padding-block-start: 0;
  padding-inline-start: 0;
  padding-block-end: 19px;
  padding-inline-end: 0;
}

.order-list .list-item {
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-block-end: 1px solid var(--border);
}

.order-list img {
  border-radius: 6px;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.row-cards>.col,
.row-cards>[class*="col-"] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}

.row-deck>.col,
.row-deck>[class*="col-"] {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.row-deck>.col .card,
.row-deck>[class*="col-"] .card {
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

/*------ Pricing Styles ---------*/
.panel-heading {
  padding: 10px 15px;
  border-block-end: 1px solid var(--white-2);
  background: rgb(var(--background-rgb));
}

.panel-body {
  padding: 15px;
  border: 1px solid var(--border);
}

.panel-body .lead {
  font-size: 20px;
  margin-block-end: 0;
  padding: 10px 0;
}

.panel-body .lead strong {
  font-size: 40px;
  margin-block-end: 0;
}

.panel-footer {
  padding: 10px 15px;
  background-color: rgb(var(--background-rgb));
  border-block-start: 1px solid var(--border);
  border-inline-start: 1px solid var(--border);
  border-inline-end: 1px solid var(--border);
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.pricing .list-unstyled li {
  display: flow-root;
  margin-block-end: 0;
  padding: 15px 0;
}

.demo-gallery>ul>li a {
  border-radius: 3px;
  display: block;
  overflow: hidden;
  position: relative;
  float: var(--float-start);
  height: 100%;
  width: 100%;
}

.demo-gallery>ul>li a>img {
  opacity: 1;
  transition: 0.3s ease-in-out;
  width: 100% !important;
}

.demo-gallery>ul>li a:hover>img,
.demo-gallery>ul>li a:hover .demo-gallery-poster>img {
  opacity: 0.5;
}

.demo-gallery>ul>li a .demo-gallery-poster {
  background-color: var(--black-1);
  inset-block-end: 0;
  inset-inline-start: 0;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery>ul>li a .demo-gallery-poster>img {
  inset-inline-start: 50%;
  margin-inline-start: -10px;
  margin-block-start: -10px;
  opacity: 0;
  position: absolute;
  inset-block-start: 50%;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery>ul>li a:hover .demo-gallery-poster {
  background-color: var(--black-5);
}

.demo-gallery .justified-gallery>a>img {
  transition: transform 0.15s ease 0s;
  transform: scale3d(1, 1, 1);
  height: 100%;
  width: 100%;
}

.demo-gallery .justified-gallery>a:hover>img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster>img {
  opacity: 1;
}

.demo-gallery .justified-gallery>a .demo-gallery-poster {
  background-color: var(--black-1);
  inset-block-end: 0;
  inset-inline-start: 0;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
  transition: background-color 0.15s ease 0s;
}

.demo-gallery .justified-gallery>a .demo-gallery-poster>img {
  inset-inline-start: 50%;
  margin-inline-start: -10px;
  margin-block-start: -10px;
  opacity: 0;
  position: absolute;
  inset-block-start: 50%;
  transition: opacity 0.3s ease 0s;
}

.demo-gallery .justified-gallery>a:hover .demo-gallery-poster {
  background-color: var(--black-5);
}

.demo-gallery .video .demo-gallery-poster img {
  height: 48px;
  margin-inline-start: -24px;
  margin-block-start: -24px;
  opacity: 0.8;
  width: 48px;
}

.demo-gallery.dark>ul>li a {
  border: 3px solid var(--border);
}

.card-body+.card-body {
  border-block-start: 1px solid var(--border);
}

.product-details .media img {
  width: 60px;
  height: 60px;
  margin-inline-end: 20px;
  border-radius: 4px;
  max-width: inherit;
  border: 1px solid var(--border);
}

.details {
  display: flex;
  flex-direction: column;
}

.colors {
  flex-grow: 1;
}

.product-title,
.price,
.sizes,
.colors {
  font-weight: 500;
}

.checked {
  color: #ffcf86;
}

.price span {
  color: var(--dark);
}

.product-title,
.rating,
.product-description,
.price,
.vote,
.sizes {
  margin-block-end: 15px;
}

.product-title {
  margin-block-start: 0;
}

.size {
  margin-inline-end: 10px;
}

.size:first-of-type {
  margin-inline-start: 40px;
}

.details .rdiobox span:after {
  inset-block-start: 5px;
}

.details .rdiobox span:before {
  inset-block-start: 0px;
}

@keyframes opacity {
  0% {
    opacity: 0;
    transform: scale(3);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.colorinput {
  margin: 0;
  position: relative;
  cursor: pointer;
}

.colorinput-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.item-card .cardtitle span {
  display: block;
  font-size: 0.75rem;
}

.item-card .cardtitle a {
  color: var(--dark);
  font-weight: 500;
  text-decoration: none;
}

.item-card .cardprice {
  position: absolute;
  inset-block-start: 17px;
  inset-inline-end: 15px;
}

.item-card .cardprice span {
  display: block;
  color: var(--dark);
}

.item-card .cardprice span.type--strikethrough {
  opacity: 0.7;
  text-decoration: line-through;
}

.item-card .relative {
  position: relative;
}

/*------icons-list---------*/
.main-icon-list {
  padding: 0;
}

.icons-list-item i {
  font-size: 1rem !important;
  line-height: 3;
}

.icons-list {
  list-style: none;
  margin-block-start: 0;
  margin-inline-end: -1px;
  margin-block-end: -1px;
  margin-inline-start: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.icons-list-item {
  text-align: center;
  height: 3rem;
  width: 3rem;
  line-height: 3.3;
  display: block;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--border);
  margin: 4px;
  border-radius: 4px;
  color: var(--color2);
  fill: var(--color2);
}

.icons-list-item .bs-tooltip-start {
  padding: 10px 0.4rem;
}

.flag {
  margin: 8px auto !important;
  width: 30px;
  height: 30px;
  margin: 0 auto;
  display: block;
  box-shadow: none;
}

.payment {
  width: 30px;
  height: 30px;
  margin: 0 auto;
  margin-block-start: 9px;
  display: block;
  box-shadow: none;
}

.icons-list .flag-icon {
  border-radius: 0;
}

/*------icons-list---------*/
.text-wrap> :last-child {
  margin-block-end: 0;
}

.highlight {
  margin: 0 0 2rem;
  border: 1px solid var(--border);
  border-radius: 0px;
  border-end-start-radius: 7px;
  border-end-end-radius: 7px;
  font-size: 0.9375rem;
  background: rgb(var(--background-rgb));
  border-start-end-radius: 8px;
  position: relative !important;
}

.highlight pre {
  margin-block-end: 0;
  background-color: var(--border);
  max-height: 20rem;
}

pre {
  color: var(--color2);
  padding: 1rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  background-color: rgb(var(--background-rgb));
  border-end-start-radius: 5px;
  border-end-end-radius: 5px;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  hyphens: none;
  border-start-end-radius: 7px;
}

/*------icons-list---------*/
*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  transition: 0.3s background;
}

::-webkit-scrollbar-thumb {
  background: var(--primary02);
}

*:hover::-webkit-scrollbar-thumb {
  background: var(--bg-hover);
}

@media (max-width: 568px) {
  .alert-dismissible .close {
    padding: 0.55rem 0.2rem;
    color: inherit;
  }

  .main-nav .nav-link+.nav-link {
    border-block-start: 0px solid var(--border);
  }

  .main-nav .nav-link+.nav-link {
    border-inline-start: 0px !important;
  }
}

@media (min-width: 992px) {
  .responsive-logo {
    display: none;
  }
}

@media (max-width: 991px) {
  .responsive-logo .header-logo {
    display: block;
    margin: 0 auto;
    inset-inline-start: 0;
    inset-inline-end: 0;
    text-align: center;
    position: absolute;
    inset-block-start: 12px;
  }
}

.angle {
  color: var(--primary05) !important;
}

.pro-img-box img {
  border: 1px solid var(--border) !important;
}

.banner {
  background: var(--bg-hover);
  display: flex;
  align-items: center;
  border-radius: 4px;
  margin: 1.5rem 0;
  padding: 14px 15px;
  border: 1px solid var(--border);
}

@media (max-width: 992px) {
  .fullscreen-button {
    display: none;
  }
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;
  border-radius: 0px;
  box-shadow: 0 0 5px var(--primary-bg-color);
  background-color: var(--primary01);
  background-position: center 64px;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
}

.prev-price {
  text-decoration: line-through;
}

.qunatity-list {
  margin-inline-start: 0;
  padding-inline-start: 6px;
}

.qunatity-list li {
  list-style-type: none;
}

.feature .project {
  display: flex;
  width: 45px;
  height: 45px;
  line-height: 2em;
  vertical-align: middle;
  padding-block-start: 0px;
  color: rgb(var(--background-rgb));
  font-size: 20px;
  border-radius: 50px;
  position: relative;
  align-items: center;
  text-align: center;
  inset-inline-start: 0;
  inset-inline-end: 0;
  justify-content: center;
  font-weight: 500;
}

.plan-icon {
  font-size: 25px;
  width: 80px;
  height: 80px;
  line-height: 80px !important;
  overflow: hidden;
  border: 1px solid var(--border);
  border-radius: 50%;
  background: var(--bg-hover);
  transition: all 0.3s;
}

/*-----Back to Top-----*/
.back-to-top {
  position: fixed;
  inset-block-end: 20px;
  inset-inline-end: 20px;
  z-index: 900;
  display: none;
  color: var(--dark);
  text-align: center;
  height: 50px;
  width: 50px;
  border: 2px solid var(--secondary);
  background: var(--secondary);
  background-repeat: no-repeat;
  background-position: center;
  transition: all ease 0.3s;
}

.back-to-top:hover {
  background: rgb(var(--background-rgb));
}

.back-to-top:hover i {
  line-height: 2;
}

.back-to-top i {
  padding-block-start: 0;
  font-size: 20px;
  line-height: 2.4;
  transition: all ease 0.3s;
}

.back-to-top.animate {
  animation: 0.4s cubic-bezier(0.65, 0.13, 0.32, 1.15) 0s 1 normal none running dropIn;
}

.back-to-top.animate-reverse {
  animation: 0.4s cubic-bezier(0.65, 0.13, 0.32, 1.15) 0s 1 normal none running dropIn-reverse;
  inset-block-end: 1050px;
}

@keyframes dropIn {
  0% {
    inset-block-end: 1050px;
  }

  100% {
    inset-block-end: 20px;
  }
}

@keyframes dropIn-reverse {
  0% {
    inset-block-end: 20px;
  }

  100% {
    inset-block-end: 1050px;
  }
}

#compositeline,
#compositeline2 {
  position: relative;
  inset-block-end: -1px;
}

@media (max-width: 578px) {
  .main-logo {
    display: none !important;
  }
}

.box-shadow {
  box-shadow: none;
}

@media (max-width: 578px) {
  .desktop-logo.logo-light .main-logo {
    display: block;
  }
}

@media (max-width: 991px) {
  .icons-list-item {
    margin: 4px 4px;
  }
}

.main-header-profile {
  border-start-end-radius: 7px;
  border-start-start-radius: 7px;
}

.list-group-item-action {
  width: 100%;
  color: var(--color);
  text-align: inherit;
}

.fullscreen-button .full-screen-link {
  line-height: 33px !important;
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    text-align: center;
  }
}

.counter-icon {
  margin-block-end: 0;
  display: inline-flex;
  padding: 1.3rem 1.4rem;
  border-radius: 50%;
  text-align: center;
  background: var(--bg-hover);
}

.counter-icon i {
  color: rgb(var(--background-rgb));
  font-size: 22px;
}

.success-widget h3 {
  text-align: center;
}

.success-widget h3:before {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #43ce85;
}

.success-widget h3:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #43ce85;
  inset-inline-start: 0.5em;
}

.success-widget h3:before {
  inset-inline-end: 0.5em;
}

.danger-widget h3 {
  text-align: center;
}

.danger-widget h3:before,
.danger-widget h3:after {
  background: #d6573c;
}

.danger-widget h3:before {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #d6573c;
}

.danger-widget h3:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #d6573c;
  inset-inline-start: 0.5em;
}

.danger-widget h3:before {
  inset-inline-end: 0.5em;
}

.warning-widget h3 {
  text-align: center;
}

.warning-widget h3:before,
.warning-widget h3:after {
  background: #d6573c;
}

.warning-widget h3:before {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #ffcf86;
}

.warning-widget h3:after {
  content: "";
  position: relative;
  display: inline-block;
  width: 26%;
  height: 1px;
  vertical-align: middle;
  background: #ffcf86;
  inset-inline-start: 0.5em;
}

.warning-widget h3:before {
  inset-inline-end: 0.5em;
}

.card .box {
  position: relative;
  inset-block-start: 50%;
  inset-inline-start: 0;
  text-align: center;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid var(--border);
  border-radius: 4px;
  box-shadow: 0 0 25px var(--white-3);
  width: 100%;
}

.card .box .img {
  width: 120px;
  height: 120px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}

.card .box h2 {
  font-size: 20px;
  margin: 20px auto;
}

.card .box ul li a {
  display: block;
  margin: 0 10px;
  font-size: 20px;
  transition: 0.5s;
  text-align: center;
}

.card .box h2 span {
  background: var(--primary-bg-color);
  font-size: 14px;
  color: rgb(var(--background-rgb));
  display: inline-block;
  padding: 4px 10px;
  border-radius: 15px;
  margin-block-start: 10px;
}

@keyframes ani {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  30% {
    transform: scale(1.4);
  }

  50% {
    transform: scale(1.2);
  }

  70% {
    transform: scale(1.4);
  }

  90% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

.main-card-signin,
.modal-content .card {
  box-shadow: none;
}

.table {
  width: 100% !important;
  margin-block-end: 1rem;
  color: var(--color2);
  border: 1px solid var(--border);
}

.product-pagination .page-link {
  background-color: rgb(var(--background-rgb)) !important;
  border: 1px solid var(--border);
}

.product-pagination .page-item.active .page-link {
  background: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color);
}

.main-logo1 {
  font-family: poppins;
  font-weight: 600;
}

@media (min-width: 992px) {
  .top-header .main-header-center {
    margin-inline-start: 0 !important;
  }
}

.btn-outline-danger:focus {
  color: #d6573c;
}

.featured_icon i {
  transform: rotate(-43deg);
  position: absolute;
  inset-block-start: 3px;
  text-align: center;
  inset-inline-end: 0;
  inset-inline-start: 0;
  color: rgb(var(--background-rgb));
  font-size: 6px;
}

.fa-circle:before {
  content: "\f111";
}

.featured_icon.danger {
  border: 3px solid #d6573c;
  background-image: linear-gradient(-38deg,
      #d6573c 0%,
      #d6573c 100%) !important;
}

.featured_icon {
  width: 17px;
  height: 17px;
  line-height: 60px;
  margin-inline-start: -35px !important;
  background-image: linear-gradient(-38deg,
      var(--primary-bg-color) 0%,
      var(--primary-bg-color) 100%);
  color: var(--black);
  font-size: 10px;
  border: 3px solid var(--primary-bg-color);
  position: relative;
  margin-block-start: -40px;
  border-radius: 18px;
  inset-block-start: 20px;
}

.featured_icon.danger {
  border: 3px solid #d6573c;
  background-image: linear-gradient(-38deg,
      #d6573c 0%,
      #d6573c 100%) !important;
}

.featured_icon.success {
  border: 3px solid #43ce85;
  background-image: linear-gradient(-38deg,
      #43ce85 0%,
      #43ce85 100%) !important;
}

.featured_icon.warning {
  border: 3px solid #ffcf86;
  background-image: linear-gradient(-38deg,
      #d6573c 0%,
      #ffcf86 100%) !important;
}

.featured_icon.teal {
  border: 3px solid #4ac9bd;
  background-image: linear-gradient(-38deg,
      var(--dark) 0%,
      #4ac9bd 100%) !important;
}

.featured_icon.yellow {
  border: 3px solid var(--yellow);
  background-image: linear-gradient(-38deg,
      var(--yellow) 0%,
      var(--yellow) 100%) !important;
}

.featured_icon.orange {
  border: 3px solid #ffb67b;
  background-image: linear-gradient(-38deg,
      #ffcf86 0%,
      #ffb67b 100%) !important;
}

.progress-style .bg-warning-gradient.progress-bar:after {
  border: 4px solid #ffcf86;
}

.img-card .img-card-circle {
  position: absolute;
  inset-block-start: 68px;
  margin-inline-end: -48px;
  text-align: end;
  inset-inline-end: 0;
  opacity: 0.6;
  z-index: 3;
  transform: rotate(25deg);
}

.fa-genderless:before {
  content: "\f22d";
}

.fs-12 {
  font-size: 12px;
}

.fs-15 {
  font-size: 15px;
}

.btn-icon {
  padding-inline-start: 0.5rem;
  padding-inline-end: 0.5rem;
  text-align: center;
}

.main-body #mixed-chart {
  height: 330px !important;
}

@media (max-width: 991px) {
  .responsive-navbar.navbar .navbar-collapse {
    padding: 0px;
    width: 100%;
    background: var(--main-gradient-bg);
    margin-block-start: 0;
    z-index: 9999;
    inset-block-start: 63px;
    border-block-end: 1px solid var(--border);
    border-block-start: 1px solid var(--border);
    position: fixed;
    inset-inline-start: 0;
    inset-inline-end: 0;
  }

  .responsive-navbar.navbar {
    position: relative !important;
    display: -ms-flexbox;
    display: flex;
    inset-block-start: -5px;
    margin: 0;
    margin-block-end: 0 !important;
    padding: 0;
  }

  .responsive-navbar.navbar .new.nav-link {
    margin: 10px 3px !important;
  }

  .mobile-main-header .navbar {
    padding: 0 !important;
  }
}

@media (min-width: 992px) {
  .mobile-header {
    display: none;
    height: 0;
  }

  .navbar-toggler.navresponsive-toggler {
    display: none;
  }
}

.feature-1 {
  position: absolute;
  inset-inline-end: 40px;
  inset-block-start: 30px;
}

.feature .text-start .text-success {
  background: #43ce85;
  padding: 10px;
  border-radius: 4px;
  margin-inline-start: -104px;
}

.feature .text-danger {
  background: #d6573c;
  padding: 10px;
  border-radius: 4px;
  margin-inline-start: -104px;
}

.sidenav-toggled .responsive-navbar.navbar .navbar-collapse {
  z-index: 999 !important;
}

.product-grid6 .icons li a {
  color: rgb(var(--background-rgb));
}

.product-grid6 .icons {
  padding: 0;
  margin: 0;
  list-style: none;
  opacity: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  text-align: center;
  position: absolute;
  inset-block-end: -3px;
  z-index: 1;
  transition: all 0.5s ease 0s;
  display: block;
  margin: 0 auto;
}

.product-grid6 .icons li .primary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to right,
      var(--primary-bg-color) 0%,
      #43ce85 100%) !important;
  color: rgb(var(--background-rgb));
}

.product-grid6 .icons li .secondary-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to right,
      var(--secondary) 0%,
      var(--secondary-05) 100%);
}

.product-grid6 .icons li .info-gradient {
  font-size: 13px;
  line-height: 35px;
  text-align: center;
  height: 35px;
  width: 35px;
  margin: 0 auto;
  border-radius: 4px;
  display: block;
  position: relative;
  transition: all 0.3s ease-in-out;
  background: linear-gradient(to right,
      #4ec2f0 0%,
      rgba(78, 194, 240, 0.5) 100%);
}

.product-grid6:hover .icons {
  opacity: 1;
  inset-block-end: 12px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  margin: 0 auto;
  text-align: center;
}

.product-grid6 .icons li a:hover:after,
.product-grid6 .icons li a:hover:before {
  opacity: 1;
}

.product-image .pic-1 {
  transition: all 0.3s ease;
}

.product-grid6 .product-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.product-image .pic-2 {
  width: 100%;
  height: 100%;
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  transition: all 0.3s ease;
  inset-block-end: 0;
  inset-inline-end: 0;
  margin: 0 auto;
}

.product-grid6 .product-link a:nth-child(2n) {
  border-inline-end: none;
}

.product-grid6 .product-image a.image {
  display: block;
}

.product-grid6:hover .product-image .pic-2 {
  opacity: 0;
}

.product-grid6:hover .icons li {
  opacity: 1;
}

.product-grid6 .icons li {
  margin: 0 auto;
  display: inline-block;
  opacity: 0;
  transition: all 0.4s ease;
}

.handle-counter {
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.counter-minus.btn {
  padding: 9px 15px !important;
}

.handle-counter .btn {
  padding: 9px 10px !important;
}

.handle-counter input {
  float: var(--float-start);
  text-align: center;
  height: 41px;
  border-radius: 0;
  width: 70px;
  border: 1px solid var(--border);
  outline: none;
  border-inline-start: 0;
  border-inline-end: 0;
}

.handle-counter .counter-minus,
.handle-counter .handle-counter .counter-plus {
  float: var(--float-start);
  text-align: center;
  border-radius: 1px;
}

.handle-counter .counter-minus {
  border-start-start-radius: 3px !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 0 !important;
  border-end-start-radius: 3px !important;
}

.handle-counter .counter-plus {
  border-start-start-radius: 0 !important;
  border-start-end-radius: 3px !important;
  border-end-end-radius: 3px !important;
  border-end-start-radius: 0 !important;
}

.counter-minus {
  border-radius: 0 !important;
}

.counter-plus {
  border-radius: 0 !important;
}

.dlist-align {
  display: flex;
}

.mobile-main-header .main-header-message .dropdown-menu {
  width: 100px;
  position: absolute;
  inset-block-start: 52px;
  inset-inline-start: auto;
  inset-inline-end: -5px;
  inset-block-end: auto;
  z-index: 9;
}

.card-options a:not(.btn) {
  margin-inline-start: 0;
  display: inline-block;
  min-width: 1rem;
  padding: 0px 15px;
}

.card-options {
  margin-inline-start: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-order: 100;
  order: 100;
  -ms-flex-item-align: center;
  align-self: center;
}

.product-carousel .carousel-inner .carousel-item {
  border: 0px solid var(--border);
  padding: 35px;
  border-radius: 4px;
  background: transparent;
}

.carousel-inner .carousel-item .thumb {
  padding: 0.55rem;
  border: 1px solid var(--border);
  border-radius: 4px;
  cursor: pointer;
}

.carousel-inner .carousel-item .thumb.active {
  border: 1px solid var(--border);
  background: var(--primary02);
}

.Accordion-Style02 {
  border-radius: 0px;
}

@media (max-width: 991px) {
  .responsive-logo img {
    height: 2.5rem;
    margin: 0 auto;
  }
}

@media (max-width: 991.98px) {

  .main-header-message .dropdown-menu,
  .main-header-notification .dropdown-menu,
  .main-header-shop .dropdown-menu,
  .main-profile-menu .dropdown-menu {
    width: 95% !important;
    inset-inline-start: 5px !important;
    inset-inline-end: 5px !important;
    inset-block-start: 60px !important;
    margin: 0 auto;
  }

  .main-header-message,
  .main-header-notification,
  .main-profile-menu,
  .nav-link.icon {
    position: inherit !important;
  }

  .main-header-profile {
    border-radius: 0px;
  }
}

.btn.Sub {
  padding: 5px 20px !important;
  transition: none;
}

.bxl-instagram.tx-prime,
.bxl-linkedin.tx-prime,
.bxl-twitter.tx-prime,
.bxl-facebook.tx-prime {
  color: var(--primary-bg-color);
  display: block;
  font-size: 22px;
  position: relative;
  line-height: 35px !important;
  outline: none;
  height: 37px;
  width: 37px;
  background: rgb(var(--background-rgb));
  margin: auto;
  text-align: center;
  border: 1px solid var(--border);
  box-shadow: none;
  border-radius: 35px;
}

@media (max-width: 568px) {
  .feature-1 {
    inset-inline-end: 4px;
  }

  .btn,
  .sp-container button {
    padding: 0.5rem 0.5rem;
    transition: none;
    margin-inline-end: 0px;
    margin-block-start: 0px;
  }

  .main-contact-action {
    inset-inline-end: 0px !important;
  }
}

.main-sidemenu {
  margin-block-start: 85px;
  height: 90%;
}

.main-sidemenu .slide.is-expanded i.angle {
  transform: rotate(90deg);
  position: absolute;
  inset-block-start: 13px;
  inset-inline-end: 20px;
  font-size: 15px;
}

.main-sidemenu .slide .side-menu__item.active i.angle {
  color: var(--primary-bg-color) !important;
}

.main-sidemenu i.angle {
  transform-origin: center;
  position: absolute;
  inset-block-start: 13px;
  inset-inline-end: 20px;
  font-size: 15px;
}

@media (max-width: 600px) {
  .main-nav-line .nav-link+.nav-link {
    margin-block-start: 13px;
    margin-inline-start: 0;
  }
}

@media (max-width: 992px) {
  .nav.main-nav-line {
    display: block;
  }
}

@media (max-width: 991px) {
  .main-error-wrapper.wrapper-1 {
    margin-block-start: 0rem !important;
  }

  .profile-cover__info .nav li {
    width: 100% !important;
  }

  .profile-cover__info .nav li:not(:first-child) {
    margin-inline-start: 0px !important;
  }
}

@media (max-width: 568px) {

  .btn.btn-rounded.plus,
  .btn.btn-rounded.comment {
    padding: 8px 12px;
    transition: none;
    margin-inline-end: -25px;
    margin-block-start: 9px;
  }

  .main-error-wrapper h1 {
    line-height: 1;
    font-size: 95px !important;
  }
}

.mail-option .chk-all,
.mail-option .btn-group a.btn {
  border: 1px solid var(--border);
  border-radius: 3px !important;
  display: inline-block;
  min-height: 0;
  padding: 5px 10px;
}

ul.inbox-pagination {
  float: var(--float-end);
  list-style-type: none;
  display: flex;
}

.mail-option .dropdown-menu>li>a {
  display: block;
  padding: 0.5rem 0.75rem;
  clear: both;
  font-weight: 400;
  line-height: 1.428571429;
  color: var(--muted);
  white-space: nowrap;
}

.inbox-pagination a.np-btn {
  border: 1px solid var(--border);
  border-radius: 3px !important;
  display: inline-block;
  padding: 5px 10px;
  margin-inline-start: 5px;
  vertical-align: middle;
  min-height: 0;
}

.inbox-pagination li span {
  display: inline-block;
  margin-inline-end: 5px;
  margin-block-start: 10px;
}

.remove-icons .remove-heart i {
  border: 1px solid var(--primary-bg-color);
  border-radius: 35px;
  padding: 6px;
  color: var(--primary-bg-color);
}

.remove-icons .remove-thumb i {
  border: 1px solid var(--secondary);
  border-radius: 35px;
  padding: 6px;
  color: var(--secondary);
}

.nav.panel-tabs .side-menu__icon {
  margin-inline-end: 5px;
  width: 23px;
  height: 23px;
  color: var(--muted);
  fill: var(--muted);
}

.nav.panel-tabs .active .side-menu__icon {
  fill: rgb(var(--background-rgb));
  color: rgb(var(--background-rgb));
}

hr:not([size]) {
  height: 0px;
}

.btn-check:focus+.btn-info,
.btn-info:focus {
  color: rgb(var(--background-rgb));
}

.light-layout {
  display: none;
}

@media (max-width: 991px) {
  .header-icons .new.nav-link {
    position: relative;
    margin: auto 6px !important;
  }

  .responsive-navbar.navbar .dropdown {
    position: initial;
  }
}

@media (max-width: 992px) {
  .responsive-navbar .collapse.show .dropdown {
    position: inherit;
  }

  .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search {
    width: 100%;
    inset-inline-start: 0;
    inset-inline-end: 0;
  }

  .responsive-navbar .collapse.show .dropdown .dropdown-menu.header-search .form-control {
    border-radius: 4px;
  }

  .responsive-navbar .collapse.show .dropdown .dropdown-menu:before,
  .responsive-navbar .collapse.show .dropdown .dropdown-menu:after {
    display: none;
  }

  .responsive-navbar .collapse.show .dropdown .show.dropdown-menu {
    inset-block-start: 57px !important;
    inset-inline-start: 5px !important;
    inset-inline-end: 5px !important;
  }
}

.responsive-logo .mobile-logo.dark-logo-1 {
  display: none;
}

hr {
  border-block-start: 1px solid var(--border);
}

.circle-icon.widget i {
  line-height: 1.9 !important;
}

.circle-icon {
  height: 40px;
  width: 40px;
  position: absolute;
  inset-block-start: 40px;
  border-radius: 4px;
  inset-inline-end: 40px;
}

.circle-icon i {
  line-height: 2.4 !important;
}

.lh-lg {
  line-height: 2.2 !important;
}

@media (min-width: 992px) {
  .main-content .main-header .container-fluid {
    padding: 0px;
  }
}

@media (max-width: 991px) {
  form[role="search"].active input {
    inset-block-start: 0.09rem !important;
  }

  .navbar-form.active .input-group-btn {
    inset-block-start: 1rem !important;
  }

  .switcher-icon .nav-link {
    padding-block-start: 12px !important;
    padding-inline-start: 4px !important;
    padding-block-end: 4px !important;
    padding-inline-end: 10px !important;
  }
}

@media (min-width: 992px) and (max-width: 992px) {
  .search-open .main-header {
    z-index: 9999 !important;
  }

  .search-open form[role="search"].active {
    position: fixed;
    inset-block-start: 0;
  }

  .search-open form[role="search"].active input {
    inset-block-start: 0;
    transition: none;
    box-shadow: none;
  }

  .search-open form[role="search"].active .input-group-btn {
    inset-block-start: 0.75rem;
  }

  .main-header-right .input-group-btn .btn {
    height: 30px !important;
    width: 30px !important;
    line-height: 18px !important;
  }
}

.spinner1 {
  width: 40px;
  height: 40px;
  position: relative;
  margin: 100px auto;
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}

.row.user-social-detail {
  position: absolute;
}

.pos-absolute.bg-black-9 {
  border-start-start-radius: 0;
  border-start-end-radius: 5px;
  border-end-end-radius: 5px;
  border-end-start-radius: 0;
}

.main-mail-star .typcn-star:before {
  content: "\e109";
  font-size: 20px;
  line-height: 0.8;
}

.main-content-label {
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1;
  margin-block-end: 10px;
  letter-spacing: 0.2px;
}

#morrisDonut1.morris-donut-wrapper-demo svg text {
  font-size: 12px !important;
  font-weight: 500 !important;
}

.form-label {
  display: block;
  margin-block-end: 0.375rem;
  font-size: 0.875rem;
  margin-block-start: 9px;
}

.form-group {
  margin-block-end: 1rem;
  display: block;
}

.ant-select-selector {
  padding: 10px !important;
}

.select_bg {
  width: 100%;
}

@media (min-width: 992px) {
  .main-footer {
    padding-block-end: 0.9rem !important;
    /* padding-inline-start: 250px !important; */
    padding-inline-end: 1rem !important;
  }
}

@media (max-width: 991px) {
  .main-footer {
    padding-block: 0.9rem;
    padding-inline-start: 10px;
    padding-inline-end: 1rem;
  }
}

@media (max-width: 991px) {
  .handle-counter input {
    height: 41px;
    width: 48px;
  }

  .handle-counter .btn {
    padding: 5px 6px !important;
  }
}

.custom-control-input:focus~.custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.typcn:before {
  height: auto !important;
  line-height: 2 !important;
}

.search-icon {
  margin: auto 6px !important;
}

.pricing-body {
  list-style-type: none;
}

.pricing-tabs ul.nav-price {
  text-align: center;
  margin: 0 auto;
  display: inline-flex;
  margin-block-start: 30px;
  margin-block-end: 40px;
  border: 1px solid var(--border);
  border-radius: 4px;
  background: rgb(var(--background-rgb));
  overflow: hidden;
}

.pricing-tabs ul.nav-price li {
  display: block;
}

.pricing-tabs ul.nav-price li a.active {
  background: var(--primary-bg-color);
}

.pricing-tabs ul.nav-price li a.active {
  color: rgb(var(--background-rgb));
  transition: 0.3s;
}

.pricing-tabs ul.nav-price li a {
  padding: 12px 22px;
  display: block;
  background: rgb(var(--background-rgb));
  font-size: 16px;
  border-radius: 0px;
  transition: 0.3s;
}

#user-datatable {
  border-inline-start: 0px !important;
}

#user-datatable thead th {
  background-image: none;
  background-color: rgb(var(--background-rgb)) !important;
  position: relative;
  border-inline-end: 0px !important;
  border-inline-start: 0px !important;
}

#user-datatable td {
  padding: 0.8rem;
  border-inline-end: 0px !important;
  border-inline-start: 0px !important;
}

#user-datatable thead .sorting::after {
  content: "\f3d0" !important;
  inset-block-start: 49%;
  display: none !important;
}

#user-datatable input.form-control {
  width: 25rem !important;
  border-radius: 4px !important;
  background-color: var(--bg-hover) !important;
}

.theme-layout {
  cursor: pointer;
}

.file-manger-icon1 img {
  width: 70px;
  height: 70px;
  border-radius: 4px;
}

.productdesc .tab-menu-heading {
  border-block-end: 0px !important;
}

.productdesc .panel-body {
  border: 0px solid var(--border);
}

.background-image-blog {
  /* background-image: url(../img/photos/29.jpg) !important; */
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  height: 320px;
  position: relative;
  border-radius: 0px;
}

.white-space-nowrap {
  white-space: nowrap;
}

.theme-container button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 0.5rem;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  color: var(--color2);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.theme-container1 button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 0.5rem;
  background-color: rgb(var(--background-rgb));
  color: var(--color2);
  border: 1px solid var(--border);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.theme-container2 button {
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 0.5rem;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  color: var(--color2);
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 4px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pickr {
  text-align: center;
  margin-block-start: 10px;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background-color: rgb(var(--background-rgb));
  opacity: 7;
  color: inherit;
  border: none;
  padding: 0px !important;
  font: inherit;
}

.owl-nav .owl-next {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-end: -25px;
  margin-block-start: -1.65em;
}

.owl-nav .owl-prev {
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: -25px;
  margin-block-start: -1.65em;
}

.owl-nav button {
  display: block;
  font-size: 1.3rem !important;
  line-height: 2em;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  text-align: center;
  background: rgb(var(--background-rgb)) !important;
  opacity: 0.5 !important;
  border: 1px solid var(--border) !important;
  z-index: 99;
  box-shadow: 0 4px 15px var(--shadow-color);
}

@media (min-width: 992px) {
  .main-settings-menu .nav-link {
    font-size: 14px;
  }

  .main-settings-menu .nav-link i {
    font-size: 18px;
  }
}

.settings-main-icon {
  display: flex;
  text-align: center;
}

.settings-main-icon i {
  width: 3rem;
  font-size: 18px;
  line-height: 3rem;
  height: 3rem;
  background-color: var(--primary02);
  color: var(--primary-bg-color);
  border-radius: 50%;
}

.leading-normal {
  line-height: 1.5 !important;
}

.lead-1 {
  font-size: 1.6rem;
  font-weight: 500;
}

.about-icons {
  height: 60px;
  width: 60px;
}

.about-team {
  width: 80px;
  height: 80px;
  inset-inline-end: 0;
  inset-inline-start: 0;
  margin: 0 auto;
}

.about-main,
.about-motto {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.tasks-all {
  padding-inline-start: 0px;
}

ul.tasks-all>li {
  list-style: none;
  margin-block-end: 25px;
}

ul.tasks-all>li:last-child {
  margin-block-end: 0px;
}

.apexcharts-yaxis-texts-g text,
.apexcharts-xaxis-texts-g text {
  fill: var(--color) !important;
}

#Viewers .apexcharts-canvas {
  margin: 0 auto;
}

.apexcharts-legend-marker {
  margin-inline-end: 10px !important;
}

.apexcharts-legend-series {
  margin: 0px 20px !important;
}

.countdown span:first-child {
  font-size: 30px;
  font-weight: 500;
}

.country-selector img {
  width: 20px;
  height: 15px;
  border-radius: 0.25rem;
}

#country-selector .modal-header {
  background-color: rgba(0, 0, 0, 0.1);
  border-block-end: 1px solid var(--border) !important;
}

#country-selector .modal-body {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}

#country-selector .modal-body:before {
  width: 100%;
  height: 100%;
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
}

.btn-country {
  border: 1px solid var(--primary02);
  box-shadow: none !important;
  text-align: justify !important;
  font-size: 13px !important;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  padding: 0.45rem 0.45rem !important;
  transition: none !important;
}

.btn-country:hover {
  border: 1px solid var(--primary-bg-color) !important;
}

.btn-country .country-selector img {
  box-shadow: 0px 1px 16px var(--shadow-color) !important;
}

.btn-check:checked+.btn-country,
.btn-country.active,
.btn-country:active {
  border: 1px solid var(--primary05) !important;
  font-weight: 600;
  color: var(--primary-bg-color);
  background-color: var(--primary03);
}

#login-otp {
  display: none;
}

.apexcharts-toolbar {
  z-index: 0 !important;
}

.app-sidebar.sidebar-scroll.open.ps--scrolling-y .main-content.app-content {
  position: fixed !important;
}

.apexcharts-legend .apexcharts-legend-series .apexcharts-legend-text {
  font-size: 12px !important;
  font-weight: 400 !important;
}

@supports (-webkit-touch-callout: none) {
  @media screen and (max-width: 991px) and (orientation: portrait) {

    .ps.ps--active-y:hover>.ps__rail-y,
    .ps.ps--active-y:focus>.ps__rail-y {
      opacity: 0;
    }
  }

  @media screen and (max-width: 991px) and (orientation: landscape) {

    .ps.ps--active-y:hover>.ps__rail-y,
    .ps.ps--active-y:focus>.ps__rail-y {
      opacity: 0;
    }
  }
}

.tree li i {
  color: var(--primary-bg-color);
}

.sweet-alert button {
  background-color: var(--primary-bg-color) !important;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow .ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  color: var(--primary-bg-color);
}

.ql-snow.ql-toolbar button:hover .ql-fill,
.ql-snow .ql-toolbar button:hover .ql-fill,
.ql-snow.ql-toolbar button:focus .ql-fill,
.ql-snow .ql-toolbar button:focus .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: var(--primary-bg-color);
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow .ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke,
.ql-snow .ql-toolbar button:focus .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button:hover .ql-stroke-miter,
.ql-snow .ql-toolbar button:hover .ql-stroke-miter,
.ql-snow.ql-toolbar button:focus .ql-stroke-miter,
.ql-snow .ql-toolbar button:focus .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
  stroke: var(--primary-bg-color);
}

.ql-snow a {
  color: var(--primary-bg-color);
}

.lg-actions .lg-next,
.lg-actions .lg-prev {
  background-color: var(--primary05);
  border: 1px solid var(--primary-bg-color);
}

.lg-toolbar {
  background-color: var(--primary05);
}

.datepicker .datepicker-switch {
  color: var(--primary-bg-color);
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  color: var(--primary-bg-color) !important;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  color: var(--primary-bg-color);
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-image: linear-gradient(to bottom,
      var(--primary-bg-color),
      var(--primary-bg-color));
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4fb7e3", endColorstr="#4fb7e3", GradientType=0);
  border-color: var(--primary-bg-color);
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: var(--primary-bg-color);
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: var(--primary-bg-color);
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-image: -o-linear-gradient(to bottom,
      var(--primary-bg-color),
      var(--primary-bg-color));
  background-image: linear-gradient(to bottom,
      var(--primary-bg-color),
      var(--primary-bg-color));
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4fb7e3", endColorstr="#4fb7e3", GradientType=0);
  border-color: var(--primary-bg-color);
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: var(--primary-bg-color);
}

.datepicker table tr td span.old:hover,
.datepicker table tr td span.new:hover {
  color: rgb(var(--background-rgb)) !important;
}

@media (max-width: 767.98px) {
  .error-page1 .switcher-icon svg {
    position: absolute;
    inset-inline-start: auto;
    inset-inline-end: 60px;
    inset-block-start: 60px;
    fill: var(--primary-bg-color);
    width: 25px;
    height: 25px;
    z-index: 99999;
  }
}

.error-page1 .switcher-icon svg {
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: 60px;
  inset-block-start: 60px;
  fill: rgb(var(--background-rgb));
  width: 25px;
  height: 25px;
}

.error-page1 .tabs-menu1 ul li a.active {
  border-block-end: 3px solid var(--primary-bg-color) !important;
}

.error-page1 .tabs-menu1 ul li a {
  border-block-end: 3px solid var(--border) !important;
}

@media (max-width: 991.98px) {
  .error-page1 .switcher-icon svg {
    position: absolute;
    inset-inline-start: auto;
    inset-inline-end: 50px;
    inset-block-start: 20px;
    width: 25px;
    z-index: 999;
    height: 25px;
  }
}

.carousel-inner.slider {
  position: relative;
  height: 400px;
}

.carousel-inner.slider .carousel-item {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 0;
}

.sidebar-mini .slide-left,
.sidebar-mini .slide-right {
  display: none !important;
}

#videomodal .modal-content,
#audiomodal .modal-content {
  box-shadow: 0 1px 15px 1px var(--shadow-color);
}

.masonry .brick {
  width: auto;
  margin-block-end: 20px;
}

.brick img {
  border-radius: 4px;
}

.smartphoto-img.active {
  border-radius: 4px;
}

.smartphoto-arrows li {
  padding: 8px;
  border-radius: 4px;
  background: var(--primary02);
}

.smartphoto-arrows a {
  width: 50% !important;
  height: 50% !important;
  margin: 7px;
}

.smartphoto-dismiss {
  width: 15px !important;
  height: 15px !important;
  inset-inline-end: 22px !important;
  inset-block-start: 18px !important;
}

.smartphoto {
  background-color: var(--white-8) !important;
}

.smartphoto-arrow-left {
  inset-inline-start: 15px !important;
}

.smartphoto-arrow-right {
  inset-inline-end: 15px !important;
}

.smartphoto-nav li {
  width: 70px !important;
  height: 70px !important;
  margin-inline-start: 5px;
}

.smartphoto-nav {
  inset-block-end: 10px !important;
}

.smartphoto-nav a {
  border-radius: 4px;
  opacity: 0.6 !important;
  border: var(--border);
}

.smartphoto-list li {
  display: table !important;
}

.ck.ck-button,
a.ck.ck-button {
  border: 1px solid var(--border);
}

@media (max-width: 576px) {
  .error-page1 .switcher-icon {
    display: none;
  }
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before,
table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
  background-color: var(--primary-bg-color) !important;
}

.twentytwenty-container {
  border-radius: 5px !important;
}

.pcr-app[data-theme="classic"] {
  border-radius: 5px !important;
}

.pcr-app[data-theme="monolith"] {
  border-radius: 5px !important;
}

.pcr-app[data-theme="nano"] {
  border-radius: 5px !important;
}

.file-detailimg img {
  width: 1000%;
  height: 100%;
}

#gallery img {
  width: 360px;
}

@media screen and (max-width: 1024px) {
  .cardbody {
    padding-inline-start: 0;
  }

  .item-card .product-grid6 .cardprice {
    inset-block-start: 20px;
    inset-inline-end: 0;
  }
}

@media (orientation: landscape) and (max-width: 991px) {
  .smartphoto-img-wrap {
    inset-inline-start: 0;
    display: block;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 33%;
    inset-inline-end: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}

@media (orientation: landscape) and (min-width: 992px) and (max-width: 991px) {
  .smartphoto-img {
    width: 350px !important;
    inset-inline-start: 75%;
    display: block;
    inset-inline-end: 0;
    margin-inline-start: auto;
    margin-inline-end: auto;
    width: 50%;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .smartphoto-img-wrap {
    inset-inline-start: 0;
    display: block;
    margin-inline-start: auto;
    margin-inline-end: auto;
    margin-block-start: 20%;
    inset-inline-end: 0;
    transform: none !important;
    justify-content: center;
    align-items: center;
  }
}

.SumoSelect.disabled>.CaptionCont {
  background-color: rgb(var(--background-rgb));
}

.SumoSelect.disabled .SlectBox,
.SumoSelect.disabled .testselect2 {
  display: none;
}

#transactionsApex .apexcharts-tooltip.light {
  color: var(--black) !important;
}

#transactionsApex .apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: var(--bg-hover) !important;
}

#mixedChart .apexcharts-tooltip.light .apexcharts-tooltip-title,
#visitors .apexcharts-tooltip.light .apexcharts-tooltip-title {
  background: var(--primary-bg-color) !important;
  color: rgb(var(--background-rgb));
}

.lh-maincard {
  line-height: 1.6 !important;
}

.border-0.form-group .select2-container--default .select2-selection--single {
  border: 0px !important;
}

.sidebar .more {
  inset-inline-end: 7%;
  inset-block-end: 16%;
}

.selectgroup-input {
  opacity: 0;
  position: absolute;
  z-index: -1;
  inset-block-start: 0;
  inset-inline-start: 0;
}

.sidebar .color-theme-settings .color-primary-light {
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid rgb(var(--background-rgb));
  box-shadow: 0px 0px 3px 5px var(--primary02);
  cursor: pointer;
}

.sidebar .color-theme-settings .color-primary-light::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.sidebar .color-theme-settings .color-primary-light::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.sidebar .color-theme-settings .clr-bg-transparent {
  padding: 0;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 4px solid rgb(var(--background-rgb));
  box-shadow: 0px 0px 3px 5px var(--shadow-color);
  cursor: pointer;
}

.sidebar .color-theme-settings .clr-bg-transparent::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.sidebar .color-theme-settings .clr-bg-transparent::-webkit-color-swatch-wrapper {
  border: none;
  border-radius: 50%;
  padding: 0;
}

.sidebar #tab_profile input::-webkit-outer-spin-button,
.sidebar #tab_profile input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.sidebar #tab_profile input[type="number"] {
  -moz-appearance: textfield;
}

.drop-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgb(var(--background-rgb));
  position: relative;
}

.todo-sublist {
  position: relative;
}

.todo-sublist::before {
  content: " ";
  height: 100%;
  width: 2px;
  background-color: var(--primary-transparentcolor);
  position: absolute;
  inset-inline-start: -24px;
  inset-block-start: -2px;
}

.todo-sublist>li .custom-control-label {
  cursor: pointer;
}

.todo-sublist>li .custom-control-label::before,
.todo-sublist>li .custom-control-label::after {
  inset-block-start: 2px;
}

.todo-sublist>li .custom-control-label.strike {
  text-decoration: line-through;
  color: var(--muted);
}

.todo-sublist>li .custom-control-input:checked~.custom-control-label::before,
.todo-sublist>li .custom-control-input:not(:disabled):active~.custom-control-label::before {
  background-color: var(--primary-transparentcolor);
  border-color: var(--form-border);
}

.todo-sublist>li .custom-control-input:focus:not(:checked)~.custom-control-label::before {
  border-color: var(--form-border);
}

.recent-activity {
  position: relative;
}

.recent-activity::before {
  content: "";
  position: absolute;
  inset-inline-start: -1px;
  width: 2px;
  height: 92%;
  background-color: var(--primary-transparentcolor);
  inset-block-start: 9px;
}

.recent-activity>li {
  position: relative;
}

.recent-activity>li .activity-item::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: var(--gray-400);
  border: 1px solid var(--primary-bg-color);
  inset-inline-start: -4.5px;
  inset-block-start: 10px;
}

.recent-activity>li .activity-item .input-group .input-group-text {
  position: absolute;
  height: 80%;
  border-radius: 50%;
  border-start-start-radius: 50% !important;
  border-end-start-radius: 50% !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  inset-block-start: 50%;
  inset-block-end: 50%;
  inset-inline-end: 5px;
  transform: translateY(-50%);
}

.recent-activity>li .activity-item .input-group.input-group-sm .input-group-text {
  width: 28px;
  inset-inline-end: 4px;
}

.recent-activity>li .activity-item .more {
  inset-inline-end: 12px;
  inset-block-end: 6px;
}

.recent-activity-1> :not(:last-child).list-item {
  position: relative;
}

.recent-activity-1> :not(:last-child).list-item::before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 24px);
  border-inline-end: 1px dashed var(--border);
  inset-block-start: 43px;
  inset-inline-start: 20px;
}

.link-overlap {
  position: absolute;
  height: 100%;
  width: 100%;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 2;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.svg-card .svg-find {
  transform: scale(1.8);
}

.svg-card .svg-upgrade {
  transform: scale(2) translateY(10px);
}

.svg-card .svg-faq {
  transform: scale(1);
}

.progress-top-bar {
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  z-index: 9999;
  height: 2px;
  background-color: var(--primary-bg-color);
  transition: all linear 0.1s;
  min-width: 0%;
}

.result-container-main {
  display: flex;
}

.result-container-main .results-all .main-result-container+.main-result-container {
  margin-block-start: 40px;
}

.result-container-main .results-all .main-result a {
  color: var(--color);
}

.result-container-main .results-all .main-result p {
  color: var(--color2);
}

.result-container-main .results-all .main-result .sub-result .table,
.result-container-main .results-all .main-result .sub-result .table td {
  border: none;
}

.result-container-main .results-all .main-result .sub-result .table {
  margin-block-end: 0px;
}

.result-container-main .similar-search a {
  color: var(--color);
}

.result-container-main .similar-search p {
  color: var(--color2);
}

.result-container-main .similar-search+.similar-search {
  margin-block-start: 15px;
}

.result-container-main .result-tools {
  position: sticky;
  inset-block-start: 88px;
}

.tag-attachment {
  padding: 0.2rem 0.65rem;
  font-size: 13px;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  border-radius: 4px;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  color: var(--gray-600);
}

.tag-attachment .tag-addon {
  margin-inline-start: 1.5rem;
  opacity: 0.5;
  color: inherit;
}

.tag-attachment .tag-addon:hover {
  opacity: 1;
}

.tag-attachment.attachment-primary {
  border-color: var(--primary-bg-color) !important;
  color: #000 !important;
  background-color: var(--primary-bg-color) !important;
}

.tag-attachment.attachment-info {
  border-color: #4ec2f0 !important;
  color: #fff !important;
  background-color: #4ec2f0 !important;
}

.tag-attachment.attachment-secondary {
  border-color: var(--secondary) !important;
  color: #000 !important;
  background-color: var(--secondary) !important;
}

.tag-attachment.attachment-warning {
  border-color: #ffcf86 !important;
  color: #000 !important;
  background-color: #ffcf86 !important;
}

.tag-attachment.attachment-success {
  border-color: #43ce85 !important;
  color: #fff !important;
  background-color: #43ce85 !important;
}

.tag-attachment.attachment-danger {
  border-color: #d6573c !important;
  color: #fff !important;
  background-color: #d6573c !important;
}

.tag-attachment.attachment-outline-primary {
  color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color) !important;
}

.tag-attachment.attachment-outline-secondary {
  color: var(--secondary) !important;
  border-color: var(--secondary) !important;
}

.tag-attachment.attachment-outline-info {
  color: #4ec2f0 !important;
  border-color: #4ec2f0 !important;
}

.tag-attachment.attachment-outline-success {
  color: #43ce85 !important;
  border-color: #43ce85 !important;
}

.tag-attachment.attachment-outline-danger {
  color: #d6573c !important;
  border-color: #d6573c !important;
}

.tag-attachment.attachment-outline-warning {
  color: #ffcf86 !important;
  border-color: #ffcf86 !important;
}

.tag-attachment.attachment-sm {
  padding: 0.15rem 0.4rem;
  font-size: 11px;
}

.tag-attachment.attachment-lg {
  padding: 0.45rem 0.95rem;
  font-size: 16px;
}

.tag-attachment.attachment-xl {
  padding: 0.65rem 1.15rem;
  font-size: 18px;
}

.tag-attachments-group .tag-attachment {
  margin-block-start: 0rem;
  margin-inline-end: 0.25rem;
  margin-block-end: 0.35rem;
  margin-inline-start: 0rem;
}

.tag-attachment a {
  display: flex;
  align-items: center;
}

.image-attach {
  position: relative;
}

.image-attach .attach-options {
  width: 100%;
  text-align: center;
  position: absolute;
  inset-block-end: 0px;
  inset-inline-start: 50%;
  transform: translateX(-50%);
  opacity: 0;
  border-radius: 4px;
  transition: all ease 0.2s;
  background-image: linear-gradient(to top, var(--black-5), transparent 100%);
}

.image-attach:hover .attach-options {
  opacity: 1;
  padding-block-end: 0.75rem;
}

.file-attach {
  width: 100px;
  height: 100px;
  display: inline-table;
  border-radius: 4px;
  text-align: center;
}

.file-attach.file-attach-sm {
  width: 50px;
  height: 50px;
}

.file-attach.file-attach-lg {
  width: 150px;
  height: 150px;
}

.file-attach.file-attach-xl {
  width: 200px;
  height: 200px;
}

.file-attach+.file-attach {
  margin-inline-start: 5px;
}

.file-attach .attach-options {
  position: absolute;
  inset-inline-end: -15px;
  opacity: 0;
  transition: all ease 0.2s;
}

.file-attach:hover .attach-options {
  inset-inline-end: 8%;
  opacity: 1;
}

.file-manager-icon {
  position: relative;
  height: 35px;
}

.fm-sidebar-open .fm-sidebar {
  transform: translate(0, 0) !important;
}

.fm-sidebar {
  position: fixed;
  width: 320px;
  inset-inline-end: 0px;
  inset-block-end: 0px;
  z-index: 9999;
  height: calc(100% - 63px);
  background: rgb(var(--background-rgb));
  border-inline-start: 1px solid var(--border);
  border-block-start: 1px solid var(--border);
  transform: translate(100%, 0);
  transition: transform 300ms ease;
}

.fm-sidebar .fm-timeline {
  position: relative;
}

.fm-sidebar .fm-timeline::before {
  content: "";
  position: absolute;
  height: calc(100% - 19px);
  width: 2px;
  background-color: var(--border);
  inset-block-start: 13px;
  inset-inline-start: 26px;
}

.fm-sidebar .fm-timeline>li {
  padding-inline-start: 40px;
  position: relative;
  margin-block-end: 30px;
}

.fm-sidebar .fm-timeline>li .fm-timeline-icn {
  position: absolute;
  border-radius: 50%;
  background-color: var(--bg-hover);
  inset-block-start: -3px;
  inset-inline-start: -5px;
}

.fm-sidebar .fm-timeline>li .files-container {
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  width: 21%;
}

.fm-sidebar .fm-timeline>li .files-container+.files-container {
  margin-inline-start: 5px;
}

.absolute-center {
  inset-block-start: 50%;
  inset-inline-start: 50%;
  inset-block-end: 50%;
  transform: translate(-50%, 50%);
}

.owl-carousel-icons2 img {
  border-end-start-radius: 0px;
  border-end-end-radius: 0px;
}

.todoChart {
  height: 320px;
}

.datepicker table tr td.day {
  border-radius: 4px;
}

.datepicker table tr td.day.today,
.datepicker table tr td.day.today.disabled,
.datepicker table tr td.day.today.disabled:hover {
  background: none;
  color: var(--primary-bg-color);
}

.datepicker table tr td.day.today.active {
  background: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.datepicker table tr td.day.active,
.datepicker table tr td.day.active:hover,
.datepicker table tr td.day.active.disabled,
.datepicker table tr td.day.active.disabled:hover {
  text-shadow: none;
}

.datepicker table tr td.day:not(.active):hover,
.datepicker table tr td.day:not(.active).focused,
.datepicker table tr td.day:not(.active).today:hover,
.datepicker table tr td.day:not(.active).today:focus {
  background: var(--gray-100);
  color: var(--primary-bg-color);
}

.datepicker table tr td span:not(.active):hover,
.datepicker table tr td span:not(.active).focused {
  background: var(--gray-100);
  color: var(--primary-bg-color);
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: var(--color2);
  opacity: 0.5;
}

.datepicker table tr td span.old:hover,
.datepicker table tr td span.new:hover {
  color: var(--primary-bg-color) !important;
}

.datepicker .datepicker-switch {
  font-weight: 500;
}

.datepicker th {
  color: var(--muted);
  font-weight: 400;
}

.datepicker th.clear {
  text-align: center;
}

.contact-item {
  position: relative;
}

.contact-item .contact_links {
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 0;
}

.contact-btn-list {
  position: relative;
}

.contact-btn-list::after {
  content: "";
  position: absolute;
  width: calc(100% - 225px);
  height: 4px;
  border-block-start: 1px solid var(--border);
  inset-inline-end: 0px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.motto:hover .motto-icon::before {
  transform: rotate(45deg);
}

.motto-icon {
  position: relative;
  border-radius: 4px;
}

.motto-icon:hover::before {
  transform: rotate(8deg);
}

.motto-icon::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  inset-block-start: 0;
  inset-inline-start: 0;
  transform: rotate(0deg);
  transition: transform ease 400ms;
  border-radius: 4px;
}

.motto-icon.mi_1 {
  background-color: #7891ef !important;
}

.motto-icon.mi_1::before {
  background-color: rgba(120, 145, 239, 0.31);
}

.motto-icon.mi_2 {
  background-color: #d6573c !important;
}

.motto-icon.mi_2::before {
  background-color: rgba(214, 87, 60, 0.31);
}

.motto-icon.mi_3 {
  background-color: #f754fa !important;
}

.motto-icon.mi_3::before {
  background-color: rgba(247, 84, 250, 0.31);
}

.motto-icon.mi_4 {
  background-color: #4ec2f0 !important;
}

.motto-icon.mi_4::before {
  background-color: rgba(78, 194, 240, 0.31);
}

::selection {
  background: var(--primary05);
  color: var(--black);
}

.notification {
  list-style-type: none;
  padding: 0;
  position: relative;
}

.notification::before {
  content: "";
  position: absolute;
  inset-block-start: 0px;
  inset-block-end: 5px;
  width: 4px;
  background-color: var(--primary02);
  inset-inline-start: 20%;
  margin-inline-start: -2.5px;
}

.notification>li {
  position: relative;
  min-height: 50px;
  padding: 15px 0px;
}

.notification .notification-time {
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 30px;
  width: 18%;
  text-align: end;
}

.notification .notification-time .date,
.notification .notification-time .time {
  display: block;
  font-weight: 500;
  color: var(--muted);
}

.notification .notification-time .date {
  line-height: 16px;
  font-size: 12px;
  margin-block-end: 4px;
}

.notification .notification-time .time {
  line-height: 24px;
  font-size: 18px;
}

.notification .notification-icon {
  position: absolute;
  inset-inline-start: 15%;
  inset-block-start: 41px;
  width: 10%;
  text-align: center;
}

.notification .notification-icon a {
  text-decoration: none;
  width: 20px;
  height: 20px;
  display: inline-block;
  border-radius: 50%;
  background: rgb(var(--background-rgb));
  line-height: 10px;
  color: rgb(var(--background-rgb));
  font-size: 14px;
  border: 3px solid var(--primary-bg-color);
  transition: border-color 0.2s linear;
}

.notification .notification-body {
  box-shadow: 0px 1px 16px var(--shadow-color);
  margin-inline-start: 24%;
  margin-inline-end: 18%;
  background: rgb(var(--background-rgb));
  position: relative;
  padding: 14px 20px;
  border-radius: 6px;
}

.notification .notification-body::before {
  content: "";
  display: block;
  position: absolute;
  border: 10px solid transparent;
  border-inline-end-color: rgb(var(--background-rgb));
  inset-inline-start: -20px;
  inset-block-start: 32px;
  transform: translateY(-50%);
}

.notification .notification-body .notification-badge {
  background-color: var(--gray-100);
  color: var(--muted);
}

.btn-loadmore {
  display: flex;
}

@media (max-width: 576px) {
  .notification::before {
    display: none;
  }

  .notification .notification-body {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    position: relative;
  }

  .notification .notification-body::before {
    display: none;
  }

  .notification .notification-body .notification-badge {
    position: absolute;
    inset-inline-start: 10px;
    inset-block-start: 8px;
  }

  .notification .notification-icon a {
    display: none;
  }

  .notification .notification-time {
    z-index: 99;
    width: 100%;
    inset-inline-end: 5px !important;
    position: absolute;
    inset-block-start: 20px !important;
  }

  .notification .notification-time .date,
  .notification .notification-time .time {
    display: inline;
  }

  .notification .notification-time .time {
    line-height: 16px;
    font-size: 11px;
    margin-inline-start: 5px;
    margin-inline-end: 10px;
    color: var(--color2);
    display: inline-block;
  }
}

.pricing-card {
  transition: all 300ms ease;
}

.pricing-card:hover {
  box-shadow: 0px 16px 32px var(--shadow-color);
}

.pricing-card.premium-card:hover {
  box-shadow: 0px 16px 32px var(--primary02);
}

.flex-1 {
  flex: 1;
}

.flex-grow-1 {
  flex-grow: 1;
}

@media (min-width: 992px) {
  .main-settings-row {
    height: calc(100vh - 212px);
  }

  .main-settings-app {
    height: calc(100vh - 353px);
  }
}

.main-settings-app {
  position: relative;
}

.main-settings-row {
  user-select: none;
}

.product-card .mask-container {
  position: relative;
  overflow: hidden;
}

.product-card .mask-container::before {
  content: "";
  position: absolute;
  height: 50px;
  width: 50px;
  border-end-end-radius: 250px;
  background-color: rgba(0, 0, 0, 0.5);
  inset-block-start: -45px;
  inset-inline-start: -45px;
  transform: scale(1);
  transition: all ease-in-out 250ms;
}

.product-card .product-options {
  opacity: 0;
  transform: translate(-50%, 30%);
  transition: all ease 150ms;
  transition-delay: 80ms;
}

.product-card .product-badge {
  opacity: 1;
  transition: all ease-in-out 100ms;
}

.product-card:hover .mask-container::before {
  transform: scale(40);
}

.product-card:hover .product-options {
  opacity: 1;
  transform: translate(-50%, -50%);
}

.product-card:hover .product-badge {
  opacity: 0;
}

.masked-link {
  position: absolute;
  height: 100%;
  width: 100%;
  inset-block-start: 0px;
  inset-inline-start: 0px;
}

@media (min-width: 1201px) {
  .stickyBar {
    position: sticky !important;
    inset-block-start: 85px;
  }
}

.item-group>.item-box .selectgroup-input+.item {
  color: var(--muted);
  cursor: pointer;
  transition: all ease 200ms;
}

.item-group>.item-box .selectgroup-input:not(:checked)+.item:hover {
  color: var(--background-rgb);
}

.item-group>.item-box .selectgroup-input:checked+.item {
  color: var(--primary-bg-color);
}

.order-track>.accordion>.accordion-item {
  position: relative;
}

.order-track>.accordion>.accordion-item::before {
  content: "";
  position: absolute;
  inset-inline-start: 15px;
  inset-block-start: 32px;
  height: 100%;
  border-inline-start: 1px dashed var(--primary03);
}

.order-track>.accordion>.accordion-item.next-step::before {
  border-inline-start-color: var(--border);
}

.order-track>.accordion>.accordion-item.next-step .sub-track::before {
  border-color: var(--border);
}

.order-track>.accordion>.accordion-item .sub-track {
  position: relative;
}

.order-track>.accordion>.accordion-item .sub-track::before {
  content: "";
  position: absolute;
  height: 10px;
  width: 10px;
  background-color: rgb(var(--background-rgb));
  border: 3px solid var(--primary02);
  border-radius: 50%;
  inset-inline-start: -30px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.order-track>.accordion>.accordion-item .sub-track:last-child {
  margin-block-end: 20px;
}

.order-track>.accordion>.accordion-item .sub-track+.sub-track {
  margin-block-start: 15px;
}

.order-track :last-child>.accordion-item::before {
  content: unset;
}

.apexcharts-datalabels text[font-family] {
  font-family: "Poppins", sans-serif !important;
}

.apexcharts-datalabels text[fill] {
  fill: var(--color);
}

.apexcharts-datalabels text[filter] {
  filter: none;
}

.btc-svg {
  fill: var(--primary-bg-color);
}

.eth-svg {
  fill: var(--secondary);
}

.dash-svg {
  fill: #ffcf86;
}

.glm-svg {
  fill: #43ce85;
}

.apexcharts-tooltip-box span.value {
  font-weight: normal !important;
}

.jvectormap-zoomin,
.jvectormap-zoomout,
.jvectormap-goback {
  background: var(--primary05) !important;
  color: var(--primary-bg-color) !important;
  font-size: 20px !important;
}

.jvectormap-tip {
  border-color: var(--primary-bg-color) !important;
  background: var(--primary-bg-color) !important;
}

.world-map circle:nth-child(1) {
  fill: var(--primary-bg-color);
}

.world-map circle:nth-child(2) {
  fill: var(--secondary);
}

.world-map circle:nth-child(3) {
  fill: #43ce85;
}

.world-map circle:nth-child(4) {
  fill: #d6573c;
}

.landing-sidemenu-open .landing-layout .nav-header-main .landing-open-btn {
  display: none !important;
}

.landing-sidemenu-open .landing-layout .nav-header-main .landing-close-btn {
  display: block !important;
}

.landing-sidemenu-open .landing-layout .sidemenu-landing {
  transform: translate(0, 0) !important;
}

.landing-layout {
  position: relative;
}

.landing-layout .section-title {
  font-size: 16px;
}

.landing-layout .v-line {
  height: 100%;
  width: 1px;
  border-inline-start: 1px solid var(--border);
}

.landing-layout .section-title-1 {
  font-size: 22px;
}

.landing-layout .section-title-2 {
  font-size: 30px;
}

.landing-layout .invert {
  color: #fff !important;
}

.landing-layout .shadow-none {
  box-shadow: none !important;
}

.landing-layout .scale-1-5 {
  transform: scale(1.5);
}

.landing-layout .rounded-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.landing-layout .rounded-icon.sm {
  height: 30px;
  width: 30px;
}

.landing-layout .title-desc {
  color: var(--muted);
}

.landing-layout .media-links .media-btn {
  height: 35px;
  width: 35px;
  line-height: 2.87;
  border-radius: 50%;
  color: var(--muted);
  background-color: var(--bg-hover);
  display: inline-block;
  transition: all ease 0.3s;
}

.landing-layout .media-links .media-btn:hover {
  background-color: var(--primary-bg-color);
  color: #fff;
}

.landing-layout section {
  position: relative;
}

.landing-layout .bg-gray {
  background-color: var(--background) !important;
}

.landing-layout .flex-nowrap {
  flex-wrap: nowrap !important;
}

.landing-layout .feature-card {
  position: relative;
  background-color: rgb(var(--background-rgb));
  border-radius: 4px;
  margin-block-end: 1.3rem;
  box-shadow: 2px 1px 20px 0px var(--shadow-color);
}

.landing-layout .feature-card.bg-hover-shape {
  z-index: 1;
}

.landing-layout .feature-card.bg-hover-shape:hover::before {
  transform: scale(-1) rotate(40deg);
  visibility: visible;
}

.landing-layout .feature-card.bg-hover-shape::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/blob-bg.svg);
  filter: invert(0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  visibility: hidden;
  transform: scale(-1);
  transition: transform ease 0.4s;
}

.landing-layout .reveal {
  transform: translateY(100px);
  opacity: 0;
  transition: all 0.3s ease;
}

.landing-layout .reveal.active {
  opacity: 1;
  transform: translateY(0px);
}

.landing-layout .min-btn-list-sm .btn {
  width: 4rem;
}

.landing-layout .min-btn-list .btn {
  width: 6rem;
}

.landing-layout .min-btn-list-lg .btn {
  width: 8rem;
}

.landing-layout .min-btn-list-xl .btn {
  width: 10rem;
}

.landing-layout .op--1 {
  opacity: 1 !important;
}

.landing-layout .sidemenu-landing {
  display: none;
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  width: 240px;
  height: 100%;
  background: rgb(var(--background-rgb));
  border-inline-end: 1px solid var(--border);
  z-index: 99;
  transform: translate(-100%, 0);
  transition: transform 300ms ease;
}

.landing-layout .sidemenu-landing .nav-link {
  font-size: 15px;
  color: var(--color2);
  font-weight: 500;
  opacity: 0.8;
  padding: 0.8rem 1.2rem;
}

.landing-layout .sidemenu-landing .nav-link:focus,
.landing-layout .sidemenu-landing .nav-link.active {
  opacity: 1;
  color: var(--primary-bg-color);
}

.landing-layout .banner-section {
  background-image: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/wave-bg.svg);
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

.landing-layout .banner-section .nav-header-main {
  padding: 10px 0;
  border-block-end: 1px solid transparent;
}

.landing-layout .banner-section .nav-header-main .mobile-logo {
  display: none;
  margin-inline-start: -30px;
}

.landing-layout .banner-section .nav-header-main .mobile-logo.dark-logo-1 {
  display: block;
  margin-inline-start: -30px;
}

.landing-layout .banner-section .nav-header-main .nav-header-icon {
  height: 37px;
  width: 37px;
  border-radius: 4px;
  color: var(--color2);
}

.landing-layout .banner-section .nav-header-main .nav-header-icon:hover,
.landing-layout .banner-section .nav-header-main .nav-header-icon:focus {
  background-color: var(--background);
  color: var(--primary-bg-color);
}

.landing-layout .banner-section .nav-header-main .landing-open-btn {
  display: block;
  font-size: 1.3rem;
}

.landing-layout .banner-section .nav-header-main .landing-close-btn {
  display: none;
  font-size: 1.3rem;
}

.landing-layout .banner-section .nav-header-main .nav .nav-item .nav-link {
  color: rgb(var(--background-rgb));
  opacity: 0.65;
  font-weight: 500;
  font-size: 14px;
}

.landing-layout .banner-section .nav-header-main .nav .nav-item .nav-link:hover,
.landing-layout .banner-section .nav-header-main .nav .nav-item .nav-link.active {
  opacity: 1;
}

.landing-layout .banner-section .nav-header-main .dropdown-link {
  color: rgb(var(--background-rgb));
  font-weight: 500;
  font-size: 14px;
}

.landing-layout .banner-section .nav-header-main .dropdown-link .dropdown-menu {
  transition: none;
}

.landing-layout .banner-section .title-content {
  font-size: 32px;
  color: rgb(var(--background-rgb));
}

.landing-layout .banner-section .title-content .title {
  font-weight: 600;
  text-transform: uppercase;
}

.landing-layout .banner-section .title-desc {
  font-size: 15px;
  color: rgb(var(--background-rgb));
  opacity: 0.8;
}

.landing-layout .banner-section .image-container {
  background-color: rgb(var(--background-rgb));
  padding: 5px;
  border-radius: 4px;
  box-shadow: 1px 3px 11px 0px rgba(0, 0, 0, 0.13);
}

.landing-layout .choose-section {
  margin-block-start: 1.5rem;
}

.landing-layout .choose-section .feature-card.bg-hover-shape:hover::before {
  transform: translateX(-55px) scale(-1.3) rotate(40deg);
}

.landing-layout .choose-section .feature-card.bg-hover-shape::before {
  transform: translateX(-55px) scale(-1.3);
}

.landing-layout .counters-section {
  background-color: var(--primary-bg-color);
  color: #fff;
  z-index: 1;
}

.landing-layout .counters-section::before {
  content: "";
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/counters-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.15;
}

.landing-layout .counters-section .counter-container {
  position: relative;
  z-index: 1;
}

.landing-layout .counters-section .counter-container::before {
  font-family: "feather" !important;
  speak-as: normal;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1.1;
  font-size: initial;
  position: absolute;
  height: 100%;
  width: 100%;
  inset-block-start: 0;
  inset-inline-start: -37%;
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.1);
  z-index: -1;
}

.landing-layout .counters-section .counter-container.container-1::before {
  content: "\e971";
}

.landing-layout .counters-section .counter-container.container-2::before {
  content: "\e960";
  transform: scaleX(-1);
}

.landing-layout .counters-section .counter-container.container-3::before {
  content: "\ea03";
}

.landing-layout .counters-section .counter-container.container-4::before {
  content: "\e97d";
  inset-inline-start: -20%;
}

.landing-layout .happy-clients-section {
  background-color: var(--primary-bg-color);
  z-index: 1;
}

.landing-layout .happy-clients-section::before {
  content: "";
  inset-inline-start: 0;
  inset-block-start: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/clients-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  z-index: -1;
  opacity: 0.15;
}

.landing-layout .happy-clients-section .carousel-indicators {
  margin: 0 auto !important;
  inset-block-end: -25% !important;
}

.landing-layout .happy-clients-section .review {
  max-width: 70%;
  width: fit-content;
  margin: 0 auto;
}

.landing-layout .more-faq-section {
  z-index: 1;
}

.landing-layout .footer-section {
  background-color: var(--primary02);
}

.landing-layout .footer-section .mobile-logo.dark-logo-1 {
  margin-inline-start: -30px;
}

.landing-layout .footer-section .footer-container-1 .mobile-logo.dark-logo-1 {
  margin-block-end: 1rem;
}

.landing-layout .footer-section .copyright-content {
  margin-block-start: 3rem;
}

.landing-layout .footer-section .footer-page-link {
  opacity: 0.7;
  transition: all ease 0.2s;
}

.landing-layout .footer-section .footer-page-link:hover {
  opacity: 1;
}

.landing-layout .footer-section .footer-icons .footer-link {
  color: rgb(var(--background-rgb));
  opacity: 0.7;
  border-radius: 50%;
  transition: all ease 0.3s;
}

.landing-layout .footer-section .footer-icons .footer-link:hover {
  background-color: rgb(var(--background-rgb));
  color: var(--primary-bg-color);
  opacity: 1;
}

@media (max-width: 377px) {
  .landing-layout .working-section .working-svg {
    transform: scale(0.85) translateX(-35px);
  }
}

@media (max-width: 574px) {
  .landing-layout .contact-us-section :not(:last-child) .branch-address {
    margin-block-end: 25px;
  }

  .landing-layout .footer-section .footer-container-2,
  .landing-layout .footer-section .footer-container-3 {
    margin-block-end: 25px;
  }

  .landing-layout .counters-section .counter-container:not(:last-child) {
    margin-block-end: 50px;
  }
}

@media (max-width: 991px) {
  .landing-layout .my-col-v-line {
    display: none;
  }

  .landing-layout .myorder-1 {
    order: 1;
  }

  .landing-layout .myorder-2 {
    order: 2;
  }

  .landing-layout .nav-header-main.sticky {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 99;
    padding: 0;
    border-block-end: 1px solid var(--border);
    background: rgb(var(--background-rgb));
    padding: 10px 0;
    box-shadow: 5px 7px 26px -5px var(--shadow-color);
  }

  .landing-layout .nav-header-main.sticky .mobile-logo {
    display: block;
  }

  .landing-layout .nav-header-main.sticky .mobile-logo.dark-logo-1 {
    display: none;
  }

  .landing-layout .nav-header-main.sticky .nav .nav-item .nav-link {
    color: var(--color2);
    opacity: 0.65;
    font-weight: 500;
    font-size: 14px;
  }

  .landing-layout .nav-header-main.sticky .nav .nav-item .nav-link:hover,
  .landing-layout .nav-header-main.sticky .nav .nav-item .nav-link.active {
    color: var(--primary-bg-color);
    opacity: 1;
  }

  .landing-layout .sidemenu-landing {
    display: block;
  }

  .landing-layout .companies-section .brand-img {
    display: inline-block;
    margin-inline-end: 35px;
    margin-block-end: 5px;
  }

  .landing-layout .counters-section .counter-container.container-1::before,
  .landing-layout .counters-section .counter-container.container-2::before,
  .landing-layout .counters-section .counter-container.container-3::before,
  .landing-layout .counters-section .counter-container.container-4::before {
    content: none;
  }

  .landing-layout .feature-card.bg-hover-shape {
    z-index: 1;
  }

  .landing-layout .feature-card.bg-hover-shape::before {
    content: none;
  }

  .landing-layout section:not(.banner-section) {
    padding-block-start: 3rem;
    padding-block-end: 3rem;
  }

  .landing-layout .faq-section .general-faq-container {
    margin-block-end: 50px;
  }

  .landing-layout .footer-section .footer-container-1 {
    margin-block-end: 40px;
  }

  .landing-layout .footer-section .footer-container-1 .mobile-logo.dark-logo-1 {
    margin-block-end: 0.75rem;
  }

  .landing-layout .footer-section .copyright-content {
    margin-block-start: 3rem;
  }
}

@media (max-width: 991px) {
  .product-section svg {
    display: none;
  }

  .choose-section {
    margin-block-start: 0rem;
  }
}

@media (min-width: 992px) {
  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    width: 100%;
    z-index: 99;
    border-block-end: 1px solid var(--border);
    background: rgb(var(--background-rgb));
    box-shadow: 5px 7px 26px -5px var(--shadow-color);
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .mobile-logo {
    display: block;
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .mobile-logo.dark-logo-1 {
    display: none;
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .nav .nav-item .nav-link {
    color: var(--color2);
    opacity: 0.65;
    font-weight: 500;
    font-size: 14px;
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .nav .nav-item .nav-link:hover,
  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .nav .nav-item .nav-link.active {
    color: var(--primary-bg-color);
    opacity: 1;
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .dropdown-link {
    color: var(--color2);
    font-weight: 500;
    font-size: 14px;
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .dropdown-link.show {
    color: var(--primary-bg-color);
  }

  .landing-layout .banner-section .nav-header-main.sticky.sticky-pin .dropdown-link .dropdown-menu {
    transition: none;
  }

  .landing-layout section:not(.banner-section) {
    padding: 3rem;
  }
}

@media (min-width: 1200px) {
  .landing-layout .working-section .working-container {
    position: relative;
    padding: 0px 100px;
  }

  .landing-layout .working-section .working-container:hover .working-content::before {
    visibility: visible;
  }

  .landing-layout .working-section .working-container:hover .working-content.content-1::before {
    transform: translateY(-50%) rotate(45deg);
  }

  .landing-layout .working-section .working-container:hover .working-content.content-2::before,
  .landing-layout .working-section .working-container:hover .working-content.content-4::before {
    transform: translateY(-50%);
    inset-inline-start: -23%;
  }

  .landing-layout .working-section .working-container:hover .working-content.content-3::before {
    transform: translateY(-50%);
    inset-block-start: 50%;
  }

  .landing-layout .working-section .working-container .working-content {
    position: relative;
    z-index: 1;
  }

  .landing-layout .working-section .working-container .working-content::before {
    font-family: "feather" !important;
    speak-as: normal;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1.1;
    font-size: initial;
    position: absolute;
    height: fit-content;
    width: fit-content;
    color: var(--background);
    font-size: 175px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    inset-block-start: 50%;
    transform: translateY(-50%);
    transition: all ease 0.3s;
  }

  .landing-layout .working-section .working-container .working-content.content-1::before {
    content: "\e9c2";
    inset-inline-start: -23%;
  }

  .landing-layout .working-section .working-container .working-content.content-2::before {
    content: "\e90a";
    inset-inline-start: -18%;
  }

  .landing-layout .working-section .working-container .working-content.content-3::before {
    content: "\e93f";
    inset-inline-start: -23%;
    inset-block-start: 80%;
  }

  .landing-layout .working-section .working-container .working-content.content-4::before {
    content: "\e901";
    inset-inline-start: -27%;
  }
}

.side-menu .slide {
  position: relative;
}

.page {
  overflow: clip;
}

.side-menu {
  overflow: visible !important;
}

.slide-right,
.slide-left {
  inset-block-start: 0;
  inset-block-end: 0;
  background: transparent;
}

.slide-right svg,
.slide-left svg {
  fill: var(--color2);
}

@media screen and (max-width: 991px) {
  .switcher-icon {
    margin: 0 !important;
  }
}

@media screen and (min-width: 1200px) and (max-width: 1379px) {

  #contact-row .btn-sm,
  #contact-row .btn-group-sm>.btn,
  #contact-row .sp-container .btn-group-sm>button {
    padding: 0.25rem 0.2rem !important;
  }
}

@media screen and (max-width: 338px) {
  .notifit_confirm_accept {
    margin-block-end: 5px;
  }
}

@media screen and (max-width: 400px) {
  #liveToast {
    width: 100%;
  }

  .product-btn .btn {
    width: 100%;
  }

  .intl-tel-input input {
    width: 170px;
  }

  .wizard>.steps {
    padding: 20px 5px !important;
  }

  .wizard>.steps>ul li .title {
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .svg-card .svg-faq {
    transform: scale(0.9);
  }

  .fc-col-header thead th {
    padding: 15px 0;
  }

  .about-svg {
    display: none;
  }

  .error-page1 .square-box {
    display: none;
  }
}

#owl-demo2.owl-carousel .owl-item img {
  width: 100%;
}

hr {
  background-color: var(--border);
  opacity: 1;
}

#scroll-stickybar {
  width: 100% !important;
}

.datepicker {
  z-index: 9;
}

@media screen and (max-width: 537px) {
  .card-pay .tabs-menu.nav li {
    border-inline-end: 0;
    border-block-end: 1px solid var(--border);
    width: 100%;
  }
}

.form-size .custom-control-label::before {
  inset-block-start: 5px;
}

.form-size .custom-control-label::after {
  inset-block-start: 5px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
  border-radius: 4px !important;
  height: 34px !important;
}

.dataTables_wrapper .dataTables_length .select2-container .select2-selection--single .select2-selection__rendered {
  padding-inline-start: 15px;
}

@media screen and (max-width: 575px) {
  div.table-responsive>div.dataTables_wrapper>div.row>div[class^="col-"]:last-child {
    padding-inline-start: 0;
  }
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
  height: calc(1.5em + 0.5rem + 2px) !important;
  padding: 0.25rem 0.5rem !important;
  font-size: 0.785rem !important;
  line-height: 1.5 !important;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
  inset-block-start: 0 !important;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 16px !important;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
  inset-block-start: 0px !important;
}

@media screen and (max-width: 575px) {
  div.dataTables_wrapper div.dataTables_filter input {
    margin-inline-start: 0 !important;
  }

  #projectReport .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    inset-block-start: 23px !important;
  }

  .file-attach {
    margin-block-end: 10px;
  }
}

@media screen and (max-width: 700px) {
  #chartA .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    inset-inline-start: 0 !important;
    inset-block-start: 23px !important;
  }

  .fc-timeGridWeek-view .fc-daygrid-event {
    position: absolute;
  }
}

@media screen and (max-width: 600px) {
  #totalRevenue .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
    inset-block-start: 23px !important;
  }

  #totalRevenue .apexcharts-legend-series {
    margin: 0px 10px !important;
  }
}

#chartA .apexcharts-legend.apx-legend-position-top.apexcharts-align-center {
  inset-inline-start: -34px !important;
}

@media screen and (max-width: 340px) {

  label[for="btncheck1"],
  label[for="btncheck2"],
  label[for="btncheck3"] {
    font-size: 11px;
  }

  .main-toggle.on span {
    inset-inline-start: 30px;
  }
}

@media screen and (max-width: 1199px) {
  .form-size .row {
    margin-inline-end: 0;
    margin-inline-start: 0;
  }

  .form-size .row .col-xl-12,
  .form-size .row .col-xl-2 {
    padding: 0 0.75rem !important;
  }

  .form-size .select2-container {
    width: 100% !important;
  }
}

@media screen and (max-width: 599px) {
  #owl-demo2 .item .card {
    box-shadow: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-inline-end: 0;
  margin-block-end: 0;
  list-style: none;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-inline-start: 0.75rem;
  padding-inline-end: 0.75rem;
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.form-group .select2-container {
  width: 100% !important;
}

#mixedChart {
  width: 100% !important;
  height: 100% !important;
}

.modal {
  position: fixed;
  inset-block-start: 0;
  inset-inline-end: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.fade {
  transition: opacity 0.15s linear;
}

#cryptoReport .apexcharts-canvas,
#mixedChart .apexcharts-canvas,
#chartA .apexcharts-canvas,
#projectReport .apexcharts-canvas,
#revenueReport .apexcharts-canvas,
#totalRevenue .apexcharts-canvas,
#audienceReport .apexcharts-canvas,
#salesReport .apexcharts-canvas,
#sessionsCountry .apexcharts-canvas,
#visitorsGrowth .apexcharts-canvas {
  width: 100% !important;
}

#cryptoReport svg,
#mixedChart svg,
#chartA svg,
#projectReport svg,
#revenueReport svg,
#totalRevenue svg,
#audienceReport svg,
#salesReport svg,
#sessionsCountry svg,
#visitorsGrowth svg {
  width: 100% !important;
}

.fc .fc-scrollgrid table {
  width: 100% !important;
}

.fc .fc-daygrid-body {
  width: 100% !important;
}

.breadcrumb-item+.breadcrumb-item {
  padding-inline-end: 0;
  padding-inline-start: 0.5rem;
}

.apexcharts-legend-text {
  color: var(--primary05) !important;
}

.apexcharts-gridlines-horizontal line {
  stroke: var(--primary01) !important;
}

.form-control::placeholder {
  opacity: 0.6;
}

.apexcharts-tooltip.apexcharts-theme-light {
  border: 1px solid var(--border) !important;
  background: var(--main-gradient-bg) !important;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--main-gradient-bg) !important;
  border-bottom: 1px solid var(--border) !important;
}

.demo_changer .form_holder {
  background: var(--main-gradient-bg) !important;
  border-inline-start: 1px solid var(--primary03);
}

.demo_changer h4 {
  border-block-end: 1px solid var(--border);
  border-block-start: 1px solid var(--border);
}

.onoffswitch2-label {
  border: 1px solid var(--border);
}

.onoffswitch2-label:before {
  background: var(--background);
  border: 1px solid var(--border);
}

.demo_changer {
  color: var(--color);
}

.demo_changer img {
  border: 2px solid var(--border);
  background: rgb(var(--background-rgb));
}

.demo_changer.active .form_holder {
  box-shadow: -4px 2px 14.72px 1.28px var(--shadow-color);
}

.input-dark-color-picker,
.input-color-picker,
.input-transparent-color-picker {
  background-color: var(--black-5);
  border: 1px solid var(--border);
}

.demo_changer h4 {
  border-block-end: 1px solid var(--border);
  background: var(--black-03);
  border-block-start: 1px solid var(--border);
}

.apexcharts-series.apexcharts-pie-series path {
  stroke: rgb(var(--background-rgb)) !important;
}

.btn-close {
  color: var(--black);
}

.modal-footer {
  border-block-start: 1px solid var(--border);
}

.handle-counter .btn.btn-white {
  color: var(--color2);
  background-color: var(--bg-hover);
  border-color: var(--border);
}

.handle-counter .btn.btn-white:focus,
.handle-counter .btn.btn-white.focus {
  box-shadow: 0 0 0 0.2rem var(--shadow-color) !important;
  background-color: var(--bg-hover) !important;
  border-color: var(--border) !important;
}

.handle-counter input {
  background-color: var(--bg-hover);
  color: var(--black);
}

.bootstrap-tagsinput input {
  color: var(--color);
}

.morris-donut-wrapper-demo svg path {
  stroke: var(--border);
}

.morris-donut-wrapper-demo svg text {
  fill: var(--black);
}

.table> :not(caption)>*>* {
  box-shadow: inset 0 0 0 9999px transparent;
}

.table-striped>tbody>tr:nth-of-type(odd)>* {
  color: var(--color);
}

.dropdown-menu {
  display: none;
}

@media (min-width: 992px) {
  .main-header-left .btn {
    background: transparent;
    border: 1px solid transparent;
  }
}

@media (max-width: 991px) {
  .header-icon-svgs {
    color: var(--primary-bg-color) !important;
    fill: var(--primary-bg-color) !important;
  }

  .switcher-icon .header-icon-svgs {
    color: var(--primary-bg-color) !important;
  }
}

.table-responsive .table td a.tx-inverse i.fe {
  display: inline-block;
}

.list-unstyled .list-item p i.fe {
  display: inline-block;
}

@media (max-width: 400px) {
  .flatpickr-calendar {
    width: 228.875px !important;
  }

  .intl-tel-input input {
    width: 169px !important;
  }
}

@keyframes activityaminationprimary {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px var(--primary03);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationsecondary {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px var(--secondary-03);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationwarning {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px rgba(255, 207, 134, 0.3);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationdanger {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px rgba(214, 87, 60, 0.3);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationsuccess {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px rgba(67, 206, 133, 0.3);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationinfo {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px rgba(78, 194, 240, 0.3);
  }

  100% {
    box-shadow: none;
  }
}

@keyframes activityaminationorange {
  0% {
    box-shadow: none;
  }

  50% {
    box-shadow: 0px 0px 0px 5px rgba(255, 182, 123, 0.3);
  }

  100% {
    box-shadow: none;
  }
}

.activity-content1 {
  position: relative;
}

.activity-content1:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--primary-bg-color);
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationprimary 2s linear infinite;
}

.activity-content1:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: var(--primary-bg-color);
  opacity: 0.3;
}

.activity-content2 {
  position: relative;
}

.activity-content2:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: var(--secondary);
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationsecondary 2s linear infinite;
}

.activity-content2:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: var(--secondary);
  opacity: 0.3;
}

.activity-content3 {
  position: relative;
}

.activity-content3:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffcf86;
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationwarning 2s linear infinite;
}

.activity-content3:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: #ffcf86;
  opacity: 0.3;
}

.activity-content4 {
  position: relative;
}

.activity-content4:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #d6573c;
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationdanger 2s linear infinite;
}

.activity-content4:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: #d6573c;
  opacity: 0.3;
}

.activity-content5 {
  position: relative;
}

.activity-content5:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #43ce85;
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationsuccess 2s linear infinite;
}

.activity-content5:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: #43ce85;
  opacity: 0.3;
}

.activity-content6 {
  position: relative;
}

.activity-content6:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #4ec2f0;
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationinfo 2s linear infinite;
}

.activity-content6:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 114%;
  top: 20px;
  inset-inline-start: -24px;
  bottom: 10px;
  background-color: #4ec2f0;
  opacity: 0.3;
}

.activity-content7 {
  position: relative;
}

.activity-content7:before {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #ffb67b;
  top: 8px;
  inset-inline-start: -25px;
  opacity: 1;
  animation: activityaminationorange 2s linear infinite;
}

.pending-products .nav-link.active {
  border-bottom: 2px solid var(--primary01);
}

#total-investment {
  min-height: 50px !important;
}

.main-dashboard-cards-svg {
  padding: 8px;
  width: 40px;
  height: 40px;
  border-radius: 7px;
}

.sales-stats {
  padding-inline-start: 50px;
}

.project-stats {
  flex: 1;
}

#chart-container {
  position: relative;
  height: 180px;
  overflow: hidden;
}

.line {
  stroke: #e8e8e8;
  stroke-dasharray: 0;
  stroke-linecap: butt;
}

.apexcharts-yaxis text {
  fill: var(--primary02);
}

.apexcharts-xaxis text {
  fill: var(--secondary);
}

.apexcharts-radar-series line {
  stroke: var(--primary01);
}

.side-menu__icon,
.header-icon,
.header-icon-svgs {
  -webkit-filter: drop-shadow(0px 2px 5px var(--primary-bg-color));
  filter: drop-shadow(0 2px 5px var(--primary-bg-color));
}

.total-profit-svg {
  -webkit-filter: drop-shadow(0px 2px 5px var(--primary-bg-color));
  filter: drop-shadow(0 2px 5px var(--primary-bg-color));
}

.total-order-svg {
  -webkit-filter: drop-shadow(0px 2px 5px var(--secondary));
  filter: drop-shadow(0 2px 5px var(--secondary));
}

.total-sales-svg {
  -webkit-filter: drop-shadow(0px 2px 5px #ffcf86);
  filter: drop-shadow(0 2px 5px #ffcf86);
}

.numberfont {
  font-family: "Rajdhani", sans-serif;
}

#chartdiv {
  max-width: 100%;
  height: 30vh;
}

.avatar.bg-primary,
.avatar.bg-secondary {
  color: #000;
}

.apexcharts-theme-light .apexcharts-selection-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoom-icon:not(.apexcharts-selected):hover svg,
.apexcharts-theme-light .apexcharts-zoomin-icon:hover svg,
.apexcharts-theme-light .apexcharts-zoomout-icon:hover svg,
.apexcharts-theme-light .apexcharts-reset-icon:hover svg,
.apexcharts-theme-light .apexcharts-menu-icon:hover svg {
  fill: var(--black) !important;
}

.apexcharts-menu {
  background: var(--main-gradient-bg) !important;
  border: 1px solid var(--border) !important;
}

.apexcharts-theme-light .apexcharts-menu-item:hover {
  background: transparent !important;
}

.main-card-signin .logo-dark {
  display: block;
}

.main-card-signin .logo-light-theme {
  display: none;
}

[data-theme-color="default"] .layout-setting {
  display: none !important;
}

.onoffswitch2-checkbox:checked+.onoffswitch2-label {
  background-color: var(--primary-bg-color) !important;
}

.onoffswitch2-checkbox:checked+.onoffswitch-label2,
.onoffswitch2-checkbox:checked+.onoffswitch2-label:before {
  border-color: var(--primary-bg-color) !important;
}

.pro-img-box {
  position: relative;
}

.product-pagination .page-link {
  background-color: var(--gray-100);
  border: 1px solid var(--border);
}

.product-pagination .page-item.disabled .page-link {
  border-color: var(--border);
}

.order-table .product-item .product-thumb,
.order-table .product-item .product-info {
  display: table-cell;
  vertical-align: top;
}

.shopping-cart-footer {
  display: table;
  width: 100%;
  border-block-start: 1px solid var(--border);
}

.shopping-cart-footer>.column {
  display: table-cell;
  padding: 0;
  vertical-align: middle;
}

.shopping-cart-footer>.column:last-child {
  text-align: end;
}

.shopping-cart-footer>.column:last-child .btn {
  margin-inline-end: 0;
  margin-inline-start: 5px;
}

@media (max-width: 768px) {
  .shopping-cart-footer>.column {
    display: block;
    width: 100%;
  }

  .shopping-cart-footer>.column:last-child {
    text-align: center;
  }

  .shopping-cart-footer>.column .btn {
    width: 100%;
    margin: 12px 0 !important;
  }
}

.product-details tr>td {
  vertical-align: middle !important;
}

.product-details>table thead th {
  padding-block-start: 17px;
  padding-block-end: 17px;
  border-width: 1px;
  text-align: center;
}

.product-details .media {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
}

@media screen and (max-width: 850px) {
  .shopping-cart-footer>.column {
    display: flow-root;
  }
}

@media (min-width: 768px) {
  #checkoutsteps .checkoutline {
    height: 3px;
    background-color: var(--bg-hover);
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 11%;
    position: relative;
    z-index: 0;
    inset-block-start: 28px;
  }
}

#checkoutsteps ul {
  list-style: none !important;
  padding: 0;
  margin: 0;
}

#checkoutsteps ul[role="tablist"] li {
  display: flex;
  z-index: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  flex-basis: auto;
  font-weight: 500;
  font-size: 1.1rem;
}

#checkoutsteps>.steps a {
  display: block;
  width: auto;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  padding: 14px 0;
  text-decoration: none;
  border-radius: 30px;
  cursor: default;
  text-align: center;
  outline: none;
}

.wizard>.steps>ul li .current-info {
  display: none;
}

#checkoutsteps>.steps .current a .number {
  background: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

#checkoutsteps .item .thumb {
  display: inline-flex;
  width: 100px;
  height: 90px;
  justify-content: center;
  align-items: center;
}

#checkoutsteps .item .left {
  display: flex;
  align-items: center;
}

#checkoutsteps .item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block-end: 30px;
  border-block-end: 1px solid var(--border);
  margin-block-end: 30px;
}

#checkoutsteps .item .purchase {
  text-decoration: none;
}

#checkoutsteps .item .purchase {
  display: inline-block;
  margin-inline-start: 21px;
}

#checkoutsteps .total {
  float: var(--float-end);
  color: var(--primary-bg-color);
}

#checkoutsteps>.steps {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 3px;
  padding: 0;
}

#checkoutsteps.wizard {
  border-radius: 4px;
}

.wizard>.content>.title {
  font-size: 18px;
  color: var(--color2);
  font-weight: 700;
  margin-block-end: 5px;
  display: none;
}

.wizard>.content>.body {
  float: none;
  position: static;
  width: auto;
  height: auto;
}

.wizard>.content>.title.current {
  display: none !important;
}

.card-pay .tabs-menu.nav li {
  border-inline-end: 1px solid var(--border);
}

.card-pay .tabs-menu li {
  width: 33.3%;
  display: block;
}

.card-pay .tabs-menu li a.active {
  background: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.card-pay .tabs-menu li a {
  padding: 0.7rem 1rem;
  display: block;
  text-align: center;
}

.card-pay .tabs-menu.nav {
  background: var(--bg-hover);
}

.card-pay .tabs-menu {
  margin-block-end: 25px;
  border-radius: 4px;
  overflow: hidden;
}

@media (min-width: 768px) {
  #checkoutsteps>.steps .number {
    background-color: var(--bg-hover);
    color: var(--color2);
    font-size: 17px;
    font-weight: 400;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px auto;
  }
}

@media (max-width: 767px) {
  #checkoutsteps>.steps .number {
    background-color: var(--bg-hover);
    color: var(--color2);
    font-size: 15px;
    font-weight: 600;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin: 15px auto;
  }
}

@media (max-width: 767px) {
  #checkoutsteps>.steps a {
    font-size: 10px !important;
  }
}

@media (max-width: 767px) {
  #checkoutsteps .checkoutline {
    height: 3px;
    background-color: var(--bg-hover);
    -webkit-transform: translateY(1.7rem);
    transform: translateY(1.7rem);
    margin: 0 11%;
    position: relative;
    z-index: 0;
    inset-block-start: 20px;
  }
}

.cart-img {
  height: 55px !important;
  width: 55px;
  border-radius: 4px !important;
}

@media (max-width: 340px) {
  #checkoutsteps .item {
    padding-block-end: 0;
  }

  #checkoutsteps .item .purchase {
    margin-inline-start: 0;
    margin-block-end: 10px;
    margin-block-start: 10px;
  }

  #checkoutsteps .item .d-flex {
    display: block !important;
  }

  #checkoutsteps .item .d-flex .thumb {
    border: 0;
  }

  #checkoutsteps .item .d-flex .handle-counter {
    margin-inline-start: 0 !important;
  }
}

.widget-user .widget-user-header {
  padding: 20px;
  height: 120px;
}

.widget-user .widget-user-username {
  margin-block-start: 0;
  margin-block-end: 5px;
  font-size: 25px;
  font-weight: 300;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  color: rgb(var(--background-rgb));
}

.widget-user .widget-user-desc {
  margin-block-start: 0;
  color: rgb(var(--background-rgb));
}

.widget-user .widget-user-image {
  position: absolute;
  inset-block-start: 65px;
  inset-inline-start: 50%;
  margin-inline-start: -45px;
}

.widget-user .widget-user-image>img {
  width: 90px;
  height: auto;
  border: 3px solid rgb(var(--background-rgb));
}

.widget-user .user-wideget-footer {
  padding-block-start: 30px;
}

.user-wideget-footer {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-block-start: 1px solid #f4f4f4;
  padding: 10px;
  background-color: rgb(var(--background-rgb));
}

.user-wideget .border-right {
  border-inline-end: 1px solid #f4f4f4;
}

.description-block {
  display: block;
  margin: 10px 0;
  text-align: center;
}

.description-block>.description-header {
  margin: 0;
  padding: 0;
  font-weight: 600;
  font-size: 16px;
}

.description-block>.description-text {
  text-transform: uppercase;
  color: #a3b1c9;
}

/* LAYOUT-STYLES */
/* ###### 6.2 Footer  ###### */
.main-footer {
  margin-block-start: auto;
}

@media (min-width: 992px) {
  .main-footer {
    display: block;
  }

  .sticky {
    width: 100%;
  }
}

.main-footer .container {
  font-size: 14px;
  padding: 38px 20px 0;
  align-items: center;
  justify-content: space-between;
  color: var(--gray-600);
  margin: 0 auto;
  text-align: center;
  justify-content: center;
}

.main-footer .container-fluid {
  font-size: 14px;
  padding: 38px 20px 0;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  padding-inline-start: 24px;
  padding-inline-end: 24px;
}

@media (min-width: 992px) {
  .main-footer .container {
    padding: 10px 0 0;
  }
}

@media (max-width: 480px) {
  .main-footer .container-fluid {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

@media (max-width: 380px) {
  .main-footer .container-fluid {
    font-size: 12px;
  }
}

/* ###### 6.4 Header  ###### */
.main-header {
  z-index: 100;
  height: 64px;
  position: fixed;
  margin-block-end: 20px;
  box-shadow: none;
  background: transparent;
  backdrop-filter: blur(5px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
}

.main-header.sticky.sticky-pin {
  position: fixed;
  top: 0;
  background-color: rgb(var(--background-rgb));
  transition: top ease 0.3s;
}

.main-header>.container,
.main-header>.container-fluid {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-header .dropdown-menu {
  border: 1px solid var(--primary03) !important;
}

.main-header .dropdown-menu .main-message-list .dropdown-item {
  padding: 0.75rem;
}

.main-header .dropdown-menu .main-message-list .dropdown-item:last-of-type {
  border-block-end: 0 !important;
}

.main-header .dropdown-menu .main-notification-list a:last-of-type {
  border-block-end: 0 !important;
}

.main-header>.main-container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-position: center top;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
}

.main-header>.main-container:before {
  position: absolute;
  content: "";
  width: 50%;
  height: 1px;
  bottom: 0;
  inset-inline-start: 30%;
  background: linear-gradient(45deg,
      transparent,
      var(--primary03),
      var(--primary06),
      var(--primary06),
      var(--primary03),
      transparent);
  box-shadow: 0 8px 17px 0.0125px var(--primary-bg-color);
}

.side-header {
  position: fixed;
  width: 100%;
  z-index: 10 !important;
  border-block-end: 1px solid var(--border);
}

@media (min-width: 992px) {
  .main-header>.container {
    padding: 0;
  }

  .main-header.hor-header.sticky.sticky-pin {
    position: relative;
  }

  .hor-header>.main-container::before {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .main-header>.container {
    max-width: none;
  }

  .main-header {
    z-index: 1030 !important;
  }
}

@media (max-width: 1158px) {
  .main-header-left #dropdownMenuButton {
    display: none;
  }
}

@media (max-width: 1052px) {
  .main-header-left #dropdownMenuButton2 {
    display: none;
  }
}

.main-profile-menu.nav-link {
  padding: 0rem !important;
  margin: auto 6px;
}

.main-header .main-header-left .main-logo {
  display: none;
}

@media (min-width: 992px) {
  .main-header .main-header-left .main-logo {
    display: flex;
  }
}

.main-header-left {
  display: flex;
  align-items: center;
}

.main-header-left .list-items {
  margin-block-end: 0;
}

.main-header-left .list-items li {
  display: inline-block;
}

.main-header-left .list-items li i {
  display: block;
  font-size: 24px;
  color: var(--gray-900);
  position: relative;
  line-height: 0.9;
  outline: none;
  margin-inline-end: 15px;
}

.main-header-left .main-header-arrow {
  display: none;
  position: relative;
  inset-block-start: -2px;
}

.main-logo {
  display: flex;
  align-items: center;
  position: relative;
  height: 2.5rem;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
}

.main-logo:hover,
.main-logo:focus {
  color: var(--primary-bg-color);
}

.main-logo i {
  display: block;
  line-height: 0.9;
  margin-inline-end: 8px;
  font-size: 40px;
}

.main-logo i::before {
  width: auto;
  height: auto;
}

.main-header-center {
  flex: 1;
  margin: 0 50px;
  position: relative;
  display: none;
}

@media (min-width: 992px) {
  .main-header-center {
    display: block;
  }
}

.main-header-right .main-profile-menu .dropdown-menu::before {
  background: var(--primary-bg-color);
  border-width: 0px;
}

.main-profile-menu .dropdown-menu:before {
  inset-inline-end: 15px;
}

.main-header-right {
  display: flex;
  align-items: center;
}

.main-header-arrow {
  font-size: 27px;
  color: var(--gray-900);
  display: inline-block;
  line-height: 0;
}

.main-header-arrow i {
  line-height: 0;
}

.main-header-arrow:hover,
.main-header-arrow:focus {
  color: var(--gray-700);
}

.header-icons .new.nav-link {
  position: relative;
  margin: auto 6px !important;
}

.header-icons .new.nav-link {
  display: block;
  font-size: 22px;
  color: var(--primary02);
  position: relative;
  line-height: 33px;
  outline: none;
  height: 37px;
  width: 37px;
  margin: auto;
  text-align: center;
}

@media (min-width: 992px) {

  .main-header-notification>a::after,
  .main-header-message>a::after {
    inset-block-end: -36px;
  }
}

.main-header-notification .dropdown-menu {
  width: 100%;
  position: fixed;
  border-radius: 0;
  width: 300px;
  position: absolute;
  inset-block-start: 44px;
  inset-inline-start: auto;
  inset-inline-end: -10px;
  inset-block-end: auto;
  padding: 0;
  z-index: 9;
}

.main-header-message .dropdown-menu {
  width: 300px;
  position: absolute;
  inset-block-start: 44px;
  inset-inline-start: auto;
  inset-inline-end: -10px;
  inset-block-end: auto;
  z-index: 9;
  padding: 0;
}

.main-header-shop .dropdown-menu {
  width: 410px;
  position: absolute;
  inset-block-start: 44px;
  inset-inline-start: auto;
  inset-inline-end: -10px;
  inset-block-end: auto;
  z-index: 9;
  padding: 0px;
}

.main-header-notification .dropdown-footer {
  text-align: center;
  padding-block-start: 10px;
  font-size: 13px;
  border-block-start: 1px dotted var(--gray-400);
}

.main-header-notification.show .dropdown-menu {
  display: block;
}

@media (min-width: 576px) {

  .main-header-notification.show>a::after,
  .main-header-message.show>a::after {
    display: block;
  }
}

.main-header-message.show .dropdown-menu {
  display: block;
}

.main-notification-list .media {
  padding: 10px 0;
  position: relative;
}

.main-notification-list .media+.media {
  border-block-start: 1px dotted var(--gray-400);
}

.main-notification-list .media.new {
  color: var(--gray-900);
}

.main-notification-list .media:hover,
.main-notification-list .media:focus {
  z-index: 1;
}

.main-notification-list .media:hover::before,
.main-notification-list .media:focus::before {
  content: "";
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: -20px;
  inset-block-end: -1px;
  inset-inline-end: -20px;
  background-color: var(--gray-100);
  border-block-start: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
  z-index: -1;
}

.main-notification-list .media-body {
  margin-inline-start: 15px;
  font-size: 13px;
}

.main-notification-list .media-body p {
  margin-block-end: 5px;
  line-height: 1.3;
}

.main-notification-list .media-body strong {
  font-weight: 500;
}

.main-notification-list .media-body span {
  display: block;
  font-size: 11px;
  color: var(--gray-500);
}

.main-profile-menu>.main-img-user {
  outline: none;
  width: 32px;
  height: 32px;
}

.main-profile-menu>.main-img-user::before {
  content: "";
  position: absolute;
  inset-block-end: -20px;
  inset-inline-end: -90px;
  margin-inline-start: -7px;
  width: 14px;
  height: 14px;
  border: 2px solid transparent;
  border-block-start-color: var(--gray-300);
  border-inline-start-color: var(--gray-300);
  transform: rotate(45deg);
  background-color: rgb(var(--background-rgb));
  z-index: 901;
  display: none;
}

.main-profile-menu .dropdown-menu {
  padding: 0;
  z-index: 900;
  position: absolute;
  inset-inline-start: auto;
  inset-inline-end: 0px;
  inset-block-end: auto;
  width: 230px;
}

.main-profile-menu .dropdown-item {
  position: relative;
  padding: 20px 16px;
  height: 38px;
  display: flex;
  align-items: center;
  font-size: 13px;
  color: var(--color);
}

.main-profile-menu .dropdown-item i {
  font-size: 17px;
  margin-inline-end: 10px;
  width: 24px;
  text-align: center;
}

.main-profile-menu .dropdown-item i.typcn {
  line-height: 0.9;
}

.main-profile-menu.show .dropdown-menu {
  display: block;
  inset-block-start: 50px;
}

@media (min-width: 576px) {
  .main-profile-menu.show .main-img-user::before {
    display: block;
  }
}

.main-header-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-header-profile .main-img {
  margin-block-end: 10px;
  margin-block-start: 10px;
}

.main-header-profile .main-img img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.main-header-profile h6 {
  font-weight: 700;
  color: var(--gray-900);
  margin-block-end: 3px;
  font-size: 16px;
  color: rgb(var(--background-rgb));
}

.main-header-profile span {
  display: block;
  font-size: 12px;
  margin-block-end: 0;
  color: var(--white-6);
}

@media (min-width: 576px) {
  .main-header-profile .main-img-user {
    width: 80px;
    height: 80px;
  }
}

/*--Search--*/
.main-header-right .input-group-btn .btn {
  border: 0 !important;
  background: transparent;
  border-radius: 3px;
  color: var(--color);
  height: 38px;
  width: 38px;
  line-height: 24px;
  border-radius: 50px;
  font-size: 22px;
}

.main-header-right .input-group-btn .btn:hover {
  border-radius: 50%;
  color: var(--primary-bg-color);
}

.main-header-right .input-group-btn .btn:hover i {
  color: var(--primary-bg-color);
}

.main-header-right .input-group-btn .btn i {
  font-size: 16px;
  color: var(--color);
}

.main-header-right .navbar-form.active .input-group-btn .btn i {
  font-size: 14px;
  line-height: 27px;
}

.main-header-right form[role="search"] {
  inset-block-start: 0px;
  inset-inline-end: 0;
  width: 100%;
  padding: 0px;
  margin: 0px;
  z-index: 0;
}

.main-header-right form[role="search"] button {
  padding: 5px 12px;
  border-radius: 0px;
  border-width: 0px;
  color: var(--color);
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  box-shadow: none;
  outline: none;
}

.main-header-right form[role="search"] input {
  padding: 5px 12px;
  border-radius: 0px;
  border-width: 0px;
  color: var(--color);
  background-color: #f8f8f8;
  border-color: #e7e7e7;
  box-shadow: none;
  outline: none;
  padding: 16px 12px;
  font-size: 14px;
  color: var(--color);
  box-shadow: none;
}

.main-header-right form[role="search"] button[type="reset"] {
  display: none;
  background: rgb(var(--background-rgb));
  border: 0 !important;
}

.main-header-right .input-group-btn .btn i {
  font-size: 18px;
  color: #7d7d86;
}

.main-header-message.right-toggle .nav-link {
  padding: 0;
}

form[role="search"] input {
  font-size: 16px;
  opacity: 0;
  display: none;
  height: 66px;
  transition: all 5s;
}

form[role="search"].active {
  width: 100%;
  z-index: 9999;
  inset-inline-end: 0;
  border-inline-start: 0px solid #e3e3f7;
  position: absolute;
  inset-inline-start: -1px;
  display: block;
}

form[role="search"].active button {
  display: table-cell !important;
  opacity: 1;
}

form[role="search"].active input {
  display: table-cell;
  opacity: 1;
  width: 100%;
  height: 64px;
  background: rgb(var(--background-rgb));
  padding-inline-start: 20px;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
  transition: all 5s;
  box-shadow: 5px 6px 6px -3px var(--shadow-color);
  inset-block-start: 3.6rem;
}

.main-header-message i,
.main-header-notification i,
.nav-item.full-screen i {
  height: 37px;
  width: 37px;
  text-align: center;
  line-height: 37px;
  font-size: 17px;
}

.main-header-message .dropdown-footer,
.main-header-notification .dropdown-footer,
.main-header-shop .dropdown-footer {
  border-block-start: 1px solid var(--border);
  padding: 10px;
  box-shadow: -8px 12px 18px 0 rgba(25, 42, 70, 0.1);
  border-start-start-radius: 0;
  border-start-end-radius: 0;
  border-end-end-radius: 4px;
  border-end-start-radius: 4px;
}

.main-header-message .dropdown-footer a {
  font-size: 13px;
}

.main-header-center {
  display: flex;
}

.header .form-control {
  border: 2px solid var(--gray-300);
}

@media (min-width: 992px) {
  .main-header.fixed-header {
    position: fixed;
    z-index: 999;
    margin-block-start: 0;
  }

  .main-header-left .form-control {
    height: 35px;
    border-radius: 0px;
    background: transparent;
    background: transparent;
    border: 1px solid var(--primary01);
  }

  .main-header-left .form-control:focus {
    background: transparent;
    box-shadow: 0px 0px 20px -5px var(--primary-bg-color);
    border: 1px solid var(--primary05) !important;
  }

  .main-header-left .btn {
    position: absolute;
    inset-block-start: 0;
    inset-inline-end: 0px;
    height: 35px;
    color: var(--black-5);
    transition: none;
    font-size: 14px;
    padding: 8px 15px;
    border-inline-start: 0px !important;
    border-start-start-radius: 0;
    border-start-end-radius: 5px;
    border-end-end-radius: 5px;
    border-end-start-radius: 0;
  }
}

.main-header-message .nav-link i,
.main-header-notification .nav-link i,
.nav-item.full-screen .nav-link i {
  text-align: center;
  line-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
  font-size: 1.2rem;
  color: #39455d;
}

.header-icons .new.nav-link {
  padding: 0;
  border-radius: 4px;
  transition: all ease 0.3s;
}

.header-icons .new.nav-link:hover,
.header-icons .new.nav-link.show {
  background: var(--bg-hover);
}

.header-icons .new.nav-link:hover .header-icon-svgs,
.header-icons .new.nav-link.show .header-icon-svgs {
  fill: var(--primary-bg-color);
  color: var(--primary-bg-color);
}

.main-header .main-header-left .main-logo {
  display: flex;
  margin: 0;
}

@media (max-width: 1134px) {
  .main-header-left .form-control {
    width: 150px;
  }
}

@media (min-width: 1400px) {
  .main-header-left .form-control {
    width: 500px !important;
  }
}

.main-header-arrow {
  display: none;
  position: relative;
  inset-inline-end: auto;
}

@media (max-width: 480px) {
  .main-header-right .nav .nav-item .dropdown-menu {
    inset-inline-start: auto;
    inset-inline-end: auto;
    justify-content: center;
    margin: 0 auto;
    width: 93%;
    position: fixed;
    inset-inline-start: 5px;
    inset-inline-end: 5px;
    margin-block-start: 61px;
  }
}


.right-toggle {
  cursor: pointer;
}

@media (max-width: 633px) and (min-width: 321px) {
  .main-profile-menu {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    padding-inline-end: 7px !important;
    padding-inline-start: 7px !important;
  }
}

@media (max-width: 320px) {
  .main-profile-menu {
    margin-inline-start: 0 !important;
    margin-inline-end: 0 !important;
    padding-inline-end: 2px !important;
    padding-inline-start: 2px !important;
  }
}

@media (max-width: 991px) {
  .btn.btn-default.nav-link.resp-btn {
    display: block;
    font-size: 22px;
    color: #272746;
    position: relative;
    line-height: 18px;
    outline: none;
    height: 34px;
    width: 34px;
    margin: auto;
    text-align: center;
    border: 0px solid #dfd7e9;
    padding: 2px;
  }
}

.main-header .main-header-right .nav-link.icon {
  margin: 0px;
  text-align: center;
  font-size: 1.2rem;
  position: relative;
}

.nav-link.icon i {
  color: var(--color) !important;
  font-size: 20px;
  position: relative;
}

@media (max-width: 992px) {
  .responsive-navbar .collapsing .dropdown-menu {
    display: none !important;
  }

  .responsive-navbar .collapse.show .dropdown-menu.show {
    display: block !important;
  }
}

@media (max-width: 360px) {
  .responsive-navbar.navbar .new.nav-link {
    margin: 10px 0 !important;
  }
}

.header-icon-svgs {
  width: 20px;
  height: 20px;
  color: var(--primary-bg-color);
  fill: var(--header-color);
  transition: all ease 0.3s;
}

@media (min-width: 992px) {
  [data-layout="horizontal"] .main-header {
    box-shadow: none;
  }

  [data-layout="horizontal"] .app-sidebar:before {
    background: none;
    box-shadow: none;
  }

  [data-layout="horizontal"] .main-header {
    height: 64px;
  }

  [data-layout="horizontal"] .ps>.ps__rail-y>.ps__thumb-y {
    display: none;
  }

  [data-layout="horizontal"] .horizontal-main .sub-slide2 .sub-slide-menu1 {
    inset-inline-start: 180px;
    inset-block-start: 8px;
    width: 190px;
    position: absolute;
    background: rgb(var(--background-rgb));
    z-index: 9999;
    border-radius: 4px;
    padding: 5px;
    min-width: fit-content;
    box-shadow: 0px 16px 18px var(--shadow-color) !important;
    border: 1px solid var(--border);
  }

  [data-layout="horizontal"] .slide .side-menu__item.active::before,
  [data-layout="horizontal"] .slide .side-menu__item:hover::before,
  [data-layout="horizontal"] .slide .side-menu__item:focus::before {
    content: "";
    position: absolute;
    background: transparent;
    height: 5px;
    width: 30px;
    box-shadow: 0px 0px 10px 2px var(--primary-bg-color),
      0px 0px 10px 2px var(--primary-bg-color);
    top: -10px;
    inset-inline-start: 42%;
    z-index: 9;
  }

  [data-layout="horizontal"] .app-sidebar {
    border-block-end: 1px solid var(--border) !important;
    border-inline-start: 0;
    border-inline-end: 0;
    background-image: none;
    background: var(--main-gradient-bg);
  }

  [data-layout="horizontal"] .main-footer {
    padding: 0.9rem 1rem !important;
  }

  [data-layout="horizontal"] .horizontal-main {
    z-index: 999 !important;
  }

  [data-layout="horizontal"] .hor-header .container,
  [data-layout="horizontal"] .horizontal-main .container,
  [data-layout="horizontal"] .main-content.horizontal-content .container {
    max-width: 85% !important;
    padding: 0;
  }

  [data-layout="horizontal"] .app-sidebar .side-item.side-item-category {
    display: none;
  }

  [data-layout="horizontal"] .side-menu {
    display: flex;
    overflow: hidden;
  }

  [data-layout="horizontal"] .main-sidemenu {
    margin-block-start: 0;
  }

  [data-layout="horizontal"] .main-sidebar-header {
    display: none;
  }

  [data-layout="horizontal"] .horizontal-main {
    width: 100%;
    position: relative;
    inset-block-end: initial;
  }

  [data-layout="horizontal"] .horizontal-main.ps {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .horizontal-main .slide .slide-menu,
  [data-layout="horizontal"] .horizontal-main .slide .sub-slide-menu {
    width: 190px;
    position: absolute;
    background: rgb(var(--background-rgb));
    z-index: 9999;
    border-radius: 4px;
    padding: 5px;
    min-width: fit-content;
    box-shadow: 0px 16px 18px var(--shadow-color) !important;
    border: 1px solid var(--border);
  }

  [data-layout="horizontal"] .horizontal-main .slide .slide-menu .slide-item:before,
  [data-layout="horizontal"] .horizontal-main .slide .sub-slide-menu .slide-item:before {
    inset-inline-start: 20px;
    inset-block-start: 11px;
    margin-inline-end: 8px;
  }

  [data-layout="horizontal"] .slide-item {
    padding: 8px 35px;
  }

  [data-layout="horizontal"] .slide.is-expanded .sub-slide-item2:before {
    inset-inline-start: 20px !important;
  }

  [data-layout="horizontal"] .sub-slide-menu .sub-slide-item2 {
    padding-block: 8px !important;
    padding-inline-start: 40px !important;
    padding-inline-end: 20px !important;
  }

  [data-layout="horizontal"] .sub-slide .sub-angle {
    inset-inline-end: -20px;
    inset-inline-start: auto;
    margin-inline-end: 0 !important;
    position: relative;
  }

  [data-layout="horizontal"] .sub-slide .sub-slide-menu {
    position: absolute;
    background-color: rgb(var(--background-rgb));
    z-index: 9999;
    box-shadow: 5px 5px 5px var(--shadow-color);
    border-radius: 4px;
    padding: 0px;
    min-width: fit-content;
    border: 1px solid var(--border);
    inset-inline-start: 180px;
    inset-block-start: 13px;
  }

  [data-layout="horizontal"] .side-menu>li>a {
    display: flex;
    margin: 0px !important;
    text-decoration: none;
    position: relative;
    color: var(--color2);
    padding: 10px 13px !important;
    font-weight: 400;
  }

  [data-layout="horizontal"] .main-sidemenu i.angle {
    position: relative;
    inset-block-start: 0px;
    inset-inline-start: 5px;
    transform: rotate(90deg) !important;
    font-size: 15px;
  }

  [data-layout="horizontal"] .main-sidemenu .slide.is-expanded i.angle {
    transform: rotate(-90deg);
    position: relative;
    inset-block-start: 0px;
    inset-inline-end: 0;
    font-size: 15px;
  }

  [data-layout="horizontal"] .breadcrumb-header {
    margin-block-start: 20px;
  }

  [data-layout="horizontal"] .slide {
    margin: 5px 3px !important;
    padding: 0 !important;
    position: static;
  }

  [data-layout="horizontal"] .logo-horizontal {
    display: block;
  }

  [data-layout="horizontal"] .app-sidebar__toggle {
    display: none;
  }

  [data-layout="horizontal"] .main-content {
    margin-inline-start: 0px !important;
  }

  [data-layout="horizontal"] .main-sidemenu .slide-right {
    inset-inline-end: 0;
  }

  [data-layout="horizontal"] .main-sidemenu .slide-left,
  [data-layout="horizontal"] .main-sidemenu .slide-right {
    position: absolute;
    inset-block-start: 0px;
    padding: 17px;
    color: rgb(var(--background-rgb));
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
  }

  [data-layout="horizontal"] .main-sidemenu .slide-left {
    inset-inline-start: 0;
  }

  [data-layout="horizontal"] .horizontal-main {
    position: relative;
    margin: 1px auto;
  }

  [data-layout="horizontal"] .main-sidemenu {
    overflow: hidden;
  }

  [data-layout="horizontal"] .sticky-pin {
    position: fixed;
    inset-block-start: 0;
    width: 100%;
    z-index: 1;
  }

  [data-layout="horizontal"] .hor-header {
    border-bottom: 1px solid var(--border);
    padding-inline-start: 0;
    z-index: 1000 !important;
  }

  [data-layout="horizontal"] .hor-header.sticky-pin {
    z-index: 1 !important;
  }

  [data-layout="horizontal"][dir="ltr"] .horizontal-main .slide.dropdownDirChng .slide-menu {
    inset-inline-end: 0 !important;
    inset-inline-start: initial !important;
  }

  [data-layout="horizontal"][dir="ltr"] .horizontal-main .slide.dropdownDirChng .sub-slide-menu {
    inset-inline-start: -108% !important;
    inset-inline-end: initial !important;
  }

  [data-layout="horizontal"] .side-menu,
  [data-layout="horizontal"] .main-sidemenu {
    overflow: visible !important;
  }

  [data-layout="horizontal"] .app.sidenav-toggled .app-sidebar {
    width: 100% !important;
  }

  [data-layout="horizontal"] .app-sidebar {
    width: 100% !important;
  }

  [data-layout="horizontal"] .main-sidemenu.container {
    overflow: hidden !important;
  }

  [data-layout="horizontal"][data-logo="centerlogo"] .logo-horizontal {
    display: block;
    position: absolute;
    inset-inline-start: 0;
    inset-inline-end: 0;
    margin: 0 auto;
    text-align: center;
  }

  [data-layout="horizontal"][data-logo="centerlogo"] .logo-horizontal .mobile-logo {
    margin: 0 auto;
  }
}

@media (min-width: 992px) and (max-width: 1150px) {
  [data-layout="horizontal"][data-logo="centerlogo"] .main-header-notification {
    display: none !important;
  }

  [data-layout="horizontal"][data-logo="centerlogo"] .fullscreen-button {
    display: none !important;
  }
}

[data-layout="horizontal"] .vertical-switcher {
  display: none;
}

[data-layout="horizontal"] .horizontal-switcher {
  display: block;
}

@media (max-width: 991px) {
  [data-layout="horizontal"] .hor-header {
    width: 100%;
    border-bottom: 1px solid var(--border);
  }

  [data-layout="horizontal"] .app-sidebar.horizontal-main {
    inset-inline-start: -300px;
  }

  [data-layout="horizontal"] .main-header.hor-header {
    box-shadow: 0px 7px 26px var(--shadow);
  }

  [data-layout="horizontal"] .main-sidemenu {
    padding: 0;
  }

  [data-layout="horizontal"] .horizontalMenucontainer .main-header.hor-header {
    position: sticky;
  }

  [data-layout="horizontal"] .horizontalMenucontainer .main-header.hor-header.sticky-pin {
    position: fixed;
    top: 0;
    transition: top ease 0.3s;
  }

  [data-layout="horizontal"] .slide-left,
  [data-layout="horizontal"] .slide-right {
    display: none !important;
  }

  [data-layout="horizontal"] .main-sidemenu.container {
    padding-inline-start: 0 !important;
    padding-inline-end: 0 !important;
  }
}

@media (min-width: 992px) {
  [data-hor-style="hor-hover"] .sub-slide2:hover .sub-slide-menu1 {
    display: block !important;
  }

  [data-hor-style="hor-hover"] .app-sidebar .slide-menu .sub-slide-menu1 a:before {
    inset-inline-start: 20px !important;
    inset-block-start: 11px;
  }

  [data-hor-style="hor-hover"] .sub-slide-menu .sub-side-menu__item:before {
    content: "\f22d";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    inset-inline-start: 20px !important;
    inset-block-start: 10px !important;
    font-size: 9px;
    color: var(--color2);
    font-weight: 900;
  }

  [data-hor-style="hor-hover"] .slide:hover ul.slide-menu {
    display: block !important;
  }

  [data-hor-style="hor-hover"] .sub-slide:hover .sub-slide-menu {
    display: block !important;
  }

  [data-hor-style="hor-hover"] .side-menu__item:not(.has-link),
  [data-hor-style="hor-hover"] .side-menu__item-sub:not(.has-link),
  [data-hor-style="hor-hover"] .side-menu__item-sub2:not(.has-link) {
    cursor: pointer;
  }
}

.logo-horizontal {
  display: none;
}

.logo-horizontal .mobile-logo,
.logo-horizontal .mobile-logo-1 {
  height: 3rem;
}

.logo-horizontal .mobile-logo-1 {
  display: none;
}

.horizontal-switcher {
  display: none;
}

@media (max-width: 991px) {

  .sidebar-mini .side-menu,
  .sidebar-gone .side-menu {
    margin-inline: 0 !important;
  }
}

@media (min-width: 992px) {
  [data-layout="horizontal"] .side-menu {
    display: inline-flex !important;
    overflow: hidden;
  }
}

/* ###### 6.5 Iconbar  ###### */
@media (max-width: 991.98px) {
  .main-iconbar-show .main-iconbar {
    display: flex;
  }

  .main-iconbar-show .main-iconbar-aside {
    display: block;
  }
}

.main-iconbar {
  width: 64px;
  display: none;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
  background-color: rgb(var(--background-rgb));
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 0;
  height: 100%;
  background-color: rgb(var(--background-rgb));
  z-index: 500;
}

.main-iconbar .nav {
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 5px 0;
}

.main-iconbar .nav-link {
  position: relative;
  padding: 0;
  color: var(--color);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-iconbar .nav-link i {
  font-size: 28px;
}

.main-iconbar .nav-link i.typcn {
  line-height: 1;
}

.main-iconbar .nav-link i.typcn::before {
  width: auto;
}

.main-iconbar .nav-link i.typcn-device-laptop,
.main-iconbar .nav-link i.typcn-th-large-outline {
  font-size: 25px;
}

.main-iconbar .nav-link i.fa-desktop {
  font-size: 23px;
}

.main-iconbar .nav-link:hover,
.main-iconbar .nav-link:focus {
  color: var(--gray-700);
}

.main-iconbar .nav-link.active {
  color: var(--primary-bg-color);
}

.main-iconbar .nav-link+.nav-link {
  margin-block-start: 10px;
}

@media (min-width: 992px) {
  .main-iconbar {
    display: flex;
  }
}

.main-iconbar-logo {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 0.9;
  color: var(--gray-900);
  flex-shrink: 0;
  margin-block-end: 20px;
  position: relative;
}

.main-iconbar-logo::after {
  content: "";
  position: absolute;
  inset-block-end: 6px;
  inset-inline-start: 20px;
  height: 3px;
  width: 24px;
  background-color: var(--primary-bg-color);
  border-radius: 2px;
}

.main-iconbar-logo:hover,
.main-iconbar-logo:focus {
  color: var(--gray-900);
}

.main-iconbar-bottom {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-iconbar-help {
  line-height: 0;
  font-size: 22px;
  color: var(--gray-600);
  margin-block-end: 20px;
}

.main-iconbar-help:hover,
.main-iconbar-help:focus {
  color: var(--gray-900);
}

.main-iconbar-aside {
  width: 230px;
  height: 100%;
  background-color: rgb(var(--background-rgb));
  border-inline-end: 1px solid var(--gray-400);
  position: fixed;
  inset-block-start: 0;
  inset-inline-start: 64px;
  overflow-y: auto;
  z-index: 600;
  display: none;
}

@media (min-width: 1200px) {
  .main-iconbar-aside {
    border-inline-end: 0;
    z-index: 500;
  }
}

@media (min-width: 992px) {
  .main-iconbar-aside.show {
    display: block;
  }
}

@media (min-width: 1200px) {
  .main-iconbar-aside.show+.main-content {
    margin-inline-start: 294px;
  }
}

.main-iconbar-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-inline-start: 0;
}

.main-iconbar-header .main-logo {
  line-height: 1;
}

.main-iconbar-toggle-menu {
  display: block;
  position: relative;
  line-height: 0;
  color: var(--gray-900);
  font-size: 28px;
  opacity: 0.5;
}

.main-iconbar-toggle-menu:hover,
.main-iconbar-toggle-menu:focus {
  opacity: 1;
  color: var(--gray-900);
}

.main-iconbar-toggle-menu i:first-child {
  display: none;
}

@media (min-width: 1200px) {
  .main-iconbar-toggle-menu {
    font-size: 24px;
  }

  .main-iconbar-toggle-menu::before {
    content: "";
    position: absolute;
    inset-block-start: 3px;
    inset-block-end: 3px;
    inset-inline-start: -1px;
    border-inline-start: 2px solid var(--gray-900);
  }

  .main-iconbar-toggle-menu i:first-child {
    display: block;
  }

  .main-iconbar-toggle-menu i:last-child {
    display: none;
  }
}

.main-iconbar-body {
  padding: 20px;
  padding-inline-start: 0;
  height: calc(100vh - 68px);
}

.main-iconbar-body .nav {
  flex-direction: column;
  border-inline-start: 2px solid var(--gray-200);
  padding-block-start: 10px;
  padding-inline-start: 20px;
}

.main-iconbar-body .nav-item {
  position: relative;
  display: block;
}

.main-iconbar-body .nav-item+.nav-item {
  margin-block-start: -1px;
  border-block-start: 1px dotted var(--gray-300);
}

.main-iconbar-body .nav-item.active .nav-link {
  color: var(--primary-bg-color);
}

.main-iconbar-body .nav-item.active .nav-link i {
  color: var(--primary-bg-color);
}

.main-iconbar-body .nav-item.show .nav-sub {
  display: block;
}

.main-iconbar-body .nav-link {
  position: relative;
  color: var(--color);
  font-size: 13px;
  padding: 0;
  display: flex;
  align-items: center;
  height: 38px;
}

.main-iconbar-body .nav-link i {
  color: var(--gray-500);
  font-size: 16px;
  font-weight: 400;
  line-height: 0;
  margin-inline-end: 10px;
}

.main-iconbar-body .nav-link i.typcn {
  line-height: 0.9;
}

.main-iconbar-body .nav-link i.typcn::before {
  width: auto;
}

.main-iconbar-body .nav-link:hover,
.main-iconbar-body .nav-link:focus,
.main-iconbar-body .nav-link:hover i,
.main-iconbar-body .nav-link:focus i {
  color: var(--primary-bg-color);
}

.main-iconbar-body .nav-link.with-sub {
  justify-content: space-between;
}

.main-iconbar-body .nav-link.with-sub::after {
  content: "\f3d0";
  font-family: "Ionicons";
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
  position: relative;
  margin-inline-start: auto;
  color: var(--gray-500);
}

.main-iconbar-body .nav-sub {
  list-style: none;
  padding: 0;
  margin: 0;
  border-block-start: 1px dotted var(--gray-300);
  display: none;
}

.main-iconbar-body .nav-sub .nav-sub-item {
  position: relative;
  display: block;
  margin-inline-start: 15px;
}

.main-iconbar-body .nav-sub .nav-sub-item+.nav-sub-item {
  border-block-start: 1px dotted var(--gray-300);
}

.main-iconbar-body .nav-sub .nav-sub-item.active>.nav-sub-link {
  color: var(--primary-bg-color);
}

.main-iconbar-body .nav-sub .nav-sub-link {
  display: flex;
  align-items: center;
  height: 36px;
  color: var(--color);
}

.main-iconbar-body .nav-sub .nav-sub-link:hover,
.main-iconbar-body .nav-sub .nav-sub-link:focus {
  color: var(--primary-bg-color);
}

@media (min-width: 992px) {
  .main-iconbar-body .nav-link.with-sub::after {
    margin-inline-start: 4px;
    font-size: 12px;
  }
}

.main-iconbar-title {
  font-size: 20px;
  font-weight: 700;
  color: var(--gray-900);
  margin-block-end: 10px;
  line-height: 1;
}

.main-iconbar-text {
  display: block;
  font-size: 12px;
  color: var(--gray-600);
  margin-block-end: 12px;
}

.main-iconbar-pane {
  margin-block-end: 20px;
  display: none;
}

.main-iconbar-pane.show {
  display: block;
}

/* ###### 6.1 Content  ###### */
.main-content .container,
.main-content .container-fluid {
  padding-inline-start: 24px;
  padding-inline-end: 24px;
}

@media (min-width: 992px) {
  .main-content .container {
    padding: 0;
  }
}

@media (max-width: 991.98px) {
  .main-content .container {
    max-width: none;
  }

  .browser-stats .sub-text {
    display: none;
  }

  .browser-stats h6 {
    line-height: 2;
  }
}

.main-content-app {
  padding-block-start: 0;
}

.main-content-app .container,
.main-content-app .container-fluid {
  height: 100%;
  padding: 0;
}

@media (min-width: 992px) {
  .main-content-app {
    padding-block-start: 0;
  }
}

.main-content-left {
  width: 100%;
  position: relative;
  flex-shrink: 0;
}

@media (min-width: 992px) {
  .main-content-left {
    display: block;
    padding: 0;
  }
}

.main-content-body {
  flex: 1;
}

.main-content-title {
  color: var(--gray-900);
  font-weight: 500;
  font-size: 18px;
  text-indent: -1px;
  line-height: 2;
  position: relative;
  margin-block-end: 20px;
}

@media (min-width: 992px) {
  .main-content-title {
    margin-block-end: 40px;
  }
}

.main-content-label-sm {
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  color: var(--gray-500);
  letter-spacing: 0.5px;
}

.main-content .header {
  border-radius: 8px;
  margin: 20px;
}

@media (min-width: 992px) {
  .main-content {
    padding-block-start: 0 !important;
    margin-inline-start: 240px;
  }
}

.main-content-left.main-content-left-mail.card-body {
  padding: 1.25rem;
}

.main-header-profile .main-img-user {
  display: inline-block;
  position: relative;
  width: 45px;
  height: 45px;
  border-radius: 100%;
  text-align: center;
}

.main-header-profile .main-img-user::after {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 6px;
  width: 6px;
  height: 6px;
  background-color: #17b86a;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  inset-block-end: 3px;
  display: block !important;
  inset-block-start: 35px;
}

.main-profile-menu .profile-user img {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  margin-inline-start: 0;
  width: 37px;
  height: 37px;
}

/* ###### 7.8 Profile  ###### */
.main-profile-social-list .media {
  align-items: center;
}

.main-profile-social-list .media+.media {
  margin-block-start: 20px;
}

.main-profile-social-list .media-icon {
  font-size: 34px;
  width: 30px;
  line-height: 0;
}

.main-profile-social-list .media-body {
  margin-inline-start: 20px;
}

.main-profile-social-list .media-body span {
  display: block;
  font-size: 12px;
}

.main-profile-social-list .media-body a {
  font-size: 13px;
}

.main-content-body-profile .nav {
  flex-direction: column;
  padding-block: 20px;
  padding-inline-start: 20px;
  padding-inline-end: 15px;
  border-block-end: 1px solid var(--border);
  margin-block-end: 1.3rem;
}

.main-content-body-profile .main-nav-line .nav-link {
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  color: var(--gray-800);
}

.main-content-body-profile .main-nav-line .nav-link.active {
  font-weight: 700;
  color: var(--primary-bg-color);
}

.main-content-body-profile .main-nav-line .nav-link.active::before {
  background-color: var(--primary-bg-color);
}

@media (min-width: 576px) {
  .main-content-body-profile .nav {
    flex-direction: row;
    align-items: center;
  }

  .main-content-body-profile .nav {
    padding-block-start: 18px;
    padding-inline-start: 14px;
    padding-block-end: 17px;
    padding-inline-end: 30px;
  }
}

@media (min-width: 992px) {
  .main-content-body-profile .nav {
    padding-inline-start: 20px;
  }
}

@media (min-width: 1200px) {
  .main-content-body-profile .nav {
    padding-inline-start: 25px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    width: auto;
    inset-block-start: auto;
    inset-inline-start: 0;
    inset-inline-end: 0;
    height: 2px;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {
  .main-content-body-profile .main-nav-line .nav-link+.nav-link {
    margin-block-start: 0;
    margin-inline-start: 20px;
  }
}

.main-profile-body {
  padding: 15px 0 0;
}

@media (min-width: 576px) {
  .main-profile-body {
    padding-block-start: 20px;
  }
}

@media (min-width: 992px) {
  .main-profile-body {
    padding-block-start: 25px;
    padding-inline-start: 0;
    padding-block-end: 0;
    padding-inline-end: 20px;
  }
}

@media (min-width: 1200px) {
  .main-profile-body {
    padding-inline-start: 25px;
  }
}

.main-profile-contact-list .media {
  align-items: center;
}

.main-profile-contact-list .media+.media {
  margin-block-start: 25px;
}

.main-profile-contact-list .media-icon {
  line-height: 0;
  font-size: 36px;
  width: 35px;
  text-align: center;
}

.main-profile-contact-list .media-body {
  margin-inline-start: 25px;
}

.main-profile-contact-list .media-body span {
  font-size: 12px;
  color: var(--gray-600);
  display: block;
  line-height: 1.3;
}

.main-profile-contact-list .media-body div {
  font-weight: 500;
  color: var(--gray-900);
}

@media (max-width: 1134px) {
  .main-profile-body .media.profile-footer {
    display: block !important;
  }

  .main-profile-body .media.profile-footer .media-body {
    padding-block-start: 3px;
    padding-inline-start: 0;
    padding-block-end: 15px;
    padding-inline-end: 0;
  }
}

@media (max-width: 578px) {
  .main-profile-menu .profile-user img {
    margin-inline-start: 0;
  }
}

.main-profile-contact-list .media-icon,
.main-profile-social-list .media-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  color: rgb(var(--background-rgb));
  font-size: 21px;
  position: relative;
  inset-block-start: 2px;
  border-radius: 100%;
}

.main-profile-body .card-header {
  padding-block-start: 0;
  padding-inline-start: 0;
}

.drop-down-profile {
  position: relative;
}

.profile-footer a {
  width: 34px;
  height: 34px;
  background: #efeff5;
  color: var(--black);
  margin: 1px auto;
  text-align: center;
  line-height: 34px;
  display: inline-block;
  border-radius: 50%;
  font-size: 12px;
}

.drop-down-profile span.assigned-task {
  position: absolute;
  font-size: 12px;
  width: 18px;
  height: 18px;
  line-height: 18px;
  color: rgb(var(--background-rgb));
  inset-block-start: 0;
  margin: 0 auto;
  text-align: center;
  border-radius: 50%;
  inset-inline-end: 0;
}

@media (max-width: 575px) {
  .main-content-body-profile .main-nav-line .nav-link.active::before {
    inset-inline-start: 0px;
  }
}

@media (max-width: 767.98px) {
  .main-profile-menu .profile-user img {
    margin-inline-start: 0 !important;
  }
}

.profile-name {
  margin-block-start: 8px;
}

@media (min-width: 601px) {
  .profile-cover__info .nav {
    padding-block-start: 15px;
    padding-inline-start: 0;
    padding-block-end: 10px;
    padding-inline-end: 170px;
    color: #999;
    font-size: 16px;
    line-height: 26px;
    font-weight: 300;
    text-align: center;
  }
}

.profile-cover__info .nav li {
  font-size: 14px;
  color: #464461;
  margin-block-end: 13px;
}

.profile-cover__info,
.profile-tab .main-nav-line .nav-link.active {
  color: var(--primary-bg-color);
  padding: 15px;
}

.profile-cover__info .nav span {
  display: block;
  margin-block-end: 10px;
  font-size: 25px;
}

.profile-cover__info .nav li:not(:first-child) {
  margin-inline-start: 30px;
  padding-inline-start: 30px;
  border-inline-start: 1px solid rgba(238, 238, 238, 0.2);
}

.profile-tab .main-nav-line .nav-link {
  font-weight: 500;
}

.main-nav-line .nav-link {
  color: #3c4858;
  position: relative;
  border: 0px solid #ededf5;
  padding: 7px;
}

.main-content-body-profile .main-nav-line .nav-link {
  color: #3c4858;
  position: relative;
  padding: 8px 25px;
}

.profile-tab.tab-menu-heading .main-nav-line .nav-link {
  position: relative;
  padding: 4px 20px;
}

.profile-tab .main-nav-line .nav-link {
  font-weight: 500;
}

@media (min-width: 769px) {
  .main-nav-line .nav-link+.nav-link {
    margin-block-start: 0;
    margin-inline-start: 5px;
    padding: 8px 25px;
  }
}

.user-lock img {
  width: 70px;
  height: 70px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .main-nav-line .nav-link+.nav-link {
    margin-block-start: 10px !important;
    margin-inline-start: 0 !important;
  }
}

.panel-tabs.main-nav-line .nav-link {
  border-radius: 0px;
}

.profile-image img {
  position: relative;
  width: 180px;
  height: 180px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.profile-online {
  inset-block-start: 6px;
  inset-inline-end: -10px;
  position: absolute;
  border: 3px solid rgb(var(--background-rgb));
  padding: 6px;
}

.profile-tab.tab-menu-heading {
  font-size: 14px !important;
}

.bg-white-transparent {
  background-color: rgba(255, 255, 255, 0.4);
}

.profile-cover {
  /* background-image: url(../img/photos/35.jpg); */
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
}

.profile-img {
  position: absolute;
  inset-block-start: -58px;
  border: 5px solid rgb(var(--background-rgb));
}

.sidebar {
  display: block;
  inset-block-start: 0px;
  width: 212px;
  height: 100vh;
  overflow: auto;
  padding: 10px 15px;
}

.sidebar .tab-menu-heading {
  position: fixed;
  inset-block-start: 0;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  background: var(--black-03);
}

.sidebar>.tabs-style2 {
  margin-block-start: 67px;
}

.sidebar>.tabs-style2>.panel-head>.nav-tabs {
  padding: 15px !important;
  justify-content: center;
  border-block-end: 1px solid var(--border);
}

.sidebar>.tabs-style2>.panel-head>.nav-tabs>.nav-item {
  flex: 1;
}

.sidebar>.tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link {
  padding: 8px;
  display: flex;
  align-items: center;
}

.sidebar>.tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link>.right-menu__icon {
  margin-inline-end: 2px;
  width: 16px;
  height: 16px;
}

.sidebar>.tabs-style2>.panel-body {
  padding: 0px;
  border: none;
}

.sidebar .list-group .list-group-item {
  border: none;
}

.sidebar .list-group .list-group-item+.list-group-item {
  margin-block-start: 5px;
}

.sidebar-mini .side-menu {
  margin-inline-start: 0 !important;
  margin-inline-end: 0 !important;
}

.sub-slide-menu .sub-side-menu__item.active {
  color: var(--primary-bg-color) !important;
}

/* Logos Styles */
@media (min-width: 992px) {
  .app-sidebar .main-sidebar-header .desktop-dark {
    display: block;
  }

  .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark {
    display: block;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark {
    display: block;
  }
}

@media (max-width: 991.98px) {

  .app-sidebar .main-sidebar-header .desktop-dark,
  .app-sidebar .main-sidebar-header .mobile-dark,
  .app-sidebar .main-sidebar-header .desktop-dark-1,
  .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  .main-header .responsive-logo .header-logo .mobile-logo {
    display: block;
  }

  .main-header .responsive-logo .header-logo .mobile-logo-1 {
    display: none;
  }
}

/* Logos Styles */
.side-menu__label1 {
  display: none !important;
}

.app.sidenav-toggled.sidenav-toggled-open .app-sidebar {
  background: var(--main-gradient-bg);
}

.app-sidebar.sidebar-scroll .main-sidebar-header {
  background-color: rgb(var(--background-rgb));
}

@media (min-width: 992px) {
  .app.sidenav-toggled .app-content {
    margin-inline-start: 80px;
  }

  .app.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
    width: 80px !important;
  }

  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  .app.sidenav-toggled .slide.is-expanded .slide-menu {
    display: none;
  }

  .side-badge {
    display: none;
  }

  .side-header {
    width: 240px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    position: fixed;
    z-index: 1;
  }
}

@media (max-width: 991px) {
  .app {
    overflow-x: hidden;
  }

  .app .app-sidebar {
    inset-inline-start: -240px;
  }

  .app .app-sidebar__overlay {
    visibility: hidden;
  }

  .app.sidenav-toggled .app-content {
    margin-inline-start: 0;
  }

  .app.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
  }

  .app.sidenav-toggled .app-sidebar__overlay {
    visibility: visible;
  }

  .app.sidebar-gone.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
  }

  .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-inline-end: 13px !important;
    margin-inline-start: 0;
  }
}

.app-content {
  min-height: calc(100vh - 114px);
  margin-block-end: 0 !important;
  transition: margin-inline-start 320ms ease;
}

.app-content .side-app {
  padding: 0px 30px;
}

.footer .container {
  width: 100%;
  padding-inline-end: 0.75rem;
  padding-inline-start: 0.75rem;
  margin-inline-end: auto;
  margin-inline-start: auto;
}

@media (min-width: 992px) {
  .app-content {
    margin-inline-start: 0px;
  }

  .app.sidenav-toggled .avatar-xl {
    width: 55px !important;
    height: 55px !important;
  }

  .app.sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
    line-height: 19px;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled .side-item.side-item-category {
    display: none;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-item.side-item-category {
    display: block;
  }
}

@media (max-width: 991px) {
  .app-content {
    min-width: 100%;
  }
}

@media print {
  .app-content {
    margin: 0;
    padding: 0;
    background-color: rgb(var(--background-rgb));
  }
}

.light-text {
  font-weight: 300 !important;
}

.semibold-text {
  font-weight: 600 !important;
}

/*----- Componant: Top Navigation Bar ----- */
.app-sidebar__toggle {
  transition: all ease 0.3s;
  z-index: 1;
  display: block;
  font-size: 22px;
  color: var(--header-color);
  position: relative;
  line-height: 21px;
  outline: none;
  height: 37px;
  width: 37px;
  border-radius: 4px;
  margin: auto;
  text-align: center;
}

.app-sidebar__toggle:hover {
  background: var(--bg-hover);
}

.app-sidebar__toggle:hover .header-icon {
  color: var(--primary-bg-color);
}

.app-sidebar__toggle:focus,
.app-sidebar__toggle:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .app-sidebar__toggle {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .main-sidebar-header {
    display: none !important;
  }

  .main-sidemenu {
    margin-block-start: 20px !important;
  }
}

.main-sidemenu .side-menu {
  transition: left 320ms ease, width 320ms ease;
}

.app-sidebar {
  width: 240px !important;
}

.app-sidebar {
  position: fixed;
  inset-block-start: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
  color: var(--menu-color);
  max-height: 100%;
  z-index: 1024;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-box-shadow: 0px 0px 4px var(--shadow-color);
  box-shadow: none;
  border-inline-end: 1px solid var(--primary01);
  transition: left 320ms ease, width 320ms ease;
}

.app-sidebar:before {
  position: absolute;
  content: "";
  width: 0.075rem;
  height: 80%;
  top: 10%;
  bottom: 0;
  inset-inline-start: 0;
  background: linear-gradient(45deg,
      transparent,
      var(--primary03),
      var(--primary06),
      var(--primary06),
      var(--primary03),
      transparent);
  box-shadow: 0 8px 17px 0.125px var(--primary-bg-color);
}

.app-sidebar::-webkit-scrollbar {
  width: 6px;
}

.app-sidebar::-webkit-scrollbar-thumb {
  background: var(--black-2);
}

@media print {
  .app-sidebar {
    display: none;
  }
}

@media (max-width: 991.98px) {
  .app-sidebar__overlay {
    position: fixed;
    inset-block-start: 0;
    inset-inline-start: 0;
    inset-block-end: 0;
    inset-inline-end: 0;
    z-index: 9;
  }

  .sidenav-toggled .app-sidebar {
    background: var(--main-gradient-bg);
  }
}

.slide .side-menu__item.active::before,
.slide .side-menu__item:hover::before,
.slide .side-menu__item:focus::before {
  content: "";
  position: absolute;
  background: transparent;
  height: 25px;
  width: 5px;
  inset-inline-start: 10px;
  box-shadow: 0px 0px 10px 2px var(--primary-bg-color),
    0px 0px 10px 2px var(--primary-bg-color);
  z-index: 9;
  inset-inline-start: -15px;
}

.side-menu {
  margin-block-end: 0;
  padding: 0;
  list-style: none;
}

.side-menu .slide .side-menu__item {
  padding: 10px 20px;
  margin: 5px 0;
  border-radius: 0;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

.side-menu .slide .side-menu__item.active,
.side-menu .slide .side-menu__item:hover,
.side-menu .slide .side-menu__item:focus {
  text-decoration: none;
  color: var(--primary-bg-color);
  background-position: center -1px;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  width: 100%;
}

.side-menu .slide .side-menu__item.active .side-menu__label {
  color: var(--primary-bg-color);
}

.side-menu .slide .side-menu__item.active .angle {
  color: var(--primary-bg-color);
}

.side-menu .slide .side-menu__item.active .side-menu__icon {
  fill: var(--primary-bg-color) !important;
}

.side-menu .slide .side-menu__item.active .side-menu__icon,
.side-menu .slide .side-menu__item:hover .side-menu__icon {
  color: var(--primary-bg-color);
}

.app-sidebar .slide {
  margin: 0;
  padding: 0px 10px;
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__label {
    display: none !important;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
  text-decoration: none;
  color: #b5c1d2;
}

.slide-item.active,
.slide-item:hover,
.slide-item:focus {
  text-decoration: none;
  color: var(--primary-bg-color) !important;
}

.slide-item.active::before,
.slide-item:hover::before,
.slide-item:focus::before {
  color: var(--primary-bg-color) !important;
}

.slide-item .app-sidebar .slide-menu .slide-item:before:hover {
  color: var(--primary-bg-color) !important;
}

.slide.is-expanded .slide-menu a:hover:before {
  color: var(--primary-bg-color) !important;
}

.slide-menu .sub-slide-menu {
  padding: 0 10px;
}

.sub-slide .sub-angle {
  margin-inline-start: auto;
  float: var(--float-end);
  font-size: 12px;
  margin-block-start: 3px;
}

.slide-menu li {
  position: relative;
}

.slide.is-expanded a {
  color: var(--primary05);
  text-decoration: none;
}

.slide.is-expanded .sub-side-menu__item:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 15px;
  font-size: 9px;
  color: var(--primary05);
  font-weight: 900;
}

.sub-slide-menu1 .sub-slide-item2:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  inset-inline-start: 35px !important;
  font-size: 9px;
  color: var(--primary05);
  font-weight: 900;
}

.side-menu .side-menu__icon {
  font-size: 16px;
  line-height: 0;
  margin-inline-end: 10px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 2px;
  text-align: center;
  color: var(--primary-bg-color);
  fill: var(--primary-bg-color);
}

.side-menu__icon {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 25px;
}

.side-menu__label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: var(--primary05);
  position: relative;
  font-size: 0.85rem;
  line-height: 1;
  vertical-align: middle;
  font-weight: 300;
}

.sidebar-mini .side-menu__label {
  display: block;
  margin-right: 20px;
}

.app-sidebar .side-item.side-item-category {
  color: var(--primary-bg-color);
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.5px;
  margin-block-end: 12px;
  height: 15px;
  padding-block: 0;
  padding-inline-start: 25px;
  padding-inline-end: 20px;
  opacity: 0.6;
  white-space: nowrap;
}

.app-sidebar .side-item.side-item-category:not(:first-child) {
  margin-block-start: 22px;
}

.slide-menu {
  display: none;
  padding-inline-start: 25px;
  list-style: none;
}

.slide.is-expanded .slide-menu {
  display: block;
}

.slide-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  padding: 8px 35px;
  font-weight: 300;
  color: var(--primary05);
}

.slide-item .icon {
  margin-inline-end: 5px;
}

.angle {
  transform-origin: center;
  transition: transform 320ms ease;
}

@media (max-width: 848px) {
  .profile-user .p-text {
    display: none;
  }
}

@media (min-width: 992px) {

  .sidenav-toggled .angle,
  .sidenav-toggled .user-notification,
  .sidenav-toggled .user-info {
    display: none;
  }

  .sidenav-toggled .sidenav-toggled.user-notification::before {
    background: transparent;
    display: none;
  }

  .sidenav-toggled .avatar-xl {
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    font-size: 1rem;
    margin-block-end: 0px !important;
  }

  .sidenav-toggled .side-menu {
    margin-block-start: 0px;
  }

  .sidenav-toggled .app-content {
    margin-inline-start: 0;
  }

  .sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
  }

  .sidenav-toggled .app-sidebar:hover {
    overflow: visible;
  }

  .sidenav-toggled.sidenav-toggled-open .slide .side-menu__item {
    margin: 0;
  }

  .sidenav-toggled .slide .side-menu__item:hover .side-menu__label {
    opacity: 1;
  }

  .sidenav-toggled .slide .side-menu__item:hover+.slide-menu {
    visibility: visible;
  }

  .sidenav-toggled .side-menu__label {
    display: block;
    position: absolute;
    inset-block-start: 0;
    inset-inline-start: 50px;
    padding-block: 12px;
    padding-inline-start: 5px;
    padding-inline-end: 20px;
    margin-inline-start: -3px;
    line-height: 1;
    opacity: 0;
    background: rgb(var(--background-rgb));
    border-start-end-radius: 4px;
    border-end-end-radius: 4px;
    -webkit-box-shadow: 0px 8px 17px var(--black-2);
    box-shadow: 0px 8px 17px var(--black-2);
  }

  .sidenav-toggled .slide:hover .side-menu__label {
    opacity: 1;
  }

  .sidenav-toggled .slide:hover .slide-menu {
    max-height: 100%;
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  .sidenav-toggled .slide .side-menu__label {
    border-end-end-radius: 0;
  }

  .sidenav-toggled .slide-menu {
    position: absolute;
    inset-inline-start: 90px;
    min-width: 180px;
    opacity: 0;
    border-end-end-radius: 4px;
    z-index: 9;
    visibility: hidden;
    transition: visibility 320ms ease;
    box-shadow: 0px 8px 17px var(--black-2);
  }

  .app.sidenav-toggled .side-menu_label {
    display: block;
    font-size: 12px;
  }

  .app.sidenav-toggled .side-menu__label {
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }

  .app.sidenav-toggled .nav-badge {
    position: absolute;
    inset-block-start: 8px;
    inset-inline-end: 28px;
    padding: 0.2rem 0.4rem;
    font-size: 11px;
  }
}

.app.sidenav-toggled .side-menu .side-menu__icon {
  margin-inline-end: 0;
  margin-inline-start: 0;
}

.app .side-menu_label {
  display: none;
}

.dropdown-menu.dropdown-menu-right {
  inset-inline-start: auto;
}

.dropdown-item .fa,
.dropdown-item .icon {
  vertical-align: middle;
}

.app-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  background-color: rgb(var(--background-rgb));
  margin: -30px -30px 30px;
  padding: 20px 30px;
  -webkit-box-shadow: 0 1px 2px var(--black-1);
  box-shadow: 0 1px 2px var(--black-1);
}

.app-title h1 {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
}

.app-title p {
  margin-block-end: 0;
  font-style: italic;
}

@media print {
  .app-title {
    display: none;
  }
}

@media (max-width: 480px) {
  .app-title {
    margin: -15px -15px 15px;
    padding: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}

@media (max-width: 480px) {
  .app-title p {
    display: none;
  }
}

.app-breadcrumb {
  margin-block-end: 0;
  text-align: end;
  font-weight: 500;
  font-size: 13px;
  text-transform: capitalize;
  padding: 0;
  text-align: start;
  background-color: transparent;
}

@media (max-width: 480px) {
  .app-breadcrumb {
    margin-block-start: 10px;
  }
}

.tile {
  position: relative;
  background: rgb(var(--background-rgb));
  border-radius: 3px;
  padding: 20px;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px var(--black-2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px var(--black-2);
  margin-block-end: 30px;
  transition: all 320ms ease-in-out;
}

@media print {
  .tile {
    border: 1px solid #ddd;
  }
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge {
  position: absolute;
  inset-block-start: 5px;
  inset-inline-start: 57px;
  display: block !important;
  padding: 3px 5px !important;
}

.sidenav-toggled .main-wrapper .side-menu .side-menu__item .nav-badge1 {
  display: none;
}

.nav-badge {
  border-radius: 30px;
  padding: 0.4em 0.6em;
  font-size: 12px;
}

.user-info .text-dark {
  color: rgb(var(--background-rgb)) !important;
  font-weight: 400;
  font-size: 16px;
}

.user-info .text-muted {
  color: var(--white-4) !important;
}

.side-header .header-brand1 {
  text-align: center;
  display: table;
}

.side-header .header-brand-img.desktop-logo {
  max-height: 2.5rem;
  text-align: center;
  display: block;
  margin-inline-end: 0;
}

.sidenav-toggled .user-pic {
  margin-block-end: 0;
}

.sidenav-toggled .sidebar-navs {
  display: none;
}

.side-header {
  display: flex;
  transition: left 320ms ease;
}

.sidenav-toggled .app-sidebar__toggle {
  display: block;
}

.user-pic {
  margin-block-end: 0.8rem;
}

.sidebar-navs a {
  background: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.07) !important;
  color: rgb(var(--background-rgb)) !important;
  border-radius: 4px;
  padding: 0.8rem !important;
}

.app-sidebar .sidebar-navs {
  padding-block-start: 0;
  padding-inline: 10px;
  padding-block-end: 10px;
  border-block-end: 1px solid var(--white-1);
}

.sidebar-navs .nav li:last-child a {
  margin-inline-end: 0 !important;
}

.side-menu h3 {
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 1;
  padding: 20px 34px 10px;
  text-transform: capitalize;
  width: 100%;
  position: relative;
  color: #90909e;
  /* color: var(--white-4)!important; */
  position: relative;
}

.side-menu h3:after {
  content: "--";
  position: absolute;
  inset-inline-start: 21px;
  inset-block-start: 20px;
}

.sidenav-toggled .side-menu h3 {
  display: none;
}

.slide.is-expanded .slide-menu,
.sub-slide {
  display: block;
}

/*-- Sub-slide--**/
.sub-side-menu__item {
  position: relative;
  display: flex;
  align-items: center;
  padding: 7px 0;
  font-size: 13px;
  color: var(--color2);
}

.sub-side-menu__label {
  white-space: normal;
  flex: 1 1 auto;
  font-weight: 400;
}

.sub-slide .sub-angle {
  transform-origin: center;
}

.sub-slide.is-expanded .sub-angle {
  transform: rotate(-90deg);
}

.sub-slide2 .sub-angle {
  transform-origin: center;
}

.sub-slide2.is-expanded .sub-angle2 {
  transform: rotate(-90deg);
}

.sub-slide-menu {
  list-style: none;
  padding: 0;
}

.sub-slide-item {
  display: flex;
  align-items: center;
  position: relative;
  list-style: none;
  padding-block-start: 8px !important;
  padding-inline-start: 0 !important;
  padding-inline-end: 25px !important;
}

.sub-slide-menu {
  display: none;
  font-size: 0.8rem !important;
  padding-inline-start: 0;
  list-style: none;
}

.sub-slide.is-expanded .sub-slide-menu {
  display: block;
}

.slide-menu .sub-slide.is-expanded {
  display: block;
}

.sub-side-menu__item {
  padding-inline-start: 40px !important;
  padding-inline-end: 20px !important;
}

.sub-side-menu__item:hover {
  color: var(--primary-bg-color) !important;
}

.sub-side-menu__item:hover::before {
  color: var(--primary-bg-color) !important;
}

.sub-slide.is-expanded .sub-slide-item {
  padding-inline-start: 42px !important;
}

.sub-slide-menu1 .sub-slide-item2 {
  padding-block: 6px !important;
  padding-inline-start: 48px !important;
  padding-inline-end: 0 !important;
  display: block;
  font-size: 13px;
  color: var(--color2);
}

.sub-slide .sub-angle2 {
  margin-inline-start: 68px;
}

.sub-slide-menu1 .sub-slide-item2:hover {
  color: var(--primary-bg-color) !important;
}

.app-sidebar .side-menu__item.active:hover {
  color: #7282a9 !important;
}

.app-sidebar .slide-menu .sub-slide-menu a:before {
  inset-inline-start: 25px;
}

.side-menu .slid.active .sub-slide.is-expanded .sub-slide-menu {
  max-height: 0;
}

.app-sidebar .slide-menu .slide-item:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 20px;
  font-size: 10px;
  color: var(--primary05);
  font-weight: 900;
  opacity: 0.5;
}

.app-sidebar__toggle .close-toggle,
.sidebar-mini.sidenav-toggled .open-toggle {
  display: none;
}

.header-icon {
  text-align: center;
  line-height: 1.8;
  font-size: 1.3rem;
  color: var(--primary-bg-color);
}

.sidebar-mini.sidenav-toggled .close-toggle {
  display: block;
  color: var(--black);
  font-size: 18px;
  line-height: 42px;
  text-align: center;
}

.sidenav-toggled .app-content {
  transition: margin-inline-start 320ms ease;
}

.sub-slide-menu .active .sub-slide-item.active {
  color: #d6573c;
}

.app-sidebar .slide-menu a.active:before {
  color: var(--primary-bg-color);
  opacity: 1;
}

@media (min-width: 992px) {
  .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
    inset-inline-start: 0;
    width: 240px !important;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__label {
    white-space: no-wrap;
    margin-right: 20px;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    opacity: 1;
    display: block !important;
    position: initial;
    font-size: 0.85rem;
    line-height: 1;
    font-weight: 300;
  }

  .app.sidebar-mini.sidenav-toggled-open .angle {
    display: block;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu .side-menu__icon {
    margin-inline-end: 12px;
  }

  .app.sidebar-mini.sidenav-toggled-open .ps>.ps__rail-y>.ps__thumb-y {
    inset-inline-start: 3px;
    background-color: #cfd3e1;
  }

  .app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
    width: 240px;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-badge {
    display: block !important;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    inset-inline-start: 0;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .side-menu__item:hover+.slide-menu {
    visibility: visible;
    opacity: inherit;
  }

  .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide.is-expanded .slide-menu {
    display: block;
    box-shadow: none;
  }

  .sidenav-toggled .slide-menu {
    box-shadow: none !important;
  }

  .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide-menu {
    position: inherit;
    visibility: visible;
    opacity: inherit !important;
    z-index: 0;
    cursor: pointer;
  }
}

@media (min-width: 991px) and (max-width: 992px) {
  .main-sidebar-header .main-logo {
    display: block;
  }
}

@media (min-width: 1200px) {
  .main-sidebar-sticky+.main-content {
    margin-inline-start: 240px;
  }
}

.main-sidebar-header {
  height: 64px;
  padding: 13px 20px;
  width: 240px;
  background-color: transparent;
  backdrop-filter: blur(5px);
  border-block-end: 1px solid var(--primary01);
  position: fixed;
  z-index: 999;
  inset-block-start: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
  transition: left 320ms ease, width 320ms ease;
  outline: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
}

.sub-slide .sub-angle2 {
  margin-inline-start: auto;
  float: var(--float-end);
  font-size: 12px;
  margin-block-start: 3px;
  transform-origin: center;
  opacity: 0.8;
}

.side-menu__item:hover .side-menu__icon,
.side-menu__item:hover .side-menu__label,
.side-menu__item:hover .angle {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

.sidebar-mini .side-menu {
  margin-inline-start: 0 !important;
}

@media (min-width: 992px) {
  .app.sidenav-toggled .side-menu__item {
    display: block;
    padding: 8px 0 9px;
    text-align: center;
  }

  .app.sidebar-mini.sidenav-toggled-open .side-menu__item {
    display: flex;
    align-items: center;
    padding: 10px 20px;
    text-align: start;
  }
}

.sub-slide2.is-expanded .sub-slide-menu1 {
  display: none;
}

.slide-menu .sub-slide-menu1 {
  padding: 0 10px;
}

.sub-slide-menu1 {
  display: none;
  font-size: 0.8rem !important;
  padding-inline-start: 0;
  list-style: none;
}

@media (min-width: 992px) {
  [data-vertical-style="icontext"] .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-block-end: 5px;
  }

  [data-vertical-style="icontext"] .sidenav-toggled .side-menu__label {
    display: block !important;
    font-size: 11px !important;
    position: relative !important;
  }

  [data-vertical-style="icontext"] .app.sidenav-toggled .side-menu__label {
    font-size: 13px !important;
  }

  [data-vertical-style="icontext"] .app.sidenav-toggled .app-sidebar {
    width: 110px !important;
  }

  [data-vertical-style="icontext"] .app.sidenav-toggled .main-sidebar-header {
    width: 110px !important;
  }

  [data-vertical-style="icontext"] .app.sidenav-toggled .main-header {
    padding-inline-start: 110px !important;
  }

  [data-vertical-style="icontext"] .app.sidenav-toggled .app-content {
    margin-inline-start: 110px;
  }

  [data-vertical-style="icontext"] .app.sidebar-mini.sidenav-toggled-open .app-sidebar {
    width: 240px !important;
  }

  [data-vertical-style="icontext"] .app.sidebar-mini.sidenav-toggled-open .main-sidebar-header {
    width: 240px !important;
  }

  [data-vertical-style="icontext"] .app.sidebar-mini.sidenav-toggled-open .close-toggle {
    display: none !important;
  }

  [data-vertical-style="icontext"] .sidebar-mini.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    width: inherit;
    height: inherit;
  }
}

@media (min-width: 992px) {
  [data-vertical-style="closed"] .app.sidenav-toggled .app-sidebar {
    display: none !important;
  }

  [data-vertical-style="closed"] .app.sidenav-toggled .app-content {
    margin-inline-start: 0px !important;
  }

  [data-vertical-style="closed"] .app.sidenav-toggled .main-header {
    padding-inline-start: 0px !important;
  }
}

[data-vertical-style="hover"] .app-sidebar .side-menu__item {
  pointer-events: none;
  cursor: pointer;
}

[data-vertical-style="hover"] .sub-slide a {
  color: var(--primary05);
  text-decoration: none;
}

@media (min-width: 992px) {
  [data-vertical-style="hover"] .sticky.sticky-pin .app-sidebar .main-sidebar-header {
    background-color: rgb(var(--background-rgb));
  }

  [data-vertical-style="hover"] .sidenav-toggled-open .slide-menu {
    border-start-start-radius: 0 !important;
    border-start-end-radius: 5px !important;
    border-end-end-radius: 5px !important;
    border-end-start-radius: 0 !important;
  }

  [data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: block;
  }

  [data-vertical-style="hover"] .slide-item {
    padding-block: 8px;
    padding-inline-start: 35px;
    padding-inline-end: 18px;
  }

  [data-vertical-style="hover"] .slide-menu {
    border-start-start-radius: 0;
    border-start-end-radius: 5px;
    border-end-end-radius: 5px;
    border-end-start-radius: 0;
  }

  [data-vertical-style="hover"] .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    position: absolute;
    inset-inline-start: 108px;
    min-width: 180px;
    opacity: 1;
    z-index: 9999;
    visibility: hidden;
    inset-block-start: 0;
    display: block;
    max-height: inherit;
    background: var(--main-gradient-bg);
  }

  [data-vertical-style="hover"] .app-sidebar .slide {
    position: relative;
    display: block;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .ps>.ps__rail-y {
    display: none !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .slide-menu {
    padding-inline-start: 0 !important;
    padding: 10px 0px !important;
    position: absolute;
    opacity: 1;
    visibility: visible;
    z-index: 999;
    width: 200px;
    inset-inline-start: 110px;
    border: 1px solid var(--border);
    transition: none !important;
    display: block !important;
    box-shadow: 8px 8px 17px var(--shadow-color);
    inset-block-start: 0;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .slide:hover .slide-menu {
    visibility: visible;
    display: block !important;
    height: auto !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .app-sidebar {
    overflow: visible !important;
    position: absolute !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu__label {
    white-space: nowrap;
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu .side-menu__icon {
    margin-block-end: 5px !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    padding: 10px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-item.side-item-category {
    display: none !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu__icon {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin: 0 auto !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    display: block;
    margin: 0 auto !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item {
    display: block;
    margin: 5px 0 !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item i {
    display: none !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .main-content {
    min-height: 1300px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .side-menu__label {
    font-size: 11px !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .app-sidebar {
    position: absolute !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-block-end: 5px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .side-menu__label {
    display: block !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .app-sidebar__toggle .close-toggle {
    display: block;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .main-header {
    padding-inline-start: 110px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .main-sidebar-header {
    width: 110px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .app-content {
    margin-inline-start: 110px;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
    width: 110px !important;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  [data-vertical-style="hover"] .app.sidenav-toggled .slide.is-expanded .slide-menu {
    display: none;
  }

  [data-vertical-style="hover"] .side-badge {
    display: none;
  }

  [data-vertical-style="hover"] .side-header {
    width: 240px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    position: fixed;
    z-index: 1;
  }
}

[data-vertical-style="hover"] .sub-slide-menu .sub-side-menu__item:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  inset-inline-start: 25px !important;
  font-size: 9px;
  color: var(--primary05);
  font-weight: 900;
}

@media (min-width: 992px) {
  [data-vertical-style="hover1"] .app-sidebar .side-menu__item {
    pointer-events: none;
    cursor: pointer;
  }

  [data-vertical-style="hover1"] .sub-slide a {
    color: var(--primary05);
    text-decoration: none;
  }

  [data-vertical-style="hover1"] .sticky.sticky-pin .app-sidebar .main-sidebar-header {
    background-color: rgb(var(--background-rgb));
  }

  [data-vertical-style="hover1"] .sidenav-toggled-open .slide-menu {
    border-start-start-radius: 0 !important;
    border-start-end-radius: 5px !important;
    border-end-end-radius: 5px !important;
    border-end-start-radius: 0 !important;
  }

  [data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: block;
  }

  [data-vertical-style="hover1"] .slide-item {
    padding-block: 8px;
    padding-inline-start: 35px;
    padding-inline-end: 18px;
  }

  [data-vertical-style="hover1"] .sidenav-toggled .side-menu__label1 {
    display: block !important;
  }

  [data-vertical-style="hover1"] .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    position: absolute;
    inset-inline-start: 108px;
    min-width: 180px;
    opacity: 1;
    z-index: 9999;
    visibility: hidden;
    inset-block-start: 0;
    display: block;
    max-height: inherit;
  }

  [data-vertical-style="hover1"] .app-sidebar .slide {
    position: relative;
    display: block;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .ps>.ps__rail-y {
    display: none !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu__label1 {
    display: block;
    font-size: 15px;
    font-weight: 500;
    border-block-end: 1px solid var(--border);
    padding: 10px 20px !important;
    margin-block-end: 6px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu__label1 a {
    color: var(--primary-bg-color) !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .slide-menu {
    padding-inline-start: 0 !important;
    position: absolute;
    background: var(--main-gradient-bg);
    opacity: 1;
    visibility: visible;
    z-index: 999;
    inset-inline-start: 110px;
    width: 200px;
    border: 1px solid var(--border);
    transition: none !important;
    display: block !important;
    box-shadow: 8px 8px 17px var(--shadow-color);
    inset-block-start: 0;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .slide:hover .slide-menu {
    visibility: visible;
    display: block !important;
    height: auto !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .app-sidebar {
    overflow: visible !important;
    position: absolute !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu__label {
    display: none !important;
    white-space: nowrap;
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    color: var(--primary05);
    opacity: 1;
    background: transparent;
    font-size: 12px;
    box-shadow: none;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu .side-menu__icon {
    margin-block-end: 5px !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    padding: 10px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .main-sidebar-header .desktop-dark,
  [data-vertical-style="hover1"] .app.sidenav-toggled-open .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .main-sidebar-header .mobile-logo {
    display: block !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-item.side-item-category {
    display: none !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu__icon {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin: 0 auto !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item.active {
    display: block;
    margin: 0 auto !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item {
    display: block;
    margin: 5px 0 !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu .slide .side-menu__item i {
    display: none !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled-open .side-menu__label {
    display: none !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .main-content {
    min-height: 1300px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .side-menu__label {
    display: none !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .side-menu__label {
    font-size: 11px !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .app-sidebar {
    position: absolute !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .side-menu .side-menu__icon {
    margin-block-end: 5px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .app-sidebar__toggle .close-toggle {
    display: block;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .main-header {
    padding-inline-start: 110px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .main-sidebar-header {
    width: 110px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .app-content {
    margin-inline-start: 110px;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .app-sidebar {
    inset-inline-start: 0;
    width: 110px !important;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .app-sidebar__overlay {
    visibility: hidden;
  }

  [data-vertical-style="hover1"] .app.sidenav-toggled .slide.is-expanded .slide-menu {
    display: none;
  }

  [data-vertical-style="hover1"] .side-badge {
    display: none;
  }

  [data-vertical-style="hover1"] .side-header {
    width: 240px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    position: fixed;
    z-index: 1;
  }
}

[data-vertical-style="hover1"] .sub-slide-menu .sub-side-menu__item:before {
  content: "\f068";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  inset-inline-start: 25px !important;
  font-size: 9px;
  color: var(--primary05);
  font-weight: 900;
}

@media (min-width: 992px) {
  [data-vertical-style="doublemenu"] .app {
    /*.main-content {
    min-height: 1300px;
    }*/
  }

  [data-vertical-style="doublemenu"] .app .slide .side-menu__item:hover+.slide-menu {
    visibility: hidden;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar {
    position: fixed !important;
    overflow: visible !important;
    inset-inline-start: 0;
    width: 80px !important;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .mobile-dark {
    display: block;
  }

  [data-vertical-style="doublemenu"] .app .side-menu__label {
    display: none !important;
    font-size: 11px !important;
    white-space: nowrap;
    display: block;
    position: relative;
    padding: 0;
    margin: 0;
    inset-inline-start: 0;
    color: var(--primary05);
    opacity: 1;
    background: transparent;
    box-shadow: none;
  }

  [data-vertical-style="doublemenu"] .app .side-menu__label1 {
    display: block !important;
    font-size: 15px;
    font-weight: 500;
    border-block-end: 1px solid var(--border);
    padding: 20px !important;
    width: 200px;
    margin-block-end: 6px;
    margin-block-start: -65px;
  }

  [data-vertical-style="doublemenu"] .app .side-menu__label1 a {
    color: var(--primary-bg-color) !important;
  }

  [data-vertical-style="doublemenu"] .app .side-menu__icon {
    margin-inline-end: 0;
    margin-inline-start: 0;
    margin: 0 auto !important;
  }

  [data-vertical-style="doublemenu"] .app .side-menu .side-menu__icon {
    margin-block-end: 5px !important;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar__toggle .close-toggle {
    display: none;
  }

  [data-vertical-style="doublemenu"] .app .main-header {
    padding-inline-start: 280px;
  }

  [data-vertical-style="doublemenu"] .app .main-sidebar-header {
    width: 80px;
  }

  [data-vertical-style="doublemenu"] .app .app-content {
    margin-inline-start: 280px;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar__overlay {
    visibility: hidden;
  }

  [data-vertical-style="doublemenu"] .app .slide.is-expanded .slide-menu {
    display: none;
  }

  [data-vertical-style="doublemenu"] .app .slide .slide-menu {
    position: absolute;
    inset-inline-start: 79px;
    min-width: 200px;
    opacity: 1;
    z-index: 9999;
    visibility: hidden;
    inset-block-start: 0;
    display: block;
    max-height: inherit;
  }

  [data-vertical-style="doublemenu"] .app .ps>.ps__rail-y {
    display: none !important;
  }

  [data-vertical-style="doublemenu"] .app .slide-menu {
    padding-inline-start: 0 !important;
    position: absolute;
    opacity: 1;
    visibility: visible;
    z-index: 999;
    inset-inline-start: 80px;
    width: 200px;
    border: 1px solid var(--border);
    transition: none !important;
    display: block !important;
    box-shadow: none;
    inset-block-start: 0;
  }

  [data-vertical-style="doublemenu"] .app .slide .slide-menu.double-menu-active {
    visibility: visible !important;
    display: block !important;
    height: 100% !important;
    position: absolute;
    top: 0;
    padding-block-start: 65px !important;
    border-radius: 0 !important;
    border-block-start: 0;
    overflow: hidden scroll;
  }

  [data-vertical-style="doublemenu"] .app .side-menu .slide .side-menu__item.active {
    padding: 10px;
  }

  [data-vertical-style="doublemenu"] .app .side-item.side-item-category {
    display: none !important;
  }

  [data-vertical-style="doublemenu"] .app .side-menu .slide .side-menu__item.active {
    display: block;
    margin: 0 auto !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="doublemenu"] .app .side-menu .slide .side-menu__item {
    display: block;
    margin: 5px 0 !important;
    padding: 8px 0 9px !important;
    border-radius: 7px;
    text-align: center;
  }

  [data-vertical-style="doublemenu"] .app .side-menu .slide .side-menu__item i {
    display: none !important;
  }

  [data-vertical-style="doublemenu"] .app .sub-slide a {
    color: var(--primary05);
    text-decoration: none;
  }

  [data-vertical-style="doublemenu"] .app .slide-item {
    padding-block: 8px;
    padding-inline-start: 35px;
    padding-inline-end: 18px;
  }

  [data-vertical-style="doublemenu"] .app .app-sidebar .slide {
    position: inherit;
    display: block;
  }

  [data-vertical-style="doublemenu"] .app .side-badge {
    display: none;
  }

  [data-vertical-style="doublemenu"] .app .side-header {
    width: 240px;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
    position: fixed;
    z-index: 1;
  }

  [data-vertical-style="doublemenu"] .app .sub-slide-menu .sub-side-menu__item:before {
    content: "\f068";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    inset-inline-start: 25px !important;
    font-size: 9px;
    color: var(--primary05);
    font-weight: 900;
  }

  [data-vertical-style="doublemenu"] .app.sidenav-toggled .main-header {
    padding-inline-start: 80px;
  }

  [data-vertical-style="doublemenu"] .app.sidenav-toggled .app-content {
    margin-inline-start: 80px;
  }

  [data-vertical-style="doublemenu"] .app.sidenav-toggled .app-sidebar__toggle .close-toggle {
    display: block;
  }
}

/* LIB-STYLES */
/* ###### 4.5 Morris ###### */
.morris-hover.morris-default-style {
  border-radius: 0;
}

/* ###### 5.2 Charts  ###### */
.main-donut-chart {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
}

.main-donut-chart .slice {
  position: absolute;
  inset-block-start: 0;
  inset-inline-start: 0;
  width: 100%;
  height: 100%;
}

.main-donut-chart .chart-center {
  position: absolute;
  border-radius: 50%;
}

.main-donut-chart .chart-center span {
  display: block;
  text-align: center;
}

#ecom-chart {
  height: 200px !important;
}

.legend {
  width: 15px;
  height: 10px;
  display: block;
  border-radius: 2px;
  margin-inline-end: 9px;
  margin-block-start: 6px;
}

.list-unstyled .legend-content {
  display: inline-block;
  vertical-align: top;
}

.dot-label {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: ani 1s linear infinite;
  inset-inline-start: 9px;
  inset-block-end: -12px;
  position: relative;
}

#summary-chart {
  height: 204px;
  width: 100%;
}

.summary.chart-legend {
  inset-block-start: 169px;
}

.morris-wrapper-demo {
  height: 200px;
  position: relative;
  margin-inline-start: -15px;
  margin-inline-end: -15px;
}

@media (min-width: 576px) {
  .morris-wrapper-demo {
    height: 300px;
  }
}

.morris-donut-wrapper-demo {
  height: 200px;
}

@media (min-width: 576px) {
  .morris-donut-wrapper-demo {
    height: 250px;
  }
}

.chartjs-wrapper-demo {
  height: 250px;
}

@media (min-width: 992px) {
  .chartjs-wrapper-demo {
    height: 300px;
  }
}

.chartist-wrapper-demo {
  height: 200px;
}

@media (min-width: 768px) {
  .chartist-wrapper-demo {
    height: 300px;
  }
}

.flotChart6 {
  width: 100%;
  height: 180px;
}

.chart-icons {
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.sales-flot {
  position: relative;
}

.sales-flot .flot-chart .flot-x-axis>div span:first-child {
  display: block;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  font-size: 10px;
  color: var(--gray-600);
  inset-block-start: 10px;
  position: relative;
}

.sales-flot .flot-chart .flot-x-axis>div span:last-child {
  display: block;
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  color: var(--gray-900);
  line-height: 1;
  inset-block-start: 15px;
  position: relative;
}

.sales-bar-chart {
  position: relative;
  width: auto;
  height: 160px;
}

.chart-legend {
  margin-block-end: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset-block-start: 5px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  z-index: 10;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.chart-legend div {
  display: flex;
  align-items: center;
}

.chart-legend div+div {
  margin-inline-start: 15px;
}

.chart-legend span {
  width: 10px;
  height: 4px;
  display: block;
  margin-inline-end: 5px;
}

@media (min-width: 576px) {
  .chart-legend div+div {
    margin-inline-start: 30px;
  }
}

@media (min-width: 576px) {
  .chart-legend span {
    width: 40px;
    margin-inline-end: 10px;
  }
}

.chart-wrapper {
  position: relative;
  margin-inline-start: -13px;
  margin-inline-end: -13px;
}

@media (min-width: 576px) {
  .chart-wrapper {
    margin-inline-start: -10px;
    margin-inline-end: -15px;
  }
}

.card-dashboard-twelve .flot-chart {
  width: 100%;
  height: 200px;
  position: relative;
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:first-child {
  display: block;
  text-transform: uppercase;
  font-family: inherit;
  font-weight: 500;
  font-size: 10px;
  color: var(--muted);
}

.card-dashboard-twelve .flot-chart .flot-x-axis>div span:last-child {
  display: block;
  font-size: 16px;
  font-weight: 700;
  font-family: inherit;
  color: #1c273c;
  line-height: 1;
}

@media (min-width: 768px) {
  .card-dashboard-twelve .flot-chart {
    height: 250px;
  }
}

@media (min-width: 992px) {
  .card-dashboard-twelve .flot-chart {
    height: 300px;
  }
}

/* ###### 4.1 Datatables ###### */
table.dataTable {
  border: 1px solid var(--border);
}

table.dataTable thead th,
table.dataTable thead td {
  border-block-start-width: 0;
  border-block-end-width: 0;
  padding: 15px 10px;
  font-weight: 500;
  font-size: 14px;
  color: var(--gray-900);
}

table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_desc {
  background-image: none;
  position: relative;
}

table.dataTable thead .sorting_asc::after,
table.dataTable thead .sorting_desc::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  inset-block-start: 50%;
  inset-inline-end: 10px;
}

table.dataTable thead .sorting_asc::after {
  content: "\f3d8";
}

table.dataTable thead .sorting_desc::after {
  content: "\f3d0";
}

table.dataTable thead .sorting {
  background-image: none;
  position: relative;
}

table.dataTable thead .sorting::after {
  font-family: "Ionicons";
  font-size: 11px;
  position: absolute;
  line-height: 0;
  inset-inline-end: 10px;
}

table.dataTable thead .sorting::after {
  content: "\f3d0" !important;
  inset-block-start: 50%;
  inset-inline-end: 1rem !important;
}

table.dataTable thead .sorting.sorting_asc::after {
  content: "\f3d8" !important;
}

table.dataTable thead .sorting.sorting_desc::after {
  opacity: 0.3;
}

table.dataTable.dtr-inline.collapsed>tbody>tr[role="row"]>td:first-child::before {
  inset-block-start: 9.5px;
  inset-inline-start: 7px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0;
  box-shadow: none;
  background-color: rgb(var(--background-rgb));
  font-size: 14px;
  font-weight: 700;
  color: var(--gray-500);
}

table.dataTable>tbody>tr.child ul.dtr-details {
  display: block;
}

table.dataTable>tbody>tr.child span.dtr-title {
  font-weight: 500;
}

.dataTables_wrapper .selected {
  background: var(--primary005) !important;
}

.dataTables_wrapper .selected td {
  color: var(--muted);
}

.dataTables_wrapper .dataTables_length {
  text-align: start;
}

.dataTables_wrapper .dataTables_length label {
  display: block;
  margin-block-end: 0px !important;
}

.dataTables_wrapper .dataTables_length .select2-container--default {
  margin-inline-end: 5px;
  width: 65px !important;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single {
  height: 32px;
  border-color: var(--form-border);
  border-radius: 3px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 32px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}

.dataTables_wrapper .dataTables_length .select2-container--default .select2-selection--single .select2-selection__arrow b {
  border-block-start-color: var(--gray-500);
}

.dataTables_wrapper .dataTables_filter {
  text-align: start;
}

.dataTables_wrapper .dataTables_filter label {
  display: block;
  margin-block-end: 15px;
}

.dataTables_wrapper .dataTables_filter input {
  margin-inline-start: 0;
  border: 1px solid var(--primary03);
  padding: 16px 10px;
  line-height: 1.539;
  color: var(--color);
  border-radius: 3px;
  width: 100%;
}

.dataTables_wrapper .dataTables_filter input::placeholder {
  color: var(--gray-500);
}

.dataTables_wrapper .dataTables_filter input:focus {
  border: 1px solid var(--primary05);
}

.dataTables_wrapper .dataTables_info {
  margin-block-start: 19px !important;
  padding: 0 !important;
  text-align: start;
}

.dataTables_wrapper .dataTables_paginate {
  margin-block-start: 15px !important;
  padding-block-start: 0;
  margin-block-start: 15px;
  text-align: start;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
  background-color: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 1px;
  transition: all 0.2s ease-in-out;
}

.dataTables_wrapper .dataTables_paginate .paginate_button+.paginate_button {
  margin-inline-start: 0px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
  background-color: transparent;
  color: var(--gray-300) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:focus {
  color: var(--gray-300) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button:focus {
  border: 0;
  background-image: none;
  background-color: transparent;
  color: var(--color) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current {
  border: 0;
  background-image: none;
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb)) !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:focus {
  border: 0;
  background-image: none;
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb)) !important;
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter {
    float: var(--float-end);
    margin-block-start: 0;
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_filter input {
    width: auto;
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_info {
    float: var(--float-start);
  }
}

@media (min-width: 576px) {
  .dataTables_wrapper .dataTables_paginate {
    float: var(--float-end);
  }
}

@media (prefers-reduced-motion: reduce) {
  .dataTables_wrapper .dataTables_paginate .paginate_button {
    transition: none;
  }
}

@media (max-width: 575px) {
  .dataTables_wrapper .dataTables_length .select2-container--default {
    margin-block-end: 15px;
  }
}

.table.dataTable thead .sorting:before {
  display: none;
}

table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc_disabled:before {
  display: none;
}

.dataTables_paginate .pagination .page-link {
  padding: 0.5rem 0.75rem;
  background-color: var(--bg-white);
  border: 1px solid var(--primary03);
  width: auto;
}

.dataTables_paginate .page-item.active .page-link {
  background-color: var(--primary-bg-color);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.focus {
  background: var(--primary-bg-color);
}

.dataTables_wrapper .dataTables_paginate .page-item.disabled .page-link {
  color: var(--color);
  font-size: 12px;
}

.page-item.next a {
  width: 64px;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
  padding-block-start: 10px;
  padding-inline: 18px;
  padding-block-end: 6px;
  border-block-start: 1px solid var(--gray-200);
}

.table-responsive.hoverable-table {
  position: relative;
}

.hoverable-table .btn-primary {
  position: absolute;
  margin-inline-start: 98px;
  inset-block-start: 0px;
  padding: 7px 16px;
  z-index: 99;
}

#basic-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#responsive-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#delete-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus,
#file-datatable .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: 100% !important;
}

#example1 .select2.select2-container.select2-container--default.select2-container--below.select2-container--focus {
  width: auto !important;
}

@media (max-width: 767px) {
  .table-responsive.deleted-table .data-table-btn {
    position: absolute;
    inset-inline-start: 100px;
    z-index: 9;
  }
}

@media screen and (max-width: 500px) {
  .dataTables_paginate .pagination .page-link {
    padding: 0.5rem 0.3rem;
  }
}

/* ###### 3.2 Datepicker ###### */
.ui-datepicker {
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
  font-family: inherit;
  font-size: inherit;
  padding: 10px;
  margin: 1px 0 0;
  display: none;
  width: auto !important;
  z-index: 5 !important;
  border-radius: 5px;
  box-shadow: 0px 4px 7px var(--shadow-color);
}

.ui-datepicker .ui-datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  color: var(--color);
  padding: 0 0 5px;
  letter-spacing: 1px;
  border: 0;
  background-color: transparent;
  border-start-start-radius: 3px;
  border-start-end-radius: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
  text-indent: -99999px;
  color: var(--gray-600);
  inset-block-start: 3px;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
  font-size: 16px;
  font-family: "Ionicons";
  position: absolute;
  inset-block-start: -2px;
  text-indent: 0;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before {
  color: var(--gray-800);
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
  color: var(--gray-800);
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next {
  order: 3;
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next:before {
  inset-inline-end: 5px;
  content: "\f3d1";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-prev:before {
  inset-inline-start: 5px;
  content: "\f3cf";
}

.ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
  color: var(--gray-700);
  cursor: pointer;
  inset-block-start: 1px;
  border: 0;
  background-color: transparent;
}

.ui-datepicker .ui-datepicker-title {
  color: var(--gray-800);
  font-weight: 700;
  font-size: 13px;
}

.ui-datepicker .ui-datepicker-calendar {
  margin: 0;
  background-color: transparent;
  border-end-end-radius: 3px;
  border-end-start-radius: 3px;
}

.ui-datepicker .ui-datepicker-calendar th {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 1px;
  padding: 6px 9px;
  color: var(--gray-800);
}

/* ###### 4.12 Datetimepicker ###### */
/* Amaze UI Datetimepicker */
.datetimepicker {
  box-shadow: none;
  border-radius: 0;
  border-width: 1px;
  border-color: var(--border);
  min-width: 270px;
  padding: 0 10px 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
}

.datetimepicker::before {
  display: none;
}

.datetimepicker table {
  width: 100%;
}

.datetimepicker table th.prev,
.datetimepicker table th.next,
.datetimepicker table th.switch {
  background-color: rgb(var(--background-rgb));
  color: var(--color);
}

.datetimepicker table th.prev,
.datetimepicker table th.next {
  position: relative;
}

.datetimepicker table th.prev span,
.datetimepicker table th.next span {
  position: absolute;
  inset-block-start: 0;
  inset-inline-end: 0;
  inset-block-end: 0;
  inset-inline-start: 0;
}

.datetimepicker table th.prev span::before,
.datetimepicker table th.next span::before {
  font-family: "Ionicons";
  position: absolute;
  inset-block-start: 50%;
  font-size: 16px;
  color: var(--muted);
  line-height: 0;
}

.datetimepicker table th.prev:hover,
.datetimepicker table th.prev:focus {
  background-color: rgb(var(--background-rgb));
}

.datetimepicker table th.next:hover,
.datetimepicker table th.next:focus {
  background-color: rgb(var(--background-rgb));
}

.datetimepicker table th.prev:hover span::before,
.datetimepicker table th.prev:focus span::before {
  color: var(--primary-bg-color);
}

.datetimepicker table th.next:hover span::before,
.datetimepicker table th.next:focus span::before {
  color: var(--primary-bg-color);
}

.datetimepicker table th.prev span::before {
  content: "\f3cf";
  inset-inline-start: 0;
}

.datetimepicker table th.next span::before {
  content: "\f3d1";
  inset-inline-end: 0;
}

.datetimepicker table th.switch {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 1px;
  color: var(--gray-900);
}

.datetimepicker table th.switch:hover,
.datetimepicker table th.switch:focus {
  background-color: rgb(var(--background-rgb));
  color: var(--primary-bg-color);
}

.datetimepicker table td {
  font-size: 0.875rem;
  text-align: center;
}

.datetimepicker table td.old,
.datetimepicker table td.new {
  color: var(--gray-500);
}

.datetimepicker table td.active {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.datetimepicker table td.active:hover,
.datetimepicker table td.active:focus {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.datetimepicker table span.active {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.datetimepicker table span.active:hover,
.datetimepicker table span.active:focus {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.datetimepicker .datetimepicker-days table thead tr:first-child th {
  height: 38px;
}

.datetimepicker .datetimepicker-days table thead tr:last-child th {
  height: 25px;
  text-transform: uppercase;
  color: var(--gray-700);
  font-weight: 700;
  font-size: 11px;
}

/* jQuery Simple Datetimepicker */
.main-datetimepicker {
  box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
  border-radius: 4px;
  border-color: var(--border);
  margin-block-start: 0px;
}

.main-datetimepicker>.datepicker_header {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-datetimepicker>.datepicker_header>.icon-home {
  display: none;
}

.main-datetimepicker>.datepicker_header a:nth-child(2),
.main-datetimepicker>.datepicker_header a:last-child {
  display: block;
  position: relative;
  text-indent: -999999px;
}

.main-datetimepicker>.datepicker_header a:nth-child(2)::before,
.main-datetimepicker>.datepicker_header a:last-child::before {
  font-family: "Ionicons";
  position: absolute;
  inset-block-start: 50%;
  line-height: 0;
  text-indent: 0;
  font-size: 13px;
}

.main-datetimepicker>.datepicker_header a:nth-child(2)::before {
  content: "\f3cf";
  inset-inline-start: 0;
}

.main-datetimepicker>.datepicker_header a:last-child::before {
  content: "\f3d1";
  inset-inline-end: 0;
}

.main-datetimepicker>.datepicker_header span {
  font-family: inherit;
  text-transform: uppercase;
}

.main-datetimepicker>.datepicker_inner_container {
  border-radius: 0;
  box-shadow: none;
  border-width: 0;
  display: flex;
  height: 225px;
  margin: 0;
  background-color: rgb(var(--background-rgb));
}

.main-datetimepicker>.datepicker_inner_container::after {
  display: none;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar {
  width: 230px;
  border-width: 0;
  border-radius: 0;
  font-family: inherit;
  padding: 5px;
  float: none;
  margin: 0;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar>table {
  width: 100%;
  height: 100%;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar th {
  font-size: 11px;
  text-transform: uppercase;
  color: var(--gray-900);
  font-weight: 700;
  text-align: center;
  padding: 8px 5px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td {
  font-size: 0.875rem;
  font-weight: 400;
  text-align: center;
  padding: 5px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.day_another_month {
  color: var(--gray-500);
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.hover {
  background-color: var(--gray-200);
  cursor: pointer;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_calendar td.active {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
  border-radius: 2px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist {
  float: none;
  width: 70px;
  height: 100% !important;
  border-inline-start: 1px solid var(--border);
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item {
  padding: 5px 8px;
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 1px;
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:hover,
.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item:focus {
  background-color: var(--bg-hover);
}

.main-datetimepicker>.datepicker_inner_container>.datepicker_timelist>div.timelist_item.active {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

/* Picker JS */
.picker-title {
  font-size: 15px;
  font-weight: 500;
}

.picker-cell__header {
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.4px;
}

.picker-item {
  font-family: inherit;
}

.picker-picked {
  font-weight: 500;
  color: var(--primary-bg-color);
}

/* ###### 4.3 jQuery Steps ###### */
/***** DEFAULT STYLE WIZARD *****/
.wizard {
  border: 1px solid var(--border);
  background-color: rgb(var(--background-rgb));
}

.wizard>.steps {
  padding: 20px;
}

.wizard>.steps>ul {
  padding: 0;
  margin-block-end: 0;
  display: flex;
}

.wizard>.steps>ul li {
  float: none;
  display: block;
  width: auto;
}

.wizard>.steps>ul li .current-info {
  display: none;
}

.wizard>.steps>ul li .title {
  margin-inline-start: 5px;
  white-space: nowrap;
  transition: all 0.2s ease-in-out;
}

.wizard>.steps>ul li+li {
  margin-inline-start: 5px;
}

.wizard>.steps a {
  color: var(--gray-900);
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wizard>.steps a:hover,
.wizard>.steps a:active {
  color: var(--gray-900);
  font-weight: 500;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wizard>.steps a .number,
.wizard>.steps a:hover .number,
.wizard>.steps a:active .number {
  flex-shrink: 0;
  font-weight: 700;
  color: var(--primary-bg-color);
  display: block;
  text-align: center;
  line-height: 2;
  width: 30px;
  height: 30px;
  background-color: var(--primary02);
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.wizard>.steps .disabled {
  display: none;
}

.wizard>.steps .disabled a {
  color: var(--gray-500);
}

.wizard>.steps .disabled a:hover,
.wizard>.steps .disabled a:active {
  color: var(--gray-500);
}

.wizard>.steps .current a {
  color: var(--primary-bg-color);
}

.wizard>.steps .current a:hover,
.wizard>.steps .current a:active {
  color: var(--primary-bg-color);
}

.wizard>.steps .current a .title,
.wizard>.steps .current a:hover .title,
.wizard>.steps .current a:active .title {
  display: inline-block;
}

.wizard>.steps .current a .number,
.wizard>.steps .current a:hover .number,
.wizard>.steps .current a:active .number {
  background-color: var(--primary-bg-color) !important;
  color: rgb(var(--background-rgb)) !important;
}

.wizard>.steps .done a {
  color: var(--secondary);
}

.wizard>.steps .done a:hover,
.wizard>.steps .done a:active {
  color: var(--secondary);
}

.wizard>.steps .done a .title,
.wizard>.steps .done a:hover .title,
.wizard>.steps .done a:active .title {
  display: none;
}

.wizard>.steps .done a .number,
.wizard>.steps .done a:hover .number,
.wizard>.steps .done a:active .number {
  background-color: var(--secondary) !important;
  color: rgb(var(--background-rgb)) !important;
}

.wizard>.content {
  border-block-start: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
  min-height: 150px;
  padding: 20px;
}

.wizard>.content>.title {
  font-size: 18px;
  color: var(--gray-900);
  font-weight: 700;
  margin-block-end: 5px;
  display: none;
}

.wizard>.content>.title.current {
  display: block;
}

.wizard>.content>.body {
  float: none;
  position: static;
  width: auto;
  height: auto;
}

.wizard>.content>.body input.parsley-error {
  border-color: #d6573c;
}

.wizard>.content>.body input.parsley-error+ul {
  list-style: none !important;
}

.wizard>.actions {
  padding: 20px;
}

.wizard>.actions>ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.wizard>.actions>ul>li:last-child a {
  background-color: var(--secondary);
}

.wizard>.actions a {
  display: block;
  background-color: var(--primary-bg-color);
  padding: 9px 25px;
  line-height: 1.573;
  color: #000;
  border-radius: 3px;
  margin-inline-end: 10px;
}

.wizard>.actions a:hover,
.wizard>.actions a:active {
  display: block;
  background-color: var(--primary-bg-color);
  padding: 9px 25px;
  line-height: 1.573;
  color: rgb(var(--background-rgb));
}

.wizard>.actions .disabled a {
  background-color: var(--gray-500);
  border-radius: 3px;
}

.wizard>.actions .disabled a:hover,
.wizard>.actions .disabled a:active {
  background-color: var(--gray-500);
}

.wizard.vertical>.steps {
  padding: 20px;
}

.wizard.vertical>.steps ul li+li {
  margin-inline-start: 10px;
}

.wizard.vertical>.steps ul li .title {
  display: none;
}

.wizard.vertical>.steps .current a .title {
  display: inline-block;
}

.wizard.vertical>.content {
  margin: 0;
  padding: 20px;
}

.wizard.vertical>.actions {
  padding: 20px;
}

.wizard.vertical>.actions ul {
  float: none;
  margin: 0;
  padding: 0;
}

@media (min-width: 768px) {
  .wizard>.steps {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard>.steps {
    padding: 30px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .wizard>.steps>ul li .title {
    transition: none;
  }
}

@media (min-width: 576px) {
  .wizard>.steps>ul li .title {
    display: none;
    margin-inline-start: 10px;
  }
}

@media (min-width: 768px) {
  .wizard>.steps>ul li .title {
    display: none;
  }
}

@media (min-width: 576px) {
  .wizard>.steps>ul li+li {
    margin-inline-start: 20px;
  }
}

@media (min-width: 992px) {
  .wizard>.steps>ul li+li {
    margin-inline-start: 30px;
  }
}

@media (min-width: 1200px) {
  .wizard>.steps a {
    justify-content: flex-start;
  }

  .wizard>.steps a:hover,
  .wizard>.steps a:active {
    justify-content: flex-start;
  }
}

@media (prefers-reduced-motion: reduce) {

  .wizard>.steps a .number,
  .wizard>.steps a:hover .number,
  .wizard>.steps a:active .number {
    transition: none;
  }
}

@media (min-width: 576px) {

  .wizard>.steps a .number,
  .wizard>.steps a:hover .number,
  .wizard>.steps a:active .number {
    font-size: 18px;
    font-weight: 600;
    line-height: 2.1;
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 576px) {
  .wizard>.steps .disabled {
    display: block;
  }
}

@media (min-width: 768px) {

  .wizard>.steps .done a .title,
  .wizard>.steps .done a:hover .title,
  .wizard>.steps .done a:active .title {
    display: inline-block;
  }
}

@media (min-width: 768px) {
  .wizard>.content {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard>.content {
    padding: 30px;
  }
}

@media (min-width: 768px) {
  .wizard>.actions {
    padding: 25px;
  }
}

@media (min-width: 992px) {
  .wizard>.actions {
    padding: 30px;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.steps {
    float: var(--float-start);
    width: 20%;
  }
}

@media (min-width: 768px) {
  .wizard.vertical>.steps {
    width: 15%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.steps {
    padding: 25px;
    width: 30%;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.steps ul {
    flex-direction: column;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.steps ul li+li {
    margin-block-start: 10px;
    margin-inline-start: 0;
  }
}

@media (min-width: 768px) {
  .wizard.vertical>.steps ul li+li {
    margin-block-start: 20px;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.steps ul li .title {
    display: block;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.steps a {
    justify-content: flex-start;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.steps .current a .title {
    display: none;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.steps .current a .title {
    display: inline-block;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.content {
    border-block-start-width: 0;
    border-block-end-width: 0;
    width: 80%;
    float: var(--float-end);
    border-inline-start: 1px solid var(--border);
  }
}

@media (min-width: 768px) {
  .wizard.vertical>.content {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.content {
    width: 70%;
    padding: 25px;
  }
}

@media (min-width: 576px) {
  .wizard.vertical>.actions {
    width: 80%;
    float: var(--float-end);
    border-inline-start: 1px solid var(--border);
  }
}

@media (min-width: 768px) {
  .wizard.vertical>.actions {
    width: 85%;
  }
}

@media (min-width: 992px) {
  .wizard.vertical>.actions {
    width: 70%;
    padding: 25px;
  }
}

/****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
.step-equal-width>.steps>ul {
  display: flex;
  display: flex;
}

.step-equal-width>.steps>ul>li {
  flex: 1;
  width: auto;
  float: none;
  flex: 1;
  width: auto;
  float: none;
}

/****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
/***** CUSTOM STYLES *****/
.wizard-style-1>.steps>ul a {
  padding: 0;
  height: 50px;
}

.wizard-style-1>.steps>ul a:hover,
.wizard-style-1>.steps>ul a:active {
  padding: 0;
  height: 50px;
}

.wizard-style-1>.steps>ul a .number,
.wizard-style-1>.steps>ul a:hover .number,
.wizard-style-1>.steps>ul a:active .number {
  width: 50px;
  height: 100%;
  border: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--color);
  background-color: rgb(var(--background-rgb));
  border-radius: 0;
}

.wizard-style-1>.steps>ul a .title,
.wizard-style-1>.steps>ul a:hover .title,
.wizard-style-1>.steps>ul a:active .title {
  margin-inline-end: 20px;
  margin-inline-start: 20px;
}

.wizard-style-1>.steps>ul .current a .number,
.wizard-style-1>.steps>ul .current a:hover .number,
.wizard-style-1>.steps>ul .current a:active .number {
  background-color: #452efa;
  color: rgb(var(--background-rgb));
}

.wizard-style-1>.steps>ul .done a .number,
.wizard-style-1>.steps>ul .done a:hover .number,
.wizard-style-1>.steps>ul .done a:active .number {
  background-color: #643ab0;
  color: rgb(var(--background-rgb));
}

.wizard-style-2>.steps>ul a {
  padding: 0;
  height: 50px;
  border-radius: 50px;
}

.wizard-style-2>.steps>ul a:hover,
.wizard-style-2>.steps>ul a:active {
  padding: 0;
  height: 50px;
  border-radius: 50px;
}

.wizard-style-2>.steps>ul a .number,
.wizard-style-2>.steps>ul a:hover .number,
.wizard-style-2>.steps>ul a:active .number {
  width: 50px;
  height: 100%;
  border: 2px solid var(--border);
  font-size: 18px;
  font-weight: bold;
  color: var(--color);
  background-color: rgb(var(--background-rgb));
}

.wizard-style-2>.steps>ul a .title,
.wizard-style-2>.steps>ul a:hover .title,
.wizard-style-2>.steps>ul a:active .title {
  margin-inline-end: 20px;
}

.wizard-style-2>.steps>ul .current a .number,
.wizard-style-2>.steps>ul .current a:hover .number,
.wizard-style-2>.steps>ul .current a:active .number {
  border-color: var(--primary-bg-color);
  color: var(--primary-bg-color);
}

.wizard-style-2>.steps>ul .done a .number,
.wizard-style-2>.steps>ul .done a:hover .number,
.wizard-style-2>.steps>ul .done a:active .number {
  border-color: #7891ef;
  color: #7891ef;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .wizard.vertical>.steps .current a .title {
    display: none;
  }

  .wizard>.steps>ul li .title {
    display: none;
  }

  .wizard.vertical>.steps ul li .title {
    display: none;
  }
}

/* ###### 4.4 jQVMap ###### */
.jqvmap-label {
  padding: 2px 8px;
  background-color: var(--black-9);
  border-radius: 2px;
}

.jqvmap-zoomin {
  font-size: 20px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.jqvmap-zoomout {
  font-size: 20px;
  padding: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  inset-block-start: 32px;
}

/* Setup basic CSS for Label */
.jqvmap-pin {
  font-family: inherit;
  cursor: default;
  pointer-events: none;
}

/* Hide Whichever Labels you want */
#jqvmap1_ri_pin,
#jqvmap1_dc_pin,
#jqvmap1_de_pin,
#jqvmap1_md_pin {
  display: none;
}

/* Reposition Labels that are not quite right ( labels are centered in shape, and sometimes need tweaking ) */
#jqvmap1_ak_pin {
  margin-block-start: -2%;
}

#jqvmap1_ca_pin {
  margin-inline-start: -2%;
}

#jqvmap1_ct_pin {
  margin-block-start: -0.25%;
  margin-inline-start: -0.25%;
}

#jqvmap1_fl_pin {
  margin-inline-start: 5%;
}

#jqvmap1_id_pin {
  margin-block-start: 3%;
  margin-inline-start: -1%;
}

#jqvmap1_ky_pin {
  margin-inline-start: 2%;
}

#jqvmap1_la_pin {
  margin-inline-start: -2%;
}

#jqvmap1_mi_pin {
  margin-block-start: 4%;
  margin-inline-start: 3%;
}

#jqvmap1_ma_pin {
  margin-block-start: -0.25%;
}

#jqvmap1_mn_pin {
  margin-block-start: 2%;
  margin-inline-start: -2%;
}

#jqvmap1_nh_pin {
  margin-block-start: 1%;
  margin-inline-start: -0.25%;
}

#jqvmap1_nj_pin {
  margin-block-start: 1%;
}

#jqvmap1_ok_pin,
#jqvmap1_va_pin {
  margin-inline-start: 2%;
}

#jqvmap1_wv_pin {
  margin-inline-start: -1%;
  margin-block-start: 1%;
}

/* Add responsibe support to resize labels for difference screen sizes */
@media only screen and (min-width: 320px) {
  .jqvmap-pin {
    font-size: 6px;
  }
}

@media only screen and (min-width: 480px) {
  .jqvmap-pin {
    font-size: 8px;
  }
}

@media only screen and (min-width: 640px) {
  .jqvmap-pin {
    font-size: 10px;
  }
}

@media only screen and (min-width: 800px) {
  .jqvmap-pin {
    font-size: 12px;
  }
}

@media only screen and (min-width: 1024px) {
  .jqvmap-pin {
    font-size: 14px;
  }
}

.jqstooltip {
  width: 20px;
}

/* ###### 4.7 Quill ###### */
.ql-wrapper {
  display: flex;
  flex-direction: column;
}

.ql-wrapper-modal .ql-snow.ql-toolbar {
  border-width: 0;
  border-block-end-width: 1px;
}

.ql-wrapper-modal .ql-snow.ql-container {
  border-width: 0;
}

.ql-wrapper-modal .ql-snow .ql-editor {
  padding: 20px;
}

@media (min-width: 576px) {
  .ql-wrapper-modal .ql-snow.ql-toolbar {
    padding: 15px 20px;
  }
}

.ql-bubble.ql-container {
  border: 1px solid var(--border);
}

.ql-bubble.ql-container:not(.ql-disabled) a::before {
  background-color: var(--gray-900);
  border-radius: 2px;
}

.ql-bubble .ql-tooltip {
  background-color: var(--gray-900);
  border-radius: 2px;
}

.ql-bubble .ql-tooltip.ql-flip .ql-tooltip-arrow {
  border-block-start-color: var(--border);
}

.ql-bubble .ql-tooltip:not(.ql-flip) .ql-tooltip-arrow {
  border-block-end-color: var(--border);
}

.ql-bubble .ql-toolbar {
  padding: 0 8px 8px;
}

.ql-bubble .ql-toolbar button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color2);
  outline: none;
}

.ql-bubble .ql-toolbar button i {
  font-size: 24px;
  line-height: 0;
  display: inline-block;
}

.ql-bubble .ql-toolbar button i.la-bold,
.ql-bubble .ql-toolbar button i.la-underline {
  width: 21px;
}

.ql-bubble .ql-toolbar button .ql-fill {
  fill: var(--color2);
}

.ql-bubble .ql-toolbar button .ql-stroke {
  stroke: var(--color2);
}

.ql-bubble .ql-toolbar button.ql-active {
  color: rgb(var(--background-rgb));
  background-color: var(--white-08);
}

.ql-bubble .ql-toolbar .ql-formats {
  margin-block-start: 8px;
  margin-inline-end: 8px;
  margin-block-end: 0;
  margin-inline-start: 0;
}

.ql-bubble .ql-toolbar .ql-formats:first-child {
  margin-inline-start: 0;
}

@media (min-width: 576px) {
  .ql-bubble .ql-toolbar .ql-formats {
    margin: 8px 0 0;
  }

  .ql-bubble .ql-toolbar .ql-formats:first-child {
    margin-inline-start: 0;
  }
}

.ql-snow.ql-container {
  border-color: var(--border);
  border-width: 1px;
}

.ql-snow.ql-toolbar {
  border-color: var(--border);
  border-width: 1px;
  border-block-end-width: 1px;
  padding: 5px 10px 10px !important;
  background: transparent;
}

.ql-snow.ql-toolbar .ql-picker-label {
  border-color: var(--border);
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active {
  background-color: rgb(var(--background-rgb));
  border-color: transparent;
  color: var(--color2);
  font-weight: 500;
}

.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke {
  stroke: var(--color);
}

.ql-snow.ql-toolbar button {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--muted);
  outline: none;
}

.ql-snow.ql-toolbar button svg {
  width: 20px;
  float: none;
}

.ql-snow.ql-toolbar button i {
  font-size: 24px;
  line-height: 0;
  display: inline-block;
}

.ql-snow.ql-toolbar button i.la-bold,
.ql-snow.ql-toolbar button i.la-underline {
  width: 21px;
}

.ql-snow.ql-toolbar button .ql-stroke {
  stroke-width: 2px;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow.ql-toolbar button.ql-active {
  color: var(--primary-bg-color);
  background-color: var(--bg-hover);
}

.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar button:focus .ql-stroke {
  stroke: var(--gray-600);
}

.ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: var(--gray-800);
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke {
  stroke: var(--gray-800);
}

.ql-snow.ql-toolbar .ql-formats {
  margin-block-start: 5px;
  margin-inline-end: 5px;
}

.ql-snow .ql-formats button+button {
  margin-block-start: 0;
  margin-inline-end: 0;
  margin-block-end: 0;
  margin-inline-start: 2px;
}

.ql-snow .ql-picker {
  display: block;
  border-width: 0;
  height: 32px;
  color: var(--gray-700);
}

.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  inset-inline-end: 3px;
}

.ql-snow .ql-picker:hover .ql-picker-label,
.ql-snow .ql-picker:focus .ql-picker-label {
  color: var(--gray-900);
}

.ql-snow .ql-picker:hover .ql-picker-label .ql-stroke,
.ql-snow .ql-picker:focus .ql-picker-label .ql-stroke {
  stroke: var(--color);
}

.ql-snow .ql-picker.ql-header {
  width: 120px;
}

.ql-snow .ql-picker-label {
  display: block;
  padding-inline-start: 10px;
  font-size: 13px;
  font-family: inherit;
  border: 1px solid var(--border);
  background-color: rgb(var(--background-rgb));
  outline: none;
}

.ql-snow .ql-picker-label::before {
  line-height: 2.25;
  font-weight: 400;
}

.ql-snow .ql-stroke {
  stroke: var(--color);
  stroke-linecap: square;
  stroke-linejoin: miter;
  stroke-width: 1;
}

.ql-snow .ql-editor {
  padding: 20px;
  color: var(--gray-800);
}

@media (min-width: 576px) {
  .ql-snow.ql-toolbar {
    padding: 15px;
  }
}

.ql-wrapper-demo {
  background-color: rgb(var(--background-rgb));
}

.ql-wrapper-demo .ql-container {
  height: 250px;
}

.ql-wrapper-demo .ql-editor {
  height: 100%;
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .ql-wrapper-demo {
    width: 100%;
  }
}

.ql-scrolling-demo {
  background-color: rgb(var(--background-rgb));
  height: 100%;
  min-height: 100%;
  overflow: hidden;
  border: 1px solid var(--gray-200);
  position: relative;
}

.ql-scrolling-demo .ql-container {
  border-width: 0;
  height: auto;
  min-height: 100%;
  padding: 30px;
}

.ql-scrolling-demo .ql-container .ql-editor {
  font-size: 16px;
  overflow-y: visible;
  color: var(--gray-800);
}

@media (min-width: 768px) {
  .ql-scrolling-demo {
    padding-inline-start: 0;
  }
}

@media (min-width: 768px) {
  .ql-scrolling-demo .ql-container {
    padding: 1rem;
  }
}

.jqvmap-zoomin,
.jqvmap-zoomout {
  background: var(--bg-hover);
}

.ql-toolbar.ql-snow {
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;
}

.ql-container {
  border-end-start-radius: 5px;
  border-end-end-radius: 5px;
  font-family: inherit;
}

/* ###### 4.6 Parsley ###### */
input.parsley-error:focus,
textarea.parsley-error:focus,
input.parsley-success:focus,
textarea.parsley-success:focus {
  box-shadow: none !important;
}

.parsley-checkbox.parsley-error {
  display: inline-block;
  padding: 10px;
  border: 1px solid #d6573c;
  border-radius: 0px;
}

.parsley-checkbox.parsley-success {
  display: inline-block;
  padding: 10px;
  border: 1px solid #43ce85;
  border-radius: 4px;
}

.parsley-errors-list {
  color: #d6573c;
  list-style: none;
  font-size: 12px;
  padding: 0;
  margin-block-end: 0;
}

.parsley-errors-list li {
  margin: 5px 0 0;
}

/***** CUSTOM STYLED ERROR MESSAGE *****/
.parsley-style-1 .parsley-input.parsley-error .form-control {
  background-color: #fef9f9;
  border-color: #d6573c;
  border-block-end-width: 0;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.parsley-style-1 .parsley-input.parsley-success .form-control {
  border-color: #43ce85;
}

.parsley-style-1 .parsley-checkbox.parsley-error {
  border: 1px solid #d6573c;
  background-color: #fef9f9;
  border-start-start-radius: 5px;
  border-start-end-radius: 5px;
}

.parsley-style-1 .parsley-select.parsley-error .select2-container--default .select2-selection--single {
  background-color: #fef9f9;
  border-end-end-radius: 0;
  border-end-start-radius: 0;
}

.parsley-style-1 .parsley-errors-list.filled {
  background-color: #d6573c;
  color: rgb(var(--background-rgb));
  padding: 7px 10px;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
}

.parsley-style-1 .parsley-errors-list.filled li:first-child {
  margin-block-start: 0;
}

/* ###### 4.8 RangeSlider ###### */
.irs-min,
.irs-max {
  font-family: "Roboto", sans-serif;
  border-radius: 0;
}

.irs-from,
.irs-to,
.irs-single {
  font-family: "Roboto", sans-serif;
  background-color: var(--primary-bg-color);
  border-radius: 0;
}

.irs-bar {
  background-image: none;
  background-color: var(--primary-bg-color);
}

.irs-bar-edge {
  background-image: none;
  background-color: var(--primary-bg-color);
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.irs-line-left {
  background-image: none;
  background-color: rgb(var(--background-rgb));
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.irs-line-mid {
  background-image: none;
  background-color: rgb(var(--background-rgb));
}

.irs-line-right {
  background-image: none;
  background-color: rgb(var(--background-rgb));
  border-start-end-radius: 0;
  border-end-end-radius: 0;
}

.irs-from::after,
.irs-to::after,
.irs-single::after {
  border-block-start-color: var(--primary-bg-color);
}

.irs-slider {
  background-image: none;
}

.irs-slider:before {
  content: "";
  width: 2px;
  height: 18px;
  position: absolute;
  background-color: var(--primary-bg-color);
  inset-inline-start: 50%;
  margin-inline-start: -1px;
}

.irs-slider:hover:before,
.irs-slider:focus:before {
  background-color: var(--primary-bg-hover);
}

/* #################### COLOR VARIANT SKINS #################### */
.irs-primary .irs-bar,
.irs-primary .irs-bar-edge,
.irs-primary .irs-slider::before,
.irs-primary .irs-from,
.irs-primary .irs-to,
.irs-primary .irs-single {
  background-color: var(--primary-bg-color);
}

.irs-primary .irs-from::after,
.irs-primary .irs-to::after,
.irs-primary .irs-single::after {
  border-block-start-color: var(--primary-bg-color);
}

.irs-success .irs-bar,
.irs-success .irs-bar-edge,
.irs-success .irs-slider::before,
.irs-success .irs-from,
.irs-success .irs-to,
.irs-success .irs-single {
  background-color: #43ce85;
}

.irs-success .irs-from::after,
.irs-success .irs-to::after,
.irs-success .irs-single::after {
  border-block-start-color: #43ce85;
}

.irs-dark .irs-bar,
.irs-dark .irs-bar-edge,
.irs-dark .irs-slider::before,
.irs-dark .irs-from,
.irs-dark .irs-to,
.irs-dark .irs-single {
  background-color: var(--gray-800);
}

.irs-dark .irs-from::after,
.irs-dark .irs-to::after,
.irs-dark .irs-single::after {
  border-block-start-color: var(--gray-800);
}

/* #################### MODERN SKIN #################### */
.irs-modern .irs-line-left,
.irs-modern .irs-line-mid,
.irs-modern .irs-line-right {
  height: 6px;
  box-shadow: inset -2px 2px 2px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-bar,
.irs-modern .irs-bar-edge {
  height: 6px;
  box-shadow: inset 0 1px 1px 0 rgba(34, 34, 34, 0.1);
}

.irs-modern .irs-slider {
  inset-block-start: 18px;
  background-color: rgb(var(--background-rgb));
  border: 2px solid var(--primary-bg-color);
  border-radius: 4px;
}

.irs-modern .irs-slider::before {
  width: 4px;
  height: 9px;
  background-color: rgb(var(--background-rgb));
  border-inline-start: 1px solid var(--primary-bg-color);
  border-inline-end: 1px solid var(--primary-bg-color);
  margin-inline-start: -2px;
  inset-block-start: 3px;
}

.irs-modern.irs-primary .irs-slider {
  border-color: var(--primary-bg-color);
}

.irs-modern.irs-primary .irs-slider::before {
  border-color: var(--primary-bg-color);
}

.irs-modern.irs-success .irs-slider {
  border-color: #43ce85;
}

.irs-modern.irs-success .irs-slider::before {
  border-color: #43ce85;
}

.irs-modern.irs-dark .irs-slider {
  border-color: var(--gray-900);
}

.irs-modern.irs-dark .irs-slider::before {
  border-color: var(--gray-900);
}

/* #################### OUTLINE SKIN #################### */
.irs-outline .irs-line {
  border: 1px solid var(--primary-bg-color);
  height: 10px;
  border-radius: 4px;
}

.irs-outline .irs-line-left,
.irs-outline .irs-line-mid,
.irs-outline .irs-line-right {
  height: 10px;
  background-color: transparent;
}

.irs-outline .irs-bar {
  height: 10px;
}

.irs-outline .irs-bar-edge {
  height: 10px;
  border-start-start-radius: 5px;
  border-end-start-radius: 5px;
}

.irs-outline .irs-slider {
  inset-block-start: 21px;
  width: 18px;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--primary-bg-color);
  border-radius: 100%;
}

.irs-outline .irs-slider::before {
  margin-inline-start: -4px;
  inset-block-start: 4px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.irs-outline .irs-slider:hover::before,
.irs-outline .irs-slider:focus::before {
  background-color: var(--primary-bg-hover);
}

.irs-outline.irs-primary .irs-line,
.irs-outline.irs-primary .irs-slider {
  border-color: var(--primary-bg-color);
}

.irs-outline.irs-primary .irs-line:hover::before,
.irs-outline.irs-primary .irs-line:focus::before {
  background-color: #1a53ff;
}

.irs-outline.irs-primary .irs-slider:hover::before,
.irs-outline.irs-primary .irs-slider:focus::before {
  background-color: #1a53ff;
}

.irs-outline.irs-success .irs-line,
.irs-outline.irs-success .irs-slider {
  border-color: #43ce85;
}

.irs-outline.irs-success .irs-line:hover::before,
.irs-outline.irs-success .irs-line:focus::before {
  background-color: #339701;
}

.irs-outline.irs-success .irs-slider:hover::before,
.irs-outline.irs-success .irs-slider:focus::before {
  background-color: #339701;
}

.irs-outline.irs-dark .irs-line,
.irs-outline.irs-dark .irs-slider {
  border-color: var(--gray-800);
}

.irs-outline.irs-dark .irs-line:hover::before,
.irs-outline.irs-dark .irs-line:focus::before {
  background-color: #313c53;
}

.irs-outline.irs-dark .irs-slider:hover::before,
.irs-outline.irs-dark .irs-slider:focus::before {
  background-color: #313c53;
}

/* ###### 4.10 Select2 ###### */
.select2-results__option {
  border-radius: 0;
  margin: 4px;
  font-size: 13px;
}

.select2-container--default .select2-selection--single {
  background-color: rgb(var(--background-rgb));
  border-color: var(--border) !important;
  border-radius: 4px;
  outline: none;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--gray-700);
  line-height: 1.5 !important;
  padding: 0.375rem 0.75rem !important;
  height: 100%;
  display: flex;
  align-items: center;
}

.select2-container--default .select2-selection--single .select2-selection__placeholder {
  color: var(--gray-600);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  width: 30px;
  height: 38px;
  line-height: calc(1.5em + 0.75rem + 2px);
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  margin-block-start: -3px;
}

.select2-container--default .select2-selection--multiple {
  background-color: rgb(var(--background-rgb));
  border-color: var(--border);
  border-radius: 4px;
  min-height: 38px;
  outline: none;
}

.select2-container--default .select2-selection--multiple .select2-selection__rendered {
  padding: 0 4px;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
  position: relative;
  margin-block-start: 5px;
  margin-inline-end: 4px;
  padding-block: 3px;
  padding-inline-start: 20px;
  padding-inline-end: 10px;
  border-color: transparent;
  border-radius: 3px;
  background-color: var(--primary-bg-color);
  color: #000;
  line-height: 1.45;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
  color: rgb(var(--background-rgb));
  opacity: 0.5;
  font-size: 18px;
  display: inline-block;
  position: absolute;
  inset-block-start: 0px;
  inset-inline-start: 6px;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
  border-color: var(--border);
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: var(--border);
  border-radius: 0;
  outline: none;
}

.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: var(--primary-bg-color) !important;
  color: #fff !important;
}

.select2-container--default .select2-results__option[aria-selected="true"]:hover,
.select2-container--default .select2-results__option[aria-selected="true"]:focus {
  opacity: 0.8;
}

.select2-container--default .select2-results__option--highlighted[aria-selected="false"] {
  background-color: var(--black-03) !important;
  color: var(--primary-bg-color) !important;
}

.select2-container--default .select2-search--inline .select2-search__field {
  margin-block-start: 7px;
  line-height: 26px;
  padding-inline-start: 7px;
  opacity: 1;
}

.select2-container--default.select2-container--disabled .select2-selection__choice {
  padding-inline-start: 10px;
  background-color: var(--gray-500);
}

.select2-container--default.select2-container--disabled .select2-selection__choice .select2-selection__choice__remove {
  display: none;
}

.select2-container--open .select2-selection--single,
.select2-container--open .select2-selection--multiple {
  background-color: rgb(var(--background-rgb));
  border-color: var(--border);
}

.select2-container--open .select2-dropdown--above {
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.select2-container--open .select2-dropdown--below {
  border-end-end-radius: 0;
  border-end-start-radius: 0;
  inset-block-start: 0;
}

.select2-dropdown {
  border-color: var(--border) !important;
  z-index: 9999 !important;
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
  border-end-end-radius: 5px !important;
  border-end-start-radius: 5px !important;
  box-shadow: 0px 4px 7px rgba(152, 154, 155, 0.1) !important;
}

.select2-search--dropdown {
  padding-block-end: 0;
}

.select2-results__option {
  padding: 6px 10px !important;
}

.has-success .select2-container--default .select2-selection--single,
.parsley-select.parsley-success .select2-container--default .select2-selection--single {
  border-color: #43ce85;
}

.has-warning .select2-container--default .select2-selection--single {
  border-color: #ffcf86;
}

.has-danger .select2-container--default .select2-selection--single,
.parsley-select.parsley-error .select2-container--default .select2-selection--single {
  border-color: #d6573c;
}

.select2-xs+.select2-container,
.select2-dropdown-xs .select2-results__option {
  font-size: 12px;
}

.select2-sm+.select2-container,
.select2-dropdown-sm .select2-results__option {
  font-size: 14px;
}

.select2-bd-0+.select2-container--default .select2-selection--single {
  border-width: 0;
}

.select2-search--dropdown {
  display: block;
}

.select2-sm .select2-container--default .select2-selection--single {
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: calc(1.5em + 0.5rem + 2px) !important;
}

.select2-lg .select2-container .select2-selection--single .select2-selection__rendered {
  padding-inline-start: 4px !important;
}

.select2-lg .select2-container--default .select2-selection--single {
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: calc(1.5em + 1rem + 2px) !important;
  padding: 0.5rem 1rem;
  font-size: 1.09375rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

#input-fields .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 3.3;
}

#input-fields .select2-container .select2-selection--single {
  height: 40px;
}

#input-fields .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px;
}

#input-fields .select2-container {
  width: 100% !important;
}

#input-fields .select2-lg .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 1.5;
}

#input-fields .select2-lg .select2-container .select2-selection--single {
  height: 44.25px;
}

#input-fields .select2-lg .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 44.25px;
}

#input-fields .select2-lg .select2-container--default .select2-selection--single {
  padding: 0.5rem 0rem;
}

#input-fields .select2-sm .select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 1.1;
}

#input-fields .select2-sm .select2-container .select2-selection--single {
  height: 31px;
}

#input-fields .select2-sm .select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 31px;
}

#input-fields .select2-sm .select2-container--default .select2-selection--single {
  padding: 0.5rem 0rem;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  inset-block-start: 4px !important;
}

.select2-dropdown {
  direction: ltr !important;
}

/* ###### 4.11 Spectrum ###### */
.sp-container {
  background-color: rgb(var(--background-rgb));
  border-color: var(--border);
  z-index: 200;
  border-radius: 5px;
  box-shadow: 0px 4px 7px rgba(154, 154, 204, 0.1);
}

.sp-container button {
  border: 0;
  padding: 8px 15px;
  background-image: none;
  background-color: rgb(var(--background-rgb));
  font-family: inherit;
  font-size: 12px;
  text-shadow: none;
  text-transform: capitalize;
  border-radius: 2px;
}

.sp-container button:hover,
.sp-container button:focus {
  border: 0;
  background-image: none;
  background-color: var(--gray-500);
  text-shadow: none;
}

.sp-button-container .sp-cancel {
  font-family: inherit;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--gray-600) !important;
}

.sp-button-container .sp-cancel:hover,
.sp-button-container .sp-cancel:focus {
  color: var(--gray-800) !important;
  text-decoration: none;
}

.sp-picker-container {
  border-inline-start: 0;
}

.sp-replacer {
  border-color: var(--border);
  background-color: rgb(var(--background-rgb));
  border-radius: 5px;
}

.sp-replacer:hover,
.sp-replacer:focus {
  border-color: var(--border);
}

.sp-replacer.sp-active {
  border-color: var(--border);
}

.sp-dd {
  text-indent: -99999px;
  position: relative;
  width: 10px;
}

.sp-dd::before {
  content: "\f280";
  font-family: "Ionicons";
  font-size: 15px;
  color: var(--gray-500);
  position: absolute;
  text-indent: 0;
  inset-inline-start: 0;
  z-index: 10;
}

.sp-preview {
  border: 0;
}

.sp-dragger {
  background-color: transparent;
}

.sp-choose {
  background-color: rgb(var(--background-rgb));
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
  border-color: var(--gray-800);
}

.fc .fc-button-primary {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.fc .fc-scrollgrid.fc-scrollgrid-liquid thead {
  background-color: var(--black-03);
}

.fc .fc-day-today .fc-daygrid-day-number {
  font-weight: bold;
  color: var(--primary-bg-color);
}

.fc .fc-daygrid-day.fc-day-today {
  background-color: var(--gray-100);
}

.fc .fc-button-primary:hover {
  color: #000;
  background-color: var(--primary-bg-hover);
  border-color: var(--primary-bg-border);
}

.fc .fc-button-primary:disabled {
  /* not DRY */
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
}

.fc .fc-button-primary:not(:disabled):active,
.fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #000;
  background-color: var(--primary-bg-color);
  border-color: var(--primary-bg-color);
  opacity: 0.5;
}

.fc-daygrid-event.fc-event {
  background: var(--primary-bg-color);
  color: #000 !important;
}

.fc .fc-list-event-dot {
  display: inline-block;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 5px solid var(--primary-bg-color);
  border-radius: 4px;
}

.fc-v-event {
  /* allowed to be top-level */
  display: block;
  border: 1px solid var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.fc-daygrid-event-dot {
  /* the actual dot */
  display: none;
  margin: 4px;
  box-sizing: content-box;
  width: 0;
  height: 0;
  border: 4px solid var(--primary-bg-color);
  border-radius: 4px;
}

.fc-h-event {
  /* allowed to be top-level */
  display: block;
  border: 1px solid var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color);
  background-color: var(--primary-bg-color);
  background-color: var(--primary-bg-color);
}

.fc-scrollgrid-sync-table {
  border-color: var(--border);
}

.notifit_confirm_accept {
  background-color: var(--primary-bg-color);
  color: #000;
  border-radius: 4px;
}

#ui_notifIt.default {
  background-color: var(--primary-bg-color);
  color: #000;
}

#ui_notifIt.primary {
  background-color: var(--primary-bg-color);
  color: #000;
}

/* TEMP-STYLES */
.card--calendar .card-title {
  color: #14112d;
  font-weight: 700;
  text-transform: uppercase;
  margin-block-end: 15px;
}

.card--calendar .card-body {
  padding: 0;
}

.card--calendar .ui-datepicker-inline {
  border-width: 0;
  max-width: none;
  padding: 0;
  margin: 0;
}

.card--calendar .ui-datepicker .ui-datepicker-header {
  border-block-end: 1px solid var(--border);
  padding: 10px;
  margin-block-end: 5px;
}

.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
.card--calendar .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
  inset-block-start: 8px;
}

.card--calendar .ui-datepicker .ui-datepicker-month {
  color: #134cf7;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar {
  width: calc(100% - 20px);
}

.card--calendar .ui-datepicker .ui-datepicker-calendar th {
  font-size: 10px;
  text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar td a {
  display: block;
  width: 34px;
  height: 34px;
  font-size: 12px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.card--calendar .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: transparent;
  border: 1px solid #134cf7;
  color: rgb(var(--background-rgb));
  background: #134cf7;
  font-weight: 700;
}

.card--calendar .card--events .list-group-item {
  padding-block: 13px;
  padding-inline-start: 20px;
  padding-inline-end: 40px;
}

@media (min-width: 576px) {

  .card--calendar .ui-datepicker .ui-datepicker-calendar,
  .card--twelve .flot-chart {
    width: 100%;
  }
}

.card--events {
  border-color: #e3e3e3;
  box-shadow: 5px 7px 26px -5px #d5d4e0;
}

.card--events .card-header {
  padding: 20px;
  background-color: transparent;
}

.card--calendar .card--events {
  margin-inline-start: -20px;
  margin-inline-end: -20px;
}

.card--calendar .card--events .list-group-item {
  padding-block: 13px;
  padding-inline-start: 20px;
  padding-inline-end: 40px;
}

.card--events .card-title {
  color: #a5a0b1;
  text-transform: uppercase;
  margin-block-end: 10px;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1px;
}

.card--events .card-subtitle {
  color: #14112d;
  font-weight: 400;
  font-size: 18px;
}

.card--events .card-body {
  padding: 0;
}

.card--events .list-group-item {
  padding-block: 10px;
  padding-inline-start: 18px;
  padding-inline-end: 40px;
  border-inline-start-width: 0;
  border-inline-end-width: 0;
  position: relative;
}

.card--events .list-group-item:first-child,
.card--events .list-group-item:last-child {
  border-radius: 0;
}

.card--events .list-group-item label {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px;
  color: #37326d;
  margin-block-end: 8px;
  letter-spacing: 0.5px;
}

.card--events .list-group-item label span {
  color: #a5a0b1;
  font-weight: 500;
}

.card--events .list-group-item h6 {
  font-size: 14px;
  font-weight: 400;
  margin-block-end: 5px;
  display: flex;
  align-items: center;
}

.card--events .list-group-item p {
  margin-block-end: 5px;
}

.card--events .list-group-item p strong {
  font-weight: 500;
}

.card--events .list-group-item small {
  font-size: 11px;
}

.card--events .list-group-item small span {
  font-weight: 700;
  text-transform: uppercase;
}

.card--events .event-indicator {
  width: 10px;
  height: 10px;
  position: absolute;
  inset-block-start: 17px;
  inset-inline-start: 20px;
  border-radius: 100%;
}

.card--events .event-user {
  margin-block-start: 10px;
  display: flex;
  align-items: center;
}

.card--events .event-user .main-img-user {
  width: 28px;
  height: 28px;
}

.card--events .event-user .main-img-user::after {
  display: none;
}

.card--events .event-user .main-img-user+.main-img-user {
  margin-inline-start: -6px;
}

.card--events .event-user a:last-child {
  margin-inline-start: 10px;
  color: #a5a0b1;
  font-weight: 500;
  font-size: 11px;
}

.card--events .event-user a:last-child:hover,
.card--events .event-user a:last-child:focus {
  color: #37326d;
}

@media (max-width: 320px) {
  .ui-datepicker .ui-datepicker-calendar th {
    padding: 4px 0;
    letter-spacing: normal;
  }
}

.ui-datepicker .ui-datepicker-calendar td {
  border: 1px solid rgb(var(--background-rgb));
  padding: 0;
  background-color: rgb(var(--background-rgb));
  text-align: center;
}

.ui-datepicker .ui-datepicker-calendar td:last-child {
  border-inline-end: 0;
}

.ui-datepicker .ui-datepicker-calendar td.ui-datepicker-other-month .ui-state-default {
  color: var(--muted);
}

.ui-datepicker .ui-datepicker-calendar td span {
  transition: all 0.2s ease-in-out;
  padding: 5px;
  color: var(--color);
  padding: 6px 10px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  border-radius: 4px;
}

.ui-datepicker .ui-datepicker-calendar td a {
  transition: all 0.2s ease-in-out;
  padding: 5px;
  color: var(--color);
  padding: 10px 13px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  border: 0;
  border-radius: 4px;
}

.ui-datepicker .ui-datepicker-calendar td a:hover {
  background-color: rgb(var(--background-rgb));
  color: var(--color);
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:hover,
.ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a:focus {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
}

@media (prefers-reduced-motion: reduce) {

  .ui-datepicker .ui-datepicker-calendar td span,
  .ui-datepicker .ui-datepicker-calendar td a {
    transition: none;
  }
}

.ui-datepicker-multi .ui-datepicker-group {
  padding-inline-end: 15px;
  width: auto;
  float: var(--float-start);
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-title {
  margin: auto;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-prev::before {
  inset-inline-start: 10px;
}

.ui-datepicker-multi .ui-datepicker-group .ui-datepicker-next::before {
  inset-inline-end: 10px;
}

.ui-datepicker-multi .ui-datepicker-group table {
  margin: 0;
}

.ui-datepicker-multi .ui-datepicker-group-last {
  padding-inline-end: 0;
}

.ui-datepicker-inline {
  border-color: #e4e8f3;
}

@media (min-width: 576px) {
  .ui-datepicker-inline {
    max-width: 310px;
  }
}

/* ###### 7.2 Calendar  ###### */
@media (min-width: 992px) {
  .main-content-calendar {
    padding: 25px 0;
  }
}

.main-content-calendar .container,
.main-content-calendar .container-fluid {
  padding: 20px 0 0;
}

@media (min-width: 992px) {

  .main-content-calendar .container,
  .main-content-calendar .container-fluid {
    padding-block-start: 0;
  }
}

@media (min-width: 576px) {
  .main-content-left-calendar {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .main-content-left-calendar {
    width: 230px;
  }
}

.main-datepicker .ui-datepicker-inline {
  margin-block-start: 0;
}

.main-datepicker .ui-datepicker .ui-datepicker-title {
  color: var(--primary-bg-color);
  letter-spacing: -0.2px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar {
  width: 100%;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th {
  padding: 5px;
  font-size: 10px;
  color: var(--gray-900);
  text-align: center;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
  text-align: center;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar th.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
  color: #d6573c;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td {
  padding: 0 1px;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td.ui-datepicker-week-end:first-child:not(.ui-datepicker-other-month) a {
  color: #d6573c;
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar td span,
.main-datepicker .ui-datepicker .ui-datepicker-calendar td a {
  padding: 4px 5px;
  font-family: Verdana, sans-serif;
  font-size: 11px;
  color: var(--gray-700);
}

.main-datepicker .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
  border-radius: 4px;
}

.main-nav-calendar-event .nav-link {
  font-size: 13px;
  position: relative;
}

.main-nav-calendar-event .nav-link i {
  font-size: 21px;
  position: relative;
  margin-inline-end: 10px;
  inset-block-start: -0.5px;
}

.main-nav-calendar-event .nav-link.exclude {
  color: var(--gray-600);
}

.main-nav-calendar-event .nav-link.exclude i {
  color: var(--gray-600);
}

.main-nav-calendar-event .nav-link.exclude::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 0;
  inset-inline-end: 0;
  border-block-start: 1px solid var(--gray-600);
}

@media (min-width: 992px) {
  .main-content-body-calendar {
    padding-inline-start: 25px;
  }
}

.main-calendar {
  border-color: #e4e8f3;
}

.main-calendar .fc-content,
.main-calendar .fc-divider,
.main-calendar .fc-list-heading td,
.main-calendar .fc-list-view,
.main-calendar .fc-popover,
.main-calendar .fc-row,
.main-calendar tbody,
.main-calendar td,
.main-calendar th,
.main-calendar thead {
  border-color: #e4e8f3;
}

.main-calendar .fc-header-toolbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-block-end: 15px;
  padding: 0 20px;
}

.main-calendar .fc-header-toolbar h2 {
  margin-block-end: 0;
  color: var(--gray-900);
  font-size: 20px;
  font-weight: 700;
}

.main-calendar .fc-header-toolbar .fc-right {
  order: 3;
}

.main-calendar .fc-header-toolbar .fc-clear {
  display: none;
}

.main-calendar .fc-header-toolbar button {
  height: 38px;
  background-color: rgb(var(--background-rgb));
  background-image: none;
  border: 1px solid var(--border);
  box-shadow: none;
  padding: 0 15px;
  display: flex;
  align-items: center;
  color: var(--gray-900);
  font-size: 13px;
  text-transform: capitalize;
  outline: none;
  text-shadow: none;
  border-radius: 0;
}

.main-calendar .fc-header-toolbar button:hover,
.main-calendar .fc-header-toolbar button:focus {
  background-color: var(--gray-200);
}

.main-calendar .fc-header-toolbar button.fc-prev-button,
.main-calendar .fc-header-toolbar button.fc-next-button {
  padding: 0;
  width: 38px;
  justify-content: center;
}

.main-calendar .fc-header-toolbar button.fc-prev-button span,
.main-calendar .fc-header-toolbar button.fc-next-button span {
  margin: 0;
}

.main-calendar .fc-header-toolbar button.fc-state-active {
  background-color: var(--gray-200);
}

.main-calendar .fc-header-toolbar button.fc-today-button {
  color: rgb(var(--background-rgb));
  background-color: var(--primary-bg-color);
  border-color: #1a53ff;
}

.main-calendar .fc-header-toolbar button.fc-today-button.fc-state-disabled {
  border-color: #e4e8f3;
  background-color: var(--gray-100);
  color: var(--gray-500);
  cursor: default;
}

.main-calendar .fc-icon-left-single-arrow,
.main-calendar .fc-icon-right-single-arrow {
  inset-block-start: 0;
}

.main-calendar .fc-icon-left-single-arrow::after,
.main-calendar .fc-icon-right-single-arrow::after {
  font-family: "Ionicons";
  font-size: 18px;
  font-weight: 400;
  line-height: 1;
}

.main-calendar .fc-icon-left-single-arrow::after {
  content: "\f3cf";
}

.main-calendar .fc-icon-right-single-arrow::after {
  content: "\f3d1";
}

.main-calendar .fc-head-container .fc-day-header {
  font-size: 11px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 0;
  color: var(--gray-900);
}

.main-calendar .fc-view>table {
  background-color: rgb(var(--background-rgb));
}

.main-calendar .fc-view>table>thead th,
.main-calendar .fc-view>table>thead td {
  border-color: #e4e8f3;
}

.main-calendar .fc-view>table>tbody>tr>td {
  border-color: #e4e8f3;
}

.main-calendar .fc-view .fc-other-month {
  background-color: #eff3fd;
}

.main-calendar .fc-view .fc-day-number {
  font-size: 14px;
  font-weight: 400;
  font-family: Arial, sans-serif;
  color: var(--gray-700);
  display: inline-block;
  padding: 5px 8px;
  position: relative;
  transition: all 0.2s ease-in-out;
  margin-block-start: 2px;
  margin-inline-end: 2px;
  margin-block-end: 0;
  margin-inline-start: 0;
  min-width: 20px;
  text-align: center;
}

.main-calendar .fc-view .fc-day-number:hover,
.main-calendar .fc-view .fc-day-number:focus {
  color: var(--gray-900);
  background-color: var(--gray-100);
}

.main-calendar .fc-view .fc-scroller {
  height: auto !important;
  overflow: visible !important;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span {
  display: block;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
  color: var(--gray-600);
  font-size: 8px;
  font-weight: 400;
  line-height: 1.2;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
  font-weight: 700;
  font-size: 14px;
  font-family: Helvetica, Arial, sans-serif;
  line-height: 1;
}

.main-calendar .fc-view.fc-agenda-view .fc-day-header.fc-today>a {
  color: var(--primary-bg-color);
}

.main-calendar .fc-view.fc-listMonth-view,
.main-calendar .fc-view.fc-listWeek-view {
  border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-scroller,
.main-calendar .fc-view.fc-listWeek-view .fc-scroller {
  padding: 0 20px 20px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table {
  border-collapse: separate;
  display: block;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-table>tbody,
.main-calendar .fc-view.fc-listWeek-view .fc-list-table>tbody {
  display: flex;
  flex-wrap: wrap;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
  flex: 0 0 20%;
  max-width: 20%;
  margin-block-start: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading:first-child+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading:first-child+.fc-list-item {
  margin-block-start: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading td {
  background-color: transparent;
  border-width: 0;
  padding: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main {
  display: block;
  font-size: 11px;
  text-transform: uppercase;
  color: var(--gray-600);
  font-weight: 600;
  padding: 5px;
  text-align: center;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main span:last-child {
  display: block;
  font-size: 30px;
  font-weight: 700;
  font-family: Helvetica, Arial, sans-serif;
  letter-spacing: -1px;
  color: var(--gray-900);
  line-height: 1;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now,
.main-calendar .fc-view.fc-listMonth-view .fc-list-heading-main.now span:last-child,
.main-calendar .fc-view.fc-listWeek-view .fc-list-heading-main.now span:last-child {
  color: var(--primary-bg-color);
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item {
  flex: 0 0 calc(80% - 5px);
  max-width: calc(80% - 5px);
  display: flex;
  flex-direction: column;
  border-inline-start: 4px solid transparent;
  background-color: rgb(var(--background-rgb));
  margin-block-start: 15px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus {
  cursor: pointer;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus {
  cursor: pointer;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listMonth-view .fc-list-item:focus td {
  background-color: transparent;
}

.main-calendar .fc-view.fc-listWeek-view .fc-list-item:hover td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item:focus td {
  background-color: transparent;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td {
  border-width: 0;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-time {
  border-block-start: 1px solid var(--gray-200);
  border-inline-end: 1px solid var(--gray-200);
  box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item>td.fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item>td.fc-list-item-title {
  border-block-end: 1px solid var(--gray-200);
  border-inline-end: 1px solid var(--gray-200);
  box-sizing: border-box;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
  margin-inline-start: 20%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-marker,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-marker {
  display: none;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-time,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-time {
  padding: 12px 15px 2px;
  font-size: 13px;
  text-transform: capitalize;
  width: 100%;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title {
  padding: 0 15px 12px;
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title a,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title a {
  display: block;
  font-weight: 700;
  font-size: 15px;
  margin-block-end: 5px;
  color: var(--gray-900);
}

.main-calendar .fc-view.fc-listMonth-view .fc-list-item-title .fc-desc,
.main-calendar .fc-view.fc-listWeek-view .fc-list-item-title .fc-desc {
  font-size: 13px;
  line-height: 1.375;
  display: block;
}

.main-calendar td.fc-today {
  background-color: var(--primary-bg-color);
}

.main-calendar td.fc-today .fc-day-number {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
  border-radius: 1px;
}

.main-calendar td.fc-today .fc-day-number:hover,
.main-calendar td.fc-today .fc-day-number:focus {
  background-color: var(--primary-bg-color);
  color: rgb(var(--background-rgb));
  border-radius: 1px;
}

.main-calendar .fc-axis {
  font-size: 11px;
}

.main-calendar .fc-event {
  border-width: 0;
  border-block-start-width: 5px;
  border-radius: 0;
}

.main-calendar .fc-event.fc-day-grid-event>div {
  display: none;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-time {
  font-weight: 500;
}

.main-calendar .fc-event.fc-day-grid-event>div .fc-desc {
  display: none;
}

.main-calendar .fc-time-grid-event {
  padding: 5px;
  border-inline-start-width: 0;
  border-block-start-width: 2px;
}

.main-calendar .fc-time-grid-event .fc-time {
  font-size: 12px;
}

.main-calendar .fc-time-grid-event .fc-title {
  font-size: 14px;
  font-weight: 700;
  color: var(--gray-900);
  margin-block-end: 10px;
}

.main-calendar .fc-time-grid-event .fc-desc {
  font-size: 13px;
  line-height: 1.3;
  color: rgba(28, 39, 60, 0.8);
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
  display: none;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-time {
  font-size: 11px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-title {
  font-size: 13px;
}

.main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
  display: none;
}

.main-calendar .fc-list-empty {
  padding: 20px 0;
  background-color: rgb(var(--background-rgb));
  border: 1px solid var(--border);
}

@media (min-width: 992px) {
  .main-calendar .fc-header-toolbar {
    padding: 0;
    margin-block-end: 24px;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-header-toolbar h2 {
    font-size: 22px;
  }
}

@media (max-width: 767.98px) {
  .main-calendar .fc-header-toolbar .fc-center {
    order: 1;
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    margin-block-end: 20px;
  }

  .main-calendar .fc-header-toolbar .fc-left {
    order: 2;
    flex: 0 0 50%;
  }

  .main-calendar .fc-header-toolbar .fc-right {
    flex: 0 0 50%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 575px) {

  .main-calendar .fc-header-toolbar button.fc-prev-button,
  .main-calendar .fc-header-toolbar button.fc-next-button {
    width: 32px;
    height: 32px;
  }

  .main-calendar .fc-header-toolbar button.fc-today-button {
    height: 32px;
    padding-inline-start: 10px;
    padding-inline-end: 10px;
    font-size: 11px;
  }

  .main-calendar .fc-header-toolbar button.fc-month-button,
  .main-calendar .fc-header-toolbar button.fc-agendaWeek-button,
  .main-calendar .fc-header-toolbar button.fc-agendaDay-button,
  .main-calendar .fc-header-toolbar button.fc-listWeek-button,
  .main-calendar .fc-header-toolbar button.fc-listMonth-button {
    text-indent: -9999px;
    width: 32px;
    height: 32px;
    position: relative;
    color: transparent;
  }

  .main-calendar .fc-header-toolbar button.fc-month-button::before,
  .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before,
  .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before,
  .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
  .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
    position: absolute;
    inset-block-start: 6px;
    inset-inline-start: 10px;
    z-index: 100;
    display: inline-block;
    text-indent: 0;
    font-size: 12px;
    font-weight: 700;
    color: var(--color);
  }

  .main-calendar .fc-header-toolbar button.fc-month-button::before {
    content: "M";
  }

  .main-calendar .fc-header-toolbar button.fc-agendaWeek-button::before {
    content: "W";
  }

  .main-calendar .fc-header-toolbar button.fc-agendaDay-button::before {
    content: "D";
  }

  .main-calendar .fc-header-toolbar button.fc-listWeek-button::before,
  .main-calendar .fc-header-toolbar button.fc-listMonth-button::before {
    content: "L";
    inset-inline-start: 11px;
  }
}

@media (min-width: 992px) {

  .main-calendar .fc-icon-left-single-arrow::after,
  .main-calendar .fc-icon-right-single-arrow::after {
    line-height: 0.75;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-head-container .fc-day-header {
    font-size: 12px;
  }
}

@media (max-width: 991.98px) {

  .main-calendar .fc-view>table>thead th:first-child,
  .main-calendar .fc-view>table>thead td:first-child {
    border-inline-start-width: 0;
  }

  .main-calendar .fc-view>table>thead th:last-child,
  .main-calendar .fc-view>table>thead td:last-child {
    border-inline-end-width: 0;
  }
}

@media (max-width: 991.98px) {
  .main-calendar .fc-view>table>tbody>tr>td:first-child {
    border-inline-start-width: 0;
  }

  .main-calendar .fc-view>table>tbody>tr>td:last-child {
    border-inline-end-width: 0;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-calendar .fc-view .fc-day-number {
    transition: none;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:first-child {
    font-size: 11px;
    font-weight: 600;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-view.fc-agenda-view .fc-day-header>a>span:last-child {
    font-size: 25px;
  }
}

@media (min-width: 992px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-scroller,
  .main-calendar .fc-view.fc-listWeek-view .fc-scroller {
    padding: 0;
  }
}

@media (min-width: 576px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 15%;
    max-width: 15%;
  }
}

@media (min-width: 768px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 12%;
    max-width: 12%;
  }
}

@media (min-width: 992px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 10%;
    max-width: 10%;
  }
}

@media (min-width: 1200px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-heading,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-heading {
    flex: 0 0 8%;
    max-width: 8%;
  }
}

@media (min-width: 576px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(85% - 5px);
    max-width: calc(85% - 5px);
  }
}

@media (min-width: 768px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(88% - 5px);
    max-width: calc(88% - 5px);
  }
}

@media (min-width: 992px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(90% - 5px);
    max-width: calc(90% - 5px);
  }
}

@media (min-width: 1200px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item {
    flex: 0 0 calc(92% - 5px);
    max-width: calc(92% - 5px);
  }
}

@media (min-width: 576px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-inline-start: 15%;
  }
}

@media (min-width: 768px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-inline-start: 12%;
  }
}

@media (min-width: 992px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-inline-start: 10%;
  }
}

@media (min-width: 1200px) {

  .main-calendar .fc-view.fc-listMonth-view .fc-list-item+.fc-list-item,
  .main-calendar .fc-view.fc-listWeek-view .fc-list-item+.fc-list-item {
    margin-inline-start: 8%;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-axis {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-event {
    border-block-start-width: 0;
    border-inline-start-width: 2px;
    padding: 3px 4px;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-event.fc-day-grid-event>div {
    display: block;
  }
}

@media (min-width: 576px) {
  .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div {
    display: block;
  }
}

@media (min-width: 992px) {
  .main-calendar .fc-agendaWeek-view .fc-time-grid-event>div .fc-desc {
    display: block;
  }
}

.main-modal-calendar-schedule .modal-content {
  border-width: 0;
  position: relative;
}

.main-modal-calendar-schedule .modal-content>.close {
  position: absolute;
  inset-block-start: 20px;
  inset-inline-end: 25px;
  font-size: 28px;
  font-weight: 700;
  color: var(--gray-600);
  text-shadow: none;
}

.main-modal-calendar-schedule .modal-header {
  padding: 20px 25px;
}

.main-modal-calendar-schedule .modal-body {
  padding: 30px 25px;
}

.main-modal-calendar-schedule .form-group-date {
  display: flex;
  align-items: center;
  border: 1px solid var(--border);
  background-color: var(--gray-100);
  padding: 10px 5px;
  color: var(--gray-900);
  font-size: 13px;
  font-weight: 600;
}

.main-modal-calendar-schedule .form-group-date>i {
  font-size: 32px;
  line-height: 0;
  margin-inline-end: 10px;
  opacity: 0.5;
}

.main-modal-calendar-schedule .select2-container {
  width: 100% !important;
}

.main-modal-calendar-event .modal-content {
  border-width: 0;
  position: relative;
  background-color: transparent;
}

.main-modal-calendar-event .modal-content>.close {
  font-size: 18px;
  text-shadow: none;
}

.main-modal-calendar-event .modal-header {
  position: relative;
  padding: 20px;
  border-block-end-width: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-start-start-radius: 0;
  border-start-end-radius: 0;
}

.main-modal-calendar-event .nav-modal-event {
  align-items: center;
}

.main-modal-calendar-event .nav-modal-event .nav-link {
  padding: 0;
  color: rgba(255, 255, 255, 0.75);
  font-size: 21px;
  line-height: 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}

.main-modal-calendar-event .nav-modal-event .nav-link:hover,
.main-modal-calendar-event .nav-modal-event .nav-link:focus {
  color: rgb(var(--background-rgb));
}

.main-modal-calendar-event .nav-modal-event .nav-link+.nav-link {
  margin-inline-start: 15px;
}

.main-modal-calendar-event .nav-modal-event .nav-link:last-child {
  font-size: 24px;
}

.main-modal-calendar-event .event-title {
  color: rgb(var(--background-rgb));
  font-size: 18px;
  font-weight: 500;
  margin-block-end: 0;
  line-height: 1;
}

.main-modal-calendar-event .modal-body {
  padding: 20px;
  background-color: rgb(var(--background-rgb));
}

.main-modal-calendar-event .event-start-date,
.main-modal-calendar-event .event-end-date {
  color: var(--gray-900);
  font-weight: 500;
}

.main-modal-calendar-event .event-desc {
  margin-block-start: 5px;
}

.main-modal-calendar-event .event-desc:empty {
  margin-block-end: 5px;
}

@media (min-width: 576px) {
  .main-modal-calendar-event .modal-header {
    padding: 20px 25px;
  }
}

@media (prefers-reduced-motion: reduce) {
  .main-modal-calendar-event .nav-modal-event .nav-link {
    transition: none;
  }
}

@media (min-width: 576px) {
  .main-modal-calendar-event .modal-body {
    padding: 30px;
  }
}

@media (max-width: 605px) {
  .main-calendar .fc-header-toolbar {
    padding: 0;
  }

  .fc-view {
    border: 1px solid var(--border);
  }

  .fc-view>table {
    border: 1px solid var(--border);
  }

  .card--calendar .ui-datepicker .ui-datepicker-calendar td a {
    width: 28px;
    height: 28px;
  }

  .main-content-body.main-content-body-calendar.card.p-4 {
    padding: 1rem !important;
  }
}

@media (max-width: 320px) {
  .main-calendar .fc-header-toolbar .fc-right {
    flex: 0 0 100%;
    display: flex;
    justify-content: start;
    margin-block-start: 20px;
  }

  .main-calendar .fc-header-toolbar .fc-left {
    flex: 0 0 100%;
    display: flex;
    justify-content: start;
  }
}

.fc-datepicker.main-datepicker.hasDatepicker {
  border: 1px solid var(--border);
}

@media (min-width: 320px) and (max-width: 568px) {
  .ui-datepicker-calendar {
    width: 100%;
  }

  .ui-datepicker .ui-datepicker-calendar td,
  .ui-datepicker .ui-datepicker-calendar th {
    text-align: center;
  }
}

/* ###### 7.3 Chat  ###### */
@media (max-width: 991.98px) {
  .main-content-body-show .main-header-menu-icon {
    display: none;
  }

  .main-content-body-show .main-header-left .main-header-arrow {
    display: block;
  }

  .main-content-body-show .main-content-left-chat {
    display: none;
  }

  .main-content-body-show .main-content-body-chat {
    display: flex;
  }
}

@media (max-width: 991px) {
  .main-content-body-show .main-header-arrow {
    display: block;
  }
}

.main-nav-line-chat {
  border-block-end: 1px solid var(--border);
  padding-block-end: 15px;
  padding-block-start: 15px;
  flex-shrink: 0;
  flex-direction: row;
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.main-nav-line-chat .nav-link {
  font-size: 12px;
  font-weight: 500;
  color: var(--gray-800);
  text-transform: uppercase;
  padding: 8px 20px;
  border: 1px solid var(--border);
  border-radius: 4px;
  margin-block-end: 5px;
}

.main-nav-line-chat .nav-link:hover,
.main-nav-line-chat .nav-link:focus,
.main-nav-line-chat .nav-link:active {
  outline: none;
}

.main-nav-line-chat .nav-link+.nav-link {
  margin-block-start: 0;
  margin-inline-start: 5px;
}

.main-nav-line-chat .nav-link.active {
  color: rgb(var(--background-rgb));
  background: var(--primary-bg-color);
}

@media (min-width: 992px) {
  .main-nav-line-chat {
    padding-inline-start: 0;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 20px;
    border-radius: 4px;
    border-block-end: 1px solid var(--border);
  }
}

.main-chat-contacts-wrapper .main-img-user::after,
.main-chat-list .main-img-user::after,
.main-chat-header .main-img-user::after,
.main-chat-body .main-img-user::after {
  inset-inline-end: 1px;
  inset-block-end: 3px;
}

.main-chat-contacts-wrapper {
  padding: 10px 20px;
  flex-shrink: 0;
  border-block-end: 1px solid var(--border);
  overflow: hidden;
}

.main-chat-contacts-wrapper .main-content-label,
.main-chat-contacts-wrapper .card-table-two .card-title {
  margin-block-end: 15px;
  line-height: 1;
  padding-inline-start: 20px;
}

.card-table-two {
  padding: 20px;
}

.card-table-two .card-title {
  font-size: 13px;
}

@media (min-width: 992px) {
  .main-chat-contacts-wrapper {
    padding-block: 10px;
    padding-inline-end: 15px;
    padding-inline-start: 0;
  }
}

.card-table-two .main-chat-contacts-wrapper .card-title,
.main-chat-contacts-wrapper .card-dashboard-eight .card-title,
.card-dashboard-eight .main-chat-contacts-wrapper .card-title {
  margin-block-end: 15px;
  line-height: 1;
  padding-inline-start: 20px;
}

.main-chat-contacts-wrapper .lSSlideOuter,
.main-chat-contacts-wrapper .lSSlideWrapper {
  overflow: visible;
}

.main-chat-contacts {
  padding-inline-start: 20px;
  display: flex;
}

.main-chat-contacts .lslide {
  display: flex;
  flex-direction: column;
  align-items: center;
  float: none;
}

.main-chat-contacts small {
  font-size: 11px;
  width: 36px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-block-start: 3px;
  text-align: center;
}

.main-chat-contacts-more {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-color: var(--primary-bg-color);
  font-size: 12px;
  font-weight: 500;
  color: rgb(var(--background-rgb));
}

@media (min-width: 992px) {
  .main-chat-list {
    max-height: 100%;
    position: relative;
  }
}

.main-content-body-chat {
  flex-direction: column;
  display: none;
}

@media (min-width: 992px) {
  .main-content-body-chat {
    display: flex;
  }
}

.main-chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 20px;
  border-block-end: 1px solid var(--border);
}

@media (min-width: 576px) {
  .main-chat-header .nav-link {
    display: block;
  }

  .main-chat-header .nav-link:first-child {
    display: none;
  }
}

.main-chat-msg-name {
  margin-inline-start: 15px;
}

.main-chat-msg-name h6 {
  margin-block-end: 2px;
  font-size: 15px;
  font-weight: 600;
  color: var(--gray-900);
}

.main-chat-msg-name small {
  font-size: 12px;
  color: var(--gray-600);
}

.main-chat-body {
  position: relative;
  padding-block-end: 50px;
}

.main-chat-body .content-inner {
  padding: 20px;
}

.main-chat-body .media+.media {
  margin-block-start: 20px;
}

.main-chat-body .media.flex-row-reverse .media-body {
  margin-inline-start: 0;
  margin-inline-end: 20px;
  align-items: flex-end;
}

.main-chat-body .media.flex-row-reverse .main-msg-wrapper {
  background-color: var(--primary-bg-color);
  color: #fff;
  border-radius: 4px;
}

.main-chat-body .media-body {
  margin-inline-start: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.main-chat-body .media-body>div:last-child {
  font-size: 11px;
  color: var(--gray-600);
  display: flex;
  align-items: center;
}

@media (min-width: 992px) {
  .main-chat-body {
    padding-block-end: 0;
    max-height: fit-content;
    overflow: hidden;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media.flex-row-reverse .media-body {
    margin-inline-start: 55px;
  }
}

@media (min-width: 576px) {
  .main-chat-body .media-body {
    margin-inline-end: 55px;
  }
}

.main-chat-time {
  display: block;
  position: relative;
  text-align: center;
  margin: 20px 0;
}

.main-chat-time:first-of-type {
  margin-block-start: 0;
}

.main-chat-time span {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.main-msg-wrapper {
  padding: 10px 15px;
  background-color: var(--primary02);
  font-size: 13px;
  margin-block-end: 5px;
  border-radius: 4px;
  display: inline-block;
}

.main-chat-footer {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 70px;
  padding: 0 20px;
  border-block-start: 1px solid var(--border);
  background-color: rgb(var(--background-rgb));
  position: relative;
  inset-block-end: 0;
  inset-inline-start: 0;
  inset-inline-end: 0;
}

.main-chat-footer .nav {
  align-items: center;
}

.main-chat-footer .nav-link {
  padding: 0;
  color: var(--gray-500);
  font-size: 16px;
  display: none;
}

.main-chat-footer .nav-link+.nav-link {
  margin-inline-start: 10px;
}

.main-chat-footer .nav-link:last-child {
  display: block;
  margin-inline-start: 0;
}

.main-chat-footer .form-control {
  flex: 1;
  margin-block-start: 0;
  margin-inline-end: 10px;
  margin-block-end: 0;
  margin-inline-start: 0;
  padding-inline-start: 10px !important;
  padding-inline-end: 0;
  border-width: 0;
  border: 1px solid var(--border);
}

.main-chat-footer .form-control:hover,
.main-chat-footer .form-control:focus {
  box-shadow: none;
}

@media (min-width: 992px) {
  .main-chat-footer {
    position: relative;
    inset-block-end: auto;
    inset-inline-start: auto;
    inset-inline-end: auto;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .nav-link {
    display: block;
  }

  .main-chat-footer .nav-link:last-child {
    display: none;
  }
}

@media (min-width: 576px) {
  .main-chat-footer .form-control {
    margin-inline-start: 20px;
  }
}

@media (min-width: 768px) {
  .main-chat-footer .form-control {
    margin-block-start: 0;
    margin-inline-end: 20px;
    margin-block-end: 0;
    margin-inline-start: 0;
  }
}

.main-msg-send {
  color: rgb(var(--background-rgb));
  font-size: 15px;
  border: solid var(--border) 1px;
  border-radius: 4px;
  padding: 5px 10px;
  background: var(--primary-bg-color);
}

.main-msg-send i {
  line-height: 1.5;
}

@media (max-width: 991px) and (min-width: 768px) {
  .app.sidebar-mini .main-chat-footer {
    margin-inline-start: 0px;
  }

  .app.sidebar-mini.sidenav-toggled .main-chat-footer {
    margin-inline-start: 0;
  }
}

@media (max-width: 380px) and (min-width: 305px) {
  .main-nav-line-chat .nav-link+.nav-link {
    margin-block-start: 8px;
    margin-inline-start: 0px;
  }
}

/*--chat--*/
.chat {
  margin-block-end: auto;
}

.chat .card {
  height: 600px;
}

.chat .card-header {
  padding: 1rem;
}

.contacts_body {
  white-space: nowrap;
}

.msg_card_body {
  overflow-y: auto;
}

.contacts_body .contacts {
  list-style: none;
  padding: 0;
}

.contacts_body .contacts li {
  width: 100% !important;
  padding: 15px;
  border-block-end: 1px solid var(--border);
}

.contacts_body .active {
  background-color: var(--bg-hover);
}

#action_menu_btn {
  position: absolute;
  inset-inline-end: 36px;
  inset-block-start: 21px;
  color: rgb(var(--background-rgb));
  cursor: pointer;
  font-size: 20px;
}

.action_menu {
  z-index: 1;
  position: absolute;
  padding: 15px 0;
  background-color: rgb(var(--background-rgb));
  color: var(--color);
  inset-block-start: 40px;
  inset-inline-end: 15px;
  display: none;
}

.action_menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ah-actions {
  z-index: 3;
  float: var(--float-end) !important;
  inset-block-start: 3px;
  position: relative;
}

.actions {
  list-style: none;
  padding: 0;
  margin: 0;
}

.actions>li {
  display: inline-block;
}

.actions:not(.a-alt)>li>a>i {
  color: var(--color);
}

.actions>li>a {
  display: block;
  width: 37px;
  height: 37px;
  border-radius: 4px;
  margin: 0 auto;
  line-height: 37px;
  text-align: center;
}

.actions>li>a>i {
  font-size: 17px;
}

.actions>li>a:hover {
  background: var(--gray-100);
  color: var(--primary-bg-color);
}

.actions>li>a:hover i {
  color: var(--primary-bg-color) !important;
}

.dash-5 {
  text-align: center;
}

.dash-5 h3 {
  font-size: 2rem;
  margin-block-end: 0.5rem;
}

.well {
  color: var(--color);
}

.icon.icon-shape i {
  height: 3rem;
  width: 3rem;
  background: var(--black);
  border-radius: 50%;
  line-height: 3rem;
  background: var(--bg-hover);
  color: var(--primary-bg-color);
}

.user-img-circle {
  box-shadow: 0 0 0 rgba(81, 15, 167, 0.4);
  animation: blinking 2s infinite;
  height: 90px;
}

@keyframes blinking {
  0% {
    transform: scale(0.95);
    opacity: 0;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0.95);
    opacity: 0;
  }
}

.chat .action-header {
  position: relative;
  padding: 12px 13px;
  background: var(--primary02);
  box-shadow: 0px 0px 10px 2px var(--shadow-color);
}

.chat .dropdown-menu.dropdown-menu-right.show {
  width: 100%;
  margin-block-end: 5px;
  position: absolute;
  transform: translate3d(-176px, 22px, 0px);
  inset-block-start: 10px !important;
  inset-inline-start: 0px;
  will-change: transform;
}

.chat .msb-reply textarea {
  width: 100%;
  font-size: 13px;
  padding: 12px 47px;
  resize: none;
  height: 44px;
  border: 1px solid var(--border);
  background: var(--primary02);
  margin-inline-start: -42px;
}

.action_menu ul li:hover {
  cursor: pointer;
  background-color: var(--black-2);
}

.chat .actions>li {
  display: inline-block;
}

.chat .actions:not(.a-alt)>li>a>i {
  color: rgb(var(--background-rgb));
  line-height: unset;
}

.chat .chat_block {
  margin-block-end: 35px;
}

.chatbox .user_img {
  height: 50px;
  width: 50px;
}

.chatbox .user_img_msg {
  height: 40px;
  width: 40px;
  border: 0px solid var(--border);
}

.chatbox .img_cont {
  position: relative;
  height: 50px;
  width: 50px;
}

.chatbox .img_cont_msg {
  height: 40px;
  width: 40px;
  display: contents;
}

.chat-box-single-line {
  height: 1px;
  width: 100%;
  background-color: var(--border);
  opacity: 0.7;
  margin: 7px 0 30px;
  position: relative;
  text-align: center;
}

.chat .timestamp {
  padding: 12px 16px;
  color: var(--muted);
  font-size: 11px;
  background-color: var(--bg-hover);
  border: 6px solid rgb(var(--background-rgb));
  border-radius: 18px;
  position: absolute;
  inset-block-start: 50%;
  inset-block-end: 50%;
  inset-inline-end: 48%;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatbox .user_info {
  margin-block-start: auto;
  margin-block-end: auto;
  margin-inline-start: 15px;
}

.chatbox .user_info span {
  color: var(--color);
}

.chatbox .action-header small {
  font-size: 10px;
}

.chat .video_cam {
  margin-inline-start: 50px;
  margin-block-start: 5px;
}

.chat .video_cam span {
  color: rgb(var(--background-rgb));
  font-size: 20px;
  cursor: pointer;
  margin-inline-end: 20px;
}

.chat .chat_audio {
  margin-inline-start: -6px;
}

.chat .msg_block {
  margin-inline-start: 8px;
  margin-block-start: auto;
  margin-block-end: auto;
}

.chat .msg_block> :not(:first-child) {
  margin-block-start: 26px;
}

.chat .msg_container {
  position: relative;
}

.chat .msg_container .msg_cotainer-main {
  background-color: var(--bg-hover);
  padding: 9px;
  border-radius: 4px;
  border-start-start-radius: 0px;
  width: fit-content;
}

.chat .msg_container> :not(:first-child) {
  margin-block-start: 5px;
}

.chat .msg_cotainer-main.media-files,
.chat .msg_cotainer_send-main.media-files {
  background-color: unset !important;
  padding: 0px !important;
}

.chat .msg_cotainer-main.media-files img,
.chat .msg_cotainer_send-main.media-files img {
  width: 100px;
  border-radius: 4px;
}

.chat .msg_block_send {
  margin-block-start: auto;
  margin-block-end: auto;
}

.chat .msg_block_send> :not(:first-child) {
  margin-block-start: 26px;
}

.chat .msg_container_send {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.chat .msg_container_send .msg_cotainer_send-main {
  background-color: var(--primary-bg-color);
  color: #000;
  padding: 9px;
  border-radius: 4px;
  border-start-end-radius: 0px;
  width: fit-content;
}

.chat .msg_container_send .msg_cotainer_send-main.media-files {
  text-align: end;
}

.chat .msg_container_send> :not(:first-child) {
  margin-block-start: 5px;
}

.chat .msg_time {
  position: absolute;
  inset-inline-start: 0;
  inset-block-end: -18px;
}

.chat .msg_time_send {
  position: absolute;
  inset-inline-end: 0;
  inset-block-end: -18px;
  color: var(--color2);
  font-size: 10px;
}

.chat .msg_head {
  position: relative;
  background: var(--primary-bg-color);
  box-shadow: 0 1px 15px 1px var(--shadow-color);
}

.chat_input_icons {
  position: absolute;
  inset-inline-end: 20px;
  inset-block-start: 50%;
  transform: translateY(-50%);
}

@media (max-width: 449px) {
  .actions {
    float: var(--float-start) !important;
  }
}

.main-msg-wrapper.right {
  position: relative;
  background: var(--primary-bg-color);
  border: 1px solid var(--primary-bg-color);
}

.main-msg-wrapper.right:after,
.main-msg-wrapper.right:before {
  inset-inline-start: 100%;
  inset-block-start: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.main-msg-wrapper.right:nth-child(1):before {
  border-color: transparent;
  border-inline-start-color: var(--primary-bg-color);
  border-width: 0px;
  margin-block-start: -15px;
}

.main-msg-wrapper.right:nth-child(1):after {
  border-color: transparent;
  border-inline-start-color: var(--primary-bg-color);
  border-width: 8px;
  margin-block-start: -10px;
}

.main-msg-wrapper.left {
  position: relative;
}

.main-msg-wrapper.left:after,
.main-msg-wrapper.left:before {
  inset-inline-end: 100%;
  inset-block-start: 50%;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.main-msg-wrapper.left:nth-child(1):before {
  border-color: transparent;
  border-inline-end-color: var(--primary02);
  border-width: 0px;
  margin-block-start: -15px;
}

.main-msg-wrapper.left:nth-child(1):after {
  border-color: transparent;
  border-inline-end-color: var(--border);
  border-width: 8px;
  margin-block-start: -10px;
}

@media (max-width: 991px) {
  .main-nav-line-chat .nav-link {
    padding: 10px 10px;
  }
}

.modal-dialog.modal-dialog-right.chatbox .card-footer {
  padding: 1.25rem;
}

.main-chat-footer .nav-link {
  color: var(--muted);
  font-size: 15px;
  border: solid var(--border) 1px;
  border-radius: 4px;
  line-height: 15px;
  padding: 8px 10px;
  background: rgb(var(--background-rgb));
}

.main-chat-footer .main-msg-send {
  color: rgb(var(--background-rgb));
  font-size: 15px;
  border: solid var(--border) 1px;
  border-radius: 4px;
  padding: 5px 10px;
  background: var(--primary-bg-color);
}

.main-chat-footer .main-msg-send i {
  line-height: 1.5;
}

.chat-profile {
  padding: 8px;
  line-height: 1;
  color: var(--color);
}

.chat-profile i {
  font-size: 14px;
}

.chat-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.shared-files {
  padding: 12px;
  border-radius: 4px;
  font-size: 14px;
  border: 1px solid var(--border);
}

@media (min-width: 992px) {
  .main-content-app {
    height: calc(100vh - 250px);
    display: flex;
  }

  .main-chat-list {
    height: calc(100% - 68px);
    position: relative;
  }

  .main-chat-body {
    height: calc(100% - 105px);
  }

  .chat-main {
    overflow: hidden;
  }
}

.main-chat-list .chat-item {
  cursor: pointer;
}

.main-chat-list .chat-item:hover,
.main-chat-list .chat-item:focus,
.main-chat-list .chat-item.selected {
  background-color: var(--primary-bg-color);
  color: #000;
}

.main-content-left-chat .tabs-style2 .nav-tabs .nav-item+.nav-item {
  margin-block-end: 5px;
}

.main-content-app .dropdown-menu {
  z-index: 9;
}

.main-content-app .option-dots {
  position: relative;
  inset-inline-end: 15px;
  z-index: 9;
  margin: 0 auto;
}

/* ###### 7.4 Contacts  ###### */
@media (min-width: 768px) and (max-width: 991.98px) {
  .main-content-body-show .main-header-contacts .main-header-menu-icon {
    display: flex;
  }

  .main-content-body-show .main-content-left-contacts {
    display: block;
  }
}

.main-content-left-contacts {
  width: 100%;
  display: block;
  padding: 0;
}

.main-content-left-contacts .main-content-breadcrumb,
.main-content-left-contacts .main-content-title {
  padding-inline-start: 20px;
}

@media (min-width: 992px) {

  .main-content-left-contacts .main-content-breadcrumb,
  .main-content-left-contacts .main-content-title {
    padding-inline-start: 0;
  }
}

@media (min-width: 768px) {
  .main-contacts-list {
    height: 730px;
    position: relative;
  }
}

.main-contact-label {
  padding-block-start: 20px;
  padding-block-end: 10px;
  padding-inline-start: 20px;
  font-weight: 700;
  font-size: 15px;
  color: var(--primary-bg-color);
  position: relative;
  border-block-start: 1px solid var(--border);
  margin-inline-start: -10px;
}

.main-contact-label::after {
  content: "";
  position: absolute;
  inset-block-end: -1px;
  inset-inline-start: 0;
  inset-inline-end: 0;
  border-block-end: 1px solid var(--border);
  z-index: 5;
}

@media (min-width: 992px) {
  .main-contact-label {
    padding-inline-start: 30px;
  }
}

.main-contact-item {
  padding: 10px 20px;
  border: 1px solid transparent;
  border-inline-end-width: 0;
  display: flex;
  position: relative;
  cursor: pointer;
}

.main-contact-item+.main-contact-item {
  margin-block-start: -1px;
  border-block-start-color: var(--gray-200);
}

.main-contact-item+.main-contact-item::before {
  content: "";
  position: absolute;
  inset-block-start: -1px;
  inset-inline-start: 0px;
  inset-inline-end: 0;
  border-block-start: 1px solid var(--gray-200);
}

.main-contact-item:hover,
.main-contact-item:focus {
  background-color: var(--gray-100);
  border-block-start-color: var(--gray-200);
  border-block-end-color: var(--gray-200);
}

.main-contact-item.selected {
  z-index: 1;
  border-inline-start-color: var(--primary-bg-color);
  border-block-start-color: var(--gray-200);
  border-block-end-color: var(--gray-200);
  background-color: var(--gray-100);
}

@media (min-width: 992px) {
  .main-contact-item {
    padding: 15px 30px;
  }
}

.main-contact-body {
  flex: 1;
  margin-inline-start: 15px;
}

.main-contact-body h6 {
  color: var(--gray-600);
  font-weight: 500;
  margin-block-end: 0px;
}

.main-contact-body span {
  font-size: 13px;
  color: var(--gray-600);
}

.main-contact-star {
  display: block;
  font-size: 20px;
  color: var(--muted);
  position: relative;
  outline: none;
  margin: auto;
  text-align: center;
}

.main-contact-star a {
  color: var(--muted);
}

.main-contact-star:hover,
.main-contact-star:focus {
  color: var(--gray-600);
}

.main-contact-star.active {
  color: #ffcf86;
}

.main-content-body-contacts {
  display: none;
}

@media (min-width: 768px) {
  .main-content-body-contacts {
    display: block;
    overflow-y: auto;
  }
}

@media (min-width: 992px) {
  .main-content-body-contacts {
    overflow: visible;
  }
}

.main-contact-info-header {
  padding-block-start: 40px;
  padding-inline-start: 20px;
  padding-block-end: 20px;
  border-block-end: 1px solid var(--border);
  position: relative;
}

.main-contact-info-header .media {
  display: block;
}

.main-contact-info-header .main-img-user {
  width: 100px;
  height: 100px;
}

.main-contact-info-header .main-img-user a {
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--gray-600);
  color: rgb(var(--background-rgb));
  font-size: 18px;
  line-height: 0.9;
  box-shadow: 0 0 0 2px rgb(var(--background-rgb));
  border-radius: 100%;
}

.main-contact-info-header .main-img-user a:hover,
.main-contact-info-header .main-img-user a:focus {
  background-color: var(--primary-bg-color);
}

.main-contact-info-header .media-body {
  margin-block-start: 30px;
}

.main-contact-info-header .media-body h4 {
  color: var(--gray-900);
  font-size: 26px;
  font-weight: 500;
  margin-block-end: 8px;
  line-height: 1;
}

.main-contact-info-header .media-body p {
  color: var(--color);
  margin-block-end: 15px;
}

.main-contact-info-header .media-body .nav-link {
  padding: 0;
  display: flex;
  align-items: center;
  color: var(--gray-600);
  font-size: 13px;
}

.main-contact-info-header .media-body .nav-link i {
  font-size: 0.875rem;
  line-height: 0;
  margin-inline-end: 5px;
}

.main-contact-info-header .media-body .nav-link i.typcn {
  line-height: 0.9;
}

.main-contact-info-header .media-body .nav-link i.typcn::before {
  width: auto;
}

.main-contact-info-header .media-body .nav-link:hover,
.main-contact-info-header .media-body .nav-link:focus {
  color: var(--primary-bg-color);
}

.main-contact-info-header .media-body .nav-link+.nav-link {
  margin-inline-start: 10px;
}

@media (min-width: 576px) {
  .main-contact-info-header {
    padding-block-start: 20px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header {
    padding-inline-start: 30px;
    padding-block-end: 25px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header .media {
    display: flex;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .main-contact-info-header .media-body {
    margin-block-start: 0;
    margin-inline-start: 30px;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link {
    font-size: 0.875rem;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link i {
    margin-inline-end: 7px;
  }
}

@media (min-width: 576px) {
  .main-contact-info-header .media-body .nav-link+.nav-link {
    margin-inline-start: 25px;
  }
}

.main-contact-action {
  position: absolute;
  inset-block-start: 24px;
  inset-inline-end: 20px;
  display: flex;
}

.main-contact-action a {
  display: flex;
  align-items: center;
  color: var(--gray-600);
  font-size: 12px;
}

.main-contact-action a:hover,
.main-contact-action a:focus {
  color: var(--primary-bg-color);
}

.main-contact-action a i {
  font-size: 15px;
  line-height: 0;
}

@media (min-width: 992px) {
  .main-contact-action {
    inset-inline-end: 20px;
  }
}

.main-contact-info-body {
  padding-inline-start: 20px;
}

.main-contact-info-body .media-list {
  padding: 20px 0;
}

.main-contact-info-body .media {
  align-items: center;
  position: relative;
}

.main-contact-info-body .media+.media {
  margin-block-start: 30px;
}

.main-contact-info-body .media+.media::before {
  content: "";
  position: absolute;
  inset-block-start: -15px;
  inset-inline-start: -19px;
  inset-inline-end: 0;
  border-block-start: 1px dotted var(--gray-300);
}

.main-contact-info-body .media:last-child {
  margin-block-end: 15px;
}

.main-contact-info-body .media-icon {
  font-size: 32px;
  color: var(--gray-500);
  line-height: 1;
  width: 40px;
  text-align: center;
}

.main-contact-info-body .media-body {
  margin-inline-start: 0;
}

.main-contact-info-body .media-body>div+div {
  margin-block-start: 15px;
}

.main-contact-info-body .media-body label {
  color: var(--gray-600);
  margin-block-end: 0;
  display: block;
  font-size: 13px;
}

.main-contact-info-body .media-body span {
  font-size: 12px;
  display: block;
  font-weight: 400;
}

@media (min-width: 992px) {
  .main-contact-info-body {
    height: calc(100% - 126px);
    position: relative;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-list {
    padding: 25px 0;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-icon {
    width: 100px;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body {
    display: flex;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body>div {
    flex: 1;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body .media-body>div+div {
    margin-block-start: 0;
  }
}

@media (min-width: 992px) {
  .main-contact-info-body {
    height: calc(100% - 126px);
    position: relative;
  }
}

.main-contact-info-header .main-img-user img {
  width: 100px !important;
  height: 100px !important;
}

@media (min-width: 769px) and (max-width: 991px) {
  .main-contacts-list {
    height: 627px;
    position: relative;
  }
}

@media (max-width: 768px) {
  .main-contacts-list {
    height: 600px;
    position: relative;
  }
}

.main-contact-item .main-img-user::after {
  content: "";
  position: absolute;
  inset-block-end: 0;
  inset-inline-end: 0;
  width: 6px;
  height: 6px;
  background-color: #17b86a;
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
  border-radius: 100%;
  inset-block-end: 3px;
  display: block !important;
}

.main-content-body-contacts .main-contact-info-header .main-img-user::after {
  display: none !important;
}

.contact-icon {
  display: inline-flex;
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 1rem;
  align-items: center;
  justify-content: center;
  margin-inline-start: 3px;
  border-radius: 4px;
}

.main-contact-star .dropdown-menu.dropdown-menu-right {
  inset-block-start: -20px;
  box-shadow: 0px 0px 10px 0px #e5edff;
}

#mainContactList .tab-content {
  padding: 1.5rem;
}

/* ###### 7.11 Error  ###### */
.main-error-wrapper h1 {
  line-height: 1;
  font-size: 105px;
  font-weight: 700;
  color: var(--gray-900);
}

.main-error-wrapper h2 {
  font-weight: 500;
  color: var(--gray-800);
  letter-spacing: -0.5px;
  margin-block-end: 15px;
  margin-block-start: 20px;
}

.main-error-wrapper h6 {
  margin-block-end: 40px;
  font-size: 14px;
}

.main-error-wrapper .btn,
.main-error-wrapper .sp-container button {
  border-width: 2px;
  font-weight: 500;
}

.sp-container .main-error-wrapper button {
  border-width: 2px;
  font-weight: 700;
}

/* ###### 7.9 Signin  ###### */
.main-card-signin {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(var(--background-rgb));
  position: relative;
}

@media (max-width: 991px) {
  .main-card-signin {
    display: block !important;
    padding: 0;
  }

  .app-sidebar {
    z-index: 1029 !important;
    inset-block-start: 64px !important;
  }

  .main-nav-line-chat {
    padding: 20px !important;
  }
}

@media (min-width: 480px) {
  .main-card-signin {
    padding: 0;
    max-width: 750px;
  }

  .main-card-signin.construction {
    max-width: 1000px;
  }
}

.main-signin-header h2 {
  font-weight: 500;
  color: var(--primary-bg-color);
  letter-spacing: -1px;
}

.main-signin-header h4 {
  font-weight: 500;
  color: #14112d;
  margin-block-end: 25px;
  font-size: 16px;
}

.main-signin-header label {
  color: #a5a0b1;
}

.main-signin-header .form-control {
  color: #14112d;
  font-weight: 500;
  border-width: 1px;
  border-color: #e3e3e3;
}

.main-signin-header .form-control:focus {
  border-color: var(--gray-400);
  box-shadow: none;
}

.main-signin-header .form-control::placeholder {
  font-weight: 400;
  color: #a5a0b1;
}

.main-card-signin {
  box-shadow: none;
}

.main-signin-header .btn,
.main-signin-header .sp-container button {
  margin-block-start: 25px;
}

.sp-container .main-signin-header button {
  margin-block-start: 25px;
}

.main-signin-footer p {
  color: #a5a0b1;
}

.main-signin-footer p:first-child {
  margin-block-end: 5px;
}

.main-signin-footer p:last-child {
  margin-block-end: 0;
}

.main-signin-footer a {
  color: #14112d;
  font-weight: 700;
}

.main-signin-footer a:hover,
.main-signin-footer a:focus {
  color: var(--primary-bg-color);
}

/* ###### 7.10 Signup  ###### */
.main-signup-header h2 {
  font-weight: 500;
  color: var(--primary-bg-color) !important;
  letter-spacing: -1px;
}

.main-signup-header h4 {
  font-size: 20px;
  font-weight: 400;
  color: #14112d;
  margin-block-end: 25px;
}

.main-signup-header label {
  color: #212529;
}

.main-signup-header .form-control {
  color: #14112d;
  font-weight: 500;
  border-width: 1px;
  border-color: #e3e3e3;
}

.main-signup-header .form-control:focus {
  border-color: var(--gray-400);
  box-shadow: none;
}

.main-signup-header .form-control::placeholder {
  font-weight: 400;
  color: #a5a0b1;
}

.main-signup-header>.btn {
  margin-block-start: 25px;
}

.sp-container .main-signup-header>button {
  margin-block-start: 25px;
}

.main-signup-header .row {
  margin-block-start: 20px;
}

.main-signup-header .row>div .btn i,
.main-signup-header .row>div .sp-container button i {
  font-size: 15px;
  line-height: 0;
  margin-inline-end: 5px;
}

.sp-container .main-signup-header .row>div button i {
  font-size: 15px;
  line-height: 0;
  margin-inline-end: 5px;
}

.main-signup-header .row>div:first-child .btn,
.main-signup-header .row>div:first-child .sp-container button {
  background-color: #19c0c0;
  color: rgb(var(--background-rgb));
}

.sp-container .main-signup-header .row>div:first-child button {
  background-color: #4267b2;
  color: rgb(var(--background-rgb));
}

.main-signup-header .row>div:first-child .btn:hover,
.main-signup-header .row>div:first-child .sp-container button:hover {
  background-color: #1bd0d0;
  color: rgb(var(--background-rgb));
}

.sp-container .main-signup-header .row>div:first-child button:hover {
  background-color: #1bd0d0;
  color: rgb(var(--background-rgb));
}

.main-signup-header .row>div:first-child .btn:focus,
.main-signup-header .row>div:first-child .sp-container button:focus {
  background-color: #1bd0d0;
  color: rgb(var(--background-rgb));
}

.sp-container .main-signup-header .row>div:first-child button:focus {
  background-color: #1bd0d0;
  color: rgb(var(--background-rgb));
}

.main-signup-footer p {
  color: #a5a0b1;
  margin-block-end: 0;
}

.main-signup-footer a {
  color: #14112d;
  font-weight: 700;
}

.main-signup-footer a:hover,
.main-signup-footer a:focus {
  color: var(--primary-bg-color);
}

.login,
.image {
  min-height: 100vh;
}

.construction .form-control {
  height: 39px;
  padding: 0.375rem 1rem;
  border-radius: 4px;
  width: 100%;
}

.construction .btn.btn-icon {
  border: 0;
  text-align: center;
  padding: 0;
  background: 0 0;
  font-size: 20px;
  color: var(--black-9);
  margin: 0 5px;
  border-radius: 3px;
}

.construction .input-group-prepend,
.construction .input-group-append {
  position: absolute;
  inset-inline-end: 0;
}

.construction .input-group>.input-group-append>.btn {
  border-start-start-radius: 0;
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
  border-end-start-radius: 0;
  margin: 0;
  padding: 0 25px;
  height: 38px;
  line-height: 39px;
  float: var(--float-end);
  border: 0;
  font-size: 14px;
  color: rgb(var(--background-rgb));
  box-shadow: none;
  position: relative;
  overflow: hidden;
  z-index: 99;
}

.construction .input-group>.form-control:not(:last-child),
.construction .input-group>.custom-select:not(:last-child) {
  border-start-end-radius: 4px;
  border-end-end-radius: 4px;
}

@media (max-width: 991px) {
  .error-page {
    height: 70%;
    width: 70%;
  }
}

@media (min-width: 992px) {
  .main-card-signin {
    border: 0 !important;
    box-shadow: none !important;
  }

  .main-signin-header {
    box-shadow: none !important;
  }

  .error-page {
    height: 30%;
    width: 30%;
  }
}

@media (max-width: 400px) {
  .main-error-wrapper .tx-130 {
    font-size: 95px;
  }
}

.divider {
  border-block-start: 1px solid var(--border);
  position: relative;
}

.divider::before {
  content: "";
  position: absolute;
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
  background: rgb(var(--background-rgb));
  padding: 0px 10px;
}

.divider.or::before {
  content: "or";
}

.bg-svg {
  position: relative;
  overflow-x: hidden;
}

.bg-svg::before {
  content: "";
  position: absolute;
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/error_bg-1.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.35;
}

.bg-svg.svg-2::before {
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/error_bg-2.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-svg.svg-3::before {
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/lockscreen_bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.121;
}

.bg-svg.svg-4::before {
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/underconstruct_bg.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.15;
}

.bg-svg.svg-5::before {
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/error_bg-3.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.2;
}

/* ###### 7.5 Invoice  ###### */
.table-invoice th:not(:last-child),
.table-invoice td:not(:last-child) {
  border-inline-end: 1px solid var(--border);
}

.table-invoice tbody>tr>th:first-child,
.table-invoice tbody>tr>td:first-child {
  color: var(--gray-900);
  font-weight: 500;
}

.table-invoice tbody>tr>th:first-child .invoice-notes p,
.table-invoice tbody>tr>td:first-child .invoice-notes p {
  font-size: 13px;
  font-weight: 400;
  color: var(--color);
}

.invoice-highlight {
  overflow: hidden;
  border-radius: 4px;
  background: var(--primary-bg-color);
  color: #000;
  position: relative;
}

.invoice-highlight::before {
  content: "";
  position: absolute;
  background: url(https://www.spruko.com/demo/zem/Zem/assets/img/svgs/inv_bg-1.svg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: 0.145;
}

.main-mail-group>.list-group-item {
  border: none;
  border-block-end: 1px dashed var(--border);
}

.main-mail-group>.list-group-item:hover,
.main-mail-group>.list-group-item:focus,
.main-mail-group>.list-group-item.active,
.main-mail-group>.list-group-item.selected {
  background-color: var(--bg-hover) !important;
  border-color: var(--border) !important;
}

.main-mail-group>.list-group-item:last-child {
  border-block-end: none;
}

.main-mail-star {
  font-size: 18px;
  line-height: 0.9;
  color: rgb(var(--background-rgb));
  position: relative;
  inset-block-start: -11px;
}

.main-mail-star.active {
  color: #ffcf86;
}

.main-content-mail .list-group-item {
  padding: 0.75rem 1.25rem;
}

/* UTILITIES-STYLES */
.bg-primary {
  background-color: var(--primary-bg-color) !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: var(--primary-bg-color) !important;
}

.bg-transparent {
  background: transparent !important;
}

button.bg-primary:hover,
button.bg-primary:focus {
  background-color: var(--primary-bg-color) !important;
}

.bg-secondary {
  background-color: var(--secondary) !important;
  color: #000 !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
  background-color: var(--secondary) !important;
}

button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: var(--secondary) !important;
}

.bg-success {
  background-color: #43ce85 !important;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #43ce85 !important;
}

button.bg-success:hover,
button.bg-success:focus {
  background-color: #43ce85 !important;
}

.bg-info {
  background-color: #4ec2f0 !important;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #4ec2f0 !important;
}

button.bg-info:hover,
button.bg-info:focus {
  background-color: #4ec2f0 !important;
}

.bg-warning {
  background-color: #ffcf86 !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #ffcf86 !important;
}

button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #ffcf86 !important;
}

.bg-danger {
  background-color: #d6573c !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #d6573c !important;
}

button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #d6573c !important;
}

.bg-purple {
  background-color: #7891ef !important;
}

a.bg-purple:hover,
a.bg-purple:focus {
  background-color: #7891ef !important;
}

button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #7891ef !important;
}

.bg-light {
  background-color: var(--bg-hover) !important;
}

a.bg-light:hover,
a.bg-light:focus {
  background-color: var(--bg-hover) !important;
}

button.bg-light:hover,
button.bg-light:focus {
  background-color: var(--bg-hover) !important;
}

.bg-dark {
  background-color: var(--dark) !important;
}

a.bg-dark:hover,
a.bg-dark:focus {
  background-color: var(--dark) !important;
}

button.bg-dark:hover,
button.bg-dark:focus {
  background-color: var(--dark) !important;
}

.bg-white {
  background-color: rgb(var(--background-rgb)) !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gray+.select2-container--default .select2-selection--single {
  background-color: var(--gray-900);
}

.bg-gray+.select2-container--default .select2-selection--single .select2-selection__rendered {
  color: var(--gray-300);
}

/* ###### 9.1 Background  ###### */
.bg-transparent {
  background-color: transparent;
}

.bg-gray-100 {
  background-color: var(--gray-100) !important;
}

.bg-gray-200 {
  background-color: var(--gray-200) !important;
}

.bg-gray-300 {
  background-color: var(--gray-300) !important;
}

.bg-gray-400 {
  background-color: var(--gray-400) !important;
}

.bg-gray-500 {
  background-color: var(--gray-500) !important;
}

.bg-gray-600 {
  background-color: var(--gray-600) !important;
}

.bg-gray-700 {
  background-color: var(--gray-700) !important;
}

.bg-gray-800 {
  background-color: var(--gray-800) !important;
}

.bg-gray-900 {
  background-color: var(--gray-900) !important;
}

.bg-white-1 {
  background-color: var(--black-1);
}

.bg-white-2 {
  background-color: var(--black-2);
}

.bg-white-3 {
  background-color: var(--black-3);
}

.bg-white-4 {
  background-color: var(--black-4);
}

.bg-white-5 {
  background-color: var(--black-5);
}

.bg-white-6 {
  background-color: var(--black-6);
}

.bg-white-7 {
  background-color: var(--black-7);
}

.bg-white-8 {
  background-color: var(--black-8);
}

.bg-white-9 {
  background-color: var(--black-9);
}

.bg-black-1 {
  background-color: var(--white-1);
}

.bg-black-2 {
  background-color: var(--white-2);
}

.bg-black-3 {
  background-color: var(--white-3);
}

.bg-black-4 {
  background-color: var(--white-4);
}

.bg-black-5 {
  background-color: var(--white-5);
}

.bg-black-6 {
  background-color: var(--white-6);
}

.bg-black-7 {
  background-color: var(--white-7);
}

.bg-black-8 {
  background-color: var(--white-8);
}

.bg-black-9 {
  background: var(--white-9);
}

.bg-black-03 {
  background: var(--black-03) !important;
}

.bg-indigo {
  background: #5b67c7;
}

.bg-pink {
  background: #f754fa !important;
}

.bg-orange {
  background: #ffb67b;
}

.bg-teal {
  background: #4ac9bd !important;
}

.bg-purple-dark {
  background: #7891ef;
}

.bg-transparent {
  background: transparent;
}

/* ###### 4.8 transparent colors ###### */
.bg-success-transparent {
  background-color: rgba(67, 206, 133, 0.2) !important;
}

.bg-danger-transparent {
  background-color: rgba(214, 87, 60, 0.2) !important;
}

.bg-green-transparent {
  background-color: rgba(45, 206, 137, 0.2) !important;
}

.bg-primary-transparent {
  background: var(--primary02) !important;
}

.bg-secondary-transparent {
  background-color: var(--secondary-02) !important;
}

.bg-white-transparent {
  background-color: var(--white-2) !important;
}

.bg-warning-transparent {
  background-color: rgba(255, 207, 134, 0.2) !important;
}

.bg-pink-transparent {
  background-color: rgba(255, 192, 203, 0.2) !important;
}

.bg-purple-transparent {
  background-color: rgba(120, 145, 239, 0.28) !important;
}

.bg-teal-transparent {
  background-color: rgba(74, 201, 189, 0.2) !important;
}

.bg-info-transparent {
  background-color: rgba(78, 194, 240, 0.2) !important;
}

.bg-orange-transparent {
  background-color: rgba(255, 182, 123, 0.2);
}

.bg-primary {
  background-color: var(--primary-bg-color) !important;
  color: #000 !important;
}

.bg-primary-gradient {
  background-image: linear-gradient(to right,
      var(--primary-bg-color) 0%,
      #43ce85 100%) !important;
}

.bg-danger-gradient {
  background-image: linear-gradient(to right,
      #d6573c 0%,
      rgba(214, 87, 60, 0.5) 100%) !important;
}

.bg-success-gradient {
  background-image: linear-gradient(to right,
      #43ce85 0%,
      rgba(67, 206, 133, 0.5) 100%) !important;
}

.bg-warning-gradient {
  background-image: linear-gradient(to right,
      #ffcf86 0%,
      rgba(255, 207, 134, 0.5) 100%) !important;
}

.bg-primary-gradient1 {
  background-image: linear-gradient(to top,
      var(--primary05) 0%,
      var(--primary-bg-color) 100%) !important;
}

.bg-info-gradient1 {
  background-image: linear-gradient(to top,
      var(--primary-bg-color) 0%,
      #4ec2f0 100%) !important;
}

.bg-info-gradient {
  background-image: linear-gradient(to right,
      #4ec2f0 0%,
      rgba(78, 194, 240, 0.5) 100%) !important;
}

.bg-secondary-gradient {
  background-image: linear-gradient(to right,
      var(--secondary) 0%,
      var(--secondary-05) 100%) !important;
}

.bg-pink-gradient {
  background-image: linear-gradient(to right,
      #f754fa 0%,
      #d6573c 100%) !important;
}

.bg-purple-gradient {
  background-image: linear-gradient(to right,
      #7891ef 0%,
      #f754fa 100%) !important;
}

.bg-orange-gradient {
  background-image: linear-gradient(to right,
      #ffb67b 0%,
      rgba(255, 182, 123, 0.5) 100%) !important;
}

.bg-teal-gradient {
  background-image: linear-gradient(to right,
      #4ac9bd 0%,
      #4ec2f0 100%) !important;
}

.bg-azure {
  background-color: var(--primary-bg-color) !important;
}

.bg-red {
  background-color: #d6573c !important;
  color: rgb(var(--background-rgb)) !important;
}

.bg-yellow {
  background-color: #ffcf86 !important;
  color: rgb(var(--background-rgb)) !important;
}

.bg-lime {
  background-color: #4ec2f0 !important;
}

.bg-green {
  background-color: #43ce85 !important;
  color: rgb(var(--background-rgb)) !important;
}

.gutters-xs>.col,
.gutters-xs>[class*="col-"] {
  padding-inline-end: 0.25rem;
  padding-inline-start: 0.25rem;
}

.gutters-xs {
  margin-block-start: -25px;
}

.bg-chartcustom {
  background-color: rgb(var(--background-rgb));
}

.bg-custom-green {
  background-color: #43ce85;
}

.bg-custom {
  background: var(--bg-white) !important;
}

.bg-white-100 {
  background-color: var(--black-1) !important;
}

.form-border {
  border: 1px solid var(--form-border) !important;
}

.border {
  border: 1px solid var(--border) !important;
}

.border-top {
  border-block-start: 1px solid var(--border) !important;
}

.border-end {
  border-inline-end: 1px solid var(--border) !important;
}

.border-bottom {
  border-block-end: 1px solid var(--border) !important;
}

.border-start {
  border-inline-start: 1px solid var(--border) !important;
}

.border-top-dashed {
  border-block-start: 1px dashed var(--border) !important;
}

.border-end-dashed {
  border-inline-end: 1px dashed var(--border) !important;
}

.border-bottom-dashed {
  border-block-end: 1px dashed var(--border) !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-start-dashed {
  border-inline-start: 1px dashed var(--border) !important;
}

.border-top-dotted {
  border-block-start: 1px dotted var(--border) !important;
}

.border-end-dotted {
  border-inline-end: 1px dotted var(--border) !important;
}

.border-bottom-dotted {
  border-block-end: 1px dotted var(--border) !important;
}

.border-start-dotted {
  border-inline-start: 1px dotted var(--border) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-block-start: 0 !important;
}

.border-end-0 {
  border-inline-end: 0 !important;
}

.border-bottom-0 {
  border-block-end: 0 !important;
}

.border-start-0 {
  border-inline-start: 0 !important;
}

.border-primary {
  border-color: var(--primary-bg-color) !important;
}

.border-secondary {
  border-color: var(--secondary) !important;
}

.border-success {
  border-color: #43ce85 !important;
}

.border-info {
  border-color: #4ec2f0 !important;
}

.border-warning {
  border-color: #ffcf86 !important;
}

.border-danger {
  border-color: #d6573c !important;
}

.border-light {
  border-color: var(--gray-200) !important;
}

.border-dark {
  border-color: var(--gray-800) !important;
}

.border-white {
  border-color: rgb(var(--background-rgb)) !important;
}

.border-orange {
  border-color: #ffb67b !important;
}

.border-pink {
  border-color: #f754fa !important;
}

.border-teal {
  border-color: #4ac9bd !important;
}

.border-purple {
  border-color: #7891ef !important;
}

.border-indigo {
  border-color: #5b67c7 !important;
}

.border-black {
  border-color: var(--black) !important;
}

.border-yellow {
  border-color: var(--yellow) !important;
}

.border-default {
  border-color: var(--border) !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 10px !important;
}

.rounded-top {
  border-start-start-radius: 4px !important;
  border-start-end-radius: 4px !important;
}

.rounded-end {
  border-start-end-radius: 4px !important;
  border-end-end-radius: 4px !important;
}

.rounded-bottom {
  border-end-end-radius: 4px !important;
  border-end-start-radius: 4px !important;
}

.rounded-start {
  border-start-start-radius: 4px !important;
  border-end-start-radius: 4px !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

/* ###### 9.2 Border   ###### */
.bd-dashed {
  border-style: dashed !important;
}

.bd-t-dashed {
  border-block-start-style: dashed !important;
}

.bd-e-dashed {
  border-inline-end-style: dashed !important;
}

.bd-b-dashed {
  border-block-end-style: dashed !important;
}

.bd-s-dashed {
  border-inline-start-style: dashed !important;
}

.bd-transparent {
  border-color: transparent;
}

.bd {
  border: 1px solid var(--border);
}

.bd-t {
  border-block-start: 1px solid var(--border);
}

.bd-e {
  border-inline-end: 1px solid var(--border);
}

.bd-s {
  border-inline-start: 1px solid var(--border);
}

.bd-b {
  border-block-end: 1px solid var(--border);
}

.bd-l {
  border-inline-start: 1px solid var(--border);
}

.bd-y {
  border-block-start: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
}

.bd-x {
  border-inline-start: 1px solid var(--border);
  border-inline-end: 1px solid var(--border);
}

.bd-0 {
  border-width: 0;
}

.bd-1 {
  border-width: 1px;
}

.bd-2 {
  border-width: 2px;
}

.bd-3 {
  border-width: 3px;
}

.bd-4 {
  border-width: 4px;
}

.bd-5 {
  border-width: 5px;
}

.bd-t-0 {
  border-block-start: 0 !important;
}

.bd-e-0 {
  border-inline-end: 0;
}

.bd-b-0 {
  border-block-end: 0 !important;
}

.bd-s-0 {
  border-inline-start: 0;
}

.bd-y-0 {
  border-block-start-width: 0;
  border-block-end-width: 0;
}

.bd-y-1 {
  border-block-start-width: 1px;
  border-block-end-width: 1px;
}

.bd-x-0 {
  border-inline-start-width: 0;
  border-inline-end-width: 0;
}

.bd-x-1 {
  border-inline-start-width: 1px;
  border-inline-end-width: 1px;
}

.bd-primary {
  border-color: var(--primary-bg-color) !important;
}

.bd-success {
  border-color: #43ce85 !important;
}

.bd-warning {
  border-color: #ffcf86 !important;
}

.bd-danger {
  border-color: #d6573c !important;
}

.bd-info {
  border-color: #4ec2f0 !important;
}

.bd-secondary {
  border-color: var(--secondary) !important;
}

.bd-teal {
  border-color: #4ac9bd !important;
}

.bd-gray-100 {
  border-color: var(--gray-100);
}

.bd-gray-200 {
  border-color: var(--gray-200);
}

.bd-gray-300 {
  border-color: var(--gray-300);
}

.bd-gray-400 {
  border-color: var(--gray-400);
}

.bd-gray-500 {
  border-color: var(--gray-500);
}

.bd-gray-600 {
  border-color: var(--gray-600);
}

.bd-gray-700 {
  border-color: var(--gray-700);
}

.bd-gray-800 {
  border-color: var(--gray-800);
}

.bd-gray-900 {
  border-color: var(--gray-900);
}

.radius-0 {
  border-radius: 0 !important;
}

.radius-1 {
  border-radius: 1px !important;
}

.radius-3 {
  border-radius: 3px !important;
}

.radius-4 {
  border-radius: 4px !important;
}

.radius-5 {
  border-radius: 5px !important;
}

.radius-7 {
  border-radius: 7px !important;
}

.radius-10 {
  border-radius: 10px !important;
}

.radius-20 {
  border-radius: 20px !important;
}

.radius-30 {
  border-radius: 30px !important;
}

.radius-40 {
  border-radius: 40px !important;
}

.radius-50 {
  border-radius: 50px !important;
}

.bd-dashed {
  border-style: dashed;
}

.bd-dotted {
  border-style: dotted;
}

@media (min-width: 480px) {
  .bd-xs {
    border: 1px solid var(--border);
  }

  .bd-xs-t {
    border-block-start: 1px solid var(--border);
  }

  .bd-xs-e {
    border-inline-end: 1px solid var(--border);
  }

  .bd-xs-b {
    border-block-end: 1px solid var(--border);
  }

  .bd-xs-s {
    border-inline-start: 1px solid var(--border);
  }

  .bd-xs-y {
    border-block-start: 1px solid var(--border);
    border-block-end: 1px solid var(--border);
  }

  .bd-xs-x {
    border-inline-start: 1px solid var(--border);
    border-inline-end: 1px solid var(--border);
  }
}

@media (min-width: 576px) {
  .bd-sm {
    border: 1px solid var(--border);
  }

  .bd-sm-0 {
    border: none;
    border-style: none !important;
  }

  .bd-sm-t {
    border-block-start: 1px solid var(--border);
  }

  .bd-sm-t-0 {
    border-block-start: none;
    border-block-start-style: none !important;
  }

  .bd-sm-e {
    border-inline-end: 1px solid var(--border);
  }

  .bd-sm-e-0 {
    border-inline-end: none;
    border-inline-end-style: none !important;
  }

  .bd-sm-b {
    border-block-end: 1px solid var(--border);
  }

  .bd-sm-b-0 {
    border-block-end: none;
    border-block-end-style: none !important;
  }

  .bd-sm-s {
    border-inline-start: 1px solid var(--border);
  }

  .bd-sm-s-0 {
    border-inline-start: none;
    border-inline-start-style: none !important;
  }

  .bd-sm-y {
    border-block-start: 1px solid var(--border);
    border-block-end: 1px solid var(--border);
  }

  .bd-sm-y-0 {
    border-block-start: none;
    border-block-start-style: none !important;
    border-block-end: none;
    border-block-end-style: none !important;
  }

  .bd-sm-x {
    border-inline-start: 1px solid var(--border);
    border-inline-end: 1px solid var(--border);
  }

  .bd-sm-x-0 {
    border-inline-start: none;
    border-inline-start-style: none !important;
    border-inline-end: none;
    border-inline-end-style: none !important;
  }
}

@media (min-width: 768px) {
  .bd-md {
    border: 1px solid var(--border);
  }

  .bd-md-0 {
    border: none;
    border-style: none !important;
  }

  .bd-md-t {
    border-block-start: 1px solid var(--border);
  }

  .bd-md-t-0 {
    border-block-start: none;
    border-block-start-style: none !important;
  }

  .bd-md-e {
    border-inline-end: 1px solid var(--border);
  }

  .bd-md-e-0 {
    border-inline-end: none;
    border-inline-end-style: none !important;
  }

  .bd-md-b {
    border-block-end: 1px solid var(--border);
  }

  .bd-md-b-0 {
    border-block-end: none;
    border-block-end-style: none !important;
  }

  .bd-md-s {
    border-inline-start: 1px solid var(--border);
  }

  .bd-md-s-0 {
    border-inline-start: none;
    border-inline-start-style: none !important;
  }

  .bd-md-y {
    border-block-start: 1px solid var(--border);
    border-block-end: 1px solid var(--border);
  }

  .bd-md-y-0 {
    border-block-start: none;
    border-block-start-style: none !important;
    border-block-end: none;
    border-block-end-style: none !important;
  }

  .bd-md-x {
    border-inline-start: 1px solid var(--border);
    border-inline-end: 1px solid var(--border);
  }

  .bd-md-x-0 {
    border-inline-start: none;
    border-inline-start-style: none !important;
    border-inline-end: none;
    border-inline-end-style: none !important;
  }
}

@media (min-width: 992px) {
  .bd-lg {
    border: 1px solid var(--border);
  }

  .bd-lg-t {
    border-block-start: 1px solid var(--border);
  }

  .bd-lg-enf {
    border-inline-end: 1px solid var(--border);
  }

  .bd-lg-b {
    border-block-end: 1px solid var(--border);
  }

  .bd-lg-b-0 {
    border-block-end: none !important;
  }

  .bd-lg-s {
    border-inline-start: 1px solid var(--border);
  }

  .bd-lg-y {
    border-block-start: 1px solid var(--border);
    border-block-end: 1px solid var(--border);
  }

  .bd-lg-x {
    border-inline-start: 1px solid var(--border);
    border-inline-end: 1px solid var(--border);
  }
}

@media (min-width: 1200px) {
  .bd-xl {
    border: 1px solid var(--border);
  }

  .bd-xl-t {
    border-block-start: 1px solid var(--border);
  }

  .bd-xl-e {
    border-inline-end: 1px solid var(--border);
  }

  .bd-xl-b {
    border-block-end: 1px solid var(--border);
  }

  .bd-xl-s {
    border-inline-start: 1px solid var(--border);
  }

  .bd-xl-y {
    border-block-start: 1px solid var(--border);
    border-block-end: 1px solid var(--border);
  }

  .bd-xl-x {
    border-inline-start: 1px solid var(--border);
    border-inline-end: 1px solid var(--border);
  }
}

.br-ts-0 {
  border-start-start-radius: 0 !important;
}

.br-bs-0 {
  border-end-start-radius: 0 !important;
}

.br-ts-4 {
  border-start-start-radius: 4px !important;
}

.br-te-4 {
  border-start-end-radius: 4px !important;
}

.br-bs-4 {
  border-end-start-radius: 4px !important;
}

.br-be-4 {
  border-end-end-radius: 4px !important;
}

.br-ts-10 {
  border-start-start-radius: 10px !important;
}

.br-te-10 {
  border-start-end-radius: 10px !important;
}

.br-bs-10 {
  border-end-start-radius: 10px !important;
}

.br-be-10 {
  border-end-end-radius: 10px !important;
}

.br-bs-20 {
  border-end-start-radius: 20px !important;
}

.br-te-0 {
  border-start-end-radius: 0 !important;
}

.br-te-20 {
  border-start-end-radius: 20px !important;
}

.br-te-35 {
  border-start-end-radius: 35px !important;
}

.br-be-0 {
  border-end-end-radius: 0 !important;
}

.br-be-20 {
  border-end-end-radius: 20px !important;
}

.br-ts-5 {
  border-start-start-radius: 5px !important;
}

.br-ts-20 {
  border-start-start-radius: 20px !important;
}

.br-te-5 {
  border-start-end-radius: 5px !important;
}

.br-be-5 {
  border-end-end-radius: 5px !important;
}

.br-bs-5 {
  border-end-start-radius: 5px !important;
}

.border-start-primary {
  border-inline-start-color: var(--primary-bg-color) !important;
}

.border-start-success {
  border-inline-start-color: #43ce85 !important;
}

.border-start-warning {
  border-inline-start-color: #ffcf86 !important;
}

.border-primary-light {
  border: 1px solid var(--primary02);
}

.border-pink-light {
  border: 1px solid #f3a7cc;
}

.border-secondary-light {
  border: 1px solid #f1cbd3;
}

.br-0 {
  border-radius: 0 !important;
}

.br-5 {
  border-radius: 5px !important;
}

.br-7 {
  border-radius: 7px !important;
}

@media (max-width: 480px) {
  .bd-xs-e-0 {
    border-inline-end: 0 !important;
  }

  .bd-xs-s-0 {
    border-inline-start: 0 !important;
  }
}

@media (max-width: 991px) {
  .bd-md-e-0 {
    border-inline-end: 0 !important;
  }
}

@media (max-width: 1024px) {
  .bd-lg-e-0 {
    border-inline-end: 0 !important;
  }
}

@media (max-width: 1366px) {
  .bd-xl-e-0 {
    border-inline-end: 0 !important;
  }
}

.border-primary-end {
  border-inline-end: 1px solid var(--primary01) !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

/* ###### 9.3 Height   ###### */
.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-5 {
  height: 5px;
}

.ht-4 {
  height: 4px;
}

.ht-5p {
  height: 5%;
}

.ht-10 {
  height: 10px;
}

.ht-10p {
  height: 10%;
}

.ht-15 {
  height: 15px;
}

.ht-15p {
  height: 15%;
}

.ht-20 {
  height: 20px;
}

.ht-20p {
  height: 20%;
}

.ht-25 {
  height: 25px;
}

.ht-25p {
  height: 25%;
}

.ht-30 {
  height: 30px;
}

.ht-30p {
  height: 30%;
}

.ht-35 {
  height: 35px;
}

.ht-35p {
  height: 35%;
}

.ht-40 {
  height: 40px;
}

.ht-40p {
  height: 40%;
}

.ht-45 {
  height: 45px;
}

.ht-45p {
  height: 45%;
}

.ht-50 {
  height: 50px;
}

.ht-50p {
  height: 50%;
}

.ht-55 {
  height: 55px;
}

.ht-55p {
  height: 55%;
}

.ht-60 {
  height: 60px;
}

.ht-60p {
  height: 60%;
}

.ht-65 {
  height: 65px;
}

.ht-65p {
  height: 65%;
}

.ht-70 {
  height: 70px;
}

.ht-70p {
  height: 70%;
}

.ht-75 {
  height: 75px;
}

.ht-75p {
  height: 75%;
}

.ht-80 {
  height: 80px;
}

.ht-80p {
  height: 80%;
}

.ht-85 {
  height: 85px;
}

.ht-85p {
  height: 85%;
}

.ht-90 {
  height: 90px;
}

.ht-90p {
  height: 90%;
}

.ht-95 {
  height: 95px;
}

.ht-95p {
  height: 95%;
}

.ht-100 {
  height: 100px !important;
}

.ht-100p {
  height: 100%;
}

.ht-136 {
  height: 136px;
}

.ht-150 {
  height: 150px;
}

.ht-175 {
  height: 175px;
}

.ht-180 {
  height: 180px;
}

.ht-200 {
  height: 200px;
}

.ht-210 {
  height: 210px;
}

.ht-220 {
  height: 220px;
}

.ht-226 {
  height: 226px;
}

.ht-230 {
  height: 230px !important;
}

.ht-238 {
  height: 238px !important;
}

.ht-245 {
  height: 245px;
}

.ht-250 {
  height: 250px;
}

.ht-270 {
  height: 270px;
}

.ht-300 {
  height: 300px;
}

.ht-350 {
  height: 350px;
}

.ht-400 {
  height: 400px;
}

.ht-450 {
  height: 450px;
}

.ht-500 {
  height: 500px;
}

.ht-550 {
  height: 550px;
}

.ht-600 {
  height: 600px;
}

.ht-650 {
  height: 650px;
}

.ht-700 {
  height: 700px;
}

.ht-750 {
  height: 750px;
}

.ht-800 {
  height: 800px;
}

.ht-100v {
  height: 100vh;
}

.ht-1 {
  height: 1px;
}

.ht-2 {
  height: 2px;
}

.ht-3 {
  height: 3px;
}

.ht-4 {
  height: 4px;
}

.ht-6 {
  height: 6px;
}

.ht-7 {
  height: 7px;
}

.ht-8 {
  height: 8px;
}

.ht-9 {
  height: 9px;
}

.ht-auto {
  height: auto;
}

.ht-16 {
  height: 16px;
}

.ht-32 {
  height: 32px;
}

.ht-36 {
  height: 36px;
}

.ht-48 {
  height: 48px;
}

.ht-64 {
  height: 64px;
}

.ht-72 {
  height: 72px;
}

.ht-115 {
  height: 115px;
}

.ht-120 {
  height: 120px;
}

.ht-160 {
  height: 160px;
}

@media (min-width: 480px) {
  .ht-xs-5 {
    height: 5px;
  }

  .ht-xs-5p {
    height: 5%;
  }

  .ht-xs-10 {
    height: 10px;
  }

  .ht-xs-10p {
    height: 10%;
  }

  .ht-xs-15 {
    height: 15px;
  }

  .ht-xs-15p {
    height: 15%;
  }

  .ht-xs-20 {
    height: 20px;
  }

  .ht-xs-20p {
    height: 20%;
  }

  .ht-xs-25 {
    height: 25px;
  }

  .ht-xs-25p {
    height: 25%;
  }

  .ht-xs-30 {
    height: 30px;
  }

  .ht-xs-30p {
    height: 30%;
  }

  .ht-xs-35 {
    height: 35px;
  }

  .ht-xs-35p {
    height: 35%;
  }

  .ht-xs-40 {
    height: 40px;
  }

  .ht-xs-40p {
    height: 40%;
  }

  .ht-xs-45 {
    height: 45px;
  }

  .ht-xs-45p {
    height: 45%;
  }

  .ht-xs-50 {
    height: 50px;
  }

  .ht-xs-50p {
    height: 50%;
  }

  .ht-xs-55 {
    height: 55px;
  }

  .ht-xs-55p {
    height: 55%;
  }

  .ht-xs-60 {
    height: 60px;
  }

  .ht-xs-60p {
    height: 60%;
  }

  .ht-xs-65 {
    height: 65px;
  }

  .ht-xs-65p {
    height: 65%;
  }

  .ht-xs-70 {
    height: 70px;
  }

  .ht-xs-70p {
    height: 70%;
  }

  .ht-xs-75 {
    height: 75px;
  }

  .ht-xs-75p {
    height: 75%;
  }

  .ht-xs-80 {
    height: 80px;
  }

  .ht-xs-85 {
    height: 85px;
  }

  .ht-xs-85p {
    height: 85%;
  }

  .ht-xs-90 {
    height: 90px;
  }

  .ht-xs-90p {
    height: 90%;
  }

  .ht-xs-95 {
    height: 95px;
  }

  .ht-xs-95p {
    height: 95%;
  }

  .ht-xs-100 {
    height: 100px;
  }

  .ht-xs-100p {
    height: 100%;
  }

  .ht-xs-150 {
    height: 150px;
  }

  .ht-xs-150p {
    height: 150%;
  }

  .ht-xs-200 {
    height: 200px;
  }

  .ht-xs-200p {
    height: 200%;
  }

  .ht-xs-250 {
    height: 250px;
  }

  .ht-xs-250p {
    height: 250%;
  }

  .ht-xs-300 {
    height: 300px;
  }

  .ht-xs-300p {
    height: 300%;
  }

  .ht-xs-350 {
    height: 350px;
  }

  .ht-xs-350p {
    height: 350%;
  }

  .ht-xs-400 {
    height: 400px;
  }

  .ht-xs-400p {
    height: 400%;
  }

  .ht-xs-450 {
    height: 450px;
  }

  .ht-xs-450p {
    height: 450%;
  }

  .ht-xs-500 {
    height: 500px;
  }

  .ht-xs-500p {
    height: 500%;
  }

  .ht-xs-550 {
    height: 550px;
  }

  .ht-xs-550p {
    height: 550%;
  }

  .ht-xs-600 {
    height: 600px;
  }

  .ht-xs-600p {
    height: 600%;
  }

  .ht-xs-650 {
    height: 650px;
  }

  .ht-xs-650p {
    height: 650%;
  }

  .ht-xs-700 {
    height: 700px;
  }

  .ht-xs-700p {
    height: 700%;
  }

  .ht-xs-750 {
    height: 750px;
  }

  .ht-xs-750p {
    height: 750%;
  }

  .ht-xs-800 {
    height: 800px;
  }

  .ht-xs-800p {
    height: 800%;
  }

  .ht-xs-850 {
    height: 850px;
  }

  .ht-xs-850p {
    height: 850%;
  }

  .ht-xs-100v {
    height: 100vh;
  }
}

@media (min-width: 576px) {
  .ht-sm-5 {
    height: 5px;
  }

  .ht-sm-5p {
    height: 5%;
  }

  .ht-sm-10 {
    height: 10px;
  }

  .ht-sm-10p {
    height: 10%;
  }

  .ht-sm-15 {
    height: 15px;
  }

  .ht-sm-15p {
    height: 15%;
  }

  .ht-sm-20 {
    height: 20px;
  }

  .ht-sm-20p {
    height: 20%;
  }

  .ht-sm-25 {
    height: 25px;
  }

  .ht-sm-25p {
    height: 25%;
  }

  .ht-sm-30 {
    height: 30px;
  }

  .ht-sm-30p {
    height: 30%;
  }

  .ht-sm-35 {
    height: 35px;
  }

  .ht-sm-35p {
    height: 35%;
  }

  .ht-sm-40 {
    height: 40px;
  }

  .ht-sm-40p {
    height: 40%;
  }

  .ht-sm-45 {
    height: 45px;
  }

  .ht-sm-45p {
    height: 45%;
  }

  .ht-sm-50 {
    height: 50px;
  }

  .ht-sm-50p {
    height: 50%;
  }

  .ht-sm-55 {
    height: 55px;
  }

  .ht-sm-55p {
    height: 55%;
  }

  .ht-sm-60 {
    height: 60px;
  }

  .ht-sm-60p {
    height: 60%;
  }

  .ht-sm-65 {
    height: 65px;
  }

  .ht-sm-65p {
    height: 65%;
  }

  .ht-sm-70 {
    height: 70px;
  }

  .ht-sm-70p {
    height: 70%;
  }

  .ht-sm-75 {
    height: 75px;
  }

  .ht-sm-75p {
    height: 75%;
  }

  .ht-sm-80 {
    height: 80px;
  }

  .ht-sm-80p {
    height: 80%;
  }

  .ht-sm-85 {
    height: 85px;
  }

  .ht-sm-85p {
    height: 85%;
  }

  .ht-sm-90 {
    height: 90px;
  }

  .ht-sm-90p {
    height: 90%;
  }

  .ht-sm-95 {
    height: 95px;
  }

  .ht-sm-95p {
    height: 95%;
  }

  .ht-sm-100 {
    height: 100px;
  }

  .ht-sm-100p {
    height: 100%;
  }

  .ht-sm-150 {
    height: 150px;
  }

  .ht-sm-150p {
    height: 150%;
  }

  .ht-sm-200 {
    height: 200px;
  }

  .ht-sm-200p {
    height: 200%;
  }

  .ht-sm-250 {
    height: 250px;
  }

  .ht-sm-250p {
    height: 250%;
  }

  .ht-sm-300 {
    height: 300px;
  }

  .ht-sm-300p {
    height: 300%;
  }

  .ht-sm-350 {
    height: 350px;
  }

  .ht-sm-350p {
    height: 350%;
  }

  .ht-sm-400 {
    height: 400px;
  }

  .ht-sm-400p {
    height: 400%;
  }

  .ht-sm-450 {
    height: 450px;
  }

  .ht-sm-450p {
    height: 450%;
  }

  .ht-sm-500 {
    height: 500px;
  }

  .ht-sm-500p {
    height: 500%;
  }

  .ht-sm-550 {
    height: 550px;
  }

  .ht-sm-550p {
    height: 550%;
  }

  .ht-sm-600 {
    height: 600px;
  }

  .ht-sm-600p {
    height: 600%;
  }

  .ht-sm-650 {
    height: 650px;
  }

  .ht-sm-650p {
    height: 650%;
  }

  .ht-sm-700 {
    height: 700px;
  }

  .ht-sm-700p {
    height: 700%;
  }

  .ht-sm-750 {
    height: 750px;
  }

  .ht-sm-750p {
    height: 750%;
  }

  .ht-sm-800 {
    height: 800px;
  }

  .ht-sm-800p {
    height: 800%;
  }

  .ht-sm-850 {
    height: 850px;
  }

  .ht-sm-850p {
    height: 850%;
  }

  .ht-sm-100v {
    height: 100vh;
  }
}

@media (min-width: 768px) {
  .ht-md-5 {
    height: 5px;
  }

  .ht-md-5p {
    height: 5%;
  }

  .ht-md-10 {
    height: 10px;
  }

  .ht-md-10p {
    height: 10%;
  }

  .ht-md-15 {
    height: 15px;
  }

  .ht-md-15p {
    height: 15%;
  }

  .ht-md-20 {
    height: 20px;
  }

  .ht-md-20p {
    height: 20%;
  }

  .ht-md-25 {
    height: 25px;
  }

  .ht-md-25p {
    height: 25%;
  }

  .ht-md-30 {
    height: 30px;
  }

  .ht-md-30p {
    height: 30%;
  }

  .ht-md-35 {
    height: 35px;
  }

  .ht-md-35p {
    height: 35%;
  }

  .ht-md-40 {
    height: 40px;
  }

  .ht-md-40p {
    height: 40%;
  }

  .ht-md-45 {
    height: 45px;
  }

  .ht-md-45p {
    height: 45%;
  }

  .ht-md-50 {
    height: 50px;
  }

  .ht-md-50p {
    height: 50%;
  }

  .ht-md-55 {
    height: 55px;
  }

  .ht-md-55p {
    height: 55%;
  }

  .ht-md-60 {
    height: 60px;
  }

  .ht-md-60p {
    height: 60%;
  }

  .ht-md-65 {
    height: 65px;
  }

  .ht-md-65p {
    height: 65%;
  }

  .ht-md-70 {
    height: 70px;
  }

  .ht-md-70p {
    height: 70%;
  }

  .ht-md-75 {
    height: 75px;
  }

  .ht-md-75p {
    height: 75%;
  }

  .ht-md-80 {
    height: 80px;
  }

  .ht-md-80p {
    height: 80%;
  }

  .ht-md-85 {
    height: 85px;
  }

  .ht-md-85p {
    height: 85%;
  }

  .ht-md-90 {
    height: 90px;
  }

  .ht-md-90p {
    height: 90%;
  }

  .ht-md-95 {
    height: 95px;
  }

  .ht-md-95p {
    height: 95%;
  }

  .ht-md-100 {
    height: 100px;
  }

  .ht-md-100p {
    height: 100%;
  }

  .ht-md-150 {
    height: 150px;
  }

  .ht-md-150p {
    height: 150%;
  }

  .ht-md-200 {
    height: 200px;
  }

  .ht-md-200p {
    height: 200%;
  }

  .ht-md-250 {
    height: 250px;
  }

  .ht-md-250p {
    height: 250%;
  }

  .ht-md-300 {
    height: 300px;
  }

  .ht-md-300p {
    height: 300%;
  }

  .ht-md-350 {
    height: 350px;
  }

  .ht-md-350p {
    height: 350%;
  }

  .ht-md-400 {
    height: 400px;
  }

  .ht-md-400p {
    height: 400%;
  }

  .ht-md-450 {
    height: 450px;
  }

  .ht-md-450p {
    height: 450%;
  }

  .ht-md-500 {
    height: 500px;
  }

  .ht-md-500p {
    height: 500%;
  }

  .ht-md-550 {
    height: 550px;
  }

  .ht-md-550p {
    height: 550%;
  }

  .ht-md-600 {
    height: 600px;
  }

  .ht-md-600p {
    height: 600%;
  }

  .ht-md-650 {
    height: 650px;
  }

  .ht-md-650p {
    height: 650%;
  }

  .ht-md-700 {
    height: 700px;
  }

  .ht-md-700p {
    height: 700%;
  }

  .ht-md-750 {
    height: 750px;
  }

  .ht-md-750p {
    height: 750%;
  }

  .ht-md-800 {
    height: 800px;
  }

  .ht-md-800p {
    height: 800%;
  }

  .ht-md-850 {
    height: 850px;
  }

  .ht-md-850p {
    height: 850%;
  }

  .ht-md-100v {
    height: 100vh;
  }
}

@media (min-width: 992px) {
  .ht-lg-5 {
    height: 5px;
  }

  .ht-lg-5p {
    height: 5%;
  }

  .ht-lg-10 {
    height: 10px;
  }

  .ht-lg-10p {
    height: 10%;
  }

  .ht-lg-15 {
    height: 15px;
  }

  .ht-lg-15p {
    height: 15%;
  }

  .ht-lg-20 {
    height: 20px;
  }

  .ht-lg-20p {
    height: 20%;
  }

  .ht-lg-25 {
    height: 25px;
  }

  .ht-lg-25p {
    height: 25%;
  }

  .ht-lg-30 {
    height: 30px;
  }

  .ht-lg-30p {
    height: 30%;
  }

  .ht-lg-35 {
    height: 35px;
  }

  .ht-lg-35p {
    height: 35%;
  }

  .ht-lg-40 {
    height: 40px;
  }

  .ht-lg-40p {
    height: 40%;
  }

  .ht-lg-45 {
    height: 45px;
  }

  .ht-lg-45p {
    height: 45%;
  }

  .ht-lg-50 {
    height: 50px;
  }

  .ht-lg-50p {
    height: 50%;
  }

  .ht-lg-55 {
    height: 55px;
  }

  .ht-lg-55p {
    height: 55%;
  }

  .ht-lg-60 {
    height: 60px;
  }

  .ht-lg-60p {
    height: 60%;
  }

  .ht-lg-65 {
    height: 65px;
  }

  .ht-lg-65p {
    height: 65%;
  }

  .ht-lg-70 {
    height: 70px;
  }

  .ht-lg-70p {
    height: 70%;
  }

  .ht-lg-75 {
    height: 75px;
  }

  .ht-lg-75p {
    height: 75%;
  }

  .ht-lg-80 {
    height: 80px;
  }

  .ht-lg-80p {
    height: 80%;
  }

  .ht-lg-85 {
    height: 85px;
  }

  .ht-lg-85p {
    height: 85%;
  }

  .ht-lg-90 {
    height: 90px;
  }

  .ht-lg-90p {
    height: 90%;
  }

  .ht-lg-95 {
    height: 95px;
  }

  .ht-lg-95p {
    height: 95%;
  }

  .ht-lg-100 {
    height: 100px;
  }

  .ht-lg-100p {
    height: 100%;
  }

  .ht-lg-150 {
    height: 150px;
  }

  .ht-lg-150p {
    height: 150%;
  }

  .ht-lg-200 {
    height: 200px;
  }

  .ht-lg-200p {
    height: 200%;
  }

  .ht-lg-250 {
    height: 250px;
  }

  .ht-lg-250p {
    height: 250%;
  }

  .ht-lg-300 {
    height: 300px;
  }

  .ht-lg-300p {
    height: 300%;
  }

  .ht-lg-350 {
    height: 350px;
  }

  .ht-lg-350p {
    height: 350%;
  }

  .ht-lg-400 {
    height: 400px;
  }

  .ht-lg-400p {
    height: 400%;
  }

  .ht-lg-450 {
    height: 450px;
  }

  .ht-lg-450p {
    height: 450%;
  }

  .ht-lg-500 {
    height: 500px;
  }

  .ht-lg-500p {
    height: 500%;
  }

  .ht-lg-550 {
    height: 550px;
  }

  .ht-lg-550p {
    height: 550%;
  }

  .ht-lg-600 {
    height: 600px;
  }

  .ht-lg-600p {
    height: 600%;
  }

  .ht-lg-650 {
    height: 650px;
  }

  .ht-lg-650p {
    height: 650%;
  }

  .ht-lg-700 {
    height: 700px;
  }

  .ht-lg-700p {
    height: 700%;
  }

  .ht-lg-750 {
    height: 750px;
  }

  .ht-lg-750p {
    height: 750%;
  }

  .ht-lg-800 {
    height: 800px;
  }

  .ht-lg-800p {
    height: 800%;
  }

  .ht-lg-850 {
    height: 850px;
  }

  .ht-lg-850p {
    height: 850%;
  }

  .ht-lg-100v {
    height: 100vh;
  }
}

@media (min-width: 1200px) {
  .ht-xl-5 {
    height: 5px;
  }

  .ht-xl-5p {
    height: 5%;
  }

  .ht-xl-10 {
    height: 10px;
  }

  .ht-xl-10p {
    height: 10%;
  }

  .ht-xl-15 {
    height: 15px;
  }

  .ht-xl-15p {
    height: 15%;
  }

  .ht-xl-20 {
    height: 20px;
  }

  .ht-xl-20p {
    height: 20%;
  }

  .ht-xl-25 {
    height: 25px;
  }

  .ht-xl-25p {
    height: 25%;
  }

  .ht-xl-30 {
    height: 30px;
  }

  .ht-xl-30p {
    height: 30%;
  }

  .ht-xl-35 {
    height: 35px;
  }

  .ht-xl-35p {
    height: 35%;
  }

  .ht-xl-40 {
    height: 40px;
  }

  .ht-xl-40p {
    height: 40%;
  }

  .ht-xl-45 {
    height: 45px;
  }

  .ht-xl-45p {
    height: 45%;
  }

  .ht-xl-50 {
    height: 50px;
  }

  .ht-xl-50p {
    height: 50%;
  }

  .ht-xl-55 {
    height: 55px;
  }

  .ht-xl-55p {
    height: 55%;
  }

  .ht-xl-60 {
    height: 60px;
  }

  .ht-xl-60p {
    height: 60%;
  }

  .ht-xl-65 {
    height: 65px;
  }

  .ht-xl-65p {
    height: 65%;
  }

  .ht-xl-70 {
    height: 70px;
  }

  .ht-xl-70p {
    height: 70%;
  }

  .ht-xl-75 {
    height: 75px;
  }

  .ht-xl-75p {
    height: 75%;
  }

  .ht-xl-80 {
    height: 80px;
  }

  .ht-xl-80p {
    height: 80%;
  }

  .ht-xl-85 {
    height: 85px;
  }

  .ht-xl-85p {
    height: 85%;
  }

  .ht-xl-90 {
    height: 90px;
  }

  .ht-xl-90p {
    height: 90%;
  }

  .ht-xl-95 {
    height: 95px;
  }

  .ht-xl-95p {
    height: 95%;
  }

  .ht-xl-100 {
    height: 100px;
  }

  .ht-xl-100p {
    height: 100%;
  }

  .ht-xl-150 {
    height: 150px;
  }

  .ht-xl-150p {
    height: 150%;
  }

  .mn-ht-xl-150p {
    min-height: 150%;
  }

  .ht-xl-200 {
    height: 200px;
  }

  .ht-xl-200p {
    height: 200%;
  }

  .ht-xl-250 {
    height: 250px;
  }

  .ht-xl-250p {
    height: 250%;
  }

  .ht-xl-300 {
    height: 300px;
  }

  .ht-xl-300p {
    height: 300%;
  }

  .ht-xl-350 {
    height: 350px;
  }

  .ht-xl-350p {
    height: 350%;
  }

  .ht-xl-400 {
    height: 400px;
  }

  .ht-xl-400p {
    height: 400%;
  }

  .ht-xl-450 {
    height: 450px;
  }

  .ht-xl-450p {
    height: 450%;
  }

  .ht-xl-500 {
    height: 500px;
  }

  .ht-xl-500p {
    height: 500%;
  }

  .ht-xl-550 {
    height: 550px;
  }

  .ht-xl-550p {
    height: 550%;
  }

  .ht-xl-600 {
    height: 600px;
  }

  .ht-xl-600p {
    height: 600%;
  }

  .ht-xl-650 {
    height: 650px;
  }

  .ht-xl-650p {
    height: 650%;
  }

  .ht-xl-700 {
    height: 700px;
  }

  .ht-xl-700p {
    height: 700%;
  }

  .ht-xl-750 {
    height: 750px;
  }

  .ht-xl-750p {
    height: 750%;
  }

  .ht-xl-800 {
    height: 800px;
  }

  .ht-xl-800p {
    height: 800%;
  }

  .ht-xl-850 {
    height: 850px;
  }

  .ht-xl-850p {
    height: 850%;
  }

  .ht-xl-100v {
    height: 100vh;
  }
}

.ht-250 {
  height: 250px !important;
}

.h-100vh {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-block-start: 0 !important;
}

.me-0,
.mx-0 {
  margin-inline-end: 0 !important;
}

.mb-0,
.my-0 {
  margin-block-end: 0 !important;
}

.ms-0,
.mx-0 {
  margin-inline-start: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-block-start: 0.25rem !important;
}

.me-1,
.mx-1 {
  margin-inline-end: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-block-end: 0.25rem !important;
}

.ms-1,
.mx-1 {
  margin-inline-start: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-block-start: 0.5rem !important;
}

.me-2,
.mx-2 {
  margin-inline-end: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-block-end: 0.5rem !important;
}

.ms-2,
.mx-2 {
  margin-inline-start: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-block-start: 1rem !important;
}

.me-3,
.mx-3 {
  margin-inline-end: 1rem !important;
}

.mb-3,
.my-3 {
  margin-block-end: 1rem !important;
}

.ms-3,
.mx-3 {
  margin-inline-start: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-block-start: 1.5rem !important;
}

.me-4,
.mx-4 {
  margin-inline-end: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-block-end: 1.5rem !important;
}

.ms-4,
.mx-4 {
  margin-inline-start: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-block-start: 3rem !important;
}

.me-5,
.mx-5 {
  margin-inline-end: 3rem !important;
}

.mb-5,
.my-5 {
  margin-block-end: 3rem !important;
}

.ms-5,
.mx-5 {
  margin-inline-start: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-block-start: auto !important;
}

.me-auto,
.mx-auto {
  margin-inline-end: auto !important;
}

.mb-auto,
.my-auto {
  margin-block-end: auto !important;
}

.ms-auto,
.mx-auto {
  margin-inline-start: auto !important;
}

@media (min-width: 375px) {
  .m-xs-0 {
    margin: 0 !important;
  }

  .mt-xs-0,
  .my-xs-0 {
    margin-block-start: 0 !important;
  }

  .me-xs-0,
  .mx-xs-0 {
    margin-inline-end: 0 !important;
  }

  .mb-xs-0,
  .my-xs-0 {
    margin-block-end: 0 !important;
  }

  .ms-xs-0,
  .mx-xs-0 {
    margin-inline-start: 0 !important;
  }

  .m-xs-1 {
    margin: 0.25rem !important;
  }

  .mt-xs-1,
  .my-xs-1 {
    margin-block-start: 0.25rem !important;
  }

  .me-xs-1,
  .mx-xs-1 {
    margin-inline-end: 0.25rem !important;
  }

  .mb-xs-1,
  .my-xs-1 {
    margin-block-end: 0.25rem !important;
  }

  .ms-xs-1,
  .mx-xs-1 {
    margin-inline-start: 0.25rem !important;
  }

  .m-xs-2 {
    margin: 0.5rem !important;
  }

  .mt-xs-2,
  .my-xs-2 {
    margin-block-start: 0.5rem !important;
  }

  .me-xs-2,
  .mx-xs-2 {
    margin-inline-end: 0.5rem !important;
  }

  .mb-xs-2,
  .my-xs-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-xs-2,
  .mx-xs-2 {
    margin-inline-start: 0.5rem !important;
  }

  .m-xs-3 {
    margin: 1rem !important;
  }

  .mt-xs-3,
  .my-xs-3 {
    margin-block-start: 1rem !important;
  }

  .me-xs-3,
  .mx-xs-3 {
    margin-inline-end: 1rem !important;
  }

  .mb-xs-3,
  .my-xs-3 {
    margin-block-end: 1rem !important;
  }

  .ms-xs-3,
  .mx-xs-3 {
    margin-inline-start: 1rem !important;
  }

  .m-xs-4 {
    margin: 1.5rem !important;
  }

  .mt-xs-4,
  .my-xs-4 {
    margin-block-start: 1.5rem !important;
  }

  .me-xs-4,
  .mx-xs-4 {
    margin-inline-end: 1.5rem !important;
  }

  .mb-xs-4,
  .my-xs-4 {
    margin-block-end: 1.5rem !important;
  }

  .ms-xs-4,
  .mx-xs-4 {
    margin-inline-start: 1.5rem !important;
  }

  .m-xs-5 {
    margin: 3rem !important;
  }

  .mt-xs-5,
  .my-xs-5 {
    margin-block-start: 3rem !important;
  }

  .me-xs-5,
  .mx-xs-5 {
    margin-inline-end: 3rem !important;
  }

  .mb-xs-5,
  .my-xs-5 {
    margin-block-end: 3rem !important;
  }

  .ms-xs-5,
  .mx-xs-5 {
    margin-inline-start: 3rem !important;
  }

  .m-xs-auto {
    margin: auto !important;
  }

  .mt-xs-auto,
  .my-xs-auto {
    margin-block-start: auto !important;
  }

  .me-xs-auto,
  .mx-xs-auto {
    margin-inline-end: auto !important;
  }

  .mb-xs-auto,
  .my-xs-auto {
    margin-block-end: auto !important;
  }

  .ms-xs-auto,
  .mx-xs-auto {
    margin-inline-start: auto !important;
  }
}

@media (min-width: 576px) {
  .mg-sm-0 {
    margin: 0px;
  }

  .mg-sm-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-sm-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-sm-t-0 {
    margin-block-start: 0px !important;
  }

  .mg-sm-e-0 {
    margin-inline-end: 0px;
  }

  .mg-sm-b-0 {
    margin-block-end: 0px !important;
  }

  .mg-sm-s-0 {
    margin-inline-start: 0px;
  }

  .mg-sm-1 {
    margin: 1px;
  }

  .mg-sm-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-sm-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-sm-t-1 {
    margin-block-start: 1px;
  }

  .mg-sm-e-1 {
    margin-inline-end: 1px;
  }

  .mg-sm-b-1 {
    margin-block-end: 1px;
  }

  .mg-sm-s-1 {
    margin-inline-start: 1px;
  }

  .mg-sm-2 {
    margin: 2px;
  }

  .mg-sm-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-sm-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-sm-t-2 {
    margin-block-start: 2px;
  }

  .mg-sm-e-2 {
    margin-inline-end: 2px;
  }

  .mg-sm-b-2 {
    margin-block-end: 2px;
  }

  .mg-sm-s-2 {
    margin-inline-start: 2px;
  }

  .mg-sm-3 {
    margin: 3px;
  }

  .mg-sm-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-sm-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-sm-t-3 {
    margin-block-start: 3px;
  }

  .mg-sm-e-3 {
    margin-inline-end: 3px;
  }

  .mg-sm-b-3 {
    margin-block-end: 3px;
  }

  .mg-sm-s-3 {
    margin-inline-start: 3px;
  }

  .mg-sm-4 {
    margin: 4px;
  }

  .mg-sm-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-sm-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-sm-t-4 {
    margin-block-start: 4px;
  }

  .mg-sm-e-4 {
    margin-inline-end: 4px;
  }

  .mg-sm-b-4 {
    margin-block-end: 4px;
  }

  .mg-sm-s-4 {
    margin-inline-start: 4px;
  }

  .mg-sm-5 {
    margin: 5px;
  }

  .mg-sm-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-sm-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-sm-t-5 {
    margin-block-start: 5px;
  }

  .mg-sm-e-5 {
    margin-inline-end: 5px;
  }

  .mg-sm-b-5 {
    margin-block-end: 5px;
  }

  .mg-sm-s-5 {
    margin-inline-start: 5px;
  }

  .mg-sm-6 {
    margin: 6px;
  }

  .mg-sm-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-sm-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-sm-t-6 {
    margin-block-start: 6px;
  }

  .mg-sm-e-6 {
    margin-inline-end: 6px;
  }

  .mg-sm-b-6 {
    margin-block-end: 6px;
  }

  .mg-sm-s-6 {
    margin-inline-start: 6px;
  }

  .mg-sm-7 {
    margin: 7px;
  }

  .mg-sm-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-sm-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-sm-t-7 {
    margin-block-start: 7px;
  }

  .mg-sm-e-7 {
    margin-inline-end: 7px;
  }

  .mg-sm-b-7 {
    margin-block-end: 7px;
  }

  .mg-sm-s-7 {
    margin-inline-start: 7px;
  }

  .mg-sm-8 {
    margin: 8px;
  }

  .mg-sm-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-sm-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-sm-t-8 {
    margin-block-start: 8px;
  }

  .mg-sm-e-8 {
    margin-inline-end: 8px;
  }

  .mg-sm-b-8 {
    margin-block-end: 8px;
  }

  .mg-sm-s-8 {
    margin-inline-start: 8px;
  }

  .mg-sm-9 {
    margin: 9px;
  }

  .mg-sm-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-sm-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-sm-t-9 {
    margin-block-start: 9px;
  }

  .mg-sm-e-9 {
    margin-inline-end: 9px;
  }

  .mg-sm-b-9 {
    margin-block-end: 9px;
  }

  .mg-sm-s-9 {
    margin-inline-start: 9px;
  }

  .mg-sm-10 {
    margin: 10px;
  }

  .mg-sm-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-sm-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-sm-t-10 {
    margin-block-start: 10px;
  }

  .mg-sm-e-10 {
    margin-inline-end: 10px;
  }

  .mg-sm-b-10 {
    margin-block-end: 10px;
  }

  .mg-sm-s-10 {
    margin-inline-start: 10px;
  }

  .mg-sm-15 {
    margin: 15px;
  }

  .mg-sm-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-sm-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-sm-t-15 {
    margin-block-start: 15px;
  }

  .mg-sm-e-15 {
    margin-inline-end: 15px;
  }

  .mg-sm-b-15 {
    margin-block-end: 15px;
  }

  .mg-sm-s-15 {
    margin-inline-start: 15px;
  }

  .mg-sm-20 {
    margin: 20px;
  }

  .mg-sm-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-sm-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-sm-t-20 {
    margin-block-start: 20px;
  }

  .mg-sm-e-20 {
    margin-inline-end: 20px;
  }

  .mg-sm-b-20 {
    margin-block-end: 20px;
  }

  .mg-sm-s-20 {
    margin-inline-start: 20px;
  }

  .mg-sm-25 {
    margin: 25px;
  }

  .mg-sm-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-sm-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-sm-t-25 {
    margin-block-start: 25px;
  }

  .mg-sm-e-25 {
    margin-inline-end: 25px;
  }

  .mg-sm-b-25 {
    margin-block-end: 25px;
  }

  .mg-sm-s-25 {
    margin-inline-start: 25px;
  }

  .mg-sm-30 {
    margin: 30px;
  }

  .mg-sm-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-sm-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-sm-t-30 {
    margin-block-start: 30px;
  }

  .mg-sm-e-30 {
    margin-inline-end: 30px;
  }

  .mg-sm-b-30 {
    margin-block-end: 30px;
  }

  .mg-sm-s-30 {
    margin-inline-start: 30px;
  }

  .mg-sm-35 {
    margin: 35px;
  }

  .mg-sm-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-sm-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-sm-t-35 {
    margin-block-start: 35px;
  }

  .mg-sm-e-35 {
    margin-inline-end: 35px;
  }

  .mg-sm-b-35 {
    margin-block-end: 35px;
  }

  .mg-sm-s-35 {
    margin-inline-start: 35px;
  }

  .mg-sm-40 {
    margin: 40px;
  }

  .mg-sm-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-sm-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-sm-t-40 {
    margin-block-start: 40px;
  }

  .mg-sm-e-40 {
    margin-inline-end: 40px;
  }

  .mg-sm-b-40 {
    margin-block-end: 40px;
  }

  .mg-sm-s-40 {
    margin-inline-start: 40px;
  }

  .mg-sm-t-auto {
    margin-block-start: auto;
  }

  .mg-sm-e-auto {
    margin-inline-end: auto;
  }

  .mg-sm-b-auto {
    margin-block-end: auto;
  }

  .mg-sm-s-auto {
    margin-inline-start: auto;
  }

  .mg-sm-auto {
    margin: auto;
  }

  .mg-sm-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
  .my-sm-0 {
    margin-block-start: 0 !important;
  }

  .me-sm-0,
  .mx-sm-0 {
    margin-inline-end: 0 !important;
  }

  .mb-sm-0,
  .my-sm-0 {
    margin-block-end: 0 !important;
  }

  .ms-sm-0,
  .mx-sm-0 {
    margin-inline-start: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
  .my-sm-1 {
    margin-block-start: 0.25rem !important;
  }

  .me-sm-1,
  .mx-sm-1 {
    margin-inline-end: 0.25rem !important;
  }

  .mb-sm-1,
  .my-sm-1 {
    margin-block-end: 0.25rem !important;
  }

  .ms-sm-1,
  .mx-sm-1 {
    margin-inline-start: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
  .my-sm-2 {
    margin-block-start: 0.5rem !important;
  }

  .me-sm-2,
  .mx-sm-2 {
    margin-inline-end: 0.5rem !important;
  }

  .mb-sm-2,
  .my-sm-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-sm-2,
  .mx-sm-2 {
    margin-inline-start: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
  .my-sm-3 {
    margin-block-start: 1rem !important;
  }

  .me-sm-3,
  .mx-sm-3 {
    margin-inline-end: 1rem !important;
  }

  .mb-sm-3,
  .my-sm-3 {
    margin-block-end: 1rem !important;
  }

  .ms-sm-3,
  .mx-sm-3 {
    margin-inline-start: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
  .my-sm-4 {
    margin-block-start: 1.5rem !important;
  }

  .me-sm-4,
  .mx-sm-4 {
    margin-inline-end: 1.5rem !important;
  }

  .mb-sm-4,
  .my-sm-4 {
    margin-block-end: 1.5rem !important;
  }

  .ms-sm-4,
  .mx-sm-4 {
    margin-inline-start: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
  .my-sm-5 {
    margin-block-start: 3rem !important;
  }

  .me-sm-5,
  .mx-sm-5 {
    margin-inline-end: 3rem !important;
  }

  .mb-sm-5,
  .my-sm-5 {
    margin-block-end: 3rem !important;
  }

  .ms-sm-5,
  .mx-sm-5 {
    margin-inline-start: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
  .my-sm-auto {
    margin-block-start: auto !important;
  }

  .me-sm-auto,
  .mx-sm-auto {
    margin-inline-end: auto !important;
  }

  .mb-sm-auto,
  .my-sm-auto {
    margin-block-end: auto !important;
  }

  .ms-sm-auto,
  .mx-sm-auto {
    margin-inline-start: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
  .my-md-0 {
    margin-block-start: 0 !important;
  }

  .me-md-0,
  .mx-md-0 {
    margin-inline-end: 0 !important;
  }

  .mb-md-0,
  .my-md-0 {
    margin-block-end: 0 !important;
  }

  .ms-md-0,
  .mx-md-0 {
    margin-inline-start: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
  .my-md-1 {
    margin-block-start: 0.25rem !important;
  }

  .me-md-1,
  .mx-md-1 {
    margin-inline-end: 0.25rem !important;
  }

  .mb-md-1,
  .my-md-1 {
    margin-block-end: 0.25rem !important;
  }

  .ms-md-1,
  .mx-md-1 {
    margin-inline-start: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
  .my-md-2 {
    margin-block-start: 0.5rem !important;
  }

  .me-md-2,
  .mx-md-2 {
    margin-inline-end: 0.5rem !important;
  }

  .mb-md-2,
  .my-md-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-md-2,
  .mx-md-2 {
    margin-inline-start: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
  .my-md-3 {
    margin-block-start: 1rem !important;
  }

  .me-md-3,
  .mx-md-3 {
    margin-inline-end: 1rem !important;
  }

  .mb-md-3,
  .my-md-3 {
    margin-block-end: 1rem !important;
  }

  .ms-md-3,
  .mx-md-3 {
    margin-inline-start: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
  .my-md-4 {
    margin-block-start: 1.5rem !important;
  }

  .me-md-4,
  .mx-md-4 {
    margin-inline-end: 1.5rem !important;
  }

  .mb-md-4,
  .my-md-4 {
    margin-block-end: 1.5rem !important;
  }

  .ms-md-4,
  .mx-md-4 {
    margin-inline-start: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-block-start: 3rem !important;
  }

  .me-md-5,
  .mx-md-5 {
    margin-inline-end: 3rem !important;
  }

  .mb-md-5,
  .my-md-5 {
    margin-block-end: 3rem !important;
  }

  .ms-md-5,
  .mx-md-5 {
    margin-inline-start: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
  .my-md-auto {
    margin-block-start: auto !important;
  }

  .me-md-auto,
  .mx-md-auto {
    margin-inline-end: auto !important;
  }

  .mb-md-auto,
  .my-md-auto {
    margin-block-end: auto !important;
  }

  .ms-md-auto,
  .mx-md-auto {
    margin-inline-start: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
  .my-lg-0 {
    margin-block-start: 0 !important;
  }

  .me-lg-0,
  .mx-lg-0 {
    margin-inline-end: 0 !important;
  }

  .mb-lg-0,
  .my-lg-0 {
    margin-block-end: 0 !important;
  }

  .ms-lg-0,
  .mx-lg-0 {
    margin-inline-start: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
  .my-lg-1 {
    margin-block-start: 0.25rem !important;
  }

  .me-lg-1,
  .mx-lg-1 {
    margin-inline-end: 0.25rem !important;
  }

  .mb-lg-1,
  .my-lg-1 {
    margin-block-end: 0.25rem !important;
  }

  .ms-lg-1,
  .mx-lg-1 {
    margin-inline-start: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
  .my-lg-2 {
    margin-block-start: 0.5rem !important;
  }

  .me-lg-2,
  .mx-lg-2 {
    margin-inline-end: 0.5rem !important;
  }

  .mb-lg-2,
  .my-lg-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-lg-2,
  .mx-lg-2 {
    margin-inline-start: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
  .my-lg-3 {
    margin-block-start: 1rem !important;
  }

  .me-lg-3,
  .mx-lg-3 {
    margin-inline-end: 1rem !important;
  }

  .mb-lg-3,
  .my-lg-3 {
    margin-block-end: 1rem !important;
  }

  .ms-lg-3,
  .mx-lg-3 {
    margin-inline-start: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
  .my-lg-4 {
    margin-block-start: 1.5rem !important;
  }

  .me-lg-4,
  .mx-lg-4 {
    margin-inline-end: 1.5rem !important;
  }

  .mb-lg-4,
  .my-lg-4 {
    margin-block-end: 1.5rem !important;
  }

  .ms-lg-4,
  .mx-lg-4 {
    margin-inline-start: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
  .my-lg-5 {
    margin-block-start: 3rem !important;
  }

  .me-lg-5,
  .mx-lg-5 {
    margin-inline-end: 3rem !important;
  }

  .mb-lg-5,
  .my-lg-5 {
    margin-block-end: 3rem !important;
  }

  .ms-lg-5,
  .mx-lg-5 {
    margin-inline-start: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
  .my-lg-auto {
    margin-block-start: auto !important;
  }

  .me-lg-auto,
  .mx-lg-auto {
    margin-inline-end: auto !important;
  }

  .mb-lg-auto,
  .my-lg-auto {
    margin-block-end: auto !important;
  }

  .ms-lg-auto,
  .mx-lg-auto {
    margin-inline-start: auto !important;
  }
}

@media (min-width: 992px) {
  .mg-lg-0 {
    margin: 0px;
  }

  .mg-lg-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-lg-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-lg-t-0 {
    margin-block-start: 0px !important;
  }

  .mg-lg-r-0 {
    margin-inline-end: 0px;
  }

  .mg-lg-b-0 {
    margin-block-end: 0px;
  }

  .mg-lg-l-0 {
    margin-inline-start: 0px;
  }

  .mg-lg-1 {
    margin: 1px;
  }

  .mg-lg-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-lg-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-lg-t-1 {
    margin-block-start: 1px;
  }

  .mg-lg-r-1 {
    margin-inline-end: 1px;
  }

  .mg-lg-b-1 {
    margin-block-end: 1px;
  }

  .mg-lg-l-1 {
    margin-inline-start: 1px;
  }

  .mg-lg-2 {
    margin: 2px;
  }

  .mg-lg-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-lg-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-lg-t-2 {
    margin-block-start: 2px;
  }

  .mg-lg-r-2 {
    margin-inline-end: 2px;
  }

  .mg-lg-b-2 {
    margin-block-end: 2px;
  }

  .mg-lg-l-2 {
    margin-inline-start: 2px;
  }

  .mg-lg-3 {
    margin: 3px;
  }

  .mg-lg-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-lg-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-lg-t-3 {
    margin-block-start: 3px;
  }

  .mg-lg-r-3 {
    margin-inline-end: 3px;
  }

  .mg-lg-b-3 {
    margin-block-end: 3px;
  }

  .mg-lg-l-3 {
    margin-inline-start: 3px;
  }

  .mg-lg-4 {
    margin: 4px;
  }

  .mg-lg-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-lg-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-lg-t-4 {
    margin-block-start: 4px;
  }

  .mg-lg-r-4 {
    margin-inline-end: 4px;
  }

  .mg-lg-b-4 {
    margin-block-end: 4px;
  }

  .mg-lg-l-4 {
    margin-inline-start: 4px;
  }

  .mg-lg-5 {
    margin: 5px;
  }

  .mg-lg-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-lg-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-lg-t-5 {
    margin-block-start: 5px;
  }

  .mg-lg-r-5 {
    margin-inline-end: 5px;
  }

  .mg-lg-b-5 {
    margin-block-end: 5px;
  }

  .mg-lg-l-5 {
    margin-inline-start: 5px;
  }

  .mg-lg-6 {
    margin: 6px;
  }

  .mg-lg-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-lg-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-lg-t-6 {
    margin-block-start: 6px;
  }

  .mg-lg-r-6 {
    margin-inline-end: 6px;
  }

  .mg-lg-b-6 {
    margin-block-end: 6px;
  }

  .mg-lg-l-6 {
    margin-inline-start: 6px;
  }

  .mg-lg-7 {
    margin: 7px;
  }

  .mg-lg-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-lg-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-lg-t-7 {
    margin-block-start: 7px;
  }

  .mg-lg-r-7 {
    margin-inline-end: 7px;
  }

  .mg-lg-b-7 {
    margin-block-end: 7px;
  }

  .mg-lg-l-7 {
    margin-inline-start: 7px;
  }

  .mg-lg-8 {
    margin: 8px;
  }

  .mg-lg-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-lg-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-lg-t-8 {
    margin-block-start: 8px;
  }

  .mg-lg-r-8 {
    margin-inline-end: 8px;
  }

  .mg-lg-b-8 {
    margin-block-end: 8px;
  }

  .mg-lg-l-8 {
    margin-inline-start: 8px;
  }

  .mg-lg-9 {
    margin: 9px;
  }

  .mg-lg-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-lg-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-lg-t-9 {
    margin-block-start: 9px;
  }

  .mg-lg-r-9 {
    margin-inline-end: 9px;
  }

  .mg-lg-b-9 {
    margin-block-end: 9px;
  }

  .mg-lg-l-9 {
    margin-inline-start: 9px;
  }

  .mg-lg-10 {
    margin: 10px;
  }

  .mg-lg-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-lg-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-lg-t-10 {
    margin-block-start: 10px;
  }

  .mg-lg-r-10 {
    margin-inline-end: 10px;
  }

  .mg-lg-b-10 {
    margin-block-end: 10px;
  }

  .mg-lg-l-10 {
    margin-inline-start: 10px;
  }

  .mg-lg-15 {
    margin: 15px;
  }

  .mg-lg-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-lg-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-lg-t-15 {
    margin-block-start: 15px;
  }

  .mg-lg-r-15 {
    margin-inline-end: 15px;
  }

  .mg-lg-b-15 {
    margin-block-end: 15px;
  }

  .mg-lg-l-15 {
    margin-inline-start: 15px;
  }

  .mg-lg-20 {
    margin: 20px;
  }

  .mg-lg-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-lg-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-lg-t-20 {
    margin-block-start: 20px;
  }

  .mg-lg-r-20 {
    margin-inline-end: 20px;
  }

  .mg-lg-b-20 {
    margin-block-end: 20px;
  }

  .mg-lg-l-20 {
    margin-inline-start: 20px;
  }

  .mg-lg-25 {
    margin: 25px;
  }

  .mg-lg-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-lg-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-lg-t-25 {
    margin-block-start: 25px;
  }

  .mg-lg-r-25 {
    margin-inline-end: 25px;
  }

  .mg-lg-b-25 {
    margin-block-end: 25px;
  }

  .mg-lg-l-25 {
    margin-inline-start: 25px;
  }

  .mg-lg-30 {
    margin: 30px;
  }

  .mg-lg-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-lg-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-lg-t-30 {
    margin-block-start: 30px;
  }

  .mg-lg-r-30 {
    margin-inline-end: 30px;
  }

  .mg-lg-b-30 {
    margin-block-end: 30px;
  }

  .mg-lg-l-30 {
    margin-inline-start: 30px;
  }

  .mg-lg-35 {
    margin: 35px;
  }

  .mg-lg-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-lg-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-lg-t-35 {
    margin-block-start: 35px;
  }

  .mg-lg-r-35 {
    margin-inline-end: 35px;
  }

  .mg-lg-b-35 {
    margin-block-end: 35px;
  }

  .mg-lg-l-35 {
    margin-inline-start: 35px;
  }

  .mg-lg-40 {
    margin: 40px;
  }

  .mg-lg-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-lg-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-lg-t-40 {
    margin-block-start: 40px;
  }

  .mg-lg-r-40 {
    margin-inline-end: 40px;
  }

  .mg-lg-b-40 {
    margin-block-end: 40px;
  }

  .mg-lg-l-40 {
    margin-inline-start: 40px;
  }

  .mg-lg-t-auto {
    margin-block-start: auto;
  }

  .mg-lg-r-auto {
    margin-inline-end: auto;
  }

  .mg-lg-b-auto {
    margin-block-end: auto;
  }

  .mg-lg-l-auto {
    margin-inline-start: auto;
  }

  .mg-lg-auto {
    margin: auto;
  }

  .mg-lg-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
  .my-xl-0 {
    margin-block-start: 0 !important;
  }

  .me-xl-0,
  .mx-xl-0 {
    margin-inline-end: 0 !important;
  }

  .mb-xl-0,
  .my-xl-0 {
    margin-block-end: 0 !important;
  }

  .ms-xl-0,
  .mx-xl-0 {
    margin-inline-start: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
  .my-xl-1 {
    margin-block-start: 0.25rem !important;
  }

  .me-xl-1,
  .mx-xl-1 {
    margin-inline-end: 0.25rem !important;
  }

  .mb-xl-1,
  .my-xl-1 {
    margin-block-end: 0.25rem !important;
  }

  .ms-xl-1,
  .mx-xl-1 {
    margin-inline-start: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
  .my-xl-2 {
    margin-block-start: 0.5rem !important;
  }

  .me-xl-2,
  .mx-xl-2 {
    margin-inline-end: 0.5rem !important;
  }

  .mb-xl-2,
  .my-xl-2 {
    margin-block-end: 0.5rem !important;
  }

  .ms-xl-2,
  .mx-xl-2 {
    margin-inline-start: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
  .my-xl-3 {
    margin-block-start: 1rem !important;
  }

  .me-xl-3,
  .mx-xl-3 {
    margin-inline-end: 1rem !important;
  }

  .mb-xl-3,
  .my-xl-3 {
    margin-block-end: 1rem !important;
  }

  .ms-xl-3,
  .mx-xl-3 {
    margin-inline-start: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
  .my-xl-4 {
    margin-block-start: 1.5rem !important;
  }

  .me-xl-4,
  .mx-xl-4 {
    margin-inline-end: 1.5rem !important;
  }

  .mb-xl-4,
  .my-xl-4 {
    margin-block-end: 1.5rem !important;
  }

  .ms-xl-4,
  .mx-xl-4 {
    margin-inline-start: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
  .my-xl-5 {
    margin-block-start: 3rem !important;
  }

  .me-xl-5,
  .mx-xl-5 {
    margin-inline-end: 3rem !important;
  }

  .mb-xl-5,
  .my-xl-5 {
    margin-block-end: 3rem !important;
  }

  .ms-xl-5,
  .mx-xl-5 {
    margin-inline-start: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
  .my-xl-auto {
    margin-block-start: auto !important;
  }

  .me-xl-auto,
  .mx-xl-auto {
    margin-inline-end: auto !important;
  }

  .mb-xl-auto,
  .my-xl-auto {
    margin-block-end: auto !important;
  }

  .ms-xl-auto,
  .mx-xl-auto {
    margin-inline-start: auto !important;
  }
}

/* ###### 9.4 Margin   ###### */
.mg-0 {
  margin: 0px;
}

.mg-y-0 {
  margin-block-start: 0px;
  margin-block-end: 0px;
}

.mg-x-0 {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.mg-t-0 {
  margin-block-start: 0px;
}

.mg-e-0 {
  margin-inline-end: 0px;
}

.mg-b-0 {
  margin-block-end: 0px;
}

.mg-s-0 {
  margin-inline-start: 0px;
}

.mg-1 {
  margin: 1px;
}

.mg-y-1 {
  margin-block-start: 1px;
  margin-block-end: 1px;
}

.mg-x-1 {
  margin-inline-start: 1px;
  margin-inline-end: 1px;
}

.mg-t-1 {
  margin-block-start: 1px;
}

.mg-e-1 {
  margin-inline-end: 1px;
}

.mg-b-1 {
  margin-block-end: 1px;
}

.mg-s-1 {
  margin-inline-start: 1px;
}

.mg-2 {
  margin: 2px;
}

.mg-y-2 {
  margin-block-start: 2px;
  margin-block-end: 2px;
}

.mg-x-2 {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
}

.mg-t-2 {
  margin-block-start: 2px;
}

.mg-e-2 {
  margin-inline-end: 2px;
}

.mg-b-2 {
  margin-block-end: 2px;
}

.mg-s-2 {
  margin-inline-start: 2px;
}

.mg-3 {
  margin: 3px;
}

.mg-y-3 {
  margin-block-start: 3px;
  margin-block-end: 3px;
}

.mg-x-3 {
  margin-inline-start: 3px;
  margin-inline-end: 3px;
}

.mg-t-3 {
  margin-block-start: 3px;
}

.mg-e-3 {
  margin-inline-end: 3px;
}

.mg-b-3 {
  margin-block-end: 3px;
}

.mg-s-3 {
  margin-inline-start: 3px;
}

.mg-4 {
  margin: 4px;
}

.mg-y-4 {
  margin-block-start: 4px;
  margin-block-end: 4px;
}

.mg-x-4 {
  margin-inline-start: 4px;
  margin-inline-end: 4px;
}

.mg-t-4 {
  margin-block-start: 4px;
}

.mg-e-4 {
  margin-inline-end: 4px;
}

.mg-b-4 {
  margin-block-end: 4px;
}

.mg-s-4 {
  margin-inline-start: 4px;
}

.mg-5 {
  margin: 5px;
}

.mg-y-5 {
  margin-block-start: 5px;
  margin-block-end: 5px;
}

.mg-x-5 {
  margin-inline-start: 5px;
  margin-inline-end: 5px;
}

.mg-t-5 {
  margin-block-start: 5px;
}

.mg-e-5 {
  margin-inline-end: 5px;
}

.mg-b-5 {
  margin-block-end: 5px;
}

.mg-s-5 {
  margin-inline-start: 5px;
}

.mg-6 {
  margin: 6px;
}

.mg-y-6 {
  margin-block-start: 6px;
  margin-block-end: 6px;
}

.mg-x-6 {
  margin-inline-start: 6px;
  margin-inline-end: 6px;
}

.mg-t-6 {
  margin-block-start: 6px;
}

.mg-e-6 {
  margin-inline-end: 6px;
}

.mg-b-6 {
  margin-block-end: 6px;
}

.mg-s-6 {
  margin-inline-start: 6px;
}

.mg-7 {
  margin: 7px;
}

.mg-y-7 {
  margin-block-start: 7px;
  margin-block-end: 7px;
}

.mg-x-7 {
  margin-inline-start: 7px;
  margin-inline-end: 7px;
}

.mg-t-7 {
  margin-block-start: 7px;
}

.mg-e-7 {
  margin-inline-end: 7px;
}

.mg-b-7 {
  margin-block-end: 7px;
}

.mg-s-7 {
  margin-inline-start: 7px;
}

.mg-8 {
  margin: 8px;
}

.mg-y-8 {
  margin-block-start: 8px;
  margin-block-end: 8px;
}

.mg-x-8 {
  margin-inline-start: 8px;
  margin-inline-end: 8px;
}

.mg-t-8 {
  margin-block-start: 8px;
}

.mg-e-8 {
  margin-inline-end: 8px;
}

.mg-b-8 {
  margin-block-end: 8px;
}

.mg-s-8 {
  margin-inline-start: 8px;
}

.mg-9 {
  margin: 9px;
}

.mg-y-9 {
  margin-block-start: 9px;
  margin-block-end: 9px;
}

.mg-x-9 {
  margin-inline-start: 9px;
  margin-inline-end: 9px;
}

.mg-t-9 {
  margin-block-start: 9px;
}

.mg-e-9 {
  margin-inline-end: 9px;
}

.mg-b-9 {
  margin-block-end: 9px;
}

.mg-s-9 {
  margin-inline-start: 9px;
}

.mg-10 {
  margin: 10px;
}

.mg-y-10 {
  margin-block-start: 10px;
  margin-block-end: 10px;
}

.mg-x-10 {
  margin-inline-start: 10px;
  margin-inline-end: 10px;
}

.mg-t-10 {
  margin-block-start: 10px;
}

.mg-e-10 {
  margin-inline-end: 10px;
}

.mg-b-10 {
  margin-block-end: 10px;
}

.mg-s-10 {
  margin-inline-start: 10px;
}

.mg-15 {
  margin: 15px;
}

.mg-y-15 {
  margin-block-start: 15px;
  margin-block-end: 15px;
}

.mg-x-15 {
  margin-inline-start: 15px;
  margin-inline-end: 15px;
}

.mg-t-15 {
  margin-block-start: 15px;
}

.mg-e-15 {
  margin-inline-end: 15px;
}

.mg-b-15 {
  margin-block-end: 15px;
}

.mg-s-15 {
  margin-inline-start: 15px;
}

.mg-20 {
  margin: 20px;
}

.mg-y-20 {
  margin-block-start: 20px;
  margin-block-end: 20px;
}

.mg-x-20 {
  margin-inline-start: 20px;
  margin-inline-end: 20px;
}

.mg-t-20 {
  margin-block-start: 20px;
}

.mg-e-20 {
  margin-inline-end: 20px;
}

.mg-b-20 {
  margin-block-end: 20px;
}

.mg-s-20 {
  margin-inline-start: 20px;
}

.mg-25 {
  margin: 25px;
}

.mg-y-25 {
  margin-block-start: 25px;
  margin-block-end: 25px;
}

.mg-x-25 {
  margin-inline-start: 25px;
  margin-inline-end: 25px;
}

.mg-t-25 {
  margin-block-start: 25px;
}

.mg-e-25 {
  margin-inline-end: 25px;
}

.mg-b-25 {
  margin-block-end: 25px;
}

.mg-s-25 {
  margin-inline-start: 25px;
}

.mg-30 {
  margin: 30px;
}

.mg-y-30 {
  margin-block-start: 30px;
  margin-block-end: 30px;
}

.mg-x-30 {
  margin-inline-start: 30px;
  margin-inline-end: 30px;
}

.mg-t-30 {
  margin-block-start: 30px;
}

.mg-e-30 {
  margin-inline-end: 30px;
}

.mg-b-30 {
  margin-block-end: 30px;
}

.mg-s-30 {
  margin-inline-start: 30px;
}

.mg-35 {
  margin: 35px;
}

.mg-y-35 {
  margin-block-start: 35px;
  margin-block-end: 35px;
}

.mg-x-35 {
  margin-inline-start: 35px;
  margin-inline-end: 35px;
}

.mg-t-35 {
  margin-block-start: 35px;
}

.mg-e-35 {
  margin-inline-end: 35px;
}

.mg-b-35 {
  margin-block-end: 35px;
}

.mg-s-35 {
  margin-inline-start: 35px;
}

.mg-40 {
  margin: 40px;
}

.mg-y-40 {
  margin-block-start: 40px;
  margin-block-end: 40px;
}

.mg-x-40 {
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

.mg-t-40 {
  margin-block-start: 40px;
}

.mg-e-40 {
  margin-inline-end: 40px;
}

.mg-b-40 {
  margin-block-end: 40px;
}

.mg-s-40 {
  margin-inline-start: 40px;
}

.mg-t-auto {
  margin-block-start: auto;
}

.mg-e-auto {
  margin-inline-end: auto;
}

.mg-b-auto {
  margin-block-end: auto;
}

.mg-s-auto {
  margin-inline-start: auto;
}

.mg-x-auto {
  margin: auto;
}

@media (min-width: 480px) {
  .mg-xs-0 {
    margin: 0px;
  }

  .mg-xs-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-xs-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-xs-t-0 {
    margin-block-start: 0px;
  }

  .mg-xs-e-0 {
    margin-inline-end: 0px;
  }

  .mg-xs-b-0 {
    margin-block-end: 0px;
  }

  .mg-xs-s-0 {
    margin-inline-start: 0px;
  }

  .mg-xs-1 {
    margin: 1px;
  }

  .mg-xs-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-xs-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-xs-t-1 {
    margin-block-start: 1px;
  }

  .mg-xs-e-1 {
    margin-inline-end: 1px;
  }

  .mg-xs-b-1 {
    margin-block-end: 1px;
  }

  .mg-xs-s-1 {
    margin-inline-start: 1px;
  }

  .mg-xs-2 {
    margin: 2px;
  }

  .mg-xs-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-xs-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-xs-t-2 {
    margin-block-start: 2px;
  }

  .mg-xs-e-2 {
    margin-inline-end: 2px;
  }

  .mg-xs-b-2 {
    margin-block-end: 2px;
  }

  .mg-xs-s-2 {
    margin-inline-start: 2px;
  }

  .mg-xs-3 {
    margin: 3px;
  }

  .mg-xs-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-xs-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-xs-t-3 {
    margin-block-start: 3px;
  }

  .mg-xs-e-3 {
    margin-inline-end: 3px;
  }

  .mg-xs-b-3 {
    margin-block-end: 3px;
  }

  .mg-xs-s-3 {
    margin-inline-start: 3px;
  }

  .mg-xs-4 {
    margin: 4px;
  }

  .mg-xs-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-xs-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-xs-t-4 {
    margin-block-start: 4px;
  }

  .mg-xs-e-4 {
    margin-inline-end: 4px;
  }

  .mg-xs-b-4 {
    margin-block-end: 4px;
  }

  .mg-xs-s-4 {
    margin-inline-start: 4px;
  }

  .mg-xs-5 {
    margin: 5px;
  }

  .mg-xs-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-xs-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-xs-t-5 {
    margin-block-start: 5px;
  }

  .mg-xs-e-5 {
    margin-inline-end: 5px;
  }

  .mg-xs-b-5 {
    margin-block-end: 5px;
  }

  .mg-xs-s-5 {
    margin-inline-start: 5px;
  }

  .mg-xs-6 {
    margin: 6px;
  }

  .mg-xs-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-xs-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-xs-t-6 {
    margin-block-start: 6px;
  }

  .mg-xs-e-6 {
    margin-inline-end: 6px;
  }

  .mg-xs-b-6 {
    margin-block-end: 6px;
  }

  .mg-xs-s-6 {
    margin-inline-start: 6px;
  }

  .mg-xs-7 {
    margin: 7px;
  }

  .mg-xs-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-xs-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-xs-t-7 {
    margin-block-start: 7px;
  }

  .mg-xs-e-7 {
    margin-inline-end: 7px;
  }

  .mg-xs-b-7 {
    margin-block-end: 7px;
  }

  .mg-xs-s-7 {
    margin-inline-start: 7px;
  }

  .mg-xs-8 {
    margin: 8px;
  }

  .mg-xs-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-xs-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-xs-t-8 {
    margin-block-start: 8px;
  }

  .mg-xs-e-8 {
    margin-inline-end: 8px;
  }

  .mg-xs-b-8 {
    margin-block-end: 8px;
  }

  .mg-xs-s-8 {
    margin-inline-start: 8px;
  }

  .mg-xs-9 {
    margin: 9px;
  }

  .mg-xs-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-xs-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-xs-t-9 {
    margin-block-start: 9px;
  }

  .mg-xs-e-9 {
    margin-inline-end: 9px;
  }

  .mg-xs-b-9 {
    margin-block-end: 9px;
  }

  .mg-xs-s-9 {
    margin-inline-start: 9px;
  }

  .mg-xs-10 {
    margin: 10px;
  }

  .mg-xs-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-xs-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-xs-t-10 {
    margin-block-start: 10px;
  }

  .mg-xs-e-10 {
    margin-inline-end: 10px;
  }

  .mg-xs-b-10 {
    margin-block-end: 10px;
  }

  .mg-xs-s-10 {
    margin-inline-start: 10px;
  }

  .mg-xs-15 {
    margin: 15px;
  }

  .mg-xs-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-xs-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-xs-t-15 {
    margin-block-start: 15px;
  }

  .mg-xs-e-15 {
    margin-inline-end: 15px;
  }

  .mg-xs-b-15 {
    margin-block-end: 15px;
  }

  .mg-xs-s-15 {
    margin-inline-start: 15px;
  }

  .mg-xs-20 {
    margin: 20px;
  }

  .mg-xs-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-xs-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-xs-t-20 {
    margin-block-start: 20px;
  }

  .mg-xs-e-20 {
    margin-inline-end: 20px;
  }

  .mg-xs-b-20 {
    margin-block-end: 20px;
  }

  .mg-xs-s-20 {
    margin-inline-start: 20px;
  }

  .mg-xs-25 {
    margin: 25px;
  }

  .mg-xs-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-xs-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-xs-t-25 {
    margin-block-start: 25px;
  }

  .mg-xs-e-25 {
    margin-inline-end: 25px;
  }

  .mg-xs-b-25 {
    margin-block-end: 25px;
  }

  .mg-xs-s-25 {
    margin-inline-start: 25px;
  }

  .mg-xs-30 {
    margin: 30px;
  }

  .mg-xs-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-xs-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-xs-t-30 {
    margin-block-start: 30px;
  }

  .mg-xs-e-30 {
    margin-inline-end: 30px;
  }

  .mg-xs-b-30 {
    margin-block-end: 30px;
  }

  .mg-xs-s-30 {
    margin-inline-start: 30px;
  }

  .mg-xs-35 {
    margin: 35px;
  }

  .mg-xs-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-xs-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-xs-t-35 {
    margin-block-start: 35px;
  }

  .mg-xs-e-35 {
    margin-inline-end: 35px;
  }

  .mg-xs-b-35 {
    margin-block-end: 35px;
  }

  .mg-xs-s-35 {
    margin-inline-start: 35px;
  }

  .mg-xs-40 {
    margin: 40px;
  }

  .mg-xs-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-xs-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-xs-t-40 {
    margin-block-start: 40px;
  }

  .mg-xs-e-40 {
    margin-inline-end: 40px;
  }

  .mg-xs-b-40 {
    margin-block-end: 40px;
  }

  .mg-xs-s-40 {
    margin-inline-start: 40px;
  }

  .mg-xs-t-auto {
    margin-block-start: auto;
  }

  .mg-xs-e-auto {
    margin-inline-end: auto;
  }

  .mg-xs-b-auto {
    margin-block-end: auto;
  }

  .mg-xs-s-auto {
    margin-inline-start: auto;
  }

  .mg-xs-auto {
    margin: auto;
  }

  .mg-xs-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

@media (min-width: 768px) {
  .mg-md-0 {
    margin: 0px;
  }

  .mg-md-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-md-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-md-t-0 {
    margin-block-start: 0px !important;
  }

  .mg-md-e-0 {
    margin-inline-end: 0px;
  }

  .mg-md-b-0 {
    margin-block-end: 0px;
  }

  .mg-md-s-0 {
    margin-inline-start: 0px;
  }

  .mg-md-1 {
    margin: 1px;
  }

  .mg-md-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-md-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-md-t-1 {
    margin-block-start: 1px;
  }

  .mg-md-e-1 {
    margin-inline-end: 1px;
  }

  .mg-md-b-1 {
    margin-block-end: 1px;
  }

  .mg-md-s-1 {
    margin-inline-start: 1px;
  }

  .mg-md-2 {
    margin: 2px;
  }

  .mg-md-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-md-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-md-t-2 {
    margin-block-start: 2px;
  }

  .mg-md-e-2 {
    margin-inline-end: 2px;
  }

  .mg-md-b-2 {
    margin-block-end: 2px;
  }

  .mg-md-s-2 {
    margin-inline-start: 2px;
  }

  .mg-md-3 {
    margin: 3px;
  }

  .mg-md-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-md-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-md-t-3 {
    margin-block-start: 3px;
  }

  .mg-md-e-3 {
    margin-inline-end: 3px;
  }

  .mg-md-b-3 {
    margin-block-end: 3px;
  }

  .mg-md-s-3 {
    margin-inline-start: 3px;
  }

  .mg-md-4 {
    margin: 4px;
  }

  .mg-md-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-md-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-md-t-4 {
    margin-block-start: 4px;
  }

  .mg-md-e-4 {
    margin-inline-end: 4px;
  }

  .mg-md-b-4 {
    margin-block-end: 4px;
  }

  .mg-md-s-4 {
    margin-inline-start: 4px;
  }

  .mg-md-5 {
    margin: 5px;
  }

  .mg-md-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-md-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-md-t-5 {
    margin-block-start: 5px;
  }

  .mg-md-e-5 {
    margin-inline-end: 5px;
  }

  .mg-md-b-5 {
    margin-block-end: 5px;
  }

  .mg-md-s-5 {
    margin-inline-start: 5px;
  }

  .mg-md-6 {
    margin: 6px;
  }

  .mg-md-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-md-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-md-t-6 {
    margin-block-start: 6px;
  }

  .mg-md-e-6 {
    margin-inline-end: 6px;
  }

  .mg-md-b-6 {
    margin-block-end: 6px;
  }

  .mg-md-s-6 {
    margin-inline-start: 6px;
  }

  .mg-md-7 {
    margin: 7px;
  }

  .mg-md-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-md-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-md-t-7 {
    margin-block-start: 7px;
  }

  .mg-md-e-7 {
    margin-inline-end: 7px;
  }

  .mg-md-b-7 {
    margin-block-end: 7px;
  }

  .mg-md-s-7 {
    margin-inline-start: 7px;
  }

  .mg-md-8 {
    margin: 8px;
  }

  .mg-md-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-md-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-md-t-8 {
    margin-block-start: 8px;
  }

  .mg-md-e-8 {
    margin-inline-end: 8px;
  }

  .mg-md-b-8 {
    margin-block-end: 8px;
  }

  .mg-md-s-8 {
    margin-inline-start: 8px;
  }

  .mg-md-9 {
    margin: 9px;
  }

  .mg-md-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-md-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-md-t-9 {
    margin-block-start: 9px;
  }

  .mg-md-e-9 {
    margin-inline-end: 9px;
  }

  .mg-md-b-9 {
    margin-block-end: 9px;
  }

  .mg-md-s-9 {
    margin-inline-start: 9px;
  }

  .mg-md-10 {
    margin: 10px;
  }

  .mg-md-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-md-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-md-t-10 {
    margin-block-start: 10px;
  }

  .mg-md-e-10 {
    margin-inline-end: 10px;
  }

  .mg-md-b-10 {
    margin-block-end: 10px;
  }

  .mg-md-s-10 {
    margin-inline-start: 10px;
  }

  .mg-md-15 {
    margin: 15px;
  }

  .mg-md-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-md-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-md-t-15 {
    margin-block-start: 15px;
  }

  .mg-md-e-15 {
    margin-inline-end: 15px;
  }

  .mg-md-b-15 {
    margin-block-end: 15px;
  }

  .mg-md-s-15 {
    margin-inline-start: 15px;
  }

  .mg-md-20 {
    margin: 20px;
  }

  .mg-md-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-md-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-md-t-20 {
    margin-block-start: 20px;
  }

  .mg-md-e-20 {
    margin-inline-end: 20px;
  }

  .mg-md-b-20 {
    margin-block-end: 20px;
  }

  .mg-md-s-20 {
    margin-inline-start: 20px;
  }

  .mg-md-25 {
    margin: 25px;
  }

  .mg-md-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-md-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-md-t-25 {
    margin-block-start: 25px;
  }

  .mg-md-e-25 {
    margin-inline-end: 25px;
  }

  .mg-md-b-25 {
    margin-block-end: 25px;
  }

  .mg-md-s-25 {
    margin-inline-start: 25px;
  }

  .mg-md-30 {
    margin: 30px;
  }

  .mg-md-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-md-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-md-t-30 {
    margin-block-start: 30px;
  }

  .mg-md-e-30 {
    margin-inline-end: 30px;
  }

  .mg-md-b-30 {
    margin-block-end: 30px;
  }

  .mg-md-s-30 {
    margin-inline-start: 30px;
  }

  .mg-md-35 {
    margin: 35px;
  }

  .mg-md-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-md-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-md-t-35 {
    margin-block-start: 35px;
  }

  .mg-md-e-35 {
    margin-inline-end: 35px;
  }

  .mg-md-b-35 {
    margin-block-end: 35px;
  }

  .mg-md-s-35 {
    margin-inline-start: 35px;
  }

  .mg-md-40 {
    margin: 40px;
  }

  .mg-md-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-md-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-md-t-40 {
    margin-block-start: 40px;
  }

  .mg-md-e-40 {
    margin-inline-end: 40px;
  }

  .mg-md-b-40 {
    margin-block-end: 40px;
  }

  .mg-md-s-40 {
    margin-inline-start: 40px;
  }

  .mg-md-t-auto {
    margin-block-start: auto;
  }

  .mg-md-e-auto {
    margin-inline-end: auto;
  }

  .mg-md-b-auto {
    margin-block-end: auto;
  }

  .mg-md-s-auto {
    margin-inline-start: auto;
  }

  .mg-md-auto {
    margin: auto;
  }

  .mg-md-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

@media (min-width: 992px) {
  .mg-sg-0 {
    margin: 0px;
  }

  .mg-sg-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-sg-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-sg-t-0 {
    margin-block-start: 0px;
  }

  .mg-sg-e-0 {
    margin-inline-end: 0px;
  }

  .mg-sg-b-0 {
    margin-block-end: 0px;
  }

  .mg-sg-s-0 {
    margin-inline-start: 0px;
  }

  .mg-sg-1 {
    margin: 1px;
  }

  .mg-sg-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-sg-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-sg-t-1 {
    margin-block-start: 1px;
  }

  .mg-sg-e-1 {
    margin-inline-end: 1px;
  }

  .mg-sg-b-1 {
    margin-block-end: 1px;
  }

  .mg-sg-s-1 {
    margin-inline-start: 1px;
  }

  .mg-sg-2 {
    margin: 2px;
  }

  .mg-sg-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-sg-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-sg-t-2 {
    margin-block-start: 2px;
  }

  .mg-sg-e-2 {
    margin-inline-end: 2px;
  }

  .mg-sg-b-2 {
    margin-block-end: 2px;
  }

  .mg-sg-s-2 {
    margin-inline-start: 2px;
  }

  .mg-sg-3 {
    margin: 3px;
  }

  .mg-sg-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-sg-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-sg-t-3 {
    margin-block-start: 3px;
  }

  .mg-sg-e-3 {
    margin-inline-end: 3px;
  }

  .mg-sg-b-3 {
    margin-block-end: 3px;
  }

  .mg-sg-s-3 {
    margin-inline-start: 3px;
  }

  .mg-sg-4 {
    margin: 4px;
  }

  .mg-sg-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-sg-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-sg-t-4 {
    margin-block-start: 4px;
  }

  .mg-sg-e-4 {
    margin-inline-end: 4px;
  }

  .mg-sg-b-4 {
    margin-block-end: 4px;
  }

  .mg-sg-s-4 {
    margin-inline-start: 4px;
  }

  .mg-sg-5 {
    margin: 5px;
  }

  .mg-sg-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-sg-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-sg-t-5 {
    margin-block-start: 5px;
  }

  .mg-sg-e-5 {
    margin-inline-end: 5px;
  }

  .mg-sg-b-5 {
    margin-block-end: 5px;
  }

  .mg-sg-s-5 {
    margin-inline-start: 5px;
  }

  .mg-sg-6 {
    margin: 6px;
  }

  .mg-sg-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-sg-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-sg-t-6 {
    margin-block-start: 6px;
  }

  .mg-sg-e-6 {
    margin-inline-end: 6px;
  }

  .mg-sg-b-6 {
    margin-block-end: 6px;
  }

  .mg-sg-s-6 {
    margin-inline-start: 6px;
  }

  .mg-sg-7 {
    margin: 7px;
  }

  .mg-sg-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-sg-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-sg-t-7 {
    margin-block-start: 7px;
  }

  .mg-sg-e-7 {
    margin-inline-end: 7px;
  }

  .mg-sg-b-7 {
    margin-block-end: 7px;
  }

  .mg-sg-s-7 {
    margin-inline-start: 7px;
  }

  .mg-sg-8 {
    margin: 8px;
  }

  .mg-sg-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-sg-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-sg-t-8 {
    margin-block-start: 8px;
  }

  .mg-sg-e-8 {
    margin-inline-end: 8px;
  }

  .mg-sg-b-8 {
    margin-block-end: 8px;
  }

  .mg-sg-s-8 {
    margin-inline-start: 8px;
  }

  .mg-sg-9 {
    margin: 9px;
  }

  .mg-sg-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-sg-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-sg-t-9 {
    margin-block-start: 9px;
  }

  .mg-sg-e-9 {
    margin-inline-end: 9px;
  }

  .mg-sg-b-9 {
    margin-block-end: 9px;
  }

  .mg-sg-s-9 {
    margin-inline-start: 9px;
  }

  .mg-sg-10 {
    margin: 10px;
  }

  .mg-sg-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-sg-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-sg-t-10 {
    margin-block-start: 10px;
  }

  .mg-sg-e-10 {
    margin-inline-end: 10px;
  }

  .mg-sg-b-10 {
    margin-block-end: 10px;
  }

  .mg-sg-s-10 {
    margin-inline-start: 10px;
  }

  .mg-sg-15 {
    margin: 15px;
  }

  .mg-sg-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-sg-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-sg-t-15 {
    margin-block-start: 15px;
  }

  .mg-sg-e-15 {
    margin-inline-end: 15px;
  }

  .mg-sg-b-15 {
    margin-block-end: 15px;
  }

  .mg-sg-s-15 {
    margin-inline-start: 15px;
  }

  .mg-sg-20 {
    margin: 20px;
  }

  .mg-sg-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-sg-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-sg-t-20 {
    margin-block-start: 20px;
  }

  .mg-sg-e-20 {
    margin-inline-end: 20px;
  }

  .mg-sg-b-20 {
    margin-block-end: 20px;
  }

  .mg-sg-s-20 {
    margin-inline-start: 20px;
  }

  .mg-sg-25 {
    margin: 25px;
  }

  .mg-sg-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-sg-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-sg-t-25 {
    margin-block-start: 25px;
  }

  .mg-sg-e-25 {
    margin-inline-end: 25px;
  }

  .mg-sg-b-25 {
    margin-block-end: 25px;
  }

  .mg-sg-s-25 {
    margin-inline-start: 25px;
  }

  .mg-sg-30 {
    margin: 30px;
  }

  .mg-sg-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-sg-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-sg-t-30 {
    margin-block-start: 30px;
  }

  .mg-sg-e-30 {
    margin-inline-end: 30px;
  }

  .mg-sg-b-30 {
    margin-block-end: 30px;
  }

  .mg-sg-s-30 {
    margin-inline-start: 30px;
  }

  .mg-sg-35 {
    margin: 35px;
  }

  .mg-sg-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-sg-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-sg-t-35 {
    margin-block-start: 35px;
  }

  .mg-sg-e-35 {
    margin-inline-end: 35px;
  }

  .mg-sg-b-35 {
    margin-block-end: 35px;
  }

  .mg-sg-s-35 {
    margin-inline-start: 35px;
  }

  .mg-sg-40 {
    margin: 40px;
  }

  .mg-sg-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-sg-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-sg-t-40 {
    margin-block-start: 40px;
  }

  .mg-sg-e-40 {
    margin-inline-end: 40px;
  }

  .mg-sg-b-40 {
    margin-block-end: 40px;
  }

  .mg-sg-s-40 {
    margin-inline-start: 40px;
  }

  .mg-sg-t-auto {
    margin-block-start: auto;
  }

  .mg-sg-e-auto {
    margin-inline-end: auto;
  }

  .mg-sg-b-auto {
    margin-block-end: auto;
  }

  .mg-sg-s-auto {
    margin-inline-start: auto;
  }

  .mg-sg-auto {
    margin: auto;
  }

  .mg-sg-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

@media (min-width: 1200px) {
  .mg-xl-0 {
    margin: 0px;
  }

  .mg-xl-y-0 {
    margin-block-start: 0px;
    margin-block-end: 0px;
  }

  .mg-xl-x-0 {
    margin-inline-start: 0px;
    margin-inline-end: 0px;
  }

  .mg-xl-t-0 {
    margin-block-start: 0px;
  }

  .mg-xl-e-0 {
    margin-inline-end: 0px;
  }

  .mg-xl-b-0 {
    margin-block-end: 0px;
  }

  .mg-xl-s-0 {
    margin-inline-start: 0px;
  }

  .mg-xl-1 {
    margin: 1px;
  }

  .mg-xl-y-1 {
    margin-block-start: 1px;
    margin-block-end: 1px;
  }

  .mg-xl-x-1 {
    margin-inline-start: 1px;
    margin-inline-end: 1px;
  }

  .mg-xl-t-1 {
    margin-block-start: 1px;
  }

  .mg-xl-e-1 {
    margin-inline-end: 1px;
  }

  .mg-xl-b-1 {
    margin-block-end: 1px;
  }

  .mg-xl-s-1 {
    margin-inline-start: 1px;
  }

  .mg-xl-2 {
    margin: 2px;
  }

  .mg-xl-y-2 {
    margin-block-start: 2px;
    margin-block-end: 2px;
  }

  .mg-xl-x-2 {
    margin-inline-start: 2px;
    margin-inline-end: 2px;
  }

  .mg-xl-t-2 {
    margin-block-start: 2px;
  }

  .mg-xl-e-2 {
    margin-inline-end: 2px;
  }

  .mg-xl-b-2 {
    margin-block-end: 2px;
  }

  .mg-xl-s-2 {
    margin-inline-start: 2px;
  }

  .mg-xl-3 {
    margin: 3px;
  }

  .mg-xl-y-3 {
    margin-block-start: 3px;
    margin-block-end: 3px;
  }

  .mg-xl-x-3 {
    margin-inline-start: 3px;
    margin-inline-end: 3px;
  }

  .mg-xl-t-3 {
    margin-block-start: 3px;
  }

  .mg-xl-e-3 {
    margin-inline-end: 3px;
  }

  .mg-xl-b-3 {
    margin-block-end: 3px;
  }

  .mg-xl-s-3 {
    margin-inline-start: 3px;
  }

  .mg-xl-4 {
    margin: 4px;
  }

  .mg-xl-y-4 {
    margin-block-start: 4px;
    margin-block-end: 4px;
  }

  .mg-xl-x-4 {
    margin-inline-start: 4px;
    margin-inline-end: 4px;
  }

  .mg-xl-t-4 {
    margin-block-start: 4px;
  }

  .mg-xl-e-4 {
    margin-inline-end: 4px;
  }

  .mg-xl-b-4 {
    margin-block-end: 4px;
  }

  .mg-xl-s-4 {
    margin-inline-start: 4px;
  }

  .mg-xl-5 {
    margin: 5px;
  }

  .mg-xl-y-5 {
    margin-block-start: 5px;
    margin-block-end: 5px;
  }

  .mg-xl-x-5 {
    margin-inline-start: 5px;
    margin-inline-end: 5px;
  }

  .mg-xl-t-5 {
    margin-block-start: 5px;
  }

  .mg-xl-e-5 {
    margin-inline-end: 5px;
  }

  .mg-xl-b-5 {
    margin-block-end: 5px;
  }

  .mg-xl-s-5 {
    margin-inline-start: 5px;
  }

  .mg-xl-6 {
    margin: 6px;
  }

  .mg-xl-y-6 {
    margin-block-start: 6px;
    margin-block-end: 6px;
  }

  .mg-xl-x-6 {
    margin-inline-start: 6px;
    margin-inline-end: 6px;
  }

  .mg-xl-t-6 {
    margin-block-start: 6px;
  }

  .mg-xl-e-6 {
    margin-inline-end: 6px;
  }

  .mg-xl-b-6 {
    margin-block-end: 6px;
  }

  .mg-xl-s-6 {
    margin-inline-start: 6px;
  }

  .mg-xl-7 {
    margin: 7px;
  }

  .mg-xl-y-7 {
    margin-block-start: 7px;
    margin-block-end: 7px;
  }

  .mg-xl-x-7 {
    margin-inline-start: 7px;
    margin-inline-end: 7px;
  }

  .mg-xl-t-7 {
    margin-block-start: 7px;
  }

  .mg-xl-e-7 {
    margin-inline-end: 7px;
  }

  .mg-xl-b-7 {
    margin-block-end: 7px;
  }

  .mg-xl-s-7 {
    margin-inline-start: 7px;
  }

  .mg-xl-8 {
    margin: 8px;
  }

  .mg-xl-y-8 {
    margin-block-start: 8px;
    margin-block-end: 8px;
  }

  .mg-xl-x-8 {
    margin-inline-start: 8px;
    margin-inline-end: 8px;
  }

  .mg-xl-t-8 {
    margin-block-start: 8px;
  }

  .mg-xl-e-8 {
    margin-inline-end: 8px;
  }

  .mg-xl-b-8 {
    margin-block-end: 8px;
  }

  .mg-xl-s-8 {
    margin-inline-start: 8px;
  }

  .mg-xl-9 {
    margin: 9px;
  }

  .mg-xl-y-9 {
    margin-block-start: 9px;
    margin-block-end: 9px;
  }

  .mg-xl-x-9 {
    margin-inline-start: 9px;
    margin-inline-end: 9px;
  }

  .mg-xl-t-9 {
    margin-block-start: 9px;
  }

  .mg-xl-e-9 {
    margin-inline-end: 9px;
  }

  .mg-xl-b-9 {
    margin-block-end: 9px;
  }

  .mg-xl-s-9 {
    margin-inline-start: 9px;
  }

  .mg-xl-10 {
    margin: 10px;
  }

  .mg-xl-y-10 {
    margin-block-start: 10px;
    margin-block-end: 10px;
  }

  .mg-xl-x-10 {
    margin-inline-start: 10px;
    margin-inline-end: 10px;
  }

  .mg-xl-t-10 {
    margin-block-start: 10px;
  }

  .mg-xl-e-10 {
    margin-inline-end: 10px;
  }

  .mg-xl-b-10 {
    margin-block-end: 10px;
  }

  .mg-xl-s-10 {
    margin-inline-start: 10px;
  }

  .mg-xl-15 {
    margin: 15px;
  }

  .mg-xl-y-15 {
    margin-block-start: 15px;
    margin-block-end: 15px;
  }

  .mg-xl-x-15 {
    margin-inline-start: 15px;
    margin-inline-end: 15px;
  }

  .mg-xl-t-15 {
    margin-block-start: 15px;
  }

  .mg-xl-e-15 {
    margin-inline-end: 15px;
  }

  .mg-xl-b-15 {
    margin-block-end: 15px;
  }

  .mg-xl-s-15 {
    margin-inline-start: 15px;
  }

  .mg-xl-20 {
    margin: 20px;
  }

  .mg-xl-y-20 {
    margin-block-start: 20px;
    margin-block-end: 20px;
  }

  .mg-xl-x-20 {
    margin-inline-start: 20px;
    margin-inline-end: 20px;
  }

  .mg-xl-t-20 {
    margin-block-start: 20px;
  }

  .mg-xl-e-20 {
    margin-inline-end: 20px;
  }

  .mg-xl-b-20 {
    margin-block-end: 20px;
  }

  .mg-xl-s-20 {
    margin-inline-start: 20px;
  }

  .mg-xl-25 {
    margin: 25px;
  }

  .mg-xl-y-25 {
    margin-block-start: 25px;
    margin-block-end: 25px;
  }

  .mg-xl-x-25 {
    margin-inline-start: 25px;
    margin-inline-end: 25px;
  }

  .mg-xl-t-25 {
    margin-block-start: 25px;
  }

  .mg-xl-e-25 {
    margin-inline-end: 25px;
  }

  .mg-xl-b-25 {
    margin-block-end: 25px;
  }

  .mg-xl-s-25 {
    margin-inline-start: 25px;
  }

  .mg-xl-30 {
    margin: 30px;
  }

  .mg-xl-y-30 {
    margin-block-start: 30px;
    margin-block-end: 30px;
  }

  .mg-xl-x-30 {
    margin-inline-start: 30px;
    margin-inline-end: 30px;
  }

  .mg-xl-t-30 {
    margin-block-start: 30px;
  }

  .mg-xl-e-30 {
    margin-inline-end: 30px;
  }

  .mg-xl-b-30 {
    margin-block-end: 30px;
  }

  .mg-xl-s-30 {
    margin-inline-start: 30px;
  }

  .mg-xl-35 {
    margin: 35px;
  }

  .mg-xl-y-35 {
    margin-block-start: 35px;
    margin-block-end: 35px;
  }

  .mg-xl-x-35 {
    margin-inline-start: 35px;
    margin-inline-end: 35px;
  }

  .mg-xl-t-35 {
    margin-block-start: 35px;
  }

  .mg-xl-e-35 {
    margin-inline-end: 35px;
  }

  .mg-xl-b-35 {
    margin-block-end: 35px;
  }

  .mg-xl-s-35 {
    margin-inline-start: 35px;
  }

  .mg-xl-40 {
    margin: 40px;
  }

  .mg-xl-y-40 {
    margin-block-start: 40px;
    margin-block-end: 40px;
  }

  .mg-xl-x-40 {
    margin-inline-start: 40px;
    margin-inline-end: 40px;
  }

  .mg-xl-t-40 {
    margin-block-start: 40px;
  }

  .mg-xl-e-40 {
    margin-inline-end: 40px;
  }

  .mg-xl-b-40 {
    margin-block-end: 40px;
  }

  .mg-xl-s-40 {
    margin-inline-start: 40px;
  }

  .mg-xl-t-auto {
    margin-block-start: auto;
  }

  .mg-xl-e-auto {
    margin-inline-end: auto;
  }

  .mg-xl-b-auto {
    margin-block-end: auto;
  }

  .mg-xl-s-auto {
    margin-inline-start: auto;
  }

  .mg-xl-auto {
    margin: auto;
  }

  .mg-xl-x-auto {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
}

/* ###### 9.5 Misc   ###### */
.op-0 {
  opacity: 0;
}

.op-0-f {
  opacity: 0;
}

.op-1 {
  opacity: 0.1;
}

.op-1-f {
  opacity: 0.1;
}

.op-2 {
  opacity: 0.2;
}

.op-2-f {
  opacity: 0.2;
}

.op-3 {
  opacity: 0.3;
}

.op-3-f {
  opacity: 0.3;
}

.op-4 {
  opacity: 0.4;
}

.op-4-f {
  opacity: 0.4;
}

.op-5 {
  opacity: 0.5;
}

.op-5-f {
  opacity: 0.5;
}

.op-6 {
  opacity: 0.6;
}

.op-6-f {
  opacity: 0.6;
}

.op-7 {
  opacity: 0.7;
}

.op-7-f {
  opacity: 0.7;
}

.op-8 {
  opacity: 0.8;
}

.op-8-f {
  opacity: 0.8;
}

.op-9 {
  opacity: 0.9;
}

.op-9-f {
  opacity: 0.9;
}

.opacity-1 {
  opacity: 1;
}

.shadow-base {
  box-shadow: 0 0 2px 2px rgba(28, 39, 60, 0.03);
}

.shadow-1 {
  box-shadow: 0 0 7px 7px rgba(28, 39, 60, 0.1);
}

.shadow-2 {
  box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
}

.shadow-none {
  box-shadow: none;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

@media (min-width: 480px) {
  .op-xs-0 {
    opacity: 0;
  }

  .op-xs-0-f {
    opacity: 0;
  }

  .op-xs-1 {
    opacity: 0.1;
  }

  .op-xs-1-f {
    opacity: 0.1;
  }

  .op-xs-2 {
    opacity: 0.2;
  }

  .op-xs-2-f {
    opacity: 0.2;
  }

  .op-xs-3 {
    opacity: 0.3;
  }

  .op-xs-3-f {
    opacity: 0.3;
  }

  .op-xs-4 {
    opacity: 0.4;
  }

  .op-xs-4-f {
    opacity: 0.4;
  }

  .op-xs-5 {
    opacity: 0.5;
  }

  .op-xs-5-f {
    opacity: 0.5;
  }

  .op-xs-6 {
    opacity: 0.6;
  }

  .op-xs-6-f {
    opacity: 0.6;
  }

  .op-xs-7 {
    opacity: 0.7;
  }

  .op-xs-7-f {
    opacity: 0.7;
  }

  .op-xs-8 {
    opacity: 0.8;
  }

  .op-xs-8-f {
    opacity: 0.8;
  }

  .op-xs-9 {
    opacity: 0.9;
  }

  .op-xs-9-f {
    opacity: 0.9;
  }
}

@media (min-width: 576px) {
  .op-sm-0 {
    opacity: 0;
  }

  .op-sm-0-f {
    opacity: 0;
  }

  .op-sm-1 {
    opacity: 0.1;
  }

  .op-sm-1-f {
    opacity: 0.1;
  }

  .op-sm-2 {
    opacity: 0.2;
  }

  .op-sm-2-f {
    opacity: 0.2;
  }

  .op-sm-3 {
    opacity: 0.3;
  }

  .op-sm-3-f {
    opacity: 0.3;
  }

  .op-sm-4 {
    opacity: 0.4;
  }

  .op-sm-4-f {
    opacity: 0.4;
  }

  .op-sm-5 {
    opacity: 0.5;
  }

  .op-sm-5-f {
    opacity: 0.5;
  }

  .op-sm-6 {
    opacity: 0.6;
  }

  .op-sm-6-f {
    opacity: 0.6;
  }

  .op-sm-7 {
    opacity: 0.7;
  }

  .op-sm-7-f {
    opacity: 0.7;
  }

  .op-sm-8 {
    opacity: 0.8;
  }

  .op-sm-8-f {
    opacity: 0.8;
  }

  .op-sm-9 {
    opacity: 0.9;
  }

  .op-sm-9-f {
    opacity: 0.9;
  }
}

@media (min-width: 768px) {
  .op-md-0 {
    opacity: 0;
  }

  .op-md-0-f {
    opacity: 0;
  }

  .op-md-1 {
    opacity: 0.1;
  }

  .op-md-1-f {
    opacity: 0.1;
  }

  .op-md-2 {
    opacity: 0.2;
  }

  .op-md-2-f {
    opacity: 0.2;
  }

  .op-md-3 {
    opacity: 0.3;
  }

  .op-md-3-f {
    opacity: 0.3;
  }

  .op-md-4 {
    opacity: 0.4;
  }

  .op-md-4-f {
    opacity: 0.4;
  }

  .op-md-5 {
    opacity: 0.5;
  }

  .op-md-5-f {
    opacity: 0.5;
  }

  .op-md-6 {
    opacity: 0.6;
  }

  .op-md-6-f {
    opacity: 0.6;
  }

  .op-md-7 {
    opacity: 0.7;
  }

  .op-md-7-f {
    opacity: 0.7;
  }

  .op-md-8 {
    opacity: 0.8;
  }

  .op-md-8-f {
    opacity: 0.8;
  }

  .op-md-9 {
    opacity: 0.9;
  }

  .op-md-9-f {
    opacity: 0.9;
  }
}

@media (min-width: 992px) {
  .op-lg-0 {
    opacity: 0;
  }

  .op-lg-0-f {
    opacity: 0;
  }

  .op-lg-1 {
    opacity: 0.1;
  }

  .op-lg-1-f {
    opacity: 0.1;
  }

  .op-lg-2 {
    opacity: 0.2;
  }

  .op-lg-2-f {
    opacity: 0.2;
  }

  .op-lg-3 {
    opacity: 0.3;
  }

  .op-lg-3-f {
    opacity: 0.3;
  }

  .op-lg-4 {
    opacity: 0.4;
  }

  .op-lg-4-f {
    opacity: 0.4;
  }

  .op-lg-5 {
    opacity: 0.5;
  }

  .op-lg-5-f {
    opacity: 0.5;
  }

  .op-lg-6 {
    opacity: 0.6;
  }

  .op-lg-6-f {
    opacity: 0.6;
  }

  .op-lg-7 {
    opacity: 0.7;
  }

  .op-lg-7-f {
    opacity: 0.7;
  }

  .op-lg-8 {
    opacity: 0.8;
  }

  .op-lg-8-f {
    opacity: 0.8;
  }

  .op-lg-9 {
    opacity: 0.9;
  }

  .op-lg-9-f {
    opacity: 0.9;
  }
}

@media (min-width: 1200px) {
  .op-xl-0 {
    opacity: 0;
  }

  .op-xl-0-f {
    opacity: 0;
  }

  .op-xl-1 {
    opacity: 0.1;
  }

  .op-xl-1-f {
    opacity: 0.1;
  }

  .op-xl-2 {
    opacity: 0.2;
  }

  .op-xl-2-f {
    opacity: 0.2;
  }

  .op-xl-3 {
    opacity: 0.3;
  }

  .op-xl-3-f {
    opacity: 0.3;
  }

  .op-xl-4 {
    opacity: 0.4;
  }

  .op-xl-4-f {
    opacity: 0.4;
  }

  .op-xl-5 {
    opacity: 0.5;
  }

  .op-xl-5-f {
    opacity: 0.5;
  }

  .op-xl-6 {
    opacity: 0.6;
  }

  .op-xl-6-f {
    opacity: 0.6;
  }

  .op-xl-7 {
    opacity: 0.7;
  }

  .op-xl-7-f {
    opacity: 0.7;
  }

  .op-xl-8 {
    opacity: 0.8;
  }

  .op-xl-8-f {
    opacity: 0.8;
  }

  .op-xl-9 {
    opacity: 0.9;
  }

  .op-xl-9-f {
    opacity: 0.9;
  }
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-block-start: 0 !important;
}

.pe-0,
.px-0 {
  padding-inline-end: 0 !important;
}

.pb-0,
.py-0 {
  padding-block-end: 0 !important;
}

.ps-0,
.px-0 {
  padding-inline-start: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-block-start: 0.25rem !important;
}

.pe-1,
.px-1 {
  padding-inline-end: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-block-end: 0.25rem !important;
}

.ps-1,
.px-1 {
  padding-inline-start: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-block-start: 0.5rem !important;
}

.pe-2,
.px-2 {
  padding-inline-end: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-block-end: 0.5rem !important;
}

.ps-2,
.px-2 {
  padding-inline-start: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-block-start: 1rem !important;
}

.pe-3,
.px-3 {
  padding-inline-end: 1rem !important;
}

.pb-3,
.py-3 {
  padding-block-end: 1rem !important;
}

.ps-3,
.px-3 {
  padding-inline-start: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-block-start: 1.5rem !important;
}

.pe-4,
.px-4 {
  padding-inline-end: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-block-end: 1.5rem !important;
}

.ps-4,
.px-4 {
  padding-inline-start: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-block-start: 3rem !important;
}

.pe-5,
.px-5 {
  padding-inline-end: 3rem !important;
}

.pb-5,
.py-5 {
  padding-block-end: 3rem !important;
}

.ps-5,
.px-5 {
  padding-inline-start: 3rem !important;
}

.p-2rem {
  padding: 2rem !important;
}

.pt-2rem,
.py-2rem {
  padding-block-start: 2rem !important;
}

.pe-2rem,
.px-2rem {
  padding-inline-end: 2rem !important;
}

.pb-2rem,
.py-2rem {
  padding-block-end: 2rem !important;
}

.ps-2rem,
.px-2rem {
  padding-inline-start: 2rem !important;
}

@media (min-width: 576px) {
  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
  .py-sm-0 {
    padding-block-start: 0 !important;
  }

  .pe-sm-0,
  .px-sm-0 {
    padding-inline-end: 0 !important;
  }

  .pb-sm-0,
  .py-sm-0 {
    padding-block-end: 0 !important;
  }

  .ps-sm-0,
  .px-sm-0 {
    padding-inline-start: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
  .py-sm-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-sm-1,
  .px-sm-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-sm-1,
  .py-sm-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-sm-1,
  .px-sm-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
  .py-sm-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-sm-2,
  .px-sm-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-sm-2,
  .py-sm-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-sm-2,
  .px-sm-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
  .py-sm-3 {
    padding-block-start: 1rem !important;
  }

  .pe-sm-3,
  .px-sm-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-sm-3,
  .py-sm-3 {
    padding-block-end: 1rem !important;
  }

  .ps-sm-3,
  .px-sm-3 {
    padding-inline-start: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
  .py-sm-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-sm-4,
  .px-sm-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-sm-4,
  .py-sm-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-sm-4,
  .px-sm-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
  .py-sm-5 {
    padding-block-start: 3rem !important;
  }

  .pe-sm-5,
  .px-sm-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-sm-5,
  .py-sm-5 {
    padding-block-end: 3rem !important;
  }

  .ps-sm-5,
  .px-sm-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 768px) {
  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
  .py-md-0 {
    padding-block-start: 0 !important;
  }

  .pe-md-0,
  .px-md-0 {
    padding-inline-end: 0 !important;
  }

  .pb-md-0,
  .py-md-0 {
    padding-block-end: 0 !important;
  }

  .ps-md-0,
  .px-md-0 {
    padding-inline-start: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
  .py-md-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-md-1,
  .px-md-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-md-1,
  .py-md-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-md-1,
  .px-md-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
  .py-md-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-md-2,
  .px-md-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-md-2,
  .py-md-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-md-2,
  .px-md-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
  .py-md-3 {
    padding-block-start: 1rem !important;
  }

  .pe-md-3,
  .px-md-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-md-3,
  .py-md-3 {
    padding-block-end: 1rem !important;
  }

  .ps-md-3,
  .px-md-3 {
    padding-inline-start: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
  .py-md-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-md-4,
  .px-md-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-md-4,
  .py-md-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-md-4,
  .px-md-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
  .py-md-5 {
    padding-block-start: 3rem !important;
  }

  .pe-md-5,
  .px-md-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-md-5,
  .py-md-5 {
    padding-block-end: 3rem !important;
  }

  .ps-md-5,
  .px-md-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 992px) {
  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
  .py-lg-0 {
    padding-block-start: 0 !important;
  }

  .pe-lg-0,
  .px-lg-0 {
    padding-inline-end: 0 !important;
  }

  .pb-lg-0,
  .py-lg-0 {
    padding-block-end: 0 !important;
  }

  .ps-lg-0,
  .px-lg-0 {
    padding-inline-start: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
  .py-lg-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-lg-1,
  .px-lg-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-lg-1,
  .py-lg-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-lg-1,
  .px-lg-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
  .py-lg-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-lg-2,
  .px-lg-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-lg-2,
  .py-lg-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-lg-2,
  .px-lg-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
  .py-lg-3 {
    padding-block-start: 1rem !important;
  }

  .pe-lg-3,
  .px-lg-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-lg-3,
  .py-lg-3 {
    padding-block-end: 1rem !important;
  }

  .ps-lg-3,
  .px-lg-3 {
    padding-inline-start: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
  .py-lg-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-lg-4,
  .px-lg-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-lg-4,
  .py-lg-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-lg-4,
  .px-lg-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
  .py-lg-5 {
    padding-block-start: 3rem !important;
  }

  .pe-lg-5,
  .px-lg-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-lg-5,
  .py-lg-5 {
    padding-block-end: 3rem !important;
  }

  .ps-lg-5,
  .px-lg-5 {
    padding-inline-start: 3rem !important;
  }
}

@media (min-width: 1200px) {
  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
  .py-xl-0 {
    padding-block-start: 0 !important;
  }

  .pe-xl-0,
  .px-xl-0 {
    padding-inline-end: 0 !important;
  }

  .pb-xl-0,
  .py-xl-0 {
    padding-block-end: 0 !important;
  }

  .ps-xl-0,
  .px-xl-0 {
    padding-inline-start: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
  .py-xl-1 {
    padding-block-start: 0.25rem !important;
  }

  .pe-xl-1,
  .px-xl-1 {
    padding-inline-end: 0.25rem !important;
  }

  .pb-xl-1,
  .py-xl-1 {
    padding-block-end: 0.25rem !important;
  }

  .ps-xl-1,
  .px-xl-1 {
    padding-inline-start: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
  .py-xl-2 {
    padding-block-start: 0.5rem !important;
  }

  .pe-xl-2,
  .px-xl-2 {
    padding-inline-end: 0.5rem !important;
  }

  .pb-xl-2,
  .py-xl-2 {
    padding-block-end: 0.5rem !important;
  }

  .ps-xl-2,
  .px-xl-2 {
    padding-inline-start: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
  .py-xl-3 {
    padding-block-start: 1rem !important;
  }

  .pe-xl-3,
  .px-xl-3 {
    padding-inline-end: 1rem !important;
  }

  .pb-xl-3,
  .py-xl-3 {
    padding-block-end: 1rem !important;
  }

  .ps-xl-3,
  .px-xl-3 {
    padding-inline-start: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
  .py-xl-4 {
    padding-block-start: 1.5rem !important;
  }

  .pe-xl-4,
  .px-xl-4 {
    padding-inline-end: 1.5rem !important;
  }

  .pb-xl-4,
  .py-xl-4 {
    padding-block-end: 1.5rem !important;
  }

  .ps-xl-4,
  .px-xl-4 {
    padding-inline-start: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
  .py-xl-5 {
    padding-block-start: 3rem !important;
  }

  .pe-xl-5,
  .px-xl-5 {
    padding-inline-end: 3rem !important;
  }

  .pb-xl-5,
  .py-xl-5 {
    padding-block-end: 3rem !important;
  }

  .ps-xl-5,
  .px-xl-5 {
    padding-inline-start: 3rem !important;
  }
}

/* ###### 9.6 Padding   ###### */
.pd-0 {
  padding: 0px;
}

.pd-y-0 {
  padding-block-start: 0px;
  padding-block-end: 0px;
}

.pd-x-0 {
  padding-inline-start: 0px;
  padding-inline-end: 0px;
}

.pd-t-0 {
  padding-block-start: 0px !important;
}

.pd-e-0 {
  padding-inline-end: 0px !important;
}

.pd-b-0 {
  padding-block-end: 0px !important;
}

.pd-s-0 {
  padding-inline-start: 0px !important;
}

.pd-1 {
  padding: 1px;
}

.pd-y-1 {
  padding-block-start: 1px;
  padding-block-end: 1px;
}

.pd-x-1 {
  padding-inline-start: 1px;
  padding-inline-end: 1px;
}

.pd-t-1 {
  padding-block-start: 1px;
}

.pd-e-1 {
  padding-inline-end: 1px;
}

.pd-b-1 {
  padding-block-end: 1px;
}

.pd-s-1 {
  padding-inline-start: 1px;
}

.pd-2 {
  padding: 2px;
}

.pd-y-2 {
  padding-block-start: 2px;
  padding-block-end: 2px;
}

.pd-x-2 {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}

.pd-t-2 {
  padding-block-start: 2px;
}

.pd-e-2 {
  padding-inline-end: 2px;
}

.pd-b-2 {
  padding-block-end: 2px;
}

.pd-s-2 {
  padding-inline-start: 2px;
}

.pd-3 {
  padding: 3px;
}

.pd-y-3 {
  padding-block-start: 3px;
  padding-block-end: 3px;
}

.pd-x-3 {
  padding-inline-start: 3px;
  padding-inline-end: 3px;
}

.pd-t-3 {
  padding-block-start: 3px;
}

.pd-e-3 {
  padding-inline-end: 3px;
}

.pd-b-3 {
  padding-block-end: 3px;
}

.pd-s-3 {
  padding-inline-start: 3px;
}

.pd-4 {
  padding: 4px;
}

.pd-y-4 {
  padding-block-start: 4px;
  padding-block-end: 4px;
}

.pd-x-4 {
  padding-inline-start: 4px;
  padding-inline-end: 4px;
}

.pd-t-4 {
  padding-block-start: 4px;
}

.pd-e-4 {
  padding-inline-end: 4px;
}

.pd-b-4 {
  padding-block-end: 4px;
}

.pd-s-4 {
  padding-inline-start: 4px;
}

.pd-5 {
  padding: 5px;
}

.pd-y-5 {
  padding-block-start: 5px;
  padding-block-end: 5px;
}

.pd-x-5 {
  padding-inline-start: 5px;
  padding-inline-end: 5px;
}

.pd-t-5 {
  padding-block-start: 5px;
}

.pd-e-5 {
  padding-inline-end: 5px;
}

.pd-b-5 {
  padding-block-end: 5px;
}

.pd-s-5 {
  padding-inline-start: 5px;
}

.pd-6 {
  padding: 6px;
}

.pd-y-6 {
  padding-block-start: 6px;
  padding-block-end: 6px;
}

.pd-x-6 {
  padding-inline-start: 6px;
  padding-inline-end: 6px;
}

.pd-t-6 {
  padding-block-start: 6px !important;
}

.pd-e-6 {
  padding-inline-end: 6px;
}

.pd-b-6 {
  padding-block-end: 6px;
}

.pd-s-6 {
  padding-inline-start: 6px;
}

.pd-7 {
  padding: 7px;
}

.pd-y-7 {
  padding-block-start: 7px;
  padding-block-end: 7px;
}

.pd-x-7 {
  padding-inline-start: 7px;
  padding-inline-end: 7px;
}

.pd-t-7 {
  padding-block-start: 7px;
}

.pd-e-7 {
  padding-inline-end: 7px;
}

.pd-b-7 {
  padding-block-end: 7px;
}

.pd-s-7 {
  padding-inline-start: 7px;
}

.pd-8 {
  padding: 8px;
}

.pd-y-8 {
  padding-block-start: 8px;
  padding-block-end: 8px;
}

.pd-x-8 {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
}

.pd-t-8 {
  padding-block-start: 8px;
}

.pd-e-8 {
  padding-inline-end: 8px;
}

.pd-b-8 {
  padding-block-end: 8px;
}

.pd-s-8 {
  padding-inline-start: 8px;
}

.pd-9 {
  padding: 9px;
}

.pd-y-9 {
  padding-block-start: 9px;
  padding-block-end: 9px;
}

.pd-x-9 {
  padding-inline-start: 9px;
  padding-inline-end: 9px;
}

.pd-t-9 {
  padding-block-start: 9px;
}

.pd-e-9 {
  padding-inline-end: 9px;
}

.pd-b-9 {
  padding-block-end: 9px;
}

.pd-s-9 {
  padding-inline-start: 9px;
}

.pd-10 {
  padding: 10px;
}

.pd-y-10 {
  padding-block-start: 10px;
  padding-block-end: 10px;
}

.pd-x-10 {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
}

.pd-t-10 {
  padding-block-start: 10px;
}

.pd-e-10 {
  padding-inline-end: 10px;
}

.pd-b-10 {
  padding-block-end: 10px;
}

.pd-s-10 {
  padding-inline-start: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-y-15 {
  padding-block-start: 15px;
  padding-block-end: 15px;
}

.pd-x-15 {
  padding-inline-start: 15px;
  padding-inline-end: 15px;
}

.pd-t-15 {
  padding-block-start: 15px;
}

.pd-e-15 {
  padding-inline-end: 15px;
}

.pd-b-15 {
  padding-block-end: 15px;
}

.pd-s-15 {
  padding-inline-start: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-y-20 {
  padding-block-start: 20px;
  padding-block-end: 20px;
}

.pd-x-20 {
  padding-inline-start: 20px;
  padding-inline-end: 20px;
}

.pd-t-20 {
  padding-block-start: 20px;
}

.pd-e-20 {
  padding-inline-end: 20px;
}

.pd-b-20 {
  padding-block-end: 20px;
}

.pd-s-20 {
  padding-inline-start: 20px;
}

.pd-25 {
  padding: 25px;
}

.pd-y-25 {
  padding-block-start: 25px;
  padding-block-end: 25px;
}

.pd-x-25 {
  padding-inline-start: 25px;
  padding-inline-end: 25px;
}

.pd-t-25 {
  padding-block-start: 25px;
}

.pd-e-25 {
  padding-inline-end: 25px;
}

.pd-b-25 {
  padding-block-end: 25px;
}

.pd-s-25 {
  padding-inline-start: 25px;
}

.pd-30 {
  padding: 30px;
}

.pd-y-30 {
  padding-block-start: 30px;
  padding-block-end: 30px;
}

.pd-x-30 {
  padding-inline-start: 30px;
  padding-inline-end: 30px;
}

.pd-t-30 {
  padding-block-start: 30px;
}

.pd-e-30 {
  padding-inline-end: 30px;
}

.pd-b-30 {
  padding-block-end: 30px;
}

.pd-s-30 {
  padding-inline-start: 30px;
}

.pd-35 {
  padding: 35px;
}

.pd-y-35 {
  padding-block-start: 35px;
  padding-block-end: 35px;
}

.pd-x-35 {
  padding-inline-start: 35px;
  padding-inline-end: 35px;
}

.pd-t-35 {
  padding-block-start: 35px;
}

.pd-e-35 {
  padding-inline-end: 35px;
}

.pd-b-35 {
  padding-block-end: 35px;
}

.pd-s-35 {
  padding-inline-start: 35px;
}

.pd-40 {
  padding: 40px;
}

.pd-y-40 {
  padding-block-start: 40px;
  padding-block-end: 40px;
}

.pd-x-40 {
  padding-inline-start: 40px;
  padding-inline-end: 40px;
}

.pd-t-40 {
  padding-block-start: 40px;
}

.pd-e-40 {
  padding-inline-end: 40px;
}

.pd-b-40 {
  padding-block-end: 40px;
}

.pd-s-40 {
  padding-inline-start: 40px;
}

@media (min-width: 480px) {
  .pd-xs-0 {
    padding: 0px;
  }

  .pd-xs-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-xs-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-xs-t-0 {
    padding-block-start: 0px;
  }

  .pd-xs-r-0 {
    padding-inline-end: 0px;
  }

  .pd-xs-b-0 {
    padding-block-end: 0px;
  }

  .pd-xs-l-0 {
    padding-inline-start: 0px;
  }

  .pd-xs-1 {
    padding: 1px;
  }

  .pd-xs-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-xs-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-xs-t-1 {
    padding-block-start: 1px;
  }

  .pd-xs-r-1 {
    padding-inline-end: 1px;
  }

  .pd-xs-b-1 {
    padding-block-end: 1px;
  }

  .pd-xs-l-1 {
    padding-inline-start: 1px;
  }

  .pd-xs-2 {
    padding: 2px;
  }

  .pd-xs-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-xs-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-xs-t-2 {
    padding-block-start: 2px;
  }

  .pd-xs-r-2 {
    padding-inline-end: 2px;
  }

  .pd-xs-b-2 {
    padding-block-end: 2px;
  }

  .pd-xs-l-2 {
    padding-inline-start: 2px;
  }

  .pd-xs-3 {
    padding: 3px;
  }

  .pd-xs-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-xs-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-xs-t-3 {
    padding-block-start: 3px;
  }

  .pd-xs-r-3 {
    padding-inline-end: 3px;
  }

  .pd-xs-b-3 {
    padding-block-end: 3px;
  }

  .pd-xs-l-3 {
    padding-inline-start: 3px;
  }

  .pd-xs-4 {
    padding: 4px;
  }

  .pd-xs-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-xs-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-xs-t-4 {
    padding-block-start: 4px;
  }

  .pd-xs-r-4 {
    padding-inline-end: 4px;
  }

  .pd-xs-b-4 {
    padding-block-end: 4px;
  }

  .pd-xs-l-4 {
    padding-inline-start: 4px;
  }

  .pd-xs-5 {
    padding: 5px;
  }

  .pd-xs-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-xs-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-xs-t-5 {
    padding-block-start: 5px;
  }

  .pd-xs-r-5 {
    padding-inline-end: 5px;
  }

  .pd-xs-b-5 {
    padding-block-end: 5px;
  }

  .pd-xs-l-5 {
    padding-inline-start: 5px;
  }

  .pd-xs-6 {
    padding: 6px;
  }

  .pd-xs-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-xs-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-xs-t-6 {
    padding-block-start: 6px;
  }

  .pd-xs-r-6 {
    padding-inline-end: 6px;
  }

  .pd-xs-b-6 {
    padding-block-end: 6px;
  }

  .pd-xs-l-6 {
    padding-inline-start: 6px;
  }

  .pd-xs-7 {
    padding: 7px;
  }

  .pd-xs-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-xs-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-xs-t-7 {
    padding-block-start: 7px;
  }

  .pd-xs-r-7 {
    padding-inline-end: 7px;
  }

  .pd-xs-b-7 {
    padding-block-end: 7px;
  }

  .pd-xs-l-7 {
    padding-inline-start: 7px;
  }

  .pd-xs-8 {
    padding: 8px;
  }

  .pd-xs-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-xs-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-xs-t-8 {
    padding-block-start: 8px;
  }

  .pd-xs-r-8 {
    padding-inline-end: 8px;
  }

  .pd-xs-b-8 {
    padding-block-end: 8px;
  }

  .pd-xs-l-8 {
    padding-inline-start: 8px;
  }

  .pd-xs-9 {
    padding: 9px;
  }

  .pd-xs-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-xs-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-xs-t-9 {
    padding-block-start: 9px;
  }

  .pd-xs-r-9 {
    padding-inline-end: 9px;
  }

  .pd-xs-b-9 {
    padding-block-end: 9px;
  }

  .pd-xs-l-9 {
    padding-inline-start: 9px;
  }

  .pd-xs-10 {
    padding: 10px;
  }

  .pd-xs-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-xs-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-xs-t-10 {
    padding-block-start: 10px;
  }

  .pd-xs-r-10 {
    padding-inline-end: 10px;
  }

  .pd-xs-b-10 {
    padding-block-end: 10px;
  }

  .pd-xs-l-10 {
    padding-inline-start: 10px;
  }

  .pd-xs-15 {
    padding: 15px;
  }

  .pd-xs-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-xs-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-xs-t-15 {
    padding-block-start: 15px;
  }

  .pd-xs-r-15 {
    padding-inline-end: 15px;
  }

  .pd-xs-b-15 {
    padding-block-end: 15px;
  }

  .pd-xs-l-15 {
    padding-inline-start: 15px;
  }

  .pd-xs-20 {
    padding: 20px;
  }

  .pd-xs-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-xs-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-xs-t-20 {
    padding-block-start: 20px;
  }

  .pd-xs-r-20 {
    padding-inline-end: 20px;
  }

  .pd-xs-b-20 {
    padding-block-end: 20px;
  }

  .pd-xs-l-20 {
    padding-inline-start: 20px;
  }

  .pd-xs-25 {
    padding: 25px;
  }

  .pd-xs-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-xs-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-xs-t-25 {
    padding-block-start: 25px;
  }

  .pd-xs-r-25 {
    padding-inline-end: 25px;
  }

  .pd-xs-b-25 {
    padding-block-end: 25px;
  }

  .pd-xs-l-25 {
    padding-inline-start: 25px;
  }

  .pd-xs-30 {
    padding: 30px;
  }

  .pd-xs-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-xs-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-xs-t-30 {
    padding-block-start: 30px;
  }

  .pd-xs-r-30 {
    padding-inline-end: 30px;
  }

  .pd-xs-b-30 {
    padding-block-end: 30px;
  }

  .pd-xs-l-30 {
    padding-inline-start: 30px;
  }

  .pd-xs-35 {
    padding: 35px;
  }

  .pd-xs-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-xs-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-xs-t-35 {
    padding-block-start: 35px;
  }

  .pd-xs-r-35 {
    padding-inline-end: 35px;
  }

  .pd-xs-b-35 {
    padding-block-end: 35px;
  }

  .pd-xs-l-35 {
    padding-inline-start: 35px;
  }

  .pd-xs-40 {
    padding: 40px;
  }

  .pd-xs-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-xs-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-xs-t-40 {
    padding-block-start: 40px;
  }

  .pd-xs-r-40 {
    padding-inline-end: 40px;
  }

  .pd-xs-b-40 {
    padding-block-end: 40px;
  }

  .pd-xs-l-40 {
    padding-inline-start: 40px;
  }
}

@media (min-width: 576px) {
  .pd-sm-0 {
    padding: 0px;
  }

  .pd-sm-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-sm-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-sm-t-0 {
    padding-block-start: 0px;
  }

  .pd-sm-r-0 {
    padding-inline-end: 0px;
  }

  .pd-sm-b-0 {
    padding-block-end: 0px;
  }

  .pd-sm-l-0 {
    padding-inline-start: 0px;
  }

  .pd-sm-1 {
    padding: 1px;
  }

  .pd-sm-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-sm-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-sm-t-1 {
    padding-block-start: 1px;
  }

  .pd-sm-r-1 {
    padding-inline-end: 1px;
  }

  .pd-sm-b-1 {
    padding-block-end: 1px;
  }

  .pd-sm-l-1 {
    padding-inline-start: 1px;
  }

  .pd-sm-2 {
    padding: 2px;
  }

  .pd-sm-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-sm-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-sm-t-2 {
    padding-block-start: 2px;
  }

  .pd-sm-r-2 {
    padding-inline-end: 2px;
  }

  .pd-sm-b-2 {
    padding-block-end: 2px;
  }

  .pd-sm-l-2 {
    padding-inline-start: 2px;
  }

  .pd-sm-3 {
    padding: 3px;
  }

  .pd-sm-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-sm-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-sm-t-3 {
    padding-block-start: 3px;
  }

  .pd-sm-r-3 {
    padding-inline-end: 3px;
  }

  .pd-sm-b-3 {
    padding-block-end: 3px;
  }

  .pd-sm-l-3 {
    padding-inline-start: 3px;
  }

  .pd-sm-4 {
    padding: 4px;
  }

  .pd-sm-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-sm-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-sm-t-4 {
    padding-block-start: 4px;
  }

  .pd-sm-r-4 {
    padding-inline-end: 4px;
  }

  .pd-sm-b-4 {
    padding-block-end: 4px;
  }

  .pd-sm-l-4 {
    padding-inline-start: 4px;
  }

  .pd-sm-5 {
    padding: 5px;
  }

  .pd-sm-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-sm-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-sm-t-5 {
    padding-block-start: 5px;
  }

  .pd-sm-r-5 {
    padding-inline-end: 5px;
  }

  .pd-sm-b-5 {
    padding-block-end: 5px;
  }

  .pd-sm-l-5 {
    padding-inline-start: 5px;
  }

  .pd-sm-6 {
    padding: 6px;
  }

  .pd-sm-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-sm-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-sm-t-6 {
    padding-block-start: 6px;
  }

  .pd-sm-r-6 {
    padding-inline-end: 6px;
  }

  .pd-sm-b-6 {
    padding-block-end: 6px;
  }

  .pd-sm-l-6 {
    padding-inline-start: 6px;
  }

  .pd-sm-7 {
    padding: 7px;
  }

  .pd-sm-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-sm-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-sm-t-7 {
    padding-block-start: 7px;
  }

  .pd-sm-r-7 {
    padding-inline-end: 7px;
  }

  .pd-sm-b-7 {
    padding-block-end: 7px;
  }

  .pd-sm-l-7 {
    padding-inline-start: 7px;
  }

  .pd-sm-8 {
    padding: 8px;
  }

  .pd-sm-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-sm-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-sm-t-8 {
    padding-block-start: 8px;
  }

  .pd-sm-r-8 {
    padding-inline-end: 8px;
  }

  .pd-sm-b-8 {
    padding-block-end: 8px;
  }

  .pd-sm-l-8 {
    padding-inline-start: 8px;
  }

  .pd-sm-9 {
    padding: 9px;
  }

  .pd-sm-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-sm-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-sm-t-9 {
    padding-block-start: 9px;
  }

  .pd-sm-r-9 {
    padding-inline-end: 9px;
  }

  .pd-sm-b-9 {
    padding-block-end: 9px;
  }

  .pd-sm-l-9 {
    padding-inline-start: 9px;
  }

  .pd-sm-10 {
    padding: 10px;
  }

  .pd-sm-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-sm-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-sm-t-10 {
    padding-block-start: 10px;
  }

  .pd-sm-r-10 {
    padding-inline-end: 10px;
  }

  .pd-sm-b-10 {
    padding-block-end: 10px;
  }

  .pd-sm-l-10 {
    padding-inline-start: 10px;
  }

  .pd-sm-15 {
    padding: 15px;
  }

  .pd-sm-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-sm-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-sm-t-15 {
    padding-block-start: 15px;
  }

  .pd-sm-r-15 {
    padding-inline-end: 15px;
  }

  .pd-sm-b-15 {
    padding-block-end: 15px;
  }

  .pd-sm-l-15 {
    padding-inline-start: 15px;
  }

  .pd-sm-20 {
    padding: 20px;
  }

  .pd-sm-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-sm-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-sm-t-20 {
    padding-block-start: 20px;
  }

  .pd-sm-r-20 {
    padding-inline-end: 20px;
  }

  .pd-sm-b-20 {
    padding-block-end: 20px;
  }

  .pd-sm-l-20 {
    padding-inline-start: 20px;
  }

  .pd-sm-25 {
    padding: 25px;
  }

  .pd-sm-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-sm-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-sm-t-25 {
    padding-block-start: 25px;
  }

  .pd-sm-r-25 {
    padding-inline-end: 25px;
  }

  .pd-sm-b-25 {
    padding-block-end: 25px;
  }

  .pd-sm-l-25 {
    padding-inline-start: 25px;
  }

  .pd-sm-30 {
    padding: 30px;
  }

  .pd-sm-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-sm-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-sm-t-30 {
    padding-block-start: 30px;
  }

  .pd-sm-r-30 {
    padding-inline-end: 30px;
  }

  .pd-sm-b-30 {
    padding-block-end: 30px;
  }

  .pd-sm-l-30 {
    padding-inline-start: 30px;
  }

  .pd-sm-35 {
    padding: 35px;
  }

  .pd-sm-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-sm-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-sm-t-35 {
    padding-block-start: 35px;
  }

  .pd-sm-r-35 {
    padding-inline-end: 35px;
  }

  .pd-sm-b-35 {
    padding-block-end: 35px;
  }

  .pd-sm-l-35 {
    padding-inline-start: 35px;
  }

  .pd-sm-40 {
    padding: 40px;
  }

  .pd-sm-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-sm-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-sm-t-40 {
    padding-block-start: 40px;
  }

  .pd-sm-r-40 {
    padding-inline-end: 40px;
  }

  .pd-sm-b-40 {
    padding-block-end: 40px;
  }

  .pd-sm-l-40 {
    padding-inline-start: 40px;
  }
}

@media (min-width: 768px) {
  .pd-md-0 {
    padding: 0px;
  }

  .pd-md-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-md-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-md-t-0 {
    padding-block-start: 0px;
  }

  .pd-md-r-0 {
    padding-inline-end: 0px;
  }

  .pd-md-b-0 {
    padding-block-end: 0px;
  }

  .pd-md-l-0 {
    padding-inline-start: 0px;
  }

  .pd-md-1 {
    padding: 1px;
  }

  .pd-md-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-md-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-md-t-1 {
    padding-block-start: 1px;
  }

  .pd-md-r-1 {
    padding-inline-end: 1px;
  }

  .pd-md-b-1 {
    padding-block-end: 1px;
  }

  .pd-md-l-1 {
    padding-inline-start: 1px;
  }

  .pd-md-2 {
    padding: 2px;
  }

  .pd-md-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-md-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-md-t-2 {
    padding-block-start: 2px;
  }

  .pd-md-r-2 {
    padding-inline-end: 2px;
  }

  .pd-md-b-2 {
    padding-block-end: 2px;
  }

  .pd-md-l-2 {
    padding-inline-start: 2px;
  }

  .pd-md-3 {
    padding: 3px;
  }

  .pd-md-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-md-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-md-t-3 {
    padding-block-start: 3px;
  }

  .pd-md-r-3 {
    padding-inline-end: 3px;
  }

  .pd-md-b-3 {
    padding-block-end: 3px;
  }

  .pd-md-l-3 {
    padding-inline-start: 3px;
  }

  .pd-md-4 {
    padding: 4px;
  }

  .pd-md-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-md-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-md-t-4 {
    padding-block-start: 4px;
  }

  .pd-md-r-4 {
    padding-inline-end: 4px;
  }

  .pd-md-b-4 {
    padding-block-end: 4px;
  }

  .pd-md-l-4 {
    padding-inline-start: 4px;
  }

  .pd-md-5 {
    padding: 5px;
  }

  .pd-md-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-md-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-md-t-5 {
    padding-block-start: 5px;
  }

  .pd-md-r-5 {
    padding-inline-end: 5px;
  }

  .pd-md-b-5 {
    padding-block-end: 5px;
  }

  .pd-md-l-5 {
    padding-inline-start: 5px;
  }

  .pd-md-6 {
    padding: 6px;
  }

  .pd-md-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-md-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-md-t-6 {
    padding-block-start: 6px;
  }

  .pd-md-r-6 {
    padding-inline-end: 6px;
  }

  .pd-md-b-6 {
    padding-block-end: 6px;
  }

  .pd-md-l-6 {
    padding-inline-start: 6px;
  }

  .pd-md-7 {
    padding: 7px;
  }

  .pd-md-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-md-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-md-t-7 {
    padding-block-start: 7px;
  }

  .pd-md-r-7 {
    padding-inline-end: 7px;
  }

  .pd-md-b-7 {
    padding-block-end: 7px;
  }

  .pd-md-l-7 {
    padding-inline-start: 7px;
  }

  .pd-md-8 {
    padding: 8px;
  }

  .pd-md-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-md-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-md-t-8 {
    padding-block-start: 8px;
  }

  .pd-md-r-8 {
    padding-inline-end: 8px;
  }

  .pd-md-b-8 {
    padding-block-end: 8px;
  }

  .pd-md-l-8 {
    padding-inline-start: 8px;
  }

  .pd-md-9 {
    padding: 9px;
  }

  .pd-md-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-md-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-md-t-9 {
    padding-block-start: 9px;
  }

  .pd-md-r-9 {
    padding-inline-end: 9px;
  }

  .pd-md-b-9 {
    padding-block-end: 9px;
  }

  .pd-md-l-9 {
    padding-inline-start: 9px;
  }

  .pd-md-10 {
    padding: 10px;
  }

  .pd-md-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-md-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-md-t-10 {
    padding-block-start: 10px;
  }

  .pd-md-r-10 {
    padding-inline-end: 10px;
  }

  .pd-md-b-10 {
    padding-block-end: 10px;
  }

  .pd-md-l-10 {
    padding-inline-start: 10px;
  }

  .pd-md-15 {
    padding: 15px;
  }

  .pd-md-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-md-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-md-t-15 {
    padding-block-start: 15px;
  }

  .pd-md-r-15 {
    padding-inline-end: 15px;
  }

  .pd-md-b-15 {
    padding-block-end: 15px;
  }

  .pd-md-l-15 {
    padding-inline-start: 15px;
  }

  .pd-md-20 {
    padding: 20px;
  }

  .pd-md-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-md-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-md-t-20 {
    padding-block-start: 20px;
  }

  .pd-md-r-20 {
    padding-inline-end: 20px;
  }

  .pd-md-b-20 {
    padding-block-end: 20px;
  }

  .pd-md-l-20 {
    padding-inline-start: 20px;
  }

  .pd-md-25 {
    padding: 25px;
  }

  .pd-md-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-md-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-md-t-25 {
    padding-block-start: 25px;
  }

  .pd-md-r-25 {
    padding-inline-end: 25px;
  }

  .pd-md-b-25 {
    padding-block-end: 25px;
  }

  .pd-md-l-25 {
    padding-inline-start: 25px;
  }

  .pd-md-30 {
    padding: 30px;
  }

  .pd-md-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-md-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-md-t-30 {
    padding-block-start: 30px;
  }

  .pd-md-r-30 {
    padding-inline-end: 30px;
  }

  .pd-md-b-30 {
    padding-block-end: 30px;
  }

  .pd-md-l-30 {
    padding-inline-start: 30px;
  }

  .pd-md-35 {
    padding: 35px;
  }

  .pd-md-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-md-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-md-t-35 {
    padding-block-start: 35px;
  }

  .pd-md-r-35 {
    padding-inline-end: 35px;
  }

  .pd-md-b-35 {
    padding-block-end: 35px;
  }

  .pd-md-l-35 {
    padding-inline-start: 35px;
  }

  .pd-md-40 {
    padding: 40px;
  }

  .pd-md-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-md-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-md-t-40 {
    padding-block-start: 40px;
  }

  .pd-md-r-40 {
    padding-inline-end: 40px;
  }

  .pd-md-b-40 {
    padding-block-end: 40px;
  }

  .pd-md-l-40 {
    padding-inline-start: 40px;
  }
}

@media (min-width: 1200px) {
  .pd-xl-0 {
    padding: 0px;
  }

  .pd-xl-y-0 {
    padding-block-start: 0px;
    padding-block-end: 0px;
  }

  .pd-xl-x-0 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  .pd-xl-t-0 {
    padding-block-start: 0px;
  }

  .pd-xl-r-0 {
    padding-inline-end: 0px;
  }

  .pd-xl-b-0 {
    padding-block-end: 0px;
  }

  .pd-xl-l-0 {
    padding-inline-start: 0px;
  }

  .pd-xl-1 {
    padding: 1px;
  }

  .pd-xl-y-1 {
    padding-block-start: 1px;
    padding-block-end: 1px;
  }

  .pd-xl-x-1 {
    padding-inline-start: 1px;
    padding-inline-end: 1px;
  }

  .pd-xl-t-1 {
    padding-block-start: 1px;
  }

  .pd-xl-r-1 {
    padding-inline-end: 1px;
  }

  .pd-xl-b-1 {
    padding-block-end: 1px;
  }

  .pd-xl-l-1 {
    padding-inline-start: 1px;
  }

  .pd-xl-2 {
    padding: 2px;
  }

  .pd-xl-y-2 {
    padding-block-start: 2px;
    padding-block-end: 2px;
  }

  .pd-xl-x-2 {
    padding-inline-start: 2px;
    padding-inline-end: 2px;
  }

  .pd-xl-t-2 {
    padding-block-start: 2px;
  }

  .pd-xl-r-2 {
    padding-inline-end: 2px;
  }

  .pd-xl-b-2 {
    padding-block-end: 2px;
  }

  .pd-xl-l-2 {
    padding-inline-start: 2px;
  }

  .pd-xl-3 {
    padding: 3px;
  }

  .pd-xl-y-3 {
    padding-block-start: 3px;
    padding-block-end: 3px;
  }

  .pd-xl-x-3 {
    padding-inline-start: 3px;
    padding-inline-end: 3px;
  }

  .pd-xl-t-3 {
    padding-block-start: 3px;
  }

  .pd-xl-r-3 {
    padding-inline-end: 3px;
  }

  .pd-xl-b-3 {
    padding-block-end: 3px;
  }

  .pd-xl-l-3 {
    padding-inline-start: 3px;
  }

  .pd-xl-4 {
    padding: 4px;
  }

  .pd-xl-y-4 {
    padding-block-start: 4px;
    padding-block-end: 4px;
  }

  .pd-xl-x-4 {
    padding-inline-start: 4px;
    padding-inline-end: 4px;
  }

  .pd-xl-t-4 {
    padding-block-start: 4px;
  }

  .pd-xl-r-4 {
    padding-inline-end: 4px;
  }

  .pd-xl-b-4 {
    padding-block-end: 4px;
  }

  .pd-xl-l-4 {
    padding-inline-start: 4px;
  }

  .pd-xl-5 {
    padding: 5px;
  }

  .pd-xl-y-5 {
    padding-block-start: 5px;
    padding-block-end: 5px;
  }

  .pd-xl-x-5 {
    padding-inline-start: 5px;
    padding-inline-end: 5px;
  }

  .pd-xl-t-5 {
    padding-block-start: 5px;
  }

  .pd-xl-r-5 {
    padding-inline-end: 5px;
  }

  .pd-xl-b-5 {
    padding-block-end: 5px;
  }

  .pd-xl-l-5 {
    padding-inline-start: 5px;
  }

  .pd-xl-6 {
    padding: 6px;
  }

  .pd-xl-y-6 {
    padding-block-start: 6px;
    padding-block-end: 6px;
  }

  .pd-xl-x-6 {
    padding-inline-start: 6px;
    padding-inline-end: 6px;
  }

  .pd-xl-t-6 {
    padding-block-start: 6px;
  }

  .pd-xl-r-6 {
    padding-inline-end: 6px;
  }

  .pd-xl-b-6 {
    padding-block-end: 6px;
  }

  .pd-xl-l-6 {
    padding-inline-start: 6px;
  }

  .pd-xl-7 {
    padding: 7px;
  }

  .pd-xl-y-7 {
    padding-block-start: 7px;
    padding-block-end: 7px;
  }

  .pd-xl-x-7 {
    padding-inline-start: 7px;
    padding-inline-end: 7px;
  }

  .pd-xl-t-7 {
    padding-block-start: 7px;
  }

  .pd-xl-r-7 {
    padding-inline-end: 7px;
  }

  .pd-xl-b-7 {
    padding-block-end: 7px;
  }

  .pd-xl-l-7 {
    padding-inline-start: 7px;
  }

  .pd-xl-8 {
    padding: 8px;
  }

  .pd-xl-y-8 {
    padding-block-start: 8px;
    padding-block-end: 8px;
  }

  .pd-xl-x-8 {
    padding-inline-start: 8px;
    padding-inline-end: 8px;
  }

  .pd-xl-t-8 {
    padding-block-start: 8px;
  }

  .pd-xl-r-8 {
    padding-inline-end: 8px;
  }

  .pd-xl-b-8 {
    padding-block-end: 8px;
  }

  .pd-xl-l-8 {
    padding-inline-start: 8px;
  }

  .pd-xl-9 {
    padding: 9px;
  }

  .pd-xl-y-9 {
    padding-block-start: 9px;
    padding-block-end: 9px;
  }

  .pd-xl-x-9 {
    padding-inline-start: 9px;
    padding-inline-end: 9px;
  }

  .pd-xl-t-9 {
    padding-block-start: 9px;
  }

  .pd-xl-r-9 {
    padding-inline-end: 9px;
  }

  .pd-xl-b-9 {
    padding-block-end: 9px;
  }

  .pd-xl-l-9 {
    padding-inline-start: 9px;
  }

  .pd-xl-10 {
    padding: 10px;
  }

  .pd-xl-y-10 {
    padding-block-start: 10px;
    padding-block-end: 10px;
  }

  .pd-xl-x-10 {
    padding-inline-start: 10px;
    padding-inline-end: 10px;
  }

  .pd-xl-t-10 {
    padding-block-start: 10px;
  }

  .pd-xl-r-10 {
    padding-inline-end: 10px;
  }

  .pd-xl-b-10 {
    padding-block-end: 10px;
  }

  .pd-xl-l-10 {
    padding-inline-start: 10px;
  }

  .pd-xl-15 {
    padding: 15px;
  }

  .pd-xl-y-15 {
    padding-block-start: 15px;
    padding-block-end: 15px;
  }

  .pd-xl-x-15 {
    padding-inline-start: 15px;
    padding-inline-end: 15px;
  }

  .pd-xl-t-15 {
    padding-block-start: 15px;
  }

  .pd-xl-r-15 {
    padding-inline-end: 15px;
  }

  .pd-xl-b-15 {
    padding-block-end: 15px;
  }

  .pd-xl-l-15 {
    padding-inline-start: 15px;
  }

  .pd-xl-20 {
    padding: 20px;
  }

  .pd-xl-y-20 {
    padding-block-start: 20px;
    padding-block-end: 20px;
  }

  .pd-xl-x-20 {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
  }

  .pd-xl-t-20 {
    padding-block-start: 20px;
  }

  .pd-xl-r-20 {
    padding-inline-end: 20px;
  }

  .pd-xl-b-20 {
    padding-block-end: 20px;
  }

  .pd-xl-l-20 {
    padding-inline-start: 20px;
  }

  .pd-xl-25 {
    padding: 25px;
  }

  .pd-xl-y-25 {
    padding-block-start: 25px;
    padding-block-end: 25px;
  }

  .pd-xl-x-25 {
    padding-inline-start: 25px;
    padding-inline-end: 25px;
  }

  .pd-xl-t-25 {
    padding-block-start: 25px;
  }

  .pd-xl-r-25 {
    padding-inline-end: 25px;
  }

  .pd-xl-b-25 {
    padding-block-end: 25px;
  }

  .pd-xl-l-25 {
    padding-inline-start: 25px;
  }

  .pd-xl-30 {
    padding: 30px;
  }

  .pd-xl-y-30 {
    padding-block-start: 30px;
    padding-block-end: 30px;
  }

  .pd-xl-x-30 {
    padding-inline-start: 30px;
    padding-inline-end: 30px;
  }

  .pd-xl-t-30 {
    padding-block-start: 30px;
  }

  .pd-xl-r-30 {
    padding-inline-end: 30px;
  }

  .pd-xl-b-30 {
    padding-block-end: 30px;
  }

  .pd-xl-l-30 {
    padding-inline-start: 30px;
  }

  .pd-xl-35 {
    padding: 35px;
  }

  .pd-xl-y-35 {
    padding-block-start: 35px;
    padding-block-end: 35px;
  }

  .pd-xl-x-35 {
    padding-inline-start: 35px;
    padding-inline-end: 35px;
  }

  .pd-xl-t-35 {
    padding-block-start: 35px;
  }

  .pd-xl-r-35 {
    padding-inline-end: 35px;
  }

  .pd-xl-b-35 {
    padding-block-end: 35px;
  }

  .pd-xl-l-35 {
    padding-inline-start: 35px;
  }

  .pd-xl-40 {
    padding: 40px;
  }

  .pd-xl-y-40 {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }

  .pd-xl-x-40 {
    padding-inline-start: 40px;
    padding-inline-end: 40px;
  }

  .pd-xl-t-40 {
    padding-block-start: 40px;
  }

  .pd-xl-r-40 {
    padding-inline-end: 40px;
  }

  .pd-xl-b-40 {
    padding-block-end: 40px;
  }

  .pd-xl-l-40 {
    padding-inline-start: 40px;
  }
}

.pd-12 {
  padding: 12px;
}

.pd-y-12 {
  padding-block-start: 12px;
  padding-block-end: 12px;
}

.pd-x-12 {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
}

.pd-t-12 {
  padding-block-start: 12px;
}

.pd-e-12 {
  padding-inline-end: 12px;
}

.pd-b-12 {
  padding-block-end: 12px;
}

.pd-s-12 {
  padding-inline-start: 12px;
}

.pd-13 {
  padding: 13px;
}

.pd-y-13 {
  padding-block-start: 13px;
  padding-block-end: 13px;
}

.pd-x-13 {
  padding-inline-start: 13px;
  padding-inline-end: 13px;
}

.pd-t-13 {
  padding-block-start: 13px;
}

.pd-e-13 {
  padding-inline-end: 13px;
}

.pd-b-13 {
  padding-block-end: 13px;
}

.pd-s-13 {
  padding-inline-start: 13px;
}

/* ###### 9.7 Position   ###### */
.pos-absolute {
  position: absolute;
}

.pos-relative {
  position: relative;
}

.pos-fixed {
  position: fixed;
}

.pos-static {
  position: static;
}

.pos-sticky {
  position: sticky;
}

.t-0 {
  inset-block-start: 0px;
}

.r-0 {
  inset-inline-end: 0px;
}

.b-0 {
  inset-block-end: 0px;
}

.l-0 {
  inset-inline-start: 0px;
}

.t--0 {
  inset-block-start: 0px;
}

.r--0 {
  inset-inline-end: 0px;
}

.b--0 {
  inset-block-end: 0px;
}

.l--0 {
  inset-inline-start: 0px;
}

.a-0 {
  inset-block-start: 0px;
  inset-inline-end: 0px;
  inset-block-end: 0px;
  inset-inline-start: 0px;
}

.x-0,
.y-0 {
  inset-inline-start: 0px;
  inset-inline-end: 0px;
}

.t-5 {
  inset-block-start: 5px;
}

.r-5 {
  inset-inline-end: 5px;
}

.b-5 {
  inset-block-end: 5px;
}

.l-5 {
  inset-inline-start: 5px;
}

.t--5 {
  inset-block-start: -5px;
}

.r--5 {
  inset-inline-end: -5px;
}

.b--5 {
  inset-block-end: -5px;
}

.l--5 {
  inset-inline-start: -5px;
}

.a-5 {
  inset-block-start: 5px;
  inset-inline-end: 5px;
  inset-block-end: 5px;
  inset-inline-start: 5px;
}

.x-5,
.y-5 {
  inset-inline-start: 5px;
  inset-inline-end: 5px;
}

.t-10 {
  inset-block-start: 10px;
}

.r-10 {
  inset-inline-end: 10px;
}

.b-10 {
  inset-block-end: 10px;
}

.l-10 {
  inset-inline-start: 10px;
}

.t--10 {
  inset-block-start: -10px;
}

.r--10 {
  inset-inline-end: -10px;
}

.b--10 {
  inset-block-end: -10px;
}

.l--10 {
  inset-inline-start: -10px;
}

.a-10 {
  inset-block-start: 10px;
  inset-inline-end: 10px;
  inset-block-end: 10px;
  inset-inline-start: 10px;
}

.x-10,
.y-10 {
  inset-inline-start: 10px;
  inset-inline-end: 10px;
}

.t-15 {
  inset-block-start: 15px;
}

.r-15 {
  inset-inline-end: 15px;
}

.b-15 {
  inset-block-end: 15px;
}

.l-15 {
  inset-inline-start: 15px;
}

.t--15 {
  inset-block-start: -15px;
}

.r--15 {
  inset-inline-end: -15px;
}

.b--15 {
  inset-block-end: -15px;
}

.l--15 {
  inset-inline-start: -15px;
}

.a-15 {
  inset-block-start: 15px;
  inset-inline-end: 15px;
  inset-block-end: 15px;
  inset-inline-start: 15px;
}

.x-15,
.y-15 {
  inset-inline-start: 15px;
  inset-inline-end: 15px;
}

.t-20 {
  inset-block-start: 20px;
}

.r-20 {
  inset-inline-end: 20px;
}

.b-20 {
  inset-block-end: 20px;
}

.l-20 {
  inset-inline-start: 20px;
}

.t--20 {
  inset-block-start: -20px;
}

.r--20 {
  inset-inline-end: -20px;
}

.b--20 {
  inset-block-end: -20px;
}

.l--20 {
  inset-inline-start: -20px;
}

.a-20 {
  inset-block-start: 20px;
  inset-inline-end: 20px;
  inset-block-end: 20px;
  inset-inline-start: 20px;
}

.x-20,
.y-20 {
  inset-inline-start: 20px;
  inset-inline-end: 20px;
}

.t-25 {
  inset-block-start: 25px;
}

.r-25 {
  inset-inline-end: 25px;
}

.b-25 {
  inset-block-end: 25px;
}

.l-25 {
  inset-inline-start: 25px;
}

.t--25 {
  inset-block-start: -25px;
}

.r--25 {
  inset-inline-end: -25px;
}

.b--25 {
  inset-block-end: -25px;
}

.l--25 {
  inset-inline-start: -25px;
}

.a-25 {
  inset-block-start: 25px;
  inset-inline-end: 25px;
  inset-block-end: 25px;
  inset-inline-start: 25px;
}

.x-25,
.y-25 {
  inset-inline-start: 25px;
  inset-inline-end: 25px;
}

.t-30 {
  inset-block-start: 30px;
}

.r-30 {
  inset-inline-end: 30px;
}

.b-30 {
  inset-block-end: 30px;
}

.l-30 {
  inset-inline-start: 30px;
}

.t--30 {
  inset-block-start: -30px;
}

.r--30 {
  inset-inline-end: -30px;
}

.b--30 {
  inset-block-end: -30px;
}

.l--30 {
  inset-inline-start: -30px;
}

.a-30 {
  inset-block-start: 30px;
  inset-inline-end: 30px;
  inset-block-end: 30px;
  inset-inline-start: 30px;
}

.x-30,
.y-30 {
  inset-inline-start: 30px;
  inset-inline-end: 30px;
}

.t-35 {
  inset-block-start: 35px;
}

.r-35 {
  inset-inline-end: 35px;
}

.b-35 {
  inset-block-end: 35px;
}

.l-35 {
  inset-inline-start: 35px;
}

.t--35 {
  inset-block-start: -35px;
}

.r--35 {
  inset-inline-end: -35px;
}

.b--35 {
  inset-block-end: -35px;
}

.l--35 {
  inset-inline-start: -35px;
}

.a-35 {
  inset-block-start: 35px;
  inset-inline-end: 35px;
  inset-block-end: 35px;
  inset-inline-start: 35px;
}

.x-35,
.y-35 {
  inset-inline-start: 35px;
  inset-inline-end: 35px;
}

.t-40 {
  inset-block-start: 40px;
}

.r-40 {
  inset-inline-end: 40px;
}

.b-40 {
  inset-block-end: 40px;
}

.l-40 {
  inset-inline-start: 40px;
}

.t--40 {
  inset-block-start: -40px;
}

.r--40 {
  inset-inline-end: -40px;
}

.b--40 {
  inset-block-end: -40px;
}

.l--40 {
  inset-inline-start: -40px;
}

.a-40 {
  inset-block-start: 40px;
  inset-inline-end: 40px;
  inset-block-end: 40px;
  inset-inline-start: 40px;
}

.x-40,
.y-40 {
  inset-inline-start: 40px;
  inset-inline-end: 40px;
}

.t-45 {
  inset-block-start: 45px;
}

.r-45 {
  inset-inline-end: 45px;
}

.b-45 {
  inset-block-end: 45px;
}

.l-45 {
  inset-inline-start: 45px;
}

.t--45 {
  inset-block-start: -45px;
}

.r--45 {
  inset-inline-end: -45px;
}

.b--45 {
  inset-block-end: -45px;
}

.l--45 {
  inset-inline-start: -45px;
}

.a-45 {
  inset-block-start: 45px;
  inset-inline-end: 45px;
  inset-block-end: 45px;
  inset-inline-start: 45px;
}

.x-45,
.y-45 {
  inset-inline-start: 45px;
  inset-inline-end: 45px;
}

.t-50 {
  inset-block-start: 50px;
}

.r-50 {
  inset-inline-end: 50px;
}

.b-50 {
  inset-block-end: 50px;
}

.l-50 {
  inset-inline-start: 50px;
}

.t--50 {
  inset-block-start: -50px;
}

.r--50 {
  inset-inline-end: -50px;
}

.b--50 {
  inset-block-end: -50px;
}

.l--50 {
  inset-inline-start: -50px;
}

.a-50 {
  inset-block-start: 50px;
  inset-inline-end: 50px;
  inset-block-end: 50px;
  inset-inline-start: 50px;
}

.x-50,
.y-50 {
  inset-inline-start: 50px;
  inset-inline-end: 50px;
}

.t-55 {
  inset-block-start: 55px;
}

.r-55 {
  inset-inline-end: 55px;
}

.b-55 {
  inset-block-end: 55px;
}

.l-55 {
  inset-inline-start: 55px;
}

.t--55 {
  inset-block-start: -55px;
}

.r--55 {
  inset-inline-end: -55px;
}

.b--55 {
  inset-block-end: -55px;
}

.l--55 {
  inset-inline-start: -55px;
}

.a-55 {
  inset-block-start: 55px;
  inset-inline-end: 55px;
  inset-block-end: 55px;
  inset-inline-start: 55px;
}

.x-55,
.y-55 {
  inset-inline-start: 55px;
  inset-inline-end: 55px;
}

.t-60 {
  inset-block-start: 60px;
}

.r-60 {
  inset-inline-end: 60px;
}

.b-60 {
  inset-block-end: 60px;
}

.l-60 {
  inset-inline-start: 60px;
}

.t--60 {
  inset-block-start: -60px;
}

.r--60 {
  inset-inline-end: -60px;
}

.b--60 {
  inset-block-end: -60px;
}

.l--60 {
  inset-inline-start: -60px;
}

.a-60 {
  inset-block-start: 60px;
  inset-inline-end: 60px;
  inset-block-end: 60px;
  inset-inline-start: 60px;
}

.x-60,
.y-60 {
  inset-inline-start: 60px;
  inset-inline-end: 60px;
}

.t-65 {
  inset-block-start: 65px;
}

.r-65 {
  inset-inline-end: 65px;
}

.b-65 {
  inset-block-end: 65px;
}

.l-65 {
  inset-inline-start: 65px;
}

.t--65 {
  inset-block-start: -65px;
}

.r--65 {
  inset-inline-end: -65px;
}

.b--65 {
  inset-block-end: -65px;
}

.l--65 {
  inset-inline-start: -65px;
}

.a-65 {
  inset-block-start: 65px;
  inset-inline-end: 65px;
  inset-block-end: 65px;
  inset-inline-start: 65px;
}

.x-65,
.y-65 {
  inset-inline-start: 65px;
  inset-inline-end: 65px;
}

.t-70 {
  inset-block-start: 70px;
}

.r-70 {
  inset-inline-end: 70px;
}

.b-70 {
  inset-block-end: 70px;
}

.l-70 {
  inset-inline-start: 70px;
}

.t--70 {
  inset-block-start: -70px;
}

.r--70 {
  inset-inline-end: -70px;
}

.b--70 {
  inset-block-end: -70px;
}

.l--70 {
  inset-inline-start: -70px;
}

.a-70 {
  inset-block-start: 70px;
  inset-inline-end: 70px;
  inset-block-end: 70px;
  inset-inline-start: 70px;
}

.x-70,
.y-70 {
  inset-inline-start: 70px;
  inset-inline-end: 70px;
}

.t-75 {
  inset-block-start: 75px;
}

.r-75 {
  inset-inline-end: 75px;
}

.b-75 {
  inset-block-end: 75px;
}

.l-75 {
  inset-inline-start: 75px;
}

.t--75 {
  inset-block-start: -75px;
}

.r--75 {
  inset-inline-end: -75px;
}

.b--75 {
  inset-block-end: -75px;
}

.l--75 {
  inset-inline-start: -75px;
}

.a-75 {
  inset-block-start: 75px;
  inset-inline-end: 75px;
  inset-block-end: 75px;
  inset-inline-start: 75px;
}

.x-75,
.y-75 {
  inset-inline-start: 75px;
  inset-inline-end: 75px;
}

.t-80 {
  inset-block-start: 80px;
}

.r-80 {
  inset-inline-end: 80px;
}

.b-80 {
  inset-block-end: 80px;
}

.l-80 {
  inset-inline-start: 80px;
}

.t--80 {
  inset-block-start: -80px;
}

.r--80 {
  inset-inline-end: -80px;
}

.b--80 {
  inset-block-end: -80px;
}

.l--80 {
  inset-inline-start: -80px;
}

.a-80 {
  inset-block-start: 80px;
  inset-inline-end: 80px;
  inset-block-end: 80px;
  inset-inline-start: 80px;
}

.x-80,
.y-80 {
  inset-inline-start: 80px;
  inset-inline-end: 80px;
}

.t-85 {
  inset-block-start: 85px;
}

.r-85 {
  inset-inline-end: 85px;
}

.b-85 {
  inset-block-end: 85px;
}

.l-85 {
  inset-inline-start: 85px;
}

.t--85 {
  inset-block-start: -85px;
}

.r--85 {
  inset-inline-end: -85px;
}

.b--85 {
  inset-block-end: -85px;
}

.l--85 {
  inset-inline-start: -85px;
}

.a-85 {
  inset-block-start: 85px;
  inset-inline-end: 85px;
  inset-block-end: 85px;
  inset-inline-start: 85px;
}

.x-85,
.y-85 {
  inset-inline-start: 85px;
  inset-inline-end: 85px;
}

.t-90 {
  inset-block-start: 90px;
}

.r-90 {
  inset-inline-end: 90px;
}

.b-90 {
  inset-block-end: 90px;
}

.l-90 {
  inset-inline-start: 90px;
}

.t--90 {
  inset-block-start: -90px;
}

.r--90 {
  inset-inline-end: -90px;
}

.b--90 {
  inset-block-end: -90px;
}

.l--90 {
  inset-inline-start: -90px;
}

.a-90 {
  inset-block-start: 90px;
  inset-inline-end: 90px;
  inset-block-end: 90px;
  inset-inline-start: 90px;
}

.x-90,
.y-90 {
  inset-inline-start: 90px;
  inset-inline-end: 90px;
}

.t-95 {
  inset-block-start: 95px;
}

.r-95 {
  inset-inline-end: 95px;
}

.b-95 {
  inset-block-end: 95px;
}

.l-95 {
  inset-inline-start: 95px;
}

.t--95 {
  inset-block-start: -95px;
}

.r--95 {
  inset-inline-end: -95px;
}

.b--95 {
  inset-block-end: -95px;
}

.l--95 {
  inset-inline-start: -95px;
}

.a-95 {
  inset-block-start: 95px;
  inset-inline-end: 95px;
  inset-block-end: 95px;
  inset-inline-start: 95px;
}

.x-95,
.y-95 {
  inset-inline-start: 95px;
  inset-inline-end: 95px;
}

.t-100 {
  inset-block-start: 100px;
}

.r-100 {
  inset-inline-end: 100px;
}

.b-100 {
  inset-block-end: 100px;
}

.l-100 {
  inset-inline-start: 100px;
}

.t--100 {
  inset-block-start: -100px;
}

.r--100 {
  inset-inline-end: -100px;
}

.b--100 {
  inset-block-end: -100px;
}

.l--100 {
  inset-inline-start: -100px;
}

.a-100 {
  inset-block-start: 100px;
  inset-inline-end: 100px;
  inset-block-end: 100px;
  inset-inline-start: 100px;
}

.x-100,
.y-100 {
  inset-inline-start: 100px;
  inset-inline-end: 100px;
}

.t-2 {
  inset-block-start: 2px;
}

.r--5 {
  inset-inline-end: -5px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-10 {
  z-index: 10;
}

.z-index-50 {
  z-index: 50;
}

.z-index-100 {
  z-index: 100;
}

.z-index-150 {
  z-index: 150;
}

.z-index-200 {
  z-index: 200;
}

@media (min-width: 480px) {
  .pos-xs-absolute {
    position: absolute;
  }

  .pos-xs-relative {
    position: relative;
  }

  .pos-xs-fixed {
    position: fixed;
  }

  .pos-xs-static {
    position: static;
  }

  .t-xs-auto {
    inset-block-start: auto;
  }

  .r-xs-auto {
    inset-inline-end: auto;
  }

  .b-xs-auto {
    inset-block-end: auto;
  }

  .l-xs-auto {
    inset-inline-start: auto;
  }
}

@media (min-width: 576px) {
  .pos-sm-absolute {
    position: absolute;
  }

  .pos-sm-relative {
    position: relative;
  }

  .pos-sm-fixed {
    position: fixed;
  }

  .pos-sm-static {
    position: static;
  }

  .t-sm-auto {
    inset-block-start: auto;
  }

  .r-sm-auto {
    inset-inline-end: auto;
  }

  .b-sm-auto {
    inset-block-end: auto;
  }

  .l-sm-auto {
    inset-inline-start: auto;
  }
}

@media (min-width: 768px) {
  .pos-md-absolute {
    position: absolute;
  }

  .pos-md-relative {
    position: relative;
  }

  .pos-md-fixed {
    position: fixed;
  }

  .pos-md-static {
    position: static;
  }

  .t-md-auto {
    inset-block-start: auto;
  }

  .r-md-auto {
    inset-inline-end: auto;
  }

  .b-md-auto {
    inset-block-end: auto;
  }

  .l-md-auto {
    inset-inline-start: auto;
  }
}

@media (min-width: 992px) {
  .pos-lg-absolute {
    position: absolute;
  }

  .pos-lg-relative {
    position: relative;
  }

  .pos-lg-fixed {
    position: fixed;
  }

  .pos-lg-static {
    position: static;
  }

  .t-lg-auto {
    inset-block-start: auto;
  }

  .r-lg-auto {
    inset-inline-end: auto;
  }

  .b-lg-auto {
    inset-block-end: auto;
  }

  .l-lg-auto {
    inset-inline-start: auto;
  }
}

@media (min-width: 1200px) {
  .pos-xl-absolute {
    position: absolute;
  }

  .pos-xl-relative {
    position: relative;
  }

  .pos-xl-fixed {
    position: fixed;
  }

  .pos-xl-static {
    position: static;
  }

  .t-xl-auto {
    inset-block-start: auto;
  }

  .r-xl-auto {
    inset-inline-end: auto;
  }

  .b-xl-auto {
    inset-block-end: auto;
  }

  .l-xl-auto {
    inset-inline-start: auto;
  }
}

.v-center {
  inset-block-start: 50%;
  transform: translateY(-50%);
}

.h-center {
  inset-inline-start: 50%;
  transform: translateX(-50%);
}

.a-center {
  inset-block-start: 50%;
  inset-inline-start: 50%;
  transform: translate(-50%, -50%);
}

.clear-end {
  clear: right;
}

.clear-start {
  clear: left;
}

.clear-both {
  clear: both;
}

.tx-black {
  font-weight: 900;
}

.tx-capitalize {
  text-transform: capitalize;
}

.tx-bold {
  font-weight: 700;
}

.tx-semibold {
  font-weight: 600;
}

.tx-medium {
  font-weight: 500;
}

.tx-normal {
  font-weight: 400;
}

.tx-light {
  font-weight: 300;
}

.tx-thin {
  font-weight: 200;
}

.tx-xthin {
  font-weight: 100;
}

.tx-sserif {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.tx-roboto {
  font-family: "Roboto", sans-serif;
}

.tx-white {
  color: #fff !important;
  fill: #fff !important;
}

.tx-green {
  color: #2dce89;
  fill: #2dce89;
}

.tx-facebook {
  color: #3b5998;
}

.tx-pinterest {
  color: #cd2029;
}

.tx-google-plus {
  color: #d34836;
}

.tx-twitter {
  color: #429cd6;
}

.tx-black {
  color: #000;
}

.tx-primary {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

.tx-primary:hover {
  color: var(--primary-bg-color) !important;
  fill: var(--primary-bg-color) !important;
}

.tx-secondary {
  color: var(--secondary);
}

.tx-secondary:hover {
  color: var(--secondary);
}

.tx-success {
  color: #43ce85 !important;
}

.tx-success:hover {
  color: #43ce85;
}

.tx-warning {
  color: #ffcf86;
}

.tx-warning:hover {
  color: #ffcf86;
}

.tx-danger {
  color: #d6573c;
}

.tx-danger:hover {
  color: #d6573c;
}

.tx-info {
  color: #4ec2f0;
}

.tx-info:hover {
  color: #4ec2f0;
}

.tx-inverse {
  color: var(--muted);
}

.tx-muted {
  color: var(--muted) !important;
}

.tx-teal {
  color: #4ac9bd;
}

.tx-teal:hover {
  color: #4ac9bd;
}

.tx-dark {
  color: var(--gray-900);
}

.tx-dark:hover {
  color: var(--gray-900);
}

.tx-indigo {
  color: #5b67c7;
}

.tx-indigo:hover {
  color: #5b67c7;
}

.tx-purple {
  color: #7891ef;
}

.tx-orange {
  color: #ffb67b;
}

.tx-orange:hover {
  color: #ffb67b;
}

.tx-pink {
  color: #f754fa;
}

.tx-pink:hover {
  color: #f754fa;
}

.tx-gray-100 {
  color: var(--gray-100);
}

.tx-gray-100:hover {
  color: var(--gray-100);
}

.tx-gray-200 {
  color: var(--gray-200);
}

.tx-gray-200:hover {
  color: var(--gray-200);
}

.tx-gray-300 {
  color: var(--gray-300);
}

.tx-gray-300:hover {
  color: var(--gray-300);
}

.tx-gray-400 {
  color: var(--gray-400);
}

.tx-gray-400:hover {
  color: var(--gray-400);
}

.tx-gray-500 {
  color: var(--gray-500);
}

.tx-gray-500:hover {
  color: var(--gray-500);
}

.tx-gray-600 {
  color: var(--gray-600);
}

.tx-gray-600:hover {
  color: var(--gray-600);
}

.tx-gray-700 {
  color: var(--gray-700);
}

.tx-gray-700:hover {
  color: var(--gray-700);
}

.tx-gray-800 {
  color: var(--gray-800);
}

.tx-gray-800:hover {
  color: var(--gray-800);
}

.tx-gray-900 {
  color: var(--gray-900);
}

.tx-gray-900:hover {
  color: var(--gray-900);
}

.tx-white-2 {
  color: var(--white-2);
}

.tx-white-2:hover {
  color: var(--white-2);
}

.tx-white-3 {
  color: var(--white-3);
}

.tx-white-3:hover {
  color: var(--white-3);
}

.tx-white-4 {
  color: var(--white-4);
}

.tx-white-4:hover {
  color: var(--white-4);
}

.tx-white-5 {
  color: var(--white-5);
}

.tx-white-5:hover {
  color: var(--white-5);
}

.tx-white-6 {
  color: var(--white-6);
}

.tx-white-6:hover {
  color: var(--white-6);
}

.tx-white-7 {
  color: var(--white-7);
}

.tx-white-7:hover {
  color: var(--white-7);
}

.tx-white-8 {
  color: var(--white-8);
}

.tx-white-8:hover {
  color: var(--white-8);
}

.tx-spacing-1 {
  letter-spacing: 0.5px;
}

.tx-spacing-2 {
  letter-spacing: 1px;
}

.tx-spacing-3 {
  letter-spacing: 1.5px;
}

.tx-spacing-4 {
  letter-spacing: 2px;
}

.tx-spacing-5 {
  letter-spacing: 2.5px;
}

.tx-spacing-6 {
  letter-spacing: 3px;
}

.tx-spacing-7 {
  letter-spacing: 3.5px;
}

.tx-spacing-8 {
  letter-spacing: 4px;
}

.tx-spacing--1 {
  letter-spacing: -0.5px;
}

.tx-spacing--2 {
  letter-spacing: -1px;
}

.tx-spacing--3 {
  letter-spacing: -1.5px;
}

.tx-spacing--4 {
  letter-spacing: -2px;
}

.tx-spacing--5 {
  letter-spacing: -2.5px;
}

.tx-spacing--6 {
  letter-spacing: -3px;
}

.tx-spacing--7 {
  letter-spacing: -3.5px;
}

.tx-spacing--8 {
  letter-spacing: -4px;
}

.tx-uppercase {
  text-transform: uppercase;
}

.tx-lowercase {
  text-transform: lowercase;
}

.tx-transform-none {
  text-transform: none;
}

.tx-center {
  text-align: center;
}

.tx-right {
  text-align: end;
}

.tx-left {
  text-align: start;
}

.tx-italic {
  font-style: italic;
}

.tx-style-normal {
  font-style: normal;
}

.tx-normal {
  font-style: normal;
}

.lh-base {
  line-height: 1.5;
}

.lh-normal {
  line-height: normal;
}

.lh-0 {
  line-height: 0;
}

.lh--1 {
  line-height: 0.1;
}

.lh--2 {
  line-height: 0.2;
}

.lh--3 {
  line-height: 0.3;
}

.lh--4 {
  line-height: 0.4;
}

.lh--5 {
  line-height: 0.5;
}

.lh--6 {
  line-height: 0.6;
}

.lh--7 {
  line-height: 0.7;
}

.lh--8 {
  line-height: 0.8;
}

.lh--9 {
  line-height: 0.9;
}

.lh-1 {
  line-height: 1.1;
}

.lh-2 {
  line-height: 1.2;
}

.lh-3 {
  line-height: 1.3;
}

.lh-4 {
  line-height: 1.4;
}

.lh-5 {
  line-height: 1.5;
}

.lh-6 {
  line-height: 1.6;
}

.lh-7 {
  line-height: 1.7;
}

.lh-8 {
  line-height: 1.8;
}

.lh-9 {
  line-height: 1.9;
}

.lh-10 {
  line-height: 2;
}

.lh-11 {
  line-height: 2.1;
}

.lh-12 {
  line-height: 2.2;
}

.lh-13 {
  line-height: 2.3;
}

.lh-14 {
  line-height: 2.4;
}

.lh-15 {
  line-height: 2.5;
}

.valign-top {
  vertical-align: top;
}

.valign-middle {
  vertical-align: middle;
}

.valign-bottom {
  vertical-align: baseline;
}

.tx-base {
  font-size: 0.875rem;
}

.tx-1 {
  font-size: 1px;
}

.tx-2 {
  font-size: 2px;
}

.tx-3 {
  font-size: 3px;
}

.tx-4 {
  font-size: 4px;
}

.tx-5 {
  font-size: 5px;
}

.tx-6 {
  font-size: 6px;
}

.tx-7 {
  font-size: 7px;
}

.tx-8 {
  font-size: 8px;
}

.tx-9 {
  font-size: 9px;
}

.tx-10 {
  font-size: 10px;
}

.tx-11 {
  font-size: 11px;
}

.tx-12 {
  font-size: 12px;
}

.tx-13 {
  font-size: 13px;
}

.tx-14 {
  font-size: 14px;
}

.tx-15 {
  font-size: 15px !important;
}

.tx-16 {
  font-size: 16px;
}

.tx-17 {
  font-size: 17px;
}

.tx-18 {
  font-size: 18px !important;
}

.tx-20 {
  font-size: 20px !important;
}

.tx-21 {
  font-size: 21px;
}

.tx-22 {
  font-size: 22px;
}

.tx-23 {
  font-size: 23px;
}

.tx-24 {
  font-size: 24px;
}

.tx-25 {
  font-size: 25px;
}

.tx-26 {
  font-size: 26px;
}

.tx-28 {
  font-size: 28px;
}

.tx-30 {
  font-size: 30px;
}

.tx-32 {
  font-size: 32px;
}

.tx-34 {
  font-size: 34px;
}

.tx-36 {
  font-size: 36px;
}

.tx-38 {
  font-size: 38px;
}

.tx-40 {
  font-size: 40px;
}

.tx-42 {
  font-size: 42px;
}

.tx-44 {
  font-size: 44px;
}

.tx-46 {
  font-size: 46px;
}

.tx-48 {
  font-size: 48px;
}

.tx-50 {
  font-size: 50px;
}

.tx-100 {
  font-size: 100px;
}

@media (min-width: 480px) {
  .tx-xs-8 {
    font-size: 8px;
  }

  .tx-xs-9 {
    font-size: 9px;
  }

  .tx-xs-10 {
    font-size: 10px;
  }

  .tx-xs-11 {
    font-size: 11px;
  }

  .tx-xs-12 {
    font-size: 12px;
  }

  .tx-xs-13 {
    font-size: 13px;
  }

  .tx-xs-14 {
    font-size: 14px;
  }

  .tx-xs-15 {
    font-size: 15px;
  }

  .tx-xs-16 {
    font-size: 16px;
  }

  .tx-xs-18 {
    font-size: 18px;
  }

  .tx-xs-20 {
    font-size: 20px;
  }

  .tx-xs-22 {
    font-size: 22px;
  }

  .tx-xs-24 {
    font-size: 24px;
  }

  .tx-xs-26 {
    font-size: 26px;
  }

  .tx-xs-28 {
    font-size: 28px;
  }

  .tx-xs-30 {
    font-size: 30px;
  }

  .tx-xs-32 {
    font-size: 32px;
  }

  .tx-xs-34 {
    font-size: 34px;
  }

  .tx-xs-36 {
    font-size: 36px;
  }

  .tx-xs-38 {
    font-size: 38px;
  }

  .tx-xs-40 {
    font-size: 40px;
  }

  .tx-xs-42 {
    font-size: 42px;
  }

  .tx-xs-44 {
    font-size: 44px;
  }

  .tx-xs-46 {
    font-size: 46px;
  }

  .tx-xs-48 {
    font-size: 48px;
  }

  .tx-xs-50 {
    font-size: 50px;
  }

  .tx-xs-100 {
    font-size: 100px;
  }

  .tx-xs-base {
    font-size: 0.875rem;
  }

  .tx-xs-bold {
    font-weight: 700;
  }

  .tx-xs-semibold {
    font-weight: 600;
  }

  .tx-xs-medium {
    font-weight: 500;
  }

  .tx-xs-normal {
    font-weight: 400;
  }

  .tx-xs-light {
    font-weight: 300;
  }

  .tx-xs-thin {
    font-weight: 200;
  }

  .tx-xs-xthin {
    font-weight: 100;
  }

  .lh-xs-0 {
    line-height: 0;
  }

  .lh-xs-1 {
    line-height: 1.1;
  }

  .lh-xs-2 {
    line-height: 1.2;
  }

  .lh-xs-3 {
    line-height: 1.3;
  }

  .lh-xs-4 {
    line-height: 1.4;
  }

  .lh-xs-5 {
    line-height: 1.5;
  }

  .lh-xs-6 {
    line-height: 1.6;
  }

  .lh-xs-7 {
    line-height: 1.7;
  }

  .lh-xs-8 {
    line-height: 1.8;
  }

  .lh-xs-9 {
    line-height: 1.9;
  }

  .lh-xs-10 {
    line-height: 2;
  }

  .lh-xs-11 {
    line-height: 2.1;
  }

  .lh-xs-12 {
    line-height: 2.2;
  }

  .lh-xs-13 {
    line-height: 2.3;
  }

  .lh-xs-14 {
    line-height: 2.4;
  }

  .lh-xs-15 {
    line-height: 2.5;
  }

  .tx-xs-center {
    text-align: center;
  }

  .tx-xs-right {
    text-align: end;
  }

  .tx-xs-left {
    text-align: start;
  }
}

@media (min-width: 576px) {
  .tx-sm-8 {
    font-size: 8px;
  }

  .tx-sm-9 {
    font-size: 9px;
  }

  .tx-sm-10 {
    font-size: 10px;
  }

  .tx-sm-11 {
    font-size: 11px;
  }

  .tx-sm-12 {
    font-size: 12px;
  }

  .tx-sm-13 {
    font-size: 13px;
  }

  .tx-sm-14 {
    font-size: 14px;
  }

  .tx-sm-15 {
    font-size: 15px;
  }

  .tx-sm-16 {
    font-size: 16px;
  }

  .tx-sm-18 {
    font-size: 18px;
  }

  .tx-sm-20 {
    font-size: 20px;
  }

  .tx-sm-22 {
    font-size: 22px;
  }

  .tx-sm-24 {
    font-size: 24px;
  }

  .tx-sm-26 {
    font-size: 26px;
  }

  .tx-sm-28 {
    font-size: 28px;
  }

  .tx-sm-30 {
    font-size: 30px;
  }

  .tx-sm-32 {
    font-size: 32px;
  }

  .tx-sm-34 {
    font-size: 34px;
  }

  .tx-sm-36 {
    font-size: 36px;
  }

  .tx-sm-38 {
    font-size: 38px;
  }

  .tx-sm-40 {
    font-size: 40px;
  }

  .tx-sm-42 {
    font-size: 42px;
  }

  .tx-sm-44 {
    font-size: 44px;
  }

  .tx-sm-46 {
    font-size: 46px;
  }

  .tx-sm-48 {
    font-size: 48px;
  }

  .tx-sm-50 {
    font-size: 50px;
  }

  .tx-sm-100 {
    font-size: 100px;
  }

  .tx-sm-base {
    font-size: 0.875rem;
  }

  .tx-sm-bold {
    font-weight: 700;
  }

  .tx-sm-semibold {
    font-weight: 600;
  }

  .tx-sm-medium {
    font-weight: 500;
  }

  .tx-sm-normal {
    font-weight: 400;
  }

  .tx-sm-light {
    font-weight: 300;
  }

  .tx-sm-thin {
    font-weight: 200;
  }

  .tx-sm-xthin {
    font-weight: 100;
  }

  .lh-sm-0 {
    line-height: 0;
  }

  .lh-sm-1 {
    line-height: 1.1;
  }

  .lh-sm-2 {
    line-height: 1.2;
  }

  .lh-sm-3 {
    line-height: 1.3;
  }

  .lh-sm-4 {
    line-height: 1.4;
  }

  .lh-sm-5 {
    line-height: 1.5;
  }

  .lh-sm-6 {
    line-height: 1.6;
  }

  .lh-sm-7 {
    line-height: 1.7;
  }

  .lh-sm-8 {
    line-height: 1.8;
  }

  .lh-sm-9 {
    line-height: 1.9;
  }

  .lh-sm-10 {
    line-height: 2;
  }

  .lh-sm-11 {
    line-height: 2.1;
  }

  .lh-sm-12 {
    line-height: 2.2;
  }

  .lh-sm-13 {
    line-height: 2.3;
  }

  .lh-sm-14 {
    line-height: 2.4;
  }

  .lh-sm-15 {
    line-height: 2.5;
  }

  .tx-sm-center {
    text-align: center;
  }

  .tx-sm-right {
    text-align: end;
  }

  .tx-sm-left {
    text-align: start;
  }
}

@media (min-width: 768px) {
  .tx-md-8 {
    font-size: 8px;
  }

  .tx-md-9 {
    font-size: 9px;
  }

  .tx-md-10 {
    font-size: 10px;
  }

  .tx-md-11 {
    font-size: 11px;
  }

  .tx-md-12 {
    font-size: 12px;
  }

  .tx-md-13 {
    font-size: 13px;
  }

  .tx-md-14 {
    font-size: 14px;
  }

  .tx-md-15 {
    font-size: 15px;
  }

  .tx-md-16 {
    font-size: 16px;
  }

  .tx-md-18 {
    font-size: 18px;
  }

  .tx-md-20 {
    font-size: 20px;
  }

  .tx-md-22 {
    font-size: 22px;
  }

  .tx-md-24 {
    font-size: 24px;
  }

  .tx-md-26 {
    font-size: 26px;
  }

  .tx-md-28 {
    font-size: 28px;
  }

  .tx-md-30 {
    font-size: 30px;
  }

  .tx-md-32 {
    font-size: 32px;
  }

  .tx-md-34 {
    font-size: 34px;
  }

  .tx-md-36 {
    font-size: 36px;
  }

  .tx-md-38 {
    font-size: 38px;
  }

  .tx-md-40 {
    font-size: 40px;
  }

  .tx-md-42 {
    font-size: 42px;
  }

  .tx-md-44 {
    font-size: 44px;
  }

  .tx-md-46 {
    font-size: 46px;
  }

  .tx-md-48 {
    font-size: 48px;
  }

  .tx-md-50 {
    font-size: 50px;
  }

  .tx-md-100 {
    font-size: 100px;
  }

  .tx-md-base {
    font-size: 0.875rem;
  }

  .tx-md-bold {
    font-weight: 700;
  }

  .tx-md-semibold {
    font-weight: 600;
  }

  .tx-md-medium {
    font-weight: 500;
  }

  .tx-md-normal {
    font-weight: 400;
  }

  .tx-md-light {
    font-weight: 300;
  }

  .tx-md-thin {
    font-weight: 200;
  }

  .tx-md-xthin {
    font-weight: 100;
  }

  .lh-md-0 {
    line-height: 0;
  }

  .lh-md-1 {
    line-height: 1.1;
  }

  .lh-md-2 {
    line-height: 1.2;
  }

  .lh-md-3 {
    line-height: 1.3;
  }

  .lh-md-4 {
    line-height: 1.4;
  }

  .lh-md-5 {
    line-height: 1.5;
  }

  .lh-md-6 {
    line-height: 1.6;
  }

  .lh-md-7 {
    line-height: 1.7;
  }

  .lh-md-8 {
    line-height: 1.8;
  }

  .lh-md-9 {
    line-height: 1.9;
  }

  .lh-md-10 {
    line-height: 2;
  }

  .lh-md-11 {
    line-height: 2.1;
  }

  .lh-md-12 {
    line-height: 2.2;
  }

  .lh-md-13 {
    line-height: 2.3;
  }

  .lh-md-14 {
    line-height: 2.4;
  }

  .lh-md-15 {
    line-height: 2.5;
  }

  .tx-md-center {
    text-align: center;
  }

  .tx-md-right {
    text-align: end;
  }

  .tx-md-left {
    text-align: start;
  }
}

@media (min-width: 992px) {
  .tx-lg-8 {
    font-size: 8px;
  }

  .tx-lg-9 {
    font-size: 9px;
  }

  .tx-lg-10 {
    font-size: 10px;
  }

  .tx-lg-11 {
    font-size: 11px;
  }

  .tx-lg-12 {
    font-size: 12px;
  }

  .tx-lg-13 {
    font-size: 13px;
  }

  .tx-lg-14 {
    font-size: 14px;
  }

  .tx-lg-15 {
    font-size: 15px;
  }

  .tx-lg-16 {
    font-size: 16px;
  }

  .tx-lg-18 {
    font-size: 18px;
  }

  .tx-lg-20 {
    font-size: 20px;
  }

  .tx-lg-22 {
    font-size: 22px;
  }

  .tx-lg-24 {
    font-size: 24px;
  }

  .tx-lg-26 {
    font-size: 26px;
  }

  .tx-lg-28 {
    font-size: 28px;
  }

  .tx-lg-30 {
    font-size: 30px;
  }

  .tx-lg-32 {
    font-size: 32px;
  }

  .tx-lg-34 {
    font-size: 34px;
  }

  .tx-lg-36 {
    font-size: 36px;
  }

  .tx-lg-38 {
    font-size: 38px;
  }

  .tx-lg-40 {
    font-size: 40px;
  }

  .tx-lg-42 {
    font-size: 42px;
  }

  .tx-lg-44 {
    font-size: 44px;
  }

  .tx-lg-46 {
    font-size: 46px;
  }

  .tx-lg-48 {
    font-size: 48px;
  }

  .tx-lg-50 {
    font-size: 50px;
  }

  .tx-lg-100 {
    font-size: 100px;
  }

  .tx-lg-base {
    font-size: 0.875rem;
  }

  .tx-lg-bold {
    font-weight: 700;
  }

  .tx-lg-semibold {
    font-weight: 600;
  }

  .tx-lg-medium {
    font-weight: 500;
  }

  .tx-lg-normal {
    font-weight: 400;
  }

  .tx-lg-light {
    font-weight: 300;
  }

  .tx-lg-thin {
    font-weight: 200;
  }

  .tx-lg-xthin {
    font-weight: 100;
  }

  .lh-lg-0 {
    line-height: 0;
  }

  .lh-lg-1 {
    line-height: 1.1;
  }

  .lh-lg-2 {
    line-height: 1.2;
  }

  .lh-lg-3 {
    line-height: 1.3;
  }

  .lh-lg-4 {
    line-height: 1.4;
  }

  .lh-lg-5 {
    line-height: 1.5;
  }

  .lh-lg-6 {
    line-height: 1.6;
  }

  .lh-lg-7 {
    line-height: 1.7;
  }

  .lh-lg-8 {
    line-height: 1.8;
  }

  .lh-lg-9 {
    line-height: 1.9;
  }

  .lh-lg-10 {
    line-height: 2;
  }

  .lh-lg-11 {
    line-height: 2.1;
  }

  .lh-lg-12 {
    line-height: 2.2;
  }

  .lh-lg-13 {
    line-height: 2.3;
  }

  .lh-lg-14 {
    line-height: 2.4;
  }

  .lh-lg-15 {
    line-height: 2.5;
  }

  .tx-lg-center {
    text-align: center;
  }

  .tx-lg-right {
    text-align: end;
  }

  .tx-lg-left {
    text-align: start;
  }
}

@media (min-width: 1200px) {
  .tx-xl-8 {
    font-size: 8px;
  }

  .tx-xl-9 {
    font-size: 9px;
  }

  .tx-xl-10 {
    font-size: 10px;
  }

  .tx-xl-11 {
    font-size: 11px;
  }

  .tx-xl-12 {
    font-size: 12px;
  }

  .tx-xl-13 {
    font-size: 13px;
  }

  .tx-xl-14 {
    font-size: 14px;
  }

  .tx-xl-15 {
    font-size: 15px;
  }

  .tx-xl-16 {
    font-size: 16px;
  }

  .tx-xl-18 {
    font-size: 18px;
  }

  .tx-xl-20 {
    font-size: 20px;
  }

  .tx-xl-22 {
    font-size: 22px;
  }

  .tx-xl-24 {
    font-size: 24px;
  }

  .tx-xl-26 {
    font-size: 26px;
  }

  .tx-xl-28 {
    font-size: 28px;
  }

  .tx-xl-30 {
    font-size: 30px;
  }

  .tx-xl-32 {
    font-size: 32px;
  }

  .tx-xl-34 {
    font-size: 34px;
  }

  .tx-xl-36 {
    font-size: 36px;
  }

  .tx-xl-38 {
    font-size: 38px;
  }

  .tx-xl-40 {
    font-size: 40px;
  }

  .tx-xl-42 {
    font-size: 42px;
  }

  .tx-xl-44 {
    font-size: 44px;
  }

  .tx-xl-46 {
    font-size: 46px;
  }

  .tx-xl-48 {
    font-size: 48px;
  }

  .tx-xl-50 {
    font-size: 50px;
  }

  .tx-xl-100 {
    font-size: 100px;
  }

  .tx-xl-base {
    font-size: 0.875rem;
  }

  .tx-xl-bold {
    font-weight: 700;
  }

  .tx-xl-semibold {
    font-weight: 600;
  }

  .tx-xl-medium {
    font-weight: 500;
  }

  .tx-xl-normal {
    font-weight: 400;
  }

  .tx-xl-light {
    font-weight: 300;
  }

  .tx-xl-thin {
    font-weight: 200;
  }

  .tx-xl-xthin {
    font-weight: 100;
  }

  .lh-xl-0 {
    line-height: 0;
  }

  .lh-xl-1 {
    line-height: 1.1;
  }

  .lh-xl-2 {
    line-height: 1.2;
  }

  .lh-xl-3 {
    line-height: 1.3;
  }

  .lh-xl-4 {
    line-height: 1.4;
  }

  .lh-xl-5 {
    line-height: 1.5;
  }

  .lh-xl-6 {
    line-height: 1.6;
  }

  .lh-xl-7 {
    line-height: 1.7;
  }

  .lh-xl-8 {
    line-height: 1.8;
  }

  .lh-xl-9 {
    line-height: 1.9;
  }

  .lh-xl-10 {
    line-height: 2;
  }

  .lh-xl-11 {
    line-height: 2.1;
  }

  .lh-xl-12 {
    line-height: 2.2;
  }

  .lh-xl-13 {
    line-height: 2.3;
  }

  .lh-xl-14 {
    line-height: 2.4;
  }

  .lh-xl-15 {
    line-height: 2.5;
  }

  .tx-xl-center {
    text-align: center;
  }

  .tx-xl-right {
    text-align: end;
  }

  .tx-xl-left {
    text-align: start;
  }
}

.text-nowrap {
  white-space: nowrap !important;
}

@media (max-width: 576px) {
  .text-sm-nowrap {
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .text-md-nowrap {
    white-space: nowrap;
  }
}

@media (max-width: 992px) {
  .text-lg-nowrap {
    white-space: nowrap;
  }
}

@media (max-width: 1000px) {
  .text-xl-nowrap {
    white-space: nowrap;
  }
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono",
    "Courier New", monospace;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-start {
  text-align: start !important;
}

.text-end {
  text-align: end !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: start !important;
  }

  .text-sm-right {
    text-align: end !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: start;
  }

  .text-md-right {
    text-align: end;
  }

  .text-md-center {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: start;
  }

  .text-lg-right {
    text-align: end;
  }

  .text-lg-center {
    text-align: center;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: start;
  }

  .text-xl-right {
    text-align: end;
  }

  .text-xl-center {
    text-align: center;
  }
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300;
}

.font-weight-lighter {
  font-weight: lighter;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder;
}

.font-italic {
  font-style: italic;
}

.text-white-80 {
  color: var(--white-8);
  opacity: 0.8;
}

.text-primary {
  color: var(--primary-bg-color) !important;
}

a:hover {
  opacity: 1 !important;
}

a.text-primary:hover,
a.text-primary:focus {
  color: var(--primary-bg-hover) !important;
}

.text-secondary {
  color: var(--secondary) !important;
}

a.text-secondary:hover,
a.text-secondary:focus {
  color: #ffcf86 !important;
}

.text-success {
  color: #43ce85 !important;
}

a.text-success:hover,
a.text-success:focus {
  color: #0f9e86 !important;
}

.text-info {
  color: #4ec2f0 !important;
}

a.text-info:hover,
a.text-info:focus {
  color: #37b8eb !important;
}

a.text-warning:hover,
a.text-warning:focus {
  color: #faae3d !important;
}

.text-purple {
  color: #7891ef !important;
}

a.text-purple:hover,
a.text-purple:focus {
  color: #7891ef !important;
}

.text-danger {
  color: #d6573c !important;
}

a.text-danger:hover,
a.text-danger:focus {
  color: #a70d1c !important;
}

.text-pink {
  color: #f754fa !important;
}

a.text-pink:hover,
a.text-pink:focus {
  color: #e61078 !important;
}

.text-orange {
  color: #ffb67b !important;
}

a.text-orange:hover,
a.text-orange:focus {
  color: #e46f0e !important;
}

.text-dark-light {
  color: var(--dark) !important;
  opacity: 0.1;
}

.text-light {
  color: #fff !important;
}

a.text-light:hover,
a.text-light:focus {
  color: #fff !important;
}

.text-teal {
  color: #4ac9bd !important;
}

a.text-teal:hover,
a.text-teal:focus {
  color: #06c1c1 !important;
}

.text-dark {
  color: var(--gray-800) !important;
}

a.text-dark:hover,
a.text-dark:focus {
  color: #1e2533 !important;
}

.text-body {
  color: var(--color) !important;
}

.text-muted {
  color: var(--muted) !important;
}

.text-black-50 {
  color: var(--black-5);
}

.text-white-50 {
  color: var(--white-5);
}

.text-hide {
  font: 0/0 a;
  color: var(--color);
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none;
}

.text-break {
  word-break: normal;
  overflow-wrap: break-word;
}

.text-reset {
  color: inherit;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden;
}

.text-primary-light {
  color: var(--primary02) !important;
}

/* svg colors */
.svg-primary {
  fill: var(--primary-bg-color);
}

.svg-secondary {
  fill: var(--secondary);
}

.svg-info {
  fill: #4ec2f0;
}

.svg-warning {
  fill: #ffcf86;
}

.svg-success {
  fill: #43ce85;
}

.svg-white {
  fill: var(--black);
}

/* svg colors */
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.w-fit-content {
  width: fit-content !important;
}

.min-w-fit-content {
  min-width: fit-content !important;
}

/* ###### 9.9 Width   ###### */
.wd-1 {
  width: 1px;
}

.wd-2 {
  width: 2px;
}

.wd-3 {
  width: 3px;
}

.wd-4 {
  width: 4px;
}

.wd-6 {
  width: 6px;
}

.wd-7 {
  width: 7px;
}

.wd-8 {
  width: 8px;
}

.wd-9 {
  width: 9px;
}

.wd-280 {
  width: 280px;
}

.wd-5 {
  width: 5px;
}

.wd-5p {
  width: 5%;
}

.wd-10 {
  width: 10px;
}

.wd-10p {
  width: 10%;
}

.wd-15 {
  width: 15px;
}

.wd-15p {
  width: 15%;
}

.wd-20 {
  width: 20px;
}

.wd-20p {
  width: 20%;
}

.wd-25 {
  width: 25px;
}

.wd-25p {
  width: 25%;
}

.wd-30 {
  width: 30px !important;
}

.wd-30p {
  width: 30%;
}

.wd-35 {
  width: 35px;
}

.wd-35p {
  width: 35%;
}

.wd-40 {
  width: 40px;
}

.wd-40p {
  width: 40%;
}

.wd-45 {
  width: 45px;
}

.wd-45p {
  width: 45%;
}

.wd-50 {
  width: 50px;
}

.wd-50p {
  width: 50%;
}

.wd-55 {
  width: 55px !important;
}

.wd-55p {
  width: 55%;
}

.wd-60 {
  width: 60px;
}

.wd-60p {
  width: 60%;
}

.wd-65 {
  width: 65px !important;
}

.wd-65p {
  width: 65%;
}

.wd-70 {
  width: 70px;
}

.wd-70p {
  width: 70%;
}

.wd-75 {
  width: 75px;
}

.wd-75p {
  width: 75%;
}

.wd-80 {
  width: 80px;
}

.wd-80p {
  width: 80%;
}

.wd-85 {
  width: 85px;
}

.wd-85p {
  width: 85%;
}

.wd-90 {
  width: 90px;
}

.wd-90p {
  width: 90%;
}

.wd-95 {
  width: 95px !important;
}

.wd-95p {
  width: 95%;
}

.wd-100 {
  width: 100px !important;
}

.wd-100p {
  width: 100%;
}

.wd-150 {
  width: 150px !important;
}

.wd-200 {
  width: 200px !important;
}

.wd-230 {
  width: 230px !important;
}

.wd-250 {
  width: 250px;
}

.wd-300 {
  width: 300px !important;
}

.wd-350 {
  width: 350px !important;
}

.wd-400 {
  width: 400px;
}

.wd-450 {
  width: 450px;
}

.wd-500 {
  width: 500px;
}

.wd-550 {
  width: 550px;
}

.wd-600 {
  width: 600px;
}

.wd-650 {
  width: 650px;
}

.wd-700 {
  width: 700px;
}

.wd-750 {
  width: 750px;
}

.wd-800 {
  width: 800px;
}

.wd-850 {
  width: 850px;
}

.wd-900 {
  width: 900px;
}

.wd-950 {
  width: 950px;
}

.wd-1000 {
  width: 1000px;
}

@media (min-width: 480px) {
  .wd-xs-5 {
    width: 5px;
  }

  .wd-xs-5p {
    width: 5%;
  }

  .wd-xs-10 {
    width: 10px;
  }

  .wd-xs-10p {
    width: 10%;
  }

  .wd-xs-15 {
    width: 15px;
  }

  .wd-xs-15p {
    width: 15%;
  }

  .wd-xs-20 {
    width: 20px;
  }

  .wd-xs-20p {
    width: 20%;
  }

  .wd-xs-25 {
    width: 25px;
  }

  .wd-xs-25p {
    width: 25%;
  }

  .wd-xs-30 {
    width: 30px;
  }

  .wd-xs-30p {
    width: 30%;
  }

  .wd-xs-35 {
    width: 35px;
  }

  .wd-xs-35p {
    width: 35%;
  }

  .wd-xs-40 {
    width: 40px;
  }

  .wd-xs-40p {
    width: 40%;
  }

  .wd-xs-45 {
    width: 45px;
  }

  .wd-xs-45p {
    width: 45%;
  }

  .wd-xs-50 {
    width: 50px;
  }

  .wd-xs-50p {
    width: 50%;
  }

  .wd-xs-55 {
    width: 55px;
  }

  .wd-xs-55p {
    width: 55%;
  }

  .wd-xs-60 {
    width: 60px;
  }

  .wd-xs-60p {
    width: 60%;
  }

  .wd-xs-65 {
    width: 65px;
  }

  .wd-xs-65p {
    width: 65%;
  }

  .wd-xs-70 {
    width: 70px;
  }

  .wd-xs-70p {
    width: 70%;
  }

  .wd-xs-75 {
    width: 75px;
  }

  .wd-xs-75p {
    width: 75%;
  }

  .wd-xs-80 {
    width: 80px;
  }

  .wd-xs-80p {
    width: 80%;
  }

  .wd-xs-85 {
    width: 85px;
  }

  .wd-xs-85p {
    width: 85%;
  }

  .wd-xs-90 {
    width: 90px;
  }

  .wd-xs-90p {
    width: 90%;
  }

  .wd-xs-95 {
    width: 95px;
  }

  .wd-xs-95p {
    width: 95%;
  }

  .wd-xs-100 {
    width: 100px;
  }

  .wd-xs-100p {
    width: 100%;
  }

  .wd-xs-150 {
    width: 150px;
  }

  .wd-xs-150p {
    width: 150%;
  }

  .wd-xs-200 {
    width: 200px;
  }

  .wd-xs-200p {
    width: 200%;
  }

  .wd-xs-250 {
    width: 250px;
  }

  .wd-xs-250p {
    width: 250%;
  }

  .wd-xs-300 {
    width: 300px;
  }

  .wd-xs-300p {
    width: 300%;
  }

  .wd-xs-350 {
    width: 350px;
  }

  .wd-xs-350p {
    width: 350%;
  }

  .wd-xs-400 {
    width: 400px;
  }

  .wd-xs-400p {
    width: 400%;
  }

  .wd-xs-450 {
    width: 450px;
  }

  .wd-xs-450p {
    width: 450%;
  }

  .wd-xs-500 {
    width: 500px;
  }

  .wd-xs-500p {
    width: 500%;
  }

  .wd-xs-550 {
    width: 550px;
  }

  .wd-xs-550p {
    width: 550%;
  }

  .wd-xs-600 {
    width: 600px;
  }

  .wd-xs-600p {
    width: 600%;
  }

  .wd-xs-650 {
    width: 650px;
  }

  .wd-xs-650p {
    width: 650%;
  }

  .wd-xs-700 {
    width: 700px;
  }

  .wd-xs-700p {
    width: 700%;
  }

  .wd-xs-750 {
    width: 750px;
  }

  .wd-xs-750p {
    width: 750%;
  }

  .wd-xs-800 {
    width: 800px;
  }

  .wd-xs-800p {
    width: 800%;
  }

  .wd-xs-850 {
    width: 850px;
  }

  .wd-xs-850p {
    width: 850%;
  }

  .wd-xs-900 {
    width: 900px;
  }

  .wd-xs-900p {
    width: 900%;
  }

  .wd-xs-950 {
    width: 950px;
  }

  .wd-xs-950p {
    width: 950%;
  }

  .wd-xs-1000 {
    width: 1000px;
  }

  .wd-xs-1000p {
    width: 1000%;
  }

  .wd-xs-auto {
    width: auto;
  }
}

@media (min-width: 576px) {
  .wd-sm-5 {
    width: 5px;
  }

  .wd-sm-5p {
    width: 5%;
  }

  .wd-sm-10 {
    width: 10px;
  }

  .wd-sm-10p {
    width: 10%;
  }

  .wd-sm-15 {
    width: 15px;
  }

  .wd-sm-15p {
    width: 15%;
  }

  .wd-sm-20 {
    width: 20px;
  }

  .wd-sm-20p {
    width: 20%;
  }

  .wd-sm-25 {
    width: 25px;
  }

  .wd-sm-25p {
    width: 25%;
  }

  .wd-sm-30 {
    width: 30px;
  }

  .wd-sm-30p {
    width: 30%;
  }

  .wd-sm-35 {
    width: 35px;
  }

  .wd-sm-35p {
    width: 35%;
  }

  .wd-sm-40 {
    width: 40px;
  }

  .wd-sm-40p {
    width: 40%;
  }

  .wd-sm-45 {
    width: 45px;
  }

  .wd-sm-45p {
    width: 45%;
  }

  .wd-sm-50 {
    width: 50px;
  }

  .wd-sm-50p {
    width: 50%;
  }

  .wd-sm-55 {
    width: 55px;
  }

  .wd-sm-55p {
    width: 55%;
  }

  .wd-sm-60 {
    width: 60px;
  }

  .wd-sm-60p {
    width: 60%;
  }

  .wd-sm-65 {
    width: 65px;
  }

  .wd-sm-65p {
    width: 65%;
  }

  .wd-sm-70 {
    width: 70px;
  }

  .wd-sm-70p {
    width: 70%;
  }

  .wd-sm-75 {
    width: 75px;
  }

  .wd-sm-75p {
    width: 75%;
  }

  .wd-sm-80 {
    width: 80px;
  }

  .wd-sm-80p {
    width: 80%;
  }

  .wd-sm-85 {
    width: 85px;
  }

  .wd-sm-85p {
    width: 85%;
  }

  .wd-sm-90 {
    width: 90px;
  }

  .wd-sm-90p {
    width: 90%;
  }

  .wd-sm-95 {
    width: 95px;
  }

  .wd-sm-95p {
    width: 95%;
  }

  .wd-sm-100 {
    width: 100px;
  }

  .wd-sm-100p {
    width: 100%;
  }

  .wd-sm-150 {
    width: 150px;
  }

  .wd-sm-150p {
    width: 150%;
  }

  .wd-sm-200 {
    width: 200px;
  }

  .wd-sm-200p {
    width: 200%;
  }

  .wd-sm-250 {
    width: 250px;
  }

  .wd-sm-250p {
    width: 250%;
  }

  .wd-sm-300 {
    width: 300px;
  }

  .wd-sm-300p {
    width: 300%;
  }

  .wd-sm-350 {
    width: 350px;
  }

  .wd-sm-350p {
    width: 350%;
  }

  .wd-sm-400 {
    width: 400px;
  }

  .wd-sm-400p {
    width: 400%;
  }

  .wd-sm-450 {
    width: 450px;
  }

  .wd-sm-450p {
    width: 450%;
  }

  .wd-sm-500 {
    width: 500px;
  }

  .wd-sm-500p {
    width: 500%;
  }

  .wd-sm-550 {
    width: 550px;
  }

  .wd-sm-550p {
    width: 550%;
  }

  .wd-sm-600 {
    width: 600px;
  }

  .wd-sm-600p {
    width: 600%;
  }

  .wd-sm-650 {
    width: 650px;
  }

  .wd-sm-650p {
    width: 650%;
  }

  .wd-sm-700 {
    width: 700px;
  }

  .wd-sm-700p {
    width: 700%;
  }

  .wd-sm-750 {
    width: 750px;
  }

  .wd-sm-750p {
    width: 750%;
  }

  .wd-sm-800 {
    width: 800px;
  }

  .wd-sm-800p {
    width: 800%;
  }

  .wd-sm-850 {
    width: 850px;
  }

  .wd-sm-850p {
    width: 850%;
  }

  .wd-sm-900 {
    width: 900px;
  }

  .wd-sm-900p {
    width: 900%;
  }

  .wd-sm-950 {
    width: 950px;
  }

  .wd-sm-950p {
    width: 950%;
  }

  .wd-sm-1000 {
    width: 1000px;
  }

  .wd-sm-1000p {
    width: 1000%;
  }

  .wd-sm-auto {
    width: auto;
  }
}

@media (min-width: 768px) {
  .wd-md-5 {
    width: 5px;
  }

  .wd-md-5p {
    width: 5%;
  }

  .wd-md-10 {
    width: 10px;
  }

  .wd-md-10p {
    width: 10%;
  }

  .wd-md-15 {
    width: 15px;
  }

  .wd-md-15p {
    width: 15%;
  }

  .wd-md-20 {
    width: 20px;
  }

  .wd-md-20p {
    width: 20%;
  }

  .wd-md-25 {
    width: 25px;
  }

  .wd-md-25p {
    width: 25%;
  }

  .wd-md-30 {
    width: 30px;
  }

  .wd-md-30p {
    width: 30%;
  }

  .wd-md-35 {
    width: 35px;
  }

  .wd-md-35p {
    width: 35%;
  }

  .wd-md-40 {
    width: 40px;
  }

  .wd-md-40p {
    width: 40%;
  }

  .wd-md-45 {
    width: 45px;
  }

  .wd-md-45p {
    width: 45%;
  }

  .wd-md-50 {
    width: 50px;
  }

  .wd-md-50p {
    width: 50%;
  }

  .wd-md-55 {
    width: 55px;
  }

  .wd-md-55p {
    width: 55%;
  }

  .wd-md-60 {
    width: 60px;
  }

  .wd-md-60p {
    width: 60%;
  }

  .wd-md-65 {
    width: 65px;
  }

  .wd-md-65p {
    width: 65%;
  }

  .wd-md-70 {
    width: 70px;
  }

  .wd-md-70p {
    width: 70%;
  }

  .wd-md-75 {
    width: 75px;
  }

  .wd-md-75p {
    width: 75%;
  }

  .wd-md-80 {
    width: 80px;
  }

  .wd-md-80p {
    width: 80%;
  }

  .wd-md-85 {
    width: 85px;
  }

  .wd-md-85p {
    width: 85%;
  }

  .wd-md-90 {
    width: 90px;
  }

  .wd-md-90p {
    width: 90%;
  }

  .wd-md-95 {
    width: 95px;
  }

  .wd-md-95p {
    width: 95%;
  }

  .wd-md-100 {
    width: 100px;
  }

  .wd-md-100p {
    width: 100%;
  }

  .wd-md-150 {
    width: 150px;
  }

  .wd-md-150p {
    width: 150%;
  }

  .wd-md-200 {
    width: 200px;
  }

  .wd-md-200p {
    width: 200%;
  }

  .wd-md-250 {
    width: 250px;
  }

  .wd-md-250p {
    width: 250%;
  }

  .wd-md-300 {
    width: 300px;
  }

  .wd-md-300p {
    width: 300%;
  }

  .wd-md-350 {
    width: 350px;
  }

  .wd-md-350p {
    width: 350%;
  }

  .wd-md-400 {
    width: 400px;
  }

  .wd-md-400p {
    width: 400%;
  }

  .wd-md-450 {
    width: 450px;
  }

  .wd-md-450p {
    width: 450%;
  }

  .wd-md-500 {
    width: 500px;
  }

  .wd-md-500p {
    width: 500%;
  }

  .wd-md-550 {
    width: 550px;
  }

  .wd-md-550p {
    width: 550%;
  }

  .wd-md-600 {
    width: 600px;
  }

  .wd-md-600p {
    width: 600%;
  }

  .wd-md-650 {
    width: 650px;
  }

  .wd-md-650p {
    width: 650%;
  }

  .wd-md-700 {
    width: 700px;
  }

  .wd-md-700p {
    width: 700%;
  }

  .wd-md-750 {
    width: 750px;
  }

  .wd-md-750p {
    width: 750%;
  }

  .wd-md-800 {
    width: 800px;
  }

  .wd-md-800p {
    width: 800%;
  }

  .wd-md-850 {
    width: 850px;
  }

  .wd-md-850p {
    width: 850%;
  }

  .wd-md-900 {
    width: 900px;
  }

  .wd-md-900p {
    width: 900%;
  }

  .wd-md-950 {
    width: 950px;
  }

  .wd-md-950p {
    width: 950%;
  }

  .wd-md-1000 {
    width: 1000px;
  }

  .wd-md-1000p {
    width: 1000%;
  }

  .wd-md-auto {
    width: auto;
  }

  .wd-md-120 {
    width: 120px;
  }
}

@media (min-width: 992px) {
  .wd-lg-5 {
    width: 5px;
  }

  .wd-lg-5p {
    width: 5%;
  }

  .wd-lg-10 {
    width: 10px;
  }

  .wd-lg-10p {
    width: 10%;
  }

  .wd-lg-15 {
    width: 15px;
  }

  .wd-lg-15p {
    width: 15%;
  }

  .wd-lg-20 {
    width: 20px;
  }

  .wd-lg-20p {
    width: 20%;
  }

  .wd-lg-25 {
    width: 25px;
  }

  .wd-lg-25p {
    width: 25%;
  }

  .wd-lg-30 {
    width: 30px;
  }

  .wd-lg-30p {
    width: 30%;
  }

  .wd-lg-35 {
    width: 35px;
  }

  .wd-lg-35p {
    width: 35%;
  }

  .wd-lg-40 {
    width: 40px;
  }

  .wd-lg-40p {
    width: 40%;
  }

  .wd-lg-45 {
    width: 45px;
  }

  .wd-lg-45p {
    width: 45%;
  }

  .wd-lg-50 {
    width: 50px;
  }

  .wd-lg-50p {
    width: 50%;
  }

  .wd-lg-55 {
    width: 55px;
  }

  .wd-lg-55p {
    width: 55%;
  }

  .wd-lg-60 {
    width: 60px;
  }

  .wd-lg-60p {
    width: 60%;
  }

  .wd-lg-65 {
    width: 65px;
  }

  .wd-lg-65p {
    width: 65%;
  }

  .wd-lg-70 {
    width: 70px;
  }

  .wd-lg-70p {
    width: 70%;
  }

  .wd-lg-75 {
    width: 75px;
  }

  .wd-lg-75p {
    width: 75%;
  }

  .wd-lg-80 {
    width: 80px;
  }

  .wd-lg-80p {
    width: 80%;
  }

  .wd-lg-85 {
    width: 85px;
  }

  .wd-lg-85p {
    width: 85%;
  }

  .wd-lg-90 {
    width: 90px;
  }

  .wd-lg-90p {
    width: 90%;
  }

  .wd-lg-95 {
    width: 95px;
  }

  .wd-lg-95p {
    width: 95%;
  }

  .wd-lg-100 {
    width: 100px;
  }

  .wd-lg-100p {
    width: 100%;
  }

  .wd-lg-150 {
    width: 150px;
  }

  .wd-lg-150p {
    width: 150%;
  }

  .wd-lg-200 {
    width: 200px;
  }

  .wd-lg-200p {
    width: 200%;
  }

  .wd-lg-250 {
    width: 250px;
  }

  .wd-lg-250p {
    width: 250%;
  }

  .wd-lg-300 {
    width: 300px;
  }

  .wd-lg-300p {
    width: 300%;
  }

  .wd-lg-350 {
    width: 350px;
  }

  .wd-lg-350p {
    width: 350%;
  }

  .wd-lg-400 {
    width: 400px;
  }

  .wd-lg-400p {
    width: 400%;
  }

  .wd-lg-450 {
    width: 450px;
  }

  .wd-lg-450p {
    width: 450%;
  }

  .wd-lg-500 {
    width: 500px;
  }

  .wd-lg-500p {
    width: 500%;
  }

  .wd-lg-550 {
    width: 550px;
  }

  .wd-lg-550p {
    width: 550%;
  }

  .wd-lg-600 {
    width: 600px;
  }

  .wd-lg-600p {
    width: 600%;
  }

  .wd-lg-650 {
    width: 650px;
  }

  .wd-lg-650p {
    width: 650%;
  }

  .wd-lg-700 {
    width: 700px;
  }

  .wd-lg-700p {
    width: 700%;
  }

  .wd-lg-750 {
    width: 750px;
  }

  .wd-lg-750p {
    width: 750%;
  }

  .wd-lg-800 {
    width: 800px;
  }

  .wd-lg-800p {
    width: 800%;
  }

  .wd-lg-850 {
    width: 850px;
  }

  .wd-lg-850p {
    width: 850%;
  }

  .wd-lg-900 {
    width: 900px;
  }

  .wd-lg-900p {
    width: 900%;
  }

  .wd-lg-950 {
    width: 950px;
  }

  .wd-lg-950p {
    width: 950%;
  }

  .wd-lg-1000 {
    width: 1000px;
  }

  .wd-lg-1000p {
    width: 1000%;
  }

  .wd-lg-auto {
    width: auto;
  }
}

@media (min-width: 1200px) {
  .wd-xl-5 {
    width: 5px;
  }

  .wd-xl-5p {
    width: 5%;
  }

  .wd-xl-10 {
    width: 10px;
  }

  .wd-xl-10p {
    width: 10%;
  }

  .wd-xl-15 {
    width: 15px;
  }

  .wd-xl-15p {
    width: 15%;
  }

  .wd-xl-20 {
    width: 20px;
  }

  .wd-xl-20p {
    width: 20%;
  }

  .wd-xl-25 {
    width: 25px;
  }

  .wd-xl-25p {
    width: 25%;
  }

  .wd-xl-30 {
    width: 30px;
  }

  .wd-xl-30p {
    width: 30%;
  }

  .wd-xl-35 {
    width: 35px;
  }

  .wd-xl-35p {
    width: 35%;
  }

  .wd-xl-40 {
    width: 40px;
  }

  .wd-xl-40p {
    width: 40%;
  }

  .wd-xl-45 {
    width: 45px;
  }

  .wd-xl-45p {
    width: 45%;
  }

  .wd-xl-50 {
    width: 50px;
  }

  .wd-xl-50p {
    width: 50%;
  }

  .wd-xl-55 {
    width: 55px;
  }

  .wd-xl-55p {
    width: 55%;
  }

  .wd-xl-60 {
    width: 60px;
  }

  .wd-xl-60p {
    width: 60%;
  }

  .wd-xl-65 {
    width: 65px;
  }

  .wd-xl-65p {
    width: 65%;
  }

  .wd-xl-70 {
    width: 70px;
  }

  .wd-xl-70p {
    width: 70%;
  }

  .wd-xl-75 {
    width: 75px;
  }

  .wd-xl-75p {
    width: 75%;
  }

  .wd-xl-80 {
    width: 80px;
  }

  .wd-xl-80p {
    width: 80%;
  }

  .wd-xl-85 {
    width: 85px;
  }

  .wd-xl-85p {
    width: 85%;
  }

  .wd-xl-90 {
    width: 90px;
  }

  .wd-xl-90p {
    width: 90%;
  }

  .wd-xl-95 {
    width: 95px;
  }

  .wd-xl-95p {
    width: 95%;
  }

  .wd-xl-100 {
    width: 100px;
  }

  .wd-xl-100p {
    width: 100%;
  }

  .wd-xl-150 {
    width: 150px;
  }

  .wd-xl-150p {
    width: 150%;
  }

  .wd-xl-200 {
    width: 200px;
  }

  .wd-xl-200p {
    width: 200%;
  }

  .wd-xl-250 {
    width: 250px;
  }

  .wd-xl-250p {
    width: 250%;
  }

  .wd-xl-300 {
    width: 300px;
  }

  .wd-xl-300p {
    width: 300%;
  }

  .wd-xl-350 {
    width: 350px;
  }

  .wd-xl-350p {
    width: 350%;
  }

  .wd-xl-400 {
    width: 400px;
  }

  .wd-xl-400p {
    width: 400%;
  }

  .wd-xl-450 {
    width: 450px;
  }

  .wd-xl-450p {
    width: 450%;
  }

  .wd-xl-500 {
    width: 500px;
  }

  .wd-xl-500p {
    width: 500%;
  }

  .wd-xl-550 {
    width: 550px;
  }

  .wd-xl-550p {
    width: 550%;
  }

  .wd-xl-600 {
    width: 600px;
  }

  .wd-xl-600p {
    width: 600%;
  }

  .wd-xl-650 {
    width: 650px;
  }

  .wd-xl-650p {
    width: 650%;
  }

  .wd-xl-700 {
    width: 700px;
  }

  .wd-xl-700p {
    width: 700%;
  }

  .nft_theme .wd-xl-750 {
    width: 750px;
  }

  .nft_theme .wd-xl-750p {
    width: 750%;
  }

  .nft_theme .wd-xl-800 {
    width: 800px;
  }

  .nft_theme .wd-xl-800p {
    width: 800%;
  }

  .nft_theme .wd-xl-850 {
    width: 850px;
  }

  .nft_theme .wd-xl-850p {
    width: 850%;
  }

  .nft_theme .wd-xl-900 {
    width: 900px;
  }

  .nft_theme .wd-xl-900p {
    width: 900%;
  }

  .nft_theme .wd-xl-950 {
    width: 950px;
  }

  .nft_theme .wd-xl-950p {
    width: 950%;
  }

  .nft_theme .wd-xl-1000 {
    width: 1000px;
  }

  .nft_theme .wd-xl-1000p {
    width: 1000%;
  }

  .nft_theme .wd-xl-auto {
    width: auto;
    width: auto !important;
  }
}

.nft_theme .wd-100v {
  width: 100vw;
}

.nft_theme .btn-w-xs {
  min-width: 50px;
}

.nft_theme .btn-w-sm {
  min-width: 85px;
}

.nft_theme .btn-w {
  min-width: 130px;
}

.nft_theme .btn-w-lg {
  min-width: 180px;
}

/* Switcher-STYLES */
[dir="rtl"] .sidebar.sidebar-right,
[dir="rtl"] .fm-sidebar {
  transform: translate(-100%, 0);
}

[dir="rtl"] .main-sidemenu i.angle {
  transform: rotate(180deg);
}

[dir="rtl"] .sub-slide.is-expanded .sub-angle {
  transform: rotate(90deg);
}

[dir="rtl"] .sub-slide2.is-expanded .sub-angle2 {
  transform: rotate(90deg);
}

[dir="rtl"] .fc-direction-ltr {
  direction: rtl;
}

[dir="rtl"] .fc-direction-ltr .fc-button .fc-icon {
  transform: rotate(180deg);
}

[dir="rtl"] #projectReport .apexcharts-yaxis-texts-g text {
  transform: translate(0, 0px);
}

[dir="rtl"] .apexcharts-yaxis-texts-g text {
  transform: translate(-70px, 0px);
}

[dir="rtl"] .pagination .page-item .page-link i {
  transform: rotate(180deg);
}

[dir="rtl"] .badge-top {
  transform: translate(50%, -50%);
}

[dir="rtl"] .carousel .carousel-control-next i,
[dir="rtl"] .carousel .carousel-control-prev i {
  transform: rotate(180deg);
}

[dir="rtl"] .active.carousel-item-start,
[dir="rtl"] .carousel-item-prev:not(.carousel-item-end) {
  transform: translateX(100%) !important;
}

[dir="rtl"] .active.carousel-item-end {
  transform: translateX(-100%) !important;
}

[dir="rtl"] .carousel-item-next:not(.carousel-item-start) {
  transform: translateX(-100%) !important;
  transform: translateX(-100%) !important;
}

[dir="rtl"] .vtimeline .timeline-wrapper .timeline-badge {
  transform: translateX(50%);
}

[dir="rtl"] .image-attach .attach-options {
  transform: translateX(50%);
}

[dir="rtl"] .smartphoto-arrow-right a,
[dir="rtl"] .smartphoto-arrow-left a {
  transform: rotate(180deg);
}

[dir="rtl"] .was-validated .form-control:invalid,
[dir="rtl"] .form-control.is-invalid,
[dir="rtl"] input.form-control.parsley-error,
[dir="rtl"] textarea.form-control.parsley-error {
  background-position: center left calc(0.375em + 0.1875rem);
}

[dir="rtl"] .was-validated .form-control:valid,
[dir="rtl"] .form-control.is-valid,
[dir="rtl"] input.form-control.parsley-success,
[dir="rtl"] textarea.form-control.parsley-success {
  background-position: center left calc(0.375em + 0.1875rem);
}

[dir="rtl"] [type="email"],
[dir="rtl"] [type="number"],
[dir="rtl"] [type="tel"],
[dir="rtl"] [type="url"] {
  direction: rtl;
}

[dir="rtl"] .slide-right svg,
[dir="rtl"] .slide-left svg {
  transform: rotate(180deg);
}

[dir="rtl"] .select2-dropdown {
  direction: ltr !important;
  text-align: end;
}

[dir="rtl"] .select2-dropdown .select2-results__option {
  direction: rtl !important;
}

[dir="rtl"] .tabs-style6 .nav-tabs .nav-item .nav-link {
  direction: initial;
}

[dir="rtl"] .dropdown-menu {
  direction: rtl !important;
}

[dir="rtl"] .smartphoto {
  direction: ltr !important;
}

[dir="rtl"] #basicSlider .MS-content {
  direction: ltr;
}

[dir="rtl"] .pcr-app {
  direction: ltr;
}

[dir="rtl"] .tooltip.show {
  direction: ltr;
}

[dir="rtl"] .tooltip-static-demo .tooltip {
  direction: ltr;
}

[dir="rtl"] .bs-popover-end>.popover-arrow,
[dir="rtl"] .bs-popover-auto[data-popper-placement^="right"]>.popover-arrow,
[dir="rtl"] .bs-popover-start>.popover-arrow,
[dir="rtl"] .bs-popover-auto[data-popper-placement^="left"]>.popover-arrow {
  direction: ltr;
}

[dir="rtl"] .irs-from,
[dir="rtl"] .irs-to,
[dir="rtl"] .irs-single {
  inset-inline-start: auto;
}

[dir="rtl"] .leaflet-pane,
[dir="rtl"] .leaflet-tile,
[dir="rtl"] .leaflet-marker-icon,
[dir="rtl"] .leaflet-marker-shadow,
[dir="rtl"] .leaflet-tile-container {
  inset-inline-end: 0;
  inset-inline-start: auto;
}

@media (min-width: 1400px) {
  [data-width="boxed"] body {
    background: rgb(var(--background-rgb));
  }

  [data-width="boxed"] body .page {
    width: 1600px;
    margin: 0 auto;
    background: var(--main-gradient-bg);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 100vh;
    box-shadow: var(--shadow-color) 0px 5px 0px 0px,
      var(--shadow-color) 0px 5px 25px 0px;
  }

  [data-width="boxed"] body .sticky-pin .horizontal-main {
    max-width: 1600px;
    margin: 0;
  }

  [data-width="boxed"] body .main-header {
    max-width: 1600px;
    margin: 0 auto;
    inset-inline-start: 0;
    inset-inline-end: 0;
    inset-block-start: 0;
  }

  [data-width="boxed"] body .main-content .container,
  [data-width="boxed"] body .main-header>.container,
  [data-width="boxed"] body .horizontal-mainwrapper.container {
    padding-inline-end: 0.75rem;
    padding-inline-start: 0.75rem;
  }

  [data-width="boxed"] body .horizontalMenu>.horizontalMenu-list>li>a {
    padding: 15px 11px;
  }

  [data-width="boxed"] body .app-sidebar {
    inset-inline-start: auto !important;
  }

  [data-width="boxed"] body .main-content-left-chat .main-nav-line-chat .nav-link+.nav-link {
    margin-inline-start: 0;
  }

  [data-width="boxed"] body .main-nav-line-chat {
    padding: 0 17px;
  }

  [data-width="boxed"] body .app-sidebar .main-sidebar-header {
    inset-inline-start: auto;
    inset-inline-end: auto;
  }

  [data-width="boxed"] .slide .side-menu__item.active::before,
  [data-width="boxed"] .slide .side-menu__item:hover::before,
  [data-width="boxed"] .slide .side-menu__item:focus::before {
    width: 0px;
    inset-inline-start: -5px;
  }

  [data-width="boxed"][data-layout="horizontal"] .hor-header .container,
  [data-width="boxed"][data-layout="horizontal"] .horizontal-main .container,
  [data-width="boxed"][data-layout="horizontal"] .main-content.horizontal-content .container {
    max-width: 95% !important;
  }
}

@media (max-width: 991px) {
  [data-position="scrollable"] .responsive-navbar.navbar {
    position: inherit !important;
  }

  [data-position="scrollable"] .responsive-navbar.navbar .navbar-collapse {
    position: absolute !important;
  }
}

@media (min-width: 992px) {
  [data-position="scrollable"][data-layout="horizontal"] .app-sidebar {
    position: relative;
    max-width: 100% !important;
  }

  [data-position="scrollable"][data-layout="horizontal"] .main-header {
    position: relative;
  }

  [data-position="scrollable"][data-layout="horizontal"] .hor-header {
    position: fixed;
  }
}

[data-position="scrollable"] .main-header.side-header {
  position: absolute !important;
  max-width: 100% !important;
}

[data-position="scrollable"] .app-sidebar,
[data-position="scrollable"] .main-sidebar-header {
  position: absolute;
}

[data-position="scrollable"] .page {
  position: relative;
}

[data-position="scrollable"] .sticky.sticky-pin {
  position: inherit !important;
}

@media (min-width: 992px) {
  [data-position="scrollable"][data-vertical-style="doublemenu"] .app .app-sidebar {
    position: absolute !important;
  }
}

[data-theme-color="default"][data-menuimg="bg-img1"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img1"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img1"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img1"] .app-sidebar {
  /* background: url(../img/menu-images/1.jpg) !important; */
  background-size: cover !important;
  background-repeat: no-repeat;
  border-inline-end: 1px solid rgb(var(--background-rgb));
}

[data-theme-color="default"][data-menuimg="bg-img1"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img1"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img1"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img1"] .app-sidebar:before {
  content: "";
  width: 240px;
  height: 100%;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-start: 0;
  inset-block-end: 0;
  background: rgba(var(--background-rgb), 0.9) !important;
  z-index: -1;
  position: fixed;
  box-shadow: none;
}

@media (max-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"] .app-sidebar:before {
    width: fit-content;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before {
    width: 240px;
  }
}

[data-theme-color="default"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar {
  border-block-end: 1px solid rgb(var(--background-rgb)) !important;
}

[data-theme-color="default"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before {
  position: absolute;
  width: 100%;
}

@media (max-width: 991px) {

  [data-theme-color="default"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-layout="horizontal"] .app-sidebar:before {
    position: fixed;
    width: fit-content;
  }
}

@media (min-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"] body.sidenav-toggled .app-sidebar:before {
    width: 80px;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before {
    width: 110px !important;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="overlay"] .app-sidebar:before {
    width: 80px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img1"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img1"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img1"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img1"][data-vertical-style="doublemenu"] .app-sidebar:before {
    width: 80px !important;
  }
}

[data-theme-color="default"][data-menuimg="bg-img1"] #menubg-img1 img,
[data-theme-color="light"][data-menuimg="bg-img1"] #menubg-img1 img,
[data-theme-color="dark"][data-menuimg="bg-img1"] #menubg-img1 img,
[data-theme-color="glassy"][data-menuimg="bg-img1"] #menubg-img1 img {
  border: 2px solid var(--primary-bg-color);
  border-radius: 7px;
}

[data-theme-color="default"][data-menuimg="bg-img2"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img2"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img2"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img2"] .app-sidebar {
  /* background: url(../img/menu-images/2.jpg) !important; */
  background-size: cover !important;
  background-repeat: no-repeat;
  border-inline-end: 1px solid rgb(var(--background-rgb));
  transition: left 320ms ease, width 320ms ease;
}

[data-theme-color="default"][data-menuimg="bg-img2"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img2"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img2"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img2"] .app-sidebar:before {
  content: "";
  width: 240px;
  height: 100%;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-start: 0;
  inset-block-end: 0;
  background: rgba(var(--background-rgb), 0.9) !important;
  z-index: -1;
  position: fixed;
  box-shadow: none;
  transition: left 320ms ease, width 320ms ease;
}

@media (max-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img2"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"] .app-sidebar:before {
    width: fit-content;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before {
    width: 240px;
  }
}

@media (min-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"] body.sidenav-toggled .app-sidebar:before {
    width: 80px;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before {
    width: 110px !important;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="overlay"] .app-sidebar:before {
    width: 80px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img2"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-vertical-style="doublemenu"] .app-sidebar:before {
    width: 80px !important;
  }
}

[data-theme-color="default"][data-menuimg="bg-img2"] #menubg-img2 img,
[data-theme-color="light"][data-menuimg="bg-img2"] #menubg-img2 img,
[data-theme-color="dark"][data-menuimg="bg-img2"] #menubg-img2 img,
[data-theme-color="glassy"][data-menuimg="bg-img2"] #menubg-img2 img {
  border: 2px solid var(--primary-bg-color);
  border-radius: 7px;
}

[data-theme-color="default"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar {
  border-block-end: 1px solid rgb(var(--background-rgb)) !important;
}

[data-theme-color="default"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before {
  position: absolute;
  width: 100%;
}

@media (max-width: 991px) {

  [data-theme-color="default"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img2"][data-layout="horizontal"] .app-sidebar:before {
    position: fixed;
    width: fit-content;
  }
}

[data-theme-color="default"][data-menuimg="bg-img3"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img3"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img3"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img3"] .app-sidebar {
  /* background: url(../img/menu-images/3.jpg) !important; */
  background-size: cover !important;
  background-repeat: no-repeat;
  border-inline-end: 1px solid rgb(var(--background-rgb));
  transition: left 320ms ease, width 320ms ease;
}

[data-theme-color="default"][data-menuimg="bg-img3"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img3"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img3"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img3"] .app-sidebar:before {
  content: "";
  width: 240px;
  height: 100%;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-start: 0;
  inset-block-end: 0;
  background: rgba(var(--background-rgb), 0.9) !important;
  z-index: -1;
  position: fixed;
  box-shadow: none;
  transition: left 320ms ease, width 320ms ease;
}

@media (max-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img3"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"] .app-sidebar:before {
    width: fit-content;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before {
    width: 240px;
  }
}

@media (min-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"] body.sidenav-toggled .app-sidebar:before {
    width: 80px;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before {
    width: 110px !important;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="overlay"] .app-sidebar:before {
    width: 80px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img3"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-vertical-style="doublemenu"] .app-sidebar:before {
    width: 80px !important;
  }
}

[data-theme-color="default"][data-menuimg="bg-img3"] #menubg-img3 img,
[data-theme-color="light"][data-menuimg="bg-img3"] #menubg-img3 img,
[data-theme-color="dark"][data-menuimg="bg-img3"] #menubg-img3 img,
[data-theme-color="glassy"][data-menuimg="bg-img3"] #menubg-img3 img {
  border: 2px solid var(--primary-bg-color);
  border-radius: 7px;
}

[data-theme-color="default"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar {
  border-block-end: 1px solid rgb(var(--background-rgb)) !important;
}

[data-theme-color="default"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before {
  position: absolute;
  width: 100%;
}

@media (max-width: 991px) {

  [data-theme-color="default"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img3"][data-layout="horizontal"] .app-sidebar:before {
    position: fixed;
    width: fit-content;
  }
}

[data-theme-color="default"][data-menuimg="bg-img4"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img4"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img4"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img4"] .app-sidebar {
  /* background: url(../img/menu-images/4.jpg) !important; */
  background-size: cover !important;
  background-repeat: no-repeat;
  border-inline-end: 1px solid rgb(var(--background-rgb));
  transition: left 320ms ease, width 320ms ease;
}

[data-theme-color="default"][data-menuimg="bg-img4"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img4"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img4"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img4"] .app-sidebar:before {
  content: "";
  width: 240px;
  height: 100%;
  inset-inline-start: auto;
  inset-inline-end: auto;
  inset-block-start: 0;
  inset-block-end: 0;
  background: rgba(var(--background-rgb), 0.9) !important;
  z-index: -1;
  position: fixed;
  box-shadow: none;
  transition: left 320ms ease, width 320ms ease;
}

@media (max-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img4"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"] .app-sidebar:before {
    width: fit-content;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before {
    width: 240px;
  }
}

@media (min-width: 992px) {

  [data-theme-color="default"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"] body.sidenav-toggled .app-sidebar:before {
    width: 80px;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"] body.sidenav-toggled.sidenav-toggled-open .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="icontext"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover1"] .app-sidebar:before {
    width: 240px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .app-sidebar:before {
    width: 110px !important;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="hover1"] body.sidenav-toggled .sidenav-toggled-open.app-sidebar:before {
    width: 110px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="overlay"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="overlay"] .app-sidebar:before {
    width: 80px !important;
    transition: left 320ms ease, width 320ms ease;
  }

  [data-theme-color="default"][data-menuimg="bg-img4"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-vertical-style="doublemenu"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-vertical-style="doublemenu"] .app-sidebar:before {
    width: 80px !important;
  }
}

[data-theme-color="default"][data-menuimg="bg-img4"] #menubg-img4 img,
[data-theme-color="light"][data-menuimg="bg-img4"] #menubg-img4 img,
[data-theme-color="dark"][data-menuimg="bg-img4"] #menubg-img4 img,
[data-theme-color="glassy"][data-menuimg="bg-img4"] #menubg-img4 img {
  border: 2px solid var(--primary-bg-color);
  border-radius: 7px;
}

[data-theme-color="default"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="light"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="dark"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar {
  border-block-end: 1px solid rgb(var(--background-rgb)) !important;
}

[data-theme-color="default"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="light"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="dark"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
[data-theme-color="glassy"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before {
  position: absolute;
  width: 100%;
}

@media (max-width: 991px) {

  [data-theme-color="default"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="light"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="dark"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before,
  [data-theme-color="glassy"][data-menuimg="bg-img4"][data-layout="horizontal"] .app-sidebar:before {
    position: fixed;
    width: fit-content;
  }
}

/***********Leftmenu Image Styles****************/
[data-theme-color="light"] body {
  color: #585656;
}

[data-theme-color="light"] .slide .side-menu__item.active::before,
[data-theme-color="light"] .slide .side-menu__item:hover::before,
[data-theme-color="light"] .slide .side-menu__item:focus::before {
  box-shadow: 0px 0px 10px 2px var(--primary-bg-color),
    0px 0px 10px 2px var(--primary-bg-color);
}

[data-theme-color="light"] .slide-item,
[data-theme-color="light"] .side-menu__label {
  font-weight: 400;
}

[data-theme-color="light"] .dynamic-bg {
  display: none !important;
}

[data-theme-color="light"] .app-sidebar {
  border-inline-end: 1px solid var(--border);
  color: var(--menu-color);
  backdrop-filter: unset;
}

[data-theme-color="light"] .app.sidenav-toggled.sidenav-toggled-open .app-sidebar {
  background: var(--menu-bg);
}

[data-theme-color="light"] .side-menu__icon {
  color: var(--color2);
  fill: var(--color2);
}

[data-theme-color="light"] .card-title {
  color: #5d6162;
}

[data-theme-color="light"] .angle {
  color: var(--color2) !important;
}

[data-theme-color="light"] .side-menu__icon,
[data-theme-color="light"] .header-icon,
[data-theme-color="light"] .header-icon-svgs {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.38));
}

[data-theme-color="light"] .slide.is-expanded a,
[data-theme-color="light"] .slide-item,
[data-theme-color="light"] .app-sidebar .slide-menu .slide-item::before,
[data-theme-color="light"] .side-menu__label,
[data-theme-color="light"] .app-sidebar .side-item.side-item-category {
  color: var(--color2);
}

[data-theme-color="light"] .header-icon,
[data-theme-color="light"] .header-icon-svgs {
  color: var(--color2);
}

[data-theme-color="light"] .main-sidebar-header {
  --background-rgb: 241, 241, 241;
}

[data-theme-color="light"] .fm-sidebar {
  background-color: #fff;
}

[data-theme-color="light"] .card.card-bg-primary {
  background-color: var(--primary-bg-color);
  color: #fff;
  box-shadow: inset 0px -1px 12px 3px rgba(233, 236, 241, 0.7);
}

[data-theme-color="light"] .card.card-bg-primary .card-title,
[data-theme-color="light"] .card.card-bg-primary .card-subtitle {
  color: #fff;
}

[data-theme-color="light"] .card.premium-card {
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.08);
}

[data-theme-color="light"] .card.bg-primary-gradient,
[data-theme-color="light"] .card .bg-secondary-gradient,
[data-theme-color="light"] .card .bg-info-gradient,
[data-theme-color="light"] .card .bg-teal-gradient {
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.08);
}

[data-theme-color="light"] .card.card-bg-secondary {
  background-color: var(--secondary);
  color: #fff;
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.18);
}

[data-theme-color="light"] .card.card-bg-secondary .card-title,
[data-theme-color="light"] .card.card-bg-secondary .card-subtitle {
  color: #fff;
}

[data-theme-color="light"] .card.card-bg-dark {
  background-color: var(--dark);
}

[data-theme-color="light"] .card-header {
  background-color: transparent !important;
}

[data-theme-color="light"] .select2-container--default .select2-selection--single {
  border: 1px solid var(--border) !important;
  background-color: rgb(var(--background-rgb));
}

[data-theme-color="light"] .dataTables_wrapper .dataTables_filter input {
  border: 1px solid var(--border);
}

[data-theme-color="light"] .main-chat-footer {
  background-color: transparent;
}

[data-theme-color="light"] .form-control:focus {
  border-color: var(--primary-bg-color) !important;
}

[data-theme-color="light"] .tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-color: var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="light"] .modal-content {
  background: var(--dark-theme);
}

[data-theme-color="light"] .modal .modal-content {
  border: 1px solid var(--border);
}

[data-theme-color="light"] .sidebar {
  background: var(--dark-theme);
}

[data-theme-color="light"] .app-sidebar.sidebar-scroll .main-sidebar-header {
  background-color: var(--menu-bg);
}

[data-theme-color="light"] .main-sidebar-header {
  border-inline-end: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="light"] .progress-bar-custom .glow::after {
  background: linear-gradient(to right, #fff 0%, transparent 100%);
}

[data-theme-color="light"] .dropdown-menu {
  background: var(--dark-theme);
  border: 1px solid var(--border);
}

[data-theme-color="light"] .dropdown-menu::before {
  background: linear-gradient(45deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.5),
      transparent);
}

[data-theme-color="light"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="light"] .main-header.sticky.sticky-pin {
  background-color: var(--header-bg);
  backdrop-filter: blur(10px);
}

[data-theme-color="light"] .main-header .dropdown-menu {
  border: 1px solid var(--border) !important;
}

[data-theme-color="light"] .table-hover>tbody>tr:hover {
  background-color: var(--bg-hover);
}

[data-theme-color="light"] .apexcharts-gridlines-horizontal line {
  stroke: var(--black-05) !important;
}

[data-theme-color="light"] .apexcharts-tooltip.apexcharts-theme-light {
  background: var(--dark-theme) !important;
}

[data-theme-color="light"] .tabs-style6>.panel-head>.nav-tabs {
  background: var(--black-03);
}

[data-theme-color="light"] .tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  background: var(--dark-theme);
}

@media (min-width: 992px) {
  [data-theme-color="light"] .main-header-left .form-control {
    border: 1px solid var(--border);
    background-color: transparent;
  }

  [data-theme-color="light"] .main-header-left .form-control:focus {
    box-shadow: none;
  }

  [data-theme-color="light"][data-layout="horizontal"] .horizontal-main .slide .slide-menu,
  [data-theme-color="light"][data-layout="horizontal"] .horizontal-main .slide .sub-slide-menu {
    background: var(--dark-theme);
    box-shadow: 0px 0px 5px 0px var(--shadow-color) !important;
  }
}

[data-theme-color="light"] .apexcharts-marker {
  stroke: var(--secondary);
}

[data-theme-color="light"] .select2-dropdown {
  background-color: var(--dark-theme);
}

[data-theme-color="light"] .dataTables_paginate .pagination .page-link {
  border: 1px solid var(--border);
}

[data-theme-color="light"] .back-to-top:hover {
  background-color: #fff;
}

[data-theme-color="light"] .tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="light"] .tabs-style5>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="light"] .tabs-style3>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="light"] .tabs-style4>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: #fff;
}

[data-theme-color="light"] .btn-check:active+.btn-outline-primary,
[data-theme-color="light"] .btn-check:checked+.btn-outline-primary,
[data-theme-color="light"] .btn-outline-primary.active,
[data-theme-color="light"] .btn-outline-primary.dropdown-toggle.show,
[data-theme-color="light"] .btn-outline-primary:active {
  color: #fff !important;
}

[data-theme-color="light"] .btn-check:active+.btn-outline-secondary,
[data-theme-color="light"] .btn-check:checked+.btn-outline-secondary,
[data-theme-color="light"] .btn-outline-secondary.active,
[data-theme-color="light"] .btn-outline-secondary.dropdown-toggle.show,
[data-theme-color="light"] .btn-outline-secondary:active {
  color: #fff !important;
}

[data-theme-color="light"] .btn-outline-primary:hover {
  color: #fff !important;
}

[data-theme-color="light"] .btn-outline-secondary:hover {
  color: #fff !important;
}

[data-theme-color="light"] .custom-switch-indicator:before {
  background: var(--gray-500);
}

[data-theme-color="light"] .custom-switch-input:checked~.custom-switch-indicator::before {
  background: #fff;
}

[data-theme-color="light"] .btn-primary {
  color: #fff;
}

[data-theme-color="light"] .btn-primary:not(:disabled):not(.disabled):active,
[data-theme-color="light"] .btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
}

[data-theme-color="light"] .btn-secondary {
  color: #fff !important;
}

[data-theme-color="light"] .btn-secondary:not(:disabled):not(.disabled):active,
[data-theme-color="light"] .btn-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
}

[data-theme-color="light"] .btn-outline-secondary:not(:disabled):not(.disabled):active,
[data-theme-color="light"] .btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fff !important;
}

[data-theme-color="light"] .bg-primary {
  background-color: var(--primary-bg-color);
  color: #fff !important;
}

[data-theme-color="light"] .text-white {
  color: #fff !important;
}

[data-theme-color="light"] .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--primary-bg-color) !important;
  color: #fff !important;
}

[data-theme-color="light"] .apexcharts-legend-text {
  color: var(--color2) !important;
}

[data-theme-color="light"] .ckbox span::before {
  background-color: #fff;
}

[data-theme-color="light"] .btn-primary-light:hover {
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color);
}

[data-theme-color="light"] .apexcharts-series.apexcharts-pie-series path {
  stroke: #fff !important;
}

[data-theme-color="light"] .fc-h-event .fc-event-main {
  color: #fff;
}

[data-theme-color="light"] .fc-button-primary {
  color: #fff;
}

[data-theme-color="light"] .fc .fc-button-primary:disabled {
  color: #fff;
}

[data-theme-color="light"] .fc .fc-button-primary:hover {
  color: #fff;
}

[data-theme-color="light"] .fc .fc-button-primary:not(:disabled):active,
[data-theme-color="light"] .fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
}

[data-theme-color="light"] ::placeholder {
  color: var(--muted);
}

[data-theme-color="light"] .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #fff;
}

[data-theme-color="light"] .bs-tooltip-top .tooltip-arrow::before,
[data-theme-color="light"] .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: #000;
}

[data-theme-color="light"] .tooltip-inner {
  background-color: #000;
}

[data-theme-color="light"] #ui_notifIt.default {
  color: #fff;
}

[data-theme-color="light"] .notifit_confirm_accept {
  color: #fff;
}

[data-theme-color="light"] .progress {
  background-color: var(--black-03);
}

[data-theme-color="light"] .progress-style.progress .progress-bar::after {
  background-color: #fff;
}

[data-theme-color="light"] .pagination .page-item.active .page-link {
  color: #fff;
}

[data-theme-color="light"] .pagination .page-item:not(.active) .page-link:hover {
  color: #fff;
}

[data-theme-color="light"] .main-chat-list .chat-item:hover,
[data-theme-color="light"] .main-chat-list .chat-item:focus,
[data-theme-color="light"] .main-chat-list .chat-item.selected {
  color: #fff;
}

[data-theme-color="light"] .chat .msg_container_send .msg_cotainer_send-main {
  color: #fff;
}

[data-theme-color="light"] .tags-group .tag-item .selectgroup-input:checked+.tag {
  color: #fff;
}

[data-theme-color="light"] .bg-secondary {
  background-color: var(--secondary) !important;
  color: #fff !important;
}

[data-theme-color="light"] .badge-secondary-transparent {
  background-color: var(--secondary-02);
}

[data-theme-color="light"] .btn-secondary-gradient {
  color: #fff !important;
}

[data-theme-color="light"] .btn-secondary-gradient:hover {
  color: #fff !important;
}

[data-theme-color="light"] .btn-outline-secondary:hover .bg-secondary {
  color: var(--secondary) !important;
}

[data-theme-color="light"] .btn-outline-primary:hover .bg-primary {
  color: var(--primary-bg-color) !important;
}

[data-theme-color="light"] .list-group-item.active {
  color: #fff !important;
}

[data-theme-color="light"] .list-group-item-action:hover,
[data-theme-color="light"] .list-group-item-action:focus {
  color: #fff;
}

[data-theme-color="light"] .nav-secondary .nav-link {
  color: #fff !important;
  border-color: #fff !important;
  opacity: 0.5;
}

[data-theme-color="light"] .nav-secondary .nav-link .active {
  opacity: 1;
}

[data-theme-color="light"] .light-nav-tab {
  background-color: var(--gray-400);
}

[data-theme-color="light"] .border-light {
  border-color: var(--gray-400) !important;
}

[data-theme-color="light"] .border-dark {
  border-color: var(--color2) !important;
}

[data-theme-color="light"] .primary-nav-tab .nav-tabs .nav-link,
[data-theme-color="light"] .secondary-nav-tab .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-color="light"] .primary-nav-tab .nav-tabs .nav-link.active,
[data-theme-color="light"] .secondary-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

[data-theme-color="light"] .nav-tabs .nav-link.active {
  background-color: #fff;
}

[data-theme-color="light"] .light-nav-tab .nav-tabs .nav-link {
  color: var(--color2);
}

[data-theme-color="light"] .pagination.pagination-light .page-item:not(.active) .page-link:hover,
[data-theme-color="light"] .pagination.pagination-light .page-item.active .page-link {
  color: var(--gray-900);
}

[data-theme-color="light"] .popover {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .popover-secondary .popover-body,
[data-theme-color="light"] .popover-secondary .popover-header {
  color: #fff;
}

[data-theme-color="light"] .tooltip {
  --background-rgb: 0, 0, 0;
}

[data-theme-color="light"] .tooltip-inner {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .tooltip-secondary .tooltip-inner {
  color: #fff !important;
}

[data-theme-color="light"] .toast {
  background: #fff;
}

[data-theme-color="light"] .tag.tag-primary {
  color: #fff !important;
}

[data-theme-color="light"] .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
}

[data-theme-color="light"] .accordion.custom-accordion>.accordion-item>.accordion-header>a:not(.collapsed),
[data-theme-color="light"] .accordion.accordion-style1>.accordion-item>.accordion-header>a:not(.collapsed) {
  color: #fff;
}

[data-theme-color="light"] .tag-attachment {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .tag-attachment.attachment-primary,
[data-theme-color="light"] .tag-attachment.attachment-secondary {
  color: #fff !important;
}

[data-theme-color="light"] .notification {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .invoice-highlight {
  color: #fff;
}

[data-theme-color="light"] .bg-white {
  background-color: #fff !important;
}

[data-theme-color="light"] .premium-card.text-white {
  color: var(--primary-bg-color) !important;
}

[data-theme-color="light"] .pagination {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .form-control.flatpickr-input,
[data-theme-color="light"] .flatpickr-calendar,
[data-theme-color="light"] .SumoSelect,
[data-theme-color="light"] .intl-tel-input,
[data-theme-color="light"] .form-select,
[data-theme-color="light"] .form-control,
[data-theme-color="light"] .select2 {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .form-control {
  background-color: rgb(var(--background-rgb));
}

[data-theme-color="light"] .smartphoto {
  --white-8: rgba(0, 0, 0, 0.8);
}

[data-theme-color="light"] .card-sigin {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .rdiobox {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .note-editor {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .ff_fileupload_wrap {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .bootstrap-tagsinput {
  --background-rgb: transparent;
}

[data-theme-color="light"] .nice-select {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .wizard {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .main-toggle {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .dropify-wrapper {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .richText,
[data-theme-color="light"] .ql-wrapper-demo,
[data-theme-color="light"] .ql-scrolling-demo {
  --background-rgb: 255, 255, 255;
}

[data-theme-color="light"] .badge-primary,
[data-theme-color="light"] .badge-secondary {
  color: #fff !important;
}

[data-theme-color="light"] .wizard>.actions a {
  color: #fff;
}

[data-theme-color="light"] .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
[data-theme-color="light"] .custom-radio .custom-control-input:checked~.custom-control-label::after {
  filter: none;
}

[data-theme-color="light"] .bg-white-1 {
  background: var(--white-1);
}

[data-theme-color="light"] .bg-white-2 {
  background: var(--white-2);
}

[data-theme-color="light"] .bg-white-3 {
  background: var(--white-3);
}

[data-theme-color="light"] .bg-white-4 {
  background: var(--white-4);
}

[data-theme-color="light"] .bg-white-5 {
  background: var(--white-5);
}

[data-theme-color="light"] .bg-white-6 {
  background: var(--white-6);
}

[data-theme-color="light"] .bg-white-7 {
  background: var(--white-7);
}

[data-theme-color="light"] .bg-white-8 {
  background: var(--white-8);
}

[data-theme-color="light"] .bg-white-9 {
  background: var(--white-9);
}

[data-theme-color="light"] .bg-black-1 {
  background: var(--black-1);
}

[data-theme-color="light"] .bg-black-2 {
  background: var(--black-2);
}

[data-theme-color="light"] .bg-black-3 {
  background: var(--black-3);
}

[data-theme-color="light"] .bg-black-4 {
  background: var(--black-4);
}

[data-theme-color="light"] .bg-black-5 {
  background: var(--black-5);
}

[data-theme-color="light"] .bg-black-6 {
  background: var(--black-6);
}

[data-theme-color="light"] .bg-black-7 {
  background: var(--black-7);
}

[data-theme-color="light"] .bg-black-8 {
  background: var(--black-8);
}

[data-theme-color="light"] .bg-black-9 {
  background: var(--black-9);
}

[data-theme-color="light"] .main-profile-menu .text-black {
  color: #fff !important;
}

[data-theme-color="light"] .demo_changer .form_holder {
  background: #fff !important;
  border-inline-start: 1px solid var(--border);
}

[data-theme-color="light"][data-layout="horizontal"] .slide .side-menu__item.active::before,
[data-theme-color="light"][data-layout="horizontal"] .slide .side-menu__item:hover::before,
[data-theme-color="light"][data-layout="horizontal"] .slide .side-menu__item:focus::before {
  content: unset;
}

@media (min-width: 992px) {
  [data-theme-color="light"] .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="light"] .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: block;
  }

  [data-theme-color="light"] .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="light"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: block;
  }

  [data-theme-color="light"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="light"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="light"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="light"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="light"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="light"][data-layout="horizontal"][data-logo="centerlogo"] .logo-horizontal .mobile-logo-1 {
    margin: 0 auto;
  }

  [data-theme-color="light"][data-vertical-style="hover"] .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    background: #fff;
  }

  [data-theme-color="light"][data-vertical-style="hover1"] .app.sidenav-toggled-open .slide-menu {
    background: #fff;
  }
}

@media (max-width: 991.98px) {

  [data-theme-color="light"] .app-sidebar .main-sidebar-header .desktop-dark,
  [data-theme-color="light"] .app-sidebar .main-sidebar-header .mobile-dark,
  [data-theme-color="light"] .app-sidebar .main-sidebar-header .desktop-dark-1,
  [data-theme-color="light"] .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="light"] .main-header .responsive-logo .header-logo .mobile-logo {
    display: none;
  }

  [data-theme-color="light"] .main-header .responsive-logo .header-logo .mobile-logo-1 {
    display: block;
  }
}

[data-theme-color="light"] .logo-horizontal .mobile-logo {
  display: none;
}

[data-theme-color="light"] .logo-horizontal .mobile-logo-1 {
  display: block;
}

@media (max-width: 991px) {
  [data-theme-color="light"] .responsive-navbar.navbar .navbar-collapse {
    background: #fff;
  }
}

[data-theme-color="light"] .navbar-toggler-icon {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.38));
}

@media (min-width: 1400px) {
  [data-theme-color="light"][data-width="boxed"] body .page {
    background: var(--background);
  }
}

[data-theme-color="light"] .main-card-signin .logo-dark {
  display: none;
}

[data-theme-color="light"] .main-card-signin .logo-light-theme {
  display: block;
}

[data-theme-color="light"] .text-light {
  color: var(--dark) !important;
}

[data-theme-color="light"] .app-sidebar::before,
[data-theme-color="light"] .card::before,
[data-theme-color="light"] .main-header>.container-fluid::before {
  background: linear-gradient(45deg,
      transparent,
      rgba(255, 255, 255, 0.08),
      rgba(192, 192, 192, 0.6),
      rgba(192, 192, 192, 0.6),
      rgba(255, 255, 255, 0.08),
      transparent);
  box-shadow: 0 8px 17px 0px #58585894;
}

[data-theme-color="light"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="light"] body {
  background: var(--main-gradient-bg);
  background-attachment: fixed;
}

[data-theme-color="light"] .page {
  background-color: #efefef1a;
}

[data-theme-color="light"] .card {
  background-color: transparent;
  box-shadow: inset 0px -1px 12px 3px rgba(218, 221, 226, 0.4);
  border: 1px solid var(--border);
}

[data-theme-color="light"] .card:before {
  top: -1px;
}

[data-theme-color="light"] .slide-item,
[data-theme-color="light"] .side-menu__label {
  font-weight: 400;
}

[data-theme-color="light"] .table-hover>tbody>tr:hover {
  background: var(--bg-hover) !important;
}

@media (min-width: 992px) {
  [data-theme-color="light"][data-layout="horizontal"] .app-sidebar {
    background: var(--header-bg);
  }
}

[data-theme-color="dark"] .side-menu .slide .side-menu__item.active,
[data-theme-color="dark"] .side-menu .slide .side-menu__item:hover,
[data-theme-color="dark"] .side-menu .slide .side-menu__item:focus {
  color: #fff !important;
}

[data-theme-color="dark"] .side-menu .slide .side-menu__item.active .side-menu__label {
  color: #fff !important;
}

[data-theme-color="dark"] .side-menu .slide .side-menu__item.active .side-menu__icon,
[data-theme-color="dark"] .side-menu .slide .side-menu__item:hover .side-menu__icon {
  color: #fff !important;
}

[data-theme-color="dark"] .slide .side-menu__item.active::before,
[data-theme-color="dark"] .slide .side-menu__item:hover::before,
[data-theme-color="dark"] .slide .side-menu__item:focus::before {
  color: #fff !important;
}

[data-theme-color="dark"] .main-sidemenu .slide .side-menu__item.active i.angle,
[data-theme-color="dark"] .slide-item.active,
[data-theme-color="dark"] .slide-item:hover,
[data-theme-color="dark"] .slide-item:focus,
[data-theme-color="dark"] .slide-item.active::before,
[data-theme-color="dark"] .slide.is-expanded .slide-menu a:hover:before,
[data-theme-color="dark"] .side-menu__item:hover .side-menu__label,
[data-theme-color="dark"] .side-menu__item:hover .angle,
[data-theme-color="dark"] .app-sidebar .side-item.side-item-category,
[data-theme-color="dark"] .sub-side-menu__item:hover,
[data-theme-color="dark"] .sub-slide-menu1 .sub-slide-item2:hover,
[data-theme-color="dark"] .sub-side-menu__item:active,
[data-theme-color="dark"] .sub-slide-menu1 .sub-slide-item2:active,
[data-theme-color="dark"] .sub-slide-menu .sub-side-menu__item.active {
  color: #fff !important;
}

[data-theme-color="dark"] .side-menu .side-menu__icon {
  color: #fff;
  fill: #fff;
}

[data-theme-color="dark"] .app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar {
  background: var(--main-gradient-bg);
}

[data-theme-color="dark"] .slide .side-menu__item.active::before,
[data-theme-color="dark"] .slide .side-menu__item:hover::before,
[data-theme-color="dark"] .slide .side-menu__item:focus::before {
  box-shadow: 0px 0px 10px 2px #fff, 0px 0px 10px 2px #fff;
}

[data-theme-color="dark"] .light-layout {
  display: block;
}

[data-theme-color="dark"] .dark-layout {
  display: none;
}

[data-theme-color="dark"] .header-icons .new.nav-link:hover .header-icon-svgs,
[data-theme-color="dark"] .header-icons .new.nav-link.show .header-icon-svgs {
  color: #fff;
  fill: #fff;
}

[data-theme-color="dark"] .header-icon-svgs {
  color: #fff;
  fill: #fff;
  filter: drop-shadow(0 2px 5px #fff);
}

[data-theme-color="dark"] .card.card-bg-primary {
  background-color: var(--primary-bg-color);
  color: #000;
}

[data-theme-color="dark"] .card.card-bg-primary .card-title,
[data-theme-color="dark"] .card.card-bg-primary .card-subtitle {
  color: #000;
}

[data-theme-color="dark"] .card.card-bg-secondary {
  background-color: var(--secondary);
  color: #000;
}

[data-theme-color="dark"] .card.card-bg-secondary .card-title,
[data-theme-color="dark"] .card.card-bg-secondary .card-subtitle {
  color: #000;
}

[data-theme-color="dark"] .card.card-bg-dark {
  background-color: var(--dark);
}

[data-theme-color="dark"][data-layout="horizontal"] .app-sidebar:before {
  background: rgb(var(--background-rgb));
}

[data-theme-color="dark"] .select2-container--default .select2-selection--single {
  border: 1px solid var(--border) !important;
}

[data-theme-color="dark"] .dataTables_wrapper .dataTables_filter input {
  border: 1px solid var(--border);
}

[data-theme-color="dark"] .form-control:focus {
  border-color: var(--primary-bg-color) !important;
}

[data-theme-color="dark"] .tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-color: var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="dark"] .modal-content {
  background: var(--dark-theme);
}

[data-theme-color="dark"] .modal .modal-content {
  border: 1px solid var(--border);
}

[data-theme-color="dark"] .sidebar {
  background: var(--dark-theme);
}

[data-theme-color="dark"] .main-sidebar-header {
  border-inline-end: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="dark"] .dropdown-menu {
  border: 1px solid var(--border);
}

[data-theme-color="dark"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="dark"] .main-header .dropdown-menu {
  border: 1px solid var(--border) !important;
}

[data-theme-color="dark"] .table-hover>tbody>tr:hover {
  background-color: var(--bg-hover);
}

[data-theme-color="dark"] .apexcharts-gridlines-horizontal line {
  stroke: var(--black-05) !important;
}

[data-theme-color="dark"] .tabs-style6>.panel-head>.nav-tabs {
  background: var(--black-03);
}

[data-theme-color="dark"] .tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  background: var(--dark-theme);
}

@media (min-width: 992px) {
  [data-theme-color="dark"] .main-header-left .form-control {
    border: 1px solid var(--border);
  }

  [data-theme-color="dark"] .main-header-left .form-control:focus {
    box-shadow: none;
  }
}

[data-theme-color="dark"] .select2-dropdown {
  background-color: var(--dark-theme);
}

[data-theme-color="dark"] .dataTables_paginate .pagination .page-link {
  border: 1px solid var(--border);
}

[data-theme-color="dark"] .bg-black-9 {
  background: var(--white-9);
}

[data-theme-color="dark"] .demo_changer .form_holder {
  background: var(--dark-theme) !important;
  border-inline-start: 1px solid var(--border);
}

@media (max-width: 991px) {
  [data-theme-color="dark"] .responsive-navbar.navbar .navbar-collapse {
    background: var(--dark-theme);
  }
}

[data-theme-color="dark"] .app-sidebar::before,
[data-theme-color="dark"] .card::before,
[data-theme-color="dark"] .main-header>.container-fluid::before {
  background: linear-gradient(45deg,
      transparent,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.4),
      rgba(255, 255, 255, 0.1),
      transparent);
  box-shadow: 0 8px 17px 0px #9b9b9b;
}

[data-theme-color="dark"] .dynamic-bg {
  display: none !important;
}

[data-theme-color="dark"] .app-sidebar,
[data-theme-color="dark"] .main-header {
  --primary05: rgba(255, 255, 255, 0.5);
  --background-rgb: 30, 35, 45;
}

[data-theme-color="dark"] .card-title {
  color: #fff;
}

[data-theme-color="dark"] .main-sidebar-header {
  border-inline-end: 1px solid var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="dark"] .app-sidebar {
  border-inline-end: 1px solid var(--border);
}

[data-theme-color="dark"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="dark"] .main-header .dropdown-menu {
  border: 1px solid var(--border) !important;
}

[data-theme-color="dark"] .demo_changer .form_holder {
  border-inline-start: 1px solid var(--border);
}

[data-theme-color="dark"] .page {
  background-color: transparent;
}

[data-theme-color="glassy"] body {
  background-size: cover !important;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed !important;
  /* background: url(../img/media/11.jpg); */
  color: #585656;
}

[data-theme-color="glassy"] body::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  inset-inline-start: 0;
  inset-inline-end: 0;
  inset-block-start: 0;
  inset-block-end: 0;
  background-color: rgba(255, 255, 255, 0.6);
}

[data-theme-color="glassy"] .main-content,
[data-theme-color="glassy"] .main-footer {
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .layout-setting {
  display: none !important;
}

[data-theme-color="glassy"] .slide .side-menu__item.active::before,
[data-theme-color="glassy"] .slide .side-menu__item:hover::before,
[data-theme-color="glassy"] .slide .side-menu__item:focus::before {
  box-shadow: 0px 0px 10px 2px var(--primary-bg-color),
    0px 0px 10px 2px var(--primary-bg-color);
}

[data-theme-color="glassy"] .slide-item,
[data-theme-color="glassy"] .side-menu__label {
  font-weight: 400;
}

[data-theme-color="glassy"] .app-sidebar {
  border-inline-end: 1px solid var(--border);
  color: var(--menu-color);
}

[data-theme-color="glassy"] .app.sidenav-toggled.sidenav-toggled-open .app-sidebar {
  background: var(--menu-bg);
}

[data-theme-color="glassy"] .side-menu__icon {
  color: var(--color2);
  fill: var(--color2);
}

[data-theme-color="glassy"] .card-title {
  color: #5d6162;
}

[data-theme-color="glassy"] .angle {
  color: var(--color2) !important;
}

[data-theme-color="glassy"] .side-menu__icon,
[data-theme-color="glassy"] .header-icon,
[data-theme-color="glassy"] .header-icon-svgs {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.38));
}

[data-theme-color="glassy"] .dynamic-bg {
  display: none !important;
}

[data-theme-color="glassy"] .slide.is-expanded a,
[data-theme-color="glassy"] .slide-item,
[data-theme-color="glassy"] .app-sidebar .slide-menu .slide-item::before,
[data-theme-color="glassy"] .side-menu__label,
[data-theme-color="glassy"] .app-sidebar .side-item.side-item-category {
  color: var(--color2);
}

[data-theme-color="glassy"] .header-icon,
[data-theme-color="glassy"] .header-icon-svgs {
  color: var(--color2);
}

[data-theme-color="glassy"] .main-sidebar-header {
  --background-rgb: 241, 241, 241;
}

[data-theme-color="glassy"] .fm-sidebar {
  background-color: #fff;
}

[data-theme-color="glassy"] .card.card-bg-primary {
  background-color: var(--primary-bg-color);
  color: #fff;
  box-shadow: inset 0px -1px 12px 3px rgba(233, 236, 241, 0.7);
}

[data-theme-color="glassy"] .card.card-bg-primary .card-title,
[data-theme-color="glassy"] .card.card-bg-primary .card-subtitle {
  color: #fff;
}

[data-theme-color="glassy"] .card.premium-card {
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.08);
}

[data-theme-color="glassy"] .card.bg-primary-gradient,
[data-theme-color="glassy"] .card .bg-secondary-gradient,
[data-theme-color="glassy"] .card .bg-info-gradient,
[data-theme-color="glassy"] .card .bg-teal-gradient {
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.08);
}

[data-theme-color="glassy"] .card.card-bg-secondary {
  background-color: var(--secondary);
  color: #fff;
  box-shadow: inset 0px -1px 12px 3px rgba(0, 0, 0, 0.18);
}

[data-theme-color="glassy"] .card.card-bg-secondary .card-title,
[data-theme-color="glassy"] .card.card-bg-secondary .card-subtitle {
  color: #fff;
}

[data-theme-color="glassy"] .card.card-bg-dark {
  background-color: var(--dark);
}

[data-theme-color="glassy"] .card-header {
  background-color: transparent !important;
}

[data-theme-color="glassy"] .select2-container--default .select2-selection--single {
  border: 1px solid var(--border) !important;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .dataTables_wrapper .dataTables_filter input {
  border: 1px solid var(--border);
}

[data-theme-color="glassy"] .main-chat-footer {
  background-color: transparent;
}

[data-theme-color="glassy"] .form-control:focus {
  border-color: var(--primary-bg-color) !important;
}

[data-theme-color="glassy"] .tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link {
  border-color: var(--border);
  border-block-end: 1px solid var(--border);
}

[data-theme-color="glassy"] .modal-content {
  background: var(--dark-theme);
}

[data-theme-color="glassy"] .modal .modal-content {
  border: 1px solid var(--border);
}

[data-theme-color="glassy"] .sidebar {
  background: var(--dark-theme);
}

[data-theme-color="glassy"] .app-sidebar.sidebar-scroll .main-sidebar-header {
  background-color: var(--menu-bg);
}

[data-theme-color="glassy"] .main-sidebar-header {
  border-block-end: 1px solid var(--border);
}

[data-theme-color="glassy"] .progress-bar-custom .glow::after {
  background: linear-gradient(to right, transparent 0%, transparent 100%);
}

[data-theme-color="glassy"] .dropdown-menu {
  background: var(--dark-theme);
  border: 1px solid var(--border);
}

[data-theme-color="glassy"] .dropdown-menu::before {
  background: linear-gradient(45deg,
      transparent,
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.7),
      rgba(255, 255, 255, 0.5),
      transparent);
}

[data-theme-color="glassy"] .header-icons .new.nav-link:hover,
[data-theme-color="glassy"] .header-icons .new.nav-link.show {
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="glassy"] .main-header.sticky.sticky-pin {
  background-color: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .main-header .dropdown-menu {
  border: 1px solid var(--border) !important;
}

[data-theme-color="glassy"] .table-hover>tbody>tr:hover {
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .apexcharts-gridlines-horizontal line {
  stroke: var(--black-05) !important;
}

[data-theme-color="glassy"] .apexcharts-tooltip.apexcharts-theme-light {
  background: var(--dark-theme) !important;
}

[data-theme-color="glassy"] .tabs-style6>.panel-head>.nav-tabs {
  background: var(--black-03);
}

[data-theme-color="glassy"] .tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .tabs-style6>.panel-head>.nav-tabs>.nav-item>.nav-link:not(.active):hover {
  background: transparent;
  backdrop-filter: blur(30px);
}

@media (min-width: 992px) {
  [data-theme-color="glassy"] .main-header-left .form-control {
    border: 1px solid var(--border);
    background-color: transparent;
  }

  [data-theme-color="glassy"] .main-header-left .form-control:focus {
    box-shadow: none;
  }

  [data-theme-color="glassy"][data-layout="horizontal"] .horizontal-main .slide .slide-menu,
  [data-theme-color="glassy"][data-layout="horizontal"] .horizontal-main .slide .sub-slide-menu {
    background: var(--dark-theme);
    box-shadow: 0px 0px 5px 0px var(--shadow-color) !important;
  }
}

[data-theme-color="glassy"] .apexcharts-marker {
  stroke: var(--secondary);
}

[data-theme-color="glassy"] .select2-dropdown {
  background-color: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .dataTables_paginate .pagination .page-link {
  border: 1px solid var(--border);
  background-color: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .back-to-top:hover {
  background-color: #fff;
}

[data-theme-color="glassy"] .tabs-style2>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="glassy"] .tabs-style5>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="glassy"] .tabs-style3>.panel-head>.nav-tabs>.nav-item>.nav-link.active,
[data-theme-color="glassy"] .tabs-style4>.panel-head>.nav-tabs>.nav-item>.nav-link.active {
  color: #fff;
}

[data-theme-color="glassy"] .btn-check:active+.btn-outline-primary,
[data-theme-color="glassy"] .btn-check:checked+.btn-outline-primary,
[data-theme-color="glassy"] .btn-outline-primary.active,
[data-theme-color="glassy"] .btn-outline-primary.dropdown-toggle.show,
[data-theme-color="glassy"] .btn-outline-primary:active {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-check:active+.btn-outline-secondary,
[data-theme-color="glassy"] .btn-check:checked+.btn-outline-secondary,
[data-theme-color="glassy"] .btn-outline-secondary.active,
[data-theme-color="glassy"] .btn-outline-secondary.dropdown-toggle.show,
[data-theme-color="glassy"] .btn-outline-secondary:active {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-outline-primary:hover {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-outline-secondary:hover {
  color: #fff !important;
}

[data-theme-color="glassy"] .custom-switch-indicator:before {
  background: var(--gray-500);
}

[data-theme-color="glassy"] .custom-switch-input:checked~.custom-switch-indicator::before {
  background: #fff;
}

[data-theme-color="glassy"] .btn-primary {
  color: #fff;
}

[data-theme-color="glassy"] .btn-primary:not(:disabled):not(.disabled):active,
[data-theme-color="glassy"] .btn-primary:not(:disabled):not(.disabled).active {
  color: #fff;
}

[data-theme-color="glassy"] .btn-secondary {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-secondary:not(:disabled):not(.disabled):active,
[data-theme-color="glassy"] .btn-secondary:not(:disabled):not(.disabled).active {
  color: #fff;
}

[data-theme-color="glassy"] .btn-outline-secondary:not(:disabled):not(.disabled):active,
[data-theme-color="glassy"] .btn-outline-secondary:not(:disabled):not(.disabled).active {
  color: #fff !important;
}

[data-theme-color="glassy"] .bg-primary {
  background-color: var(--primary-bg-color);
  color: #fff !important;
}

[data-theme-color="glassy"] .text-white {
  color: #fff !important;
}

[data-theme-color="glassy"] .apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: var(--primary-bg-color) !important;
  color: #fff !important;
}

[data-theme-color="glassy"] .apexcharts-legend-text {
  color: var(--color2) !important;
}

[data-theme-color="glassy"] .ckbox span::before {
  background-color: rgba(255, 255, 255, 0.3);
}

[data-theme-color="glassy"] .btn-primary-light:hover {
  background-color: var(--primary-bg-color) !important;
  border-color: var(--primary-bg-color);
}

[data-theme-color="glassy"] .apexcharts-series.apexcharts-pie-series path {
  stroke: #fff !important;
}

[data-theme-color="glassy"] .fc-h-event .fc-event-main {
  color: #fff;
}

[data-theme-color="glassy"] .fc-button-primary {
  color: #fff;
}

[data-theme-color="glassy"] .fc .fc-button-primary:disabled {
  color: #fff;
}

[data-theme-color="glassy"] .fc .fc-button-primary:hover {
  color: #fff;
}

[data-theme-color="glassy"] .fc .fc-button-primary:not(:disabled):active,
[data-theme-color="glassy"] .fc .fc-button-primary:not(:disabled).fc-button-active {
  color: #fff;
}

[data-theme-color="glassy"] ::placeholder {
  color: var(--muted);
}

[data-theme-color="glassy"] .select2-container--default .select2-search--dropdown .select2-search__field {
  background-color: #fff;
}

[data-theme-color="glassy"] .bs-tooltip-top .tooltip-arrow::before,
[data-theme-color="glassy"] .bs-tooltip-auto[data-popper-placement^="top"] .tooltip-arrow::before {
  border-block-start-color: #000;
}

[data-theme-color="glassy"] .tooltip-inner {
  background-color: #000;
}

[data-theme-color="glassy"] #ui_notifIt.default {
  color: #fff;
}

[data-theme-color="glassy"] .notifit_confirm_accept {
  color: #fff;
}

[data-theme-color="glassy"] .progress {
  background-color: var(--black-03);
}

[data-theme-color="glassy"] .progress-style.progress .progress-bar::after {
  background-color: #fff;
}

[data-theme-color="glassy"] .pagination .page-item.active .page-link {
  color: #fff;
}

[data-theme-color="glassy"] .pagination .page-item:not(.active) .page-link:hover {
  color: #fff;
}

[data-theme-color="glassy"] .main-chat-list .chat-item:hover,
[data-theme-color="glassy"] .main-chat-list .chat-item:focus,
[data-theme-color="glassy"] .main-chat-list .chat-item.selected {
  color: #fff;
}

[data-theme-color="glassy"] .chat .msg_container_send .msg_cotainer_send-main {
  color: #fff;
}

[data-theme-color="glassy"] .tags-group .tag-item .selectgroup-input:checked+.tag {
  color: #fff;
}

[data-theme-color="glassy"] .bg-secondary {
  background-color: var(--secondary) !important;
  color: #fff !important;
}

[data-theme-color="glassy"] .badge-secondary-transparent {
  background-color: var(--secondary-02);
}

[data-theme-color="glassy"] .btn-secondary-gradient {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-secondary-gradient:hover {
  color: #fff !important;
}

[data-theme-color="glassy"] .btn-outline-secondary:hover .bg-secondary {
  color: var(--secondary) !important;
}

[data-theme-color="glassy"] .btn-outline-primary:hover .bg-primary {
  color: var(--primary-bg-color) !important;
}

[data-theme-color="glassy"] .list-group-item.active {
  color: #fff !important;
}

[data-theme-color="glassy"] .list-group-item-action:hover,
[data-theme-color="glassy"] .list-group-item-action:focus {
  color: #fff;
}

[data-theme-color="glassy"] .nav-secondary .nav-link {
  color: #fff !important;
  border-color: #fff !important;
  opacity: 0.5;
}

[data-theme-color="glassy"] .nav-secondary .nav-link .active {
  opacity: 1;
}

[data-theme-color="glassy"] .light-nav-tab {
  background-color: var(--gray-400);
}

[data-theme-color="glassy"] .border-light {
  border-color: var(--gray-400) !important;
}

[data-theme-color="glassy"] .border-dark {
  border-color: var(--color2) !important;
}

[data-theme-color="glassy"] .primary-nav-tab .nav-tabs .nav-link,
[data-theme-color="glassy"] .secondary-nav-tab .nav-tabs .nav-link {
  color: #fff;
}

[data-theme-color="glassy"] .primary-nav-tab .nav-tabs .nav-link.active,
[data-theme-color="glassy"] .secondary-nav-tab .nav-tabs .nav-link.active {
  color: var(--gray-900);
}

[data-theme-color="glassy"] .nav-tabs .nav-link.active {
  background-color: #fff;
}

[data-theme-color="glassy"] .light-nav-tab .nav-tabs .nav-link {
  color: var(--color2);
}

[data-theme-color="glassy"] .pagination.pagination-light .page-item:not(.active) .page-link:hover,
[data-theme-color="glassy"] .pagination.pagination-light .page-item.active .page-link {
  color: var(--gray-900);
}

[data-theme-color="glassy"] .popover {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .popover-secondary .popover-body,
[data-theme-color="glassy"] .popover-secondary .popover-header {
  color: #fff;
}

[data-theme-color="glassy"] .tooltip {
  --background-rgb: 0, 0, 0;
}

[data-theme-color="glassy"] .tooltip-inner {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .tooltip-secondary .tooltip-inner {
  color: #fff !important;
}

[data-theme-color="glassy"] .toast {
  background: #fff;
}

[data-theme-color="glassy"] .tag.tag-primary {
  color: #fff !important;
}

[data-theme-color="glassy"] .select2-container--default .select2-selection--multiple .select2-selection__choice {
  color: #fff;
}

[data-theme-color="glassy"] .accordion.custom-accordion>.accordion-item>.accordion-header>a:not(.collapsed),
[data-theme-color="glassy"] .accordion.accordion-style1>.accordion-item>.accordion-header>a:not(.collapsed) {
  color: #fff;
}

[data-theme-color="glassy"] .tag-attachment {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .tag-attachment.attachment-primary,
[data-theme-color="glassy"] .tag-attachment.attachment-secondary {
  color: #fff !important;
}

[data-theme-color="glassy"] .notification {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .invoice-highlight {
  color: #fff;
}

[data-theme-color="glassy"] .bg-white {
  background-color: #fff !important;
}

[data-theme-color="glassy"] .premium-card.text-white {
  color: var(--primary-bg-color) !important;
}

[data-theme-color="glassy"] .pagination {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .form-control.flatpickr-input,
[data-theme-color="glassy"] .flatpickr-calendar,
[data-theme-color="glassy"] .SumoSelect,
[data-theme-color="glassy"] .intl-tel-input,
[data-theme-color="glassy"] .form-select,
[data-theme-color="glassy"] .form-control,
[data-theme-color="glassy"] .select2 {
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .form-control {
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .smartphoto {
  --white-8: rgba(0, 0, 0, 0.8);
}

[data-theme-color="glassy"] .card-sigin {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .rdiobox {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .note-editor {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .ff_fileupload_wrap {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .bootstrap-tagsinput {
  --background-rgb: transparent;
}

[data-theme-color="glassy"] .nice-select {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .wizard {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .main-toggle {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .dropify-wrapper {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .richText,
[data-theme-color="glassy"] .ql-wrapper-demo,
[data-theme-color="glassy"] .ql-scrolling-demo {
  background: transparent;
  backdrop-filter: blur(30px);
}

[data-theme-color="glassy"] .badge-primary,
[data-theme-color="glassy"] .badge-secondary {
  color: #fff !important;
}

[data-theme-color="glassy"] .wizard>.actions a {
  color: #fff;
}

[data-theme-color="glassy"] .custom-checkbox .custom-control-input:checked~.custom-control-label::after,
[data-theme-color="glassy"] .custom-radio .custom-control-input:checked~.custom-control-label::after {
  filter: none;
}

[data-theme-color="glassy"] .bg-white-1 {
  background: var(--white-1);
}

[data-theme-color="glassy"] .bg-white-2 {
  background: var(--white-2);
}

[data-theme-color="glassy"] .bg-white-3 {
  background: var(--white-3);
}

[data-theme-color="glassy"] .bg-white-4 {
  background: var(--white-4);
}

[data-theme-color="glassy"] .bg-white-5 {
  background: var(--white-5);
}

[data-theme-color="glassy"] .bg-white-6 {
  background: var(--white-6);
}

[data-theme-color="glassy"] .bg-white-7 {
  background: var(--white-7);
}

[data-theme-color="glassy"] .bg-white-8 {
  background: var(--white-8);
}

[data-theme-color="glassy"] .bg-white-9 {
  background: var(--white-9);
}

[data-theme-color="glassy"] .bg-black-1 {
  background: var(--black-1);
}

[data-theme-color="glassy"] .bg-black-2 {
  background: var(--black-2);
}

[data-theme-color="glassy"] .bg-black-3 {
  background: var(--black-3);
}

[data-theme-color="glassy"] .bg-black-4 {
  background: var(--black-4);
}

[data-theme-color="glassy"] .bg-black-5 {
  background: var(--black-5);
}

[data-theme-color="glassy"] .bg-black-6 {
  background: var(--black-6);
}

[data-theme-color="glassy"] .bg-black-7 {
  background: var(--black-7);
}

[data-theme-color="glassy"] .bg-black-8 {
  background: var(--black-8);
}

[data-theme-color="glassy"] .bg-black-9 {
  background: var(--black-9);
}

[data-theme-color="glassy"] .main-profile-menu .text-black {
  color: #fff !important;
}

[data-theme-color="glassy"] .demo_changer .form_holder {
  background: #fff !important;
  border-inline-start: 1px solid var(--border);
}

[data-theme-color="glassy"][data-layout="horizontal"] .slide .side-menu__item.active::before,
[data-theme-color="glassy"][data-layout="horizontal"] .slide .side-menu__item:hover::before,
[data-theme-color="glassy"][data-layout="horizontal"] .slide .side-menu__item:focus::before {
  content: unset;
}

@media (min-width: 992px) {
  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="glassy"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="hover"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="hover1"] .sidenav-toggled.sidenav-toggled-open .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .desktop-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .mobile-dark {
    display: none;
  }

  [data-theme-color="glassy"][data-vertical-style="doublemenu"] .app .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: block;
  }

  [data-theme-color="glassy"][data-layout="horizontal"][data-logo="centerlogo"] .logo-horizontal .mobile-logo-1 {
    margin: 0 auto;
  }

  [data-theme-color="glassy"][data-vertical-style="hover"] .sidebar-mini.sidenav-toggled.sidenav-toggled-open .slide .slide-menu {
    background: #fff;
  }

  [data-theme-color="glassy"][data-vertical-style="hover1"] .app.sidenav-toggled-open .slide-menu {
    background: #fff;
  }
}

@media (max-width: 991.98px) {

  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .desktop-dark,
  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .mobile-dark,
  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .desktop-dark-1,
  [data-theme-color="glassy"] .app-sidebar .main-sidebar-header .mobile-dark-1 {
    display: none;
  }

  [data-theme-color="glassy"] .main-header .responsive-logo .header-logo .mobile-logo {
    display: none;
  }

  [data-theme-color="glassy"] .main-header .responsive-logo .header-logo .mobile-logo-1 {
    display: block;
  }
}

[data-theme-color="glassy"] .logo-horizontal .mobile-logo {
  display: none;
}

[data-theme-color="glassy"] .logo-horizontal .mobile-logo-1 {
  display: block;
}

@media (max-width: 991px) {
  [data-theme-color="glassy"] .responsive-navbar.navbar .navbar-collapse {
    background: #fff;
  }
}

[data-theme-color="glassy"] .navbar-toggler-icon {
  filter: drop-shadow(0 2px 5px rgba(0, 0, 0, 0.38));
}

@media (min-width: 1400px) {
  [data-theme-color="glassy"][data-width="boxed"] body .page {
    background: var(--background);
  }
}

[data-theme-color="glassy"] .main-card-signin .logo-dark {
  display: none;
}

[data-theme-color="glassy"] .main-card-signin .logo-light-theme {
  display: block;
}

[data-theme-color="glassy"] .text-light {
  color: var(--dark) !important;
}

[data-theme-color="glassy"] .app-sidebar::before,
[data-theme-color="glassy"] .card::before,
[data-theme-color="glassy"] .main-header>.container-fluid::before {
  background: linear-gradient(45deg,
      transparent,
      rgba(255, 255, 255, 0.08),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.6),
      rgba(255, 255, 255, 0.08),
      transparent);
  box-shadow: 0 8px 17px 0px #fff;
}

[data-theme-color="glassy"] .main-header>.container-fluid {
  border-block-end: 1px solid var(--border) !important;
}

[data-theme-color="glassy"] .page {
  background-color: #efefef1a;
}

[data-theme-color="glassy"] .card {
  background: rgba(255, 255, 255, 0.15);
  box-shadow: inset 0 8px 32px 0 rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
}

[data-theme-color="glassy"] .card:before {
  top: -1px;
}

[data-theme-color="glassy"] .slide-item,
[data-theme-color="glassy"] .side-menu__label {
  font-weight: 400;
}

@media (min-width: 992px) {
  [data-theme-color="glassy"] .app-sidebar {
    background: transparent;
    backdrop-filter: blur(30px);
  }
}

[data-theme-color="glassy"][data-menuimg="bg-img1"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img2"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img3"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img4"] .app-sidebar,
[data-theme-color="glassy"][data-menuimg="bg-img5"] .app-sidebar {
  backdrop-filter: unset;
}

/*# sourceMappingURL=style.css.map */

/*---new css---*/
.nft_theme .min_height {
  min-height: 148px !important;
}

.nft_theme .ft_18 {
  font-size: 18px;
}

.nft_theme .act_rlt {
  position: relative;
}

.nft_theme .act_rate {
  position: absolute;
  top: 44%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 21px;
}

.nft_theme #myProgress {
  background-color: #add8e6;
  width: 100%;
}

.nft_theme #myBar {
  height: 20px;
  width: 1%;
}

/*EXTRA CODE*/
.nft_theme .copy_btn_set {
  justify-content: space-between !important;
}

.nft_theme .modal-content10 {
  border-radius: 6px;
  margin: auto;
  padding: 15px 15px;
  width: 100%;
}

.nft_theme .avlbal {
  justify-content: space-between;
  margin: 7px 0;
}

.nft_theme .modal-title {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  margin-bottom: 0;
}

.nft_theme .card1 {
  border-radius: 5px;
  margin-block-end: 1.5rem;
  box-shadow: inset 0px -1px 12px 3px rgba(255, 255, 255, 0.05);
  background-color: transparent;
  background-position: center top;
  background-repeat: no-repeat;
  content: "";
  inset-inline-start: 0;
  inset-inline-end: 0;
  top: 0;
  /* width: 100%; */
}

.nft_theme .modal-body {
  padding: 15px;
  position: relative;
}

.nft_theme .tree_icon {
  border: 1px solid;
  border-radius: 6px;
  display: flex;
  font-size: 18px;
  justify-content: space-evenly;
}

@media (min-width: 1024px) {
  .sid-ip-1 {
    margin-left: -1198px;
  }

  .pro-sidebar {
    position: absolute !important;
  }
}

.nft_theme .offcanvas-start {
  width: 240px !important;
}

li {
  list-style-type: none;
  /* max-width: 200px; */
}

.mobile-logo {
  width: 100px;
  padding: 10px;
}

/* .nft_theme .modal-content10 {
    margin-left: 20px;
} */
@media only screen and (max-width: 600px) {
  .inputes {
    padding: 5px 9px;
    width: 60%;
    border-radius: 5px;
    border: none;
    margin-top: 5px;
    margin-left: 0px !important;
  }
}

.inputes {
  padding: 5px 9px;
  width: 60%;
  border-radius: 5px;
  border: none;
  margin-top: 5px;
  margin-left: 10px;
}

@media (min-width: 1024px) {
  .nft_theme .main-profile-menu {
    margin-top: -50px;
  }

  /* .nft_theme .modal-content10 {
        margin-left: 45px;
    } */

  .nft_theme .profile-user {
    float: right;
  }

  .nft_theme .net_box {
    margin-left: -70px;
  }
}

@media (max-width: "1023px") {
  .nft_theme .dark-logo-1 {
    margin-left: 47px !important;
  }
}

.nft_theme .user_icon img {
  height: 80px;
  margin-left: 41%;
  margin-top: 6%;
}

.nft_theme .upgrade_btn {
  text-align: center;
  position: absolute;
  right: 0;
  left: 0;
}

.nft_theme .upgrade_btn button {
  background-color: rgb(77, 238, 234) !important;
  color: #fff !important;
}

.nft_theme .min_height333 {
  min-height: 333px;
}

.nft_theme .offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 0rem !important;
}

.nft_theme .gaming {
  text-align: center;
  padding-top: 6rem;
}

.nft_theme .popup-arrow {
  display: none !important;
}

@media (min-width: 1024px) {
  .nft_theme .popup-content {
    top: 25% !important;
    left: 30% !important;
    width: 500px !important;
    padding: 0px !important;
    border: 1px solid var(--primary02) !important;
    border-radius: 3px !important;
  }

  .nft_theme .modal-dialog {
    max-width: 500px;
    margin: 0 auto !important;
  }
}

@media (max-width: 1024px) {
  .nft_theme .popup-content {
    top: 20% !important;
    left: 2% !important;
    width: 96% !important;
    padding: 0px !important;
    border: 1px solid var(--primary02) !important;
    border-radius: 3px !important;
  }

  .nft_theme .modal-dialog {
    max-width: 100%;
    margin: 0 auto !important;
  }

  .nft_theme .px-1 {
    padding-inline-start: 0px;
    padding-inline-end: 0px;
  }

  /* .col-12{
        padding-left: 0px;
        padding-right: 0px;
    } */
}

.nft_theme .buy_btn button {
  background-color: rgb(77, 238, 234) !important;
  color: #fff !important;
  font-size: 1rem;
  font-weight: 400;
  padding: 0.5rem 1.5rem;
}

.nft_theme .buy_btn {
  text-align: center;
}

.nft_theme .offcanvas-body {
  padding-top: 0rem !important;
}

.nft_theme .card1 {
  box-shadow: inset 0px -1px 12px 3px rgb(202 202 202 / 79%);
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 3s linear infinite;
}

.nft_theme .businness_ip {
  margin-bottom: 66px;
}

.nft_theme .main-profile-menu {
  top: -1rem;
  margin-left: 85%;
}

.nft_theme .box_shadow {
  box-shadow: 0 0 14px rgb(250 255 253);
}

.nft_theme .min_height2 {
  height: 168px !important;
}

@media (max-width: 1024px) {
  /* .nft_theme .mere_sarkar {
    display: none;
  } */
}

.nft_theme .charno {
  margin-top: -50p !important;
}

.nft_theme .abc1 {
  max-width: 42% !important;
}

.nft_theme .app-sidebar .slide-menu .slide-item::before {
  content: "\f068";
  /* font-family: "Font Awesome 5 Free"; */
  position: absolute;
  inset-block-start: 10px;
  inset-inline-start: 20px;
  font-size: 10px;
  color: var(--primary05);
  font-weight: 900;
  opacity: 0.5;
}

.nft_theme .form-control {
  background: transparent !important;
}

.nft_theme .offcanvas-body .dropdown .slide {
  margin-bottom: 25px;
}

.bg-label-primary {
  background-color: #122931 !important;
  color: #ffffff !important;
}

.userskil {
  background: #122931;
  border-radius: 5px;
  padding: 15px 35px;
  margin: auto;
  width: 100%;
}

.card-heading {
  font-weight: 600 !important;
  font-size: 20px !important;
  color: var(--primary-bg-color) !important;
  opacity: 1 !important;
  text-transform: uppercase !important;
}

.userskilTit {
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mainss {
  padding: 15px;
  display: flex;
  justify-content: space-between;
}

.userskilOne {
  background: rgb(4 98 77);
  border-radius: 11px;
  display: flex;
  min-width: 100px;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  margin-top: 10px;
  width: 100%;
}

.LegOneSkill {
  display: flex;
  margin-top: 15px;
  justify-content: space-between;
  align-items: center;
}

.userskilFill {
  color: white;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
}

.inputsssss {
  width: 100%;
}

.purple1 {
  margin-bottom: 15px;
  margin-right: 10px;
}

.purple1 span {
  background-color: white;
  padding: 5px 15px;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: bold;
  color: black;
}

.nft_theme .avlbal h3 {
  color: #71fcde !important;
}

.form5 {
  width: 65%;
  border-radius: 5px;
  border: none;
  margin: 5px 0px;
  padding: 5px;
}

.btse {
  font-size: 16px !important;
  font-weight: 600;
}

.nft_theme .activation_Item {
  max-width: 380px;
  margin: auto;
}

.nft_theme .activation_modal .popup_net {
  max-width: 380px;
  margin: auto;
}

.nft_theme .activation_modal .addNum {
  position: relative;
  top: -8px;
}

.iconss {
  padding-right: 10px;
  margin-top: 20px;
}

/* New Dashboard css */
.NewDashborad {}

.NewDashborad .header {
  background: red;

}

.sidebar .leftisidelogo {
  width: 100px;
  height: 100px;
}

.contentside .header .rightside .togglebtn {
  background: #00CAB2;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  margin-right: 15px;
  margin-left: 15px;
  position: relative;
  z-index: 99999;
}

.contentside .header .rightside .togglebtn i {
  color: white;

}

.sidebar {
  transition: width 0.3s ease;
  background: #f7f6fe;
  position: absolute !important;
  z-index: 999;
}

.sidebar .itemssidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.sidebar .itemssidebar li {
  background: #F7F6FE;
  border-radius: 8px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: black;
  font-weight: 700;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.sidebar .itemssidebar li .Anker {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.sidebar .itemssidebar li .Anker .icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sidebar .bottomimage {
  margin-top: 20px;
  height: 110px;
  margin-bottom: 20px;
}

.contentsidesec {
  /* height: 100vh;
  overflow-y: auto; */
  padding-bottom: 100px;
}

.contentsidesec .borderbottom {
  border-bottom: 1px solid #E3E3E8;
}

.App {
  overflow: hidden auto;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .hdleft {
  color: #0E0637;
  font-size: 30px;
  font-weight: 700;
}

.header .rightside {
  display: flex;
  align-items: center;
  gap: 0.5rem;

}

.header .rightside .dropdown.dropdow1 {
  position: relative;
}

.header .rightside .dropdown.dropdow1 .btnsecondary {
  border: 0px;
  border-radius: 8px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  position: relative;
  animation: abanico 1000ms ease-in-out;
}

.ant-pagination-item {
  background: #00CAB2 !important;
  color: white !important;
}

.ant-pagination-item-ellipsis {
  background: #00CAB2 !important;
  color: #000000 !important;
  border-radius: 5px;
}

.ant-pagination-item-link {
  background: #00CAB2 !important;
  color: white !important;
  border-radius: 5px;
}

.header .rightside .dropdown.dropdow1 .btnsecondary span {
  background: transparent;
  color: #09b7a2;
  position: absolute;
  top: -4px;
  right: 2px;
  padding: 0;
  font-size: 17px;
  FONT-WEIGHT: 600;

}

@keyframes abanico {
  0% {
    transform: rotate(0deg);
  }

  7.5% {
    transform: rotate(25deg);
  }

  30% {
    transform: rotate(-25deg);
  }

  45% {
    transform: rotate(15deg);
  }

  58% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(5deg);
  }

  87.5% {
    transform: rotate(-2deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

.header .rightside .dropdown.dropdow1 .dropdown-menu {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.header .rightside .dropdown.dropdow1 .dropdown-menu span {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.header .rightside .dropdown.dropdow1 .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.header .rightside .headerbtn {
  background: #00CAB2;
  border-radius: 8px;
  height: 35px;
  color: white;
  height: 40px;

}

.header .rightside .dropdown.dropdow2 {}

.header .rightside .dropdown.dropdow2 .btnsecondary {
  border: 1px solid #C8C8D0;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  height: 40px;
}

.header .rightside .dropdown.dropdow2 .btnsecondary .text1 p {
  font-size: 12px;
  color: black;

}

.header .rightside .dropdown.dropdow2 .btnsecondary .icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 14px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #A191F34D;

}

.header .rightside .dropdown.dropdow2 .dropdown-menu {
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.header .rightside .dropdown.dropdow2 .dropdown-menu .text1 {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.header .rightside .dropdown.dropdow2 .dropdown-menu .dropdown-item {
  font-size: 14px;
  color: white;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.contentsidesec .compaytext {
  font-size: 14px;
  color: #9C9CAB;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.4rem;
  margin-bottom: 10px;
}

.contentsidesec .compaytext span {
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentsidesec .Ankerinput {
  background: transparent;
  height: 15px;
  padding: 0;
  color: #0E0637;
  font-size: 14px;
}

.contentsidesec .HeadingTim {
  color: #0E0637;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}

.contentsidesec .cricle {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.contentsidesec .cricle .bigc {
  border: 1px solid #ECFDF7;
  border-radius: 50px;
  font-size: 13px;
  color: #11CF8B;
  font-weight: 400;
  background: #ECFDF7;
  padding: 4px 7px;
}

.contentsidesec .cricle .smallc {
  border: 1px solid #ECFDF7;
  border-radius: 50px;
  font-size: 13px;
  color: #11CF8B;
  font-weight: 400;
  background: #ECFDF7;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentsidesec .Anker {
  font-size: 14px;
  color: #0E0637;
}

.contentsidesec .btntop {
  border-radius: 8px;
  background: #00CAB2;
  color: white;
  font-size: 14px;
  height: 35px;
}

.contentsidesec .grapsec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentsidesec .grapsec .heading {
  font-size: 16px;
  color: #131316;
  font-weight: 500;
}

.contentsidesec .grapsec .dropdown {}

.contentsidesec .grapsec .dropdown .btnsecondary {
  border: 1px solid #C8C8D0;
  font-size: 14px;
  color: #0E0637;
  border-radius: 10px;
}

.contentsidesec .grapsec .dropdown .dropdown-menu {}

.contentsidesec .grapsec .dropdown .dropdown-menu .dropdown-item {}

.contentsidesec .leftsidecardpoint {}

.contentsidesec .leftsidecardpoint .card-heading {
  color: #131316;
  font-size: 16px;

}

.contentsidesec .leftsidecardpoint .Progressection {}

.contentsidesec .leftsidecardpoint .Progressection .progress {
  background-color: rgb(233 236 239);
}

.contentsidesec .leftsidecardpoint .Progressection .Progress .progress-bar {
  border-radius: 0px !important;
}

.contentsidesec .leftsidecardpoint .Progressection .Progress {}




.contentsidesec .leftsidecardpoint {}

.contentsidesec .totlalPoint {}


.contentsidesec .totlalPoint {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentsidesec .totlalPoint .leftside {
  font-size: 14px;
  color: #717184;
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.contentsidesec .totlalPoint .leftside span {
  width: 8px;
  height: 8px;
}

.contentsidesec .totlalPoint .leftside span.bg1 {
  background-color: #0E0637;
}

.contentsidesec .totlalPoint .leftside span.bg2 {
  background-color: #DAF727;
}

.contentsidesec .totlalPoint .leftside span.bg3 {
  background-color: #00CAB2;
}

.contentsidesec .totlalPoint .leftside .pont {
  font-size: 14px;
  color: #131316;
}

.contentsidesec .bottomcard {
  background: #EFEDFD;
  height: 167px;
  overflow: hidden;
}

.contentsidesec .bottomcard .Ankercard {
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 25px;
  color: #0E0637;
  font-weight: 700;
  line-height: 40px;
}

.contentsidesec .bottomcard .newsmarpuee h6 {
  color: #0E0637;
  font-size: 16px;
  line-height: 22px;
}

.contentsidesec .bottomcard .Ankercard span {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.contentsidesec .groupbutton {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 0.5rem;

}

.contentsidesec .groupbutton .btnG {
  font-size: 14px;
  color: #00CAB2;
  width: 30%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.3rem;
}

.contentsidesec .groupbutton .btnG.active {
  font-size: 14px;
  background: #F7F6FE;
  border: 1px solid #E7E3FC;
}

.contentsidesec .groupbutton .btnG.textsecondary {
  color: #9C9CAB;
}

.contentsidesec .leftsidecard {
  border: 1px solid #E3E3E8;
}

.contentsidesec .leftsidecard {}

.contentsidesec .leftsidecard .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  border-bottom: 1px solid #DADADA;
}

.contentsidesec .leftsidecard .header .left {
  font-weight: 600;
  font-size: 16px;
  color: #757575;
}

.contentsidesec .leftsidecard .header .right {
  font-weight: 700;
  font-size: 40px;
  color: #00CAB2;
}

.contentsidesec .leftsidecard .twocrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contentsidesec .leftsidecard .twocrow .crow {
  width: 45%;
  text-align: center;
}

.contentsidesec .leftsidecard .twocrow .crow .heading {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  position: relative;
  margin-top: 20px;
}

.contentsidesec .leftsidecard .twocrow .crow .heading::after {
  position: absolute;
  content: "";
  background-image: url(../../Assets/Dasahboard/line.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 50%;
  height: 12px;
  bottom: 0;
  transform: translate(50%, 50%);
  left: 0;
}

.contentsidesec .leftsidecard .twocrow .crow .grouptext {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
}

.contentsidesec .leftsidecard .twocrow .crow .grouptext .lefttext {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  flex-direction: column;
}

.contentsidesec .leftsidecard .twocrow .crow .grouptext .toptext {
  font-size: 14px;
  font-weight: 600;
  color: #757575;
  margin-bottom: 0px;
}

.contentsidesec .leftsidecard .twocrow .crow .grouptext .bottomtext {
  font-size: 16px;
  font-weight: 600;
  color: #00CAB2;
  margin-bottom: 0px;
}

.contentsidesec .samecard {
  border: 1px solid #E3E3E8;
  border-radius: 8px;
  height: 120px;
}

.contentsidesec .samecard .heading {
  font-size: 25px;
  font-weight: 500;
  color: #062046;
  margin-bottom: 10px;
}

.contentsidesec .samecard p {
  font-size: 16px;
  font-weight: 400;
  color: #757575;
  margin-bottom: 5px;
}

.contentsidesec .samecard .eyeimage {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Default sidebar open */
.sidebar-open {
  width: 212px;
  transition: width 0.3s ease;
  position: fixed;
  left: 0;
  z-index: 9999;
  background: #f7f6fe;
  padding: 0;
}

.sidebar-closed {
  width: 0;
  transition: width 0.3s ease;
  display: none;
  z-index: 9999;
  background: #f7f6fe;
  position: relative;
}

.fas.fa-bars.collapsed {
  transform: rotate(0deg);
}

.NewDashboard .sidebarLeft {
  /* z-index: 9999;
  background: #f2f0ff;
  position: relative; */
}

.NewDashboard .sidebarLeft .leftisidelogo {
  width: 100px;
  height: 100px;
}

.NewDashboard .sidebarLeft .itemssidebar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.NewDashboard .sidebarLeft .itemssidebar li {
  background: #F7F6FE;
  border-radius: 8px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: black;
  font-weight: 700;
  flex-wrap: wrap;
  gap: 0.4rem;
}

.NewDashboard .sidebarLeft .itemssidebar li .Anker {
  font-size: 14px;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: transparent;
  color: black;
}

.NewDashboard .sidebarLeft .itemssidebar li .Anker .icon {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NewDashboard .sidebarLeft .bottomimage {
  margin-top: 10px;
  height: 105px;
  margin-bottom: 10px;
  width: -moz-fit-content;
  width: fit-content;
  display: inline-block;
  width: 100%;
}

.Zindexcard {
  position: relative;
  z-index: 1;
}

.NewDashboard .GraphSection {
  position: relative;
}

.NewDashboard .GraphSection .grapgcard-box {
  background-color: black;
  border-color: black;
  margin: auto;
  width: 200px;
  margin-bottom: -100px;
}

.NewDashboard .GraphSection .grapgcard-box .heading {
  font-size: 16px;
  color: white;
  font-weight: 700;
  text-align: center;
}

.NewDashboard .GraphSection .grapgcard-box .liveratetext {
  font-size: 14px;
  color: white;
  font-weight: 700;
  text-align: center;
}

/* Activate Page Css */
.NewDashboard .Activatecard {
  background-color: #00CAB2;
  box-shadow: 0px 0px 10px #06c1c1;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.NewDashboard .Activatecard input {
  background: #00544a;
}

/* Activate_History Css */
.NewDashboard.Activate_History .card {
  border: 1px solid #00544a;
  transition: all 0.3s ease-in-out;
  position: relative;
  z-index: 1;
}

.NewDashboard.Activate_History .card:hover {
  box-shadow: inset 0 -1px 12px 3px #06c1c1;
}

.NewDashboard.Activate_History .card thead tr {
  background-color: #00544a;
}

.NewDashboard.Activate_History .card thead tr th {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.NewDashboard.Activate_History .card table tbody tr td {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

/* Working_Withdrawalpage Css Start */

.Working_Withdrawalpage {}

.Working_Withdrawalpage .card1 {
  background-color: #00CAB2;
  box-shadow: 0px 0px 10px #06c1c1;
  border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Working_Withdrawalpage input {
  background-color: #00544a;
}

.Working_Withdrawalpage .activate_btn {
  background-color: white;
  color: black;
}

/* HelpDesk Css */
.NewDashboard.HelpDesk {}

.NewDashboard.HelpDesk input {
  background-color: #00544a;
}

.HelpDesk textarea {
  background-color: #00544a;
}

.NewDashboard.HelpDesk table thead tr {
  background-color: #00544a;
}

.NewDashboard.HelpDesk table thead tr th {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.NewDashboard.HelpDesk .card table tbody tr td {
  color: black;
  font-size: 14px;
  font-weight: 500;
}

.notfictiontextable table tbody td {
  color: black;
  font-size: 14px;
}

/* Your CSS rules here */

@media (min-width: 320px) and (max-width: 575.99px) {
  .contentsidesec .samecard p {
    font-size: 14px;
  }

  .contentsidesec .leftsidecard .header .left {
    font-size: 14px;
  }

  .contentsidesec .leftsidecard .header .right {
    font-size: 25px;
  }

  .contentsidesec .leftsidecard .twocrow .crow .heading {
    font-size: 14px;
  }

  .contentsidesec .leftsidecard .twocrow .crow .grouptext .toptext {
    font-size: 13px;
  }

  .contentsidesec .leftsidecard .twocrow .crow .grouptext .bottomtext {
    font-size: 13px;
  }

  .contentsidesec .bottomcard .newsmarpuee h6 {
    font-size: 13px;
  }

  .contentsidesec .samecard .heading {
    font-size: 20px;
  }

  .contentsidesec {
    padding-bottom: 0px;
  }

  .NewDashboard .sidebarLeft .leftisidelogo {
    display: none;
  }

  .contentsidesec .samecard {
    width: 260px;
    height: 130px;
  }

  .NewDashboard .GraphSection .grapgcard-box {
    margin-bottom: -40px;
  }

  .NewDashboard .GraphSection .grapgcard-box .liveratetext {
    font-size: 13px;
  }

  .header .hdleft .namelogo {
    font-size: 12px;
    line-height: 20px;
  }

  .header .rightside .headerbtn {
    font-size: 12px;
  }

  .contentside .header .rightside .togglebtn {
    margin-left: 10px;
    margin-right: 10px;
  }

  .header {
    gap: 0.5rem;
  }

  span.ant-select-selection-item {
    font-size: 12px;
  }

  .header .rightside .dropdown.dropdow2 .btnsecondary {
    gap: 0rem;
    border: 0px;
    padding: 0;
  }
  .walltadd{
    font-size: 13px;
  }
}


@media (min-width: 576px) and (max-width: 767.99px) {
  .header .rightside .dropdown.dropdow2 .btnsecondary {
    gap: 0rem;
    border: 0px;
  }
  .walltadd{
    font-size: 13px;
  }
}

@media (min-width: 768px) and (max-width: 991.99px) {
  .walltadd{
    font-size: 14px;
  }
  .contentsidesec .leftsidecard .twocrow .crow .heading::after {
    height: 25px;
    bottom: 0;
    transform: translate(0%, 50%);
    left: 0;
    width: 100%;
  }
}