body {
  /* background: #021410 !important; */
  overflow: scroll !important;
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.bg_img {
  /* background-image: url("../public/assets/img/bg/advantages_bg.png"); */
}

.bg_img_footer {
  background-image: url("../public/assets/img/bg/footer_bg2.jpg");
}

.team__bg {
  background-image: url("../public/assets/img/bg/team_sec_bg.png");
}

.brand__sec-bg {
  background-image: url("../public/assets/img/bg/brand_bg.png");
}

.accordion-button:not(.collapsed),
.accordion-button {
  color: black !important;
  background-color: white !important;
}

.accordion {
  --bs-accordion-color: #000 !important;
  --bs-accordion-bg: #fff !important;
  --bs-accordion-btn-focus-border-color: transparent !important;
  --bs-accordion-btn-focus-box-shadow: none !important;
  font-size: 26px;
  line-height: 2.5rem;
  --bs-accordion-btn-padding-y: 2.4rem !important;
}

.accordion-button {
  font-size: 26px !important;
}

.accordion-item {
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #dee2e6;
}

.accordion-button:focus {
  border-color: transparent !important;
}

.pddff {
  display: flex;
  align-items: center;
  justify-content: center;
  top: -80px;
  margin-left: 43px !important;
}

.tknDtails {
  width: 7%;
}

@media screen and (max-width: 650px) {
  .pddff {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0px;
    margin-left: 0px !important;
  }

  .user-option__item {
    padding-right: 10px;
  }

  .mapEmg {
    margin-bottom: 30px;
  }

  .hero__blockchain-icon img {
    width: 50%;
  }

  .hero__blockchain-icon span {
    font-size: 13px;
    margin-right: 5px;
  }

  .hero__blockchain-icon .icon--1 {
    left: -22px;
  }

  .accordion {
    font-size: 20px;
    line-height: 1.8rem;
  }
}

@media (min-width: 700px) and (max-width: 950px) {
  .mapEmg {
    margin-bottom: 30px;
  }
}

@media (min-width: 1000px) and (max-width: 1150px) {
  .pddff {
    display: flex;
    align-items: center;
    justify-content: center;
    top: -76px;
    margin-left: 170px !important;
  }
}

/*  */
body {
  /* display: flex; */
  height: 100vh;
  overflow-y: auto;
}

.sidebar {
  width: 250px;
  transition: width 0.3s;
  overflow: hidden;
}

.sidebar.closed {
  width: 80px;
}

Ṭ .sidebar .nav-link {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;
}

.sidebar.closed .nav-link span {
  display: none;
}

.content-wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.header,
.footer {
  background-color: #f8f9fa;
  padding: 15px;
  text-align: center;
}

.content {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #177d97;
  width: 92%;
  margin: auto;
}

/* Hero Section css */
.headinHero {
  font-weight: 700;
}

.headinHero span {
  color: #009d8a;

}
.headinHero .hometextrigt{
  color: #009d8a;
  font-weight: bold;
}