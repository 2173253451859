.loginpage {
  height: 100vh;
  background-image: url(../../../public/assets/img/Login/loginbg.png);
  object-fit: cover;
  background-size: 100% 100%;
}

.loginpage .vhUnstet {
  height: unset !important;
}

.loginpage .logoimage {
  margin: auto;
}

.loginpage .heading {
  font-size: 35px;
  font-weight: 600;
  color: white;
  line-height: 56px;
  display: flex;
  justify-content: center;
}

.loginpage .loginheading {
  font-size: 30px;
  font-weight: 700;
  color: #090E3A;
  text-align: start;
  margin-bottom: 10px;
  line-height: 40px;

}
.loginheading2{
  font-size: 25px;
  font-weight: 700;
  color: #090E3A;
  text-align: start;
  margin-bottom: 10px;
}
.loginpage .subtext {
  font-size: 14px;
  color: #6F7187;
  text-align: start;
}

.loginpage .cardlogin {
  min-height: 300px;
  position: relative;
  width: fit-content;
  border: 0;

}

.loginpage .cardlogin label {
  font-size: 14px;
  font-weight: 600;
  color: #090E3A;
}

.loginpage .cardlogin .control-form {
  height: 50px;
  background-color: transparent;
  border: 1px solid #00cab2;
  font-size: 13px;
  color: #000000;
  border-radius: 30px;
  border-color: #00cab2;
  box-shadow: 1px 3px 13px #c7ece4;
}

.loginpage .cardlogin .control-form::placeholder {
  color: #6F7187;
}

.loginpage .cardlogin .remembertext {
  font-size: 13px;
  color: #000000;
}

.loginpage .cardlogin .remembertext .Anker {
  font-size: 14px;
  color: #090E3A;
  font-weight: 600;
}

.loginpage .cardlogin .logibtn {
  height: 40px;
  border-radius: 20px;
  background: #00CAB2;
  color: white;
  width: 100%;
  text-align: center;
  line-height: 25px;
  border: 1px solid #00CAB2;
  width: fit-content;
  margin: auto;
  display: flex;
}
.loginpage .cardlogin .logibtn.newcolor{
  color: #e5e3e3;
}

.loginpage .cardlogin .logibtn .Anker {
  color: #001513;
  text-decoration: underline;
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
}

.loginpage .cardlogin .logibtn:hover {
  background: #00CAB2;
  color: white;
}

.bottomtext {
  text-align: center;
  font-size: 13px;
  color: #090E3A;

}

.remembertext2 {
  font-size: 14px;
  color: #000000;

}

.remembertext2 .Anker {
  font-size: 14px;
  color: #090E3A;
  font-weight: 600;

}
.modalinput{
  background-color: white !important;
}
.modalinput::placeholder{
 color: black;
}
.modalbtn1{
  font-size: 15px;
  color: white;
  font-weight: 600;
  width: 40%;
  border: 1px solid #00CAB2;
  background-color: #087d6f !important;
  height: 40px;
}
.modalbtn2{
  font-size: 15px;
  color: white;
  font-weight: 600;
  width: 40%;
  border: 1px solid #00CAB2;
  background-color: #00CAB2;
  height: 40px;
}

@media (min-width: 320px) and (max-width: 575.99px) {
  .loginpage {
    background-image: url(../../../public/assets/img/Login/loginbg2.png);
    height: unset;
  }

  .loginpage .cardlogin {
    margin-top: 200px;
  }

  .imageside {
    display: none !important;
  }

  .loginpage .loginheading {
    text-align: center;
  }

  .loginpage .subtext {
    text-align: center;
  }

  .logosideimg {
    display: none;
  }

  .loginpage .cardlogin .logibtn {
    font-size: 10px;
  }

  .loginpage .cardlogin .remembertext .Anker {
    font-size: 14px;
  }

  .loginpage .heading {
    font-size: 30px;
    line-height: 45;
  }
}

@media (min-width: 576px) and (max-width: 767.99px) {
  .loginpage {
    background-image: url(../../../public/assets/img/Login/loginbg2.png);
    height: unset;
  }

  .loginpage .cardlogin {
    margin-top: 200px;
  }

  .imageside {
    display: none !important;
  }

  .loginpage .loginheading {
    text-align: center;
  }

  .loginpage .subtext {
    text-align: center;
  }

  .logosideimg {
    display: none;
  }

  .loginpage .cardlogin .logibtn {
    font-size: 10px;
  }

  .loginpage .cardlogin .remembertext .Anker {
    font-size: 14px;
  }

  .loginpage .heading {
    font-size: 30px;
    line-height: 45;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .loginpage .heading {
    font-size: 30px;
    font-weight: 600;
    color: white;
    line-height: 40px;
    display: flex;
    justify-content: center;
  }

  .loginpage {
    height: 100%;
    padding-bottom: 50px;
  }
}